import EnvVars from 'helpers/envVars';

import Request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'getMedia';
const domainImages = EnvVars('API_IMAGES_BASE_URL');

const getMediaRequest = ({ mediaId, mediaVcode }) => {
  return Request.get<any>(
    `${domainImages}/media/v1.0/${mediaId}${
      mediaVcode ? `?vcode=${mediaVcode}` : ''
    }`,
    {},
    { responseType: 'blob' }
  ).then(buildHTTPResponseHandler(`${serviceName}.getMediaRequest()`) as any);
};

const getMediaUrl = ({ mediaId, mediaVcode }) => {
  return `${domainImages}/media/v1.0/${mediaId}${
    mediaVcode ? `?vcode=${mediaVcode}` : ''
  }`;
};

export { getMediaUrl, getMediaRequest };
