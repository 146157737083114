import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import {
  ExpansionPanelContainer,
  SummaryContainer,
  SummaryText,
  DetailsContainer,
  ExpansionIcon,
  IconAnimation,
} from './styles';

const ExpansionPanel = withStyles({
  root: {
    padding: '0',
    borderRadius: '4px',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    height: '50px',
    borderBottom: '1px solid #f2f2f2',
    padding: '0 16px 0 16px',
    '&$expanded': {
      minHeight: '48px',
      padding: '0 16px 0 16px',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: '0',
  },
}))(MuiExpansionPanelDetails);

const CustomizedExpansionPanel = ({
  panel,
  last = false,
  children,
  startExpanded = false,
  fixed = false,
  openManual = null,
}) => {
  const [expanded, setExpanded] = useState(fixed ? true : startExpanded);

  const handleClick = () => {
    if (fixed) {
      return;
    }
    setExpanded(!expanded);
  };

  return (
    <ExpansionPanelContainer last={last}>
      <ExpansionPanel
        square
        expanded={openManual !== null ? openManual : expanded}
        onChange={openManual ? null : handleClick}
      >
        <ExpansionPanelSummary>
          <SummaryContainer alignCenter>
            <SummaryText expanded={openManual ?? expanded}>
              {panel.title}
            </SummaryText>
            {!fixed ? (
              <IconAnimation className={expanded ? 'expanded' : null}>
                <ExpansionIcon />
              </IconAnimation>
            ) : null}
          </SummaryContainer>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DetailsContainer justifyBetween>{children}</DetailsContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </ExpansionPanelContainer>
  );
};

export default CustomizedExpansionPanel;
