import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';

import ActionCreators from 'modules/resale/store/reducers/leads/actionCreators';
import AutoComplete from 'components/atoms/AutoComplete';
import ChooseVehicleTabs from 'components/molecules/ChooseVehicleTabs';
import ClientsList from 'modules/financing/components/molecules/ClientsList';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import SimpleClientForm from 'modules/financing/components/molecules/SimpleClientForm';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { userType } from 'constants/userAccess';

import DealerServices from 'services/dealerServices';
import ProposalServices from 'services/proposalServices';
import ProviderServices from 'services/providerServices';

import ContextProposal from '../../Context';
import ProposalHistoryList from './components/ProposalHistoryList';
import CreateNewProposalModalComponent from './CreateNewProposalModalComponent';
import { Skeleton } from '@material-ui/lab';

const CreateNewProposalModalContainer = props => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { getProposals } = useContext(ContextProposal);

  const { currentDealer, userStore, originOptions } = useSelector(state => ({
    ...state.dealerStore,
    userStore: state.userStore,
    originOptions: state.leads.originOptions,
  }));

  const [state, setState] = useState({
    stepsConfig: {
      steps: [
        { label: 'Dados do Cliente' },
        { label: 'Histórico de atendimento' },
        { label: 'Veículo' },
        { label: 'Detalhes' },
      ],
      currentStep: 0,
      completed: [],
    },
    clientCurrent: null,
    sourceSelected:
      originOptions.find(origin => origin?.value === 'PASSING')?.value ||
      originOptions?.[0]?.value ||
      null,
    createNewProposalIsLoading: false,
    createContact: false,
    departments: [],
    departmentCode: null,
    providerList: [],
    providerId: null,
    allSellers: [],
    currentSellers: [],
    sellerId: props.currentUserId,
    sellerEmail: null,
  });

  const [vehicleCurrent, setVehicleCurrent] = useState(
    props?.vehicleCurrent ? [props?.vehicleCurrent] : []
  );

  const filterSellers = ({ values = [], departmentCode = null }) => {
    const filteredSellers = values?.filter(seller => {
      const isSalesManager = seller?.userType === userType.SALES_MANAGER.name;
      const isSameDepartment = !!seller?.departments.find(
        sellerDepartment => sellerDepartment.code === departmentCode
      );
      const dealerNotHasDepartment = !departmentCode;

      if (dealerNotHasDepartment) {
        return true;
      }

      if (!isSalesManager) return isSameDepartment;

      return seller?.department ? isSameDepartment : true;
    });

    const formattedSellers = filteredSellers?.map(seller => ({
      label: seller?.userSite?.name,
      value: seller?.userSite?.email,
      department: seller?.department,
    }));

    return formattedSellers;
  };

  const handleClickToNextStep = () => {
    setState(prevState => {
      if (prevState.stepsConfig.currentStep === 3) {
        return {
          ...prevState,
          stepsConfig: {
            ...prevState.stepsConfig,
          },
        };
      }
      return {
        ...prevState,
        stepsConfig: {
          ...prevState.stepsConfig,
          currentStep: prevState.stepsConfig.currentStep + 1,
        },
      };
    });
  };

  const setClientCurrent = clientCurrent => {
    setState(prevState => ({ ...prevState, clientCurrent }));
  };

  const handleClickToBackStep = () => {
    setState(prevState => ({
      ...prevState,
      stepsConfig: {
        ...prevState.stepsConfig,
        currentStep: prevState.stepsConfig.currentStep - 1,
      },
    }));
  };

  const handleOnChangeOrigin = newValue => {
    setState(prevState => ({
      ...prevState,
      sourceSelected: newValue,
    }));
  };

  const handleOnChangeDepartment = newValue => {
    setState(prevState => ({
      ...prevState,
      departmentCode: newValue,
      sellerEmail: null,
      currentSellers: filterSellers({
        values: prevState?.allSellers,
        departmentCode: newValue,
      }),
    }));
  };

  const handleOnChangeProviderId = newValue => {
    setState(prevState => ({
      ...prevState,
      providerId: newValue,
    }));
  };

  const handleOnChangeSeller = newValue => {
    setState(prevState => ({
      ...prevState,
      sellerEmail: newValue,
    }));
  };

  const getDepartments = async () => {
    try {
      const { success, data } = await DealerServices.getAllDepartamentByDealer(
        currentDealer.id
      );
      if (success && data?.length) {
        setState(prevState => ({
          ...prevState,
          departments: [
            ...data?.map(el => ({ value: el.code, label: el.name })),
          ],
        }));
        const getUserDepartment = data?.find(
          el => el.id === Number(userStore?.departments?.[0]?.id)
        );
        handleOnChangeDepartment(
          props.departmentId
            ? data.find(el => el.id === Number(props.departmentId))?.code
            : getUserDepartment
            ? getUserDepartment?.code
            : data[0]?.code
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const buildDealersList = statusList => {
    const dealersListTemp = [];
    statusList.map(item =>
      dealersListTemp.push({
        label: item.name,
        value: item.id,
      })
    );
    return dealersListTemp;
  };

  const getProviders = async () => {
    try {
      const { data, success } = await ProviderServices.getProviderLeadByDealer(
        currentDealer.id,
        true
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          providerList: buildDealersList(data),
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSellers = async () => {
    try {
      const { data, success } = await DealerServices.getSellers(
        currentDealer.id,
        null
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          allSellers: data,
          sellerEmail: null,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOriginOptions = () => {
    dispatch(ActionCreators.getOriginOptions(null, null, true));
  };

  useEffect(() => {
    getOriginOptions();
    getProviders();
    getSellers();

    if (!state.departments.length) {
      getDepartments();
    }
  }, []);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      currentSellers: filterSellers({
        values: state?.allSellers,
        departmentCode: state?.departmentCode,
      }),
    }));
  }, [state?.allSellers, state?.departmentCode]);

  //function responsible for returning the current step view
  const getCurrentStepContent = step => {
    switch (step) {
      case 0:
        return (
          <div>
            {!state.clientCurrent ? (
              <ClientsList
                clientPhone={props?.clientPhone ?? null}
                handleClickListItem={client => {
                  setClientCurrent(client);
                  handleClickToNextStep();
                }}
              />
            ) : (
              <Fragment>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <HypertextButton onClick={() => setClientCurrent(null)}>
                    Alterar cliente
                  </HypertextButton>
                </div>
                <SimpleClientForm clientCurrent={state.clientCurrent} />
              </Fragment>
            )}
          </div>
        );
      case 1:
        return (
          <div>
            <ProposalHistoryList
              dealerId={currentDealer.id}
              contactId={state.clientCurrent?.id}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <ChooseVehicleTabs
              selectedVehicles={vehicleCurrent}
              handleSelectVehicle={vehicle => {
                setVehicleCurrent(vehicle ? [vehicle] : []);
              }}
              allAvailable
              isModels0Km
            />
          </div>
        );
      case 3:
        return (
          <div>
            {!!state.departments.length && (
              <Fragment>
                <AutoComplete
                  name="Department"
                  label="Departamento*"
                  isClearable={false}
                  value={
                    state.departmentCode
                      ? {
                          value: state.departmentCode,
                          label: state.departments.find(
                            department =>
                              department.value === state.departmentCode
                          )?.label,
                        }
                      : null
                  }
                  onChange={event => handleOnChangeDepartment(event?.value)}
                  options={state.departments}
                  minHeight="58px"
                />
                <div style={{ height: 8 }} />
              </Fragment>
            )}
            {!state.currentSellers.length && (
              <Fragment>
                <Skeleton height={58} animation="wave" variant="rect" />
                <div style={{ height: 8 }} />
              </Fragment>
            )}
            {!!state.currentSellers.length && (
              <Fragment>
                <AutoComplete
                  name="sellerEmail"
                  label="Vendedor"
                  isClearable
                  value={
                    state.sellerEmail
                      ? {
                          value: state.sellerEmail,
                          label: state.currentSellers.find(
                            seller => seller.value === state.sellerEmail
                          )?.label,
                        }
                      : null
                  }
                  options={state.currentSellers}
                  onChange={event => handleOnChangeSeller(event?.value)}
                  minHeight="58px"
                />
                <div style={{ height: 8 }} />
              </Fragment>
            )}
            {!!originOptions.length && (
              <Fragment>
                <AutoComplete
                  name="Origem do lead"
                  label="Origem do lead*"
                  isClearable={false}
                  value={
                    state.sourceSelected
                      ? {
                          value: state.sourceSelected,
                          label: originOptions.find(
                            origin => origin.value === state.sourceSelected
                          )?.label,
                        }
                      : null
                  }
                  options={originOptions}
                  onChange={event => handleOnChangeOrigin(event?.value)}
                  minHeight="58px"
                />
                <div style={{ height: 8 }} />
              </Fragment>
            )}
            {!!state.providerList.length && (
              <AutoComplete
                name="providerId"
                label="Canal"
                value={
                  state.providerId
                    ? {
                        value: state.providerId,
                        label: state.providerList.find(
                          channel => channel.value === state.providerId
                        )?.label,
                      }
                    : null
                }
                onChange={event => handleOnChangeProviderId(event?.value)}
                options={state.providerList}
                minHeight="58px"
              />
            )}
            {!originOptions.length && (
              <Fragment>
                <div style={{ height: 8 }} />
                <Alert severity="error">
                  Não existe “Origem” cadastrada para selecionar, peça ao seu
                  gerente para habilitar em configurações
                </Alert>
              </Fragment>
            )}
          </div>
        );
      default:
        return <Fragment />;
    }
  };

  const addProposal = async (dealerId, proposalObj) => {
    const result = await ProposalServices.createProposal(dealerId, proposalObj);
    const { success, data } = result;

    if (success) {
      if ((data || {}).id) {
        return { success: true, id: (data || {}).id };
      }
      return { success: false, id: null };
    } else {
      return { success: false, id: null };
    }
  };

  const buildVehicleDefaultObject = dealObject => {
    if (!dealObject) {
      return null;
    }

    if (dealObject?.dealerId && dealObject?.id) {
      return { dealId: dealObject?.dealerId ? dealObject?.id : undefined };
    }

    if (typeof dealObject?.make !== 'object') {
      return {
        armored: dealObject?.armored,
        bodystyle: {
          id: dealObject?.bodystyleId,
          name: dealObject?.bodystyleName,
        },
        color: { id: dealObject?.colorId, name: dealObject?.colorName },
        fuel: { id: dealObject?.fuelId, name: dealObject?.fuelName },
        km: dealObject?.km,
        make: { id: dealObject?.makeId, name: dealObject?.makeName },
        price: dealObject?.price,
        model: { id: dealObject?.modelId, name: dealObject?.modelName },
        modelYear: dealObject?.modelYear,
        transmission: {
          id: dealObject?.transmissionId,
          name: dealObject?.transmissionName,
        },
        trim: { id: dealObject?.trimId, name: dealObject?.trimName },
        initialYear: dealObject?.initialYear,
        finalYear: dealObject?.finalYear,
      };
    }
    return dealObject;
  };

  const createNewProposal = async () => {
    try {
      const { id: contactId } = state.clientCurrent;
      setState(prevState => ({
        ...prevState,
        createNewProposalIsLoading: true,
      }));
      const { success, id } = await addProposal(currentDealer.id, {
        cpf: state.clientCurrent?.cpfCnpj || '',
        name: state.clientCurrent?.name || '',
        email: state.clientCurrent?.email || '',
        mobile: state.clientCurrent?.phone || '',
        origin: state.sourceSelected,
        proposalInterestProduct: buildVehicleDefaultObject(
          vehicleCurrent?.[0] ?? null
        ),
        sellerEmail: state.sellerEmail,
        groupId: undefined,
        providerId: state.providerId,
        contactId,
        departmentCode: state?.departmentCode || undefined,
        callId: props.callId,
      });
      if (success) {
        setState(prevState => ({
          ...prevState,
          createNewProposalIsLoading: false,
        }));
        getProposals && getProposals();
        props.handleOnClose();
        history.push({
          pathname: history.location.pathname,
          search: `?proposalId=${id}`,
        });
      } else {
        setState(prevState => ({
          ...prevState,
          createNewProposalIsLoading: false,
        }));
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro ao criar a proposta!'
          )
        );
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        createNewProposalIsLoading: false,
      }));
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao criar a proposta!'
        )
      );
    }
  };

  const checkCurrentStep = currentStep => {
    switch (currentStep) {
      case 0:
        if (state.clientCurrent) {
          return false;
        }
        return true;
      case 1:
      case 2:
      case 3:
        return false;
      default:
        return false;
    }
  };

  const viewComponentProps = {
    stepsConfig: state.stepsConfig,
    getCurrentStepContent,
    handleClickToNextStep,
    handleClickToBackStep,
    createNewProposal,
    checkCurrentStep,
    isOpen: props.isOpen,
    handleOnClose: props.handleOnClose,
    createNewProposalIsLoading: state.createNewProposalIsLoading,
    providerId: state?.providerId,
  };

  return <CreateNewProposalModalComponent {...viewComponentProps} />;
};

export default React.memo(CreateNewProposalModalContainer);
