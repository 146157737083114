import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
`;

export const CancelButton = styled(Button)`
  background: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  color: #424242 !important;
  text-transform: inherit !important;
`;

export const AddCarButton = styled(Button)`
  background: ${({ theme }) => `${theme.primary}`} !important;
  color: #fff !important;
  text-transform: inherit !important;
`;
