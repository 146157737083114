const StatusEnum = {
  WITH_PHOTOS: 'WITH_PHOTOS',
  NO_PHOTOS: 'NO_PHOTOS',
  PUBLISHED: 'PUBLISHED',
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  WAITING: 'WAITING',
  ERROR: 'ERROR',
  BOOKED: 'BOOKED',
};
export default StatusEnum;
