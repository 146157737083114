import styled from 'styled-components';

const chipColor = {
  blue: { fontColor: '#00069a', backgroundColor: '#dfe0ff' },
  purple: { fontColor: '#4443BC', backgroundColor: '#e9eaf9' },
  high: { fontColor: '#009A8A', backgroundColor: '#E0F3F1' },
  medium: { fontColor: '#FF9800', backgroundColor: '#FFF5E5' },
  low: { fontColor: '#f44336', backgroundColor: '#feeceb' },
  default: { fontColor: '#BDBDBD', backgroundColor: '#F5F5F5' },
};

const chipShape = {
  height: { round: '32px', square: '24px' },
  borderRadius: { round: '16px', square: '4px' },
};

export const Container = styled.div`
  border-radius: ${({ shape }) => chipShape?.borderRadius?.[shape] ?? '16px'};
  margin: ${({ margin }) => margin};
  width: max-content;
  height: ${({ shape }) => chipShape?.height?.[shape] ?? '32px'};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ type }) =>
    chipColor?.[type]?.backgroundColor ?? chipColor.default.backgroundColor};
`;

export const Label = styled.div`
  white-space: nowrap;
  padding: 0px 12px;
  color: ${({ type }) =>
    chipColor?.[type]?.fontColor ?? chipColor.default.fontColor};
  line-height: 20px;
  font-size: ${({ fontSize }) => fontSize};
`;
