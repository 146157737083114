import { all } from 'redux-saga/effects';

import dealReducer from './deals/reducers';
import dealsSagas from './deals/sagas';

export const manageDealsReducers = { 
        dealReducer
    };

export const manageDealsSagas = all([
        dealsSagas
    ]);





