import React from 'react';
import { PageWrapper } from 'components/PageWrapper';
import Grid from '@material-ui/core/Grid';
import Flex from 'bit/mobiauto.web-ui-components.flex/Flex';

export default function Page({
  title = null,
  children,
  hasOverflow = null,
  Filter = null,
  withoutPadding = false,
}) {
  return (
    <PageWrapper hasOverflow={hasOverflow} withoutPadding={withoutPadding}>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={Filter ? 6 : 12}>
          <Flex justifyStart>{title}</Flex>
        </Grid>
        {Filter && (
          <Grid item xs={6}>
            <Flex justifyEnd>{Filter}</Flex>
          </Grid>
        )}
      </Grid>
      {children}
    </PageWrapper>
  );
}
