export const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    border: '1px solid #E0E0E0',
    boxShadow: 'none',
  },
  table: {},
  tableWrapper: {
    overflowX: 'auto',
  },
});
