import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import ClientData from './ClientData';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Nome obrigatório')
    .default(''),
  cpfCnpj: yup
    .string()
    .required('Cpf/Cnpj obrigatório')
    .default(''),
  phone: yup
    .string()
    .required('Telefone obrigatório')
    .default(''),
  email: yup
    .string()
    .email('E-mail inválido')
    .required('e-mail obrigatório')
    .default(''),
});

const ClientDataContainer = () => {
  const dispatch = useDispatch();
  const { queueData } = useSelector(state => state.order);

  const formik = useFormik({
    initialValues: schema.cast({
      cpfCnpj: queueData?.client?.cpfCnpj,
      email: queueData?.client?.email,
      name: queueData?.client?.name,
      phone: queueData?.client?.phone,
    }),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: schema,
    onSubmit: values => {
      dispatch(
        ActionCreators.setQueueData({
          RequiredFieldsForCurrentStepCompleted: true,
          client: values,
        })
      );

      return;
    },
  });

  const viewComponentProps = {
    formik,
    queueData,
  };

  useEffect(() => {
    if (
      queueData?.currentStepProposalQueue === 1 &&
      formik.values.cpfCnpj &&
      formik.values.email &&
      formik.values.name &&
      formik.values.phone
    ) {
      formik.handleSubmit();
      return;
    }
    dispatch(
      ActionCreators.setQueueData({
        RequiredFieldsForCurrentStepCompleted: false,
      })
    );
  }, [formik.values, queueData?.currentStepProposalQueue]);

  return (
    <div style={{ margin: 30 }}>
      <ClientData {...viewComponentProps} />
    </div>
  );
};

export default ClientDataContainer;
