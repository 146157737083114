import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useSnackBar from 'hooks/useSnackBar';
import { FilterOption } from 'pages/proposals/tabs/PopoverFilters/interfaces';
import useCustomMutation from 'pages/proposals/tabs/PopoverFilters/hooks/useCustomMutation';
import groupServices from 'services/groupServices';

interface FilterObject {
  id: string | number;
  name: string;
  label: string;
  value: string | number;
}
interface Props {
  apiProperty: string;
  selectedsValues?: FilterObject[];
}

export default function useGroupChannels({
  apiProperty,
  selectedsValues,
}: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[] | null>(
    null
  );
  const { handleSnackBarError } = useSnackBar();
  const { currentGroup } = useSelector(state => state.callCenter);
  const groupId = currentGroup.id;

  function useGetChannels() {
    return useCustomMutation({
      key: 'getChannels',
      handleReturn: {
        onError: () => {
          handleSnackBarError('Ocorreu um erro ao consultar "Canal".');
        },
      },
      request: async () =>
        groupServices
          .getChannelsOptionsByGroupId(groupId)
          .then(response => response?.data),
    });
  }

  const { data, isLoading, mutate: getGroupChannels } = useGetChannels();

  useEffect(() => {
    if (options?.length) {
      if (!selectedsValues.length && selectedOptions) {
        setSelectedOptions(null);
      }
      if (selectedsValues && selectedsValues.length) {
        const filtered = options.filter(option => {
          return selectedsValues.some(selected => {
            return Number(selected.value) === Number(option.value);
          });
        });
        setSelectedOptions(filtered);
      }
    }
  }, [options, selectedsValues]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(el => ({
        filterGroupId: apiProperty,
        id: `${apiProperty}-${el?.id}`,
        label: el?.label,
        value: String(el?.id),
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOptions,
    getGroupChannels,
    isLoading,
  };
}
