import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import * as OptionsServices from 'services/optionsServices';
import defaultSelectArray from 'constants/defaultSelect';

const _defaultInitialState = {
  options: {},
  apps: null,
  publishesStatus: [],
  scoreFilter: [],
  orderBy: [],
  hasFetched: false,
  hasFailed: false,
  isLoading: false,
};
class OptionsStore {
  constructor(initialState) {
    this.setInitialState(initialState || _defaultInitialState);
  }

  setInitialState = initialState => {
    const { options, hasFailed, hasFetched, isLoading } = initialState;
    this.options = options;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setData = (data, field, hasFailed, hasFetched, isLoading) => {
    this.options[field] = data;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setDataApps = (apps, hasFailed, hasFetched, isLoading) => {
    this.apps = apps;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setPublishesStatus = (publishesStatus, hasFailed, hasFetched, isLoading) => {
    this.publishesStatus = publishesStatus;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setOrderBy = (orderBy, hasFailed, hasFetched, isLoading) => {
    this.orderBy = orderBy;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setScoreFilter = (scoreFilter, hasFailed, hasFetched, isLoading) => {
    this.scoreFilter = scoreFilter;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  formatToSelectPlusOneOnId(filters) {
    const select = [...defaultSelectArray];
    select.push(
      ...(filters || []).map(item => {
        const { id, label, name, ...props } = item;
        return {
          label: item.label,
          value: item.name,
          name: item.name,
          ...props,
        };
      })
    );
    return select;
  }

  formatToSelect(filters) {
    const select = [...defaultSelectArray];
    select.push(
      ...(filters || []).map(item => {
        const { id, label, name, ...props } = item;
        return {
          label: item.label,
          value: item.id,
          name: item.name,
          ...props,
        };
      })
    );
    return select;
  }
  getVehicleTypes = async () => {
    this.setData(null, 'vehicleType', null, null, true);
    const result = await OptionsServices.getVehicleTypes();
    const { data, success } = result;
    if (success) {
      this.setData(data, 'vehicleType', false, true, false);
    } else {
      this.setData(null, 'vehicleType', true, false, false);
    }
    return this.formatToSelect(data);
  };

  getApps = async () => {
    this.setDataApps(null, null, null, true);
    const result = await OptionsServices.getApps();
    const { data, success } = result;
    if (success) {
      this.setDataApps(data, false, true, false);
    } else {
      this.setDataApps(null, true, false, false);
    }
  };

  getPublishesStatus = async () => {
    this.setPublishesStatus(null, null, null, true);
    const result = await OptionsServices.getPublishesStatus();
    const { data, success } = result;

    if (success) {
      this.setPublishesStatus(
        this.formatToSelectPlusOneOnId(data),
        false,
        true,
        false
      );
    } else {
      this.setPublishesStatus(null, true, false, false);
    }
  };
  getOrderBy = async () => {
    this.setOrderBy(null, null, null, true);
    const result = await OptionsServices.getOrderBy();
    const { data, success } = result;

    if (success) {
      this.setOrderBy(this.formatToSelectPlusOneOnId(data), false, true, false);
    } else {
      this.setOrderBy(null, true, false, false);
    }
  };

  getScoreFilter = async () => {
    this.setScoreFilter(null, null, null, true);
    const result = await OptionsServices.getScoreFilter();
    const { data, success } = result;

    if (success) {
      this.setScoreFilter(
        this.formatToSelectPlusOneOnId(data),
        false,
        true,
        false
      );
    } else {
      this.setScoreFilter(null, true, false, false);
    }
  };

  getFilters = () => {
    this.getPublishesStatus();
    this.getScoreFilter();
    this.getOrderBy();
  };
}

export default remotedev(
  decorate(OptionsStore, {
    data: observable,
    hasFetched: observable,
    hasFailed: observable,
    isLoading: observable,
    apps: observable,
    filters: observable,
    publishesStatus: observable,
    orderBy: observable,
    scoreFilter: observable,
    setInitialState: action,
    getVehicleTypes: action,
    getApps: action,
    setData: action,
    setDataApps: action,
    setFilters: action,
    getFilters: action,
    getScoreFilter: action,
    setScoreFilter: action,
    getOrderBy: action,
    setOrderBy: action,
    getPublishesStatus: action,
    setPublishesStatus: action,
  })
);
