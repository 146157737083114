import React, { useEffect, useState, useCallback } from 'react'
import SearchServices from 'services/searchServices'
import ListVehicleByMakeIdComponent from './ListVehicleByMakeIdComponent'

type AvailableVehicleType = 'CAR' | 'MOTORCYCLE';

interface VehicleType {
  label: string;
  value: AvailableVehicleType;
}

const vehicleTypeOptions: VehicleType[] = [
  { label: "Carro", value: "CAR" },
  { label: "Moto", value: "MOTORCYCLE" }
]

function ListVehicleByMakeIdContainer({
  selectedItemId,
  handleClickItem,
  dealerStore,
  vehicleType
}) {
  const [vehicleList, setVehicleList] = useState([])
  const [makesOptions, setMakesOptions] = useState([])
  const [makesIdSelected, setMakesIdSelected] = useState(dealerStore?.makeId || null)
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [vehicleTypeSelected, setVehicleTypeSelected] =
    useState<VehicleType>(vehicleTypeOptions[0]);

  const search = useCallback(async () => {
    setIsLoading(true)

    const makeId = makesIdSelected || dealerStore.makeId
    const vehicleTypeSelectedValue = vehicleTypeSelected.value;

    if (makesIdSelected || dealerStore.makeId) {
      const { data }: any =
        await SearchServices
          .getVehiclesMyMakeId(makeId, query, vehicleTypeSelectedValue)

      setVehicleList(data)
    } else {
      setVehicleList([])
    }
    setIsLoading(false)
  }, [dealerStore.makeId, makesIdSelected, query, vehicleTypeSelected])

  const getMakes = useCallback(async () => {
    if (!vehicleTypeSelected.value) setMakesOptions([])
    
    const { data }: any = await SearchServices.getMakes(vehicleTypeSelected.value)
    setMakesOptions(data.map((e) => {
      return {
        label: e.name,
        value: e.id
      }
    }))
  }, [vehicleTypeSelected])

  useEffect(() => {
    getMakes()
    search()
  }, [vehicleTypeSelected])

  useEffect(() => {
    if (makesIdSelected) search()
  }, [makesIdSelected, search])

  const handleKeyPress = event => 
    event.key === 'Enter' || event.key === 'NumpadEnter' && search();
  const handleChangeQuery = (value) => setQuery(value)

  const viewComponentProps = {
    vehicleList,
    makesOptions,
    makesIdSelected,
    setMakesIdSelected,
    handleClickItem,
    selectedItemId,
    vehicleType,
    isLoading,
    query,
    handleKeyPress,
    handleClickSearch: search,
    handleChangeQuery,
    vehicleTypeOptions,
    handleSelectVehicleType: setVehicleTypeSelected,
    vehicleTypeSelected
  }
  return <ListVehicleByMakeIdComponent {...viewComponentProps} />
}

export default ListVehicleByMakeIdContainer