export default {
  page: 0,
  size: 20,
  options: {
    orderFilter: 'DAYS_IN_STOCK_ASC',
    usado: true,
    zeroKm: true,
    query: null,
    publishStatus: null,
    scoreFilter: null,
    dashFilter: null,
    planIdFilter: null,
    showroom: null,
    booked: null
  },
};
