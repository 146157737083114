import React from 'react';

import { Box, Modal, Typography, CircularProgress } from '@material-ui/core';

import OutlinedButton from 'components/atoms/OutlinedButton';

import CloseButton from 'modules/financing/components/atoms/CloseButton';
import { useSelector, useDispatch } from 'react-redux';
import ActionCreators from 'modules/order/store/reducers/actionCreators';

const ModalCancelInvoiceComponent = ({
  open,
  onClose,
  handleOpenModalInvoice,
}) => {
  const styles = {
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    box: {
      backgroundColor: '#fff',
      outline: 'none',
    },
    button: {
      background: '#4443BC',
      borderRadius: '4px',
      outline: 'none',
      color: '#fff',
      border: '0',
    },
    title: {
      color: '#212121',
    },
    description: {
      margin: 0,
      color: '#333333',
    },
  };

  const {
    cancelInvoice: { isLoading },
  } = useSelector(state => state?.order);

  const dispatch = useDispatch();
  const handleRedux = {
    POST: () => dispatch(ActionCreators.sendCancelInvoice()),
  };
  const handleRollback = () => {
    handleOpenModalInvoice();
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose} style={styles.modal}>
      <Box component="div" width="530px" height="185px">
        <Box component="div" style={styles.box} borderRadius="4px" p={4}>
          <Box component="div" display="flex" justifyContent="flex-end">
            <CloseButton onClick={onClose} />
          </Box>
          <Box component="div" textAlign="center">
            <Typography variant="h5" style={styles.title}>
              Deseja cancelar nota emitida?
            </Typography>
            <Box component="p" style={styles.description}>
              Uma vez feita essa ação não poderá ser desfeita
            </Box>
          </Box>
          <Box
            component="div"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gridGap="10px"
            mt={4}
          >
            <OutlinedButton onClick={handleRollback}>Cancelar</OutlinedButton>
            <button style={styles.button} onClick={() => handleRedux.POST()}>
              {isLoading ? <CircularProgress /> : <span>Confirmar</span>}
            </button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalCancelInvoiceComponent;
