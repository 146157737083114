import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import formatAmount from 'helpers/formatAmount';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import { ReactComponent as BarCodeIcon } from 'assets/bar-code-icon.svg';
import isEmpty from 'helpers/isEmpty';
import {
  Flex,
  FlexSpaceBetween,
  Title,
  TicketPrice,
  Separator,
  ColumCenter,
  BankInfosContainer,
  SubLabel,
  Label,
} from './styles';

const TicketInfos = ({ ticket }): JSX.Element => {
  const [currentTicket] = useState(ticket)
  const { bankSlipIssued } = useSelector(
    state => state.order
  );
  const bankSlipAmountFormated = formatAmount(currentTicket.value, 'PT-BR', 'R$');
  const bankSlipDateFormated = new Date(currentTicket.dueDate)?.toLocaleDateString();

  const viewBankSlip = (data, fileName) => {
    try {
      const binaryString = window.atob(data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      window.open(link, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  if (bankSlipIssued) {
    return <div>Boleto Emitido.</div>;
  }

  return (
    <FlexSpaceBetween>
      <Flex>
        <BankInfosContainer>
          <BarCodeIcon />
        </BankInfosContainer>

        <Separator />

        <ColumCenter>
          <Title>Valor do Boleto</Title>
          <TicketPrice>{bankSlipAmountFormated}</TicketPrice>
        </ColumCenter>

        <Separator />

        {currentTicket.dueDate && (
          <ColumCenter>
            <Label>Vencimento</Label>
            <SubLabel>{bankSlipDateFormated}</SubLabel>
          </ColumCenter>
        )}

        <Separator />

        <ColumCenter>
          {!isEmpty(currentTicket.bankSlipContent) ? (
            <HypertextButton
              onClick={() => viewBankSlip(currentTicket.bankSlipContent, 'boleto')}
            >
              visualizar
            </HypertextButton>
          ) : (
            <ColumCenter>
              <Label>Situação</Label>
              <Label style={{ color: '#757575', fontSize: 14 }}>não emitida</Label>
            </ColumCenter>
          )}
        </ColumCenter>
      </Flex>
    </FlexSpaceBetween>
  );
};

export default TicketInfos;
