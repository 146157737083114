import React from 'react';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import formatAmount from 'helpers/formatAmount';
import { ReactComponent as LogoBancoPan } from 'assets/Logo-BancoPAN.svg';
import TextField from 'modules/financing/components/atoms/TextField';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import { useFinancingSimulationContext } from 'pages/chats/components/SideBarComponents/FinancingSimulation/context';
import ThemeButton from 'components/atoms/ThemeButton';
import { CPFMaskCustom } from 'components/MaskTextInput';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ProposalCard from './components/ProposalCard';
import Alert from './components/Alert';
import { Container, PlateContainer } from './styles';

const CreateSimulationCard = ({ handleClickCreateSimulation }) => {
  const {
    formErros,
    formTouched,
    handleBlur,
    createSimulateFormValues,
    createSimulationState,
    calculeSimulationMutation,
    handleClickCalculateSimulation,
    handleChangeSimulateForm,
  }: any = useFinancingSimulationContext();

  return (
    <Container>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding={'16px'}
        fontSize={14}
        color="#424242"
        borderRadius="4px"
        border="1px solid #E0E0E0"
        style={{ backgroundColor: '#FAFAFA' }}
      >
        {createSimulateFormValues?.vehicle?.id ||
        createSimulateFormValues?.vehicle.trim?.id ? (
          <Box component="div" display="flex" justifyContent="space-between">
            <Box component="div" display="flex" justifyContent="space-between">
              <Box component="div">
                <Box
                  component="div"
                  color="#333333"
                  fontSize={'16px'}
                  fontFamily={'Poppins Bold'}
                  fontWeight={600}
                >
                  {createSimulateFormValues.vehicle?.makeName ||
                    createSimulateFormValues.vehicle?.make?.name}{' '}
                  {createSimulateFormValues.vehicle?.modelName ||
                    createSimulateFormValues.vehicle?.model?.name}
                </Box>
                <Box component="div" color="#757575">
                  {createSimulateFormValues.vehicle?.trimName ||
                    createSimulateFormValues.vehicle?.trim?.name}{' '}
                  {createSimulateFormValues.vehicle?.modelYear}
                </Box>
                <PlateContainer>
                  {createSimulateFormValues.vehicle?.plate}
                </PlateContainer>
              </Box>
            </Box>
            <Box component="div" display="flex" justifyContent="baseline">
              <Box
                component="div"
                color="#4443BC"
                fontWeight={600}
                fontSize={'16px'}
                fontFamily={'Poppins Bold'}
              >
                {formatAmount(
                  createSimulateFormValues?.vehicle?.price,
                  'PT-BR',
                  'R$',
                  true
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <OutlinedButton fullWidth onClick={handleClickCreateSimulation}>
            Selecionar um veículo
          </OutlinedButton>
        )}
        {(createSimulateFormValues?.vehicle?.id ||
          createSimulateFormValues?.vehicle.trim?.id) && (
          <HypertextButton
            style={{ marginTop: 8 }}
            onClick={handleClickCreateSimulation}
          >
            + Selecionar outro veículo
          </HypertextButton>
        )}
      </Box>

      <div style={{ minHeight: '8px' }} />
      <Box>
        <Grid container spacing={1}>
          {/* ROW */}
          <Grid item xs={6}>
            <TextField
              label="CPF"
              name="clientCpf"
              value={createSimulateFormValues.clientCpf}
              onChange={handleChangeSimulateForm}
              InputProps={{
                inputComponent: CPFMaskCustom,
              }}
              onBlur={handleBlur}
              error={formTouched.clientCpf && Boolean(formErros.clientCpf)}
              helperText={formTouched.clientCpf && formErros.clientCpf}
              mini
              required
            />
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              label="Valor do veículo"
              value={createSimulateFormValues.vehicle.price}
              onValueChange={values =>
                handleChangeSimulateForm({
                  target: {
                    name: 'vehicle.price',
                    value: values?.floatValue || 0,
                  },
                })
              }
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              allowLeadingZeros={false}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="R$ "
              customInput={TextField}
              error={
                formTouched?.vehicle?.price &&
                Boolean(formErros?.vehicle?.price)
              }
              helperText={
                formTouched?.vehicle?.price &&
                formErros?.vehicle?.price?.message
              }
              mini
            />
          </Grid>
          {/* ROW */}
          <Grid item xs={6}>
            <NumberFormat
              label="Valor de entrada*"
              value={createSimulateFormValues.inputValue}
              onValueChange={values =>
                handleChangeSimulateForm({
                  target: {
                    name: 'inputValue',
                    value: values?.floatValue || 0,
                  },
                })
              }
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={true}
              allowLeadingZeros={false}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="R$ "
              customInput={TextField}
              onBlur={handleBlur}
              error={formTouched.inputValue && Boolean(formErros.inputValue)}
              helperText={formTouched.inputValue && formErros.inputValue}
              mini
            />
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              label="Valor a ser financiado"
              value={
                (createSimulateFormValues?.inputValue || 0) -
                (createSimulateFormValues?.vehicle?.price || 0)
              }
              onValueChange={() => null}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              allowLeadingZeros={false}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="R$ "
              customInput={TextField}
              disabled
              mini
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ minHeight: '8px' }} />
      <Box>
        {calculeSimulationMutation.isLoading ? (
          <Progress small />
        ) : (
          <ThemeButton
            onClick={handleClickCalculateSimulation}
            disabled={
              calculeSimulationMutation.isLoading ||
              !(
                createSimulateFormValues?.vehicle?.id ||
                createSimulateFormValues?.vehicle?.trim?.id
              )
            }
            fullWidth
          >
            Calcular
          </ThemeButton>
        )}
      </Box>
      {!!createSimulationState.simulateResponse && (
        <>
          <div style={{ minHeight: '8px' }} />
          <Box>
            {!createSimulationState.simulateResponse.coldSimulation && (
              <LogoBancoPan />
            )}
            <Divider />
            {createSimulationState.simulateResponse.preAnalyseMessage && (
              <Alert
                status="NOT_ELEGIVEL"
                message={
                  createSimulationState.simulateResponse.preAnalyseMessage
                }
              />
            )}
            <Box component="div" margin="16px 0px">
              <Grid container spacing={1}>
                {createSimulationState.simulateResponse?.simulacoes.map(
                  (simulacao, index) => {
                    return <ProposalCard key={index} simulacao={simulacao} />;
                  }
                )}
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default CreateSimulationCard;
