import React from 'react';
import Grid from '@material-ui/core/Grid';
import find from 'lodash.find';
import { MenuItem } from '@material-ui/core';
import { GroupMaskCutom, QuotaMaskCutom } from 'components/MaskTextInput/mask';
import { KeyboardDatePicker } from '@material-ui/pickers';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import AutoComplete from 'components/atoms/AutoComplete';
import {
  InstallmentsType,
  OrderPayment,
} from 'modules/order/types/OrderPayment';
import TextField from 'modules/financing/components/atoms/TextField';
import CurrencyTextField from 'modules/financing/components/atoms/CurrencyTextField';
import FinancingProducts from './FinancingProducts';
import SearchVehicleDms from './components/SearchVehicleDms';
import formatAmount from 'helpers/formatAmount';
import { makeStyles } from '@material-ui/core/styles';
import { FormLine, HyperlinkButton } from './styles';
import moment from 'moment';

export type AddPaymentProps = {
  open: boolean;
  installmentsTypes: InstallmentsType[];
  editPayment?: OrderPayment;
};

const useStyles = makeStyles({
  root: {
    '& .css-lo8ec1-ValueContainer': {
      overflowX: 'hidden',
    },
  },
});

function AddPaymentDialog({
  paymentValue,
  installmentsTypes,
  installmentsType,
  editPayment,
  filteredPaymentTypes,
  loadingInstitutionList,
  institutionList,
  parcelToDiscountList,
  paymentType,
  hasPaymentTypeError,
  hasValueError,
  hasTrocoError,
  financingValue,
  paymentDueDate,
  today,
  paymentToDiscount,
  institution,
  totalFinancingProducts,
  usedVehicle,
  comments,
  setComments,
  group,
  quota,
  open,
  handleOnClose,
  isFinanciamento,
  handleOnChangeInstallmentsType,
  handleOnChangePaymentType,
  getValueHelperText,
  isUsedVehicle,
  isDiscountPayment,
  handleOnChangeFinancingValue,
  handleOnChangePaymentValue,
  handleOnChangeParcelToSlaughter,
  isFinanceira,
  isConsorcio,
  handleOnChangeInstitution,
  handleOnChangeQuota,
  getHasErrors,
  handleOnSave,
  handleOnChangeGroup,
  handleOnChangeVehicle,
  useTotalReamining,
  totalRemaining,
  financingTerm,
  setFinancingTerm,
  financingInstallments,
  setFinancingInstallments,
  handleOnChangeDueDate,
}) {
  // Hooks
  const classes = useStyles();

  const dueDateIsNotSelectable =
    typeof paymentType?.dueDays === 'undefined' ||
    paymentType?.dueDays === undefined;

  return (
    <GenericDialog
      open={open}
      onClose={handleOnClose}
      DialogAction={() => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <OutlinedButton onClick={handleOnClose}>Cancelar</OutlinedButton>
          <div style={{ minWidth: 8 }}></div>
          <ThemeButton disabled={getHasErrors()} onClick={handleOnSave}>
            Salvar
          </ThemeButton>
        </div>
      )}
      title="Adicionar pagamento"
      maxWidth="sm"
    >
      <>
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={6}>
            <AutoComplete
              name=""
              label="Meio de pagamento*"
              value={
                installmentsType
                  ? {
                      label: installmentsType?.label,
                      value: installmentsType?.value,
                    }
                  : null
              }
              onChange={e =>
                handleOnChangeInstallmentsType({ target: { value: e?.value } })
              }
              options={installmentsTypes.map(item => ({
                label: item.label,
                value: item.name,
              }))}
              disabled={editPayment}
              minHeight="58px"
            />
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              name=""
              label="Tipo de pagamento*"
              options={filteredPaymentTypes}
              value={paymentType ? paymentType : null}
              onChange={e =>
                handleOnChangePaymentType(
                  find(filteredPaymentTypes, { value: e?.value })
                )
              }
              disabled={editPayment || !installmentsType}
              error={hasPaymentTypeError}
              helperText={
                hasPaymentTypeError
                  ? 'Este tipo de pagamento deve ser inserido somente uma vez na proposta'
                  : ''
              }
              minHeight="58px"
            />
          </Grid>
        </Grid>
        <div style={{ minHeight: 8 }} />
        <FormLine>
          <div style={{ width: '100%' }}>
            {isFinanciamento() ? (
              <CurrencyTextField
                value={financingValue}
                onChangeValue={handleOnChangeFinancingValue}
                label="Valor"
                error={hasValueError || hasTrocoError}
                helperText={
                  (hasValueError || hasTrocoError) && getValueHelperText()
                }
                required
              />
            ) : (
              <CurrencyTextField
                value={paymentValue}
                onChangeValue={handleOnChangePaymentValue}
                label="Valor"
                error={hasValueError || hasTrocoError}
                helperText={
                  (hasValueError || hasTrocoError) && getValueHelperText()
                }
                disabled={isUsedVehicle()}
                required={!isUsedVehicle()}
              />
            )}
            {totalRemaining > 0 && (
              <HyperlinkButton onClick={useTotalReamining}>
                Valor Residual:{' '}
                {formatAmount(totalRemaining, 'PT_BR', 'R$', true)}
              </HyperlinkButton>
            )}
          </div>
          <div style={{ minWidth: 8 }}></div>

          <KeyboardDatePicker
            autoOk
            label="Data de vencimento"
            inputVariant="outlined"
            value={paymentDueDate}
            invalidDateMessage="Data inválida"
            minDateMessage="Data não pode estar no passado"
            format="DD/MM/YYYY"
            onChange={event => {
              const selectedDate = moment(event)
                .local(true)
                .format();
              handleOnChangeDueDate(selectedDate);
            }}
            fullWidth
            minDate={today}
            disabled={!dueDateIsNotSelectable}
            required
          />
        </FormLine>
        <FormLine>
          {isDiscountPayment() && (
            <TextField
              select
              label="Parcela à abater"
              value={paymentToDiscount?.position}
              onChange={handleOnChangeParcelToSlaughter}
              margin="normal"
              required
            >
              {!parcelToDiscountList.length && (
                <MenuItem key={0} disabled>
                  Nenhuma parcela disponível para abater
                </MenuItem>
              )}
              {parcelToDiscountList.map(payment => (
                <MenuItem key={payment.position} value={payment.position}>
                  {payment.position} - {payment.paymentType.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </FormLine>

        {(isFinanceira() || isConsorcio()) && (
          <>
            <div style={{ minHeight: 8 }}></div>
            <AutoComplete
              name=""
              label="Instituição financeira"
              options={institutionList}
              value={institution ? institution : null}
              onChange={e =>
                handleOnChangeInstitution(
                  find(institutionList, { value: e?.value })
                )
              }
              minHeight="58px"
            />
            <div style={{ minHeight: 8 }}></div>
            {isFinanceira() && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <CurrencyTextField
                    value={financingInstallments}
                    onChangeValue={e => setFinancingInstallments(e.floatValue)}
                    label="Valor da Parcela"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Prazo"
                    defaultValue={financingTerm}
                    onChange={event => setFinancingTerm(event.target.value)}
                    inputProps={{ maxLength: 3 }}
                    placeholder="ex: 32"
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}

        {isConsorcio() && (
          <FormLine>
            <TextField
              label="Cota"
              margin="normal"
              required
              InputProps={{
                inputComponent: QuotaMaskCutom,
                value: quota,
                onChange: handleOnChangeQuota,
              }}
            />
            <TextField
              label="Grupo"
              margin="normal"
              required
              InputProps={{
                inputComponent: GroupMaskCutom,
                value: group,
                onChange: handleOnChangeGroup,
              }}
            />
          </FormLine>
        )}
        {isUsedVehicle() && (
          <SearchVehicleDms
            currentVehicle={usedVehicle}
            onVehicleChoosen={handleOnChangeVehicle}
          />
        )}
        {isFinanciamento() && totalFinancingProducts !== 0 && (
          <FinancingProducts
            financingValue={financingValue}
            totalValue={paymentValue}
          />
        )}
        <FormLine>
          <TextField
            label="Observações"
            margin="normal"
            multiline
            rowsMax="5"
            value={comments}
            onChange={event => setComments(event.target.value)}
            inputProps={{ maxLength: 500 }}
            helperText={`${comments?.length || 0}/500`}
            fullWidth
          />
        </FormLine>
      </>
    </GenericDialog>
  );
}

export default AddPaymentDialog;
