import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import { TextMaskCustom, CPFCNPJMaskCustom } from 'components/MaskTextInput';
import TextField from 'modules/financing/components/atoms/TextField';
import { Container, BackButton, NextButton, TitleForm } from './styles';

const ClientFormComponent = ({
  formData,
  handleBlur,
  errors,
  touched,
  onChange,
  isCreating = false,
  handleSubmit,
  handleClickCancel,
  isLoading,
}: {
  formData: any;
  touched: any;
  errors: any;
  isCreating?: boolean;
  isLoading: boolean;
  onChange: any;
  handleBlur: any;
  handleSubmit: () => void;
  handleClickCancel: () => void;
}) => {
  return (
    <Container>
      {isCreating && <TitleForm>Adicionar novo cliente</TitleForm>}
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <TextField
            id="name"
            name="name"
            label="Nome Completo"
            value={formData['name']}
            onChange={onChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            mini
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="cpfCnpj"
            name="cpfCnpj"
            label="CPF/CNPJ"
            value={formData['cpfCnpj']}
            onBlur={handleBlur}
            error={touched.cpf && Boolean(errors.cpf)}
            helperText={touched.cpf && errors.cpf}
            InputProps={{
              inputComponent: CPFCNPJMaskCustom,
              onChange: onChange,
            }}
            mini
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="phone"
            name="phone"
            label="Telefone 1"
            value={formData['phone']}
            onBlur={handleBlur}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
            }}
            required
            mini
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="phone2"
            name="phone2"
            label="Telefone 2"
            value={formData['phone2']}
            onBlur={handleBlur}
            error={touched.phone2 && Boolean(errors.phone2)}
            helperText={touched.phone2 && errors.phone2}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
            }}
            mini
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            type="email"
            label="E-mail"
            value={formData['email']}
            onChange={onChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            required
            mini
          />
        </Grid>
      </Grid>

      <DialogActions>
        <div style={{ display: 'flex' }}>
          <BackButton onClick={handleClickCancel}>Cancelar</BackButton>
          {isLoading ? (
            <Progress small />
          ) : isCreating ? (
            <NextButton onClick={handleSubmit}>Criar contato</NextButton>
          ) : (
            <NextButton onClick={handleSubmit}>Salvar dados</NextButton>
          )}
        </div>
      </DialogActions>
    </Container>
  );
};

export default ClientFormComponent;
