import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import VehicleToTrimInfos from './VehicleToTrimInfos';
import searchServices from 'services/searchServices';
// import proposalServices from 'services/proposalServices'
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
const vehicleModel = {
  make: null,
  model: null,
  modelYear: null,
  productionYear: null,
  trim: null,
  color: null,
  transmission: null,
  bodystyle: null,
  fuel: null,
  km: null,
  minPrice: null,
  maxPrice: null,
  armored: false,
};

const buildDefaultYearsOptions = () => {
  const temp = [];
  const currentYear = moment().year() + 1;
  for (let year = 1950; year <= currentYear; year++) {
    temp.push({ value: year, label: String(year) });
  }
  return temp;
};

const buildOptions = array => {
  return array.map(el => ({ value: el.id, label: el.name, ...el }));
};

const VehicleToTrimInfosContainer = ({ handleClickItem }) => {
  const { dealerStore } = useSelector(state => ({
    proposalStore: state.proposalStore,
    dealerStore: state.dealerStore,
  }));
  const dispatch = useDispatch();
  const [state, setState] = useState({
    makesOptions: [],
    makesIsLoading: false,
    modelsOptions: [],
    modelsIsLoading: false,
    initialYearsOptions: buildDefaultYearsOptions(),
    initialYearsIsLoading: false,
    finalYearsOptions: [],
    finalYearsIsLoading: false,
    trimOptions: [],
    trimIsLoading: false,
  });

  const [currentVehicleOfInterest, setCurrentVehicleOfInterest] = useState(
    vehicleModel
  );

  const handleCurrentVehicleOfInterestValues = (name, value, label) => {
    if (name === 'modelYear') {
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        modelYear: value,
        productionYear: null,
        trim: null,
      }));
      handleClickItem(prevState => ({
        ...prevState,
        modelYear: value,
        productionYear: null,
        trim: null,
      }));
      setState(prevState => ({ ...prevState, trimOptions: [] }));
      return;
    }

    if (name === 'productionYear') {
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        productionYear: value,
        trim: null,
      }));
      handleClickItem(prevState => ({
        ...prevState,
        productionYear: value,
        trim: null,
      }));
      setState(prevState => ({ ...prevState, trimOptions: [] }));
      return;
    }

    // if (name === 'trim') {
    //   // const trimData = state.trimOptions.find(el => el.value === value)
    //   // const fuelLabel = trimData?.fuelId && state.fuelOptions.find(el => el.value === trimData?.fuelId).label
    //   // const bodyStyleLabel = trimData?.bodystyleId && state.bodystylesOptions.find(el => el.value === trimData?.bodystyleId).label
    //   // const transmissionLabel = trimData?.transmissionId && state.transmissionsOptions.find(el => el.value === trimData?.transmissionId).label
    //   // setCurrentVehicleOfInterest(prevState => ({ ...prevState, trim: { id: value, name: label }, bodystyle: trimData?.bodystyleId ? { id: trimData?.bodystyleId, name: bodyStyleLabel } : null, fuel: trimData?.fuelId ? { id: trimData?.fuelId, name: fuelLabel } : null, transmission: trimData?.transmissionId ? { id: trimData?.transmissionId, name: transmissionLabel } : null }))
    //   setCurrentVehicleOfInterest(prevState => ({ ...prevState, trim: { id: value, name: label } }))
    //   handleClickItem(prevState => ({ ...prevState, trim: { id: value, name: label } }))
    //   return
    // }

    if (name === 'make') {
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        make: value ? { id: value, name: label } : null,
        trim: null,
        model: null,
      }));
      handleClickItem(prevState => ({
        ...prevState,
        make: value ? { id: value, name: label } : null,
        trim: null,
        model: null,
      }));
      setState(prevState => ({ ...prevState, trimOptions: [] }));
      return;
    }

    if (label) {
      handleClickItem(prevState => ({
        ...prevState,
        [name]: value ? { id: value, name: label } : null,
      }));
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        [name]: value ? { id: value, name: label } : null,
      }));
      return;
    }
    handleClickItem(prevState => ({ ...prevState, [name]: value || null }));
    setCurrentVehicleOfInterest(prevState => ({
      ...prevState,
      [name]: value || null,
    }));
  };

  const handleChangeState = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const getMakes = useCallback(async () => {
    try {
      const response: any = await searchServices.getMakes('CAR');
      if (response.success) {
        const makeOptions = buildOptions(response.data);
        handleChangeState('makesOptions', makeOptions);
        if (dealerStore.currentDealer?.makeId) {
          setCurrentVehicleOfInterest(prevState => ({
            ...prevState,
            make: {
              id: dealerStore.currentDealer?.makeId,
              name: makeOptions.find(
                el => el.value === dealerStore.currentDealer?.makeId
              )?.label,
            },
            model: null,
            modelYear: null,
            productionYear: null,
          }));
          handleClickItem(prevState => ({
            ...prevState,
            make: {
              id: dealerStore.currentDealer?.makeId,
              name: makeOptions.find(
                el => el.value === dealerStore.currentDealer?.makeId
              )?.label,
            },
            model: null,
            modelYear: null,
            productionYear: null,
          }));
        }
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Marcas".'
        )
      );
    }
  }, [dispatch]);

  const getModelsByMake = useCallback(async () => {
    try {
      const response: any = await searchServices.getModels(
        'CAR',
        state.makesOptions.find(
          el => el.value === currentVehicleOfInterest.make.id
        )?.label
      );
      if (response.success) {
        handleChangeState('modelsOptions', buildOptions(response.data));
        setCurrentVehicleOfInterest(prevState => ({
          ...prevState,
          trim: null,
          model: null,
          modelYear: null,
          productionYear: null,
        }));
        setState(prevState => ({
          ...prevState,
          initialYearsOptions: buildDefaultYearsOptions(),
          finalYearsOptions: [],
        }));
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Modelos".'
        )
      );
    }
  }, [currentVehicleOfInterest.make?.id, dispatch, state.makesOptions]);

  const getInitialsYears = useCallback(async () => {
    try {
      const response: any = await searchServices.getProductionYears(
        'CAR',
        currentVehicleOfInterest.model?.id
      );
      if (response.success) {
        handleChangeState(
          'initialYearsOptions',
          buildOptions(
            (response?.data || { years: [] })?.years.map(el => ({
              id: el,
              name: el,
            }))
          )
        );
        setCurrentVehicleOfInterest(prevState => ({
          ...prevState,
          trim: null,
          modelYear: null,
          productionYear: null,
        }));
        setState(prevState => ({ ...prevState, finalYearsOptions: [] }));
      }
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções Ano inicial".'
        )
      );
    }
  }, [currentVehicleOfInterest.model?.id, dispatch]);

  const getTrims = useCallback(async () => {
    try {
      const response: any = await searchServices.getTrims(
        'CAR',
        currentVehicleOfInterest.model?.id,
        currentVehicleOfInterest.productionYear
      );
      if (response.success) {
        handleChangeState('trimOptions', buildOptions(response.data));
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções de Versões".'
        )
      );
    }
  }, [
    currentVehicleOfInterest.model?.id,
    currentVehicleOfInterest.productionYear,
    dispatch,
  ]);

  useEffect(() => {
    getMakes();
  }, []);

  //GET_MODEL_BY_MAKE
  useEffect(() => {
    if (currentVehicleOfInterest.make?.id) {
      getModelsByMake();
    }
  }, [getModelsByMake, currentVehicleOfInterest.make?.id]);

  //GET_TRIMS
  useEffect(() => {
    if (currentVehicleOfInterest.productionYear) {
      getTrims();
    }
  }, [getTrims, currentVehicleOfInterest.productionYear]);

  //GET_INITIAL_YEARS
  useEffect(() => {
    if (currentVehicleOfInterest.model?.id) {
      getInitialsYears();
    }
  }, [getInitialsYears, currentVehicleOfInterest.model?.id]);

  //MAKE_FINAL_YEARS_OPTIONS_BY_INITIAL_YEAR
  useEffect(() => {
    if (currentVehicleOfInterest.modelYear) {
      const lastPosition =
        state.initialYearsOptions?.[state.initialYearsOptions.length - 1];

      if (currentVehicleOfInterest.modelYear === lastPosition?.value) {
        setState(prevState => ({
          ...prevState,
          finalYearsOptions: [
            {
              value: Number(currentVehicleOfInterest.modelYear) + 1,
              label: Number(currentVehicleOfInterest.modelYear) + 1,
            },
          ],
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          finalYearsOptions: [
            {
              value: Number(currentVehicleOfInterest.modelYear) + 1,
              label: Number(currentVehicleOfInterest.modelYear) + 1,
            },
            {
              value: currentVehicleOfInterest.modelYear,
              label: currentVehicleOfInterest.modelYear,
            },
          ],
        }));
      }
    }
  }, [currentVehicleOfInterest.modelYear, state.initialYearsOptions]);

  const viewComponentProps = {
    makesOptions: state.makesOptions,
    modelsOptions: state.modelsOptions,
    initialYearsOptions: state.initialYearsOptions,
    finalYearsOptions: state.finalYearsOptions,
    trimOptions: state.trimOptions,
    currentVehicleOfInterest,
    handleCurrentVehicleOfInterestValues,
  };

  return <VehicleToTrimInfos {...viewComponentProps} />;
};

export default VehicleToTrimInfosContainer;
