import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import plateValidator from 'helpers/plateValidator';
import inventoryServices from 'services/inventoryServices';
import PlaceSearchComponent from './PlateSearchComponent';

const PlateSearch = ({ setVehicleByPlate }) => {
  const { currentDealer } = useSelector(state => state.dealerStore);

  const [plate, setPlate] = useState('');
  const [plateError, setPlateError] = useState(null);

  async function getVehicleInfoByPlate(dealerId, plate) {
    try {
      const response = await inventoryServices.getVehicleByPlate(
        dealerId,
        plate
      );
      if (response.success) {
        const { modelYear, productionYear, trim } = response.data;

        const vehicleModel = {
          make: { id: trim.makeId, name: trim.makeName },
          model: { id: trim.modelId, name: trim.modelName },
          modelYear: { id: modelYear, name: modelYear },
          productionYear: { id: productionYear, name: productionYear },
          trim: { id: trim.id, name: trim.name, molicarId: trim.molicarId},
          plate: plate,
        };

        setVehicleByPlate(vehicleModel);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const searchPlate = plateCurrent => {
    const isValidated = plateValidator(plateCurrent);
    setPlateError(!isValidated);

    if (!isValidated) return;

    getVehicleInfoByPlate(currentDealer.id, plateCurrent);
  };

  const handleOnClickSubmit = () => {
    searchPlate(plate);
  };

  const handleOnChangePlate = event => {
    const plateCurrent = event.target.value.toUpperCase();
    if (plateCurrent.length > 7) {
      return;
    }
    if (plateCurrent.length < 7) {
      setPlate(plateCurrent);
      return;
    }
    setPlate(plateCurrent);
    searchPlate(plateCurrent);
    return;
  };

  return (
    <PlaceSearchComponent
      handleOnChangePlate={handleOnChangePlate}
      plate={plate}
      plateError={plateError}
      handleOnClickSubmit={handleOnClickSubmit}
    />
  );
};

export default PlateSearch;
