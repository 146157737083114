import styled, { css } from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

const base = css`
  background: #ffff !important;
  color: #424242 !important;
  text-transform: inherit !important;
`;

const white = css`
  border: 1px solid #bdbdbd !important;
  color: #424242 !important;
`;

const red = css`
  border: 1px solid #f44336 !important;
  color: #f44336 !important;
`;

const secondary = css`
  border: 1px solid #3e68bd !important;
  color: #3e68bd !important;
`;

const purple = css`
  font-weight: 500;
  border: 1px solid #4443bc !important;
  color: #4443bc !important ;
`;

const primary = css`
  border: 1px solid ${props => props.theme.palette.primary.main} !important;
  color: ${props => props.theme.palette.primary.main} !important;
  height: 100% !important;
`;

export const OutlinedButton = styled(Button)`
  ${base}
  ${props => props.variant === 'default' && white};
  ${props => props.variant === 'secondary' && secondary};
  ${props => props.variant === 'red' && red};
  ${props => props.variant === 'purple' && purple};
  ${props => props.variant === 'primary' && primary}
`;
