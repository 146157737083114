import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionCreators from 'modules/financing/store/reducers/actionCreators';
import PersonalInfosForm from './PersonalInfosFullPayForm';
import SearchServices from 'services/searchServices';

const PersonalInfosFormFullPayContainer = ({
  formData,
  onChange,
  disableAllInputs = false,
  enableCopyButton = false,
  disableCpfCnpj = false,
}) => {
  //REDUX_HOOKS
  const {
    statesOptions,
    citiesOptions,
    streetTypeOptions,
    addressTypeOptions,
    careersOptions,
  } = useSelector(state => state.financing);
  const { genderOptions, civilStatusOptions } = useSelector(
    state => state.financing
  );
  const dispatch = useDispatch();
  //GET_OPTIONS
  useEffect(() => {
    dispatch(ActionCreators.getGendersOptions());
    dispatch(ActionCreators.getCivilStatusOptions());
    dispatch(ActionCreators.getCareersOptions());
    if (statesOptions.length < 2) {
      dispatch(ActionCreators.getStates());
    }
  }, []);
  //CITIES
  const [citiesOptionsState, setCitiesOptions] = useState(citiesOptions);

  //GET_ADDRESS_INFO_BY_ZIP
  const getAddresInfosByZipCode = async () => {
    try {
      const { success, data }: any = await SearchServices.getLocationByZipcode(
        formData['zipcode']
      );
      if (success) {
        if (data.uf) {
          dispatch(ActionCreators.getCitieByState(data.uf));
          onChange({ target: { name: 'cityStateId', value: data.uf } });
          onChange({ target: { name: 'address', value: data.logradouro } });
          onChange({ target: { name: 'cityId', value: data.cidadeId } });
          onChange({
            target: { name: 'streetType', value: data.tipoEndereco },
          });
          onChange({ target: { name: 'neighborhood', value: data.bairro } });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  //GET_ADDRES_INFOS_BY_CEP_ON_PRESS_ENTER
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      getAddresInfosByZipCode();
    }
  };
  //GET_OPTIONS
  useEffect(() => {
    dispatch(ActionCreators.getStreetTypeOptions());
    dispatch(ActionCreators.getAddressTypeOptions());
    dispatch(ActionCreators.getCareersOptions());
    dispatch(ActionCreators.getEmploymentsOptions());
  }, []);
  //UPDATE_CITIES_OPTIONS_ON_CITIES_OPTIONS_CHANGE
  useEffect(() => {
    setCitiesOptions(citiesOptions);
  }, [citiesOptions]);
  //GET_CITIES_OPTIONS_ON_CHANGE_STATE
  useEffect(() => {
    dispatch(ActionCreators.getCitieByState(formData['cityStateId']));
  }, [formData['cityStateId']]);

  const viewComponentProps = {
    genderOptions,
    civilStatusOptions,
    formData,
    onChange,
    disableAllInputs,
    streetTypeOptions,
    addressTypeOptions,
    statesOptions,
    citiesOptionsState,
    handleKeyPress,
    getAddresInfosByZipCode,
    careersOptions,
    enableCopyButton,
    disableCpfCnpj,
  };

  return <PersonalInfosForm {...viewComponentProps} />;
};

export default PersonalInfosFormFullPayContainer;
