import React from 'react';
import classNames from 'classnames';

import { Snackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Button from '../../mobiauto.web-ui-components.components.button/Button';
import Colors from '../../mobiauto.web-ui-components.components.colors/colors';
import Icon from '../../mobiauto.web-ui-components.components.icon';

const styles = theme => ({
  success: {
    backgroundColor: Colors.green.medium,
  },
  error: {
    backgroundColor: Colors.red.medium,
  },
  info: {
    backgroundColor: Colors.blue.medium,
  },
  warning: {
    backgroundColor: Colors.orange.medium,
  },
  iconVariant: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const variantIcon = {
  success: 'CheckCircle',
  warning: 'Warning',
  error: 'Error',
  info: 'Info',
};

const SnackbarMain = props => {
  const {
    classes,
    className,
    message,
    messageButton,
    onClose,
    variant,
    ...other
  } = props;
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon
            name={variantIcon[variant]}
            type="Rounded"
            color="white"
            className={classes.iconVariant}
          />
          {message}
          {messageButton}
        </span>
      }
      action={[
        <Button key="close" variant="unstyled" onClick={onClose}>
          <Icon
            name="Close"
            color="white"
            className={{ fontSize: 20, opacity: 0.9 }}
          />
        </Button>,
      ]}
      {...other}
    />
  );
};

const SnackbarContentWrapper = withStyles(styles)(SnackbarMain);

export default function CustomSnackbar({
  variant = 'success',
  message = 'This is a success message',
  autoHideDuration = 6000,
  open,
  onClose,
  vertical = 'bottom',
  horizontal = 'center',
  ...props
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <SnackbarContentWrapper
        onClose={onClose}
        variant={variant}
        message={message}
        messageButton={props.messageButton}
      />
    </Snackbar>
  );
}
