import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from 'bit/mobiauto.web-ui-components.components.icon';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { useChatContext } from 'pages/chats/ChatsContext';
import proposalServices from 'services/proposalServices';
import ConfirmationDialog from 'components/ConfirmationFileProposal';
import styled from 'styled-components';

const Wrapper = styled.div`
  > button {
    border-radius: 50%;
    padding: 4px;
  }
`;

function ArchiveProposal() {
  const dispatch = useDispatch();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { currentProposal, getCurrentProposal } = useChatContext();

  const archiveProposalById = async () => {
    try {
      const { success } = await proposalServices.archiveProposalById(
        currentProposal.dealerId,
        currentProposal.id
      );
      if (success) {
        getCurrentProposal();
        setOpenConfirmation(false);
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Lead arquivado com sucesso.'
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao arquivar a proposta.'
        )
      );
    }
  };

  return (
    <Wrapper>
      <ButtonBase onClick={() => setOpenConfirmation(true)}>
        <Tooltip title="Arquivar proposta">
          <Icon name="Archive" size="28" />
        </Tooltip>
      </ButtonBase>
      <ConfirmationDialog
        open={openConfirmation}
        handleClickCloseDialog={() => setOpenConfirmation(false)}
        handleClickConfirm={archiveProposalById}
      />
    </Wrapper>
  );
}

export default ArchiveProposal;
