import { all, call, takeLatest, put } from 'redux-saga/effects';
import resaleOfferPortalServices from 'modules/resale/services/OfferPortalServices';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import { Action } from 'types/ActionTypes';

function* getDealsBySop(action: Action) {
  try {
    yield put(ActionCreators.setDealsLoading(true));
    const response = yield call(
      resaleOfferPortalServices.getDealsBySop,
      action.payload.sop,
      action.payload.params
    );
    if (response.success) {
      yield put(ActionCreators.setDeals(response.data));
    } else {
      yield put(ActionCreators.setDeals([response.data]));
    }
    yield put(ActionCreators.setDealsLoading(false));
  } catch (e) {
    yield put(ActionCreators.setDealsLoading(false));
    console.log(e);
  }
}

export default all([takeLatest(ActionTypes.GET_DEALS_BY_SOP, getDealsBySop)]);
