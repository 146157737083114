import React from 'react';
import { useSelector } from 'react-redux';
import Wrapper from 'components/Wrapper';
import * as S from '../../styles';

const SummaryVehicle = () => {
  const { queueData } = useSelector(state => state.order);

  return (
    <Wrapper flex direction="column">
      <S.Text>{`${queueData?.trim?.name ?? ''} `}</S.Text>

      <Wrapper flex direction="row" gap={24}>
        <S.Text>
          Ano de fabricação:
          {`  ${queueData?.trim?.productionYear ?? ''} `}
        </S.Text>

        <S.Text>
          Ano do modelo:
          {`  ${queueData?.trim?.productionYear ?? ''}`}
        </S.Text>

        <S.Text>
          Cor externa:
          {`  ${queueData?.trim?.exteriorColor ?? ''}`}
        </S.Text>

        <S.Text>
          Cor interna:
          {`  ${queueData?.trim?.interiorColor ?? ''}`}
        </S.Text>
      </Wrapper>
    </Wrapper>
  );
};
export default SummaryVehicle;
