import React, { useState, useEffect } from 'react';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import TableItemRow from './components/TableItemRow';
import TableHead from './components/TableHead';
import { styles } from './styles';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const INITIAL_STATE = {
  order: 'asc',
  orderBy: 'clienteName',
  data: [],
  page: 0,
  rowsPerPage: 5,
};

function EnhancedTable({
  itensList = [],
  isLoading = false,
  handleClickListItem,
  handleClickEditListItem,
  classes,
}) {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    const temp = [];

    if (!itensList) {
      return;
    }

    itensList.map(item => temp.push(item));

    setState({ ...state, data: temp });
  }, [itensList]);

  //HANDLE_SORT_CHANGE
  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (state.orderBy === property && state.order === 'desc') {
      order = 'asc';
    }

    setState({ ...state, order, orderBy });
  };

  //CHANGE_PAGE
  const handleChangePage = (event, page) => {
    setState({ ...state, page });
  };

  //CHANGE_ROWS_PER_PAGE
  const handleChangeRowsPerPage = event => {
    setState({ ...state, rowsPerPage: event.target.value });
  };

  const { data, order, orderBy, rowsPerPage, page } = state;

  if (isLoading) {
    return (
      <div>
        <Progress large />
      </div>
    );
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(item => {
                return (
                  <TableItemRow
                    key={item.id}
                    order={item}
                    handleClickListItem={() => handleClickListItem(item)}
                    handleClickEditItem={() => handleClickEditListItem(item)}
                  />
                );
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default withStyles(styles)(EnhancedTable);
