import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';

import CloseIcon from '@material-ui/icons/Close';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import { FilterOption } from '../../../interfaces';

import * as S from './styles';

interface Props {
  disabled: boolean;
  disableSearch?: boolean;
  onChange: (options: FilterOption[]) => void;
  options: FilterOption[];
  selectedOptions: FilterOption[] | null;
}

export default function CheckboxTemplate({
  disabled,
  disableSearch = false,
  onChange,
  options,
  selectedOptions,
}: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentOptions, setCurrentOptions] = useState<FilterOption[]>([]);

  const selectedAllOptions =
    selectedOptions?.length > 0 && options?.length === selectedOptions?.length;

  const isChecked = option => {
    if (!selectedOptions?.length) return false;
    return (
      selectedOptions.findIndex(toFind => option?.value === toFind.value) >= 0
    );
  };

  const filterOptions = ({
    valueToSearch,
    optionsToSearch,
  }: {
    valueToSearch: string;
    optionsToSearch: FilterOption[];
  }) => {
    const formattedValue = valueToSearch.toLowerCase().trim();
    const filterRegex = new RegExp(formattedValue.replace(/\s+/, '|'));

    return optionsToSearch.filter(option => {
      const currentValue = option.label.toLowerCase().trim();
      return currentValue.search(filterRegex) !== -1;
    });
  };

  const getOptionsSelected = (currentOption: FilterOption) => {
    const copy = [...selectedOptions];

    const exists =
      copy.findIndex(option => option.value === currentOption.value) >= 0;

    if (exists) {
      return copy.filter(option => option.value !== currentOption.value) || [];
    }

    return [...copy, currentOption];
  };

  useEffect(() => {
    if (options?.length) setCurrentOptions(options);
  }, [options]);

  useEffect(() => {
    if (!searchValue?.length && options?.length) {
      return setCurrentOptions(options);
    }
    const filtered = filterOptions({
      valueToSearch: searchValue,
      optionsToSearch: currentOptions,
    });
    return setCurrentOptions(filtered);
  }, [searchValue]);

  return (
    <S.Container>
      {!disableSearch && (
        <S.SearchBox>
          <DebounceInput
            variant="outlined"
            label="Buscar"
            value={searchValue}
            onChange={({ target }) => setSearchValue(target.value)}
            debounceTimeout={250}
            disabled={disabled}
            element={TextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    style={{ fontSize: '1.5rem', color: '#757575' }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={() => setSearchValue('')}>
            <CloseIcon />
          </IconButton>
        </S.SearchBox>
      )}
      {currentOptions?.length ? (
        <FormGroup>
          <S.FormControlLabel
            label="Todos"
            className="select-all"
            control={<Checkbox />}
            checked={selectedAllOptions}
            disabled={disabled}
            onChange={({ target }) => {
              const { checked } = target as HTMLInputElement;
              onChange(checked ? options : []);
            }}
          />
          {currentOptions.map(option => (
            <S.FormControlLabel
              key={`${option.label}-${option.value}`}
              label={option.label}
              value={String(option.value)}
              checked={isChecked(option)}
              control={<Checkbox />}
              disabled={disabled}
              onChange={() => {
                const formatted = getOptionsSelected(option);
                onChange(formatted);
              }}
            />
          ))}
        </FormGroup>
      ) : (
        <S.EmptyMessage>Nenhum resultado encontrado</S.EmptyMessage>
      )}
    </S.Container>
  );
}
