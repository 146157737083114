import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import {
  Error as ErrorIcon,
  Add as AddIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

// Service import
import dealerServices from 'services/dealerServices';
import proposalServices from 'services/proposalServices';
import TextField from 'modules/financing/components/atoms/TextField';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

// Component import
import TagChip, { CheckedTagsProps } from 'components/atoms/TagChip/TagChip';
import GenericDialog from 'components/GenericDialog';
import AutoComplete from 'components/atoms/AutoComplete';
import Wrapper from 'components/Wrapper';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ModalCreateTags from './ModalCreateTags';
import ModalEditTags from './ModalEditTags';
import Confirmation from 'components/Confirmation';

// Style import
import {
  ContainerTags,
  ButtonAddNewTag,
  ContainerFilters,
  ButtonViewMore,
} from './styles';
import { highAccessUserTypes } from 'constants/userAccess';

export interface TagChipProps {
  open?: boolean;
  proposalId?: string | number;
  handleOnClickCloseModal?: () => void;
  callbackOnSuccess?: (tags: CheckedTagsProps[]) => void;
  currentTaskTags?: CheckedTagsProps[];
  proposalDealerId?: string | number;
}
const roleThatCanEdit = 'EDIT_TAG';
const roleThatCanDelete = 'DELETE_TAG';

const DISPLAY_FILTER_OPTIONS = [
  { label: 'Todas as tags', value: 1 },
  { label: 'Usadas', value: 2 },
  { label: 'Não usadas', value: 3 },
];

export const ModalTags = ({
  open = false,
  proposalId,
  handleOnClickCloseModal = null,
  callbackOnSuccess,
  currentTaskTags,
  proposalDealerId,
}: TagChipProps) => {
  // Hooks
  const currentUser = useSelector(state => state.userStore);
  const dispatch = useDispatch();

  // States
  const [loading, setLoading] = useState(false);
  const [displayOptionSelected, setDisplayOptionSelected] = useState();
  const [query, setQuery] = useState<string>('');
  const [checkedTags, setCheckedTags] = useState<CheckedTagsProps[]>(
    currentTaskTags || []
  );
  const [allTagList, setTagsList] = useState([]);
  const [openCreateTagModal, setOpenModal] = useState(false);
  const [openEditTagModal, setOpenEditModal] = useState(false);
  const [tagEdit, setTagEdit] = useState<CheckedTagsProps | null>(null);
  const [maxTagsToList, setMaxTagsToList] = useState(12);
  const [confirmationModalState, setConfirmationModalState] = useState({
    open: false,
    currentTagId: null,
  });

  const isHighAccessUserType = highAccessUserTypes.includes(
    currentUser?.userType
  );

  const clickOpenModal = () => setOpenModal(true);
  const onChangeQuery = event => setQuery(event.target.value);
  const handleOptionsFilter = selectedValue =>
    setDisplayOptionSelected(selectedValue);

  const filterTags = () => {
    let filteredTags = allTagList;
    if (displayOptionSelected === DISPLAY_FILTER_OPTIONS[1].value) {
      filteredTags = allTagList.filter(
        tag => !!currentTaskTags.find(taskTag => taskTag.id === tag.id)
      );
    }
    if (displayOptionSelected === DISPLAY_FILTER_OPTIONS[2].value) {
      filteredTags = allTagList.filter(
        tag => !currentTaskTags.find(taskTag => taskTag.id === tag.id)
      );
    }
    if (query) {
      filteredTags = filteredTags.filter(tag =>
        tag.name.toLowerCase().includes(query.toLowerCase())
      );
    }
    return filteredTags.slice(0, maxTagsToList);
  };

  const getAllTags = async () => {
    setLoading(true);
    try {
      const response: any = await dealerServices.getAllTags(proposalDealerId);
      if (response.success) {
        setTagsList(response.data);
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement('error', 'Ocorreu um erro.')
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteTag = async tagId => {
    try {
      const response: any = await dealerServices.deleteTag(
        proposalDealerId,
        tagId
      );
      if (response.success) {
        setConfirmationModalState({
          open: false,
          currentTagId: null,
        });
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Tag excluida com sucesso.'
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Falha ao tentar executar uma ação. Tente novamente.'
        )
      );
    } finally {
      getAllTags();
    }
  };

  const addNewTag = async () => {
    try {
      const response: any = await proposalServices.addTag(
        proposalDealerId,
        proposalId,
        checkedTags.map(el => el.id)
      );
      if (response.success) {
        callbackOnSuccess(checkedTags);
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Tag adicionada com sucesso!'
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Falha ao adicionar uma nova tag.'
        )
      );
    } finally {
      handleOnClickCloseModal();
    }
  };

  const handleOpenModalEditTag = tag => {
    setOpenEditModal(true);
    setTagEdit(tag);
  };

  useEffect(() => {
    getAllTags();
  }, []);

  const handleClickCheck = newTag => {
    if (checkedTags.find(tag => Number(tag.id) === Number(newTag.id))) {
      const fiteredCheckedTags = checkedTags?.filter(
        checkedTag => Number(checkedTag?.id) !== Number(newTag.id)
      );

      setCheckedTags(fiteredCheckedTags);
      return;
    }

    setCheckedTags(prevState => [...prevState, newTag]);
  };

  const currentUserCanEditTags = (currentUser?.profiles || []).includes(
    roleThatCanEdit
  );
  const currentUserCanDeleteTags = (currentUser?.profiles || []).includes(
    roleThatCanDelete
  );

  return (
    <GenericDialog
      open={open}
      onClose={handleOnClickCloseModal}
      title={'Adicionar tag'}
      maxWidth="sm"
      DialogAction={() => (
        <Wrapper
          flex
          justify="end"
          direction={'row'}
          gap={16}
          style={{ width: '100%' }}
        >
          <OutlinedButton onClick={handleOnClickCloseModal}>
            Cancelar
          </OutlinedButton>

          <ThemeButton onClick={addNewTag} disabled={!filterTags().length}>
            Confirmar
          </ThemeButton>
        </Wrapper>
      )}
    >
      <Box>
        <ContainerFilters>
          <TextField
            name="query"
            label="Buscar tag"
            value={query}
            onChange={onChangeQuery}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Buscar">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            mini
          />
          <AutoComplete
            name="display"
            label="Exibir"
            options={DISPLAY_FILTER_OPTIONS}
            value={
              displayOptionSelected
                ? {
                    value: displayOptionSelected,
                    label: DISPLAY_FILTER_OPTIONS.find(
                      originItem => originItem?.value === displayOptionSelected
                    )?.label,
                  }
                : null
            }
            onChange={event => handleOptionsFilter(event?.value)}
          />
        </ContainerFilters>
        {loading ? (
          <Box component="div" textAlign="center" padding="65px 0px">
            <CircularProgress />
          </Box>
        ) : filterTags().length ? (
          <ContainerTags>
            <Box
              component="div"
              color="#757575"
              fontWeight="400"
              fontSize="16px"
              marginBottom="3px"
            >
              Tags mais usadas:
            </Box>

            <ButtonAddNewTag onClick={clickOpenModal}>
              + Criar nova tag
            </ButtonAddNewTag>

            {filterTags().map(item => (
              <TagChip
                label={item?.name}
                key={item?.id}
                color={item?.color}
                tagId={item?.id}
                checkedTags={checkedTags}
                onClick={handleClickCheck}
                {...((isHighAccessUserType || currentUserCanEditTags) && {
                  editTag: () => handleOpenModalEditTag(item),
                })}
                {...((isHighAccessUserType || currentUserCanDeleteTags) && {
                  deleteTag: () =>
                    setConfirmationModalState({
                      open: true,
                      currentTagId: item?.id,
                    }),
                })}
              />
            ))}
          </ContainerTags>
        ) : (
          <Box component="div" textAlign="center" margin="10px 0px 50px 0px">
            <ErrorIcon fontSize="large" color="disabled" />
            <Box
              fontWeight="700"
              fontSize="20px"
              color="#424E67"
              lineHeight="120%"
              margin="0px 0px 7px 0px"
            >
              Tag não encontrada
            </Box>
            <Box
              fontWeight="400"
              fontSize="16px"
              color="#768198"
              lineHeight="120%"
              margin="10px 0px"
            >
              Talvez a tag não exista. Crie uma nova tag.
            </Box>
            <Button
              style={{
                color: '#4443BC',
                border: '1px solid #4443BC',
                padding: '0px 5px',
              }}
              onClick={clickOpenModal}
            >
              <AddIcon fontSize="large" /> Criar nova tag
            </Button>
          </Box>
        )}
        {maxTagsToList <= allTagList.length && (
          <Box component="div" textAlign="center">
            <ButtonViewMore
              onClick={() => setMaxTagsToList(prevState => prevState + 6)}
            >
              Ver Mais
            </ButtonViewMore>
          </Box>
        )}

        {openCreateTagModal && (
          <ModalCreateTags
            open={openCreateTagModal}
            handleCloseModal={() => setOpenModal(false)}
            callBackOnSaveSuccess={getAllTags}
            proposalDealerId={proposalDealerId}
          />
        )}

        {openEditTagModal && (
          <ModalEditTags
            open={openEditTagModal}
            handleCloseModal={() => setOpenEditModal(false)}
            tag={tagEdit}
            getAllTags={getAllTags}
            proposalDealerId={proposalDealerId}
          />
        )}
      </Box>
      {confirmationModalState.open && (
        <Confirmation
          open={confirmationModalState.open}
          handleClickCloseDialog={() =>
            setConfirmationModalState({ open: false, currentTagId: null })
          }
          handleClickConfirm={() =>
            deleteTag(confirmationModalState.currentTagId)
          }
        />
      )}
    </GenericDialog>
  );
};
