export default function applyPhoneMask(phone = ''): string {
  // Remove all non-digit characters
  const cleanPhone = phone.replace(/\D/g, '');

  let formattedPhone: string;

  // Check if the phone has 11 digits (cell phone with area code)
  if (cleanPhone.length === 11) {
    formattedPhone = cleanPhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  // Check if the phone has 10 digits (landline with area code)
  else if (cleanPhone.length === 10) {
    formattedPhone = cleanPhone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
  // Check if the phone has 9 digits (cell phone without area code)
  else if (cleanPhone.length === 9) {
    formattedPhone = cleanPhone.replace(/(\d{5})(\d{4})/, '$1-$2');
  }
  // Check if the phone has 8 digits (landline without area code)
  else if (cleanPhone.length === 8) {
    formattedPhone = cleanPhone.replace(/(\d{4})(\d{4})/, '$1-$2');
  }
  // Return the original phone number if it doesn't have an expected length
  else {
    formattedPhone = phone;
  }

  return formattedPhone;
}
