import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import applyPhoneMask from 'helpers/applyPhoneMask';
import formatCpfCnpj from 'helpers/formatCpfCnpj';
import Wrapper from 'components/Wrapper';
import * as S from '../../styles';

const ClientDataPJ = () => {
  const { queueData } = useSelector(state => state.order);
  const dataCompleted = queueData?.client?.formOem === 'FULL';

  return (
    <Wrapper flex direction="row">
      <Wrapper flex direction="column" width={'50%'}>
        <S.Text>
          Nome da empresa:
          {`  ${queueData?.client?.name ?? ''}`}
        </S.Text>

        <S.Text>
          CNPJ:
          {`  ${
            queueData?.client?.cpfCnpj
              ? formatCpfCnpj(queueData?.client?.cpfCnpj)
              : ''
          }`}
        </S.Text>

        <S.Text>
          E-mail:
          {`  ${queueData?.client?.email ?? ''}`}
        </S.Text>

        <S.Text>
          Telefone/Celular:
          {`  ${
            queueData?.client?.phone
              ? applyPhoneMask(queueData?.client?.phone)
              : ''
          }`}
        </S.Text>
      </Wrapper>

      <Wrapper flex direction="column">
        <S.Text notVisible={dataCompleted}>
          Nome Fantasia:
          {`  ${queueData?.client?.socialName ?? ''}`}
        </S.Text>
        <S.Text notVisible={dataCompleted}>
          Inscrição Estadual:
          {`  ${queueData?.client?.companyStateEnrollment ?? ''}`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Data de criação:
          {`  ${
            queueData?.client?.companyStartDate
              ? moment(queueData?.client?.companyStartDate).format('L')
              : ''
          }`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Telefone/Celular 2:
          {`  ${
            queueData?.client?.phone2
              ? applyPhoneMask(queueData?.client?.phone2)
              : ''
          }`}
        </S.Text>
      </Wrapper>
    </Wrapper>
  );
};
export default ClientDataPJ;
