import React from 'react';
import { useSelector } from 'react-redux'
import { ReactComponent as ChronometerIconBlue } from 'assets/chronometer-icon-blue.svg';
import FinancingInfos from './components/FinancingInfos'
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #757575;
  line-height:25px;
`;

const Financing = (): JSX.Element => {
  const { checkoutData } = useSelector(state => state.order)
  const hasFinancing = !!checkoutData?.financing
  if (hasFinancing) {
    return (
      <FinancingInfos financing={checkoutData?.financing} />
    )
  }
  return (
    <Container>
      <ChronometerIconBlue />
      <div>
        Aguarde um momento.
        <br />O financiamento está em análise
      </div>
    </Container>
  );
};

export default Financing;
