import React from 'react';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IModalStateManager {
  isOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
}

const useModalStateManager = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const open = () => { 
    setIsOpen(true);
  }

  const close = () => {
    setIsOpen(false);
  }

  return { isOpen, handleOpenModal, handleCloseModal, open, close };
};

export default useModalStateManager;
export type {IModalStateManager};
