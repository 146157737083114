import React from 'react';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const StyledTab = withStyles(theme => ({
  root: {
    fontSize: 14,
    borderBottom: '1px solid #CCCCCC',
    color: Colors.text.normal,
    textTransform: 'initial',
    '&:hover': {
      color: theme.palette.primary.main || Colors.primary.medium,
      opacity: 1,
    },
    '&:focus': {
      color: theme.palette.primary.main || Colors.primary.medium,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main || Colors.primary.medium,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

export default StyledTab;
