import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import PublishService from 'services/publishServices';
import uploadCsv from 'services/uploadCsv';

const _defaultInitialState = {
  deal: {},
  hasFetched: false,
  hasFailed: false,
  isLoading: false,
};

class PublishStore {
  constructor(initialState) {
    this.setInitialState(_defaultInitialState);
    this.rootStore = initialState;
  }

  setInitialState = ({ hasFetched, hasFailed, isLoading }) => {
    this.hasFetched = hasFetched;
    this.hasFailed = hasFailed;
    this.isLoading = isLoading;
  };

  setData = (data, hasFailed, hasFetched, isLoading) => {
    this.deal = data;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  getPublishes = async () => {
    const result = await PublishService.getPublishes();
    const { data, success } = result;
    if (success) {
      this.setData(data, false, true, false);
    } else {
      this.setData(null, true, false, false);
    }
  };

  publishDealsByCsv = async (items, isResale) => {
    const dealerId = this.rootStore.dealerStore.dealer.id;
    const showroom = isResale ? null : true;
    const resale = isResale ? true : null;
    const response = await PublishService.publishCsvDeals(
      dealerId,
      items,
      showroom,
      resale
    );
    return response;
  };

  uploadDealsCsv = async (file, setUploadProgress) => {
    const dealerId = this.rootStore.dealerStore.dealer.id;
    const { success, data } = await uploadCsv(
      file,
      dealerId,
      setUploadProgress
    );
    return { success, data };
  };
}

export default remotedev(
  decorate(PublishStore, {
    hasFetched: observable,
    hasFailed: observable,
    isLoading: observable,
    deal: observable,
    setInitialState: action,
    getInventories: action,
    setData: action,
    publishDealsByCsv: action,
    uploadDealsCsv: action,
  })
);
