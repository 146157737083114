import React from 'react';
import { connect } from 'store';

import Error500Component from './Error500Component';
import Error500Container from './Error500Container';
const Container = connect(Error500Container, ['userStore', 'dealerStore']);

function Error500Main(props) {
  return <Container viewComponent={Error500Component} {...props} />;
}

export default Error500Main;
