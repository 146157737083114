const creditScoreTypes = {
  APPROVED: { type: 'success', name: 'Aprovado' },
  IN_PROGRESS: { type: 'waiting', name: 'Aguardando' },
  IN_ANALYSE: { type: 'waiting', name: 'Em análise' },
  PRE_APPROVED: { type: 'success', name: 'Pré Aprovado' },
  PRE_ANALYSED: { type: 'success', name: 'Pré Analisado' },
  NOT_ACCEPTED: { type: 'error', name: 'Recusado' },
  NOT_APPLICABLE: { type: 'gray', name: 'Não aplicavel' },
  FINISHED_WITH_SOLD: { type: 'success', name: 'Finalizado com venda' },
  NOT_STARTED: { type: 'gray', name: 'Não Iniciado' },
  ERROR: { type: 'error', name: 'Erro' },
  REJECTED: { type: 'error', name: 'Rejeitado' },
  CANCELLED: { type: 'error', name: 'Cancelado' },
};

export default creditScoreTypes;
