import * as yup from 'yup';

const simpleContactSchema = yup.object().shape(
  {
    id: yup.number().optional(),
    name: yup
      .string()
      .required('Nome obrigatório')
      .default(''),
    email: yup
      .string()
      .email('E-mail inválido')
      .when('phone', {
        is: value => value && value.length,
        then: yup.string().nullable(),
        otherwise: yup.string().required('Telefone ou e-mail obrigatório'),
      })
      .nullable()
      .default(''),
    phone: yup
      .string()
      .when('email', {
        is: value => value && value.length,
        then: yup.string().nullable(),
        otherwise: yup
          .string()
          .nullable()
          .test('phone-check-min-values', 'Telefone inválido.', props => {
            const phoneNumbers = props ? props.replace(/\D/g, '') : null;
            if (phoneNumbers && phoneNumbers.length < 10) {
              return false;
            }
            return true;
          })
          .required('Telefone ou e-mail obrigatório'),
      })
      .nullable()
      .default(''),
    phone2: yup
      .string()
      .test('phone-check-min-values', 'Telefone inválido.', props => {
        const phoneNumbers = props ? props.replace(/\D/g, '') : null;
        if (phoneNumbers && phoneNumbers.length < 10) {
          return false;
        }
        return true;
      })
      .optional(),
    cpfCnpj: yup.string().optional(),
  },
  [['email', 'phone']]
);

export default simpleContactSchema;
