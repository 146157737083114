import { configure } from 'mobx';
import UserStore from './UserStore';
import UIStore from './UIStore';
import OptionsStore from './OptionsStore';
import SearchStore from './SearchStore';
import InventoryStore from './InventoryStore';
import PublishStore from './PublishStore';
import DealerStore from './DealerStore';
import ImageStore from './ImageStore';
import ProposalStore from './ProposalStore';
import PerformanceStore from './PerformanceStore';
import MobiFiStore from './MobiFiStore';
import DealerSiteStore from './DealerSiteStore';

export default class RootStore {
  constructor() {
    configure({ enforceActions: 'always' });
    this.userStore = new UserStore(this);
    this.dealerStore = new DealerStore(this);
    this.uiStore = new UIStore();
    this.optionsStore = new OptionsStore();
    this.searchStore = new SearchStore(this);
    this.inventoryStore = new InventoryStore(this);
    this.publishStore = new PublishStore(this);
    this.imageStore = new ImageStore();
    this.proposalStore = new ProposalStore(this);
    this.performanceStore = new PerformanceStore();
    this.mobiFiStore = new MobiFiStore();
    this.dealerSiteStore = new DealerSiteStore();
  }
}
