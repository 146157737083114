import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  font-size: 14px;
  color: #424242;
  align-items: center;
  height: 10px;

  .copyIcon {
    > svg {
      width: 18px;
    }
  }
`;

export const useStyles = makeStyles({
  root: {
    fontSize: 12,
    color: '#424242',
    marginLeft: 8,
    width: '60px',
  },
  input: {
    padding: 0,
  },
});
