/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom';
import './reactotron';
import App from './App';
import ErrorReport from 'services/ErrorReport';
import EnvVars from 'helpers/envVars';
import { initFirebase } from './push-notification';
import { swUrl } from 'serviceWorker';

const environment = EnvVars('ENV');

if (environment === 'DEVELOPMENT') {
  const { registerObserver } = require('react-perf-devtool');
  const options = {};

  window.observer = registerObserver(options);
}

if (environment !== 'DEVELOPMENT') {
  ErrorReport.init();
}
// ServiceWorker Register
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(swUrl)
    .then(function(registration) {
      console.log('>>> Registration successful, scope is:', registration.scope);
    })
    .catch(function(err) {
      console.log('>>> Service worker registration failed, error:', err);
    });
}

ReactDOM.render(<App />, document.getElementById('root'));
initFirebase();
