import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'OfferPortalServices';
const domain = EnvVars('API_SEARCH_BASE_URL');

export default class OfferPortalServices {
  static getDealsBySop = (sop, params) => {
    return request
      .get(`${domain}/deals/v1.0/sop/${sop}`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.createInventory()`));
  };
}
