import React from 'react';
import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import { Link } from 'react-router-dom';
const LinkExternal = styled.a`
  text-decoration: ${props => (props.hasLink ? 'underline' : 'none')};
  :link {
    color: ${Colors.primary.medium};
    text-decoration: ${props => (props.hasLink ? 'underline' : 'none')};
  }
  :visited {
    color: ${Colors.primary.medium};
    text-decoration: ${props => (props.hasLink ? 'underline' : 'none')};
  }
  :hover {
    color: ${Colors.primary.medium};
    text-decoration: ${props => (props.hasLink ? 'underline' : 'none')};
  }
  :active {
    color: ${Colors.primary.medium};
    text-decoration: ${props => (props.hasLink ? 'underline' : 'none')};
  }
`;

const LinkInternal = ({ children, hasLink, ...props }) => (
  <Link hasLink={hasLink} {...props}>
    {children}
  </Link>
);

export { LinkInternal, LinkExternal };
