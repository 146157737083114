import { StepLabel } from '@material-ui/core';
import styled, { css } from "styled-components";

export const componentStyles = theme => ({
  root: {
    width: '55%',
  },
  stepperRoot: {
    padding: 0,
  },
  stepperRootVertical: {
    backgroundColor: 'initial',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
  },
  label: {
    marginTop: `5px !important`,
  },
  stepLabelVertical: {
    marginBottom: '16px',
  },
  verticalLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    backgroundColor: '#fff',
    borderRadius: '4px 4px 0px 0px',
    borderBottom: '1px solid #ccc',
  },
  horizontalLabel: {
    fontWeight: 700
  },
  iconContainerAvailable: {
    '& :first-child': {
      backgroundColor: '#66c2b9'
    }
  }
});

export const customConnectorStyles = {
  alternativeLabel: {
    top: 15,
    left: `calc(-50% + 10px)`,
    right: `calc(50% + 10px)`,
  },
  active: {
    '& $line': {
      backgroundColor: '#009A8A',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#009A8A',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  }
};


export const StyledStepLabel = styled(StepLabel)`
  ${(props) => props.clickable && css`
    cursor: pointer !important;
  `}
`