import Request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'PublishService';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class PublishService {
  static getPublishes = () => {
    return Request.get(`${domain}/publishes/v1.0/all`).then(
      buildHTTPResponseHandler(`${serviceName}.getPublishes()`)
    );
  };

  static getPublishById = id => {
    return Request.get(`${domain}/publishes/v1.0/${id}`).then(
      buildHTTPResponseHandler(`${serviceName}.getPublishById()`)
    );
  };

  static createPublish = data => {
    return Request.post(`${domain}/publishes/v1.0`, data).then(
      buildHTTPResponseHandler(`${serviceName}.createPublish()`)
    );
  };

  static updatePublishById = (id, data) => {
    return Request.put(`${domain}/publishes/v1.0/${id}`, data).then(
      buildHTTPResponseHandler(`${serviceName}.updatePublishById()`)
    );
  };

  static deletePublish = id => {
    return Request.delete(`${domain}/publishes/v1.0/${id}`).then(
      buildHTTPResponseHandler(`${serviceName}.deletePublish()`)
    );
  };

  static getPublishesWithPagination = (page, size, sort) => {
    return Request.get(
      `${domain}/publishes/v1.0/all/pagination?page=${page}&size=${size}&sort=${sort}`
    ).then(
      buildHTTPResponseHandler(`${serviceName}.getPublishesWithPagination()`)
    );
  };

  static getPublishesByDeal = (dealerId, dealId) => {
    return Request.get(`${domain}/publishes/v2.0/deal/${dealId}`, {
      dealerId,
    }).then(buildHTTPResponseHandler(`${serviceName}.getPublishesByDeal()`));
  };

  static publishCsvDeals = (dealerId, csvDeals, showroom, resale) => {
    const params = {
      showroom,
      resale,
    };
    return Request.post(`${domain}/${dealerId}/dealbulk/v1.0`, csvDeals, {
      params,
    }).then(buildHTTPResponseHandler(`${serviceName}.publishCsvDeals()`));
  };
}
