import { Action } from 'types/ActionTypes';
import { ProposalOrderStep } from './../../types/ProposalOrder';
import ActionTypes from './actionTypes';
import { ProposalSubStep } from 'modules/order/pages/OrderSteps/QueueStep/QueueStep';
import {
  CancelInvoiceTypes,
  PaymentData,
  ProposalDigitalTypes,
  QueueDataTypes,
  DeliveryListTypes,
} from 'modules/order/store/reducers/index';

export default {
  resetOrderStore: () => {
    return { type: ActionTypes.RESET_STORE };
  },
  setLoadingOrder: (isLoading: boolean): Action => {
    return { type: ActionTypes.SET_LOADING_ORDER, payload: isLoading };
  },
  setLoadingOrderStatus: (isLoading: boolean): Action => {
    return { type: ActionTypes.SET_LOADING_ORDER_STATUS, payload: isLoading };
  },
  setDealerIdAndProposalId: ({ dealerId, proposalId }) => {
    return {
      type: ActionTypes.SET_DEALER_ID_PROPOSAL_ID,
      payload: { dealerId, proposalId },
    };
  },
  setPaymentData: (paymentData: PaymentData) => {
    return {
      type: ActionTypes.SET_PAYMENT_DATA,
      payload: paymentData,
    };
  },

  setQueueData: (queueDataTypes: QueueDataTypes) => {
    return {
      type: ActionTypes.SET_QUEUE_DATA,
      payload: queueDataTypes,
    };
  },
  setDeliveryList: (deliveryListTypes: DeliveryListTypes) => {
    return {
      type: ActionTypes.SET_DELIVERY_LIST,
      payload: deliveryListTypes,
    };
  },
  setProposalDigital: (proposalDigitalData: ProposalDigitalTypes) => {
    return {
      type: ActionTypes.SET_PROPOSAL_DIGITAL,
      payload: proposalDigitalData,
    };
  },
  sendProposalDigital: () => {
    return {
      type: ActionTypes.SEND_PROPOSAL_DIGITAL,
    };
  },
  getProposalDigital: () => {
    return {
      type: ActionTypes.GET_PROPOSAL_DIGITAL,
    };
  },
  setCancelInvoice: (cancelInvoiceData: CancelInvoiceTypes) => {
    return {
      type: ActionTypes.SET_CANCEL_INVOICE,
      payload: cancelInvoiceData,
    };
  },
  getCancelInvoice: () => {
    return {
      type: ActionTypes.GET_CANCEL_INVOICE,
    };
  },
  sendCancelInvoice: () => {
    return {
      type: ActionTypes.SEND_CANCEL_INVOICE,
    };
  },
  setSaleComments: (saleComments: string) => {
    return {
      type: ActionTypes.SET_SALE_COMMENTS,
      payload: saleComments,
    };
  },
  getOrderById: () => {
    return {
      type: ActionTypes.GET_ORDER_BY_ID,
    };
  },
  getQueueById: () => {
    return {
      type: ActionTypes.GET_QUEUE_BUY_ID,
    };
  },
  setCurrentOrder: orderData => {
    return {
      type: ActionTypes.SET_CURRENT_ORDER,
      payload: orderData,
    };
  },
  setCurrentBuyer: buyerData => {
    return {
      type: ActionTypes.SET_CURRENT_BUYER,
      payload: buyerData
    }
  },
  setCurrentVehicle: (vehicle): Action => {
    return { type: ActionTypes.SET_CURRENT_VEHICLE, payload: vehicle };
  },
  resetCurrentVehicle: (): Action => {
    return {
      type: ActionTypes.RESET_CURRENT_VEHICLE
    }
  },
  setActiveStep: (stepValue: number): Action => {
    return {
      type: ActionTypes.SET_ACTIVE_STEP,
      payload: stepValue,
    };
  },
  setCompletedSteps: (steps: number[]): Action => {
    return {
      type: ActionTypes.SET_COMPLETED_STEPS,
      payload: steps,
    };
  },
  setProposalStep: (stepValue: ProposalOrderStep | ProposalSubStep): Action => {
    return {
      type: ActionTypes.SET_PROPOSAL_STEP,
      payload: stepValue,
    };
  },
  //FINISH_ORDER
  finishOrder: () => {
    return {
      type: ActionTypes.FINISH_ORDER,
    };
  },
  //CHANGE CURRENT BUYER DATA
  changeCurrentClientData: (dataName, dataValue): Action => {
    return {
      type: ActionTypes.CHANGE_BUYER_CURRENT_DATA,
      payload: { dataName, dataValue },
    };
  },
  sendBuyerForm: (
    currentStep,
    callback = () => {
      return;
    }
  ): Action => {
    return {
      type: ActionTypes.SEND_BUYER_FORM,
      payload: { currentStep, callback },
    };
  },
  getAllFinancialInstitutionOptions: (): Action => {
    return {
      type: ActionTypes.GET_ALL_FINANCIAL_INSTITUTION_OPTIONS,
    };
  },
  setAllFinancialInstitutionOptions: (options): Action => {
    return {
      type: ActionTypes.SET_ALL_FINANCIAL_INSTITUTION_OPTIONS,
      payload: options,
    };
  },
  //CHECKOUT:
  getOrderStatus: (): Action => {
    return {
      type: ActionTypes.GET_ORDER_STATUS,
    };
  },
  setOrderStatus: (data): Action => {
    return {
      type: ActionTypes.SET_ORDER_STATUS,
      payload: data,
    };
  },
  setCheckoutOrder: isFinished => {
    return {
      type: ActionTypes.SET_CHECKOUT_ORDER,
      payload: isFinished,
    };
  },
  approvalProposal: (approvalStatus, refuseReason, callback) => {
    return {
      type: ActionTypes.APPROVE_PROPOSAL,
      payload: { approvalStatus, refuseReason, callback },
    };
  },
  setApproveIsLoading: isLoading => {
    return { type: ActionTypes.SET_APPROVE_IS_LOADING, payload: isLoading };
  },
  changePendenciesInfoById: (id, inputName, value) => {
    return {
      type: ActionTypes.CHANGE_PENDENCIES_INFOS_BY_ID,
      payload: { id, inputName, value },
    };
  },
  //CONFETTI
  setConfettiRun: run => {
    return {
      type: ActionTypes.SET_CONFETTI_RUN,
      payload: run,
    };
  },
};
