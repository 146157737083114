import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import FinancingActionCreators from 'modules/financing/store/reducers/actionCreators';
import ClientDataPF from './ClientDataPF';
import ClientDataPJ from './ClientDataPJ';

//FORM_STEP_MANAGE
const defaultExpansionState = (isPJ, enableClickOnStep = false) => {
  if (isPJ) {
    return {
      employmentFormOpen: {
        isCurrentStep: enableClickOnStep ? null : false,
        stepName: 'BUYER_FINANCING_COMPANY',
        currentStepNumber: 1,
        lastStep: null,
      },
      clientFormOpen: {
        isCurrentStep: enableClickOnStep ? null : false,
        stepName: 'BUYER_FINANCING_PERSON',
        currentStepNumber: 2,
        lastStep: 'employmentFormOpen',
      },
      addressFormOpen: {
        isCurrentStep: enableClickOnStep ? null : false,
        stepName: 'BUYER_FINANCING_ADDRESS',
        currentStepNumber: 3,
        lastStep: 'clientFormOpen',
      },
      referencesFormOpen: {
        isCurrentStep: enableClickOnStep ? null : false,
        stepName: 'BUYER_FINANCING_REFERENCE',
        currentStepNumber: 4,
        lastStep: 'addressFormOpen',
      },
      occupationFormOpen: {
        isCurrentStep: enableClickOnStep ? null : false,
        stepName: 'BUYER_FINANCING_OCCUPATION',
        currentStepNumber: 5,
        lastStep: 'referencesFormOpen',
      },
      inCashCompany: {
        isCurrentStep: null,
        stepName: 'BUYER_INCASH_COMPANY',
        currentStepNumber: 5,
        lastStep: '',
      },
    };
  }
  return {
    clientFormOpen: {
      isCurrentStep: enableClickOnStep ? null : false,
      stepName: 'BUYER_FINANCING_PERSON',
      currentStepNumber: 1,
      lastStep: null,
    },
    addressFormOpen: {
      isCurrentStep: enableClickOnStep ? null : false,
      stepName: 'BUYER_FINANCING_ADDRESS',
      currentStepNumber: 2,
      lastStep: 'clientFormOpen',
    },
    referencesFormOpen: {
      isCurrentStep: enableClickOnStep ? null : false,
      stepName: 'BUYER_FINANCING_REFERENCE',
      currentStepNumber: 3,
      lastStep: 'addressFormOpen',
    },
    occupationFormOpen: {
      isCurrentStep: enableClickOnStep ? null : false,
      stepName: 'BUYER_FINANCING_OCCUPATION',
      currentStepNumber: 4,
      lastStep: 'referencesFormOpen',
    },
    guarantorFormOpen: {
      isCurrentStep: enableClickOnStep ? null : false,
      stepName: 'BUYER_GUARANTOR',
      currentStepNumber: 5,
      lastStep: 'occupationFormOpen',
    },
    maritalInfosFormOpen: {
      isCurrentStep: enableClickOnStep ? null : false,
      stepName: 'BUYER_PERSON_MARITAL_PARTNER',
      currentStepNumber: 6,
      lastStep: 'guarantorFormOpen',
    },
    inCashPerson: {
      isCurrentStep: null,
      stepName: 'BUYER_INCASH_PERSON',
      currentStepNumber: 5,
      lastStep: '',
    },
  };
};

const ClientDataContainer = () => {
  //REDUX_HOOKS
  const dispatch = useDispatch();
  const { buyerCurrentData, isFinished, paymentData } = useSelector(
    state => state.order
  );
  const isPJ = buyerCurrentData.contactPersonType === 'PJ';
  const [
    guarantorCheckAndDisabledInputs,
    setGuarantorCheckAndDisabledInputs,
  ] = useState(false);
  //HANDLE_ALL_CHANGE_INPUT_FORM
  const handleChangeFormValues = event => {
    const { value, name } = event.target;
    if (name === 'maritalPartnerCpf') {
      const sanitizedValue = value.replace(/\D/g, '');
      dispatch(
        ActionCreators.changeCurrentClientData(
          'maritalPartnerCpf',
          sanitizedValue
        )
      );
      return;
    }
    dispatch(ActionCreators.changeCurrentClientData(name, value));
  };
  //STEP_MANAGE
  const [formStep, setFormStepCurrent] = useState<any>(
    defaultExpansionState(isPJ)
  );
  const defaultExpansionStatus = defaultExpansionState(isPJ);

  const changeCurrentStep = name => {
    setFormStepCurrent({
      ...defaultExpansionStatus,
      [name]: { ...defaultExpansionStatus[name], isCurrentStep: true },
    });
  };

  const closeAllForms = () => {
    setFormStepCurrent(defaultExpansionState(isPJ, true));
  };

  const handleChangeFormStep = (name, isBack = false) => {
    if (isBack) {
      changeCurrentStep(name);
    } else {
      if (isFinished) {
        changeCurrentStep(name);
      } else {
        dispatch(
          ActionCreators.sendBuyerForm(
            defaultExpansionStatus[defaultExpansionStatus[name].lastStep]
              ?.stepName,
            () => changeCurrentStep(name)
          )
        );
      }
    }
  };

  const handleClickFinishedInCashForm = currentStep => {
    dispatch(
      ActionCreators.sendBuyerForm(
        formStep[currentStep].stepName,
        closeAllForms
      )
    );
  };

  const handleClickFinishedStep = (name, isBack = false) => {
    if (isBack) {
      changeCurrentStep(name);
    } else {
      dispatch(
        ActionCreators.sendBuyerForm(
          isPJ
            ? formStep['occupationFormOpen'].stepName
            : formStep['maritalInfosFormOpen'].stepName,
          closeAllForms
        )
      );
    }
  };

  //OPEN_FIRST_EXPANSION_PANEL_ON_START_PAGE
  useEffect(() => {
    changeCurrentStep(Object.keys(formStep)[0]);
    //GET_STATES_ON_START_PAGE
    dispatch(FinancingActionCreators.getStates());
  }, []);

  const typeOfSale = paymentData.typeOfSale;

  const viewComponentProps = {
    buyerCurrentData,
    handleChangeFormValues,
    handleChangeFormStep,
    handleClickFinishedStep,
    handleClickFinishedInCashForm,
    formStep,
    isFinished,
    typeOfSale,
    guarantorCheckAndDisabledInputs,
    setGuarantorCheckAndDisabledInputs,
  };

  return (
    <div style={{ margin: 30 }}>
      {isPJ ? (
        <ClientDataPJ {...viewComponentProps} />
      ) : (
        <ClientDataPF {...viewComponentProps} />
      )}
    </div>
  );
};

export default ClientDataContainer;
