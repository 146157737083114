import React from 'react';
import CardBase from '../CardBase';
import CardIconWIthHourAndDescription from '../CardIconWIthHourAndDescription';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import getInitialsName from 'helpers/getInitialsName';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CallIcon from '@material-ui/icons/Call';
import TimerIcon from '@material-ui/icons/Timer';

type propTypes = {
  salesName: string;
  occupation: string;
  onClick?: () => void;
  leadCreatedAt?: string;
  interactionDate?: string;
  serviceTime?: string;
  buttonText?: string;
  enableChangeSeller?: boolean;
};

const CardSellerProfileAndLeadInfosComponent = ({
  salesName,
  occupation,
  leadCreatedAt,
  interactionDate,
  serviceTime,
  buttonText = 'Alterar Vendedor',
  onClick,
  enableChangeSeller,
}: propTypes) => {
  return (
    <CardBase m={3}>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        borderBottom="1px solid #E0E0E0"
        pt={2}
        pb={2}
        mb={2}
      >
        <Avatar
          alt="Remy Sharp"
          src="/broken-image.jpg"
          style={{ backgroundColor: '#7B6FEF', fontSize: '14px' }}
        >
          {getInitialsName(salesName)}
        </Avatar>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          gridGap="8px"
          pl={1}
          pr={1}
          color="#1C1B1F"
          mt={2}
        >
          <Box component="h3" fontSize="18px" fontWeight="700" m={0}>
            {salesName}
          </Box>
          <Box component="p" fontSize="14px" m={0}>
            {occupation}
          </Box>
        </Box>
        {enableChangeSeller && (
          <Box
            component="button"
            fontSize="14px"
            color="#017569"
            onClick={onClick}
            border="0"
            bgcolor="transparent"
            style={{ cursor: 'pointer' }}
            mt={2}
          >
            {buttonText}
          </Box>
        )}
      </Box>
      <Box component="div" display="flex" flexDirection="column" gridGap="8px">
        {leadCreatedAt && (
          <CardIconWIthHourAndDescription
            Icon={<CalendarTodayIcon />}
            date={leadCreatedAt}
            description="Dia em que recebeu o lead"
          />
        )}
        {interactionDate && (
          <CardIconWIthHourAndDescription
            Icon={<CallIcon />}
            date={interactionDate}
            description="Data da última interação"
          />
        )}
        {serviceTime && (
          <CardIconWIthHourAndDescription
            Icon={<TimerIcon />}
            date={serviceTime}
            description="Tempo médio de atendimento"
          />
        )}
      </Box>
    </CardBase>
  );
};

export default CardSellerProfileAndLeadInfosComponent;
