import React from 'react';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

// Style import
import { Container } from './styles';

interface ImageProps {
  source: string;
}

export function Image({ source }: ImageProps) {
  return (
    <Container style={{ paddingRight: 0 }}>
      <CameraAltIcon />
      <p>Foto</p>

      <img src={source} alt="Foto de uma conversa" />
    </Container>
  );
}