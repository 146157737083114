import styled from 'styled-components';

import MUIButton from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import MUIStepper from '@material-ui/core/Stepper';
import { CreateCSSProperties, withStyles } from '@material-ui/styles';
import TextField from 'modules/financing/components/atoms/TextField';

export const Header = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & > .title {
    color: #424242;
    font-family: 'Poppins Regular';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: -0.4px;
  }
`;

export const Dialog = withStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      fontFamily: 'Poppins Regular',
      fontSize: '1rem',
      padding: '1.5rem',
      lineHeight: '1.5rem',
      color: '#757575',
      gap: '1.5rem',
      overflow: 'hidden',
    },
    '& .MuiDialogTitle-root': {
      padding: '0',
      '& .MuiTypography-root': {
        fontFamily: 'Poppins Regular',
        fontSize: '1.375rem',
        lineHeight: '2rem',
        color: '#464646',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0',
      overflow: 'hidden scroll !important',
      width: '100%',
      float: 'left',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
    },
    '& p': {
      margin: 0,
    },
  },
}))(MUIDialog);

const bottomButtonsStyles: CreateCSSProperties<{}> = {
  height: '2.5rem',
  fontSize: '0.875rem',
  fontFamily: 'Poppins Medium',
  fontWeight: 500,
  textTransform: 'none',
  boxShadow: 'none',
  padding: '0 1.5rem',
};

export const CancelButton = withStyles(() => ({
  root: {
    ...bottomButtonsStyles,
    color: '#424242',
    backgroundColor: 'transparent',
  },
}))(MUIButton);

export const SaveAndCloseButton = withStyles(() => ({
  root: {
    ...bottomButtonsStyles,
    color: '#4443BC',
    backgroundColor: 'transparent',
    border: '1px solid #4443BC',
  },
}))(MUIButton);

export const ConfirmButton = withStyles(() => ({
  root: {
    ...bottomButtonsStyles,
    color: '#FFF',
    backgroundColor: '#00B4AA',
    '&:hover': {
      backgroundColor: '#00B4AA',
    },
    '&.Mui-disabled': {
      color: '#FFF',
      opacity: '0.5',
    },
  },
}))(MUIButton);

export const Stepper = withStyles(() => ({
  root: {
    padding: '0',
    margin: '1rem auto',
    width: '40%',
    '& .MuiStepConnector-alternativeLabel': {
      top: '20px',
      '& .MuiStepConnector-line': {
        borderColor: '#E0E0E0',
      },
    },
    '& .MuiStepLabel-iconContainer': {
      width: '2.5rem',
      height: '2.5rem',
      background: '#F5F5F5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      color: '#757575',
      fontFamily: 'Poppins Medium',
      fontSize: '1rem',
      fontWeight: '500',
    },
    '& .MuiStepLabel-labelContainer .MuiTypography-root': {
      marginTop: '0.5rem',
      color: '#757575',
      fontFamily: 'Poppins Medium',
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.375rem',
    },
    '& .MuiStep-root': {
      '&.actived, &.completed': {
        '& .MuiStepLabel-iconContainer': {
          background: '#00B4AA',
          color: '#FFF',
        },
        '& .MuiStepLabel-labelContainer .MuiTypography-root': {
          color: '#00B4AA',
        },
        '& .MuiStepConnector-alternativeLabel .MuiStepConnector-line': {
          borderColor: '#00B4AA',
        },
      },
    },
  },
}))(MUIStepper);

export const CustomTextField = styled(TextField)`
  .MuiInputLabel-root,
  .MuiInputBase-input {
    font-family: 'Poppins Regular';
  }
`;

export const KmField = styled(TextField)`
  background-color: #ffffff !important;

  .MuiInputBase-input:focus {
    background-color: '#FFFFF';
  }

  .MuiInputBase-root {
    height: 48px;
  }
`;
