import styled from 'styled-components';

export const Title = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  margin: 24px auto;
`;

export const BoxTime = styled.div`
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 72px;
  padding: 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: ${props => (props.green ? '#00B4AA' : 'transparent')};
  margin: 0;
  width: 100px;
  color: #ffffff;
  border-bottom: 5px #ffffff solid;
  :hover {
    background: ${props =>
      props.lastColumn || props.disableCellGreen ? 'transparent' : '#00b4aa'};
    cursor: ${props => (props.lastColumn ? '' : 'pointer')};
  }
`;

export const BoxTimeDisable = styled.div`
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 72px;
  padding: 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: #f5f5f5;
  margin: 0;

  width: 100px;
  border-top: 5px #757575 solid;
  border-bottom: 5px #ffffff solid;
`;

export const BoxTimeScheduling = styled.div`
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 72px;
  padding: 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: #fff5e5;
  margin: 0;
  color: #ff9800;
  width: 100px;
  border-top: 5px #ff9800 solid;
  border-bottom: 5px #ffffff solid;
`;
