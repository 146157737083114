import { applyMiddleware, compose } from 'redux';
import middlewares from 'helpers/middlewares';

import { setBaseUrlWithDealeridAPiMiddleWare } from 'modules/financial/store/tempDealer/middlewares';

const allMiddleWares = [...middlewares, setBaseUrlWithDealeridAPiMiddleWare];

const enhancer =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...allMiddleWares), global.tron.createEnhancer())
    : applyMiddleware(...allMiddleWares);

export default enhancer;
