import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import MessageTypes from 'constants/MessageTypes';

const serviceName = 'ResaleNegotiationServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default {
  getCurrentProposalById: function(dealerId, proposalId) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getCurrentProposalById`));
  },

  getBuyerCurrentProposalById: function(dealerId, proposalId) {
    return request
      .get(
        `${domain}/buyer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getBuyerCurrentProposalById`)
      );
  },

  getSalesNegotiations: function(dealerId, dealId, archived = false) {
    const params = { archived };
    return request
      .get(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${dealId}/negotiations`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getSalesNegotiations`));
  },

  getBuyerNegotiations: function(dealerId, dealId, archived = false) {
    const params = { archived };
    return request
      .get(
        `${domain}/buyer/${dealerId}/corporativesale/proposal/v1.0/${dealId}/negotiations`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getBuyerNegotiations`));
  },

  getSalesComboNegotiations: function(dealerId, dealId, archived = false) {
    const params = { archived };
    return request
      .get(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${dealId}/negotiations/combo`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getSalesNegotiations`));
  },

  setForNegotiationArchive: function(dealerId, proposalId, negotiationId) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/negotiation/archive`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.setForNegotiationArchive`)
      );
  },

  getInteractionsByProposal: function(
    dealerId,
    proposalId,
    negotiationId,
    lastInteractionId
  ) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/interactions`,
        { lastInteractionId }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getInteractionsByProposal`)
      );
  },

  sendInteraction: function(dealerId, proposalId, data, mediaType) {
    if (mediaType === MessageTypes.TEXT) {
      return request
        .post(
          `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${data.negotiationId}/interaction`,
          data
        )
        .then(buildHTTPResponseHandler(`${serviceName}.sendInteraction`));
    } else {
      // AUDIO/IMAGEM
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('groupBy', data.dateISO);
      return request
        .post(
          `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/${data.negotiationId}/interaction/${mediaType}`,
          formData,
          { fromBuyer: data.fromBuyer, groupBy: data.dateISO }
        )
        .then(buildHTTPResponseHandler(`${serviceName}.sendInteraction`));
    }
  },

  sendBuyerInteraction: function(dealerId, proposalId, data, mediaType) {
    if (mediaType === MessageTypes.TEXT) {
      return request
        .post(
          `${domain}/buyer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${data.negotiationId}/interaction`,
          data
        )
        .then(buildHTTPResponseHandler(`${serviceName}.sendInteraction`));
    } else {
      // AUDIO/IMAGEM
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('groupBy', data.dateISO);
      return request
        .post(
          `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/${data.negotiationId}/interaction/${mediaType}`,
          formData,
          { fromBuyer: data.fromBuyer, groupBy: data.dateISO }
        )
        .then(buildHTTPResponseHandler(`${serviceName}.sendInteraction`));
    }
  },

  sendAcceptBid: function(dealerId, negotiationId, proposalId) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/acceptBid`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendAcceptBid`));
  },

  sendBuyerAcceptBid: function(dealerId, negotiationId, proposalId) {
    return request
      .put(
        `${domain}/buyer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/acceptBid`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendAcceptBid`));
  },

  sendRefuseBid: function(dealerId, negotiationId, proposalId) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/refuseBid`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendRefuseBid`));
  },

  sendBuyerRefuseBid: function(dealerId, negotiationId, proposalId) {
    return request
      .put(
        `${domain}/buyer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/refuseBid`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendRefuseBid`));
  },

  sendNewBid: function(dealerId, negotiationObject) {
    const body = negotiationObject;
    return request
      .post(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/bid`,
        body
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendNewBid`));
  },

  sendBuyerNewBid: function(dealerId, negotiationObject) {
    const body = negotiationObject;
    return request
      .post(
        `${domain}/buyer/${dealerId}/corporativesale/proposal/v1.0/bid`,
        body
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendNewBid`));
  },

  getHigherCurrentBid: function(dealerId, dealId, bid) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${dealId}/higherThanTheCurrentBid`,
        { bid }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getHigherCurrentBid`));
  },

  visualizeMessages: function(dealerId, negotiationId, proposalId, fromBuyer) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/visualize/${fromBuyer}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.visualizeMessages`));
  },

  updateProposal: function(dealerId, proposalId, vehicles) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/vehicles`,
        vehicles
      )
      .then(buildHTTPResponseHandler(`${serviceName}.visualizeMessages`));
  },

  setSellerToNegotiation: function(
    dealerId,
    proposalId,
    negotiationId,
    sellerId
  ) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/${negotiationId}/seller/${sellerId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.setSellerToNegotiation`));
  },

  getLastUpdatedDateTime: function(dealerId, proposalId) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/lastupdated`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getLastUpdatedDateTime`));
  },

  getBuyerLastUpdatedDateTime: function(dealerId, proposalId) {
    return request
      .get(
        `${domain}/buyer/${dealerId}/corporativesale/proposal/v1.0/${proposalId}/lastupdated`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getLastUpdatedDateTime`));
  },
};
