import React from 'react';

import { Modal, Box, Typography } from '@material-ui/core';

import ListInvoiceCard from 'components/atoms/InvoiceCard/ListInvoiceCard';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import OutlinedButton from 'components/atoms/OutlinedButton';

import CloseIcon from '@material-ui/icons/Close';

import { useSelector, useDispatch } from 'react-redux';
import ActionCreators from 'modules/order/store/reducers/actionCreators';

const ModalInvoiceComponent = ({
  isOpen,
  handleOpenCancelInvoce,
  handleCloseModalInvoce,
}) => {
  const { cancelInvoice }: any = useSelector(state => state?.order);
  const dispatch = useDispatch();

  const handleCancel = () => {
    handleCloseModalInvoce();
    handleOpenCancelInvoce();
  };

  const handleRedux = {
    GET: () => dispatch(ActionCreators.getCancelInvoice()),
  };

  React.useEffect(() => {
    handleRedux.GET();
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModalInvoce}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        component="div"
        style={{ backgroundColor: '#fff', outline: 'none' }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={4}
        borderRadius="4px"
        width="800px"
        height="auto"
      >
        <Box component="div" display="flex" flexDirection="column">
          <Box component="div" display="flex" justifyContent="space-between">
            <Typography variant="h5">Nota Fiscais</Typography>
            <HypertextButton onClick={handleCloseModalInvoce}>
              {' '}
              <CloseIcon />
            </HypertextButton>
          </Box>
          <Box component="div">
            {!cancelInvoice?.data && (
              <Box component="div" textAlign="center">
                <h3 style={{ color: '#424242', fontWeight: 'bold' }}>
                  Não a notas fiscais disponiveis.
                </h3>
              </Box>
            )}
            {cancelInvoice?.data?.notaFiscalDeEntrada && (
              <ListInvoiceCard
                title={'Nota de Entrada'}
                invoice={cancelInvoice?.data?.notaFiscalDeEntrada}
              />
            )}
            {cancelInvoice?.data?.notaFiscalDeSaida && (
              <ListInvoiceCard
                title={'Nota de Saída'}
                invoice={cancelInvoice?.data?.notaFiscalDeSaida}
              />
            )}
          </Box>
        </Box>
        <Box component="div" display="flex" justifyContent="flex-end" mt={2}>
          {cancelInvoice?.data?.cancellable && (
            <OutlinedButton variant="red" onClick={handleCancel}>
              Cancelar Pedido
            </OutlinedButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalInvoiceComponent;
