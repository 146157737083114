import React from 'react';
import Wrapper from 'components/Wrapper';
import TextComponent from 'modules/mobiOne/components/Text';
import { ReactComponent as WarningIcon } from 'assets/warning_amber.svg';
import { EvaluationInProgress } from 'pages/chats/components/SideBarComponents/Evaluation/sideBarEvaluationTypes';
import EvaluationInProgressItem from './EvaluationInProgressItem';

interface EvaluationsProgressCardProps {
  evaluationsInProgress: EvaluationInProgress[];
}

const EvaluationsProgressCard: React.FC<EvaluationsProgressCardProps> = ({
  evaluationsInProgress,
}) => {
  const evaluationInProgressCardTitle = evaluationsInProgress.length > 1
  ? 'Encontramos mais de uma avaliação para esse veículo'
  : 'Encontramos uma avaliação para esse veículo'

  return (
    <Wrapper
      width="100%"
      boxSizing="border-box"
      r="sm"
      borderSize={1}
      borderStyle="solid"
      borderColor="#ED6C0280"
      backgroundColor="#ED6C021F"
      p={16}
    >
      <Wrapper flex align="center" mb={14}>
        <Wrapper
          width="36px"
          height="36px"
          flex
          align="center"
          justify="center"
          backgroundColor="#FF9800"
          r="lg"
          mr="sm"
        >
          <WarningIcon />
        </Wrapper>
        <TextComponent fontSize="16px" fontWeight={400}>
          {evaluationInProgressCardTitle}
        </TextComponent>
      </Wrapper>
      {evaluationsInProgress?.map(evaluationInProgress => (
        <EvaluationInProgressItem
          key={evaluationInProgress.id}
          evaluationInProgress={evaluationInProgress}
        />
      ))}
    </Wrapper>
  );
};

export default EvaluationsProgressCard;
