import React from 'react';
import { useSelector } from 'react-redux';
import TicketInfos from './components/TicketInfos';
import styled from 'styled-components';
import isEmpty from 'helpers/isEmpty';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #757575;
  line-height: 25px;
`;
const Ticket = (): JSX.Element => {
  const { bankSlips } = useSelector(state => state.order)

  return (
    <>
      {isEmpty(bankSlips) ? (
        <Container>
          <div>Não há boleto para imprimir</div>
        </Container>
      ) : (
        bankSlips?.map(slip => {
          return <TicketInfos key={slip.id} ticket={slip} />;
        })
      )}
    </>
  );
};

export default Ticket;
