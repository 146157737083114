import React from 'react';
import {
  NewReleasesOutlined,
  StarsRounded,
  AttachMoney,
  StoreOutlined,
  LocalCarWash,
  CheckCircle
} from '@material-ui/icons';

// Style import
import { Wrapper, Flex, IconContainer, DoneIconWrapper } from '../styles';

const itens = [
  { key: 'new_releases', icon: NewReleasesOutlined, done: true },
  { key: 'stars', icon: StarsRounded },
  { key: 'attach_money', icon: AttachMoney, done: true },
  { key: 'car_repair', icon: LocalCarWash },
  { key: 'store_front', icon: StoreOutlined },
]

export function NegotiationProcess() {
  return (
    <Wrapper>
      <span>Processo de negociação</span>

      <Flex>
        {itens.map(({ icon: Icon, key, done }) => (
          <IconContainer key={key} done={done}>
            {done && (
              <DoneIconWrapper>
                <CheckCircle color="primary" fontSize="small" />
              </DoneIconWrapper>
            )}
            
            <Icon style={{ color: done ? "#00B4AA" : "#757575" }} />
          </IconContainer>
        ))}
      </Flex>
    </Wrapper>
  );
}
