import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableDataGrid from 'components/atoms/TableDataGrid';
import SearchInput from 'modules/financing/components/atoms/SearchInput';

const ChooseModel0Km = ({ modelsOptions, headCells, makeTableRows, query, handleChangeQuery }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SearchInput
          value={query}
          onChange={event => handleChangeQuery(event.target.value)}
          placeholder="Busque por modelo..."
          searchFunction={() => null}
          onKeyPress={() => null}
        />
      </Grid>
      <Grid item xs={12}>
        <Box component="div" border="1px solid #e0e0e0" borderRadius="4px">
          {!!modelsOptions.length && (
            <TableDataGrid
              pageSize={5}
              rows={makeTableRows(modelsOptions)}
              columns={headCells}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChooseModel0Km;
