import { getters, httpCallStatus } from '../acctionOptions';

const MODULE = "tempDealer"

export default {
//REDUCERS
    SAVE_DEALER_INFOS: `@${MODULE}/SAVE_DEALER_INFOS`,
    SAVE_BANKACCOUNT: `@${MODULE}/SAVE_BANKACCOUNT`,
    SAVE_SELLERS_IDS: `@${MODULE}/SAVE_SELLERS_IDS`,
    SAVE_PAYMENT_METHOD: `@${MODULE}/SAVE_PAYMENT_METHOD`,
    SAVE_CONTACTS: `@${MODULE}/SAVE_CONTACTS`,
    
//SAGAS
    LOAD_DEALER_DATA_OPTIONS: `@${MODULE}/LOAD_DEALER_DATA_OPTIONS_${httpCallStatus.REQUEST}`,
    GET_DEALER_CONTACTS_REQUEST: `@${MODULE}/GET_DEALER_CONTACTS_REQUEST_${httpCallStatus.REQUEST}`,
};