import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import moment from 'moment';
import formatAmount from 'helpers/formatAmount';
import ProposalCard from '../SimulationCard/components/ProposalCard';
import { ReactComponent as LogoBancoPan } from 'assets/Logo-BancoPAN.svg';

interface Simulacao {
  prazo?: number;
  prazoPreferencial?: boolean;
  decisaoCredito?: string;
  mensagemCredito?: string;
  valorParcela?: number;
  entradaSolicitada?: number;
  entradaAnalisada?: number;
  entradaMinimaAnalisada?: number;
  mensagemUsuario?: string;
  error?: string;
}

interface VehicleSimulation {
  date: number;
  make: string;
  model: string;
  trim: string;
  modelYear: number;
  productionYear: number;
  plate: string;
  dealId: number;
  vehiclePrice: number;
  inputValue: number;
  simulacoes: Simulacao[];
  vehicle?: {
    price: number;
  };
}

interface PanSimulationCardProps {
  simulationIndex: number;
  vehicleSimulation: VehicleSimulation;
}

const PanSimulationCard = ({
  vehicleSimulation,
  simulationIndex,
}: PanSimulationCardProps) => {
  return (
    <Box
      component="div"
      display="flex"
      flex={1}
      flexDirection="column"
      borderRadius="4px"
      border="1px solid #E0E0E0"
      padding="16px"
      mb="16px"
    >
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Box component="div">
          <Box
            component="div"
            fontSize={'14px'}
            lineHeight="12px"
            color="#009A8A"
            fontFamily={'Poppins Bold'}
          >
            Simulação {simulationIndex + 1}
          </Box>
          <Box
            component="div"
            color={'#757575'}
            fontSize={'12px'}
            lineHeight="18px"
          >
            {vehicleSimulation?.date &&
              moment(vehicleSimulation?.date).format('DD/MM/YYYY [ás] HH:mm')}
          </Box>
        </Box>
        <Box>
          <LogoBancoPan />
        </Box>
      </Box>
      <Divider />
      <Box component="div" mb={2} mt={2}>
        <Box component="div" fontSize={'14px'} fontFamily={'Poppins Bold'}>
          Condições
        </Box>
        <Box component="div" color={'#757575'} lineHeight="18px">
          Entrada:{' '}
          <Box component="span" color={'#424242'}>
            {formatAmount(vehicleSimulation?.inputValue, 'PT-BR', 'R$', true)}
          </Box>
        </Box>
        {(vehicleSimulation?.vehiclePrice ||
          vehicleSimulation?.vehicle?.price) &&
          vehicleSimulation?.inputValue && (
            <Box component="div" color={'#757575'} lineHeight="18px">
              Valor a ser liberado:{' '}
              <Box component="span" color={'#424242'}>
                {formatAmount(
                  (vehicleSimulation?.vehiclePrice ||
                    vehicleSimulation?.vehicle?.price) -
                    vehicleSimulation?.inputValue,
                  'PT-BR',
                  'R$',
                  true
                )}
              </Box>
            </Box>
          )}
      </Box>
      <Box component="div">
        <Box component="div" fontSize={'14px'} fontFamily={'Poppins Bold'}>
          Propostas
        </Box>
        <Grid container spacing={1}>
          {vehicleSimulation?.simulacoes?.map((simulacao, index) => {
            const text = simulacao?.valorParcela
              ? `${simulacao?.prazo}x ${`de ${formatAmount(
                  simulacao?.valorParcela,
                  'PT-BR',
                  'R$'
                )}`} `
              : '';

            return (
              <ProposalCard
                key={index}
                text={text}
                error={simulacao?.error}
                creditStatus={simulacao?.mensagemUsuario}
              />
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default PanSimulationCard;
