import { isValid as isValidCnpj } from '@fnando/cnpj';
import { isValid as isValidCpf, strip } from '@fnando/cpf';

export default function validateCpfCnpj(cpfCnpjWithMask: string) {
  const cpfCnpj = strip(cpfCnpjWithMask);

  if (cpfCnpj.length === 11) return isValidCpf(cpfCnpj);

  if (cpfCnpj.length === 14) return isValidCnpj(cpfCnpj);

  return false;
}
