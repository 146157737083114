import React from 'react';

import { Box, Typography } from '@material-ui/core';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import BoxConsentDefault from 'components/BoxConsent/BoxConsentDefault';
import Chip from 'components/atoms/Chip';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import getImage from 'components/getImage';

import { statusType, IBoxConsent } from '../BoxConsentWrapper.types';

const styles = {
  consent: {
    backgroundColor: '#F0F6F6',
    borderColor: '#7DCAC2',
  },
  textColorBlack: {
    color: '#424242',
    fontWeight: 500,
    fontSize: '18px',
  },
  textColorGray: {
    color: '#757575',
    fontSize: '18px',
  },
  idBorder: {
    borderBottom: '5px solid #009A8A',
  },
  badge: {},
};

const BoxWithConsentComponent: React.FC<IBoxConsent> = ({
  status,
  imageId,
  imageVcode,
  idValue,
  ipValue,
  createData,
  createHours,
  expirationData,
  expirationHours,
  userAgentContent,
}) => {
  const [lightboxImage, setLightBoxImage] = React.useState(null);
  const [lightboxState, setLightBoxState] = React.useState<boolean>(false);
  const handleImage = async () => {
    setLightBoxImage(
      getImage({ imageId: imageId, imageVcode: imageVcode }, 100)
    );
  };
  React.useEffect(() => {
    handleImage();
  }, []);

  return (
    <BoxConsentDefault style={styles.consent} p={2}>
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box component="div">
          <Chip type="high">
            {status === statusType.APPROVED && 'Consentido'}
          </Chip>
          <Box
            component="strong"
            fontWeight="700"
            mt={2}
            mb={0.5}
            style={styles.textColorBlack}
          >
            <Box component="span" style={styles.idBorder}>
              ID
            </Box>
            : {idValue}
          </Box>
          <Box component="strong" style={styles.textColorBlack}>
            {ipValue && ` | IP: ${ipValue}`}
          </Box>
        </Box>
        <Box component="div">
          {/* <button onClick={}={() => setLightBoxState(true)}> test</button> */}
          <HypertextButton onClick={() => setLightBoxState(true)}>
            <VisibilityOutlinedIcon style={{ fontSize: 18, marginRight: 4 }} />
            Ver foto
          </HypertextButton>
        </Box>
      </Box>
      <Box component="div" mt={1}>
        <Box component="div">
          <Box component="span" style={styles.textColorBlack}>
            <span style={styles.textColorGray}>Criação: </span> {createData} -{' '}
            {createHours} |{' '}
          </Box>
          <Box component="span" style={styles.textColorBlack}>
            <span style={styles.textColorGray}>Expiração:</span>{' '}
            {expirationData} - {expirationHours}{' '}
          </Box>
        </Box>
      </Box>
      <Box component="hr" mt={4} />
      <Box component="div">
        <Box component="p" style={styles.textColorGray} fontSize="14px" m={0}>
          User agent:
        </Box>
        <Typography>{userAgentContent}</Typography>
      </Box>
      {lightboxState && (
        <Lightbox
          mainSrc={lightboxImage}
          onCloseRequest={() => setLightBoxState(false)}
        />
      )}
    </BoxConsentDefault>
  );
};

export default BoxWithConsentComponent;
