import { all, put, takeLatest } from 'redux-saga/effects';

import { Action } from 'types/ActionTypes';
import ActionTypes from './actionTypes';
import AC from './actionCreators';

import CategoryServices from 'modules/financial/services/CategoryServices';

const buildCategoryOptions = (categories: any[]) => {
  return categories?.length
    ? categories?.map(cat => ({
        value: cat.id,
        label: cat.name,
      }))
    : [];
};

function* get(action: Action) {
  const { success, data } = yield CategoryServices.list();

  yield success && put(AC.reloadAll(buildCategoryOptions(data)));
}

function* deleteCategory(action: Action) {
  const { id } = action.payload;
  const { success, data } = yield CategoryServices.delete(id);

  yield success && put(AC.remove(id));
}

function* putCategory(action: Action) {
  const { id, attrs } = action.payload;
  const { success, data } = yield CategoryServices.put(id, attrs);

  yield success && put(AC.upateExisting(data));
}

export default all([
  takeLatest(ActionTypes.GET_CATEGORIES_REQUEST, get),
  takeLatest(ActionTypes.DELETE_CATEGORIE_REQUEST, deleteCategory),
  takeLatest(ActionTypes.PUT_CATEGORIES_REQUEST, putCategory),
]);
