/* eslint-disable react/display-name */
import { makeStyles } from '@material-ui/core/styles';
import { GridColumns, GridOverlay, GridRowsProp } from '@material-ui/data-grid';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import React from 'react';

import Wrapper from 'components/Wrapper';
import TableDataGrid from 'components/atoms/TableDataGrid';
import { IModalStateManager } from 'hooks/useModalStateManager';
import { TitleDoc } from './styles';
// import { Document } from './ContainerTableApprovals';

const useStyles = makeStyles({
  root: {
    '& .table-header': {
      fontFamily: 'Poppins Regular',
      fontWeight: '400',
      fontStyle: 'normal',
      color: '#424242',
      fontSize: '16px',
      background: 'white',
      border: '0px solid #757575',
    },
    '& .table-body': {
      fontFamily: 'Poppins Regular',
      fontWeight: '400',
      fontStyle: 'normal',
      color: '#424242',
      fontSize: '16px',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-footer': {
      background: 'white',
    },
  },
});

interface Props {
  uploadModalState?: IModalStateManager;
  columns: GridColumns;
  rows: GridRowsProp;
  documentsOptions: { label: string; value: string | number }[];
  isLoading?: boolean;
  updateDocumentsFunction?: () => void;
}

const ComponentTableDocuments = ({ columns, rows, isLoading }: Props) => {
  const classes = useStyles();

  const LoadingOverlay = () => (
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: 200,
      }}
    >
      <Progress large />
    </div>
  );

  return (
    <div style={{ background: 'white' }}>
      <Wrapper p={16}>
        <Wrapper flex align="center" gap="md" width="100%" mb={16}>
          <Wrapper flex justify="start" width="100%" gap="md">
            <TitleDoc>Documentos inseridos</TitleDoc>
          </Wrapper>
        </Wrapper>
        <TableDataGrid
          rows={rows}
          columns={columns}
          hideFooter
          pageSize={99}
          loading={isLoading}
          className={classes.root}
          getRowId={() => Math.random()}
          sortModel={[{ field: 'required', sort: 'asc' }]}
          components={{
            NoRowsOverlay: () => (
              <GridOverlay>
                <div
                  style={{
                    position: 'absolute',
                    top: 55,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Wrapper color="#424242" align="center">
                    Nenhum documento encontrado.
                  </Wrapper>
                </div>
              </GridOverlay>
            ),
            LoadingOverlay: LoadingOverlay,
          }}
        />
      </Wrapper>
    </div>
  );
};

export default ComponentTableDocuments;
