import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const chunkAddress = 'leadmanager/v1.0';
const serviceName = 'leadsManagerService';

export default class LeadsManagerService {
  static getSellerActivities = (dealerId, userId, start, end) => {
    const params = { userId, start, end };
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/activities`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getSellerActivities()`));
  };

  static getManagerConvertion = (dealerId, userId, start, end) => {
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/snapshot`, {
        userId,
        start,
        end,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getManagerConvertion()`));
  };

  static getSellerConvertion = (dealerId, userId, start, end) => {
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/snapshot`, {
        userId,
        start,
        end,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getSellerConvertion()`));
  };

  static getProposalsInfos = (dealerId, userId, start, end) => {
    const params = { userId, start, end };
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/overview`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposalsInfos()`));
  };

  static getSellerSchedulle = (dealerId, userId, start, end) => {
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/schedule/dates`, {
        userId,
        start,
        end,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getSellerSchedulle()`));
  };

  static getSellerSchedulleInfos = (dealerId, userId, start, end) => {
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/schedule/reminders`, {
        userId,
        start,
        end,
      })
      .then(
        buildHTTPResponseHandler(`${serviceName}.getSellerSchedulleInfos()`)
      );
  };

  static getProvidersProposals = (dealerId, start, end) => {
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/providers`, {
        start,
        end,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getProvidersProposals()`));
  };

  static getSellersProposals = (dealerId, start, end) => {
    return request
      .get(`${domain}/dealer/${dealerId}/${chunkAddress}/sellers`, {
        start,
        end,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.getSellersProposals()`));
  };
}
