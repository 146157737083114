import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'proposalOrderDelivery';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class ProposalOrderDelivery {
  static getAllLeadsKanbanDeliveryList = payload => {
    const params = {
      sellersId: payload?.sellerId,
      modelId: payload?.modelId,
      exteriorColors:
        payload.exteriorColors != 'undefined' ? payload.exteriorColors : null,
      trimsId: payload?.versions != 'undefined' ? payload?.versions : null,
      query: payload?.query,
    };
    return request
      .get(
        `${domain}/dealer/${payload?.dealerId}/proposal/order/delivery/v1.0/${payload?.startDate}/${payload?.endDate}`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllLeadsKanbanDeliveryList()`
        ) as any
      );
  };
  static getAllDeliveryOrdersWithDetails = (dealerId, params = {}) => {
    return request
      .post(`${domain}/dealer/${dealerId}/proposal/order/delivery/v1.0`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllDeliveryOrdersWithDetails()`
        ) as any
      );
  };

  static getProposalOrderDeliveryFindId = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/delivery/v1.0/${proposalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getProposalOrderDeliveryFindId()`
        ) as any
      );
  };

  static getAvailableWorkTimetableGrade = (dealerId, date) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/delivery/v1.0/worktimetable-grade?date=${date}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAvailableWorkTimetableGrade()`
        ) as any
      );
  };

  static createScheduling = (dealerId, id, payload) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/order/delivery/v1.0/${id}/schedule`,
        payload
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.createScheduling()`) as any
      );
  };

  static deleteScheduling = (dealerId, id) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposal/order/delivery/v1.0/${id}/schedules`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.deleteScheduling()`) as any
      );
  };

  static finishDeliveryOrder = (dealerId, id) => {
    return request
      .patch(
        `${domain}/dealer/${dealerId}/proposal/order/delivery/v1.0/${id}/finish`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.finishDeliveryOrder()`) as any
      );
  };

  static reOpenDeliveryOrder = (dealerId, id) => {
    return request
      .patch(
        `${domain}/dealer/${dealerId}/proposal/order/delivery/v1.0/${id}/re-open`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.reOpenDeliveryOrder()`) as any
      );
  };
}
