import React from 'react';
import { CircularProgress } from '@material-ui/core';
import useCreateDeliverySchedule from 'modules/delivery/DeliveryKanban/hooks/useCreateDeliverySchedule';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ConfirmButton from 'components/atoms/ConfirmButton';
import ComponentConfirmAppointment from './ComponentConfirmAppointment';
import { ButtonWrapper } from './styles';

const ContainerConfirmAppointment = ({
  dataResponse,
  open,
  onClose,
  date,
  proposalSelected,
}) => {
  const {
    mutateAsync: mutateCreateScheduling,
    isLoading,
  } = useCreateDeliverySchedule();

  const confirmScheduling = async () => {
    const mutationVariables = {
      id: proposalSelected?.id,
      dealerId: proposalSelected.dealerId,
      payload: {
        deliveryDate: new Date(date).getTime(),
        endInterval: dataResponse[dataResponse.length - 1].endInterval.replace(
          ':',
          ''
        ),
        startInterval: dataResponse[0].startInterval.replace(':', ''),
        usersiteId: dataResponse[0].sellerId,
      },
    };
    await mutateCreateScheduling(mutationVariables);
    onClose();
  };

  const DialogAction = () => (
    <ButtonWrapper>
      <OutlinedButton onClick={() => onClose()}>Cancelar</OutlinedButton>
      <div style={{ width: 10 }} />
      <ConfirmButton disabled={isLoading} onClick={() => confirmScheduling()}>
        {isLoading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          'Confirmar'
        )}
      </ConfirmButton>
    </ButtonWrapper>
  );

  return (
    <ComponentConfirmAppointment
      dataResponse={dataResponse}
      date={date}
      open={open}
      onClose={onClose}
      DialogAction={DialogAction}
    />
  );
};

export default ContainerConfirmAppointment;
