import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { NextPanelButton } from '../../styles';

export const OrderNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FinishButton = styled(NextPanelButton)`
  background: #4c5fae !important;
  color: white !important;
  border: none !important;
`;

export const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      background: '#FFFFFF',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      background: '#e6e6e6',
    },
  },
});
