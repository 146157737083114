export type FormHelpersProps = {
  handleAutoCompleteValue: (name: string, options) => object;
  handleError: (name: string) => boolean;
  handleHelper: (name: string) => void;
};

const useFormHelpers = (values, touched, errors) => {
  const handleAutoCompleteOption = (name: string, options) => {
    return values[name]
      ? {
          value: values[name],
          label: options?.find(e => e.value === values[name])?.label,
          name: options?.find(e => e.value === values[name])?.label,
        }
      : null;
  };

  const handleError = (name: string) => {
    return touched?.[name] && Boolean(errors?.[name]);
  };

  const handleHelper = (name: string) => {
    return Boolean(touched?.[name]) && errors?.[name];
  };

  return { handleAutoCompleteOption, handleError, handleHelper };
};

export default useFormHelpers;
