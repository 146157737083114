// Interface import
import { ChatData, GetLeadsQ } from 'pages/conversations/interfaces';

// Service import
import ProposalServices from 'services/proposalServices';

export async function getLeads({
  dealerId,
  groupId,
  search,
  getInactiveLeads,
  filterByDates,
  filterByActiveLead,
  isCallCenter,
  externalSalesAssistantId,
  salesAssistantId,
  resetFilters = false,
  channel,
  departmentCodes,
  tags,
  sellerId,
  dealers,
  origin,
}: GetLeadsQ): Promise<ChatData[]> {
  const { data } = await ProposalServices.getProposalsV2(dealerId, {
    periodInit: filterByDates.initialDate,
    periodEnd: filterByDates.finalDate,
    search,
    // extraFilter: getQueryFilter(),
    attendanceType: isCallCenter ? 'CALL_CENTER' : 'STORE',
    salesAssistantId: externalSalesAssistantId ?? salesAssistantId,
    groupId: groupId,
    filterByInactiveLead: getInactiveLeads,
    filterByActiveLead,
    ...(!resetFilters
      ? {
          ...(channel?.length && {
            providerId: channel.map(value => value.value),
          }),
          ...(departmentCodes?.length && {
            departmentCodes: departmentCodes.map(value => value.value),
          }),
          ...(tags?.length && { tags }),
          ...(origin?.length && {
            origin: origin.map(value => value.value),
          }),
          ...(sellerId && { sellerId: sellerId }),
          ...(dealers?.length && {
            dealers: dealers,
          }),
        }
      : {
          providerId: null,
          departmentCode: null,
          tags: null,
          origin: null,
          sellerId: null,
          dealers: null,
        }),
  });

  return data;
}
