import styled, { keyframes } from 'styled-components';

//keyframes
const oppacity = keyframes`
  from {
        opacity: 0;
    }
  to {
      opacity: 1;
  }
`;

const leftoToRight = keyframes`
  from { opacity: 0; left:100px;}
  to { opacity: 1; left:0px;}
`;

export const TextInputContainer = styled.div`
  border-top: 1px solid #eeeeee;
  padding: 12px 16px;
`;

export const TextInput = styled.div`
  display: flex;
  background: #eeeeee;
  border-radius: 90px;
  padding: 4px 8px 4px 30px;
  height: auto;
`;

export const AnimatedLeftToRight = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  animation: ${leftoToRight} 0.3s linear;
`;

export const TimerContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  flex: 1;
  position: relative;
  animation: ${leftoToRight} 0.3s linear;
`;

export const IsRecording = styled.div`
  display: flex;
  margin-right: 8px;
  animation: ${oppacity} 1s linear infinite;
`;

export const PreviewImg = styled.div`
  display: flex;
  position: relative;
`;

export const RemoveButton = styled.div`
  position: absolute;
  top: -10px;
  right: 0px;
  background: white;
  border-radius: 20px;
  height: 20px;
`;

export const CancelSpan = styled.span`
  color: red;
  font-size: 14px;
`;

export const styles = {
  input: {
    flex: 1,
    width: '100%',
    lineHeight: '26px',
    fontSize: 17,
  },
  textInputButtons: {
    padding: '0px 12px',
    width: 48,
  },
};
