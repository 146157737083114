import ActionTypes from './actionTypes'
import { Action } from 'types/ActionTypes'

export default {
  get: function(dealId: number, start: string, end: string): Action{
    return{
      type: ActionTypes.GET_SCHEDULER_ALL_EVENT,
      payload: { dealId, start, end }
    }
  },
  set: function(dealId: number, data: any): Action{
    return{
      type: ActionTypes.CREATE_SCHEDULER_EVENT,
      payload: { dealId, data }
    }
  },
  getById: function(dealId: number, eventId: any): Action{
    return{
      type: ActionTypes.GET_SCHEDULER_EVENT_BY_ID,
      payload: { dealId, eventId }
    }
  },
  updateEvent: function(dealId: number, eventId: any, data): Action{
    return{
      type: ActionTypes.UPDATE_SCHEDULER_EVENT,
      payload: { dealId, eventId, data }
    }
  },
  callIsLoad: function(): Action{
    return{
      type: ActionTypes.SCHEDULER_LOADING,
    }
  },
  setSuccess: function(): Action{
    return{
      type: ActionTypes.CREATE_SCHEDULER_EVENT_SUCCESS,
    }
  },
  getAllSuccess: function(data: any): Action{
    return{
      type: ActionTypes.GET_SCHEDULER_ALL_EVENT_SUCCESS,
      payload: data
    }
  },
  getByIdSuccess: function(data: any): Action{
    return{
      type: ActionTypes.GET_SCHEDULER_BY_ID_EVENT_SUCCESS,
      payload: data
    }
  },
  updateSuccess: function(): Action{
    return{
      type: ActionTypes.UPDATE_SCHEDULER_EVENT_SUCCESS,
    }
  },
  deleteSuccess: function(): Action{
    return{
      type: ActionTypes.DELETE_SCHEDULER_EVENT_SUCCESS,
    }
  },
  setError: function(): Action{
    return{
      type: ActionTypes.CREATE_SCHEDULER_EVENT_ERROR,
    }
  },
  getAllError: function(): Action{
    return{
      type: ActionTypes.GET_SCHEDULER_ALL_EVENT_ERROR,
    }
  },
  getByIdError: function(): Action{
    return{
      type: ActionTypes.GET_SCHEDULER_BY_ID_EVENT_ERROR,
    }
  },
  updateError: function(): Action{
    return{
      type: ActionTypes.UPDATE_SCHEDULER_EVENT_ERROR,
    }
  },
  deleteError: function(): Action{
    return{
      type: ActionTypes.DELETE_SCHEDULER_EVENT_ERROR,
    }
  },

}