import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import VehicleOfInterest from './VehicleOfInterest';
import searchServices from 'services/searchServices';
// import proposalServices from 'services/proposalServices'
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

type AvailableVehicleType = 'CAR' | 'MOTORCYCLE';

interface VehicleType {
  label: string;
  value: AvailableVehicleType;
}

const vehicleTypeOptions: VehicleType[] = [
  { label: 'Carro', value: 'CAR' },
  { label: 'Moto', value: 'MOTORCYCLE' },
];

const vehicleModel = {
  make: null,
  model: null,
  initialYear: null,
  finalYear: null,
  trim: null,
  color: null,
  transmission: null,
  bodystyle: null,
  fuel: null,
  km: null,
  minPrice: null,
  maxPrice: null,
  armored: false,
};

const buildDefaultYearsOptions = () => {
  const temp = [];
  const currentYear = moment().year() + 1;
  for (let year = 1950; year <= currentYear; year++) {
    temp.push({ value: year, label: String(year) });
  }
  return temp;
};

const buildOptions = array => {
  return array.map(el => ({ value: el.id, label: el.name, ...el }));
};

const VehicleOfInterestContainer = ({ handleClickItem }) => {
  const { dealerStore } = useSelector(state => ({
    proposalStore: state.proposalStore,
    dealerStore: state.dealerStore,
  }));
  const dispatch = useDispatch();
  const [state, setState] = useState({
    makesOptions: [],
    makesIsLoading: false,
    modelsOptions: [],
    modelsIsLoading: false,
    initialYearsOptions: buildDefaultYearsOptions(),
    initialYearsIsLoading: false,
    finalYearsOptions: [],
    finalYearsIsLoading: false,
    trimOptions: [],
    trimIsLoading: false,
    colorsOptions: [],
    colorsIsLoading: false,
    transmissionsOptions: [],
    transmissionsIsLoading: false,
    fuelOptions: [],
    fuelIsLoading: false,
    bodystylesOptions: [],
    bodyStylesIsLoading: false,
  });

  const [vehicleTypeSelected, setVehicleTypeSelected] = useState<VehicleType>(
    vehicleTypeOptions[0]
  );
  const [currentVehicleOfInterest, setCurrentVehicleOfInterest] = useState(
    vehicleModel
  );

  const handleCurrentVehicleOfInterestValues = (name, value, label) => {
    if (name === 'initialYear') {
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        initialYear: value,
        finalYear: null,
        trim: null,
      }));
      handleClickItem({
        ...currentVehicleOfInterest,
        initialYear: value,
        finalYear: null,
        trim: null,
      });
      setState(prevState => ({ ...prevState, trimOptions: [] }));
      return;
    }

    if (name === 'finalYear') {
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        finalYear: value,
        trim: null,
      }));
      handleClickItem({
        ...currentVehicleOfInterest,
        finalYear: value,
        trim: null,
      });
      setState(prevState => ({ ...prevState, trimOptions: [] }));
      return;
    }

    // if (name === 'trim') {
    //   // const trimData = state.trimOptions.find(el => el.value === value)
    //   // const fuelLabel = trimData?.fuelId && state.fuelOptions.find(el => el.value === trimData?.fuelId).label
    //   // const bodyStyleLabel = trimData?.bodystyleId && state.bodystylesOptions.find(el => el.value === trimData?.bodystyleId).label
    //   // const transmissionLabel = trimData?.transmissionId && state.transmissionsOptions.find(el => el.value === trimData?.transmissionId).label
    //   // setCurrentVehicleOfInterest(prevState => ({ ...prevState, trim: { id: value, name: label }, bodystyle: trimData?.bodystyleId ? { id: trimData?.bodystyleId, name: bodyStyleLabel } : null, fuel: trimData?.fuelId ? { id: trimData?.fuelId, name: fuelLabel } : null, transmission: trimData?.transmissionId ? { id: trimData?.transmissionId, name: transmissionLabel } : null }))
    //   setCurrentVehicleOfInterest(prevState => ({ ...prevState, trim: { id: value, name: label } }))
    //   handleClickItem(prevState => ({ ...prevState, trim: { id: value, name: label } }))
    //   return
    // }

    if (name === 'make') {
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        make: value ? { id: value, name: label } : null,
        trim: null,
        model: null,
        bodystyle: null,
        fuel: null,
        transmission: null,
      }));
      handleClickItem({
        ...currentVehicleOfInterest,
        make: value ? { id: value, name: label } : null,
        trim: null,
        model: null,
        bodystyle: null,
        fuel: null,
        transmission: null,
      });
      setState(prevState => ({ ...prevState, trimOptions: [] }));
      return;
    }

    if (label) {
      handleClickItem({
        ...currentVehicleOfInterest,
        [name]: value ? { id: value, name: label } : null,
      });
      setCurrentVehicleOfInterest(prevState => ({
        ...prevState,
        [name]: value ? { id: value, name: label } : null,
      }));
      return;
    }
    handleClickItem({ ...currentVehicleOfInterest, [name]: value || null });
    setCurrentVehicleOfInterest(prevState => ({
      ...prevState,
      [name]: value || null,
    }));
  };

  const handleChangeState = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const getMakes = useCallback(async () => {
    try {
      const response: any = await searchServices.getMakes(
        vehicleTypeSelected.value
      );
      if (response.success) {
        const makeOptions = buildOptions(response.data);
        handleChangeState('makesOptions', makeOptions);
        if (dealerStore.currentDealer?.makeId) {
          setCurrentVehicleOfInterest(prevState => ({
            ...prevState,
            make: {
              id: dealerStore.currentDealer?.makeId,
              name: makeOptions.find(
                el => el.value === dealerStore.currentDealer?.makeId
              )?.label,
            },
            model: null,
            initialYear: null,
            finalYear: null,
          }));
          handleClickItem({
            ...currentVehicleOfInterest,
            make: {
              id: dealerStore.currentDealer?.makeId,
              name: makeOptions.find(
                el => el.value === dealerStore.currentDealer?.makeId
              )?.label,
            },
            model: null,
            initialYear: null,
            finalYear: null,
          });
        }
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Marcas".'
        )
      );
    }
  }, [dispatch, vehicleTypeSelected.value]);

  const getModelsByMake = useCallback(async () => {
    try {
      const make = state.makesOptions.find(
        el => el.value === currentVehicleOfInterest.make.id
      )?.label;
      const response: any = await searchServices.getModels(
        vehicleTypeSelected.value,
        make
      );

      if (response.success) {
        if (!response.data) return;
        handleChangeState('modelsOptions', buildOptions(response.data));
        setCurrentVehicleOfInterest(prevState => ({
          ...prevState,
          trim: null,
          model: null,
          initialYear: null,
          finalYear: null,
        }));
        setState(prevState => ({
          ...prevState,
          initialYearsOptions: buildDefaultYearsOptions(),
          finalYearsOptions: [],
        }));
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Modelos".'
        )
      );
    }
  }, [
    currentVehicleOfInterest.make?.id,
    dispatch,
    state.makesOptions,
    vehicleTypeSelected.value,
  ]);

  const getInitialsYears = useCallback(async () => {
    try {
      const response: any = await searchServices.getProductionYears(
        vehicleTypeSelected.value,
        currentVehicleOfInterest.model?.id
      );
      if (response.success) {
        handleChangeState(
          'initialYearsOptions',
          buildOptions(
            (response?.data || { years: [] })?.years.map(el => ({
              id: el,
              name: el,
            }))
          )
        );
        setCurrentVehicleOfInterest(prevState => ({
          ...prevState,
          trim: null,
          initialYear: null,
          finalYear: null,
        }));
        setState(prevState => ({ ...prevState, finalYearsOptions: [] }));
      }
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções Ano inicial".'
        )
      );
    }
  }, [currentVehicleOfInterest.model?.id, dispatch, vehicleTypeSelected.value]);

  const getTrims = useCallback(async () => {
    try {
      const response: any = await searchServices.getTrims(
        vehicleTypeSelected.value,
        currentVehicleOfInterest.model?.id,
        currentVehicleOfInterest.finalYear
      );
      if (response.success) {
        handleChangeState('trimOptions', buildOptions(response.data));
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções de Versões".'
        )
      );
    }
  }, [
    currentVehicleOfInterest.model?.id,
    currentVehicleOfInterest.finalYear,
    dispatch,
    vehicleTypeSelected.value,
  ]);

  const getColors = useCallback(async () => {
    try {
      const response: any = await searchServices.getColors();
      if (response.success) {
        handleChangeState(
          'colorsOptions',
          buildOptions(response.data.map(el => ({ id: el.id, name: el.name })))
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções de Cores".'
        )
      );
    }
  }, [dispatch]);

  const getTransmissions = useCallback(async () => {
    try {
      const response: any = await searchServices.getTransmissions();
      if (response.success) {
        handleChangeState(
          'transmissionsOptions',
          buildOptions(
            response.data
              .filter(el => ['Manual', 'Automática'].includes(el.name))
              .map(el => ({ id: el.id, name: el.name }))
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções de Transmissão".'
        )
      );
    }
  }, [dispatch]);

  const getFuels = useCallback(async () => {
    try {
      const response: any = await searchServices.getFuels();
      if (response.success) {
        handleChangeState(
          'fuelOptions',
          buildOptions(response.data.map(el => ({ id: el.id, name: el.name })))
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções de Combustível".'
        )
      );
    }
  }, [dispatch]);

  const getBodystyles = useCallback(async () => {
    try {
      const response: any = await searchServices.getBodystyles(
        vehicleTypeSelected.value
      );
      if (response.success) {
        handleChangeState(
          'bodystylesOptions',
          buildOptions(response.data.map(el => ({ id: el.id, name: el.name })))
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Opções de Carroceria".'
        )
      );
    }
  }, [dispatch, vehicleTypeSelected.value]);

  // const addInterestProduct = useCallback(async () => {
  //   try {
  //     const response: any = await proposalServices.addInterestProduct(dealerStore.currentDealer.id, proposalStore.currentProposal.proposalId, currentVehicleOfInterest);
  //     if (response.success) {
  //       dispatch(UIActionCreators.snackbarManagement(
  //         'success',
  //         'Produto de interesse adicionado com sucesso.'
  //       ))
  //     }
  //   } catch {
  //     dispatch(UIActionCreators.snackbarManagement(
  //       'error',
  //       'Ocorreu um erro ao adicionar um produto de interesse.'
  //     ))
  //   }
  // }, [currentVehicleOfInterest, dealerStore.currentDealer.id, dispatch, proposalStore.currentProposal.proposalId])

  useEffect(() => {
    getMakes();
    getColors();
    getTransmissions();
    getFuels();
    getBodystyles();
  }, [vehicleTypeSelected.value]);

  //GET_MODEL_BY_MAKE
  useEffect(() => {
    if (currentVehicleOfInterest.make?.id) {
      getModelsByMake();
    }
  }, [getModelsByMake, currentVehicleOfInterest.make?.id]);

  //GET_TRIMS
  useEffect(() => {
    if (currentVehicleOfInterest.finalYear) {
      getTrims();
    }
  }, [getTrims, currentVehicleOfInterest.finalYear]);

  //GET_INITIAL_YEARS
  useEffect(() => {
    if (currentVehicleOfInterest.model?.id) {
      getInitialsYears();
    }
  }, [getInitialsYears, currentVehicleOfInterest.model?.id]);

  //MAKE_FINAL_YEARS_OPTIONS_BY_INITIAL_YEAR
  useEffect(() => {
    if (currentVehicleOfInterest.initialYear) {
      // const firstPosition = state.initialYearsOptions?.[1];
      const lastPosition =
        state.initialYearsOptions?.[state.initialYearsOptions.length - 1];

      // if (currentVehicleOfInterest.initialYear === firstPosition?.value) {
      //   setState(prevState => ({
      //     ...prevState,
      //     finalYearsOptions: [
      //       {
      //         value: currentVehicleOfInterest.initialYear,
      //         label: currentVehicleOfInterest.initialYear,
      //       },
      //     ],
      //   }));
      // }
      // else
      if (currentVehicleOfInterest.initialYear === lastPosition?.value) {
        setState(prevState => ({
          ...prevState,
          finalYearsOptions: [
            {
              value: Number(currentVehicleOfInterest.initialYear) + 1,
              label: Number(currentVehicleOfInterest.initialYear) + 1,
            },
          ],
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          finalYearsOptions: [
            {
              value: Number(currentVehicleOfInterest.initialYear) + 1,
              label: Number(currentVehicleOfInterest.initialYear) + 1,
            },
            {
              value: currentVehicleOfInterest.initialYear,
              label: currentVehicleOfInterest.initialYear,
            },
          ],
        }));
      }
    }
  }, [currentVehicleOfInterest.initialYear, state.initialYearsOptions]);

  const viewComponentProps = {
    makesOptions: state.makesOptions,
    modelsOptions: state.modelsOptions,
    initialYearsOptions: state.initialYearsOptions,
    finalYearsOptions: state.finalYearsOptions,
    trimOptions: state.trimOptions,
    colorsOptions: state.colorsOptions,
    transmissionsOptions: state.transmissionsOptions,
    fuelOptions: state.fuelOptions,
    bodystylesOptions: state.bodystylesOptions,
    currentVehicleOfInterest,
    handleCurrentVehicleOfInterestValues,
    vehicleTypeOptions,
    handleSelectVehicleType: setVehicleTypeSelected,
    vehicleTypeSelected,
  };

  return <VehicleOfInterest {...viewComponentProps} />;
};

export default VehicleOfInterestContainer;
