import styled from 'styled-components';

import MUIFormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/styles';

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SearchBox = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  & > .MuiFormControl-root {
    flex: 1 auto;
  }
`;

export const EmptyMessage = styled('p')`
  color: #424242;
  margin: 0;
`;

export const FormControlLabel = withStyles(() => ({
  root: {
    '&.select-all': {
      borderBottom: '1px solid #e0e0e0',
      marginRight: '0',
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Poppins Regular',
      fontSize: '1rem',
      color: '#424242',
    },
  },
}))(MUIFormControlLabel);
