import React from 'react';
import Icon from 'bit/mobiauto.web-ui-components.components.icon';

import DialogComponent from 'modules/resale/components/DialogComponent';
import {
  Container,
  Title,
  Text,
  Row,
  ConfirmButton,
  CancelButton,
  WrapperIcon,
} from './styles';

const ConfirmationContent = ({ title = null }: { title?: string | null }) => {
  return (
    <Container>
      <WrapperIcon>
        <Icon name="PriorityHigh" size={30} />
      </WrapperIcon>
      <Text>{title ?? 'Tem certeza disso?'}</Text>
    </Container>
  );
};

const ConfirmationActions = ({
  handleClickCloseDialog,
  handleClickConfirm,
  labelConfirmButton,
}: {
  handleClickCloseDialog: () => void;
  handleClickConfirm: () => void;
  labelConfirmButton?: string;
}) => {
  return (
    <Container padding>
      <Row>
        <CancelButton onClick={handleClickCloseDialog} variant="primary">
          Cancelar
        </CancelButton>
        <ConfirmButton onClick={handleClickConfirm} variant="primary">
          {labelConfirmButton ?? 'Confirmar'}
        </ConfirmButton>
      </Row>
    </Container>
  );
};

const ConfirmationTitle = () => {
  return (
    <Row>
      <Title>Confirmação</Title>
    </Row>
  );
};

const Confirmation = ({
  open,
  handleClickCloseDialog,
  handleClickConfirm,
  title = null,
  labelConfirmButton = null,
}: any) => {
  return (
    <DialogComponent
      handleClose={handleClickCloseDialog}
      open={open}
      title={<ConfirmationTitle />}
      content={<ConfirmationContent title={title} />}
      actions={
        <ConfirmationActions
          handleClickCloseDialog={handleClickCloseDialog}
          handleClickConfirm={handleClickConfirm}
          labelConfirmButton={labelConfirmButton}
        />
      }
    />
  );
};

export default Confirmation;
