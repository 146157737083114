import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'MobiPhoneServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export const getCalls = ({
  page,
  size,
  numberTo,
  numberFrom,
  dealerId,
  finalDate,
  answered,
  initialDate,
  departmentId,
}) => {
  const params = {
    page,
    size,
    numberTo,
    numberFrom,
    atendida: answered,
    departmentId,
  };

  return request
    .get(`${domain}/call/v1.0/${dealerId}/${initialDate}/${finalDate}`, params)
    .then(buildHTTPResponseHandler(`${serviceName}.getCalls()`));
};

export const getStats = ({
  dealerId,
  finalDate,
  initialDate,
  departmentId,
}) => {
  return request
    .get(
      `${domain}/call/v1.0/stats/${dealerId}/${initialDate}/${finalDate}${
        departmentId ? `?departmentId=${departmentId}` : ''
      }`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getStats()`));
};

export const getChartData = ({
  dealerId,
  initialDate,
  finalDate,
  departmentId,
}) => {
  return request
    .get(
      `${domain}/call/v1.0/stats/${dealerId}/${initialDate}/${finalDate}/daytoday${
        departmentId ? `?departmentId=${departmentId}` : ''
      }`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getChartData()`));
};

export const getChartDataGroupView = ({
  dealerId,
  initialDate,
  finalDate,
  departmentId,
}) => {
  return request
    .get(
      `${domain}/call/group/${dealerId}/v1.0/stats/${initialDate}/${finalDate}/${
        departmentId ? `?departmentId=${departmentId}` : ''
      }`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getChartData()`));
};

export const getChartDataHistoricGroupView = ({
  dealerId,
  initialDate,
  finalDate,
  departmentId,
}) => {
  return request
    .get(
      `${domain}/call/group/${dealerId}/v1.0/stats/daytoday/${initialDate}/${finalDate}/${
        departmentId ? `?departmentId=${departmentId}` : ''
      }`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getChartData()`));
};

export const getLabelChannels = ({
  dealerId,
  initialDate,
  finalDate,
  departmentId,
}) => {
  return request
    .get(
      `${domain}/call/group/${dealerId}/v1.0/stats/label/${initialDate}/${finalDate}/${
        departmentId ? `?departmentId=${departmentId}` : ''
      }`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getChartData()`));
};

export const getTableInfosGroupView = ({
  dealerId,
  initialDate,
  finalDate,
  departmentId,
}) => {
  return request
    .get(
      `${domain}/call/group/${dealerId}/v1.0/stats/label-dealer/${initialDate}/${finalDate}/${
        departmentId ? `?departmentId=${departmentId}` : ''
      }`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getChartData()`));
};

export const getList = ({ dealerId }) => {
  return request
    .get(`${domain}/call/v1.0/${dealerId}/numbers`)
    .then(buildHTTPResponseHandler(`${serviceName}.getStats()`));
};

export const updateList = ({ dealerId, data }) => {
  return request
    .put(`${domain}/call/v1.0/${dealerId}/number`, data)
    .then(buildHTTPResponseHandler(`${serviceName}.getStats()`));
};

export const getStatsPerLabel = ({
  dealerId,
  finalDate,
  initialDate,
  departmentId,
}) => {
  return request
    .get(
      `${domain}/call/v1.0/stats/label/${dealerId}/${initialDate}/${finalDate}${
        departmentId ? `?departmentId=${departmentId}` : ''
      }`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getStatsPerLabel()`));
};

export const changeMissedCallReturned = (
  dealerId,
  callId,
  missedCallReturned
) => {
  return request
    .put(
      `${domain}/call/v1.0/${dealerId}/call/${callId}/missed-call-returned?missedCallReturned=${missedCallReturned}`,
      {}
    )
    .then(
      buildHTTPResponseHandler(`${serviceName}.changeMissedCallReturned()`)
    );
};

export const updateDepartmentByCall = ({ dealerId, callId, departmentId }) => {
  return request
    .put(
      `${domain}/call/v1.0/${dealerId}/call/${callId}/department/${departmentId}`,
      {}
    )
    .then(buildHTTPResponseHandler(`${serviceName}.updateDepartmentByCall()`));
};
