import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import DealerServices from 'services/dealerServices';
import _ from 'lodash';

export const useGetDownPaymentsPartners = () => {
  const { currentDealer } = useSelector(state => state.dealerStore);

  return useQuery({
    queryKey: 'get_downPayment_partners',
    queryFn: () => DealerServices.getDownpaymentPartners(currentDealer.id),
    select(data) {
      return data.success
        ? data.data.content.map(partner => ({
            label: _.capitalize(partner.name),
            value: partner.id,
          }))
        : [];
    },
  });
};
