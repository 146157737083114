import { useMutation, useQueryClient } from 'react-query';

interface HandleReturn {
  onSuccess?: (status?: number, message?: string) => void;
  onError?: (status?: number, message?: string) => void;
}

interface CustomMutation<Query, Response> {
  key: string;
  request: (params: Query) => Promise<Response>;
  handleReturn?: HandleReturn;
  queriesToInvalidate?: string[];
}

export default function useCustomMutation<Query, Response>({
  handleReturn,
  key,
  request,
  queriesToInvalidate,
}: CustomMutation<Query, Response>) {
  const queryClient = useQueryClient();
  return useMutation([key], (params: Query) => request(params), {
    onSuccess: () => {
      if (queriesToInvalidate) {
        queriesToInvalidate.map(query => queryClient.invalidateQueries(query));
      }
      if (handleReturn?.onSuccess) handleReturn?.onSuccess();
    },
    onError: () => {
      if (handleReturn?.onError) handleReturn?.onError();
    },
  });
}
