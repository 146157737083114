import styled from 'styled-components';

export const TagColor = styled.div`
  width: 23px;
  height: 23px;
  font-size: 16px;
  background-color: #e0f3f1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00b4aa;
`;
