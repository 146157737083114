import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  //GET_DEALS_BY_SOP
  GET_DEALS_BY_SOP: `@${MODULE}/${getters.GET}_DEALS_BY_SOP_${actionOptions.REQUEST}`,
  SET_DEALS_SUCESS: `@${MODULE}/${getters.SET}_DEALS_SUCESS_${actionOptions.SUCCESS}`,
  SET_DEALS_LOADING: `@${MODULE}/${getters.SET}_DEALS_LOADING_${actionOptions.LOADING}`,
  SET_FILTERS: `@${MODULE}/${getters.SET}_FILTERS`,
  SET_PARAMS_AND_SOP: `@${MODULE}/${getters.SET}_PARAMS_AND_SOP`,
};
