import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 16px;
  margin: 8px;
`;

export const Title = styled.div`
  line-height: 21px;
`;

export const SubTitle = styled.div`
  line-height: 17px;
  color: #757575;
`;
