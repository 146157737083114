import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'DealderSupplierService';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default {
  getSupplierById: function(dealerId, supplierId) {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/supplier/${supplierId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getSupplierById()`));
  },

  updateSupplier: function(dealerId, supplierId, supplierData) {
    return request
      .put(
        `${domain}/dealer/v1.0/${dealerId}/supplier/${supplierId}`,
        supplierData
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateSupplier()`));
  },

  createSupplier: function(dealerId, supplierData) {
    return request
      .post(`${domain}/dealer/v1.0/${dealerId}/supplier`, supplierData)
      .then(buildHTTPResponseHandler(`${serviceName}.createSupplier()`));
  },

  getSuppliers: function(dealerId, { page = 0, query = null, size = 20 }) {
    const params = { page, q: query.length < 1 ? null : query, size };
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/suppliers`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getSuppliers()`));
  },

  getSupplierServicesTypes: function(dealerId) {
    return request
      .get(`${domain}/dealer/v1.0/${dealerId}/supplier/service-types`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getSupplierServicesTypes()`)
      );
  },
};
