import React from 'react';
import Hidden from '@material-ui/core/Hidden';

const Desktop = ({ children }) =>
  children && <Hidden only={['xs']}>{children}</Hidden>;

const Mobile = ({ children }) =>
  children && <Hidden only={['sm', 'md', 'lg', 'xl']}>{children}</Hidden>;

const Tablet = ({ children }) =>
  children && <Hidden only={['xs', 'sm', 'lg', 'xl']}>{children}</Hidden>;

const DesktopAndTablet = ({ children }) =>
  children && <Hidden only={['xs', 'sm']}>{children}</Hidden>;

const TabletAndMobile = ({ children }) =>
  children && <Hidden only={['lg', 'xl']}>{children}</Hidden>;

const MobileAndDesktop = ({ children }) =>
  children && <Hidden only="sm">{children}</Hidden>;
export {
  Mobile,
  Desktop,
  Tablet,
  DesktopAndTablet,
  TabletAndMobile,
  MobileAndDesktop,
};
