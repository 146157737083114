import styled, { css } from 'styled-components';
import { ListItemText } from '@material-ui/core';

export const Container = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;
  
  background-color: #FFF;
  
  z-index: -5;

  cursor: pointer;

  border-radius: 4px;
  border-left: 3px solid #FFF;

  height: 72px;

  &:hover {
    background-color: #F0F6F6;
    border-left: 3px solid #F0F6F6;
    transition: all 200ms;
  }

  ${props => props.active && css`
    background-color: #F0F6F6;
    border-left: 3px solid #00B4AA;

    &:hover {
      border-left: 3px solid #00B4AA;
      transition: all 200ms;
    }
  `}
`;

export const MessageText = styled(ListItemText)`
  /* Nome do usuário */
  .MuiListItemText-primary {
    white-space: nowrap; 
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    overflow: hidden; 
  }

  /* Mensagem */
  .MuiListItemText-secondary {
    white-space: nowrap; 
    text-overflow: ellipsis;
    letter-spacing: 0.25px;
    line-height: 20px;
    font-size: 12px;
    font-family: 'Poppins Regular';
    color: #757575;
    
    overflow: hidden; 
  }
`;

export const BadgeAndTimeWrapper = styled.div<{ hasMessage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-left: 16px;

  > span {
    color: ${props => props.hasMessage ? "#00B4AA" : "#757575"};
    font-family: 'Poppins Regular';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 16px;
  width: 16px;

  background-color: #00B4AA;
  border-radius: 100%;

  font-size: 10px;
  color: #FFF;
`;