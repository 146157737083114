import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export const INITIAL_STATE = {
  hasFailed: false,
  isLoading: false,
  stockProviders: {
    actives: [],
    disableds: [],
    disconnecteds: [],
  },
  syncIsLoading: false,
  //connect
  connectHasSuccess: false,
  connectHasFailed: false,
  connectData: null,
  connectIsLoading: false,
  connectUrl: null,
  //tabs
  tabs: [],
  currentTab: null,
};

export default function provider(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case ActionTypes.SET_TABS:
      return {
        ...state,
        tabs: [...action.payload.tabs],
      };
    case ActionTypes.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload.currentTab,
      };
    //STOCK_PROVIDERS
    case ActionTypes.SET_PROVIDER_DATA_SUCCEDED:
      return {
        ...state,
        stockProviders: {
          actives: [
            ...action.payload.providers.actives.map(item => ({
              ...item,
              status: true,
            })),
            ...action.payload.providers.disableds.map(item => ({
              ...item,
              status: false,
            })),
          ],
          disconnecteds: [...action.payload.providers.disconnecteds],
        },
        hasFailed: false,
        isLoading: false,
      };
    case ActionTypes.SET_PROVIDER_STOCK_ERROR:
      return {
        ...state,
        hasFailed: true,
        isLoading: false,
      };
    case ActionTypes.SET_PROVIDER_STOCK_LOADING:
      return {
        ...state,
        hasFailed: false,
        isLoading: true,
      };
    //SYNC PLAN
    case ActionTypes.SYNC_PLAN_LOADING:
      return {
        ...state,
        syncIsLoading: action.payload.isLoading,
      };
    //CHANGE_SWITCH_PROPOSALS
    case ActionTypes.CHANGE_SWITCH_STOCK: {
      const stockProvidersAux = [...state.stockProviders.actives];
      const currentProviderIndex = stockProvidersAux.findIndex(
        item => item.id === action.payload.providerId
      );
      stockProvidersAux[currentProviderIndex].status = !stockProvidersAux[
        currentProviderIndex
      ].status;
      return {
        ...state,
        stockProviders: {
          ...state.stockProviders,
          actives: [...stockProvidersAux],
        },
      };
    }
    //SET CONNECT STATUS
    case ActionTypes.CONNECT_SET_STATUS:
      return {
        ...state,
        connectHasSuccess: action.payload.connectHasSuccess,
        connectHasFailed: action.payload.connectHasFailed,
        data: action.payload.connectData,
        connectIsLoading: action.payload.connectIsLoading,
        connectUrl: action.payload.connectUrl,
      };
    default:
      return state;
  }
}
