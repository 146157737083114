import useSnackBar from 'hooks/useSnackBar';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import ProposalServices, { DownPaymentDTO } from 'services/proposalServices';

export const useCalculateDownPayment = () => {
  const { dealerId, proposalId } = useSelector(
    (state: any) => state.proposalStore.currentProposal
  );

  const { handleSnackBarError, handleSnackBarSuccess } = useSnackBar();

  return useMutation<any, Error, DownPaymentDTO>(
    'calculate_downPayment',
    downPayment =>
      ProposalServices.calculateDownPayment(dealerId, proposalId, downPayment),
    {
      onError: () => {
        handleSnackBarError('Ocorreu um erro ao calcular.');
      },
      onSuccess: data => {
        if (data.success) {
          handleSnackBarSuccess('Cálculo efetuado com sucesso.');
        }
      },
    }
  );
};

export default useCalculateDownPayment;
