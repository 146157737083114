import { CircularProgress, GridProps } from '@material-ui/core';
import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useGetProposals from '../../hooks/useGetProposals';

// Context import
import columns from 'constants/columns';
import {
  filterByUnread as filterByUnreadState,
  inactiveLeadsList,
  leadsList,
  selectedLead,
} from 'pages/conversations/contexts/atoms';

// Component import
import { LeadChat } from 'pages/conversations/components';
import { Header } from './Header';

// Interface import
import { ChatListShow } from 'pages/conversations/interfaces';

// Style import
import { Container, Content } from './styles';

interface ChatListProps extends Partial<GridProps> {
  show: ChatListShow;
}

export function ChatList({ show = 'CONVERSATIONS', ...rest }: ChatListProps) {
  const dispatch = useDispatch();
  // States
  const [leads, setLeads] = useRecoilState(leadsList);
  const setSelectedLead = useSetRecoilState(selectedLead);
  const [inactiveLeads, setInactiveLeads] = useRecoilState(inactiveLeadsList);
  const filterByUnread = useRecoilValue(filterByUnreadState);

  const {
    currentDealer,
    departmentCode,
    channel,
    origin,
    tags,
    dealers,
    filterByDates,
    filterSalesAssistant,
    sellerId,
  } = useSelector(state => ({
    ...state.dealerStore,
    ...state.leads,
  }));

  const { filters } = useSelector(state => state.callCenter);

  const {
    data: leadsData,
    isLoading: isLoadingLeads,
    isFetching: isFetchingLeads,
    refetch,
  }: any = useGetProposals();

  useEffect(() => {
    refetch();
  }, [
    currentDealer.id,
    filterByDates.finalDate,
    filterByDates.initialDate,
    sellerId,
    filterSalesAssistant.salesAssistantId,
    departmentCode,
    origin,
    channel,
    tags,
    dealers,
    filters?.departmentCode,
    filters?.providerId?.length,
    filters?.origin?.length,
    filters?.tags?.length,
    filters?.dealerId?.length,
    filters?.salesAssistantId,
  ]);

  useEffect(() => {
    const allFinishedStatus = [
      columns.FINISHED_AND_CONCLUDED.id,
      columns.FINISHED_BUT_NOT_CONCLUDED.id,
      columns.FINISHED_BY_TIME.id,
      columns.WITH_ORDER.id,
      columns.PROPOSAL_ORDER_OEM.id,
    ];
    setLeads(
      Array.isArray(leadsData)
        ? leadsData.filter(lead => !allFinishedStatus.includes(lead.status))
        : []
    );
    setInactiveLeads(
      Array.isArray(leadsData)
        ? leadsData.filter(lead => allFinishedStatus.includes(lead.status))
        : []
    );
  }, [leadsData?.length]);

  const cleanLeadSelected = () => {
    dispatch(proposalCreators.setProposalModalOpen(false, null));
    setSelectedLead(null);
  };

  useEffect(() => {
    //CLEAR_LEAD_SELECTED_ON_EXIT_CONVERSATIONS_MODULE
    return () => {
      cleanLeadSelected();
    };
  }, []);

  const isLoading = isLoadingLeads || isFetchingLeads;

  const leadsArr = show === 'INACTIVE_LEADS' ? inactiveLeads : leads;
  const filteredLeads = filterByUnread
    ? leadsArr.filter(lead => lead.unseenMessages >= 1)
    : leadsArr;

  return (
    <Container {...rest}>
      <Header show={show} />
      <Content>
        {isLoading ? (
          <CircularProgress
            size={15}
            style={{ marginLeft: '50%', marginTop: 20 }}
          />
        ) : (
          <Virtuoso
            data={filteredLeads}
            style={{ height: '700px' }}
            itemContent={(_, lead) => <LeadChat lead={lead} />}
          />
        )}
      </Content>
    </Container>
  );
}
