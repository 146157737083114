import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ContactServices from 'modules/resale/services/ContactServices';
import ClientsListComponent from './ClientsListComponent';

const ClientsListContainer = ({ handleClickListItem, clientPhone = '' }) => {
  const { currentDealer } = useSelector(state => state.dealerStore);
  //SEARCH
  const [query, setQuery] = useState(clientPhone);
  //LIST
  const [clients, setClientsList] = useState([]);
  //IS_CREATING_CUSTOMER
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  //LOADING
  const [isLoading, setLoading] = useState(false);
  //IS_EDITING
  const [isEditing, setIsEditing] = useState(false);
  const [currentClientToEditing, setCurrentClientToEditing] = useState({});

  const getContacts = async () => {
    try {
      setLoading(true);
      const { success, data }: any = await ContactServices.getContacts(
        currentDealer.id,
        {
          query,
          size: 100,
        }
      );
      if (success) {
        setClientsList(data);
        setLoading(false);
        return;
      }
      setClientsList([]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleClickCancel = (enableGetContacts = true) => {
    setIsEditing(false);
    setIsCreatingCustomer(false);
    if (enableGetContacts) {
      getContacts();
    }
  };

  const handleClickEditListItem = item => {
    setCurrentClientToEditing(item);
    setIsEditing(true);
  };

  const handleSearchContacts = () => {
    getContacts();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') handleSearchContacts();
  };

  useEffect(() => {
    getContacts();
  }, []);

  useEffect(() => {
    if (clientPhone) {
      getContacts();
    }
  }, [clientPhone]);

  const viewComponentProps = {
    clients,
    query,
    setQuery,
    handleSearchContacts,
    handleKeyPress,
    handleClickListItem,
    isCreatingCustomer,
    setIsCreatingCustomer,
    isLoading,
    isEditing,
    handleClickCancel,
    currentClientToEditing,
    handleClickEditListItem,
    clientPhone,
  };

  return <ClientsListComponent {...viewComponentProps} />;
};

export default ClientsListContainer;
