import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import MessageTypes from 'constants/MessageTypes';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'callCenter';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class CallCenterServices {
  static getDealerGroups = ({ page = 0, size = 20, q }) => {
    const params = { page, size, q };
    return request
      .get(`${domain}/dealer-group/v1.0`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealerGroups()`));
  };

  static getDealersByGroup = groupId => {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/dealers`)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealersByGroup()`));
  };

  static getAllProposalsByGroup = (groupId, obj) => {
    return request
      .get(`${domain}/callcenter/${groupId}/v1.0/proposals`, {
        ...obj,
      })
      .then(
        buildHTTPResponseHandler(`${serviceName}.getAllProposalsByGroup()`)
      );
  };

  static getProposalStatusDetail = dealerId => {
    return request
      .get(`${domain}/dealer-group/${dealerId}/v1.0/status-detail/options`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalStatusDetail()`)
      );
  };

  static alterStatusProposalSalesAssistant = (
    groupId,
    proposalId,
    newStatus,
    statusDetailId
  ) => {
    const url = `${domain}/callcenter/${groupId}/v1.1/${proposalId}/sales-assistant/status/${newStatus}`;
    const axiosConfig = statusDetailId ? { params: { statusDetailId } } : null;
    return request
      .put(url, {}, axiosConfig)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.alterStatusProposalSalesAssistant()`
        )
      );
  };

  static setProposalSalesAssistant = (
    groupId,
    proposalId,
    salesAssistantId
  ) => {
    const url = `${domain}/callcenter/${groupId}/v1.0/${proposalId}/sales-assistant/${salesAssistantId}`;
    return request
      .put(url, {})
      .then(
        buildHTTPResponseHandler(`${serviceName}.setProposalSalesAssistant()`)
      );
  };

  static addInteraction = (
    dealerId,
    proposalId,
    mediaType,
    data,
    dateISO = new Date().toISOString()
  ) => {
    if (mediaType === MessageTypes.TEXT) {
      return request
        .post(
          `${domain}/callcenter/${dealerId}/v1.0/${proposalId}/addinteraction`,
          data
        )
        .then(buildHTTPResponseHandler(`${serviceName}.setInteraction-TEXT()`));
    }
    const formData = new FormData();

    formData.append('file', data.file);
    formData.append('groupBy', dateISO);

    return request
      .post(
        `${domain}/callcenter/${dealerId}/v1.0/${proposalId}/addinteraction/${mediaType}?channel=${data.channel}`,
        formData
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.addInteraction-IMAGE-AUDIO()`)
      );
  };

  static createProposalSchedule = (dealerId, proposalId, data) => {
    return request
      .post(
        `${domain}/callcenter/${dealerId}/v1.0/${proposalId}/scheduled`,
        data
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.createProposalSchedule()`)
      );
  };

  static getProposalById = (dealerId, proposalId) => {
    return request
      .get(`${domain}/callcenter/${dealerId}/v1.0/${proposalId}`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getProposalById()`));
  };

  static exportProposalsByCsv = (groupId, queryParams = {}) => {
    return request
      .get(`${domain}/callcenter/${groupId}/v1.0/proposals/file`, queryParams)
      .then(buildHTTPResponseHandler(`${serviceName}.exportProposalsByCsv()`));
  };

  static getTagsByGrupId = groupId => {
    return request
      .get(`${domain}/callcenter/${groupId}/v1.0/tags`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getTagsByGrupId()`));
  };

  static getDatesWithRemindersPerGroup = (
    groupId,
    start,
    finish,
    id,
    reminderType,
    done = ''
  ) => {
    const params = {
      salesAssistantId: id,
      reminderType: reminderType,
      done,
    };
    return request
      .get(
        `${domain}/callcenter/${groupId}/schedule/v1.0/reminders/dates/${start}/${finish}`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getDatesWithRemindersPerGroup()`
        )
      );
  };

  static getRemindersDetailed = (
    groupId,
    start,
    finish,
    id,
    reminderType,
    done = ''
  ) => {
    const params = {
      salesAssistantId: id,
      reminderType: reminderType,
      done: done,
    };
    return request
      .get(
        `${domain}/callcenter/${groupId}/schedule/v1.0/reminders/detailed/${start}/${finish}`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getRemindersDetailed()`));
  };
}
