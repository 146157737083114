import styled, { css } from 'styled-components';
import { ReactComponent as ConeIconSvg } from 'assets/cone.svg';

export const Tag = styled.div`
  color: #c77700;
  background-color: #fff5e5;
  padding: 8px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1em;
  overflow: hidden;
  width: fit-content;
  ${props =>
    props.clickable &&
    css`
      &:hover {
        text-decoration: underline;
      }
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
  ${props =>
    props.fab &&
    css`
    cursor: pointer;
    display: flex
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    padding: 0;
  `}
`;

export const TootipTitle = styled.span`
  font-size: 12px;
`;

export const ConeIcon = styled(ConeIconSvg)`
  ${props =>
    props.fab &&
    css`
      height: 14px;
      width: 12px;
    `}
`;
