import styled from 'styled-components';

import MUIButton from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import { withStyles, CreateCSSProperties } from '@material-ui/styles';

export const Dialog = withStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      padding: '1.5rem',
      fontFamily: 'Poppins Regular',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: '#757575',
      gap: '1.5rem',
      minWidth: '500px',
    },
    '& p': {
      margin: 0,
    },
  },
}))(MUIDialog);

const bottomButtonsStyles: CreateCSSProperties<{}> = {
  fontSize: '0.875rem',
  fontFamily: 'Poppins Medium',
  fontWeight: 500,
  textTransform: 'none',
  boxShadow: 'none',
};

export const CancelButton = withStyles(() => ({
  root: {
    ...bottomButtonsStyles,
    color: '#424242',
  },
}))(MUIButton);

export const ConfirmButton = withStyles(() => ({
  root: {
    ...bottomButtonsStyles,
    color: '#FFF',
    backgroundColor: '#00B4AA',
    '&:hover': {
      backgroundColor: '#00B4AA',
    },
  },
}))(MUIButton);

export const Header = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & > .title {
    color: #424242;
    font-family: 'Poppins Bold';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  width: 100%;
  font-family: 'Poppins Regular';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #757575;
`;

export const ButtonsBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
