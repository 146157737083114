import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchServices from 'services/searchServices';
import ActionCreators from 'modules/financing/store/reducers/actionCreators';
import OccupationForm from './OccupationForm'

const getCitieByState = async (ufData, callback) => {
  try {
    if (!ufData) {
      return;
    }
    const { success, data }: any = await SearchServices.getCitiesByState(ufData);
    if (success) {
      const arr: any = [{ value: 0, label: 'Selecione...' }];
      data.map(el => {
        arr.push({ label: el.name, value: el.id });
      });
      callback(arr);
    }
  } catch (e) {
    console.log(e);
  }
}

const OccupationFormContainer = ({ formData, onChange, disableAllInputs = false, enableCopyButton = false }) => {
  //REDUX_HOOKS
  const {
    statesOptions,
    citiesOptions,
    streetTypeOptions,
    addressTypeOptions,
    careersOptions,
    employmentsOptions,
  } = useSelector(state => state.financing);
  const dispatch = useDispatch();

  //CITIES
  const [employmentCitiesOptions, setEmploymentCitiesOptions] = useState(
    citiesOptions
  );

  //GET_ADDRESS_INFO_BY_ZIP
  const getAddresInfosByZipCode = async () => {
    try {
      const { success, data }: any = await SearchServices.getLocationByZipcode(
        formData['employmentZipcode']
      );
      if (success) {
        if (data.uf) {
          dispatch(ActionCreators.getCitieByState(data.uf));
          onChange({ target: { name: 'employmentCityStateId', value: data.uf } });
          onChange({
            target: { name: 'employmentAddress', value: data.logradouro },
          });
          onChange({
            target: { name: 'employmentCityId', value: data.cidadeId },
          });
          onChange({
            target: { name: 'employmentStreetType', value: data.tipoEndereco },
          });
          onChange({ target: { name: 'employmentNeighborhood', value: data.bairro } });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  //GET_ADDRES_INFOS_BY_CEP_ON_PRESS_ENTER
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      getAddresInfosByZipCode();
    }
  };

  //GET_OPTIONS
  useEffect(() => {
    dispatch(ActionCreators.getStreetTypeOptions());
    dispatch(ActionCreators.getAddressTypeOptions());
    dispatch(ActionCreators.getCareersOptions());
    dispatch(ActionCreators.getEmploymentsOptions());
  }, []);

  //GET_CITIES_OPTIONS_ON_CHANGE_STATE
  useEffect(() => {
    getCitieByState(formData['employmentCityStateId'], setEmploymentCitiesOptions);
  }, [formData['employmentCityStateId']]);

  useEffect(() => {
    if (formData['employmentId'] === 4 || formData['employmentId'] === 8) {
      onChange({
        target: { name: 'employmentSocialName', value: '' },
      });
      onChange({
        target: { name: 'employmentStartDate', value: null },
      });
      onChange({
        target: { name: 'monthlyIncome', value: null },
      });
      onChange({
        target: { name: 'employmentZipcode', value: null },
      });
      onChange({
        target: { name: 'employmentStreetType', value: null },
      });
      onChange({
        target: { name: 'employmentAddress', value: '' },
      });
      onChange({
        target: { name: 'employmentNumber', value: '' },
      });
      onChange({
        target: { name: 'employmentComplement', value: '' },
      });
      onChange({
        target: { name: 'employmentNeighborhood', value: '' },
      });
      onChange({
        target: { name: 'employmentCityStateId', value: null },
      });
      onChange({
        target: { name: 'employmentCityId', value: null },
      });
    }
  }, [formData['employmentId']])

  const viewComponentProps = {
    statesOptions,
    employmentCitiesOptions,
    streetTypeOptions,
    addressTypeOptions,
    careersOptions,
    employmentsOptions,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  };

  return <OccupationForm {...viewComponentProps} />;
};

export default OccupationFormContainer;
