import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import bookVehicleService from 'modules/store-inventory/services/InventorySearchServices';
import CancelBlockedComponent from './CancelBlockedComponent';

const CancelBlockedContainer = ({ open, onClose, dealerId, blockedId, callbackOnSuccess }) => {
  const dispatch = useDispatch()
  const [blockedState, setBlockedState] = useState({
    reason: null,
    isLoading: false,
  });

  const cancelBlockVehicle = async () => {
    try {
      setBlockedState(prevState => ({ ...prevState, isLoading: true }));
      const response: any = await bookVehicleService.cancelBlockVehicle(
        dealerId,
        blockedId,
        blockedState.reason
      );
      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Veículo desbloqueado com sucesso.'
          )
        );
        setBlockedState(prevState => ({ ...prevState, isLoading: false }));
        callbackOnSuccess()
        onClose()
        return
      }
      setBlockedState(prevState => ({ ...prevState, isLoading: false }));
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    } catch {
      setBlockedState(prevState => ({ ...prevState, isLoading: false }));
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  };

  const handleChangeValue = event => {
    const { name, value } = event.target;
    setBlockedState(prevState => ({ ...prevState, [name]: value }));
  };

  const componentProps = {
    open,
    onClose,
    handleChangeValue,
    cancelBlockVehicle,
    reason: blockedState.reason,
    isLoading: blockedState.isLoading,
  };
  return <CancelBlockedComponent {...componentProps} />;
};

export default memo(CancelBlockedContainer);
