import React, { useState, memo, useRef, useEffect } from 'react';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #ffffff5c;
  padding: 0px 8px;
  border-radius: 4px;
`;

const AudioProgress = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  margin: 21px 8px 0px 8px;
`;

const MobiSlider = withStyles({
  root: {
    color: '#ffffff',
    height: 5,
    width: 100,
    padding: '6px 0',
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#4400d2',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

const MessageAudio = props => {
  const {
    message,
    currentAudioPlaying,
    setCurrentAudioPlaying,
    audiosRef,
  } = props;

  const [trackProgress, setTrackProgress] = useState(
    audiosRef?.current?.[message.id]?.trackProgress ?? 0
  );

  const isPlaying = currentAudioPlaying === message.id;

  const addNewAudio = () => {
    if (!audiosRef?.current?.[message.id]) {
      audiosRef.current = {
        ...audiosRef.current,
        [message.id]: new Audio(message.media.url),
        trackProgress: 0,
      };
    }
  };

  useEffect(() => {
    addNewAudio();
  }, []);

  const currentAudio = audiosRef?.current?.[message.id];

  const intervalRef = useRef();

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (currentAudio?.ended) {
        audiosRef.current[message.id].trackProgress = 0;
        setCurrentAudioPlaying(null);
        setTrackProgress(0);
        clearInterval(intervalRef.current);
        return;
      }
      audiosRef.current[message.id].trackProgress = currentAudio.currentTime;
      setTrackProgress(currentAudio.currentTime);
    }, [1000]);
  };

  useEffect(() => {
    if (currentAudio) {
      if (isPlaying) {
        currentAudio.play();
        startTimer();
      } else {
        currentAudio.pause();
      }
    }
  }, [currentAudio, isPlaying]);

  const secondsToMinutes = secondsFloat => {
    if (isNaN(secondsFloat)) {
      return '0:00';
    }
    const seconds = Math.floor(secondsFloat);
    const minutes = Math.floor(secondsFloat / 60);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  };

  return (
    <AudioWrapper>
      {isPlaying ? (
        <div
          style={{ display: 'flex' }}
          type="button"
          className="pause"
          onClick={() => {
            setCurrentAudioPlaying(null);
          }}
          aria-label="Pause"
        >
          <PauseCircleOutlineIcon style={{ width: '32px', height: '32px' }} />
        </div>
      ) : (
        <div
          style={{ display: 'flex' }}
          type="button"
          className="play"
          onClick={() => {
            setCurrentAudioPlaying(message?.id);
          }}
          aria-label="Play"
        >
          <PlayCircleOutlineIcon style={{ width: '32px', height: '32px' }} />
        </div>
      )}
      <AudioProgress>
        <MobiSlider
          value={trackProgress}
          min={0}
          step={1}
          max={parseInt(currentAudio?.duration)}
        />
        <div style={{ fontSize: '12px', lineHeight: '20px' }}>
          {secondsToMinutes(trackProgress)}/
          {secondsToMinutes(currentAudio?.duration)}
        </div>
      </AudioProgress>
    </AudioWrapper>
  );
};

export default memo(MessageAudio);
