import * as yup from 'yup';

const schema = yup.object().shape({
  typeDoc: yup
    .string()
    .required('Tipo de documento obrigatório')
    .test(
      'isString',
      'O tipo de documento deve ser uma string válida',
      function(value) {
        return typeof value === 'string';
      }
    ),
  uploadDoc: yup
    .array()
    .required('Documento obrigatório')
    .min(1, 'Adicione um documento válido'),
});

export default schema;
