import React, { memo } from 'react';
import ChatShowroomComponent from './ChatShowroomComponent';
import { useChatContext } from 'pages/chats/ChatsContext';

const ChatShowroomContainer = () => {
  const { currentProposal }: any = useChatContext();

  const viewComponentsProps = {
    currentProposal: currentProposal
  };

  return <ChatShowroomComponent {...viewComponentsProps} />;
};

export default memo(ChatShowroomContainer);
