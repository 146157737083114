import { all, takeLatest, put, call, select } from 'redux-saga/effects';

import InventoryService from 'services/inventoryServices';
import ProviderServices from 'services/providerServices';
import { Action } from 'types/ActionTypes';

import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import ActionCreator from './actionCreators';
import actionTypes from './actionTypes';

const DEFAULT_ERROR_MESSAGE =
  'Houve uma falha inesperada, entre em contato com a equipe suporte';

function* getProviderByType(action: Action) {
  try {
    const { type } = action.payload;
    const { currentDealer } = yield select(state => state.dealerStore);

    yield put(ActionCreator.setProviderStockLoading());

    const response = yield ProviderServices.getProviderByType(
      currentDealer.id,
      type
    );

    if (response.success) {
      yield put(ActionCreator.setProviderDataSuccess(response.data));
    }
  } catch {
    yield put(
      UIActionCreators.snackbarManagement('error', DEFAULT_ERROR_MESSAGE)
    );

    yield put(
      ActionCreator.setConnectionStatus(false, true, false, null, null)
    );
  }
}

function* reconnectProvider(action: Action) {
  try {
    const { providerId, credentialsDto } = action.payload;
    const { currentDealer } = yield select(state => state.dealerStore);
    const { currentTab } = yield select(state => state.providers);

    yield put(ActionCreator.syncPlanLoading(true));

    yield put(
      ActionCreator.setConnectionStatus(false, false, true, null, null)
    );

    const response = yield ProviderServices.connect(
      currentDealer.id,
      providerId,
      currentTab,
      credentialsDto
    );

    if (!response.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response?.data || DEFAULT_ERROR_MESSAGE
        )
      );

      return yield put(
        ActionCreator.setConnectionStatus(false, true, false, null, null)
      );
    }

    yield put(
      ActionCreator.setConnectionStatus(
        true,
        false,
        false,
        response.data,
        response.url
      )
    );

    if (response.data?.redirect) {
      const newTab = window.open(response.data.redirect);
      newTab?.focus();
      return;
    }

    return yield put(
      UIActionCreators.snackbarManagement(
        'success',
        response?.data?.callBack || 'Você foi reconectado!'
      )
    );
  } catch {
    yield put(
      UIActionCreators.snackbarManagement('error', DEFAULT_ERROR_MESSAGE)
    );

    yield put(
      ActionCreator.setConnectionStatus(false, true, false, null, null)
    );
  } finally {
    yield put(ActionCreator.syncPlanLoading(false));
  }
}

function* connectProvider(action: Action) {
  try {
    const { providerId, credentialsDto } = action.payload;
    const { currentDealer } = yield select(state => state.dealerStore);
    const { currentTab } = yield select(state => state.providers);

    yield put(
      ActionCreator.setConnectionStatus(false, false, true, null, null)
    );

    const response = yield call(
      ProviderServices.connect,
      currentDealer.id,
      providerId,
      currentTab,
      credentialsDto
    );

    console.log({ response });

    if (!response?.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response?.callBack || response?.data || DEFAULT_ERROR_MESSAGE
        )
      );

      return yield put(
        ActionCreator.setConnectionStatus(false, true, false, null, null)
      );
    }

    yield put(
      ActionCreator.setConnectionStatus(
        true,
        false,
        false,
        response.data,
        response.url
      )
    );

    if (response.data?.redirect) {
      const newTab = window.open(response.data.redirect);
      newTab?.focus();
      return;
    }

    yield put(ActionCreator.getProviderByType(currentTab));
  } catch {
    yield put(
      UIActionCreators.snackbarManagement('error', DEFAULT_ERROR_MESSAGE)
    );

    yield put(
      ActionCreator.setConnectionStatus(false, true, false, null, null)
    );
  }
}

function* disconnectProvider(action: Action) {
  try {
    const { providerId } = action.payload;
    const { currentDealer } = yield select(state => state.dealerStore);
    const { currentTab } = yield select(state => state.providers);

    yield put(ActionCreator.syncPlanLoading(true));

    const response = yield ProviderServices.disconnectProvider(
      currentDealer.id,
      providerId,
      currentTab
    );

    if (!response.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response?.data || DEFAULT_ERROR_MESSAGE
        )
      );

      return yield put(
        ActionCreator.setConnectionStatus(false, true, false, null, null)
      );
    }

    yield put(
      UIActionCreators.snackbarManagement(
        'success',
        response?.data?.callBack || 'Você foi desconectado do portal!'
      )
    );

    yield put(ActionCreator.getProviderByType(currentTab));
  } catch {
    yield put(
      UIActionCreators.snackbarManagement('error', DEFAULT_ERROR_MESSAGE)
    );

    yield put(
      ActionCreator.setConnectionStatus(false, true, false, null, null)
    );
  } finally {
    yield put(ActionCreator.syncPlanLoading(false));
  }
}

function* syncPlan(action: Action) {
  try {
    const { providerId } = action.payload;
    const { currentDealer } = yield select(state => state.dealerStore);

    yield put(ActionCreator.syncPlanLoading(true));

    const response = yield InventoryService.syncPlansProvider(
      currentDealer.id,
      providerId
    );

    if (!response.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response?.data || DEFAULT_ERROR_MESSAGE
        )
      );

      return yield put(
        ActionCreator.setConnectionStatus(false, true, false, null, null)
      );
    }

    yield put(
      UIActionCreators.snackbarManagement(
        'success',
        response?.data?.callBack || 'Planos sincronizados com sucesso'
      )
    );
  } catch {
    yield put(
      UIActionCreators.snackbarManagement('error', DEFAULT_ERROR_MESSAGE)
    );

    yield put(
      ActionCreator.setConnectionStatus(false, true, false, null, null)
    );
  } finally {
    yield put(ActionCreator.syncPlanLoading(false));
  }
}

function* changeActivationProvider(action: Action) {
  try {
    const { providerId, status } = action.payload;
    const { currentDealer } = yield select(state => state.dealerStore);
    const { currentTab } = yield select(state => state.providers);

    yield put(ActionCreator.syncPlanLoading(true));

    const response = yield ProviderServices.changeActivationProvider(
      currentDealer.id,
      providerId,
      currentTab,
      status
    );

    if (!response.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response?.data ||
            `Falha na ${!status ? 'ativação' : 'desativação'} do portal`
        )
      );

      return yield put(
        ActionCreator.setConnectionStatus(false, true, false, null, null)
      );
    }

    yield put(ActionCreator.changeProviderSwitchStock(providerId));

    yield put(
      UIActionCreators.snackbarManagement(
        'success',
        response?.data?.callBack ||
          `Portal ${status ? 'ativado' : 'desativado'} com sucesso`
      )
    );
  } catch {
    const { status } = action.payload;

    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        `Falha na ${!status ? 'ativação' : 'desativação'} do portal`
      )
    );

    yield put(
      ActionCreator.setConnectionStatus(false, true, false, null, null)
    );
  } finally {
    yield put(ActionCreator.syncPlanLoading(false));
  }
}

function* getTabs() {
  try {
    const { currentDealer } = yield select(state => state.dealerStore);

    const response = yield ProviderServices.getTabs(currentDealer.id);

    if (!response?.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response?.data || DEFAULT_ERROR_MESSAGE
        )
      );

      return yield put(
        ActionCreator.setConnectionStatus(false, true, false, null, null)
      );
    }

    yield put(ActionCreator.setTabs(response?.data ?? []));

    if (response?.data?.[0]?.name) {
      yield put(ActionCreator.setCurrentTab(response.data[0]?.name));
    }
  } catch {
    yield put(
      UIActionCreators.snackbarManagement('error', DEFAULT_ERROR_MESSAGE)
    );

    return yield put(
      ActionCreator.setConnectionStatus(false, true, false, null, null)
    );
  }
}

export default all([
  takeLatest(actionTypes.GET_TABS, getTabs),
  takeLatest(actionTypes.GET_PROVIDER_BY_TYPE, getProviderByType),
  takeLatest(actionTypes.CONNECT_PROVIDER, connectProvider),
  takeLatest(actionTypes.DISCONNECT_PROVIDER, disconnectProvider),
  takeLatest(actionTypes.RECONNECT_PROVIDER, reconnectProvider),
  takeLatest(actionTypes.SYNC_PLAN, syncPlan),
  takeLatest(actionTypes.CHANGE_ACTIVATION_STATUS, changeActivationProvider),
]);
