import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { DealRepasse } from 'types/DealTypes';

interface State {
  deals: DealRepasse[];
  totalElements: undefined | number;
  totalPages: undefined | number;
  number: undefined | number;
  size: undefined | number;
  notFilteredTotalElements: undefined | number;
  isEmpty?: undefined | boolean;
  isLoading?: boolean;
  errorLoading?: boolean;
  hadErrorDeleting?: boolean;
  isDeleting?: boolean;
  textSearch: string;
  orderValue: number;
  infosCard: Record<string, any>;
  isCardError?: boolean;
  getContactDataError?: boolean;
  contactData: Record<string, any>;
  contactIsLoad: boolean;
  isActive: boolean;
  isInactive: boolean;
}

export const INITIAL_STATE: State = {
  deals: [],
  totalElements: undefined,
  totalPages: undefined,
  number: undefined,
  size: undefined,
  notFilteredTotalElements: undefined,
  isEmpty: undefined,
  isLoading: false,
  errorLoading: false,
  hadErrorDeleting: true,
  isDeleting: false,
  textSearch: '',
  orderValue: 0,
  infosCard: {},
  isCardError: false,
  getContactDataError: false,
  contactData: {},
  contactIsLoad: false,
  isActive: false,
  isInactive: false,
};

export default function dealForm(
  state: State = INITIAL_STATE,
  action: Action
): State {
  switch (action.type) {
    case ActionTypes.GET_DEALS_SUCCEDED:
      return {
        ...state,
        isLoading: false,
        errorLoading: false,
        deals: action.payload.content,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        number: action.payload.number,
        size: action.payload.size,
        notFilteredTotalElements: action.payload.notFilteredTotalElements,
        isEmpty: action.payload.notFilteredTotalElements === 0,
      };
    case ActionTypes.GET_DEALS_LOADING:
      return { ...state, isLoading: true, errorLoading: false };
    case ActionTypes.GET_DEALS_ERROR:
      return {
        ...state,

        isLoading: false,
        errorLoading: true,
        //reseting deals
        deals: INITIAL_STATE.deals,
        totalElements: INITIAL_STATE.totalElements,
        totalPages: INITIAL_STATE.totalPages,
        number: INITIAL_STATE.number,
        size: INITIAL_STATE.size,
        notFilteredTotalElements: INITIAL_STATE.notFilteredTotalElements,
        isEmpty: INITIAL_STATE.isEmpty,
      };
    case ActionTypes.DELETING_DEAL:
      return { ...state, isDeleting: true };
    case ActionTypes.DELETE_DEAL_ERROR:
      return { ...state, hadErrorDeleting: true, isDeleting: false };
    case ActionTypes.DELETE_DEAL_SUCCEDED: {
      const filteredDeals = [...state.deals].filter(
        deal => deal.id !== action.payload
      );
      return { ...state, deals: [...filteredDeals], isDeleting: false };
    }
    case ActionTypes.ACTIVING_DEAL:
      return { ...state, isActive: true, isLoading: true };
    case ActionTypes.ACTIVATE_DEAL_ERROR:
      return { ...state, hadErrorDeleting: true, isActive: false, isLoading: false };
    case ActionTypes.ACTIVATE_DEAL_SUCCEDED: {
      const dealPosition = state.deals.findIndex(
        deal => deal.id === action.payload.id
      );
      const deals = [...state.deals];
      deals[dealPosition] = action.payload;
      return { ...state, deals: [...deals], isActive: false, isLoading: false };
    }

    case ActionTypes.INACTIVING_DEAL:
      return { ...state, isInactive: true, isLoading: true };
    case ActionTypes.INACTIVATE_DEAL_ERROR:
      return { ...state, hadErrorDeleting: true, isInactive: false, isLoading: false };
    case ActionTypes.INACTIVATE_DEAL_SUCCEDED: {
      const dealPosition = state.deals.findIndex(
        deal => deal.id === action.payload.id
      );
      const deals = [...state.deals];
      deals[dealPosition] = action.payload;
      return { ...state, deals: [...deals], isInactive: false, isLoading: false };
    }

    case ActionTypes.UPDATE_DEAL: {
      const dealPosition = state.deals.findIndex(
        deal => deal.id === action.payload.id
      );
      const deals = [...state.deals];
      deals[dealPosition] = action.payload;

      return { ...state, deals: [...deals] };
    }
    case ActionTypes.SET_DEALS_INFOS:
      return { ...state, isEmpty: action.payload.isEmpty };
    case ActionTypes.ADD_DEAL:
      return { ...state, deals: [action.payload, ...(state.deals || [])] };
    case ActionTypes.RESET_DELETE_DEAL:
      return {
        ...state,
        isLoading: INITIAL_STATE.isLoading,
        errorLoading: INITIAL_STATE.errorLoading,
        hadErrorDeleting: INITIAL_STATE.hadErrorDeleting,
        isDeleting: INITIAL_STATE.isDeleting,
      };
    case ActionTypes.SET_TXT_SEARCH:
      return {
        ...state,
        textSearch: action.payload,
      };
    case ActionTypes.SET_BY_ORDER:
      return {
        ...state,
        orderValue: action.payload,
      };
    case ActionTypes.SET_INFO_CARD:
      return {
        ...state,
        infosCard: action.payload,
      };
    case ActionTypes.SET_INFO_CARD_ERROR:
      return {
        ...state,
        isCardError: true,
      };
    case ActionTypes.SET_CONTACT_INFO:
      return {
        ...state,
        contactData: action.payload,
      };
    case ActionTypes.SET_CONTACT_INFO_ERROR:
      return {
        ...state,
        getContactDataError: true,
      };
    case ActionTypes.SET_CONTACT_INFO_LOADING:
      return {
        ...state,
        contactIsLoad: action.payload,
      };
    default:
      return state;
  }
}
