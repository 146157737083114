import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PaymentIcon from '@material-ui/icons/Payment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import { ProposalOrderStep } from 'modules/order/types/ProposalOrder';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import PaymentDataComponent from './steps/PaymentData';
import VehicleDataComponent from './steps/VehicleData';
import OrderNotes from './steps/OrderNotes';
import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import { IconContainer, ExpansionPanelContainer } from './styles';

function ProposalData() {
  const { proposalStep, isFinished } = useSelector(state => state.order);
  const dispatch = useDispatch();

  const setProposalStep = (step: ProposalOrderStep) => {
    dispatch(ActionCreators.setProposalStep(step));
  };

  const VehicleTitle = (
    <ExpansionPanelTitle
      title={'Veículo'}
      IconComponent={
        <IconContainer>
          <DriveEtaIcon />
        </IconContainer>
      }
    />
  );
  const PaymentTitle = (
    <ExpansionPanelTitle
      title={'Pagamento'}
      IconComponent={
        <IconContainer>
          <PaymentIcon />
        </IconContainer>
      }
    />
  );
  const CommentsTitle = (
    <ExpansionPanelTitle
      title={'Observações'}
      IconComponent={
        <IconContainer>
          <InsertCommentIcon />
        </IconContainer>
      }
    />
  );

  return (
    <ExpansionPanelContainer>
      <ExpansionPanel
        panel={{ title: VehicleTitle }}
        openManual={
          isFinished ? null : proposalStep === ProposalOrderStep.VEHICLE
        }
      >
        <VehicleDataComponent
          handleNextPanel={() => setProposalStep(ProposalOrderStep.PAYMENT)}
        />
      </ExpansionPanel>
      <div style={{ height: 16 }} />

      <ExpansionPanel
        panel={{ title: PaymentTitle }}
        openManual={
          isFinished ? null : proposalStep === ProposalOrderStep.PAYMENT
        }
      >
        <PaymentDataComponent
          handlePreviousPanel={() => setProposalStep(ProposalOrderStep.VEHICLE)}
          handleNextPanel={() => setProposalStep(ProposalOrderStep.COMMENTS)}
        />
      </ExpansionPanel>

      <div style={{ height: 16 }} />

      <ExpansionPanel
        panel={{ title: CommentsTitle }}
        openManual={
          isFinished ? null : proposalStep === ProposalOrderStep.COMMENTS
        }
      >
        <OrderNotes
          handlePreviousPanel={() => setProposalStep(ProposalOrderStep.PAYMENT)}
          handleNextPanel={() => setProposalStep(ProposalOrderStep.PERSON)}
        />
      </ExpansionPanel>
    </ExpansionPanelContainer>
  );
}

export default ProposalData;
