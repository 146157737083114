/* eslint-disable prefer-const */
import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
// import { Proposal } from 'types/ProposalsTypes'

interface Snackbar {
  variant: string;
  message: string;
  isOpen: boolean;
}

interface State {
  currentProposal: Array<any>;
  negotiations: any;
  isLoading: boolean;
  snackbarState: Snackbar;
  haveHigherBidPriceDialog: { open: boolean };
  confirmationDialog: { open: boolean; isAccept: boolean; isRefused: boolean };
}

export const INITIAL_STATE: State = {
  currentProposal: [],
  negotiations: [],
  isLoading: false,
  snackbarState: {
    variant: '',
    message: '',
    isOpen: false,
  },
  haveHigherBidPriceDialog: { open: false },
  confirmationDialog: { open: false, isAccept: false, isRefused: false },
};

export default function proposal(
  state: State = INITIAL_STATE,
  action: Action
): State {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_PROPOSAL: {
      return {
        ...state,
        currentProposal: action.payload,
      };
    }
    case ActionTypes.SET_NEGOTIATION: {
      return {
        ...state,
        negotiations: action.payload,
      };
    }
    case ActionTypes.UPDATE_SNACKBAR_STATE: {
      return {
        ...state,
        snackbarState: { ...state.snackbarState, ...action.payload },
      };
    }
    case ActionTypes.MANAGE_HIGHER_BID_DIALOG: {
      return {
        ...state,
        haveHigherBidPriceDialog: { open: action.payload.open },
      };
    }
    case ActionTypes.MANAGE_CONFIRMATION_DIALOG: {
      return {
        ...state,
        confirmationDialog: {
          open: action.payload.open,
          isAccept: action.payload.isAccept,
          isRefused: action.payload.isRefused,
        },
      };
    }
    case ActionTypes.SET_INTERACTIONS_SUCCESS: {
      let tempSalesNegotiation = state.negotiations;
      if (state.negotiations?.negotiation) {
        let comboInteractions = state.negotiations.negotiation.interactions;

        comboInteractions = [
          ...comboInteractions,
          ...action.payload.interactions,
        ];
        tempSalesNegotiation.negotiation.interactions = comboInteractions;

        return {
          ...state,
          negotiations: { ...tempSalesNegotiation },
        };
      } else {
        const negotiationById = tempSalesNegotiation.find(
          item => item.id === action.payload.negotiationId
        );
        const negotiationIndexById = tempSalesNegotiation.findIndex(
          item => item.id === action.payload.negotiationId
        );
        negotiationById.interactions = [
          ...negotiationById.interactions,
          ...action.payload.interactions,
        ];
        tempSalesNegotiation[negotiationIndexById] = negotiationById;

        return {
          ...state,
          negotiations: [...tempSalesNegotiation],
        };
      }
    }

    default:
      return state;
  }
}
