import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import routesPath from 'config/routesPath';
import GenericDialog from 'components/GenericDialog';
import ErrorIcon from '@material-ui/icons/Error';
import OutlinedButton from 'components/atoms/OutlinedButton';
import { Flex, RedOutlinedButton, Text, WarningContainer } from './styles';
import QueueServices from 'services/queueServices';

const CancelOrderDialog = ({ open, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { currentDealer, proposal } = useSelector(state => ({
    ...state.dealerStore,
    ...state.order.queueData,
  }));

  const cancelOrder = async () => {
    const dealerId = currentDealer?.id;
    try {
      const response: any = await QueueServices.cancelOrder(
        dealerId,
        proposal.id
      );
      if (response.success) {
        onClose();
        history.push(
          `${routesPath.getRecursivePath(routesPath.proposals, {
            dealerId,
          })}?tab=2`
        );
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Pedido cancelado com sucesso!',
            true
          )
        );
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            response?.data ?? 'Desculpe, Ocorreu um erro inesperado.',
            true
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  };

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Cancelar pedido"
      maxWidth="sm"
      DialogAction={() => (
        <Flex>
          <OutlinedButton onClick={onClose}>Voltar</OutlinedButton>
          <div style={{ width: 10 }} />
          <RedOutlinedButton onClick={cancelOrder}>
            Cancelar Pedido
          </RedOutlinedButton>
        </Flex>
      )}
    >
      <div>
        <WarningContainer>
          <ErrorIcon style={{ marginRight: 10 }} />
          Pedido em andamento
        </WarningContainer>
        <Text>Você tem certeza que gostaria de cancelar este pedido ?</Text>
      </div>
    </GenericDialog>
  );
};

export default CancelOrderDialog;
