import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Label = styled.label`
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 7px;
  color: ${({ error }) => (error ? '#f44336' : 'rgba(0, 0, 0, 0.54)')};
  line-height: ${({ minHeight }) => (minHeight === '58px' ? '15px' : '13px')};
`;
