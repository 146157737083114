import { all, takeLatest, put, call } from 'redux-saga/effects';
import dealerServices from 'services/dealerServices';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';

function* getDealerById(action: any) {
  try {
    // yield put(ActionCreators.setAllProposalsbyGroupLoading(true));
    if (action.payload.dealerId) {
      const response = yield call(
        dealerServices.getDealerById,
        action.payload.dealerId
      );
      if (response.success) {
        yield put(ActionCreators.setDealer(response.data));
        return;
      } else {
        // yield put(ActionCreators.setAllProposalsbyGroupError(true));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export default all([takeLatest(ActionTypes.GET_DEALER_BY_ID, getDealerById)]);
