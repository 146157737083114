import styled from 'styled-components';
import { ButtonProps, Button as MuiButton } from '@material-ui/core'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
`;

export const Container = styled.div`
  max-height: calc(100% - 120px);
  overflow-y: auto;
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button: (props: ButtonProps) => JSX.Element = styled(MuiButton)`
  &.MuiButton-root {
    background-color: #3E68BD;
    font-family: "Poppins Regular";
    font-weight: 500;
    color: #ffffff;
    font-size: 14px;
    text-transform: initial;

    &:hover {
      background-color: #33579f;
    }

    & .MuiSvgIcon-root {
      font-size: 14px;
    }
  }
`;
