import styled from 'styled-components';

export const Container = styled.div`
  height: 300px;
  overflow-y: auto;
`;

export const FlexCenterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexItem = styled.div`
  text-align: center;
  align-content: flex-start;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
