// export default useUploadDocument;
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import OrderDocumentsServices from 'modules/order/services/OrderDocumentsServices';

const useUploadDocument = () => {
  const { dealerId, proposalId } = useSelector(state => state.order);

  const dispatch = useDispatch();

  return useMutation<any, any, any>(
    'upload_document',
    ({
      documentConfigId,
      fileName,
      mediaId,
      mediaType,
      proposalOrderPaymentId,
    }: {
      documentConfigId: string;
      fileName: string;
      mediaId: string;
      mediaType: 'IMAGE' | 'AUDIO' | 'VIDEO' | 'MEDIA';
      proposalOrderPaymentId?: string | number;
    }) =>
      OrderDocumentsServices.uploadDocument(dealerId, proposalId, {
        documentConfigId,
        fileName,
        mediaId,
        mediaType,
        proposalOrderPaymentId,
      }),
    {
      onError: () => {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro ao tentar subir o documento.'
          )
        );
      },
      onSuccess(data, { callbackOnSuccess }) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Documento enviado com sucesso.'
          )
        );
        typeof callbackOnSuccess === 'function' && callbackOnSuccess();
        return data;
      },
    }
  );
};

export default useUploadDocument;
