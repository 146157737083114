import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  &:hover {
    background: #e0e0e0;
  }
`;

export const Label = styled.label`
  color: #424242;
  font-size: 12px;
  line-height: 17px;
`;
