import MODULE from './module'
import actionOptions, { getters } from '../actionsOptions'

export default {
  GET_SCHEDULER_ALL_EVENT: `@${MODULE}/${getters.GET}_SCHEDULER_ALL_EVENT_${actionOptions.REQUEST}`,
  GET_SCHEDULER_EVENT_BY_ID: `@${MODULE}/${getters.GET}_SCHEDULER_EVENT_BY_ID${actionOptions.REQUEST}`,
  CREATE_SCHEDULER_EVENT: `@${MODULE}/${getters.SET}_CREATE_SCHEDULER_EVENT`,
  UPDATE_SCHEDULER_EVENT: `@${MODULE}/${getters.SET}_UPDATE_SCHEDULER_EVENT`,
  DELETE_SCHEDULER_EVENT: `@${MODULE}/${getters.DELETE}_SCHEDULER_EVENT`,
  
  GET_SCHEDULER_ALL_EVENT_SUCCESS: `@${MODULE}/${getters.GET}_SCHEDULER_ALL_EVENT_${actionOptions.SUCCESS}`,
  GET_SCHEDULER_BY_ID_EVENT_SUCCESS: `@${MODULE}/${getters.GET}_SCHEDULER_BY_ID_EVENT_${actionOptions.SUCCESS}`,
  UPDATE_SCHEDULER_EVENT_SUCCESS: `@${MODULE}/${getters.SET}_SCHEDULER_ALL_EVENT_${actionOptions.SUCCESS}`,
  DELETE_SCHEDULER_EVENT_SUCCESS: `@${MODULE}/${getters.DELETE}_SCHEDULER_ALL_EVENT_${actionOptions.SUCCESS}`,
  CREATE_SCHEDULER_EVENT_SUCCESS: `@${MODULE}/${getters.DELETE}_SCHEDULER_ALL_EVENT_${actionOptions.SUCCESS}`,

  GET_SCHEDULER_ALL_EVENT_ERROR: `@${MODULE}/${getters.GET}_SCHEDULER_ALL_EVENT_${actionOptions.ERROR}`,
  GET_SCHEDULER_BY_ID_EVENT_ERROR: `@${MODULE}/${getters.GET}_SCHEDULER_BY_ID_EVENT_${actionOptions.ERROR}`,
  UPDATE_SCHEDULER_EVENT_ERROR: `@${MODULE}/${getters.SET}_SCHEDULER_ALL_EVENT_${actionOptions.ERROR}`,
  DELETE_SCHEDULER_EVENT_ERROR: `@${MODULE}/${getters.DELETE}_SCHEDULER_ALL_EVENT_${actionOptions.ERROR}`,
  CREATE_SCHEDULER_EVENT_ERROR: `@${MODULE}/${getters.DELETE}_SCHEDULER_ALL_EVENT_${actionOptions.ERROR}`,

  SCHEDULER_LOADING: `@${MODULE}/${getters.GET}_SCHEDULER_ALL_EVENT_${actionOptions.LOADING}`,

}