import React, { useEffect, useState, useMemo, memo } from 'react';
import ProposalServices from 'services/proposalServices';
import ItemHeader from '../atoms/ItemHeader';
import { Lens, TripOrigin } from '@material-ui/icons';
import getInitialsName from 'helpers/getInitialsName';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import { useChatContext } from 'pages/chats/ChatsContext';
import {
  Container,
  ItemContainer,
  ControllersIcones,
  ItemFooter,
  ItemContent,
  OvalBigger,
} from './styles';

const TimelineItem = ({ item, isLast }) => {
  const itemDate = useMemo(() => new Date(item.date).toLocaleString(), [
    item.date,
  ]);

  return (
    <ItemContainer>
      <ControllersIcones>
        <div className="timelineDot">{!isLast ? <Lens /> : <TripOrigin />}</div>
        {!isLast && <div className="timelineConnect"></div>}
      </ControllersIcones>
      <ItemContent>
        <span className="timelineTitle">{item.message}</span>
        <ItemFooter>
          <div className="timelineDate">{itemDate}</div>
          <div className="timelineNameContent">
            <Tooltip title={item.ownerName}>
              <OvalBigger>{getInitialsName(item.ownerName)}</OvalBigger>
            </Tooltip>
          </div>
        </ItemFooter>
      </ItemContent>
    </ItemContainer>
  );
};

const TimelineComponent = () => {
  const [currentHistoric, setHistoric] = useState<any>([]);

  const { currentProposal }: any = useChatContext();

  const getInteractionsById = async () => {
    try {
      const { data, success }: any = await ProposalServices.getInteractionsById(
        currentProposal.dealerId,
        currentProposal.id,
        'CHAT',
        true
      );

      if (success) {
        setHistoric(data);
      }
    } catch {
      console.log('Error');
    }
  };

  useEffect(() => {
    getInteractionsById();
  }, []);

  return (
    <>
      <ItemHeader>Histórico de atendimento</ItemHeader>
      <Container>
        {!currentHistoric.length ? (
          <div>Ainda não há histórico de atendimento.</div>
        ) : (
          currentHistoric.map((item, index) => {
            return (
              <TimelineItem
                key={item.id}
                item={item}
                isLast={currentHistoric.length - 1 === index}
              />
            );
          })
        )}
      </Container>
    </>
  );
};

export default memo(TimelineComponent);
