import styled from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CancelButton = styled(Button)`
  background-color: transparent !important;
  color: #424242 !important;
  border: 1px solid #bdbdbd !important;
  font-weight: 500 !important;
  text-transform: none !important;
  margin-right: 10px !important;
`;

export const ConfirmButton = styled(Button)`
  background-color: transparent !important;
  color: #f44336 !important;
  border: 1px solid #f44336 !important;
  font-weight: 500 !important;
  text-transform: none !important;
`;

export const AlertTitle = styled.h4`
  font-weight: 600;
  font-size: 30px;
  color: #595959;
  margin: 0;
`;

export const QuestionText = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #424242;
  padding: 16px 16px 0 16px;
  display: flex;
`;

export const AlertActions = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

export const ErrorIcon = styled.span`
  display: flex;
  justify-content: center;
  width: 2em;
  height: 2em;
  margin-bottom: 20px;
  border: 0.1em solid transparent;
  border-radius: 50%;
  line-height: 2em;
  cursor: default;
  user-select: none;
  border-color: #facea8;
  color: #f8bb86;
  font-size: 4em;
`;
