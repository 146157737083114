import styled from 'styled-components';

import { DialogContent as MUIDialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const Title = styled('h6')`
  font-family: 'Poppins Bold';
  font-size: 1.25rem;
  color: #333;
  margin: 0;
  line-height: 48px;
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

export const Container = withStyles({
  root: {
    overflowX: 'hidden',
    '& > iframe': {
      width: '100%',
      height: 'calc(90vh - 80px)',
      border: '0',
      padding: '0',
      margin: '0',
    },
  },
})(MUIDialogContent);
