import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ContainerTitle = styled('b')`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #424242;
  font-family: 'Poppins Regular';
  font-weight: 400;
`;

export const List = styled('ul')`
  display: flex;
  flex-direction: ${props => (props.listMode ? 'column' : 'row')};
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1rem;
`;

export const Item = styled('li')`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fafafa;
  padding: 1rem;
  gap: 0.75rem;
  width: ${props =>
    !props.listMode && props.referencesLength > 1 ? '50%' : '100%'};

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .alert {
    font-family: 'Poppins Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5rem;
    color: #424242;
  }
`;

export const ItemTitle = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    color: #424242;
    font-family: 'Poppins Medium';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
`;

export const ItemPrice = styled('div')`
  background: #e0f3f1;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: #006b5d;
  font-family: 'Poppins Medium';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
`;

export const Divider = styled.div`
  display: block;
  margin: 0.5rem 0;
  width: 100%;

  &:before {
    content: '';
    background-color: #e0e0e0;
    width: 100%;
    height: 1px;
    display: block;
  }
`;
