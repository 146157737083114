import theme from 'modules/financial/pages/GeneralView/theme';
import styled from 'styled-components';

export const MyText = styled.p`
  color: #757575;
  font-size: 16px;
  line-height: 160%;
  color: ${theme.colors.mediumText};
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: flex-end;
`;
