import React from 'react';
import {
  CardInfos,
  ContentInfo,
  InstallmentValue,
  ItemDetails,
  ModalContainer,
  NumberOfInstallments,
  Row,
  StyledBackButton,
  TitleDetailsSimulation,
  TotalAmount,
  WrapperList,
  WrapperResultDetails,
  WrapperSimulationDetails,
} from '../style';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import _ from 'lodash';
import formatAmount from 'helpers/formatAmount';
import ScrollView from 'components/ScrollView';

interface DetailsCardProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  selectedSimulation: any;
  formatIdSimulation: (id: number) => string;
  formatTimeStamp: (timestamp: number) => string;
}

const DetailsCard = ({
  openModal,
  setOpenModal,
  selectedSimulation,
  formatIdSimulation,
  formatTimeStamp,
}: DetailsCardProps) => {
  return (
    <ModalContainer openModal={openModal}>
      <WrapperSimulationDetails>
        <StyledBackButton
          startIcon={<ChevronLeftIcon />}
          onClick={() => setOpenModal(false)}
          fullWidth={false}
        >
          Voltar
        </StyledBackButton>
        <TitleDetailsSimulation>
          Detalhes da simulação #{formatIdSimulation(selectedSimulation?.id)}
        </TitleDetailsSimulation>
        <CardInfos className="details">
          <Row>
            <ContentInfo>Data:</ContentInfo>
            <ContentInfo className="bold">
              {formatTimeStamp(selectedSimulation?.createdAt)}
              {/* {selectedSimulation?.createdAt} */}
            </ContentInfo>
          </Row>
          <Row>
            <ContentInfo>Parceiro:</ContentInfo>
            <ContentInfo className="bold">
              {_.capitalize(selectedSimulation?.name)}
            </ContentInfo>
          </Row>
          <Row>
            <ContentInfo>Entrada:</ContentInfo>
            <ContentInfo className="bold">
              {formatAmount(selectedSimulation?.downPayment, 'PT-BR', 'R$')}
            </ContentInfo>
          </Row>
        </CardInfos>
        <TitleDetailsSimulation>Resultado da simulação</TitleDetailsSimulation>
        <WrapperResultDetails>
          <ScrollView>
            <WrapperList>
              {React.Children.toArray(
                selectedSimulation?.simulation?.map(simulation => (
                  <ItemDetails>
                    <NumberOfInstallments className="details">
                      {simulation.installments}x
                    </NumberOfInstallments>
                    <InstallmentValue>
                      {formatAmount(simulation.installmentValue, 'PT-BR', 'R$')}
                    </InstallmentValue>
                    <TotalAmount className="details">
                      {formatAmount(
                        simulation.installmentValue * simulation.installments,
                        'PT-BR',
                        'R$'
                      )}
                    </TotalAmount>
                  </ItemDetails>
                ))
              )}
            </WrapperList>
          </ScrollView>
        </WrapperResultDetails>
      </WrapperSimulationDetails>
    </ModalContainer>
  );
};

export default DetailsCard;
