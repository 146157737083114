import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { useChatContext } from 'pages/chats/ChatsContext';
import MobiIAServices from 'services/mobiIAServices';

const usePostSmartChatSuggestion = () => {
  const dispatch = useDispatch();
  const { currentProposal }: any = useChatContext();

  return useMutation<any, any, any>(
    'smart_chat_suggestion_posting',
    params =>
      MobiIAServices.postMobiSmartChatSugestions(
        params.id,
        currentProposal?.id,
        params.textSuggestion
      ),
    {
      onError: () => {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro inesperado.',
            true
          )
        );
      },
    }
  );
};

export default usePostSmartChatSuggestion;
