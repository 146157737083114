import createSagaMiddleware from 'redux-saga';

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? global.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

export default sagaMiddleware;
