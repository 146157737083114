import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as EmptyTemplateMessages } from 'assets/empty-messages-template.svg';

import ThemeButton from 'components/atoms/ThemeButton';

import { useChatContext } from 'pages/chats/ChatsContext';
import proposalIsFinished from 'pages/chats/helpers/proposalIsFinished';
import { TemplatesDialog } from 'modules/messageTemplate/components/TemplatesDialog';

import ChannelTabs from './components/ChannelTabs';
import TextInputMessage from './components/TextInputMessage';
import ViewText from './components/ViewText';

import { Container, EmptyContainer, EmptyIcon } from './styles';

const ChatComponent = () => {
  const { currentDealer } = useSelector(({ dealerStore }) => dealerStore);
  const {
    otherStates: {
      currentChannel,
      canSendWhatsAppBusinessMessage,
      currentDealerProducts,
    },
    handleOnWhatsApp,
    handleWhatsAppEvent,
    currentProposal,
  }: any = useChatContext();

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const hasWhatsAppProduct = useMemo(
    () =>
      currentDealerProducts?.find(product => product.type === 'WHATSAPP') ||
      currentDealer.products?.find(product => product.type === 'WHATSAPP'),
    [currentDealer.products, currentDealerProducts]
  );

  return (
    <Container>
      <ChannelTabs hasWhatsAppProduct={hasWhatsAppProduct} />

      {currentChannel === 'CHAT' ? (
        <>
          <ViewText currentChannel={'CHAT'} key={'CHAT'} />
          <TextInputMessage disabled={proposalIsFinished(currentProposal)} />
        </>
      ) : (
        <>
          {hasWhatsAppProduct ? (
            <>
              <ViewText
                currentChannel={'WHATSAPP'}
                key={'WHATSAPP'}
                canSendWhatsAppBusinessMessage={canSendWhatsAppBusinessMessage}
              />
              <TextInputMessage
                canSendWhatsAppBusinessMessage={canSendWhatsAppBusinessMessage}
                disabled={proposalIsFinished(currentProposal)}
              />
            </>
          ) : (
            <>
              <EmptyContainer>
                <EmptyIcon>
                  <EmptyTemplateMessages />
                </EmptyIcon>
                <span>Sem histórico de mensagens.</span>
              </EmptyContainer>
              <div style={{ padding: 8 }}>
                <ThemeButton
                  onClick={() => setOpenTemplateDialog(true)}
                  fullWidth
                >
                  Ir para WhatsApp Web
                </ThemeButton>
                {openTemplateDialog && (
                  <TemplatesDialog
                    channel="WHATSAPP"
                    isOpen={openTemplateDialog}
                    onClose={() => setOpenTemplateDialog(false)}
                    onSubmit={message => {
                      handleWhatsAppEvent();
                      handleOnWhatsApp(message);
                      setOpenTemplateDialog(false);
                    }}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default React.memo(ChatComponent);
