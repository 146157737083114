import React from 'react';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import GenericDialog from 'components/GenericDialog';
import StepperComponent from 'modules/financing/components/atoms/StepperComponent';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ThemeButton from 'components/atoms/ThemeButton';
import { ActionsContainer } from './styles';

const CreateNewProposalModalComponent = ({
  isOpen,
  handleOnClose,
  //STEPS
  stepsConfig,
  getCurrentStepContent,
  handleClickToNextStep,
  handleClickToBackStep,
  createNewProposal,
  checkCurrentStep,
  createNewProposalIsLoading,
}) => {
  const DialogActionComponent = () => (
    <ActionsContainer>
      {stepsConfig.currentStep !== 0 && (
        <OutlinedButton onClick={handleClickToBackStep}>Voltar</OutlinedButton>
      )}
      <div style={{ width: 8 }} />
      {stepsConfig.currentStep === stepsConfig.steps.length - 1 ? (
        <ThemeButton
          onClick={createNewProposalIsLoading ? null : createNewProposal}
          id="create-proposal-button-modal"
        >
          {createNewProposalIsLoading ? (
            <Progress small style={{ marginTop: 9 }} />
          ) : (
            'Criar proposta'
          )}
        </ThemeButton>
      ) : (
        <ThemeButton
          disabled={checkCurrentStep(stepsConfig.currentStep)}
          onClick={handleClickToNextStep}
        >
          Continuar
        </ThemeButton>
      )}
    </ActionsContainer>
  );

  return (
    <GenericDialog
      open={isOpen}
      onClose={handleOnClose}
      title="Criando proposta"
      aria-labelledby="Criando proposta"
      DialogAction={DialogActionComponent}
      maxWidth="xl"
    >
      <div style={{ padding: 8 }}>
        <div>
          <StepperComponent
            steps={stepsConfig.steps}
            stepperConfig={{
              currentStep: stepsConfig.currentStep,
              completedSteps: stepsConfig.completedSteps,
            }}
            handClickToGoStep={() => {
              return;
            }}
          />
        </div>
        <div style={{ height: 32 }}></div>
        <div>{getCurrentStepContent(stepsConfig.currentStep)}</div>
      </div>
    </GenericDialog>
  );
};
export default CreateNewProposalModalComponent;
