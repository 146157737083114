import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  margin-right: 16px;
`;

export const SubTitle = styled.div`
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

export const Container = styled.div`
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  border: 1px #e0e0e0 solid;
  border-radius: 5px;
  margin: 16px 0px;
`;

export const ResponseTitle = styled.div`
  display: flex;
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

export const Response = styled.div`
  display: flex;
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
