import React from 'react';
import getImage from 'components/getImage';
import { SquareImageComponent } from './styles';

const SquareImage = ({ size = '98px', imageId }) => {
  return (
    <SquareImageComponent size={size} src={getImage({ imageId: imageId })} />
  );
};

export default SquareImage;
