import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import ProposalService from 'services/proposalServices';
import { defaultOptions } from 'modules/financing/helpers/defaultOptions';
import { useChatContext } from 'pages/chats/ChatsContext';
import FormSimulation from './FormSimulation';

const generateYearsOptions = () => {
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 20))
    .fill('')
    .map((v, idx) => now - idx)
    .map(year => ({ label: `${year}`, value: year }));
  return [...defaultOptions, ...years];
};

const FormSimulationContainer = () => {
  const { currentProposal }: any = useChatContext();
  const { currentDealer } = useSelector(state => state.dealerStore);
  const [financingData, setFinancingData] = useState({
    inputValue: 0,
    installments: 0,
    modelYear: 0,
    state: currentDealer.state,
    vehiclePrice: 0,
  });
  const [isLoading, setLoading] = useState(false);

  const [financingSimulationResult, setFinancingSimulatonResult] = useState(
    null
  );

  // PAYLOAD
  // coefficient: 0.05334
  // finalYear: 2012
  // financingTableCode: "BVNT9"
  // id: 2
  // initialYear: 2010
  // inputPercentage: 40
  // installmentValue: 124
  // installments: 24
  // maxInstallmentValue: 131
  // minInstallmentValue: 118
  // rate: 1.8
  // tac: 0

  const sendFinancingSimulation = async () => {
    try {
      setLoading(true);
      const {
        success,
        data,
      }: any = await ProposalService.coldSimulationFinancing(
        currentDealer.id,
        currentProposal.id,
        financingData
      );
      if (success) {
        setFinancingSimulatonResult(data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const manageFinancingData = (attributeName, value) => {
    setFinancingData(prevState => ({ ...prevState, [attributeName]: value }));
  };

  const viewComponentProps = {
    sendFinancingSimulation,
    yearsOptions: generateYearsOptions(),
    onChange: manageFinancingData,
    inputValue: financingData.inputValue,
    installments: financingData.installments,
    modelYear: financingData.modelYear,
    vehiclePrice: financingData.vehiclePrice,
    isLoading: isLoading,
    financingSimulationResult,
  };

  return <FormSimulation {...viewComponentProps} />;
};

export default memo(FormSimulationContainer);
