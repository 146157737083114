import { Category } from 'modules/financial/types';
import { Action } from 'types/ActionTypes';

import actionTypes from './actionTypes';

interface InitalStateCategories {
  categories: Category[];
}

const initialState: InitalStateCategories = {
  categories: [],
};

const categoriesReducer = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case actionTypes.RELOAD_ALL_CATEGORIES:
      return { ...state, categories: payload.categories };

    // case actionTypes.SAVE_CATEGORIE:
    //     return {...state, categories: [...state.categories, payload.categories] }

    case actionTypes.UPDATE_CATEGORY: {
      const { updatedCategory } = payload;

      const categoryToUpdateIndex = state.categories.findIndex(
        category => category.id == updatedCategory.id
      );
      const categories = state.categories;

      if (categoryToUpdateIndex >= 0) {
        categories.splice(categoryToUpdateIndex, 1, updatedCategory);
      }

      return { ...state, categories: categories };
    }
    case actionTypes.REMOVE_CATEGORY: {
      const categories = state.categories;
      const categoryToRemoveIndex = categories.findIndex(
        category => category.id == payload.id
      );
      categories.splice(categoryToRemoveIndex, 1);

      return { ...state, categories: categories };
    }
    default:
      return state;
  }
};

export default categoriesReducer;
