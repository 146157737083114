/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import OrderServices from 'modules/order/services/OrderServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

const useGetFinancialAdvanced = () => {
  const dispatch = useDispatch();
  const { currentProposal } = useSelector(state => state.proposalStore);

  return useQuery({
    queryKey: 'get_financial_advanced',
    queryFn: () =>
      OrderServices.getFinancialAdvanced(
        currentProposal.dealerId,
        currentProposal.proposalId
      ),
    refetchOnWindowFocus: false,
    // enabled: false,
    onSuccess(props: { success: boolean; data: any }) {
      if (!props?.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            props?.data ?? 'Ocorreu um erro ao consultar adiantamentos.'
          )
        );
        return [];
      }
      return props.data;
    },
    onError() {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao consultar adiantamentos.'
        )
      );
    },
  });
};

export default useGetFinancialAdvanced;
