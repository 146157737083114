import React from 'react';
import Grid from '@material-ui/core/Grid';
import Select from 'modules/financing/components/atoms/Select';
import { TextMaskCustom, CPFMaskCustom } from 'components/MaskTextInput';
import getDateByDatePickerComponent from 'modules/financing/helpers/getDateByDatePickerComponent';
import TextInput from 'modules/financing/components/atoms/TextField';
import { escolarityTypeOptions } from 'modules/financing/helpers/defaultOptions';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput';

const PersonalInfosForm = props => {
  const {
    genderOptions,
    civilStatusOptions,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton,
    disableCpfCnpj,
    allRequired,
    statesOptions,
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="cpf"
            name="cpf"
            label="CPF"
            value={formData['cpf']}
            disabled={disableAllInputs || disableCpfCnpj}
            InputProps={{
              inputComponent: CPFMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('cpf')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="name"
            name="name"
            label="Nome Completo"
            value={formData['name']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('name')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="birthDate"
            name="birthDate"
            type="date"
            label="Data de nascimento"
            value={getDateByDatePickerComponent(formData['birthDate'])}
            onChange={onChange}
            disabled={disableAllInputs}
            required={allRequired}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="fatherName"
            name="fatherName"
            label="Nome do pai"
            value={formData['fatherName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('fatherName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="motherName"
            name="motherName"
            label="Nome da mãe"
            value={formData['motherName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('motherName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="email"
            name="email"
            type="email"
            label="E-mail"
            value={formData['email']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('email')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="phone"
            name="phone"
            label="Telefone 1"
            value={formData['phone']}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('phone')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="phone2"
            name="phone2"
            label="Telefone 2"
            value={formData['phone2']}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('phone2')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="nationality"
            name="nationality"
            label="Nacionalidade"
            value={formData['nationality']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('nationality')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="gender"
            name="gender"
            label="Sexo"
            value={formData['gender']}
            onChange={onChange}
            options={genderOptions}
            disabled={disableAllInputs}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="civilStatus"
            name="civilStatus"
            label="Estado Civil"
            value={formData['civilStatus']}
            onChange={onChange}
            options={civilStatusOptions}
            disabled={disableAllInputs}
            required={allRequired}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <Select
            id="schoolingType"
            name="schoolingType"
            label="Escolaridade"
            value={formData['schoolingType']}
            onChange={onChange}
            options={escolarityTypeOptions}
            disabled={disableAllInputs}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="rgNumber"
            name="rgNumber"
            label="RG"
            value={formData['rgNumber']}
            disabled={disableAllInputs}
            onChange={onChange}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('rgNumber')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="rgIssuer"
            name="rgIssuer"
            label="Orgão emissor"
            value={formData['rgIssuer']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('rgIssuer')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <Select
            id="rgIssuedState"
            name="rgIssuedState"
            label="UF do orgão emissor"
            value={formData['rgIssuedState']}
            onChange={onChange}
            options={statesOptions}
            disabled={disableAllInputs}
            inputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('schoolingType')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="rgIssuedAt"
            type="date"
            name="rgIssuedAt"
            label="Data de emissão do RG"
            value={getDateByDatePickerComponent(formData['rgIssuedAt'])}
            onChange={onChange}
            disabled={disableAllInputs}
            required={allRequired}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PersonalInfosForm;
