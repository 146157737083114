import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TabPanel from 'components/StyledComponents/TabPanel';
import StyledTab from 'components/StyledComponents/StyledTab';
import StyledTabs from 'components/StyledComponents/StyledTabs';
import ListDeals from 'modules/financing/components/molecules/ListDeals';
import ListVehiclesByMakeId from 'modules/financing/components/molecules/ListVehiclesByMakeId';
import VehicleOfInterest from 'components/molecules/VehicleOfInterest';
import ChooseModel0Km from 'components/molecules/ChooseModel0Km';
import { ListDealsQueryOptions } from 'modules/financing/components/molecules/ListDeals/ListDealsContainer';

const ChooseVehicleTabsComponent = ({
  selectedVehicles,
  handleSelectVehicle,
  isStock = true,
  isCatalog = true,
  isProfile = true,
  isModels0Km = false,
  allAvailable = false,
  listType = 'SHOWROOM',
  extraDefaultSopValues = {},
  multipleSelection = false,
}) => {
  const { currentDealer } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
  }));
  const [state, setState] = useState({ currentTab: !isStock ? 2 : 0 });

  const handleChangeTab = (_, newTab) => {
    setState(prevState => ({ ...prevState, currentTab: newTab }));
  };

  const options: ListDealsQueryOptions = {
    evaluation: true,
    evaluationStatus: 'FINISHED',
  };

  return (
    <>
      {listType === 'SHOWROOM' && (
        <StyledTabs value={state.currentTab} onChange={handleChangeTab}>
          {isStock && <StyledTab value={0} label="Meu Estoque" />}
          {isModels0Km && currentDealer?.makeId && (
            <StyledTab value={1} label="Modelos 0km" />
          )}
          {isCatalog && <StyledTab value={2} label="Catálogo 0km" />}
          {isProfile && <StyledTab value={3} label="Perfil do Cliente" />}
        </StyledTabs>
      )}
      <TabPanel value={state.currentTab} index={0} key={'estoque'}>
        {state.currentTab === 0 && (
          <div style={{ paddingTop: 16 }}>
            <ListDeals
              selectedVehicles={selectedVehicles}
              handleClickItem={handleSelectVehicle}
              allAvailable={allAvailable}
              extraDefaultSopValues={extraDefaultSopValues}
              multipleSelection={multipleSelection}
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={state.currentTab} index={1} key={'model0km'}>
        {state.currentTab === 1 && (
          <div style={{ paddingTop: 16 }}>
            <ChooseModel0Km
              selectedModel={selectedVehicles?.[0]}
              handleClickItem={vehicle => {
                handleSelectVehicle({ ...vehicle, type: '0km' });
              }}
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={state.currentTab} index={2} key={'0km'}>
        {state.currentTab === 2 && (
          <div style={{ paddingTop: 16 }}>
            <ListVehiclesByMakeId
              selectedItemId={selectedVehicles[0]?.id}
              handleClickItem={vehicle => {
                handleSelectVehicle({ ...vehicle, type: '0km' });
              }}
              queryOptions={options}
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={state.currentTab} index={3} key={'perfil'}>
        {state.currentTab === 3 && (
          <div style={{ paddingTop: 16 }}>
            <VehicleOfInterest handleClickItem={handleSelectVehicle} />
          </div>
        )}
      </TabPanel>
    </>
  );
};

export default ChooseVehicleTabsComponent;
