import buildHTTPResponseHandler from "helpers/buildHTTPResponseHandlerTS";
import {requestModule as request} from "./index";

const serviceName = "PaymentMethodsServices"

class PaymentMethodsServices {

  static list = async()=> {
    const response = await request
      .get('paymentmethod/v1.0/paymentmethods')

    const responseHandler =await buildHTTPResponseHandler(
          `${serviceName}.list()`)

    return responseHandler(response);
  };
  
}

export default PaymentMethodsServices;
