import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from 'components/atoms/Chip';
import IInvoiceCardTypes from './InvoiceCardTypes';

const InvoiceCardComponent: React.FC<IInvoiceCardTypes> = ({
  title,
  status,
  date,
  keyValue,
  number,
  moviment,
  refNote,
  loading = false,
  ...rest
}) => {
  const statusType = {
    Emitido: 'high',
    Cancelado: 'default',
  };

  return (
    <>
      {loading && (
        <Skeleton
          variant="rect"
          width={860}
          height={144}
          {...rest}
          style={{ marginTop: '20px' }}
        />
      )}
      {!loading && (
        <Box
          component="div"
          border="1px solid #E0E0E0;"
          borderRadius="4px"
          p={2}
          {...rest}
        >
          <Box
            component="div"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            alignItems="center"
          >
            <Box
              component="div"
              display="flex"
              alignItems="center"
              gridGap="15px"
            >
              <Box component="p" color="#424242" fontSize="18px">
                {title}
              </Box>
              <Chip type={statusType[status]}>{status}</Chip>
            </Box>
            {refNote && (
              <Box ml={12}>
                <Box component="span" color="#757575" fontSize="16px">
                  Nota de REF:
                </Box>

                <Box component="span" color="#424242" fontSize="16px">
                  {refNote}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            component="div"
            display="grid"
            gridTemplateColumns="0.6fr 1fr 0.3fr 1fr"
            style={{ gap: '12px' }}
          >
            <Box component="div" display="flex" flexDirection="column">
              <Box component="span" color="#757575" fontSize="16px">
                Data:{' '}
              </Box>
              <Box component="span" color="#424242" fontSize="16px">
                {moment(date).format('YYYY-MM-DD')}
              </Box>
            </Box>
            <Box component="div" display="flex" flexDirection="column">
              <Box component="span" color="#757575" fontSize="16px">
                Chave:
              </Box>
              <Box component="span" color="#424242" fontSize="16px">
                {keyValue}
              </Box>
            </Box>
            <Box component="div" display="flex" flexDirection="column">
              <Box component="span" color="#757575" fontSize="16px">
                Número:
              </Box>
              <Box component="span" color="#424242" fontSize="16px">
                {number}
              </Box>
            </Box>
            <Box component="div" display="flex" flexDirection="column">
              <Box component="span" color="#757575" fontSize="16px">
                Movimento:
              </Box>
              <span
                style={{
                  color: '#424242',
                  fontWeight: 'bold',
                }}
              >
                {moviment}
              </span>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default InvoiceCardComponent;
