import useProduct from './useProduct';

interface Props {
  hasMobiGpt: boolean;
  hasMobiGptForStock: boolean;
}

export default function useMobiIAProducts(): Props {
  const { hasProduct } = useProduct();

  const hasMobiGpt = hasProduct('MOBIGPT_FOR_LEADMANAGEMENT');
  const hasMobiGptForStock = hasProduct('MOBIGPT_FOR_STOCK');

  return { hasMobiGpt, hasMobiGptForStock };
}
