import React from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import Wrapper from 'components/Wrapper';
import ConfirmAppointment from '../ConfirmAppointment';
import { BoxTime, Title, BoxTimeDisable, BoxTimeScheduling } from './styles';

const SchedulingComponent = ({
  date = null,
  dataTable,
  proposalSelected,
  timeSlots,
  handleRowMouseLeave,
  translateUserType,
  handleMouseEnter,
  handleCellClick,
  greenCells,
  selectedCells,
  thereIsScheduling,
  openModalConfirmation,
  handleCloseModalConfirmation,
  isFetching,
}) => {
  return (
    <div className="App">
      {isFetching ? (
        <Wrapper flex justify="center" align="center" height={450}>
          <CircularProgress />
        </Wrapper>
      ) : (
        <>
          <Title>
            {date
              ? moment(date)
                  .locale('pt-br')
                  .format('dddd  L')
              : null}
          </Title>

          <table style={{ borderSpacing: '0', padding: '0px 16px' }}>
            <thead>
              <tr>
                <th></th>
                {timeSlots.map((timeSlot, index) => (
                  <th key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        marginLeft: '-8px',
                      }}
                    >
                      {timeSlot.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataTable?.map((item, rowIndex) => (
                <tr key={rowIndex} onMouseLeave={handleRowMouseLeave}>
                  <td>
                    <Wrapper flex width={100} direction="column">
                      <Wrapper color="#424242" fontSize={12} fontWeight={400}>
                        {item?.userName}
                      </Wrapper>
                      <Wrapper color="grey" fontSize={10} mt={-10}>
                        {`(${translateUserType(item?.userType)})`}
                      </Wrapper>
                    </Wrapper>
                  </td>
                  {timeSlots.map((timeSlot, columnIndex) => (
                    <td
                      key={columnIndex}
                      onMouseEnter={() =>
                        handleMouseEnter(rowIndex, columnIndex)
                      }
                      onClick={() => handleCellClick(rowIndex)}
                      style={{
                        padding: '0',
                      }}
                    >
                      {item?.intervals?.find(
                        el =>
                          el?.startTime ===
                          timeSlot
                            ?.toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                            })
                            ?.replace(':', '')
                      )?.available === 'AVAILABLE' ? (
                        <BoxTime
                          green={
                            greenCells.some(
                              cell =>
                                cell.row === rowIndex &&
                                cell.col === columnIndex
                            ) ||
                            selectedCells.some(
                              cell =>
                                cell.row === rowIndex &&
                                cell.col === columnIndex
                            )
                          }
                          disableCellGreen={thereIsScheduling}
                          lastColumn={columnIndex === timeSlots.length - 1}
                          style={{
                            margin: '0',
                          }}
                        >
                          + Agendar
                        </BoxTime>
                      ) : item?.intervals?.find(
                          el =>
                            el?.startTime ===
                            timeSlot
                              ?.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                              ?.replace(':', '')
                        )?.available === 'SCHEDULED' &&
                        item?.intervals?.find(
                          el =>
                            el?.startTime ===
                            timeSlot
                              ?.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                              ?.replace(':', '')
                        )?.scheduleId === proposalSelected?.schedule?.id ? (
                        <BoxTimeScheduling
                          style={{
                            margin: '0',
                          }}
                        >
                          Agendado
                        </BoxTimeScheduling>
                      ) : (
                        <BoxTimeDisable
                          style={{
                            margin: '0',
                          }}
                        >
                          Indisponível
                        </BoxTimeDisable>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {openModalConfirmation && (
        <ConfirmAppointment
          dataResponse={selectedCells}
          date={date}
          open={openModalConfirmation}
          onClose={handleCloseModalConfirmation}
          proposalSelected={proposalSelected}
        />
      )}
    </div>
  );
};

export default SchedulingComponent;
