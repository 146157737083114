import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  tooltip: {
    fontFamily: 'Poppins Medium',
    fontSize: '0.825rem',
    lineHeight: '1.25rem',
    color: '#FFF',
    backgroundColor: '#516766',
    padding: '0.75rem 1rem',
    borderRadius: '0.5rem',

    '& > .MuiTooltip-arrow': {
      color: '#516766',
    },
  },
});

function DefaultTooltip({ title, placement, classes, ...props }) {
  return (
    <Tooltip
      arrow
      title={title}
      placement={placement}
      classes={{ tooltip: classes.tooltip, customWidth: classes.customWidth }}
    >
      {props.children}
    </Tooltip>
  );
}

export default withStyles(styles)(DefaultTooltip);
