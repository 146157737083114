import React, { useEffect, useState, useRef, useCallback } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import isEmpty from 'helpers/isEmpty';
import getIsoTime from 'helpers/getIsoTime';
import convertBlobToFile from 'helpers/convertBlobToFile';
import { useChatContext } from 'pages/chats/ChatsContext';
import MessageTypes from 'constants/MessageTypes';
import TextInputMessageComponent from './TextInputMessageComponent';
import useSnackBar from 'hooks/useSnackBar';
import { useClipboardArea } from 'hooks/useClipboardArea';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const TextInputContainer = ({
  disabled = false,
  canSendWhatsAppBusinessMessage = true,
}) => {
  //CHAT_HOOKS
  const {
    addInteraction,
    otherStates: { currentChannel, whatsAppBusinessEnable },
    getInteractionsById,
    handleWhatsAppEvent,
    sendWhatsAppLocation,
    textInputState,
    setTextInputState,
    defaultState,
  } = useChatContext();

  const snackbar = useSnackBar();
  const { onPaste } = useClipboardArea();

  const [emojiPickerIsOpen, setEmojiPickerIsOpen] = useState(false);
  //TEMPLATE_DIALOG
  const [openTemplateDialog, setOpenTemplateDialog] = useState({
    open: false,
    isFirst: false,
  });
  //CAR_PICTURE_DIALOG
  const [openCarPictureDialog, setOpenCarPictureDialog] = useState(false);
  //IMAGE_CONFIGS
  const imageInputRef = useRef(null);
  const mediaInputRef = useRef(null);

  const handleOnChangeChooseMedia = () => mediaInputRef.current?.click();

  const handleOnChangeChooseImage = () => imageInputRef.current?.click();

  const setDefaultState = () => {
    setTextInputState({
      ...defaultState,
    });
  };

  const hasFilesToUpload = files => {
    return (files || []).length > 0;
  };

  const toggleEmojiPicker = () =>
    setEmojiPickerIsOpen(previousState => !previousState);

  //MEDIA
  const sendMessageMedia = async (
    file,
    dateISO = new Date().toISOString(),
    isBase64 = false
  ) => {
    if (file) {
      const response = await addInteraction(
        MessageTypes.MEDIA,
        { channel: currentChannel, file },
        dateISO,
        isBase64
      );

      if (response?.success) {
        setDefaultState();
        getInteractionsById();
      }
    }
  };

  //IMAGE
  const sendMessageImage = async (
    file,
    dateISO = new Date().toISOString(),
    isBase64 = false
  ) => {
    if (file) {
      const { success } = await addInteraction(
        MessageTypes.IMAGE,
        { channel: currentChannel, file },
        dateISO,
        isBase64
      );
      if (success) {
        setDefaultState();
        getInteractionsById();
      }
    }
  };

  const handleOnClickToUploadMedia = event => {
    const files = event.target.files;
    const imagesArray = [];
    if (hasFilesToUpload(files)) {
      setTextInputState(prevProps => ({
        ...prevProps,
        inProgressImage: true,
        messageType: MessageTypes.MEDIA,
      }));
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        imagesArray.push({ file: file, previewUrl: URL.createObjectURL(file) });
      }
      setTextInputState(prevProps => ({
        ...prevProps,
        imagesArray,
      }));
    } else {
      setTextInputState(prevProps => ({
        ...prevProps,
        inProgressLogoImage: false,
      }));
      setDefaultState();
    }
  };

  const handleOnUploadImages = files => {
    const imagesArray = [];
    if (hasFilesToUpload(files)) {
      setTextInputState(prevProps => ({
        ...prevProps,
        inProgressImage: true,
        messageType: MessageTypes.IMAGE,
      }));
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        imagesArray.push({ file: file, previewUrl: URL.createObjectURL(file) });
      }
      setTextInputState(prevProps => ({
        ...prevProps,
        imagesArray,
      }));
    } else {
      setTextInputState(prevProps => ({
        ...prevProps,
        inProgressLogoImage: false,
      }));
      setDefaultState();
    }
  };

  const handleOnClickToUploadImage = event => {
    const files = event.target.files;

    handleOnUploadImages(files);
  };

  const handlePasteImages = useCallback(
    event => {
      const clipboardItems = event.clipboardData?.items;

      if (!clipboardItems) {
        snackbar.handleSnackBarWarning(
          'Nenhuma imagem disponível na área de transferência'
        );
        return;
      }

      const imagesList = Array.from(clipboardItems)
        .filter(item => item.kind === 'file')
        .map(item => {
          const file = item.getAsFile();

          if (file?.type.startsWith('image')) {
            return file;
          }
        })
        .filter(Boolean);

      if (imagesList.length > 0) {
        handleOnUploadImages(imagesList);
      }
    },
    [handleOnUploadImages]
  );

  const handleOnCancelImages = () => {
    setDefaultState();
  };

  const handleRemoveImageByUrl = previewUrl => {
    if (textInputState.imagesArray.length <= 1) {
      setDefaultState();
      return;
    }
    const imagesArrayFiltered = textInputState.imagesArray.filter(
      element => element.previewUrl !== previewUrl
    );
    setTextInputState(prevProps => ({
      ...prevProps,
      imagesArray: imagesArrayFiltered,
    }));
  };

  //TEXT
  const sendMessageText = async (messageText = null, isTemplate = false) => {
    if (!isEmpty(messageText?.trim() || textInputState.messageText?.trim())) {
      const success = await addInteraction(MessageTypes.TEXT, {
        channel: currentChannel,
        message: messageText || textInputState.messageText,
        premessage: isTemplate,
      });
      if (success) {
        setDefaultState();
        getInteractionsById();
      }
    }
  };

  //MICROPHONE
  const getMicrophoneResource = () => {
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log('Permission Granted');
        setTextInputState(prevProps => ({
          ...prevProps,
          isBlocked: false,
        }));
        return false;
      },
      () => {
        console.log('Permission Denied');
        setTextInputState(prevProps => ({
          ...prevProps,
          isBlocked: true,
        }));
        return true;
      }
    );
  };

  const managerTimer = () => {
    if (textInputState.currentSecond < 59) {
      setTextInputState(prevProps => ({
        ...prevProps,
        currentSecond: prevProps.currentSecond + 1,
      }));
    } else {
      setTextInputState(prevProps => ({
        ...prevProps,
        currentMinute: prevProps.currentMinute + 1,
        currentSecond: 0,
      }));
    }
  };

  const handleClickSendWhatsAppLocation = () => {
    sendWhatsAppLocation();
  };

  useEffect(() => {
    let timerInterval = null;
    if (textInputState.isRecording === true) {
      timerInterval = setInterval(() => managerTimer(), 1000);
    } else {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [textInputState.isRecording]);

  const startRecording = () => {
    if (textInputState.isBlocked) {
      alert('Por favor habilite a permissão para utilizar o microfone.');
    } else {
      Mp3Recorder.start()
        .then(() => {
          setTextInputState(prevProps => ({
            ...prevProps,
            isRecording: true,
          }));
        })
        .catch(e => console.error(e));
    }
  };

  const stopRecordingAndClean = () => {
    Mp3Recorder.stop();
    setDefaultState();
  };

  const sendMessageAudio = async file => {
    const success = await addInteraction(MessageTypes.AUDIO, {
      channel: currentChannel,
      file,
    });
    if (success) {
      setDefaultState();
      getInteractionsById();
    }
  };

  //SENDMESSAGE
  const sendMessage = (file = null, currentDate = null, messageType = null) => {
    const messageTypeCurrent = messageType || textInputState.messageType;
    switch (messageTypeCurrent) {
      case MessageTypes.TEXT:
        sendMessageText();
        break;

      case MessageTypes.IMAGE:
        for (let i = 0; i < textInputState.imagesArray.length; i++) {
          const image = textInputState.imagesArray[i].file;
          sendMessageImage(image, currentDate);
        }
        setDefaultState();
        getInteractionsById();
        break;
      case MessageTypes.MEDIA:
        for (let i = 0; i < textInputState.imagesArray.length; i++) {
          const media = textInputState.imagesArray[i].file;
          sendMessageMedia(media, currentDate);
        }
        setDefaultState();
        getInteractionsById();
        break;
      case MessageTypes.AUDIO:
        sendMessageAudio(file);
        break;

      default:
        sendMessageText();
        break;
    }
    return;
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && event.shiftKey) {
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage();
    }
  };

  const handleOnClickSendMedia = () => {
    const dateCurrentFormatIso = getIsoTime();
    sendMessage(null, dateCurrentFormatIso, textInputState.messageType);
  };

  const stopRecordingAndSending = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        const objFile = convertBlobToFile(blob);
        setTextInputState(prevProps => ({
          ...prevProps,
          blobURL,
          isRecording: false,
          messageType: MessageTypes.AUDIO,
          currentMinute: 0,
          currentSecond: 0,
        }));
        sendMessage(objFile, getIsoTime(), MessageTypes.AUDIO);
      })
      .catch(e => console.log(e));
  };

  const handleToggleMicrophone = async () => {
    if (textInputState.isRecording) {
      stopRecordingAndSending();
    } else {
      await getMicrophoneResource();
      startRecording();
    }
  };

  const handleOnChangeMessage = event => {
    const value = event.target.value;
    setTextInputState(prevState => ({
      ...prevState,
      messageType: MessageTypes.TEXT,
      messageText: value,
    }));
  };

  const handleClickSendTemplateMessage = message => {
    sendMessageText(message, true);
  };

  useEffect(() => {
    onPaste(handlePasteImages);
  }, []);

  const viewComponentProps = {
    //TEXT_MESSAGE
    messageText: textInputState.messageText,
    handleOnChangeMessage,
    handleKeyPress,
    sendMessage,
    handleWhatsAppEvent,
    handleClickSendWhatsAppLocation,
    //TEMPLATE_DIALOG
    openTemplateDialog,
    setOpenTemplateDialog,
    handleClickSendTemplateMessage,
    handleOnClickToUploadImage,
    handleOnChangeChooseMedia,
    handleOnChangeChooseImage,
    handleToggleMicrophone,
    handleOnClickToUploadMedia,

    stopRecordingAndClean: stopRecordingAndClean,
    isRecording: textInputState.isRecording,
    timer: {
      minute: textInputState.currentMinute,
      second: textInputState.currentSecond,
    },
    imagesArray: textInputState.imagesArray,
    handleOnClickSendMedia,
    handleOnCancelImages,
    handleRemoveImageByUrl,
    imageInputRef,
    mediaInputRef,
    whatsAppBusinessEnable,
    currentChannel,
    canSendWhatsAppBusinessMessage,
    messageType: textInputState.messageType,
    //CAR_PICTURE_DIALOG
    openCarPictureDialog,
    setOpenCarPictureDialog,
    disabled,

    emojiPickerIsOpen,
    toggleEmojiPicker,
  };

  return <TextInputMessageComponent {...viewComponentProps} />;
};

export default TextInputContainer;
