import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import OrderServices from 'modules/order/services/OrderServices';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import ComponentCancelPDMDialog from './ComponentCancelPDMDialog';

const ContainerCancelPDMDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { dealerId, proposalId } = useSelector(state => state.order);

  const cancelOrder = async () => {
    try {
      const response: any = await OrderServices.cancelDigitalProposal(
        dealerId,
        proposalId
      );

      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Pedido cancelado com sucesso!',
            true
          )
        );
        onClose();
        dispatch(ActionCreators.getProposalDigital());
        dispatch(ActionCreators.setLoadingOrder(true));
        return;
      }
      dispatch(
        UIActionCreators.snackbarManagement('error', response.data, true)
      );
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  };

  const viewComponentProps = {
    open,
    onClose,
    cancelOrder,
  };

  return <ComponentCancelPDMDialog {...viewComponentProps} />;
};

export default ContainerCancelPDMDialog;
