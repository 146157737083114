import React from 'react';
import SearchInput from 'modules/financing/components/atoms/SearchInput';
import List from './components/List';
import ClientForm from './components/ClientForm';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import ThemeButton from 'components/atoms/ThemeButton';
import { ReactComponent as EmptyContactListIcon } from 'assets/empty-contact-icon.svg';
import isEmpty from 'helpers/isEmpty';
import {
  Container,
  ContainerCreateClientBtn,
  EmptyListIconContainer,
  FlexCenterContainer,
} from './styles';

const ClientsListComponent = ({
  isCreatingCustomer,
  clients,
  handleSearchContacts,
  handleKeyPress,
  query,
  setQuery,
  handleClickListItem,
  setIsCreatingCustomer,
  isLoading,
  isEditing,
  handleClickCancel,
  currentClientToEditing,
  handleClickEditListItem,
  clientPhone
}): JSX.Element => {
  if (isEditing) {
    return (
      <ClientForm
        currentClientToEditing={currentClientToEditing}
        handleClickCancel={() => handleClickCancel()}
      />
    );
  }
  if (isCreatingCustomer) {
    return (
      <ClientForm
        isCreating
        handleClickCancel={() => handleClickCancel(false)}
        selectClientAfterSave={handleClickListItem}
        clientPhone={clientPhone}
      />
    );
  }
  return (
    <Container>
      <SearchInput
        value={query}
        onChange={event => {
          setQuery(event.target.value);
        }}
        onKeyUp={handleKeyPress}
        placeholder="Busque um cliente existente"
        searchFunction={handleSearchContacts}
      />
      {!isLoading && isEmpty(clients.content) ? (
        <FlexCenterContainer>
          <EmptyListIconContainer>
            <EmptyContactListIcon />
          </EmptyListIconContainer>
          Nenhum cliente foi encontrado.
          <ThemeButton onClick={() => setIsCreatingCustomer(true)}>
            Cadastrar novo cliente
          </ThemeButton>
        </FlexCenterContainer>
      ) : (
        <div style={{ position: 'relative' }}>
          <List
            itensList={clients.content}
            handleClickListItem={handleClickListItem}
            isLoading={isLoading}
            handleClickEditListItem={handleClickEditListItem}
          />
          <ContainerCreateClientBtn>
            <HypertextButton onClick={() => setIsCreatingCustomer(true)}>
              + Cadastrar novo cliente
            </HypertextButton>
          </ContainerCreateClientBtn>
        </div>
      )}
    </Container>
  );
};

export default ClientsListComponent;
