import styled from 'styled-components';

export const Container = styled('div')`
  background-color: #ceedf3;
  border: 1px solid #6bc8da;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
`;

export const Title = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #056274;
  font-size: 0.875rem;

  .MuiSvgIcon-root {
    color: #04414e;
  }
`;

export const IconContainer = styled('span')`
  background-color: #6bc8da;
  font-size: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentInfo = styled('div')`
  font-size: 0.75rem;
  padding-left: 1.5rem;
  line-height: 1.5;
`;
