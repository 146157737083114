import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchServices from 'services/searchServices';
import ActionCreators from 'modules/financing/store/reducers/actionCreators';
import CompanyForm from './CompanyFullPayForm';

const CompanyFullPayFormContainer = ({
  formData,
  onChange,
  disableAllInputs = false,
  enableCopyButton = false,
  disableCpfCnpj = false,
}) => {
  //REDUX_HOOKS
  const {
    statesOptions,
    citiesOptions,
    streetTypeOptions,
    addressTypeOptions,
  } = useSelector(state => state.financing);
  const dispatch = useDispatch();
  //CITIES
  const [citiesOptionsState, setCitiesOptions] = useState(citiesOptions);
  //GET_ADDRESS_INFO_BY_ZIP
  const getAddresInfosByZipCode = async () => {
    try {
      const { success, data }: any = await SearchServices.getLocationByZipcode(
        formData['companyZipcode']
      );
      if (success) {
        if (data.uf) {
          dispatch(ActionCreators.getCitieByState(data.uf));
          onChange({ target: { name: 'companyCityStateId', value: data.uf } });
          onChange({
            target: { name: 'companyAddress', value: data.logradouro },
          });
          onChange({ target: { name: 'companyCityId', value: data.cidadeId } });
          onChange({
            target: { name: 'companyStreetType', value: data.tipoEndereco },
          });
          onChange({
            target: { name: 'companyNeighborhood', value: data.bairro },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  //GET_ADDRES_INFOS_BY_CEP_ON_PRESS_ENTER
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      getAddresInfosByZipCode();
    }
  };
  //GET_OPTIONS
  useEffect(() => {
    dispatch(ActionCreators.getStreetTypeOptions());
    dispatch(ActionCreators.getAddressTypeOptions());
    if (statesOptions.length < 2) {
      dispatch(ActionCreators.getStates());
    }
  }, []);
  //UPDATE_CITIES_OPTIONS_ON_CITIES_OPTIONS_CHANGE
  useEffect(() => {
    setCitiesOptions(citiesOptions);
  }, [citiesOptions]);
  //GET_CITIES_OPTIONS_ON_CHANGE_STATE
  useEffect(() => {
    dispatch(ActionCreators.getCitieByState(formData['companyCityStateId']));
  }, [formData['companyCityStateId']]);

  const viewComponentProps = {
    statesOptions,
    streetTypeOptions,
    addressTypeOptions,
    citiesOptionsState,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton,
    disableCpfCnpj,
  };

  return <CompanyForm {...viewComponentProps} />;
};

export default CompanyFullPayFormContainer;
