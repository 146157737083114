import React from 'react';
import TableHeadBase from './components/TableHeadBase';

const columnsConfig = [
  {
    id: 'id',
    padding: 'checkbox',
    align: 'center',
    label: 'Cód cliente',
    width: '120px',
  },
  {
    id: 'clienteName',
    label: 'Cliente',
    width: '150px',
  },
  {
    id: 'cpf',
    label: 'CPF',
    width: '150px',
  },
  {
    id: 'phone',
    label: 'Telefone',
    width: '150px',
  },
  {
    id: 'email',
    label: 'E-mail',
    width: '200px',
  },
  {
    id: 'actions',
    padding: 'checkbox',
    disablePadding: false,
    label: '',
    width: '30px',
  },
];

function TableHeadComponent({ onRequestSort, order, orderBy }) {
  return (
    <TableHeadBase
      columnsConfig={columnsConfig}
      onRequestSort={onRequestSort}
      order={order}
      orderBy={orderBy}
    />
  );
}

export default TableHeadComponent;
