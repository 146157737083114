import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { ReactComponent as ClientDataFilter } from 'assets/client-data-filter.svg';
import { ReactComponent as ClientDataFilterActive } from 'assets/client-data-filter-active.svg';
import { ReactComponent as ProposalDataFilterActive } from 'assets/proposal-data-filter-active.svg';
import { ReactComponent as Summary } from 'assets/summaryIcon.svg';
import { ReactComponent as SummaryActive } from 'assets/summaryActiveIcon.svg';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import CancelOrderDialog from '../CancelOrderDialog';
import { Row, Container } from './styles';

const SubHeader = ({ currentStep, onChangeStep, clickable = false }) => {
  const [openCancelOrderDialog, setOpenCancelOrderDialog] = useState<any>(
    false
  );

  const { queueData } = useSelector(state => state.order);

  const isCanceled = queueData?.status?.value === 'CANCELED';

  return (
    <Container>
      <Row>
        <ProposalDataFilterActive
          onClick={() => clickable && onChangeStep(0)}
          style={{ cursor: clickable ? 'pointer' : 'normal' }}
        />
        {currentStep != 0 ? (
          <ClientDataFilterActive
            onClick={() => clickable && onChangeStep(1)}
            style={{ cursor: clickable ? 'pointer' : 'normal' }}
          />
        ) : (
          <ClientDataFilter
            onClick={() => clickable && onChangeStep(1)}
            style={{ cursor: clickable ? 'pointer' : 'normal' }}
          />
        )}
        {currentStep === 2 ? (
          <Summary
            onClick={() => clickable && onChangeStep(2)}
            style={{ cursor: clickable ? 'pointer' : 'normal' }}
          />
        ) : (
          <SummaryActive
            onClick={() => clickable && onChangeStep(2)}
            style={{ cursor: clickable ? 'pointer' : 'normal' }}
          />
        )}
      </Row>
      {!isCanceled && (
        <HypertextButton onClick={() => setOpenCancelOrderDialog(true)} red>
          <CancelIcon style={{ fontSize: 18, marginRight: 5 }} />
          Cancelar Pedido
        </HypertextButton>
      )}
      {openCancelOrderDialog && (
        <CancelOrderDialog
          open={openCancelOrderDialog}
          onClose={() => setOpenCancelOrderDialog(false)}
        />
      )}
    </Container>
  );
};

export default SubHeader;
