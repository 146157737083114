import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchServices from 'services/searchServices';
import ActionCreators from 'modules/financing/store/reducers/actionCreators';
import MaritalInfosForm from './MaritalInfosForm';
import validateCPF from 'helpers/validateCPF';

const getCitiesByState = async (ufData, callback) => {
  try {
    if (!ufData) {
      return;
    }
    const { success, data }: any = await SearchServices.getCitiesByState(
      ufData
    );
    if (success) {
      const arr: any = [{ value: 0, label: 'Selecione...' }];
      data.map(el => {
        arr.push({ label: el.name, value: el.id });
      });
      callback(arr);
    }
  } catch (e) {
    console.log(e);
  }
};

const MaritalInfosFormContainer = ({
  formData,
  onChange,
  disableAllInputs = false,
  enableCopyButton = false,
}) => {
  //REDUX_HOOKS
  const { statesOptions, civilStatusOptions, employmentsOptions } = useSelector(
    state => state.financing
  );
  const dispatch = useDispatch();

  //CITIES
  const [citiesOptionsState, setCitiesOptions] = useState([]);
  const [errors, setErrors] = useState({});

  //GET_ADDRESS_INFO_BY_ZIP
  const getAddressInfosByZipCode = async () => {
    try {
      const { success, data }: any = await SearchServices.getLocationByZipcode(
        formData['maritalPartnerZipcode']
      );
      if (success) {
        if (data.uf) {
          dispatch(ActionCreators.getCitieByState(data.uf));
          onChange({
            target: { name: 'maritalPartnerCityStateId', value: data.uf },
          });
          onChange({
            target: { name: 'maritalPartnerAddress', value: data.logradouro },
          });
          onChange({
            target: { name: 'maritalPartnerCityId', value: data.cidadeId },
          });
          // onChange({ target: { name: 'streetType', value: data.tipoEndereco } });
          onChange({
            target: { name: 'maritalPartnerNeighborhood', value: data.bairro },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  //GET_ADDRESS_INFOS_BY_CEP_ON_PRESS_ENTER
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      getAddressInfosByZipCode();
    }
  };

  const getValuesValidation = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    if (name === 'maritalPartnerCpf') {
      return setErrors({
        ...errors,
        maritalPartnerCpf: !value || !validateCPF(value),
      });
    }
    setErrors({
      ...errors,
      [name]: !value,
    });
  };

  const handleMaritalPartnerCpfChange = useCallback(
    ({
      value,
      handleType,
    }: {
      value: string;
      handleType: 'onChange' | 'onBlur';
    }) => {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      if (handleType === 'onChange') {
        if (sanitizedValue.length === 11) {
          getValuesValidation({
            name: 'maritalPartnerCpf',
            value: sanitizedValue,
          });
        }

        onChange({
          target: { value: sanitizedValue, name: 'maritalPartnerCpf' },
        });

        return;
      }

      if (handleType === 'onBlur') {
        return getValuesValidation({
          name: 'maritalPartnerCpf',
          value: sanitizedValue,
        });
      }
    },
    [getValuesValidation, onChange]
  );

  useEffect(() => {
    dispatch(ActionCreators.getStreetTypeOptions());
    dispatch(ActionCreators.getAddressTypeOptions());
    // dispatch(ActionCreators.getStates());
  }, [dispatch]);

  //GET_CITIES_OPTIONS_ON_CHANGE_STATE
  useEffect(() => {
    getCitiesByState(formData['maritalPartnerCityStateId'], setCitiesOptions);
  }, [formData['maritalPartnerCityStateId']]);

  const viewComponentProps = {
    formData,
    onChange,
    citiesOptions: citiesOptionsState,
    statesOptions,
    civilStatusOptions,
    employmentsOptions,
    disableAllInputs,
    enableCopyButton,
    errors,
    handleKeyPress,
    getAddressInfosByZipCode,
    handleMaritalPartnerCpfChange,
  };

  return <MaritalInfosForm {...viewComponentProps} />;
};

export default MaritalInfosFormContainer;
