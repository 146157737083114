import request from 'helpers/request';
import EnvVar from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVar('API_PAINEL_DIGITAL_BASE_URL');
const priceDomain = EnvVar('API_PRICE_BASE_URL');
const serviceName = 'evaluationServices';

export const getEvaluations = (dealerId, dealForListingFilter, query) => {
  const { size, page } = query;
  return request
    .post(
      `${domain}/dealer/${dealerId}/inventory/evaluation/v1.0/listing?size=${size}&page=${page}`,

      dealForListingFilter
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getEvaluations()`));
};

export const getEvaluationById = (dealerId, dealId) => {
  return request
    .get(`${domain}/dealer/${dealerId}/inventory/evaluation/v1.0/${dealId}`)
    .then(buildHTTPResponseHandler(`${serviceName}.getEvaluationById()`));
};
export const putEvaluationById = (dealerId, dealId, dto) => {
  return request
    .put(
      `${domain}/dealer/${dealerId}/inventory/evaluation/v1.0/${dealId}`,
      dto
    )
    .then(buildHTTPResponseHandler(`${serviceName}.putEvaluationById()`));
};

export const createEvaluation = (dealerId, deal) => {
  return request
    .post(`${domain}/dealer/${dealerId}/inventory/evaluation/v1.0`, deal)
    .then(buildHTTPResponseHandler(`${serviceName}.createEvaluation()`));
};

export const deleteAEvaluationById = (dealerId, id) => {
  return request
    .delete(`${domain}/dealer/${dealerId}/inventory/evaluation/v1.0/${id}`)
    .then(buildHTTPResponseHandler(`${serviceName}.deleteAEvaluationById()`));
};

export const getReportPDFUri = (dealerId, evaluationId) => {
  return request
    .get(
      `${domain}/dealer/${dealerId}/inventory/evaluation/v1.0/${evaluationId}/pdf`,
      {},
      { responseType: 'blob' }
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getReportPDFUri()`));
};

export const finalizeEvaluation = ({
  dealId,
  dealPrice,
  dealerId,
  evaluationFinalPrice,
  recommendation,
  isPurchased,
  purchasePrice,
  consignmentPrice,
  negociationType,
  statusNegociation,
  evaluationReasons,
  reasons,
  buyType,
  evaluationCommentsFinalPrice,
}) => {
  return request
    .post(
      `${domain}/dealer/${dealerId}/inventory/evaluation/v2.0/finish/${dealId}`,
      {},
      {
        params: {
          ...(dealPrice ? { dealPrice } : null),
          ...(buyType ? { buyType } : null),
          ...(evaluationFinalPrice ? { evaluationFinalPrice } : null),
          ...(recommendation ? { recommendation } : null),
          ...(isPurchased ? { isPurchased } : null),
          ...(purchasePrice ? { purchasePrice } : null),
          ...(consignmentPrice ? { consignmentPrice } : null),
          ...(negociationType ? { negociationType } : null),
          ...(statusNegociation ? { statusNegociation } : null),
          ...(evaluationReasons ? { evaluationReasons } : null),
          ...(reasons ? { reasons } : null),
          ...(evaluationCommentsFinalPrice
            ? { evaluationCommentsFinalPrice }
            : null),
        },
      }
    )
    .then(buildHTTPResponseHandler(`${serviceName}.finalizeEvaluation()`));
};

export const getPredictions = (modelYear, trimId, colorId) => {
  return request
    .get(
      `${priceDomain}/pred/retail?modelyear=${modelYear}&predict_mobi_price=1&trim_id=${trimId}&color_id=${colorId}`
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getPredictions()`));
};

export const getPasseCarrosPrice = (modelYear, trimId, colorId) => {
  return request
    .get(
      `${priceDomain}/pred/trade-in?modelyear=${modelYear}&predict_mobi_price=1&trim_id=${trimId}&color_id=${colorId}
  `
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getPasseCarrosPrice()`));
};

export const syncEvaluationWithExternalProviders = (dealerId, dealId) => {
  return request
    .post(
      `${domain}/dealer/${dealerId}/inventory/evaluation/v1.0/sync/${dealId}
  `,
      {}
    )
    .then(
      buildHTTPResponseHandler(
        `${serviceName}.syncEvaluationWithExternalProviders()`
      )
    );
};

export const getImagesZipById = ({ dealerId, dealId }) => {
  return request
    .get(
      `${domain}/dealer/${dealerId}/inventory/evaluation/${dealId}/v1.0/images/download`,
      {},
      {
        responseType: 'blob',
      }
    )
    .then(buildHTTPResponseHandler(`${serviceName}.getImagesZipById()`));
};
