import { putCategoryPayloadDTO } from 'modules/financial/types';
import { createCategoryDTO } from 'modules/financial/types';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandlerTS';
import { requestModule as request } from './index';

const serviceName = 'CategoryServices';

class CategoryServices {
  static list = async () => {
    const response = await request.get('category/v1.0/categories');
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.list()`
    );
    return responseHandler(response);
  };

  static post = async (payload: createCategoryDTO) => {
    const response = await request.post(`category/v1.0`, payload);
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.post()`
    );
    return responseHandler(response);
  };

  static delete = async (categoryId: number) => {
    const response = await request.delete(`category/v1.0/${categoryId}`);
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.delete()`
    );
    return responseHandler(response);
  };

  static put = async (
    categoryId: string | number,
    payload: putCategoryPayloadDTO
  ) => {
    const response = await request.put(`category/v1.0/${categoryId}`, payload);
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.put()`
    );
    return responseHandler(response);
  };
}

export default CategoryServices;
