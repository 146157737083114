import styled, { css } from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const VehicleItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 125px;
  margin: 5px 0;
  border-radius: 4px;
  border: ${({ isChecked }) =>
    isChecked
      ? `1px solid ${Colors.primary.medium}`
      : `1px solid ${Colors.gray.nearWhite}`};
`;

export const VehicleImage = styled.img`
  height: 100%;
  width: 142px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
  margin-right: 16px;
`;

export const VehicleTitle = styled.h3`
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

export const InfoTag = styled.span`
  font-size: 12px;
  font-weight: 400;
  border-radius: 20px;
  padding: 0 8px;
  margin: 4px 11px 5px 0;
  ${({ accent }) =>
    accent
      ? css`
          color: #4443bc;
          background: #e9eaf9;
        `
      : css`
          color: #757575;
          background: #f5f5f5;
        `}
`;

export const VehicleBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0px 16px;
`;

export const VehicleTagLine = styled.div`
  display: flex;
  margin-top: 2px;
  margin-bottom: 4px;
`;

export const BookedVehicleLine = styled.div`
  display: flex;
`;

export const ViewBookedLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 0;
  color: blue;
  text-transform: none;
  text-decoration: underline;
`;

export const TootipTitle = styled.span`
  font-size: 12px;
`;

export const SelectableContainer = styled.div`
  margin-left: auto;
`;
