import React from 'react';

export function addLinkOnMessage(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  if (!text) return null;

  return text.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          {part}
        </a>
      );
    }

    return part;
  });
}
