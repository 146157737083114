import React from 'react';
import { CheckCircle } from '@material-ui/icons';

// Hooks import
import { useChatContext } from 'pages/chats/ChatsContext';

// Style import
import { Wrapper, FlexSalesOrder } from '../styles';

interface Step {
  label: string;
  key: string;
}

const options: Step[] = [
  { label: 'Atendimento', key: 'ATTENDANCE' },
  { label: 'Fila de Espera', key: 'ORDER' },
  { label: 'Checkout', key: 'CHECKOUT' },
];

export function SalesOrder() {
  // Hooks
  const { currentProposal }: any = useChatContext();

  const currentStepLabel =
    options.find(item => item.key === currentProposal?.generalStep)?.label;

  return (
    <Wrapper>
      <span>Pedido de venda</span>

      {currentStepLabel && (
        <FlexSalesOrder>
          <CheckCircle color="primary" />
          <p>{currentStepLabel}</p>
        </FlexSalesOrder>
      )}
    </Wrapper>
  );
}
