import React from 'react';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  scroller: {
    overflowX: 'auto !important',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',

    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main || Colors.primary.medium,
    },
  },
});

const StyledTabs = withStyles(styles)(props => (
  <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
));

export default StyledTabs;
