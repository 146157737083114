import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import ProposalOrderDelivery from 'services/proposalOrderDelivery';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import Wrapper from 'components/Wrapper';
import GenericDialog from 'components/GenericDialog';
import OutlinedButton from 'components/atoms/OutlinedButton';
import RedButton from 'components/atoms/RedButton';
import { ButtonWrapper } from './styles';

const CancelScheduling = ({ open, onClose, proposalSelected, refetchData }) => {
  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  const [isLoading, setIsLoading] = useState(false);

  const deleteScheduling = async () => {
    setIsLoading(true);
    try {
      const response = await ProposalOrderDelivery.deleteScheduling(
        currentDealer?.id,
        proposalSelected?.id
      );
      if (response) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Agendamento cancelado com sucesso.',
            true
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.',
          true
        )
      );
    } finally {
      refetchData();
      onClose();
      setIsLoading(false);
    }
  };
  const DialogAction = () => (
    <ButtonWrapper>
      <OutlinedButton onClick={() => onClose()}>Fechar</OutlinedButton>
      <div style={{ width: 8 }} />
      <RedButton disabled={isLoading} onClick={() => deleteScheduling()}>
        {isLoading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          'Confirmar'
        )}
      </RedButton>
    </ButtonWrapper>
  );

  return (
    <GenericDialog
      title="Cancelar agendamento"
      open={open}
      onClose={onClose}
      DialogAction={DialogAction}
      maxWidth={'sm'}
    >
      <Wrapper flex ml={8} mr={16} direction={'column'}>
        Você tem certeza que gostaria de cancelar esse agendamento? A ação não
        poderá ser desfeita.
      </Wrapper>
    </GenericDialog>
  );
};

export default CancelScheduling;
