import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  //CURRENT_PROPOSAL
  GET_CURRENT_PROPOSAL_BY_ID: `@${MODULE}/${getters.GET}_CURRENT_PROPOSAL_BY_ID_${actionOptions.REQUEST}`,
  //BUYER_CURRENT_PROPOSAL
  GET_BUYER_CURRENT_PROPOSAL_BY_ID: `@${MODULE}/${getters.GET}_BUYER_CURRENT_PROPOSAL_BY_ID_${actionOptions.REQUEST}`,
  SET_CURRENT_PROPOSAL: `@${MODULE}/${getters.SET}_CURRENT_PROPOSAL_${actionOptions.SUCCESS}`,
  //UPDATE_PROPOSAL
  UPDATE_PROPOSAL: `@${MODULE}/${getters.SET}_UPDATE_PROPOSAL_${actionOptions.REQUEST}`,
  UPDATE_PROPOSAL_SUCCESS: `@${MODULE}/${getters.SET}_UPDATE_PROPOSAL_${actionOptions.SUCCESS}`,
  //SALES_NEGOTIATION
  GET_BUYER_NEGOTIATION: `@${MODULE}/${getters.GET}_NEGOTIATION_${actionOptions.REQUEST}`,
  GET_SALES_NEGOTIATION: `@${MODULE}/${getters.GET}_SALES_NEGOTIATION_${actionOptions.REQUEST}`,
  SET_NEGOTIATION: `@${MODULE}/${getters.SET}_NEGOTIATION_${actionOptions.SUCCESS}`,
  //SALES_COMBO_NEGOTIATION
  GET_SALES_COMBO_NEGOTIATION: `@${MODULE}/${getters.GET}_SALES_COMBO_NEGOTIATION_${actionOptions.REQUEST}`,
  SET_SALES_COMBO_NEGOTIATION: `@${MODULE}/${getters.SET}_SALES_COMBO_NEGOTIATION_${actionOptions.SUCCESS}`,
  //NEGOTIATION_ARCHIVED
  SET_NEGOTIATION_ARCHIVED: `@${MODULE}/${getters.SET}_NEGOTIATION_ARCHIVED_${actionOptions.REQUEST}`,
  //SEND_ACCEPT_BID
  SEND_ACCEPT_BID: `@${MODULE}/${getters.SET}_SEND_ACCEPT_BID_${actionOptions.REQUEST}`,
  SEND_CONFIRM_ACCEPT_BID: `@${MODULE}/${getters.SET}_SEND_CONFIRM_ACCEPT_BID_${actionOptions.REQUEST}`,
  SEND_ACCEPT_BID_COMBO: `@${MODULE}/${getters.SET}_SEND_ACCEPT_BID_COMBO_${actionOptions.REQUEST}`,
  //SEND_COMFIRM_BUYER_ACCEPT_BID
  SEND_CONFIRM_BUYER_ACCEPT_BID: `@${MODULE}/${getters.SET}_SEND_CONFIRM_BUYER_ACCEPT_BID_${actionOptions.REQUEST}`,
  //SEND_REFUSED_BID
  SEND_REFUSED_BID: `@${MODULE}/${getters.SET}_SEND_REFUSED_BID_${actionOptions.REQUEST}`,
  //SEND_BUYER_REFUSED_BID
  SEND_BUYER_REFUSED_BID: `@${MODULE}/${getters.SET}_SEND_BUYER_REFUSED_BID_${actionOptions.REQUEST}`,
  //SEND_NEW_BID
  SEND_NEW_BID: `@${MODULE}/${getters.SET}_SEND_NEW_BID_${actionOptions.REQUEST}`,
  //SEND_BUYER_NEW_BID
  SEND_BUYER_NEW_BID: `@${MODULE}/${getters.SET}_SEND_BUYER_NEW_BID_${actionOptions.REQUEST}`,
  //SNACKBAR_STATE
  UPDATE_SNACKBAR_STATE: `@${MODULE}/${getters.SET}_UPDATE_SNACKBAR_STATE_${actionOptions.REQUEST}`,
  //GET_INTERACTIONS
  GET_INTERACTIONS: `@${MODULE}/${getters.GET}_GET_INTERACTONS_${actionOptions.REQUEST}`,
  SET_INTERACTIONS_SUCCESS: `@${MODULE}/${getters.SET}_GET_INTERACTONS_${actionOptions.SUCCESS}`,
  //SEND_INTERACTION
  SEND_INTERACTION: `@${MODULE}/${getters.SET}_SEND_INTERACTION_${actionOptions.REQUEST}`,
  //SEND_BUYER_INTERACTION
  SEND_BUYER_INTERACTION: `@${MODULE}/${getters.SET}_SEND_BUYER_INTERACTION_${actionOptions.REQUEST}`,
  //HIGHER_CURRENT_BID_DIALOG
  MANAGE_HIGHER_BID_DIALOG: `@${MODULE}/${getters.SET}_HIGHER_CURRENT_BID_${actionOptions.SUCCESS}`,
  //MANAGE_CONFIRMATION_DIALOG
  MANAGE_CONFIRMATION_DIALOG: `@${MODULE}/${getters.SET}_MANAGE_CONFIRMATION_DIALOG_${actionOptions.SUCCESS}`,
  //VISUALIZE_MESSAGES
  VISUALIZE_MESSAGES: `@${MODULE}/${getters.SET}_VISUALIZE_MESSAGES_${actionOptions.REQUEST}`,
  //SET_SELLER_TO_NEGOTIATION
  SET_SELLER_TO_NEGOTIATION: `@${MODULE}/${getters.SET}_SET_SELLER_TO_NEGOTIATION_${actionOptions.REQUEST}`,
  SET_SELLER_TO_NEGOTIATION_SUCCESS: `@${MODULE}/${getters.SET}_SET_SELLER_TO_NEGOTIATION_${actionOptions.SUCCESS}`,
  SET_SELLER_TO_NEGOTIATION_ERROR: `@${MODULE}/${getters.SET}_SET_SELLER_TO_NEGOTIATION_${actionOptions.ERROR}`,
  SET_SELLER_TO_NEGOTIATION_LOADING: `@${MODULE}/${getters.SET}_SET_SELLER_TO_NEGOTIATION_${actionOptions.LOADING}`,
};
