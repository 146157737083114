import moment from 'moment'

export function formatDuration(start: moment.MomentInput, end: moment.MomentInput): string{
  const startDate = moment(start)
  const endDate = moment(end)

  const duration = moment.duration(Math.abs(endDate.diff(startDate)))
  const days = duration.days() + 1 //count current day
  const hours = duration.hours()

  if(endDate.isSame(startDate, 'day'))
    return `${hours} ${hours !== 1 ? 'horas' : 'hora'}`
  else
    return `${days} ${days !== 1 ? 'dias' : 'dia'}`
}

export function formatDurationFromNow(dateInput: moment.MomentInput): string{
  const date = moment(dateInput)

  const isBeforeNow = date.isBefore(moment(), 'day')
  const isToday = date.isSame(moment(), 'day')
  const durationStr = formatDuration(moment(), date)

  if(isToday)
    return 'hoje'
  else
    return isBeforeNow ? `${durationStr} atrás` : `daqui ${durationStr}`
}