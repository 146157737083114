import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import ImageService from 'services/imageServices';

const _defaultInitialState = {
  data: null,
  hasFetched: false,
  hasFailed: false,
  isLoading: false,
};

class ImageStore {
  constructor(initialState) {
    this.setInitialState(initialState || _defaultInitialState);
  }

  setInitialState = ({ data, hasFetched, hasFailed, isLoading }) => {
    this.data = data;
    this.hasFetched = hasFetched;
    this.hasFailed = hasFailed;
    this.hasFailed = isLoading;
  };

  getImage = async (image, quality, width, height) => {
    const { data, success } = await ImageService.getImage(
      image,
      quality,
      width,
      height
    );
    if (success) {
      return data;
    }
    return data;
  };
}

export default remotedev(
  decorate(ImageStore, {
    data: observable,
    hasFetched: observable,
    hasFailed: observable,
    getImage: action,
    setInitialState: action,
  })
);
