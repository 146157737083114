/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useSelector, useDispatch } from 'react-redux';
import {
  Types as DealerTypes,
  Creators as DealerCreators,
} from 'modules/resale/store/ducks/dealer';

import useEvaluation from './hooks/useEvaluation';
import EvaluationComponent from './EvaluationComponent';
import { triggerRefetchEvaluations } from './contexts/atom';
import { useChatContext } from 'pages/chats/ChatsContext';
import { SAGA_GROUP_ID } from 'constants/sagaGroupId';
import useSnackBar from 'hooks/useSnackBar';

export default function EvaluationContainer() {
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [refetch, setRefetch] = useRecoilState(triggerRefetchEvaluations);
  const { currentProposal }: any = useChatContext();
  const { currentDealer } = useSelector(state => state.dealerStore);
  const dispatch = useDispatch();
  const snackbar = useSnackBar();

  const {
    dataEvaluations,
    refreshEvaluations,
    checkDealerBelongsToGroup,
    loadingGetReferencePrices,
    isLoading: isLoadingCheckDealerBelongsToGroup,
  } = useEvaluation({
    dealerId: currentDealer.id,
    proposalId: currentProposal.id,
  });

  const handleOpenModal = () => setModal(true);

  useEffect(() => {
    refreshEvaluations();
  }, []);

  useEffect(() => {
    if (refetch) {
      refreshEvaluations();
      setRefetch(false);
    }
  }, [refetch]);

  useEffect(() => {
    if (dataEvaluations?.data && typeof dataEvaluations?.data === 'object') {
      const formattedList = Object.values(dataEvaluations?.data).map(
        value => value
      );
      setList(formattedList);
    }
  }, [dataEvaluations]);

  useEffect(() => {
    checkDealerBelongsToGroup(
      {
        dealerId: currentDealer?.id,
        groupId: SAGA_GROUP_ID,
      },
      {
        onSuccess: (response: any) => {
          if (response?.success) {
            dispatch(
              DealerCreators.setDealerIsSaga(response?.data?.dealerongroup)
            );

            return;
          }
          throw Error();
        },
        onError() {
          snackbar.error('Desculpe, Ocorreu um erro inesperado.');
        },
      }
    );
  }, []);

  const viewComponentProps = {
    handleOpenModal,
    currentDealer,
    modal,
    setModal,
    list,
    setList,
    currentProposal,
    loading: loadingGetReferencePrices,
    dealerBelongsToSagaGroup: currentDealer?.isSaga,
    isLoadingCheckDealerBelongsToGroup,
  };

  return <EvaluationComponent {...viewComponentProps} />;
}
