/* eslint-disable @typescript-eslint/explicit-function-return-type */
import PaginationDefaultOptions from 'constants/PaginationDefaultOptions';
import { defaultSelectObject } from 'constants/defaultSelect';
import StatusEnum from 'constants/publishStatusEnum';
import VEHICLE_TYPE from 'constants/vehicleTypes';
import isEmpty from 'helpers/isEmpty';
import updateItemIntoContent from 'helpers/updateItemIntoContent';
import find from 'lodash.find';
import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import InventoryService from 'services/inventoryServices';
const _dealCurrent = {
  dealPublishes: [],
  armorType: null,
  armored: false,
  deal0km: false,
  colorId: defaultSelectObject.value,
  comments: '',
  doors: defaultSelectObject.value,
  conditionalsIds: [],
  featureIds: [],
  fuelId: defaultSelectObject.value,
  images: [],
  km: 0,
  makeId: '',
  makeName: '',
  modelId: '',
  modelName: '',
  modelYear: defaultSelectObject.value,
  plate: '',
  price: 0,
  // purchasePrice: 0,
  productionYear: defaultSelectObject.value,
  transmissionId: defaultSelectObject.value,
  trimId: defaultSelectObject.value,
  trimName: '',
  vin: '',
  youtubeId: '',
  bodystyleId: null,
  regionalGroupId: defaultSelectObject.value,
  legalInformation: '',
  shouldHireMechanicalWarranty: false,
  molicarId: null,
  isEligibility: false,
  downPayment: defaultSelectObject.value,
  remainingPayment: defaultSelectObject.value,
  totalPrice: defaultSelectObject.value,
};

const _defaultInitialState = {
  deals: {},
  priorities: [],
  infos: {},
  stock: {},
  plans: {},
  dealInfo: {},
  fipePrice: {},
  configPortais: {},
  config: {},
  stockParams: {
    page: 0,
    size: 20,
    order: 1,
    options: { showroom: true },
  },
  hasFetched: false,
  hasFailed: false,
  isLoading: false,
  dealCurrent: {
    ..._dealCurrent,
  },
  dealsHasFetched: null,
  dealsHasFailed: null,
  dealsIsLoading: null,
  infosIsLoading: null,
  infosHasFetched: null,
  infosHasFailed: null,
  stockIsLoading: null,
  stockHasFetched: null,
  stockHasFailed: null,
  overflowPrice: false,
  valuesMinAndMaxPrice: {},
  currentPage: 0,
};

function buildOptionsToParams(options) {
  const optionsNew = { ...PaginationDefaultOptions.options };

  optionsNew.orderFilter = !options.orderFilter
    ? PaginationDefaultOptions.options.orderFilter
    : options.orderFilter;

  optionsNew.usado = options.usado
    ? PaginationDefaultOptions.options.usado
    : options.usado;

  optionsNew.zeroKm = options.zeroKm
    ? PaginationDefaultOptions.options.zeroKm
    : options.zeroKm;

  optionsNew.query = !options.query
    ? PaginationDefaultOptions.options.query
    : options.query;

  optionsNew.publishStatus = !options.publishStatus
    ? PaginationDefaultOptions.options.publishStatus
    : options.publishStatus;

  optionsNew.scoreFilter = !options.scoreFilter
    ? PaginationDefaultOptions.options.scoreFilter
    : options.scoreFilter;

  optionsNew.dashFilter = !options.dashFilter
    ? PaginationDefaultOptions.options.dashFilter
    : options.dashFilter;

  optionsNew.planIdFilter = !options.planIdFilter
    ? PaginationDefaultOptions.options.planIdFilter
    : options.planIdFilter;

  optionsNew.showroom = options.showroom;
  optionsNew.booked = options.booked;
  return optionsNew;
}

class InventoryStore {
  constructor(initialState) {
    this.setInitialState(_defaultInitialState);
    this.rootStore = initialState;
  }

  checkPublishes = data => {
    const dealPublishesTemp = [];
    if (data.publishes || data.dealPublishes) {
      (data.publishes || data.dealPublishes).map(item => {
        if (item?.publishStatus === 'PUBLISHED') {
          if (item?.planId === '' || item?.planId === undefined) {
            item.planId = 'planNotFound';
          }
        }
        dealPublishesTemp.push(item);
      });
      data.dealPublishes = dealPublishesTemp;
      delete data.publishes;
    }
    return data;
  };

  setDataCurrent = (data, hasFailed, hasFetched, isLoading) => {
    this.dealCurrent = {};
    this.dealCurrent = this.checkPublishes(JSON.parse(JSON.stringify(data)));
    this.dealPublishes = data?.publishes
      ? data?.publishes
      : data.dealPublishes
      ? data.dealPublishes
      : [];
    this.currentHasFailed = hasFailed;
    this.currentIsLoading = isLoading;
    this.currentHasFetched = hasFetched;
  };

  setDadosDeVeiculo = dados => {
    const dealCurrent = { ...this.dealCurrent };
    dealCurrent.colorId = dados.colorId;
    dealCurrent.doors = dados.doors;
    dealCurrent.deal0km = dados.deal0km;
    dealCurrent.fuelId = dados.fuelId;
    dealCurrent.km = Number(dados.km).toFixed(0);
    dealCurrent.makeId = dados.makeId;
    dealCurrent.makeName = dados.makeName;
    dealCurrent.modelId = dados.modelId;
    dealCurrent.modelName = dados.modelName;
    dealCurrent.modelYear = dados.modelYear;
    dealCurrent.plate = dados.plate;
    dealCurrent.productionYear = dados.productionYear;
    dealCurrent.transmissionId = dados.transmissionId;
    dealCurrent.trimId = dados.trimId;
    dealCurrent.trimName = dados.trimName;
    if (dados.featureIds && dados.featureIds.length > 0) {
      dealCurrent.featureIds = dados.featureIds;
    }
    dealCurrent.dealPublishes = dados?.publishes ?? dados?.dealPublishes;
    dealCurrent.armorType = dados.armorType;
    dealCurrent.armored = dados.armored;
    dealCurrent.vehicleType = dados.vehicleType;
    dealCurrent.vin = dados.vin;
    dealCurrent.renavam = dados.renavam;
    dealCurrent.manufacturingPackageCode = dados.manufacturingPackageCode;
    dealCurrent.bodystyleId = dados?.bodystyleId;
    dealCurrent.shouldHireMechanicalWarranty =
      dados?.shouldHireMechanicalWarranty;
    dealCurrent.molicarId = dados?.molicarId;
    dealCurrent.isEligibility = dados?.isEligibility;
    dealCurrent.downPayment = dados?.downPayment;
    dealCurrent.remainingPayment = dados?.remainingPayment;
    dealCurrent.totalPrice = dados?.totalPrice;
    this.dealCurrent = dealCurrent;
  };

  setDownPayment = downPayment => {
    this.dealCurrent.downPayment = downPayment;
  };

  setRemainingPayment = remainingPayment => {
    this.dealCurrent.remainingPayment = remainingPayment;
  };

  setTotalPrice = totalPrice => {
    this.dealCurrent.totalPrice = totalPrice;
  };

  setIsEligibility = isEligibility => {
    this.dealCurrent.isEligibility = isEligibility;
  };

  setPriorities = priorities => {
    this.priorities = priorities;
  };

  setObservacoes = (observacoes, conditionalsIds) => {
    this.dealCurrent.comments = observacoes;
    this.dealCurrent.conditionalsIds = conditionalsIds;
  };

  setShouldHireMechanicalWarranty = () => {
    this.dealCurrent.shouldHireMechanicalWarranty = true;
  };

  setRegionalGroup = (regionalGroupId, legalInformation) => {
    const dealCurrent = { ...this.dealCurrent };
    dealCurrent.regionalGroupId = regionalGroupId;
    dealCurrent.legalInformation = legalInformation;
    this.dealCurrent = dealCurrent;
  };

  setOpcionais = opcionais => {
    this.dealCurrent.featureIds = opcionais;
  };

  setPrice = price => {
    const dealCurrent = { ...this.dealCurrent };
    dealCurrent.price = Number(price).toFixed(0);
    this.dealCurrent = dealCurrent;
  };

  // setPurchasePrice = price => {
  //   const dealCurrent = { ...this.dealCurrent };
  //   dealCurrent.purchasePrice = Number(price).toFixed(0);
  //   this.dealCurrent = dealCurrent;
  // };

  setPrices = fipePrice => {
    this.fipePrice = fipePrice;
  };

  setYoutubeId = youtubeId => {
    this.dealCurrent.youtubeId = youtubeId;
  };

  setInitialState = ({
    hasFetched,
    hasFailed,
    isLoading,
    dealCurrent,
    deals,
    infos,
    stock,
    priorities,
    plans,
    dealInfo,
    fipePrice,
    config,
    configPortais,
    stockParams,
  }) => {
    this.hasFetched = hasFetched;
    this.priorities = priorities;
    this.hasFailed = hasFailed;
    this.isLoading = isLoading;
    this.dealCurrent = dealCurrent;
    this.deals = deals;
    this.infos = infos;
    this.plans = plans;
    this.stock = stock;
    this.dealInfo = dealInfo;
    this.fipePrice = fipePrice;
    this.configPortais = configPortais;
    this.config = config;
    this.stockParams = stockParams;
  };

  setDeals = (data, hasFailed, hasFetched, isLoading) => {
    this.deals = data;
    this.dealsHasFailed = hasFailed;
    this.dealsHasFetched = hasFetched;
    this.dealsIsLoading = isLoading;
  };

  setStatusData = (hasFailed, hasFetched, isLoading) => {
    this.dealsHasFailed = hasFailed;
    this.dealsHasFetched = hasFetched;
    this.dealsIsLoading = isLoading;
  };

  setInfos = (infos, hasFailed, hasFetched, isLoading) => {
    this.infos = infos;
    this.infosHasFailed = hasFailed;
    this.infosHasFetched = hasFetched;
    this.infosIsLoading = isLoading;
  };

  setStock = (stock, hasFailed, hasFetched, isLoading) => {
    this.stock = stock;
    this.stockHasFailed = hasFailed;
    this.stockHasFetched = hasFetched;
    this.stockIsLoading = isLoading;
  };

  setStatusInfos = (hasFailed, hasFetched, isLoading) => {
    this.infosHasFailed = hasFailed;
    this.infosHasFetched = hasFetched;
    this.infosIsLoading = isLoading;
  };

  setConfigPortais = (configPortais, hasFailed, hasFetched, isLoading) => {
    this.configPortais = configPortais;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setInfosDeal = (dealInfo, hasFailed, hasFetched, isLoading) => {
    this.dealInfo = dealInfo;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setDataSearched = (searched, hasFailed, hasFetched, isLoading) => {
    this.searched = searched;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setPlans = (plans, hasFailed, hasFetched, isLoading) => {
    this.plans = plans;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setImages = (images = [], hasFailed, hasFetched, isLoading) => {
    const imagesCurrent = images.map((image, position) => ({
      ...image,
      position: position,
    }));

    const dealCurrent = { ...this.dealCurrent, images: imagesCurrent };
    this.dealCurrent = dealCurrent;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setValueMinAndMax = (
    valuesMinAndMaxPrice,
    hasFailed,
    hasFetched,
    isLoading
  ) => {
    this.valuesMinAndMaxPrice = valuesMinAndMaxPrice;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  getAllDeals = async (
    order = 1,
    page = 0,
    size = 10000,
    options = { showroom: true }
  ) => {
    const result = await InventoryService.getDealsStock({
      dealerId: this.rootStore.dealerStore.dealer.id,
      order,
      page,
      size,
      options,
    });
    const { data, success } = result;
    if (success) {
      return data;
    } else {
      return false;
    }
  };

  getDeals = async (
    page = PaginationDefaultOptions.page,
    size = PaginationDefaultOptions.size,
    options = { ...PaginationDefaultOptions.options },
    dealerId = null
  ) => {
    this.setDeals(null, null, null, true);
    const params = buildOptionsToParams(options);
    this.setConfig(params);
    const result = await InventoryService.getDealsStock({
      dealerId: dealerId || this.rootStore.dealerStore.dealer.id,
      order: 1,
      page,
      size,
      options: params,
    });

    const { data, success } = result;
    if (success) {
      this.setDeals(data, false, true, false);
    } else {
      this.setDeals(null, true, false, false);
    }
  };

  getDealsStock = async params => {
    try {
      this.setDeals(null, null, null, true);
      const result = await InventoryService.getDealsStock({
        dealerId: this.rootStore.dealerStore.dealer.id,
        page: params?.page ?? this.stockParams.page,
        order: params?.order ?? this.stockParams.order,
        size: params?.size ?? this.stockParams.size,
        options: { ...this.stockParams.options, ...params?.options },
      });

      const { data, success } = result;
      if (success) {
        this.setDeals(data, false, true, false);
      } else {
        this.setDeals(null, true, false, false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  getPrice = async (trim, year) => {
    const state = 'SP';
    try {
      const result = await InventoryService.getPrice(
        this.rootStore.dealerStore.dealer.id,
        state,
        trim,
        year
      );
      const { success, data } = result;
      if (success) {
        this.setPrices(data, false, true, false);
      } else {
        this.setPrices(null, true, false, false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  refreshDeals = () => {
    this.getDealsStock();
    this.getStock(this.config.zeroKm, this.config.usado);
  };

  changePrice = async (
    dealerId = this.rootStore.dealerStore.dealer.id,
    deal
  ) => {
    const result = await InventoryService.updateInventoryById(
      dealerId,
      deal.id,
      deal
    );
    const { data, success } = result;
    if (success) {
      if ((data || {}).id) {
        this.refreshDeals();
      } else {
        this.setDataCurrent(null, true, false, false);
      }
    } else {
      this.setDataCurrent(null, true, false, false);
    }
    return data;
  };

  getInventory = async (
    dealerId = this.rootStore.dealerStore.dealer.id,
    dealId
  ) => {
    const result = await InventoryService.getInventoryById(dealerId, dealId);
    const { data, success } = result;
    if (success) {
      if ((data || {}).id) {
        this.setDataCurrent(data, false, true, false);
        return true;
      }
      return false;
    } else {
      this.setDataCurrent(null, true, false, false);
      return false;
    }
  };

  updateInventory = async () => {
    const { hasMechanicalWarranty } = this.rootStore.searchStore;
    const dealCurrent = {
      ...this.dealCurrent,
    };

    if (!dealCurrent.images) dealCurrent.images = [];
    if (dealCurrent.vehicleType === VEHICLE_TYPE[1].label) {
      dealCurrent.doors = null;
    }
    const { vehicleType, ...deal } = dealCurrent;

    const dealPublishes = this.cleanDealPublishes();
    deal.dealPublishes = [...dealPublishes];

    if (hasMechanicalWarranty) {
      delete dealCurrent.shouldHireMechanicalWarranty;
      delete deal.shouldHireMechanicalWarranty;
    }

    if (
      this.rootStore.dealerStore?.minimumImagesToPublish &&
      dealCurrent.images.length <
        this.rootStore.dealerStore.minimumImagesToPublish
    ) {
      delete deal.dealPublishes;
    }

    const result = await InventoryService.updateInventoryById(
      this.rootStore.dealerStore.dealer.id,
      deal.id,
      deal
    );

    const { success, data } = result;
    if (success) {
      const deals = updateItemIntoContent(data, this.deals);
      if (!isEmpty(deals)) {
        this.setDeals(deals);
      }
    } else {
      this.setDataCurrent({ ...deal }, true, false, false);
    }

    return { success, data };
  };

  updatePriceByDealId = async () => {
    const result = await InventoryService.updatePriceByDealId(
      this.rootStore.dealerStore.dealer.id,
      this.dealCurrent.id,
      this.dealCurrent.price
    );
    if (result.success) {
      return result;
    } else {
      return result;
    }
  };

  downloadDetailsPaper = async (dealerId, dealId) => {
    const result = await InventoryService.downloadDetailsPaper(
      dealerId,
      dealId
    );
    return result;
  };

  deleteInventory = async (
    dealerId = this.rootStore.dealerStore.dealer.id,
    dealId
  ) => {
    const result = await InventoryService.deleteInventory(dealerId, dealId);
    return result;
  };

  setConfig = config => {
    this.config = { ...config };
  };

  getStock = async (deal0km, usado) => {
    this.setStock(null, false, false, true);
    const result = await InventoryService.getStock(
      this.rootStore.dealerStore.dealer.id,
      deal0km,
      usado
    );
    const { data, success } = result;
    if (success) {
      this.setStock(data, false, true, false);
    } else {
      this.setStock(null, true, false, false);
    }
  };

  getInfos = async (isZero, isUsado) => {
    const result = await InventoryService.getInfos(
      this.rootStore.dealerStore.dealer.id,
      isZero,
      isUsado
    );
    const { data, success } = result;
    if (success) {
      this.setInfos(data || {}, false, true, false);
    } else {
      this.setInfos(null, true, false, false);
    }
  };

  getInfosDeal = async (
    dealerId = this.rootStore.dealerStore.dealer.id,
    dealId
  ) => {
    const result = await InventoryService.getInfosDeal(
      (dealerId = this.rootStore.dealerStore.dealer.id),
      dealId
    );
    const { data, success } = result;
    if (success) {
      this.setInfosDeal(data, false, true, false);
    } else {
      this.setInfosDeal(null, true, false, false);
    }
  };

  getPriorities = async (dealerId = this.rootStore.dealerStore.dealer.id) => {
    const responseFromApi = await InventoryService.getPriorities(dealerId);
    const { data, success } = responseFromApi;
    if (success) {
      this.setPriorities(data);
    } else {
      this.setPriorities(null);
    }
  };

  search = async (dealerId = this.rootStore.dealerStore.dealer.id, query) => {
    const result = await InventoryService.search(dealerId, query);
    const { data, success } = result;
    if (success) {
      this.setDataSearched(data, false, true, false);
    } else {
      this.setDataSearched(null, true, false, false);
    }
  };

  getPlans = async (
    dealerId = this.rootStore.dealerStore.dealer.id,
    trimId = null,
    deal0km = null,
    modelYear,
    dealId = null
  ) => {
    let result;

    if (trimId) {
      result = await InventoryService.getPlans(
        dealerId,
        trimId,
        deal0km,
        modelYear,
        dealId
      );
    } else {
      result = await InventoryService.getPlans(
        dealerId,
        this.dealCurrent.trimId,
        this.dealCurrent?.deal0km || this.dealCurrent?.km === 0 || false,
        this.dealCurrent.modelYear,
        this.dealCurrent.id
      );
    }

    const { data, success } = result;
    if (success) {
      this.setPlans(data, false, true, false);
    } else {
      this.setPlans([], true, false, false);
    }
  };

  getConfigPortais = async (
    dealerId = this.rootStore.dealerStore.dealer.id
  ) => {
    try {
      const result = await InventoryService.getConfigPortais(dealerId);
      const { data, success } = result;
      if (success) {
        this.setConfigPortais(data, false, true, false);
      } else {
        this.setConfigPortais(null, true, false, false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  emptyInventoryCurrent = () => {
    this.setDataCurrent(_dealCurrent, true, false, false);
  };

  addDeal = async (dealerId = this.rootStore.dealerStore.dealer.id) => {
    const dealCurrent = { ...this.dealCurrent };
    if (!dealCurrent.images) {
      dealCurrent.images = [];
    }

    if (dealCurrent.vehicleType === VEHICLE_TYPE[1].label) {
      dealCurrent.doors = null;
    }

    const { vehicleType, ...deal } = dealCurrent;
    const dealPublishes = this.cleanDealPublishes(true);
    deal.dealPublishes = [...dealPublishes];

    if (
      this.rootStore.dealerStore?.minimumImagesToPublish &&
      dealCurrent.images.length <
        this.rootStore.dealerStore?.minimumImagesToPublish
    ) {
      delete deal.dealPublishes;
    }

    const result = await InventoryService.createInventory(dealerId, deal);
    const { success, data } = result;
    if (success) {
      this.setDataCurrent(_dealCurrent, true, false, false);
      return { success, data };
    } else {
      return { success, data };
    }
  };

  emptyDeals = () => {
    this.setDeals([], null, null, null);
  };

  setInventoryCurrentByPlate = data => {
    const dataCurrent = { ..._dealCurrent };
    const { trim, vin } = data;

    dataCurrent.id = this.dealCurrent?.id || undefined;
    dataCurrent.productionYear =
      data.productionYear || _dealCurrent.productionYear;
    dataCurrent.modelYear = data.modelYear || _dealCurrent.modelYear;
    dataCurrent.colorId =
      trim.colorId || this.dealCurrent.colorId || _dealCurrent.colorId;
    dataCurrent.comments = trim.comments || _dealCurrent.comments;
    dataCurrent.doors = trim.doors || _dealCurrent.doors;
    dataCurrent.conditionalsIds =
      trim.conditionalsIds || _dealCurrent.conditionalsIds;
    dataCurrent.featureIds = trim.featureIds || _dealCurrent.featureIds;
    dataCurrent.fuelId = trim.fuelId || _dealCurrent.fuelId;
    dataCurrent.images =
      trim.images || this.dealCurrent.images || _dealCurrent.images;
    dataCurrent.km = trim.km || this.dealCurrent.km || _dealCurrent.km;
    dataCurrent.makeId = trim.makeId || _dealCurrent.makeId;
    dataCurrent.makeName = trim.makeName || _dealCurrent.makeName;
    dataCurrent.modelId = trim.modelId || _dealCurrent.modelId;
    dataCurrent.modelName = trim.modelName || _dealCurrent.modelName;
    dataCurrent.price =
      trim.price || this.dealCurrent.price || _dealCurrent.price;
    dataCurrent.transmissionId =
      trim.transmissionId ||
      this.dealCurrent.transmissionId ||
      _dealCurrent.transmissionId;
    dataCurrent.trimId = trim.id || _dealCurrent.trimId;
    dataCurrent.trimName = trim.trimName || _dealCurrent.trimName;
    dataCurrent.vin = vin || _dealCurrent.vin;
    dataCurrent.dealPublishes =
      this.dealCurrent.dealPublishes || _dealCurrent.dealPublishes;
    dataCurrent.bodystyleId =
      this.dealCurrent.bodystyleId || _dealCurrent.bodystyleId;
    dataCurrent.molicarId = trim.molicarId || _dealCurrent.molicarId;
    this.setDataCurrent(dataCurrent, false, true, false);
    return data;
  };

  changeDealPublishes = dealPublish => {
    if (!(this.dealCurrent || {}).dealPublishes) {
      this.dealCurrent.dealPublishes = [];
    }
    const dealPublishes = [...this.dealCurrent.dealPublishes];

    const dealPublisheindex = dealPublishes.findIndex(
      element => element.providerId === dealPublish.providerId
    );

    dealPublishes[dealPublisheindex] = dealPublish;

    this.dealCurrent.dealPublishes = dealPublishes;
  };

  changeDealPublishesOnlyNewDeal = (dealPublish, indexProvider) => {
    if (!(this.dealCurrent || {}).dealPublishes) {
      this.dealCurrent.dealPublishes = [];
    }
    const dealPublishes = [...this.dealCurrent.dealPublishes];

    dealPublishes[indexProvider] = dealPublish;

    this.dealCurrent.dealPublishes = dealPublishes;
  };

  filterDealPublish = dealPublish => {
    if (
      isEmpty(dealPublish) ||
      isEmpty(dealPublish.id) ||
      (dealPublish || {}).planId === null
    ) {
      return {
        id: null || (dealPublish || {}).id,
        planId: null,
        trimId: dealPublish.trimId,
        title: dealPublish.title,
        providerDetail: dealPublish.providerDetail,
        publishStatus: StatusEnum.NOT_PUBLISHED,
      };
    }
    return {
      id: dealPublish.id,
      planId: dealPublish.planId,
      trimId: dealPublish.trimId,
      title: dealPublish.title,
      providerDetail: dealPublish.providerDetail,
      publishStatus: StatusEnum.PUBLISHED,
    };
  };

  updateDealPublishById = async (
    dealerId = this.rootStore.dealerStore.dealer.id,
    dealPublishId
  ) => {
    const dealPublish = find(this.dealCurrent.dealPublishes, {
      id: dealPublishId,
    });

    const dealPublishToSend = this.filterDealPublish(dealPublish);

    const result = await InventoryService.updateDealPublishById(
      dealerId,
      this.dealCurrent.id,
      dealPublishId,
      { ...dealPublishToSend }
    );

    const { data, success } = result;
    if (success) {
      return { data, success };
    } else {
      return { data, success };
    }
  };

  updateDealPublishOneOrMore = async changedPublishes => {
    const result = await InventoryService.updateOneOrMoreDealPublish(
      this.rootStore.dealerStore.dealer.id,
      this.dealCurrent.id,
      changedPublishes
    );

    const { data, success } = result;

    if (success) {
      return { data, success };
    } else {
      return { data, success };
    }
  };

  setOverflowPrice = overflowPrice => {
    this.overflowPrice = overflowPrice;
  };

  getValueMinAndMax = async (trimId, modelYear) => {
    this.setValueMinAndMax(null, true, false, false);
    const result = await InventoryService.getValueMinAndMax(
      this.rootStore.dealerStore.dealer.id,
      trimId,
      modelYear
    );
    const { data, success } = result;
    if (success) {
      this.setValueMinAndMax(data, false, true, false);
    } else {
      this.setValueMinAndMax(null, true, false, false);
    }
  };

  cleanDealPublishes = (isAdd = null) => {
    const dealPublishes = this.dealCurrent.dealPublishes
      .map(dealPublish => {
        if (isAdd || dealPublish.id > 0) {
          return {
            id: dealPublish.id || undefined,
            planId: dealPublish.planId || null,
            trimId: dealPublish.trimId,
            title: dealPublish.title,
            providerDetail: dealPublish.providerDetail,
            publishStatus: StatusEnum.PUBLISHED,
          };
        }
        return {
          planId: dealPublish.planId,
          trimId: dealPublish.trimId,
          title: dealPublish.title,
          providerDetail: dealPublish.providerDetail,
          publishStatus: StatusEnum.PUBLISHED,
        };
      })
      .map(item => {
        if (item.publishStatus === 'PUBLISHED') {
          if (item.planId === 'planNotFound') {
            return {
              ...item,
              planId: '',
            };
          }
          if (item.planId === undefined) {
            return {
              ...item,
              planId: '',
            };
          }
        }
        if (isEmpty(item.planId)) {
          return {
            ...item,
            planId: null,
            publishStatus: StatusEnum.NOT_PUBLISHED,
          };
        }
        return item;
      });

    return dealPublishes;
  };

  sideEffectsIntoDealForm = deal => {
    if (
      (deal.trimId === defaultSelectObject.value ||
        deal.modelYear === defaultSelectObject.value) &&
      (this.dealCurrent.trimId === defaultSelectObject.value ||
        this.dealCurrent.modelYear === defaultSelectObject.value)
    )
      return;
    if (
      deal.trimId &&
      (deal || {}).trimId !== (this.dealCurrent || {}).trimId
    ) {
      if (!this.plans) {
        this.getPlans(this.rootStore.dealerStore.dealer.id);
      }
      if (!this.configPortais) {
        this.getConfigPortais(this.rootStore.dealerStore.dealer.id);
      }
      if (!this.rootStore.searchStore.valuePriceByFipe && (deal || {}).trimId) {
        this.rootStore.searchStore.getValuePriceByFipe(
          deal.trimId,
          deal.modelYear
        );
      }
      return;
    }
    if (!this.plans) {
      this.getPlans(this.rootStore.dealerStore.dealer.id);
    }
    if (!this.configPortais) {
      this.getConfigPortais(this.rootStore.dealerStore.dealer.id);
    }
    if (
      (this.dealCurrent || {}).trimId &&
      !this.rootStore.searchStore.valuePriceByFipe
    ) {
      this.rootStore.searchStore.getValuePriceByFipe(
        this.dealCurrent.trimId,
        this.dealCurrent.modelYear,
        this.dealCurrent.deal0km
      );
    }
  };

  setCurrentPageMyDeals = currentPage => {
    this.currentPage = currentPage;
  };

  addSpotlightByDealId = async dealId => {
    try {
      const result = await InventoryService.addSpotlightByDealId(
        this.rootStore.dealerStore.dealer.id,
        dealId
      );
      if (result.success) {
        const dealsTemp = [...this.deals.content];
        const dealIndex = dealsTemp.findIndex(deal => deal.id === dealId);
        dealsTemp[dealIndex] = {
          ...dealsTemp[dealIndex],
          spotlight: true,
        };
        this.setDeals({ ...this.deals, content: dealsTemp }, null, null, false);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  removeSpotlightByDealId = async dealId => {
    try {
      const result = await InventoryService.removeSpotlightByDealId(
        this.rootStore.dealerStore.dealer.id,
        dealId
      );
      if (result.success) {
        const dealsTemp = [...this.deals.content];
        const dealIndex = dealsTemp.findIndex(deal => deal.id === dealId);
        dealsTemp[dealIndex] = {
          ...dealsTemp[dealIndex],
          spotlight: false,
        };
        this.setDeals({ ...this.deals, content: dealsTemp }, null, null, false);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export default remotedev(
  decorate(InventoryStore, {
    hasFetched: observable,
    hasFailed: observable,
    isLoading: observable,
    priorities: observable,
    deals: observable,
    config: observable,
    dealCurrent: observable,
    dealPublishes: observable,
    plans: observable,
    addSpotlightByDealId: action,
    removeSpotlightByDealId: action,
    setInitialState: action,
    getPriorities: action,
    getDealsStock: action,
    getDeals: action,
    setDeals: action,
    setInfos: action,
    setStock: action,
    getStock: action,
    getInfos: action,
    setPlans: action,
    getPlans: action,
    infos: observable,
    stock: observable,
    setDadosDeVeiculo: action,
    setObservacoes: action,
    setShouldHireMechanicalWarranty: action,
    setRegionalGroup: action,
    setOpcionais: action,
    setPriorities: action,
    setImages: action,
    deleteInventory: action,
    getInventory: action,
    updateInventory: action,
    updatePriceByDealId: action,
    setDataCurrent: action,
    setInfosDeal: action,
    getInfosDeal: action,
    dealInfo: observable,
    fipePrice: observable,
    getPrice: action,
    setPrices: action,
    setPrice: action,
    // setPurchasePrice: action,
    configPortais: observable,
    stockParams: observable,
    getConfigPortais: action,
    setConfigPortais: action,
    changePrice: action,
    setConfig: action,
    dealsHasFetched: observable,
    dealsHasFailed: observable,
    dealsIsLoading: observable,
    infosHasFailed: observable,
    infosHasFetched: observable,
    stockIsLoading: observable,
    stockHasFailed: observable,
    stockHasFetched: observable,
    infosIsLoading: observable,
    setStatusData: action,
    setStatusInfos: action,
    emptyInventoryCurrent: action,
    emptyDeals: action,
    currentHasFetched: observable,
    currentHasFailed: observable,
    currentIsLoading: observable,
    setInventoryCurrentByPlate: action,
    changeDealPublishes: action,
    changeDealPublishesOnlyNewDeal: action,
    updateDealPublishById: action.bound,
    refreshDeals: action,
    overflowPrice: observable,
    setOverflowPrice: action,
    setValueMinAndMax: action,
    valuesMinAndMaxPrice: observable,
    cleanDealPublishes: action,
    removeConfiguredProvider: action,
    currentPage: observable,
    setCurrentPageMyDeals: action,
    setYoutubeId: action,
    setIsEligibility: action,
    setDownPayment: action,
    setRemainingPayment: action,
    setTotalPrice: action,
  })
);
