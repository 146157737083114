import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QueueServices from 'services/queueServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import OrderStepperActions from 'modules/order/components/atoms/OrderStepperActions';
import { ProposalOrderStep } from 'modules/order/types/ProposalOrder';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import SubHeader from './components/SubHeader';
import ClientData from './steps/ClientData';
import QueueProposalData from './steps/QueueProposalData';
import Summary from './steps/Summary';
import ClientDataCompleted from './steps/ClientDataCompleted';

export enum ProposalSubStep {
  PROPOSAL_DATA,
  CLIENT_DATA,
  SUMMARY,
}

const QueueStep = ({ handleOnClickCloseModalProposal }) => {
  const dispatch = useDispatch();

  const { queueData, currentDealer } = useSelector(state => ({
    ...state.order,
    ...state.dealerStore,
  }));

  const [currentStep, setCurrentStep] = useState(ProposalSubStep.PROPOSAL_DATA);

  const createProposalQueue = async () => {
    try {
      const { success }: any = await QueueServices.createProposal(
        currentDealer.id,
        queueData?.proposal?.id,
        {
          delivery: {
            externalId: queueData?.delivery?.externalId,
            deliveryForecastDate: queueData?.delivery?.forecastDate,
          },
          trim: queueData?.trim,
          payment: queueData?.payment,
          client: queueData?.client,
          comments: queueData?.comments,
        },
        !!queueData?.orderPlacedAtTheAutomaker
      );
      if (success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Fila de espera criada com sucesso.'
          )
        );
        handleOnClickCloseModalProposal();
        return;
      }
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    }
  };

  const handleChangeStep = (step: ProposalSubStep) => {
    setCurrentStep(step);
  };

  const goToProposalData = () => {
    setCurrentStep(ProposalSubStep.PROPOSAL_DATA);
    dispatch(ActionCreators.setProposalStep(ProposalOrderStep.COMMENTS_QUEUE));
  };

  const goToClientData = () => {
    setCurrentStep(ProposalSubStep.CLIENT_DATA);
    dispatch(ActionCreators.setProposalStep(ProposalSubStep.CLIENT_DATA));
  };

  const goToSummary = () => {
    setCurrentStep(ProposalSubStep.SUMMARY);
    dispatch(ActionCreators.setProposalStep(ProposalSubStep.SUMMARY));
  };

  useEffect(() => {
    dispatch(
      ActionCreators.setQueueData({
        currentStepProposalQueue: currentStep,
      })
    );
  }, [currentStep]);

  useEffect(() => {
    const queueInProgress = !['NEW', '', undefined].includes(
      queueData?.status?.value
    );
    if (queueInProgress) {
      goToSummary();
    }
    if (queueData?.payment?.soldPrice > 0) {
      goToProposalData();
    }
  }, []);

  const getCurrentStep = (currentStep: ProposalSubStep) => {
    switch (currentStep) {
      case ProposalSubStep.PROPOSAL_DATA:
        return (
          <>
            <QueueProposalData />
            <OrderStepperActions
              customHandleNext={goToClientData}
              isQueue={true}
            />
          </>
        );
      case ProposalSubStep.CLIENT_DATA:
        return queueData?.client?.formOem === 'FULL' ? (
          <>
            <ClientDataCompleted />
            <OrderStepperActions
              customBackFunction={goToProposalData}
              customHandleNext={goToSummary}
              isQueue={true}
            />
          </>
        ) : (
          <>
            <ClientData />
            <OrderStepperActions
              customBackFunction={goToProposalData}
              customHandleNext={goToSummary}
              isQueue={true}
            />
          </>
        );
      case ProposalSubStep.SUMMARY:
        return (
          <>
            <Summary />
            <OrderStepperActions
              customBackFunction={goToClientData}
              customHandleNext={createProposalQueue}
              isQueue={true}
            />
          </>
        );
      default:
        return (
          <>
            <QueueProposalData />
            <OrderStepperActions
              onlyBackButton
              customHandleNext={goToClientData}
              isQueue={true}
            />
          </>
        );
    }
  };

  return (
    <div>
      <SubHeader currentStep={currentStep} onChangeStep={handleChangeStep} />
      {getCurrentStep(currentStep)}
    </div>
  );
};

export default QueueStep;
