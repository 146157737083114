import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ContainerCreateClientBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 14px;
  left: 24px;
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`;

export const EmptyListIconContainer = styled.div`
  border-radius: 35px;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #e0f3f1;
`;
