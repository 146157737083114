import React from 'react';

import { Typography } from '@material-ui/core';

import BoxConsentDefault from 'components/BoxConsent/BoxConsentDefault';

const styles = {
  root: {
    color: '#757575',
    fontSize: '16px',
  },
};

const BoxWithoutConsentComponent = () => {
  return (
    <BoxConsentDefault
      display="flex"
      justifyContent="Center"
      alignItems="center"
      height="180px"
      p={5}
    >
      <Typography style={styles.root}>
        Não há consentimento disponível no momento
      </Typography>
    </BoxConsentDefault>
  );
};

export default BoxWithoutConsentComponent;
