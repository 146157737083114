import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PaymentIcon from '@material-ui/icons/Payment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import { ProposalOrderStep } from 'modules/order/types/ProposalOrder';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import PaymentDataComponent from './steps/PaymentData';
import VehicleDataComponent from '../../../Proposal/steps/ProposalData/steps/VehicleData/VehicleDataComponent';
import OrderNotes from '../../../Proposal/steps/ProposalData/steps/OrderNotes';
import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import { ReactComponent as CarRepairIcon } from 'assets/car_repair.svg';
import Delivery from './steps/Delivery';
import { IconContainer, ExpansionPanelContainer } from './styles';

function QueueProposalData() {
  const { proposalStep, queueData } = useSelector(state => state.order);
  const dispatch = useDispatch();

  const setProposalStep = (step: ProposalOrderStep) => {
    dispatch(ActionCreators.setProposalStep(step));
  };

  const VehicleTitle = (
    <ExpansionPanelTitle
      title={'Veículo'}
      IconComponent={
        <IconContainer>
          <DriveEtaIcon />
        </IconContainer>
      }
    />
  );
  const PaymentTitle = (
    <ExpansionPanelTitle
      title={'Pagamento'}
      IconComponent={
        <IconContainer>
          <PaymentIcon />
        </IconContainer>
      }
    />
  );
  const DeliveryTitle = (
    <ExpansionPanelTitle
      title={'Previsão de entrega'}
      IconComponent={
        <IconContainer>
          <CarRepairIcon />
        </IconContainer>
      }
    />
  );
  const CommentsTitle = (
    <ExpansionPanelTitle
      title={'Observações'}
      IconComponent={
        <IconContainer>
          <InsertCommentIcon />
        </IconContainer>
      }
    />
  );

  return (
    <ExpansionPanelContainer>
      <ExpansionPanel
        panel={{ title: VehicleTitle }}
        openManual={proposalStep === ProposalOrderStep.VEHICLE_QUEUE}
      >
        <VehicleDataComponent
          handleNextPanel={() =>
            setProposalStep(ProposalOrderStep.PAYMENT_QUEUE)
          }
          isQueue={true}
        />
      </ExpansionPanel>
      <div style={{ height: 16 }} />

      <ExpansionPanel
        panel={{ title: PaymentTitle }}
        openManual={proposalStep === ProposalOrderStep.PAYMENT_QUEUE}
      >
        <PaymentDataComponent
          handlePreviousPanel={() =>
            setProposalStep(ProposalOrderStep.VEHICLE_QUEUE)
          }
          handleNextPanel={() => setProposalStep(ProposalOrderStep.DELIVERY)}
        />
      </ExpansionPanel>

      <div style={{ height: 16 }} />

      <ExpansionPanel
        panel={{ title: DeliveryTitle }}
        openManual={proposalStep === ProposalOrderStep.DELIVERY}
      >
        <Delivery
          handlePreviousPanel={() =>
            setProposalStep(ProposalOrderStep.PAYMENT_QUEUE)
          }
          handleNextPanel={() =>
            setProposalStep(ProposalOrderStep.COMMENTS_QUEUE)
          }
        />
      </ExpansionPanel>

      <div style={{ height: 16 }} />

      <ExpansionPanel
        panel={{ title: CommentsTitle }}
        openManual={proposalStep === ProposalOrderStep.COMMENTS_QUEUE}
      >
        <OrderNotes
          handlePreviousPanel={() =>
            setProposalStep(ProposalOrderStep.DELIVERY)
          }
          handleNextPanel={() =>
            setProposalStep(ProposalOrderStep.PERSON_QUEUE)
          }
          value={queueData?.comments}
          handleSaveComments={async ({ saleComments, callbackOnSuccess }) => {
            await dispatch(
              ActionCreators.setQueueData({
                comments: saleComments,
              })
            );
            callbackOnSuccess();
          }}
          isQueue
        />
      </ExpansionPanel>
    </ExpansionPanelContainer>
  );
}

export default QueueProposalData;
