import styled from 'styled-components';
import { scrollbar } from 'styles';

interface SideBarProps {
  currentToolSelected: string;
}

interface ScrollViewProps {
  items: string;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`;

export const ToolsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: solid 1px #e0e0e0;
  border-left: solid 1px #e0e0e0;
  box-sizing: border-box;

  ${scrollbar}
`;

export const ToolsContent = styled.div<SideBarProps>`
  width: ${({ currentToolSelected }) =>
    currentToolSelected !== null ? '600px' : '0px'};
  transition: width 1s;
  overflow-y: hidden;
  height: calc(100vh - 175px);

  ${scrollbar}
`;

export const SideBarScrollView = styled.div<ScrollViewProps>`
  height: ${({ items = 0 }) => items * 43}px;
`;

export const ToolButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 30px;
  margin: 10px;
  height: 43px;
  width: 43px;
  background: ${({ isActive }) => (isActive ? 'rgb(224, 242, 241)' : '#fff')};
  &:hover {
    background: rgb(224, 242, 241);
  }
`;

export const ShinyBadges = styled.div`
  height: 15px;
  border-radius: 90px;
  background: #4300d2;
  color: white;
  text-align: center;
  padding: 0px 4px;
  font-size: 8px;
  line-height: 18px;
  font-weight: 600;
`;
