import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from 'modules/financing/components/atoms/Select';
import {
  TextMaskCustom,
  CPFCNPJMaskCustom,
  CEPMaskCustom,
} from 'components/MaskTextInput';
import AutoComplete from 'components/atoms/AutoComplete';
import getDateByDatePickerComponent from 'modules/financing/helpers/getDateByDatePickerComponent';
import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import { escolarityTypeOptions } from 'modules/financing/helpers/defaultOptions';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput';

const PersonalInfosFullPayForm = ({
  genderOptions,
  civilStatusOptions,
  formData,
  onChange,
  disableAllInputs,
  streetTypeOptions,
  addressTypeOptions,
  statesOptions,
  citiesOptionsState,
  handleKeyPress,
  getAddresInfosByZipCode,
  careersOptions,
  enableCopyButton,
  disableCpfCnpj,
}) => {
  return (
    <div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="name"
            name="name"
            label="Nome Completo"
            value={formData['name']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('name')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="birthDate"
            name="birthDate"
            type="date"
            label="Data de nascimento"
            value={getDateByDatePickerComponent(formData['birthDate'])}
            onChange={onChange}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="cpf"
            name="cpf"
            label="CPF"
            value={formData['cpf']}
            disabled={disableAllInputs || disableCpfCnpj}
            InputProps={{
              inputComponent: CPFCNPJMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('cpf')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="email"
            name="email"
            type="email"
            label="E-mail"
            value={formData['email']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('email')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={3}>
          <TextInput
            id="phone"
            name="phone"
            label="Telefone 1"
            value={formData['phone']}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('phone')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="phone2"
            name="phone2"
            label="Telefone 2"
            value={formData['phone2']}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('phone2')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="nationality"
            name="nationality"
            label="Nacionalidade"
            value={formData['nationality']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('nationality')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            id="gender"
            name="gender"
            label="Sexo"
            value={formData['gender']}
            onChange={onChange}
            options={genderOptions}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            id="civilStatus"
            name="civilStatus"
            label="Estado Civil"
            value={formData['civilStatus']}
            onChange={onChange}
            options={civilStatusOptions}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={3}>
          <TextInput
            id="rgNumber"
            name="rgNumber"
            label="RG"
            value={formData['rgNumber']}
            disabled={disableAllInputs}
            onChange={onChange}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('rgNumber')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="rgIssuer"
            name="rgIssuer"
            label="Orgão emissor"
            value={formData['rgIssuer']}
            onChange={onChange}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            id="rgIssuedState"
            name="rgIssuedState"
            label="UF do orgão emissor"
            value={formData['rgIssuedState']}
            onChange={onChange}
            options={statesOptions}
            disabled={disableAllInputs}
            inputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('schoolingType')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="schoolingType"
            name="schoolingType"
            label="Escolaridade"
            value={formData['schoolingType']}
            onChange={onChange}
            options={escolarityTypeOptions}
            disabled={disableAllInputs}
            inputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('schoolingType')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <AutoComplete
            name="careerId"
            label="Profissão*"
            options={careersOptions}
            value={
              formData?.['careerId']
                ? {
                    id: formData['careerId'],
                    value: formData['careerId'],
                    label: careersOptions.find(
                      e => e.value == formData['careerId']
                    )?.label,
                    name: careersOptions.find(
                      e => e.value == formData['careerId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({ target: { value: e?.value, name: 'careerId' } })
            }
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
      </Grid>
      <div style={{ margin: '24px 0px 8px' }}>Endereço:</div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <Select
            id="addressType"
            name="addressType"
            label="Tipo do endereço"
            value={formData['addressType']}
            onChange={onChange}
            options={addressTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="zipcode"
            name="zipcode"
            label="CEP"
            value={formData['zipcode']}
            onKeyPress={handleKeyPress}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: CEPMaskCustom,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton onClick={getAddresInfosByZipCode} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={3}>
          <Select
            id="streetType"
            name="streetType"
            label="Tipo do logradouro"
            value={formData['streetType']}
            onChange={onChange}
            options={streetTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="address"
            name="address"
            label="Endereço"
            value={formData['address']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('address')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="number"
            name="number"
            label="Número"
            value={formData['number']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('number')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={12}>
          <TextInput
            id="complement"
            name="complement"
            label="Complemento (opcional)"
            value={formData['complement']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('complement')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="neighborhood"
            name="neighborhood"
            label="Bairro*"
            value={formData['neighborhood']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('neighborhood')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="cityStateId"
            label="Estado*"
            options={statesOptions}
            value={
              formData?.['cityStateId']
                ? {
                    id: formData['cityStateId'],
                    value: formData['cityStateId'],
                    label: statesOptions.find(
                      e => e.value === formData['cityStateId']
                    )?.label,
                    name: statesOptions.find(
                      e => e.value === formData['cityStateId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({ target: { value: e?.value, name: 'cityStateId' } })
            }
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="cityId"
            label="Cidade*"
            options={citiesOptionsState}
            value={
              formData?.['cityId']
                ? {
                    id: formData['cityId'],
                    value: formData['cityId'],
                    label: citiesOptionsState.find(
                      e => e.value === formData['cityId']
                    )?.label,
                    name: citiesOptionsState.find(
                      e => e.value === formData['cityId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({ target: { value: e?.value, name: 'cityId' } })
            }
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PersonalInfosFullPayForm;
