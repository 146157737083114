import { connect } from 'react-redux';

import formatToSelectInput from 'helpers/formatToSelectInput';
import ActionCreators from 'modules/evaluation/store/reducers/actionCreators';
import DealFormActionCreators from 'modules/resale/store/reducers/dealForm/actionCreators';

import PricingVehicleContainer from './PricingVehicleContainer';

function mapStateToProps(state) {
  return {
    armorTypes: formatToSelectInput(state.dealForm.armorTypes),
    colors: formatToSelectInput(state.dealForm.colors),
    deal: state.evaluation,
    doors: formatToSelectInput(state.dealForm.doors),
    fuels: formatToSelectInput(state.dealForm.fuels),
    makes: formatToSelectInput(state.dealForm.makes),
    models: formatToSelectInput(state.dealForm.models),
    modelYears: formatToSelectInput(state.dealForm.modelYears),
    productionYears: formatToSelectInput(state.dealForm.productionYears),
    transmissions: formatToSelectInput(state.dealForm.transmissions),
    trims: formatToSelectInput(state.dealForm.trims),
    vehicleTypes: state.dealForm.vehicleTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVehicleTypes: () => {
      dispatch(DealFormActionCreators.getVehicleTypes());
    },
    setVehicleType: vehicleType => {
      dispatch(ActionCreators.setVehicleType(vehicleType));
    },
    setVehicleEvaluationType: vehicleType => {
      dispatch(ActionCreators.setVehicleEvaluationType(vehicleType));
    },
    getMakes: () => {
      dispatch(DealFormActionCreators.getMakes());
    },
    getModels: makeId => {
      dispatch(DealFormActionCreators.getModels(makeId));
    },
    getProductionYears: modelName => {
      dispatch(DealFormActionCreators.getProductionYears(modelName));
    },
    getModelYears: year => {
      dispatch(DealFormActionCreators.getModelYears(year));
    },
    getTrims: modelName => {
      dispatch(DealFormActionCreators.getTrims(modelName));
    },
    getColors: () => {
      dispatch(DealFormActionCreators.getColors());
    },
    getDoors: () => {
      dispatch(DealFormActionCreators.getDoors());
    },
    getFuels: () => {
      dispatch(DealFormActionCreators.getFuels());
    },
    getTransmissions: () => {
      dispatch(DealFormActionCreators.getTransmissions());
    },
    setMake: ({ makeId, makeName }) => {
      dispatch(ActionCreators.setMake(makeId, makeName));
      dispatch(DealFormActionCreators.getModels(makeName));
    },
    setModel: ({ modelName, modelId }) => {
      dispatch(ActionCreators.setModel(modelName, modelId));
      dispatch(DealFormActionCreators.getProductionYears(modelId));
    },
    setProductionYear: productionYear => {
      dispatch(ActionCreators.setProductionYear(productionYear));
      dispatch(DealFormActionCreators.getModelYears(productionYear));
    },
    setModelYear: modelYear => {
      dispatch(ActionCreators.setModelYear(modelYear));
      dispatch(DealFormActionCreators.getTrims(modelYear));
    },
    setTrim: ({ trimId, trimName }) => {
      dispatch(ActionCreators.setTrim(trimId, trimName));
    },
    setDoor: door => {
      dispatch(ActionCreators.setDoors(door));
    },
    setColorId: colorId => {
      dispatch(ActionCreators.setColorId(colorId));
    },
    setTransmission: transmissionId => {
      dispatch(ActionCreators.setTransmission(transmissionId));
    },
    setFuel: fuelId => {
      dispatch(ActionCreators.setFuel(fuelId));
    },
    setKm: km => {
      dispatch(ActionCreators.setKm(km));
    },
    setPlate: plate => {
      dispatch(ActionCreators.setPlate(plate));
    },
    setArmored: armored => {
      dispatch(ActionCreators.setArmored(armored));
      dispatch(DealFormActionCreators.getArmorTypes());
    },
    setArmorType: armorType => {
      dispatch(ActionCreators.setArmorType(armorType));
    },
    setVin: vin => {
      dispatch(ActionCreators.setVin(vin));
    },
    setRenavam: renavam => {
      dispatch(ActionCreators.setRenavam(renavam));
    },
    setTradeIn: trade => {
      dispatch(ActionCreators.setTradeIn(trade));
    },
    setCPF: cpf => {
      dispatch(ActionCreators.setOwnerCPF(cpf));
    },
    setName: name => {
      dispatch(ActionCreators.setOwnerName(name));
    },
    setPhone: phone => {
      dispatch(ActionCreators.setOwnerPhone(phone));
    },
    setEmail: email => {
      dispatch(ActionCreators.setOwnerEmail(email));
    },
    setManufacturingPackageCode: manufacturingPackageCode => {
      dispatch(
        ActionCreators.setManufacturingPackageCode(manufacturingPackageCode)
      );
    },
  };
}

const PricingVehicle = connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingVehicleContainer);

export default PricingVehicle;
