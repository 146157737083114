const columns = {
  PROSPECT: { id: 'PROSPECT', label: 'CRM' },
  NEW: { id: 'NEW', label: 'Novas' },
  IN_NEGOTIATION: {
    id: 'IN_NEGOTIATION',
    label: 'Em negociação',
    chipType: 'warning',
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    label: 'Em andamento',
    chipType: 'warning',
  },
  IN_ANALYSE: { id: 'IN_ANALYSE', label: 'Em análise', chipType: 'warning' },
  FINISHED_WITH_SOLD: {
    id: 'FINISHED_WITH_SOLD',
    label: 'Finalizada com venda',
  },
  SCHEDULED: {
    id: 'SCHEDULED',
    label: 'Agendamentos',
  },
  FINISHED_WITHOUT_SOLD: {
    id: 'FINISHED_WITHOUT_SOLD',
    label: 'Finalizado sem venda',
  },
  FINISHED: { id: 'FINISHED', label: 'Concluídas' },
  FINISHED_AND_CONCLUDED: {
    id: 'FINISHED_AND_CONCLUDED',
    label: 'Venda Realizada',
  },
  FINISHED_BUT_NOT_CONCLUDED: {
    id: 'FINISHED_BUT_NOT_CONCLUDED',
    label: 'Venda não Realizada',
  },
  QUALIFIED: {
    id: 'QUALIFIED',
    label: 'Qualificado',
  },
  DISQUALIFIED: {
    id: 'DISQUALIFIED',
    label: 'Desqualificado',
  },
  NOT_CONTACTED: {
    id: 'NOT_CONTACTED',
    label: 'Sem contato',
  },
  UNATTENDED: {
    id: 'UNATTENDED',
    label: 'Não atendido',
  },
  FAILURE: {
    id: 'FAILURE',
    label: 'Insucesso',
  },
  SUCCESS: {
    id: 'SUCCESS',
    label: 'Sucesso',
  },
  SCHEDULED_VISIT: { id: 'SCHEDULED_VISIT', label: 'Visita agendada' },
  FINISHED_BY_TIME: { id: 'FINISHED_BY_TIME', label: 'Concluída por tempo' },
  WITH_ORDER: { id: 'WITH_ORDER', label: 'Pedido de Venda' },
  FINANCED: { id: 'FINANCED', label: 'Financiado' },
  IN_CASH: { id: 'IN_CASH', label: 'À vista' },
  APPROVED: { id: 'APPROVED', label: 'Aprovado', chipType: 'success' },
  CANCELED: { id: 'CANCELED', label: 'Cancelado', chipType: 'error' },
  RETURNED: { id: 'RETURNED', label: 'Devolvido' },
  INVOICED: { id: 'INVOICED', label: 'Faturado', chipType: 'orange' },
  NOT_FATURED: { id: 'NOT_FATURED', label: 'À Faturar', chipType: 'gray' },
  PROPOSAL_ORDER_OEM: {
    id: 'PROPOSAL_ORDER_OEM',
    label: 'Fila de Espera',
    chipType: 'gray',
  },
  DELIVERY_LIST: {
    id: 'DELIVERY_LIST',
    label: 'Lista de entrega',
  },
  FINISHED_BY_CLIENT: {
    id: 'FINISHED_BY_CLIENT',
    label: 'Finalizado pelo cliente',
    chipType: 'error',
  },
};

export default columns;
