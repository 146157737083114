import formatAmount from 'helpers/formatAmount';
import React from 'react';
import {
  PaymentListFooter,
  TotalValueLabel,
  TotalValueTitle,
  VerticalDivider,
} from './styles';

import { useSelector } from 'react-redux';
import usePayment from './usePayment';

function PaymentTotalValues() {
  const {
    totalRemaining,
    totalChargedProducts,
    totalInserted,
    totalServicesSubtractionTotalTroco,
    hasProductServices,
  } = usePayment();

  const {
    currentDealer: { products },
  } = useSelector(state => state?.dealerStore);

  const isMobiOne = (products || [])
    .map(item => item?.type)
    .includes('MOBIONE');

  return (
    <PaymentListFooter>
      <div>
        <TotalValueLabel>Serviços cobrados</TotalValueLabel>
        <TotalValueTitle>
          {formatAmount(totalChargedProducts || 0, 'PT-BR', 'R$')}
        </TotalValueTitle>
      </div>
      <VerticalDivider />
      <div>
        <TotalValueLabel>Total inserido</TotalValueLabel>
        <TotalValueTitle>
          {formatAmount(totalInserted, 'PT-BR', 'R$')}
        </TotalValueTitle>
      </div>
      <VerticalDivider />
      <div>
        <TotalValueLabel>Restante a pagar</TotalValueLabel>
        <TotalValueTitle variant="primary">
          {formatAmount(
            !totalRemaining || totalRemaining < 0 ? 0 : totalRemaining,
            'PT-BR',
            'R$'
          )}
        </TotalValueTitle>
      </div>
      {!isMobiOne && hasProductServices && (
        <>
          <VerticalDivider />
          <div>
            <TotalValueLabel>
              Total do troco - Serviços Cobrados
            </TotalValueLabel>
            <TotalValueTitle
              variant={
                (totalServicesSubtractionTotalTroco > 0 && 'green') ||
                (totalServicesSubtractionTotalTroco < 0 && 'red') ||
                (totalServicesSubtractionTotalTroco === 0 && 'primary')
              }
            >
              {formatAmount(totalServicesSubtractionTotalTroco, 'PT-BR', 'R$')}
            </TotalValueTitle>
          </div>
        </>
      )}
      {Boolean(totalRemaining && totalRemaining < 0) && (
        <>
          <VerticalDivider />
          <div>
            <TotalValueLabel>Valor excedente</TotalValueLabel>
            <TotalValueTitle variant="warning">
              {formatAmount(Math.abs(totalRemaining || 0), 'PT-BR', 'R$')}
            </TotalValueTitle>
          </div>
        </>
      )}
    </PaymentListFooter>
  );
}

export default React.memo(PaymentTotalValues);
