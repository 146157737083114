import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;

  border-top: 1px solid #E8E8E8;

  > span {
    font-family: 'Poppins Regular';
    color: #757575;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: block;
  }
`;

export const CreatedAt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  font-family: 'Poppins Regular';
  color: #757575;

  > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  > span {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div<{ done: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: ${props => props.done ? "#E0F3F1" : "#F5F5F5"};

  font-size: 24px;

  margin-right: 22px;
  margin-top: 22px;

  width: 50px;
  height: 30px;

  padding: 8px;
  border-radius: 4px;
`;

export const DoneIconWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;

  height: 10px;
  width: 10px;
`;

export const FlexSalesOrder = styled.div`
  display: flex;
  align-items: center;

  margin-top: 16px;

  gap: 3px;

  > p {
    font-family: "Poppins Regular";
    font-size: 14px;
    font-weight: 500;
    color: #00B4AA;
    margin: 0px;
  }
`;