const handlePDFBlobFile = async (BlobData, filename, openInNewTab = false) => {
  try {
    const blob = new Blob([BlobData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.pdf`);
    if (openInNewTab) {
      window.open(link, '_blank');
      return;
    }
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (e) {
    console.log(e);
  }
};

export default handlePDFBlobFile;
