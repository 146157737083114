import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as StepArrowDefault } from 'assets/step-arrow-default.svg';
import { ReactComponent as StepArrowActive } from 'assets/step-arrow-active.svg';
import { ReactComponent as FirstStepArrowActive } from 'assets/first-step-arrow-active.svg';

import { DealerFromStore } from 'components/withDealer';
import ProposalStatusTip from 'components/ProposalStatusTip/ProposalStatusTip';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import columns from 'constants/columns';
import lazyRetry from 'helpers/lazyRetry';
import { usePermissions } from 'hooks/usePermissions';
import { useChatContext } from 'pages/chats/ChatsContext';
import ProposalServices from 'services/proposalServices';

import ProposalRating from '../../components/ProposalRating';
import ConfirmStatusDialog from '../ConfirmStatusDialog';
import ArchiveProposal from './components/ArchiveProposal';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';
import { highAccessUserTypes } from 'constants/userAccess';

import { StepArrowButton, LabelStepArrowButton } from './styles';

const AddSchedule = React.lazy(() =>
  lazyRetry(() => import('components/AddSchedule'))
);
const AddAnnotation = React.lazy(() =>
  lazyRetry(() => import('components/AddAnnotation'))
);

const finishedTypes = [
  'FINISHED_WITH_SOLD',
  'FINISHED_AND_CONCLUDED',
  'FINISHED_BUT_NOT_CONCLUDED',
  'FINISHED_BY_TIME',
  'DISQUALIFIED',
  'QUALIFIED',
  'NOT_CONTACTED',
  'UNATTENDED',
  'SUCCESS',
  'FAILURE',
];

const SubHeader = ({ vision = 'SHOWROOM', currentProposalStatus }) => {
  const dispatch = useDispatch();
  const {
    currentProposal,
    getCurrentProposal,
    currentUserType,
  }: any = useChatContext();
  const { mustHaveProposalAnnotation } = useSelector(
    state => state.dealerStore.currentDealer
  );

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [openAnnotation, setOpenAnnotation] = useState(false);
  const [openScheduling, setOpenScheduling] = useState(false);

  const { hasPermission } = usePermissions();
  const canArchiveProposals = checkCallCenterOsSearchAndRescueRoute()
    ? highAccessUserTypes.includes(currentUserType)
    : hasPermission('PROPOSAL_ARCHIVE');

  const changeStatusCurrentProposalCallCenter = async (
    status,
    reasons = {
      statusDetailId: '',
    }
  ) => {
    try {
      const dealerGroup = DealerFromStore.getDealerGroupFromLocalStorage();
      const {
        success,
        data: apiData,
      }: any = await ProposalServices.alterStatusProposalSalesAssistant({
        groupId: dealerGroup?.id,
        proposalId: currentProposal.id,
        newStatus: status,
        newStatusDetailId: reasons.statusDetailId,
      });
      if (success) {
        await getCurrentProposal();
        setOpenConfirmDialog(false);
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Desculpe, Ocorreu um erro inesperado.'
          )
        );
        return apiData;
      }
    } catch (e) {
      setOpenConfirmDialog(false);
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  };

  const changeStatusCurrentProposalShowroom = async (
    status,
    extraData = {
      orderCpfCnpj: '',
      orderId: '',
      statusDetailId: '',
      reasonNotToSell: '',
    }
  ) => {
    try {
      if (status) {
        const {
          success,
          data: apiData,
        }: any = await ProposalServices.changeStatusProposal(
          currentProposal.dealerId,
          currentProposal.id,
          status,
          extraData
        );
        if (success) {
          await getCurrentProposal();
          setOpenConfirmDialog(false);
        } else {
          dispatch(UIActionCreators.snackbarManagement('error', apiData));
          return apiData;
        }
      }
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: 51,
        margin: '0px 16px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StepArrowButton>
          <FirstStepArrowActive />
          <LabelStepArrowButton firstStep isActive>
            Novo
          </LabelStepArrowButton>
        </StepArrowButton>

        <StepArrowButton
          onClick={() =>
            finishedTypes.includes(currentProposalStatus)
              ? null
              : vision === 'SHOWROOM'
              ? mustHaveProposalAnnotation
                ? setOpenAnnotation(true)
                : changeStatusCurrentProposalShowroom(columns.IN_NEGOTIATION.id)
              : changeStatusCurrentProposalCallCenter(columns.IN_PROGRESS.id)
          }
        >
          {currentProposalStatus !== 'NEW' ? (
            <StepArrowActive />
          ) : (
            <StepArrowDefault />
          )}
          <LabelStepArrowButton
            isActive={currentProposalStatus !== 'NEW' ? true : false}
          >
            {vision === 'SHOWROOM' ? 'Em negociação' : 'Em andamento'}
          </LabelStepArrowButton>
        </StepArrowButton>

        {vision === 'SHOWROOM' && (
          <StepArrowButton onClick={() => setOpenScheduling(true)}>
            {!['NEW', 'IN_NEGOTIATION'].includes(currentProposalStatus) ? (
              <StepArrowActive />
            ) : (
              <StepArrowDefault />
            )}
            <LabelStepArrowButton
              isActive={
                !['NEW', 'IN_NEGOTIATION'].includes(currentProposalStatus)
                  ? true
                  : false
              }
            >
              Agendamento
            </LabelStepArrowButton>
          </StepArrowButton>
        )}

        <StepArrowButton
          onClick={() =>
            finishedTypes.includes(currentProposalStatus)
              ? null
              : setOpenConfirmDialog(true)
          }
        >
          {finishedTypes.includes(currentProposalStatus) ? (
            <StepArrowActive />
          ) : (
            <StepArrowDefault />
          )}
          <LabelStepArrowButton
            isActive={
              finishedTypes.includes(currentProposalStatus) ? true : false
            }
          >
            Concluído
          </LabelStepArrowButton>
        </StepArrowButton>
        {currentProposalStatus && (
          <div style={{ margin: '0 8px' }}>
            <ProposalStatusTip
              status={currentProposalStatus}
              statusDetail={currentProposal.statusDetailName}
            />
          </div>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <ProposalRating />
        {canArchiveProposals && (
          <>
            <div
              style={{
                borderRight: '1px solid #b6b6b6',
                margin: '0px 8px',
              }}
            ></div>
            <ArchiveProposal />
          </>
        )}
      </div>
      {openAnnotation && (
        <AddAnnotation
          open={openAnnotation}
          handleClose={() => setOpenAnnotation(false)}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() =>
            changeStatusCurrentProposalShowroom(columns.IN_NEGOTIATION.id)
          }
        />
      )}
      {openScheduling && (
        <AddSchedule
          open={openScheduling}
          handleClose={() => setOpenScheduling(false)}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() =>
            changeStatusCurrentProposalShowroom(columns.SCHEDULED.id)
          }
        />
      )}
      {/* // SHOWROOM */}
      {openConfirmDialog && vision === 'SHOWROOM' && (
        <ConfirmStatusDialog
          isOpen={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          confirmStatus={changeStatusCurrentProposalShowroom}
          draggableItemId={currentProposal.dealerId}
          departmentId={currentProposal?.departmentId}
          clientUserCpfCnpj={currentProposal?.clientUserCpfCnpj}
          currentProposalId={currentProposal.id}
        />
      )}
      {/* // NO_SHOWROOM */}
      {openConfirmDialog && vision !== 'SHOWROOM' && (
        <ConfirmStatusDialog
          isOpen={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          confirmStatus={changeStatusCurrentProposalCallCenter}
          draggableItemId={currentProposal.dealerId}
          departmentId={currentProposal?.department?.id}
          clientUserCpfCnpj={currentProposal?.clientUserCpfCnpj}
          currentProposalId={currentProposal.id}
          isCallCenter
        />
      )}
    </div>
  );
};

export default memo(SubHeader);
