import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-left: 16px;
    font-size: 14px;
    color: #555;
  }
`;
