import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import MobiIAServices from 'services/mobiIAServices';
import proposalIsFinished from 'pages/chats/helpers/proposalIsFinished';
import { useChatContext } from 'pages/chats/ChatsContext';
import useProposal from 'hooks/useProposal';
import useMobiIAProducts from 'hooks/useMobiIAProducts';

const useGetMobiSmartChatSuggestions = () => {
  const dispatch = useDispatch();
  const { hasMobiGpt } = useMobiIAProducts();
  const { currentProposal, interactions }: any = useChatContext();
  const { proposalId, dealerId } = useProposal();

  const customerSentLastMessage = !interactions[interactions.length - 1]
    ?.fromPJ;

  const enabledQuery =
    hasMobiGpt &&
    proposalId &&
    !proposalIsFinished(currentProposal) &&
    customerSentLastMessage;

  return useQuery<any>(
    `get_suggestions_mobi_smart_chat_${proposalId}`,
    () => MobiIAServices.getMobiSmartChatSugestions(proposalId, dealerId),
    {
      select: response => (response?.data ? response?.data : []),
      refetchOnWindowFocus: false,
      initialData: [],
      enabled: enabledQuery,
      onError() {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro Inesperado".'
          )
        );
      },
    }
  );
};

export default useGetMobiSmartChatSuggestions;
