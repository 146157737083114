import isEmpty from 'helpers/isEmpty';
import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import ProposalServices from 'services/proposalServices';
import ProposalCallCenterServices from 'services/callCenterServices';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';

const _defaultInitialState = {
  hasFetched: false,
  hasFailed: false,
  isLoading: false,
  proposals: {},
  proposalCurrent: {},
  channel: 'CHAT',
};

class ProposalStore {
  constructor(initialState) {
    this.rootStore = initialState;
    this.setInitialState(_defaultInitialState);
  }

  setInitialState = initialState => {
    const {
      proposals,
      channel,
      hasFailed,
      hasFetched,
      isLoading,
    } = initialState;
    this.proposals = proposals || {};
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
    this.channel = channel;
  };

  setProposals = (proposals, hasFailed, hasFetched, isLoading) => {
    if (proposals !== undefined) {
      this.proposals = proposals;
    }
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setCurrentProposal = (current, hasFailed, hasFetched, isLoading) => {
    this.proposalCurrent = {
      ...current,
    };
    this.currentHasFailed = hasFailed;
    this.currentHasFetched = hasFetched;
    this.currentIsLoading = isLoading;
  };

  setChatChannel = channelType => {
    this.channel = channelType;
    this.setCurrentProposal(
      { ...this.proposalCurrent, interactions: [] },
      false,
      true,
      false
    );
  };

  emptyProposals = () => {
    this.setProposals([], false, true, false);
  };

  addProposal = async (
    dealerId,
    cpf,
    name,
    email,
    mobile,
    origin,
    vehicles,
    sellerEmail,
    groupId,
    providerId,
    contactId,
    departmentCode,
    callId
  ) => {
    const result = await ProposalServices.createProposal(dealerId, {
      cpf,
      email,
      name,
      mobile,
      dealIds: vehicles,
      origin,
      sellerEmail,
      groupId,
      providerId,
      contactId,
      departmentCode,
      callId,
    });
    const { success, data } = result;

    if (success) {
      if ((data || {}).id) {
        return { success: true, id: (data || {}).id, message: data.message };
      }
      return { success: false, id: null };
    } else {
      return { success: false, id: null };
    }
  };

  getProposals = async (dealerId, options) => {
    this.setProposals(undefined, false, false, true);
    const result = await ProposalServices.getProposalsV2(dealerId, options);
    const { data, success } = result;
    if (success) {
      this.setProposals(data, false, true, false);
    } else {
      this.setProposals(null, true, false, false);
    }
  };

  getInteractionsById = async (dealerId, proposalId) => {
    const result = await ProposalServices.getInteractionsById(
      dealerId,
      proposalId,
      this.channel,
      false
    );
    const { data, success } = result;

    if (success) {
      this.setCurrentProposal(
        { ...this.proposalCurrent, interactions: data },
        false,
        true,
        false
      );
    }
    return { success, data };
  };

  alterStatusProposal = async (
    dealerId,
    proposalId,
    status,
    statusDetailId = ''
  ) => {
    const result = await ProposalServices.alterStatusProposal(
      dealerId,
      proposalId,
      status,
      statusDetailId
    );
    const { data, success } = result;
    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      throw new Error(data);
    }
  };

  setSellers = async (dealerId, proposalId, sellerId) => {
    const result = await ProposalServices.setSellers(
      dealerId,
      proposalId,
      sellerId
    );
    const { data, success } = result;

    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  setDealer = async (dealerId, proposalId, newDealerId) => {
    const result = await ProposalServices.setDealer(
      dealerId,
      proposalId,
      newDealerId
    );
    const { data, success } = result;

    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  setAnnotation = async (dealerId, proposalId, obj) => {
    const result = await ProposalServices.setAnnotation(
      dealerId,
      proposalId,
      obj
    );
    const { data, success } = result;

    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  setScheduled = async (dealerId, proposalId, obj) => {
    const result = await ProposalServices.createProposalSchedule(
      dealerId,
      proposalId,
      obj
    );
    const { data, success } = result;

    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  setVehicles = async (dealerId, proposalId, obj) => {
    try {
      const result = await ProposalServices.setVehicles(
        dealerId,
        proposalId,
        obj
      );
      const { success, data } = result;
      if (success) {
        if ((data || {}).id) {
          return { success: true };
        }
        return { success: false };
      } else {
        return { success: false };
      }
    } catch (e) {
      console.error(e);
      return { success: false };
    }
  };

  setInteraction = async (dealerId, proposalId, obj) => {
    const result = await ProposalServices.setInteraction(
      dealerId,
      proposalId,
      obj
    );
    const { data, success } = result;

    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  setVisualized = async (dealerId, proposalId) => {
    const result = await ProposalServices.setVisualized(dealerId, proposalId);
    const { data, success } = result;

    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  deleteAnnotation = async (dealerId, proposalId, annotationId) => {
    try {
      const result = await ProposalServices.deleteAnnotation(
        dealerId,
        proposalId,
        annotationId
      );
      const { success } = result;

      if (success) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  deleteScheduled = async (dealerId, proposalId, scheduledId) => {
    const result = await ProposalServices.deleteProposalScheduled(
      dealerId,
      proposalId,
      scheduledId
    );
    const { success } = result;

    if (success) {
      return true;
    } else {
      return false;
    }
  };

  deleteVehicle = async (dealerId, proposalId, vehicleId) => {
    const result = await ProposalServices.deleteVehicle(
      dealerId,
      proposalId,
      vehicleId
    );
    const { data, success } = result;

    if (success) {
      if ((data || {}).id) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  getCurrentProposal = async (dealerId, proposalId) => {
    const result = await (checkCallCenterOsSearchAndRescueRoute()
      ? ProposalCallCenterServices
      : ProposalServices
    ).getProposalById(dealerId, proposalId);
    const { data, success } = result;

    if (success && data) {
      data.interactions = this.proposalCurrent?.interactions || [];
      this.setCurrentProposal(data, false, true, false);
      this.addProposalIntoList(data);
      return { success, data };
    } else {
      this.setCurrentProposal(null, true, false, false);
      return { success, data };
    }
  };

  addProposalIntoList = data => {
    if (isEmpty(this.proposals)) {
      return;
    }
    const proposals = JSON.parse(JSON.stringify(this.proposals));
    const index = proposals.findIndex(item => item.id === data.id);
    //find the element into content
    proposals.splice(index, 1);
    //remove the element into content
    proposals.splice(index, 0, data);
    //add new element into content

    this.setProposals(
      JSON.parse(JSON.stringify(proposals)),
      false,
      true,
      false
    );
    //set content
  };

  addInteraction = async (
    dealerId,
    proposalId,
    mediaType,
    mediaData,
    dateISO,
    isBase64 = false
  ) => {
    try {
      const result = await ProposalServices.addInteraction(
        dealerId,
        proposalId,
        mediaType,
        mediaData,
        dateISO,
        isBase64
      );
      const { data, success } = result;
      if (success) {
        return { success, data };
      } else {
        return { success, data };
      }
    } catch (e) {
      console.log('>>>>>', e);
    }
  };
}

export default remotedev(
  decorate(ProposalStore, {
    hasFetched: observable,
    hasFailed: observable,
    isLoading: observable,
    proposals: observable,
    setInitialState: action,
    getProposals: action,
    setProposals: action,
    proposalCurrent: observable,
    getCurrentProposal: action,
    setCurrentProposal: action,
    setSellers: action,
    setAnnotation: action,
    setVehicless: action,
    setVisualized: action,
    addProposal: action,
    emptyProposals: action,
    currentHasFetched: observable,
    currentHasFailed: observable,
    currentIsLoading: observable,
    getInteractionsById: action,
    addInteraction: action,
    addProposalIntoList: action,
    channel: observable,
    setChatChannel: action,
  })
);
