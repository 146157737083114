import { action, observable, decorate } from 'mobx';
import React from 'react';
import remotedev from 'mobx-remotedev';
import PriceDialog from 'components/PriceDialog';

const _defaultInitialState = {
  UIComponent: 'snackbar',
  isFetching: false,
  notificationVisible: false,
  notificationType: 'success',
  notificationMessage:
    'Hey! isto é uma mensagem default para snackbar mobiauto',
  notificationDuration: 2000,
  modalVisible: false,
  modalContent: null,
  modalOptions: { closeOnClick: true, closeOnEsc: true, onClose: null },
};

class UIStore {
  constructor(initialState) {
    this.setInitialState({
      ..._defaultInitialState,
      ...initialState,
    });
  }

  setInitialState = initialState => {
    Object.keys(initialState).forEach(key => {
      const isKeyValid = key in _defaultInitialState;
      if (!isKeyValid) return;
      const value = initialState[key];
      this[key] = value;
    });
  };

  setIsFetching = isFetching => {
    this.isFetching = isFetching;
  };

  showModal = (content, options) => {
    this.UIComponent = 'modal';
    this.modalContent = content;
    this.modalVisible = true;
    this.modalOptions = {
      ..._defaultInitialState.modalOptions,
      ...options,
    };
  };

  hideModal = () => {
    this.modalContent = null;
    this.modalVisible = false;
  };

  showConfirmationModal = deal => {
    return new Promise(resolve => {
      const onAccept = () => {
        this.hideModal();
        resolve(true);
      };
      const onCancel = () => {
        this.hideModal();
        resolve(false);
      };
      const content = (
        <PriceDialog
          title="Alterar valor do anúncio"
          content={null}
          price={deal.price}
          onAccept={onAccept}
          onCancel={onCancel}
          onChange={() => {}}
        />
      );
      this.showModal(content);
    });
  };

  hideNotificationMessage = () => {
    this.notificationVisible = false;
  };
  showNotificationMessage = (
    notificationMessage = 'Hey! isto é uma mensagem default para snackbar mobiauto',
    notificationType = 'success',
    notificationDuration = 2000
  ) => {
    this.UIComponent = 'snackbar';
    this.notificationVisible = true;
    this.notificationMessage = notificationMessage;
    this.notificationType = notificationType;
    this.notificationDuration = notificationDuration;
  };
}

export default remotedev(
  decorate(UIStore, {
    isFetching: observable,
    modalVisible: observable,
    modalContent: observable.ref,
    modalOptions: observable,
    UIComponent: observable,
    notificationDuration: observable,
    notificationMessage: observable,
    notificationType: observable,
    notificationVisible: observable,
    setInitialState: action,
    setIsFetching: action,
    showModal: action,
    hideModal: action,
    hideNotificationMessage: action,
    showNotificationMessage: action,
  })
);
