import isEmpty from 'helpers/isEmpty'
import { Select } from 'types/SelectType'

export default function formatModelYearsToSelect(
  productionYears: Select[],
  productionYearSelected: number
): Select[] {
  if (isEmpty(productionYears)) return []

  const productionYear = productionYears.find(
    productionYear => productionYear.value === productionYearSelected
  )
  if (isEmpty(productionYear)) return []

  const firstPosition = productionYears[1]
  const lastPosition = productionYears[productionYears.length - 1]
  if (productionYearSelected === firstPosition.value) {
    return [
      {
        value: productionYearSelected,
        label: productionYearSelected.toString(),
      },
    ]
  }
  if (productionYearSelected === lastPosition.value) {
    return [
      {
        value: Number(productionYearSelected) + 1,
        label: (productionYearSelected + 1).toString(),
      },
    ]
  }
  return [
    {
      value: Number(productionYearSelected) + 1,
      label: (productionYearSelected + 1).toString(),
    },
    {
      value: productionYearSelected,
      label: productionYearSelected.toString(),
    },
  ]
}
