import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { Select } from 'types/SelectType';

export default {
  getVehicleTypes: function(): Action {
    return {
      type: ActionTypes.GET_VEHICLE_TYPES,
    };
  },
  cleanDeal: function(): Action {
    return {
      type: ActionTypes.CLEAN_DEAL,
    };
  },
  getVehicleTypesSuccess: function(vehicleTypes: any): Action {
    return {
      type: ActionTypes.GET_VEHICLE_TYPES_SUCCEDED,
      payload: vehicleTypes,
    };
  },
  getVehicleTypesError: function(error: any): Action {
    return {
      type: ActionTypes.GET_VEHICLE_TYPES_ERROR,
      payload: error,
    };
  },

  getJustVehicleTypes: function(): Action {
    return {
      type: ActionTypes.GET_JUST_VEHICLE_TYPES,
    };
  },
  getJustVehicleTypesSuccess: function(vehicleTypes: any): Action {
    return {
      type: ActionTypes.GET_JUST_VEHICLE_TYPES_SUCCEDED,
      payload: vehicleTypes,
    };
  },
  getJustVehicleTypesError: function(error: any): Action {
    return {
      type: ActionTypes.GET_JUST_VEHICLE_TYPES_ERROR,
      payload: error,
    };
  },
  getMakes: function(): Action {
    return {
      type: ActionTypes.GET_MAKES,
    };
  },
  getModels: function(makeId: string): Action {
    return {
      type: ActionTypes.GET_MODELS,
      payload: { makeId },
    };
  },
  getProductionYears: function(modelId: number): Action {
    return {
      type: ActionTypes.GET_PRODUCTION_YEARS,
      payload: { modelId },
    };
  },
  getModelYears: function(productionYear: number): Action {
    return {
      type: ActionTypes.GET_MODEL_YEARS,
      payload: productionYear,
    };
  },
  getProdutionYearsAndModelYears: function(
    modelId: number,
    productionYear: number
  ): Action {
    return {
      type: ActionTypes.GET_PRODUCTION_YEARS_AND_MODEL_YEARS,
      payload: { modelId, productionYear },
    };
  },
  getTrims: function(modelYear: number): Action {
    return {
      type: ActionTypes.GET_TRIMS,
      payload: { modelYear },
    };
  },
  getColors: function(): Action {
    return {
      type: ActionTypes.GET_COLORS,
    };
  },
  getFuels: function(): Action {
    return {
      type: ActionTypes.GET_FUELS,
    };
  },
  getTransmissions: function(): Action {
    return {
      type: ActionTypes.GET_TRANSMISSIONS,
    };
  },

  getDoors: function(): Action {
    return {
      type: ActionTypes.GET_DOORS,
    };
  },

  getDealerLocality: function(dealerId: number): Action {
    return {
      type: ActionTypes.GET_DEALER_LOCALITIES,
      payload: { dealerId },
    };
  },

  setDealerLocality: function(dealerLocality: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_DEALER_LOCALITIES_SUCCEDED,
      payload: dealerLocality,
    };
  },

  setMakes: function(makes: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_MAKES_SUCCEDED,
      payload: makes,
    };
  },

  setModels: function(models: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_MODELS_SUCCEDED,
      payload: models,
    };
  },

  setProductionYears: function(productionYears: number[]): Action {
    return {
      type: ActionTypes.GET_PRODUCTION_YEARS_SUCCEDED,
      payload: productionYears,
    };
  },

  setModelYears: function(modelYears: number): Action {
    return {
      type: ActionTypes.GET_MODEL_YEARS,
      payload: modelYears,
    };
  },

  setTrims: function(trims: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_TRIMS_SUCCEDED,
      payload: trims,
    };
  },

  setDoors: function(doors: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_DOORS_SUCCEDED,
      payload: doors,
    };
  },

  setColors: function(colors: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_COLORS_SUCCEDED,
      payload: colors,
    };
  },

  setTransmissions: function(transmissions: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_TRANSMISSIONS_SUCCEDED,
      payload: transmissions,
    };
  },

  setFuels: function(fuels: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_FUELS_SUCCEDED,
      payload: fuels,
    };
  },

  getArmorTypes: function(): Action {
    return {
      type: ActionTypes.GET_ARMOR_TYPE,
    };
  },
  getFeatures: function(): Action {
    return {
      type: ActionTypes.GET_FEATURES,
    };
  },
  setFeatures: function(features: Select[]): Action {
    return {
      type: ActionTypes.GET_FEATURES_SUCCEDED,
      payload: features,
    };
  },

  getConditionals: function(isResale: boolean): Action {
    return {
      type: ActionTypes.GET_CONDITIONALS,
      payload: isResale,
    };
  },
  setConditionals: function(conditionals: Select[]): Action {
    return {
      type: ActionTypes.GET_CONDITIONALS_SUCCEDED,
      payload: conditionals,
    };
  },
  getFipePrice: function(
    trimId: number,
    modelYear: number,
    deal0km: boolean
  ): Action {
    return {
      type: ActionTypes.GET_FIPE_PRICE,
      payload: { trimId, modelYear, deal0km },
    };
  },
  setFipePrice: function(fipe: any): Action {
    return {
      type: ActionTypes.SET_FIPE_PRICE,
      payload: fipe,
    };
  },
  getReportStatus: function(): Action {
    return {
      type: ActionTypes.GET_REPORT_STATUS,
    };
  },
  getReportStatusSuccess: function(options: any): Action {
    return {
      type: ActionTypes.GET_REPORT_STATUS_SUCCEDED,
      payload: options,
    };
  },
  getReportStatusError: function(error: any): Action {
    return {
      type: ActionTypes.GET_REPORT_STATUS_ERROR,
      payload: error,
    };
  },

  getJustMakes: function(vehicleType?: string): Action {
    return {
      type: ActionTypes.GET_JUST_MAKES,
      payload: { vehicleType },
    };
  },
  getJustModels: function(vehicleType: string, makeId: string): Action {
    return {
      type: ActionTypes.GET_JUST_MODELS,
      payload: { makeId, vehicleType },
    };
  },
  getJustProductionYears: function(
    vehicleType: string,
    modelId: number
  ): Action {
    return {
      type: ActionTypes.GET_JUST_PRODUCTION_YEARS,
      payload: { modelId, vehicleType },
    };
  },
  getJustModelYears: function(
    vehicleType: string,
    productionYear: number
  ): Action {
    return {
      type: ActionTypes.GET_JUST_MODEL_YEARS,
      payload: { productionYear, vehicleType },
    };
  },
  getJustProdutionYearsAndModelYears: function(
    vehicleType: string,
    modelId: number,
    productionYear: number
  ): Action {
    return {
      type: ActionTypes.GET_JUST_PRODUCTION_YEARS_AND_MODEL_YEARS,
      payload: { vehicleType, modelId, productionYear },
    };
  },
  getJustTrims: function(
    vehicleType: string,
    modelId: number,
    modelYear: number
  ): Action {
    return {
      type: ActionTypes.GET_JUST_TRIMS,
      payload: { vehicleType, modelId, modelYear },
    };
  },

  setJustMakes: function(makes: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_JUST_MAKES_SUCCEDED,
      payload: makes,
    };
  },

  setJustModels: function(models: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_JUST_MODELS_SUCCEDED,
      payload: models,
    };
  },

  setJustProductionYears: function(productionYears: number[]): Action {
    return {
      type: ActionTypes.GET_JUST_PRODUCTION_YEARS_SUCCEDED,
      payload: productionYears,
    };
  },

  setJustModelYears: function(modelYears: number): Action {
    return {
      type: ActionTypes.GET_JUST_MODEL_YEARS_SUCCEDED,
      payload: modelYears,
    };
  },

  setJustTrims: function(trims: Record<string, any>[]): Action {
    return {
      type: ActionTypes.GET_JUST_TRIMS_SUCCEDED,
      payload: trims,
    };
  },
};
