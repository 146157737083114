import React, { useEffect } from 'react';
import { ReactComponent as ChatIcon } from 'assets/chat-icon.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/whatsapp.svg';
import { useChatContext } from 'pages/chats/ChatsContext';
import { ChatTabsContainer, ChatTab } from './styles';

const ChannelTabs = ({ hasWhatsAppProduct }) => {
  const {
    otherStates: { currentChannel },
    handleChangeChannel,
  }: any = useChatContext();

  const emailOrWhatsAppTabEnabled = () => {
    if (hasWhatsAppProduct) {
      handleChangeChannel('WHATSAPP');
    } else {
      handleChangeChannel('CHAT');
    }
  };

  useEffect(() => {
    emailOrWhatsAppTabEnabled();
  }, [hasWhatsAppProduct]);

  return (
    <ChatTabsContainer>
      {hasWhatsAppProduct ? (
        <>
          <ChatTab
            isActive={currentChannel === 'WHATSAPP'}
            onClick={() =>
              currentChannel === 'CHAT' && handleChangeChannel('WHATSAPP')
            }
          >
            <WhatsAppIcon />
            WhatsApp
          </ChatTab>

          <ChatTab
            isActive={currentChannel === 'CHAT'}
            onClick={() =>
              currentChannel === 'WHATSAPP' && handleChangeChannel('CHAT')
            }
            style={{ borderRight: '1px solid #e0e0e0' }}
          >
            <ChatIcon />
            Chat
          </ChatTab>
        </>
      ) : (
        <>
          <ChatTab
            isActive={currentChannel === 'CHAT'}
            onClick={() =>
              currentChannel === 'WHATSAPP' && handleChangeChannel('CHAT')
            }
            style={{ borderRight: '1px solid #e0e0e0' }}
          >
            <ChatIcon />
            Chat
          </ChatTab>

          <ChatTab
            isActive={currentChannel === 'WHATSAPP'}
            onClick={() =>
              currentChannel === 'CHAT' && handleChangeChannel('WHATSAPP')
            }
          >
            <WhatsAppIcon />
            WhatsApp
          </ChatTab>
        </>
      )}
    </ChatTabsContainer>
  );
};

export default ChannelTabs;
