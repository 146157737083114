import EnvVars from 'helpers/envVars';
import getTransformationInfo from 'components/getTransformInfo';

const domainImages = EnvVars('API_IMAGES_BASE_URL');

interface ImageData {
  imageId?: number;
  imageVcode?: string;
}

export default (
  imageData: ImageData,
  quality?: number | string,
  width?: number | string,
  height?: number | string,
  crop?: string,
  gravity?: string,
  radius?: number,
  backgroundColor?: string,
  png?: boolean
): string => {
  if (!imageData?.imageId) return require('assets/default-image.svg');
  return `${domainImages}/images/v1.0/${
    imageData.imageId
  }/transform/${getTransformationInfo(
    quality,
    width,
    height,
    crop,
    gravity,
    radius,
    backgroundColor,
    png
  )}${imageData?.imageVcode ? `?vcode=${imageData?.imageVcode}` : ''}`;
};
