import React from 'react';
import { useSelector } from 'react-redux';

import { Dialog, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import * as S from './styles';

interface Props {
  dialogTitle: string;
  iframeParams: string;
  isOpen: boolean;
  onClose: () => void;
}

export function QuoteDialog({
  dialogTitle,
  iframeParams,
  isOpen,
  onClose,
}: Props) {
  const { currentDealer } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
  }));

  const quiverPW = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'QUIVER'
  )?.externalIdentification;

  const iframeUrl = `https://www.corretor-online.com.br/canalcliente/index.htm?Pw=${quiverPW}&${iframeParams}`;

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <S.Header>
        <S.Title>{dialogTitle}</S.Title>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Container>
        <iframe src={iframeUrl} />
      </S.Container>
    </Dialog>
  );
}
