import React from 'react';
import Grid from '@material-ui/core/Grid';
import AutoComplete from 'components/atoms/AutoComplete';
import PlateSearch from './components/PlateSearch';

const VehicleOfInterest = ({
  makesOptions,
  modelsOptions,
  initialYearsOptions,
  finalYearsOptions,
  trimOptions,
  handleCurrentVehicleOfInterestValues,
  currentVehicleOfInterest,
}) => {
  return (
    <div>
      <section>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PlateSearch
              setVehicleByPlate={vehicle => {
                handleCurrentVehicleOfInterestValues(
                  'plate',
                  vehicle.plate || null
                );
                handleCurrentVehicleOfInterestValues(
                  'make',
                  vehicle.make?.id || null,
                  vehicle.make?.name || null
                );
                setTimeout(() => {
                  handleCurrentVehicleOfInterestValues(
                    'model',
                    vehicle.model?.id || null,
                    vehicle.model?.name
                  );
                }, 500);
                setTimeout(() => {
                  handleCurrentVehicleOfInterestValues(
                    'modelYear',
                    vehicle.modelYear?.id || null,
                    vehicle.modelYear?.name || null
                  );
                }, 1000);
                setTimeout(() => {
                  handleCurrentVehicleOfInterestValues(
                    'productionYear',
                    vehicle.productionYear?.id || null,
                    vehicle.productionYear?.name || null
                  );
                }, 1000);
                setTimeout(() => {
                  handleCurrentVehicleOfInterestValues(
                    'trim',
                    vehicle.trim?.id || null,
                    vehicle.trim?.name || null
                  );
                }, 1500);
              }}
            />
          </Grid>
          {/* //ROW */}
          <Grid item xs={3}>
            <AutoComplete
              name="make"
              label="Marca"
              value={
                currentVehicleOfInterest?.make?.id
                  ? {
                      id: currentVehicleOfInterest?.make?.id,
                      label: currentVehicleOfInterest?.make?.name,
                    }
                  : undefined
              }
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'make',
                  event?.value || null,
                  makesOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={makesOptions}
              disabled={!makesOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="model"
              label="Modelo"
              value={
                currentVehicleOfInterest?.model?.id
                  ? {
                      id: currentVehicleOfInterest?.model?.id,
                      label: currentVehicleOfInterest?.model?.name,
                    }
                  : undefined
              }
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'model',
                  event?.value || null,
                  modelsOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={modelsOptions}
              disabled={!modelsOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="modelYear"
              label="Ano modelo"
              value={
                currentVehicleOfInterest?.modelYear
                  ? {
                      id: currentVehicleOfInterest?.modelYear,
                      label: currentVehicleOfInterest?.modelYear,
                    }
                  : undefined
              }
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'modelYear',
                  event?.value || null
                )
              }
              options={initialYearsOptions}
              disabled={!initialYearsOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="productionYear"
              label="Ano produção"
              value={
                currentVehicleOfInterest?.productionYear
                  ? {
                      id: currentVehicleOfInterest?.productionYear,
                      label: currentVehicleOfInterest?.productionYear,
                    }
                  : undefined
              }
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'productionYear',
                  event?.value || null
                )
              }
              options={finalYearsOptions}
              disabled={!finalYearsOptions.length}
            />
          </Grid>
          {/* //ROW */}
          <Grid item xs={3}>
            <AutoComplete
              name="trim"
              label="Versão"
              value={
                currentVehicleOfInterest?.trim?.id
                  ? {
                      id: currentVehicleOfInterest?.trim?.id,
                      label: currentVehicleOfInterest?.trim?.name,
                    }
                  : undefined
              }
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'trim',
                  event?.value || null,
                  trimOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={trimOptions}
              disabled={!trimOptions.length}
            />
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default VehicleOfInterest;
