//import request from './index';
import { createTransactionalDTO } from 'modules/manageDeals/types';
import { requestModule as request } from './index';
import EnvVars from 'helpers/envVars';
import otherRequest from 'helpers/request';

import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandlerTS';

const searchImagesUrl = EnvVars('API_IMAGES_BASE_URL');
const searchUrl = `${EnvVars('API_SEARCH_BASE_URL')}/vehicle/v1.0`;
const serviceName = 'DealServices';

export default class DealServices {
  static list = async (query?: string) => {
    const response = await request.get('v1.0', { query });
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.list()`
    );
    return responseHandler(response);
  };

  static show = async (dealId: number | string) => {
    const response = await request.get(`v1.0/${dealId}`);
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.show()`
    );
    return responseHandler(response);
  };

  static put = async (
    dealId: number | string,
    status: 'ACTIVE' | 'INACTIVE' | 'SOLD'
  ) => {
    const response = await request.put(`v1.0/${dealId}`, { status });
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.put()`
    );
    return responseHandler(response);
  };

  static getDealImgUrl = (mediaId: number | string) => {
    return mediaId ? `${searchImagesUrl}/images/v1.0/${mediaId}` : null;
  };

  static getFeatures = async (featureId?: number | string) => {
    //ver isso aqui - filtrar aqui ou na tela?

    const response = await otherRequest.get(`${searchUrl}/CAR/features`);

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.getFeatures()`
    );
    return responseHandler(response);

    // const responseHandled = await {
    //     success: true,
    //     data: features
    // };

    // return responseHandled;
  };

  static getColors = async (colorId?: number | string) => {
    //ver isso aqui - filtrar aqui ou na tela?

    const response = await otherRequest.get(`${searchUrl}/colors`);

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.getColors()`
    );
    return responseHandler(response);

    // const responseHandled = await {
    //     success: true,
    //     data: colors
    // };

    // return responseHandled;
  };
  static getFuels = async (fuelId?: number | string) => {
    //ver isso aqui - filtrar aqui ou na tela?

    const response = await otherRequest.get(
      'https://test-api.mobiauto.com.br/search/api/vehicle/v1.0/fuels'
    );

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.getFuels()`
    );
    return responseHandler(response);

    // const responseHandled = await {
    //     success: true,
    //     data: fuels
    // };

    // return responseHandled;
  };

  static putTransactionalFromDeal = async (
    id: number | string,
    payload: createTransactionalDTO
  ) => {
    const response = await request.patch(`purchaseInfo/v1.0/${id}`, payload);

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.put()`
    );

    return responseHandler(response);
  };

  static postTransactionalFromDeal = async (
    payload: createTransactionalDTO
  ) => {
    const response = await request.post(`purchaseInfo/v1.0`, payload);

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.updateById()`
    );

    return responseHandler(response);
  };

  static deleteTransactionalFromDeal = async (
    transactionalId: number | string
  ) => {
    const response = await request.delete(
      `purchaseInfo/v1.0/${transactionalId}`
    );

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.delete()`
    );

    return responseHandler(response);
  };
}

// https://test-api.mobiauto.com.br/search/api/vehicle/v1.0/fuels
// https://test-api.mobiauto.com.br/search/api/vehicle/v1.0/colors
