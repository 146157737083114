import React, { memo } from 'react';
import MessagePF from './MessagePF';
import MessagePJ from './MessagePJ';

const MessageComponent = props => {
  const {
    message,
    sellerName,
    type,
    currentChannel,
    clientName,
    provider,
    currentAudioPlaying,
    setCurrentAudioPlaying,
    audiosRef,
    messageFocus,
    focusOnTheMessage,
  } = props;

  if (!message.fromPJ) {
    return (
      <MessagePF
        currentRef={props.currentRef}
        message={message}
        type={type}
        clientName={clientName}
        provider={provider}
        currentAudioPlaying={currentAudioPlaying}
        setCurrentAudioPlaying={setCurrentAudioPlaying}
        audiosRef={audiosRef}
        focusOnTheMessage={focusOnTheMessage}
        sellerName={sellerName}
      />
    );
  }
  return (
    <MessagePJ
      currentRef={props.currentRef}
      message={message}
      sellerName={sellerName}
      type={type}
      currentChannel={currentChannel}
      currentAudioPlaying={currentAudioPlaying}
      setCurrentAudioPlaying={setCurrentAudioPlaying}
      audiosRef={audiosRef}
      messageFocusIndex={messageFocus}
      focusOnTheMessage={focusOnTheMessage}
    />
  );
};

export default memo(MessageComponent);
