import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

export const ExpandMore = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.primary || Colors.primary.medium};
`;

export const WrapperButton = styled.div`
  display: flex;
  background: ${({ theme }) => theme.primary || Colors.primary.medium};
  border-radius: 4px;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-right: 16px;
`;

export const Text = styled.div`
  font-family: 'Poppins Regular';
  padding: 8px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
`;

export const Item = styled.div`
  padding: 8px 12px;
  color: black;
  font-size: 14px;
  line-height: 24px;
  &:hover {
    background: ${Colors.gray.nearWhite};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  background: #00000040;
  height: 100%;
`;

export const ButtonStyled = styled(Button)`
  font-family: 'Poppins Regular' !important;
  width: 200px !important;
  flex: 1 !important;
  text-transform: upperCase !important;
  background-color: ${({ theme }) => `${theme.primary} !important`};
`;
