import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const QuotesContainer = styled('div')`
  background-color: #fff;
  border-radius: 4px;
`;

export const Buttons = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
`;
