import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import ThemeButton from 'components/atoms/ThemeButton';
import TabPanel from 'components/StyledComponents/TabPanel';
import StyledTab from 'components/StyledComponents/StyledTab';
import StyledTabs from 'components/StyledComponents/StyledTabs';
import { useChatContext } from 'pages/chats/ChatsContext';
import SimulationHistory from './components/SimulationHistory';
import CreateSimulationCard from './components/CreateSimulationCard';
import DialogSelectVehicleToSimulate from './components/Dialogs/DialogSelectVehicleToSimulate';
import ColdSimulation from './components/FormSimulation';
import GenericDialog from 'components/GenericDialog';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ItemHeader from '../atoms/ItemHeader';
import useToObtainGuid from './hooks/useToObtainGuid';
import LoadingInnerPages from 'components/LoadingInnerPages';
import {
  FinancingSimulationProvider,
  useFinancingSimulationContext,
} from './context';
import { Container, ContainerIframeModal, Wrapper } from './styles';
import NumberFormat from 'react-number-format';
import TextField from 'modules/financing/components/atoms/TextField';

const FinancingSimulation = () => {
  const dispatch = useDispatch();
  const {
    dealerStore: { currentDealer },
  } = useSelector(state => ({
    dealerStore: state.dealerStore,
  }));

  const {
    createSimulationState,
    createSimulateFormValues,
    handleChangeSimulateForm,
    handleChangeNewSimulationValues,
    financingBancoPanEnabled,
    currentTab,
    handleChangeTab,
  }: any = useFinancingSimulationContext();

  const {
    currentProposal,
    getCurrentProposal,
    getInterestProducts,
    loading,
  }: any = useChatContext();

  const [guild, setGuild] = useState(null);

  const [openModalFandi, setOpenModalFandi] = useState(false);

  const {
    fetchGuidMutation,
    downpaymentValue,
    handleValueChange,
  } = useToObtainGuid();

  const fandiIntegration = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'FANDI'
  );

  async function handleFetchGuid() {
    if (!currentProposal?.vehicle[0]) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'É necessário ter um veículo de interesse para realizar simulação rápida.',
          true
        )
      );
      return;
    }
    const { data } = await fetchGuidMutation.mutateAsync();

    if (data) {
      setGuild(data);
    }
  }

  const onCloseModalFandi = () => {
    setOpenModalFandi(false);
  };

  useEffect(() => {
    if (guild?.retorno) {
      setOpenModalFandi(true);
    }
  }, [guild]);

  useEffect(() => {
    getCurrentProposal();
    getInterestProducts();
  }, []);

  if (loading) {
    return <LoadingInnerPages />;
  }

  return (
    <>
      <ItemHeader>Financiamento</ItemHeader>
      {fandiIntegration ? (
        <div>
          <StyledTabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            <StyledTab value={0} label="Simulação" />
            <StyledTab value={1} label="Minhas Simulações" />
          </StyledTabs>
          <TabPanel value={currentTab} index={0} key={'estoque'}>
            {currentTab === 0 && (
              <Wrapper>
                <NumberFormat
                  label="Valor de entrada"
                  value={downpaymentValue}
                  onValueChange={handleValueChange}
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  allowLeadingZeros={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="R$ "
                  customInput={TextField}
                  mini
                />
                <ThemeButton onClick={() => handleFetchGuid()} fullWidth>
                  {fetchGuidMutation.isLoading ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    'Simulação Rápida'
                  )}
                </ThemeButton>
              </Wrapper>
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1} key={'estoque'}>
            {currentTab === 1 && (
              <Container>
                {/* //SIMULATION_HISTORY */}
                <SimulationHistory />
              </Container>
            )}
          </TabPanel>
        </div>
      ) : financingBancoPanEnabled ? (
        <div>
          <StyledTabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            <StyledTab value={0} label="Simulação" />
            <StyledTab value={1} label="Minhas Simulações" />
          </StyledTabs>
          <TabPanel value={currentTab} index={0} key={'estoque'}>
            {currentTab === 0 && (
              <Container>
                {/* //CREATE_SIMULATION */}
                <CreateSimulationCard
                  handleClickCreateSimulation={() => {
                    handleChangeNewSimulationValues({
                      target: {
                        name: 'selectVehicleDialogOpen',
                        value: true,
                      },
                    });
                  }}
                />
              </Container>
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1} key={'estoque'}>
            {currentTab === 1 && (
              <Container>
                <>
                  {/* //SIMULATION_HISTORY */}
                  {!createSimulateFormValues?.vehicle?.id && (
                    <SimulationHistory />
                  )}
                </>
              </Container>
            )}
          </TabPanel>
        </div>
      ) : (
        <ColdSimulation />
      )}
      {/* //SELECT_VEHICLE_TO_CREATE_SIMULATE */}
      {createSimulationState.selectVehicleDialogOpen && (
        <DialogSelectVehicleToSimulate
          open={createSimulationState.selectVehicleDialogOpen}
          handleClickSelectVehicleToSimulate={({ vehicle }) => {
            handleChangeNewSimulationValues({
              target: { name: 'simulateResponse', value: null },
            });
            handleChangeSimulateForm({
              target: {
                name: 'vehicle',
                value: vehicle,
              },
            });
            handleChangeSimulateForm({
              target: {
                name: 'vehicle.price',
                value: vehicle?.price || 0,
              },
            });
          }}
          handleClose={() =>
            handleChangeNewSimulationValues({
              target: {
                name: 'selectVehicleDialogOpen',
                value: false,
              },
            })
          }
        />
      )}

      {openModalFandi && (
        <GenericDialog
          open={openModalFandi}
          onClose={onCloseModalFandi}
          title={'Simulação Rápida'}
          maxWidth="md"
          OnCloseButton
        >
          <ContainerIframeModal>
            <iframe
              src={`https://checkout.fandi.com.br/?token=${guild?.retorno}`}
              width={'100%'}
              style={{ height: 'calc(100vh - 130px)' }}
            />
          </ContainerIframeModal>
        </GenericDialog>
      )}
    </>
  );
};

const FinancingSimulationMain = () => (
  <FinancingSimulationProvider>
    <FinancingSimulation />
  </FinancingSimulationProvider>
);

export default React.memo(FinancingSimulationMain);
