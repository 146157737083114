import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

const styles = {
  root: {
    backgroundColor: '#F5F5F5',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
  },
};

type propsTypes = BoxProps & {
  style?: React.CSSProperties;
};

const BoxConsentDefault: React.FC<propsTypes> = ({
  children,
  style,
  ...rest
}) => {
  return (
    <Box style={{ ...styles.root, ...style }} {...rest}>
      {children}
    </Box>
  );
};

export default BoxConsentDefault;
