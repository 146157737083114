import React from 'react';
import Select from 'modules/financing/components/atoms/Select';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutoComplete from 'components/atoms/AutoComplete';
import {
  CEPMaskCustom,
  CNPJMaskCustom,
  PhoneMaskCustom,
} from 'components/MaskTextInput';
import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import getDateByDatePickerComponent from 'modules/financing/helpers/getDateByDatePickerComponent';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput';

const CompanyForm = props => {
  const {
    statesOptions,
    streetTypeOptions,
    addressTypeOptions,
    citiesOptionsState,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton,
    disableCpfCnpj,
    allRequired,
  } = props;

  return (
    <>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="companyName"
            name="companyName"
            label="Nome da empresa"
            value={formData['companyName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companySocialName"
            name="companySocialName"
            label="Nome fantasia"
            value={formData['companySocialName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companySocialName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="cnpj"
            name="cnpj"
            label="CNPJ"
            value={formData['cnpj']}
            disabled={disableAllInputs || disableCpfCnpj}
            onChange={onChange}
            InputProps={{
              inputComponent: CNPJMaskCustom,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('cnpj')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required={allRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyStartDate"
            type="date"
            name="companyStartDate"
            label="Data de inicio da empresa"
            value={getDateByDatePickerComponent(formData['companyStartDate'])}
            onChange={onChange}
            disabled={disableAllInputs}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="companyEmail"
            name="companyEmail"
            label="Email"
            value={formData['companyEmail']}
            type="email"
            onChange={onChange}
            disabled={disableAllInputs}
            required
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyEmail')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyInscricaoEstadual"
            name="companyInscricaoEstadual"
            label="Inscrição estadual"
            value={formData['companyInscricaoEstadual']}
            onChange={onChange}
            disabled={disableAllInputs}
            required
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() =>
                    contentCopyByNameInput('companyInscricaoEstadual')
                  }
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyPhone"
            name="companyPhone"
            label="Telefone"
            value={formData['companyPhone']}
            onChange={onChange}
            disabled={disableAllInputs}
            required
            InputProps={{
              inputComponent: PhoneMaskCustom,
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyPhone')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyPhone2"
            name="companyPhone2"
            label="Telefone 2"
            value={formData['companyPhone2']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: PhoneMaskCustom,
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyPhone2')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>

        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="accountantName"
            name="accountantName"
            label="Nome do contador"
            value={formData['accountantName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('accountantName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="accountantPhone"
            name="accountantPhone"
            label="Telefone do contador"
            value={formData['accountantPhone']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: PhoneMaskCustom,
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('accountantPhone')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
      </Grid>
      <div style={{ margin: '24px 0px 8px' }}>Endereço da empresa</div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={4}>
          <Select
            id="companyAddressType"
            name="companyAddressType"
            label="Tipo do endereço"
            value={formData['companyAddressType']}
            onChange={onChange}
            options={addressTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="companyZipcode"
            name="companyZipcode"
            label="CEP"
            value={formData['companyZipcode']}
            onKeyPress={handleKeyPress}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: CEPMaskCustom,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton onClick={getAddresInfosByZipCode} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="companyStreetType"
            name="companyStreetType"
            label="Tipo do logradouro"
            value={formData['companyStreetType']}
            onChange={onChange}
            options={streetTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="companyAddress"
            name="companyAddress"
            label="Endereço"
            value={formData['companyAddress']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyAddress')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyNumber"
            name="companyNumber"
            label="Número"
            value={formData['companyNumber']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyNumber')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={12}>
          <TextInput
            id="companyComplement"
            name="companyComplement"
            label="Complemento (opcional)"
            value={formData['companyComplement']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyComplement')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="companyNeighborhood"
            name="companyNeighborhood"
            label="Bairro"
            value={formData['companyNeighborhood']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyNeighborhood')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* // */}
        <Grid item xs={4}>
          <AutoComplete
            name="companyCityStateId"
            label="Estado"
            options={statesOptions}
            value={
              formData?.['companyCityStateId']
                ? {
                    id: formData['companyCityStateId'],
                    value: formData['companyCityStateId'],
                    label: statesOptions.find(
                      e => e.value === formData['companyCityStateId']
                    )?.label,
                    name: statesOptions.find(
                      e => e.value === formData['companyCityStateId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({
                target: { value: e?.value, name: 'companyCityStateId' },
              })
            }
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="companyCityId"
            label="Cidade"
            options={citiesOptionsState}
            value={
              formData?.['companyCityId']
                ? {
                    id: formData['companyCityId'],
                    value: formData['companyCityId'],
                    label: citiesOptionsState.find(
                      e => e.value === formData['companyCityId']
                    )?.label,
                    name: citiesOptionsState.find(
                      e => e.value === formData['companyCityId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({ target: { value: e?.value, name: 'companyCityId' } })
            }
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyForm;
