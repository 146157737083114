import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid).attrs({ container: true })`
  border-radius: 8px;

  max-width: calc(100% - 40px);
  max-height: 80vh;
  height: 100%;

  overflow: hidden;

  margin: 24px auto;
  background-color: #fff;
  &[data-disable-conversation='true'] {
    filter: blur(0.3em);
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChatWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const Alert = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  text-align: center;
  margin: 16px 16px 0px;
`;
