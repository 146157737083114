import React from 'react';
import styled from 'styled-components';
import animacaoSmileFinal from 'assets/animacao-loading-vFINAL.gif';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
const Title = styled.div`
  font-family: Poppins Regular;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  margin-top: 16px;
  letter-spacing: normal;
  text-align: center;
  color: ${Colors.gray.dark};
  font-family: Poppins Bold;
`;
const RenderLoadingAuthentication = () => {
  return (
    <AlignCenter>
      <img
        src={animacaoSmileFinal}
        alt="loading..."
        width="150px"
        height="150px"
      />
      <Title>MobiGestor</Title>
    </AlignCenter>
  );
};
export default RenderLoadingAuthentication;
