import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import ButtonBase from '@material-ui/core/ButtonBase';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => size || '48px'};
  background-color: ${Colors.primary.medium};
  border: 1px solid ${Colors.primary.medium};
  color: #fff;
  border-radius: 90px;
  margin-right: 8px;
  padding: 0px 8px;
  line-height: 16px;
`;

const IconContainer = styled.div`
  min-width: 32px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewProposalsButton = ({ onClick }) => {
  return (
    <Wrapper>
      <ButtonBase onClick={onClick}>
        <IconContainer>
          <RefreshIcon />
        </IconContainer>
        Novas propostas
      </ButtonBase>
    </Wrapper>
  );
};

export default NewProposalsButton;
