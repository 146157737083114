import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export default {
  getDealsBySop: function(sop?: string, params?: any): Action {
    return { type: ActionTypes.GET_DEALS_BY_SOP, payload: { sop, params } };
  },

  setDeals: function(data: any): Action {
    return { type: ActionTypes.SET_DEALS_SUCESS, payload: { data } };
  },

  setDealsLoading: function(isLoading: boolean): Action {
    return { type: ActionTypes.SET_DEALS_LOADING, payload: { isLoading } };
  },
  setFilters: function(currentFilters): Action {
    return { type: ActionTypes.SET_FILTERS, payload: { currentFilters } };
  },
  setParamsAndSop: function(currentParamsAndSop): Action {
    return {
      type: ActionTypes.SET_PARAMS_AND_SOP,
      payload: { currentParamsAndSop },
    };
  },
};
