export interface DefaultParams {
  dealerId: number;
  proposalId: number;
}

export type GetAllInsuranceQuoteByProposalIdQ = DefaultParams;

export enum InsuranceQuoteStatus {
  New = 'NEW',
  NotSynchronized = 'NOT_SYNCHRONIZED',
  Synced = 'SYNCED',
}

export interface InsuranceQuoteData {
  coverType?: string;
  cpfCnpj?: string;
  financingInstitution?: string;
  id: number;
  isSelected: boolean;
  name?: string;
  proposalId: number;
  quoteDate?: number;
  quoteId?: number;
  status: InsuranceQuoteStatus;
  value?: number;
  vehicle?: string;
}

export type GetAllInsuranceQuoteByProposalIdR = InsuranceQuoteData[];

export type StartInsuranceQuoteQ = DefaultParams;

export interface MarkSelectedInsuranceQuoteQ extends DefaultParams {
  insuranceId: number;
}

export type SendInsuranceQuoteToSpecialistQ = DefaultParams;

export type SendInsuranceQuoteToCustomerQ = DefaultParams;
