import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

import { IconButton } from './styles';

const SearchButton = ({ onClick }) => {
  return <IconButton onClick={onClick}>
    <SearchIcon />
  </IconButton>
}

export default SearchButton;