import { CircularProgress, MenuItem, NoSsr, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react'
import Select from 'react-select'
import { MatSelectContainer, styles, ListMessage, ListMessageContent, TextFieldInput } from './styles'
import { FixedSizeList as List } from "react-window"
import TextField from 'modules/financing/components/atoms/TextField'

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      <span title={props.children}>{props.children}</span>
    </Typography>
  );
}

const height = 46;

const MenuList = ({ options, selectProps, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const itemOffset = options.indexOf(value) * height;
  const listHeight = children.length * height
  const hasScroll = listHeight >= maxHeight
  const initialOffset = hasScroll ? itemOffset : 0
  const { isLoading, loadingMessage, noOptionsMessage } = selectProps

  if (isLoading)
    return (
      <ListMessageContent>
        <CircularProgress size={20} style={{ marginRight: 5 }} />
        <ListMessage>{loadingMessage()}</ListMessage>
      </ListMessageContent>
    )

  if (!options.length)
    return (
      <ListMessageContent>
        <ListMessage>{noOptionsMessage()}</ListMessage>
      </ListMessageContent>
    )

  return (
    <List
      height={hasScroll ? maxHeight : listHeight}
      itemCount={children.length}
      itemSize={30}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 600 : 400,
        fontSize: 12,
        borderBottom: '1px solid #ebebeb'
      }}
      {...props.innerProps}
    >
      <Tooltip title={props.children}>
        <span>{props.children}</span>
      </Tooltip>
    </MenuItem>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <TextFieldInput ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      elevation={8}
      className={props.selectProps.classes.paper}
      style={{ width: props.selectProps.menuWidth || 'auto', }}
      {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Option,
  Menu,
  MenuList,
  SingleValue
};

function MatSelectComponent(props) {
  return (
    <MatSelectContainer>
      <NoSsr>
        <Select
          key={`mat_select_${props.value}`}
          loadingMessage={() => 'Carregando'}
          noOptionsMessage={() => 'Nenhum registro encontrado'}
          placeholder={props.placeholder || ''}
          isClearable
          isSearchable
          components={components}
          isDisabled={props.disabled}
          {...props}
          fullwidth
        />
      </NoSsr>
    </MatSelectContainer>
  )
}

export default withStyles(styles, { withTheme: true })(MatSelectComponent)