import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 16px;
`;

export const Space = styled.div`
  min-width: ${({ size }): string => (size ? size : '8px')};
  min-height: ${({ size }): string => (size ? size : '8px')};
`;

export const ResultContainer = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  color: #424242;
`;

export const ShinyText = styled.span`
  color: #4443bc;
`;
