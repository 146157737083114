import React from 'react';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput';
import InputBase from '@material-ui/core/InputBase';
import { useStyles, Container } from './styles';

interface Props {
  proposalId: number | string;
}

const ProposalIdContainer: React.FC<Props> = ({ proposalId }) => {
  const classes = useStyles();

  return (
    <Container>
      Código:
      <InputBase
        classes={{
          root: classes.root,
          input: classes.input,
        }}
        value={proposalId}
        id="proposalId"
      />
      <RoundedButton className="copyIcon" onClick={() => contentCopyByNameInput('proposalId')}>
        <ContentCopyIcon />
      </RoundedButton>
    </Container>
  );
};

export default ProposalIdContainer;
