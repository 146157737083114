import { all, takeLatest, put, select } from 'redux-saga/effects';
import DealListServices from 'modules/resale/services/DealListServices';
import ContactServices from 'modules/resale/services/ContactServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ActionTypes from './actionTypes';
import ActionCreators, { PageDefaultOptions } from './actionCreators';
import { Action } from 'types/ActionTypes';
import isEmpty from 'helpers/isEmpty';
import { OrderByEnum } from 'constants/resaleOrderByEnum';
import SupplierServices from 'modules/resale/services/SupplierServices';

export function* get(action: Action) {
  try {
    const { currentDealer }: any = yield select(select => select.dealerStore);
    yield put(ActionCreators.setIsLoading());
    const searchTxt = action.payload.options.query;
    const orderValue = isEmpty(action.payload.options.order)
      ? OrderByEnum.CREATION_DOWN
      : action.payload.options.order;
    yield put(ActionCreators.setTxtSearch(searchTxt));
    yield put(ActionCreators.setByOrder(orderValue));
    yield put(ActionCreators.getInfoCards(currentDealer.id));
    const response = yield DealListServices.getDeals(
      currentDealer.id,
      action.payload.options
    );
    if (response.success) {
      yield put(ActionCreators.set(response.data));
      yield put(ActionCreators.recalculateInfos());
    } else {
      yield put(ActionCreators.set(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.set(e));
  }
}

export function* recalculateInfos() {
  const { deals, notFilteredTotalElements } = yield select(state => ({
    deals: state.deals.deals,
    notFilteredTotalElements: state.deals.notFilteredTotalElements,
  }));
  const isEmptyDeals = isEmpty(deals) || notFilteredTotalElements === 0;
  const payload = { isEmpty: isEmptyDeals };
  try {
    yield put(ActionCreators.setInfos(payload));
  } catch (e) {
    console.log(e);
  }
}

export function* activateDeal(action: Action) {
  try {
    yield put(ActionCreators.setIsActivate());
    const response = yield DealListServices.activateDeal(
      action.payload.dealerId,
      action.payload.dealId
    );

    if (response.success) {
      yield put(ActionCreators.activateDealSuccess(action.payload.dealId));
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Atualizado com sucesso.',
          true
        )
      );
      yield put(ActionCreators.get(PageDefaultOptions));
    } else {
      yield put(ActionCreators.setErrorActivate(response.problem));
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response.data || 'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  } catch (e) {
    yield put(ActionCreators.setErrorActivate(e));
  }
}

export function* inactivateDeal(action: Action) {
  try {
    yield put(ActionCreators.setIsInactivate());
    const response = yield DealListServices.inactivateDeal(
      action.payload.dealerId,
      action.payload.dealId
    );
    if (response.success) {
      yield put(ActionCreators.inactivateDealSuccess(action.payload.dealId));
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Atualizado com sucesso.',
          true
        )
      );
      yield put(ActionCreators.get(PageDefaultOptions));
    } else {
      yield put(ActionCreators.setErrorInactivate(response.problem));
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response.data || 'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  } catch (e) {
    yield put(ActionCreators.setErrorInactivate(e));
  }
}

export function* deleteDeal(action: Action) {
  try {
    yield put(ActionCreators.setIsActivate());
    const response = yield DealListServices.deleteDeal(
      action.payload.dealerId,
      action.payload.dealId
    );
    if (response.success) {
      yield put(ActionCreators.deleteDealSuccess(action.payload.dealId));

      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Atualizado com sucesso.',
          true
        )
      );

      yield put(ActionCreators.get(PageDefaultOptions));
    } else {
      yield put(ActionCreators.setErrorDeleting(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.setErrorDeleting(e));
  }
}

export function* cardsInfo(action: Action) {
  try {
    const response = yield DealListServices.getInfoCardsById(action.payload);
    if (response.success) {
      yield put(ActionCreators.setInfoCards({ ...response.data }));
    } else {
      yield put(ActionCreators.setErrorInfoCards(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.setErrorInfoCards(e));
  }
}

export function* getContactData(action: Action) {
  try {
    const { dealerId, contactId, isSupplier } = action.payload;
    yield put(ActionCreators.setLoadingUpdate(true));
    yield put(ActionCreators.setContactInfo({}));

    let response;
    if (isSupplier) {
      response = yield SupplierServices.getSupplierById(dealerId, contactId);
    } else {
      response = yield ContactServices.getContactById(dealerId, contactId);
    }

    yield put(ActionCreators.setLoadingUpdate(false));
    const { success, data } = response;
    if (success) {
      let contact = {};
      if (data.personType === 'PJ') {
        contact = {
          ...data,
          cnpj: data.cpfCnpj || '',
        };
      } else {
        contact = {
          ...data,
          cpf: data.cpfCnpj || '',
        };
      }
      yield put(ActionCreators.setContactInfo(contact));
    } else {
      yield put(ActionCreators.setErrorContactInfo(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.setErrorContactInfo(e));
  }
}

export function* updateContact(action: Action) {
  try {
    const { dealerId, isSupplier, contactId, data } = action.payload;
    data.cpfCnpj = data?.cpf || data?.cnpj || data?.cpfCnpj;
    yield put(ActionCreators.setLoadingUpdate(true));

    let response;
    if (!isSupplier) {
      response = yield ContactServices.updateContact(dealerId, contactId, data);
    } else {
      response = yield SupplierServices.updateSupplier(
        dealerId,
        contactId,
        data
      );
    }
    if (response.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Contato atualizado com sucesso.',
          true
        )
      );
    } else {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response.data || 'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
    yield put(ActionCreators.setLoadingUpdate(false));
  } catch (e) {
    yield put(ActionCreators.setLoadingUpdate(false));
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

export function* createContact(action: Action) {
  try {
    const { dealerId, isSupplier, data, callback } = action.payload;
    yield put(ActionCreators.setLoadingUpdate(true));
    let finalContactData = {};

    if (data.cnpj) {
      finalContactData = {
        ...data,
        cpfCnpj: data.cnpj || '',
      };
    } else {
      finalContactData = {
        ...data,
        cpfCnpj: data.cpfCnpj || '',
      };
    }

    let response;
    if (!isSupplier) {
      response = yield ContactServices.createContact(
        dealerId,
        finalContactData
      );
    } else {
      response = yield SupplierServices.createSupplier(
        dealerId,
        finalContactData
      );
    }

    yield put(ActionCreators.setLoadingUpdate(false));
    if (response.success) {
      callback();
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Contato criado com sucesso.',
          true
        )
      );
    } else {
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

export default all([
  takeLatest(ActionTypes.GET_DEALS, get),
  takeLatest(ActionTypes.RECALCULATE_DEALS_INFOS, recalculateInfos),
  takeLatest(ActionTypes.DELETE_DEAL, deleteDeal),
  takeLatest(ActionTypes.ACTIVATE_DEAL, activateDeal),
  takeLatest(ActionTypes.INACTIVATE_DEAL, inactivateDeal),
  takeLatest(ActionTypes.GET_INFO_CARD, cardsInfo),
  takeLatest(ActionTypes.GET_CONTACT_INFO, getContactData),
  takeLatest(ActionTypes.SET_UPDATE_CONTACT_INFO, updateContact),
  takeLatest(ActionTypes.CREATE_CONTACT, createContact),
]);
