import { validateUserPermission } from 'helpers/validateUserPermission';

const validadeUserProfile = (
  profileRule,
  userProfiles,
  userType,
  extraUserTypes = []
) => {
  return profileRule
    ? validateUserPermission(userType, extraUserTypes)
      ? true
      : userProfiles?.includes(profileRule)
    : true;
};

export default validadeUserProfile;
