import styled from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import ErrorIcon from '@material-ui/icons/Error';

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 95%;
  background: #fff5e5;
  border-radius: 4px;
`;

export const CancelButton = styled(Button)`
  background-color: transparent !important;
  color: #424242 !important;
  border: 1px solid #bdbdbd !important;
  font-weight: 500 !important;
  text-transform: none !important;
`;

export const ConfirmButton = styled(Button)`
  background-color: transparent !important;
  color: #f44336 !important;
  border: 1px solid #f44336 !important;
  font-weight: 500 !important;
  text-transform: none !important;
`;

export const AlertTitle = styled.h4`
  font-weight: 600;
  font-size: 12px;
  color: #874900;
  line-height: 20px;
  margin: 0;
`;
export const AlertBody = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #424242;
`;
export const QuestionText = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #424242;
  padding: 16px;
`;

export const ErrorIconStyled = styled(ErrorIcon)`
  height: 20px !important;
  width: 20px !important;
  color: #663d00 !important;
  margin-right: 12px !important;
`;
