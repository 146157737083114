import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import colors from '../../mobiauto.web-ui-components.components.colors/colors';

const styles = theme => ({
  textField: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cssLabel: {
    '&$cssFocused': {
      color: `${colors.primary.medium}`,
    },
  },
  cssFocused: {
    '&:after': {
      borderBottom: `2px solid ${colors.primary.medium}`,
    },
  },

  cssLabelMini: {
    fontSize: '15px',
    color: `${colors.gray.medium}`,
    left: '-1.2px',
    '&$cssFocused': {
      color: `${colors.primary.medium}`,
    },
  },
  focused: {},
  notchedOutline: {},
  cssOutlinedMini: {
    fontSize: '15px',
    '&$focused $notchedOutline': {
      border: `1px solid ${colors.primary.medium}`,
    },
  },
  cssOutlinedMiniError: {
    fontSize: '15px',
    '&$focused $notchedOutline': {
      borderColor: ` ${colors.warning.medium}`,
    },
  },

  cssOutlinedInputError: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    borderColor: `${colors.red.medium}`,
    borderRadius: '4px 4px 0px 0px ',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus-within': {
      backgroundColor: 'white',
    },
  },
  cssOutlinedInput: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    borderColor: `${colors.gray.light}`,
    borderRadius: '4px 4px 0px 0px ',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: `${colors.gray.medium}`,
      backgroundColor: 'white',
    },
    '&:focus-within': {
      backgroundColor: 'white',
      borderColor: `${colors.primary.medium}`,
    },
  },
});

class TextInput extends React.Component {
  render() {
    const { classes } = this.props;
    const {
      label,
      placeholder,
      onChange,
      value,
      error = false,
      disabled = false,
      readOnly = false,
      type,
      rows,
      maxRows,
      height,
      mini,
      fontSize,
      variant,
      optional,
      inputProps,
      inputLabelProps,
    } = this.props;
    if (variant === 'mini' || mini) {
      return (
        <TextField
          {...this.props}
          type={type === 'textarea' ? undefined : type}
          placeholder={placeholder}
          rows={rows && !maxRows ? rows : '5'}
          multiline={type === 'textarea' || maxRows ? true : false}
          maxRows={maxRows}
          label={
            optional ? (
              <p style={{ margin: '0px' }}>
                {' '}
                {label}
                <span style={{ color: `${colors.gray.light}` }}>
                  {' '}
                  (Opcional)
                </span>{' '}
              </p>
            ) : (
              label
            )
          }
          className={classes.textField}
          value={value}
          onChange={onChange}
          margin="normal"
          variant="outlined"
          InputProps={{
            style: {
              height: height
                ? height
                : variant === 'mini' || mini
                ? ''
                : '40px',
              fontSize: fontSize,
              ...inputProps,
            },
            classes: {
              root: error
                ? classes.cssOutlinedMiniError
                : classes.cssOutlinedMini,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          InputLabelProps={{
            style: { ...inputLabelProps },
            shrink: true,
            classes: {
              root: classes.cssLabelMini,
              focused: error || classes.cssFocused,
            },
          }}
          classes={{ root: classes.textfield }}
        />
      );
    }
    return (
      <TextField
        {...this.props}
        type={type === 'textarea' ? undefined : type}
        placeholder={placeholder}
        disabled={disabled}
        label={
          optional ? (
            <p style={{ margin: '0px' }}>
              {' '}
              {label}
              <span style={{ color: `${colors.gray.light}` }}>
                {' '}
                (Opcional)
              </span>{' '}
            </p>
          ) : (
            label
          )
        }
        rows={rows && !maxRows ? rows : '5'}
        multiline={type === 'textarea' || maxRows ? true : false}
        maxRows={maxRows}
        error={error}
        className={classes.textField}
        value={value}
        onChange={onChange}
        margin="normal"
        variant="filled"
        InputProps={{
          ...this.props.inputProps,
          readOnly: readOnly,
          classes: {
            root: error
              ? classes.cssOutlinedInputError
              : classes.cssOutlinedInput && disabled
              ? ' '
              : classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: error || classes.cssFocused,
          },
        }}
        classes={{ root: classes.textfield }}
      />
    );
  }
}

export default withStyles(styles)(TextInput);
