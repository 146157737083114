const proposalIsFinished = currentProposal => {
  const url = window.location.pathname;
  if (currentProposal?.archived) {
    return true;
  }
  //IS_SEARCH_AND_RESCUE
  if (url.includes('busca-e-resgate')) {
    return currentProposal.proposalSearchAndRescueFinished;
  }
  //IS_CALLCENTER
  if (
    url.includes('callcenter') &&
    !window.location?.search.includes('qualified')
  ) {
    return currentProposal.proposalSalesAssistantFinished;
  }
  return currentProposal.generalStep !== 'ATTENDANCE'
    ? false
    : currentProposal.proposalFinished;
};

export default proposalIsFinished;
