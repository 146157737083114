import React from 'react';
import { useSelector } from 'react-redux';
import Wrapper from 'components/Wrapper';
import * as S from '../../styles';

const SummaryPayment = () => {
  const { queueData } = useSelector(state => state.order);

  return (
    <Wrapper flex direction="column">
      <S.Text>
        Valor de Venda:
        {`  ${
          queueData?.payment?.soldPrice
            ? queueData?.payment?.soldPrice.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : ''
        }`}
      </S.Text>

      <S.Text>
        Valor do sinal:
        {`  ${
          queueData?.payment?.signalPrice
            ? queueData?.payment?.signalPrice.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : ''
        }`}
      </S.Text>
    </Wrapper>
  );
};
export default SummaryPayment;
