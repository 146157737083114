import MODULE from './module';
import actionsOptions, { getters } from '../actionsOptions';

export default {
  //GET_TABS
  GET_TABS: `@${MODULE}/${getters.GET}_TABS_${actionsOptions.REQUEST}`,
  SET_TABS: `@${MODULE}/${getters.SET}_TABS`,
  SET_CURRENT_TAB: `@${MODULE}/${getters.SET}_CURRENT_TAB`,
  //GET_STOCK_PROVIDERS
  GET_PROVIDER_BY_TYPE: `@${MODULE}/${getters.GET}_PROVIDER_BY_TYPE_${actionsOptions.REQUEST}`,
  SET_PROVIDER_DATA_SUCCEDED: `@${MODULE}/${getters.SET}_PROVIDER_DATA_${actionsOptions.SUCCESS}`,
  SET_PROVIDER_STOCK_ERROR: `@${MODULE}/${getters.SET}_SET_PROVIDER_STOCK_${actionsOptions.ERROR}`,
  SET_PROVIDER_STOCK_LOADING: `@${MODULE}/${getters.SET}_SET_PROVIDER_STOCK_${actionsOptions.LOADING}`,
  //CONNECT
  CONNECT_PROVIDER: `@${MODULE}/${getters.SET}_CONNECT_PROVIDER_${actionsOptions.REQUEST}`,
  CONNECT_SET_STATUS: `@${MODULE}/${getters.SET}_CONNECT_SET_STATUS_${actionsOptions.REQUEST}`,
  //RECONNECT
  RECONNECT_PROVIDER: `@${MODULE}/${getters.SET}_RECONNECT_PROVIDER_${actionsOptions.REQUEST}`,
  //DISCONECT PROVIDER
  DISCONNECT_PROVIDER: `@${MODULE}/${getters.SET}_DISCONNECT_PROVIDER_${actionsOptions.REQUEST}`,
  //SYNC PLAN
  SYNC_PLAN: `@${MODULE}/${getters.GET}_SYNC_PLAN_${actionsOptions.REQUEST}`,
  SYNC_PLAN_LOADING: `@${MODULE}/${getters.SET}_SYNC_PLAN_LOADING_${actionsOptions.LOADING}`,
  //CHANGE_ACTIVATION_STATUS
  CHANGE_ACTIVATION_STATUS: `@${MODULE}/CHANGE_ACTIVATION_STATUS`,
  CHANGE_SWITCH_STOCK: `@${MODULE}/CHANGE_SWITCH_STOCK`,
};
