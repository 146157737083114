import React from 'react'
import ListVehicleByMakeIdContainer from './ListVehicleByMakeIdContainer'
import { connect } from 'store'

function Container({ viewComponent, ...other }) {
  const ViewComponent = viewComponent
  return (<ViewComponent {...other} />)
}

const MainContainer = connect(Container, [
  'dealerStore'
])

function ListVehiclesByMakeIdMain(props) {
  return <MainContainer viewComponent={ListVehicleByMakeIdContainer} {...props} />
}

export default ListVehiclesByMakeIdMain