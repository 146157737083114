import React, { memo } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExpansionPanelTitle = ({ title, IconComponent, extraButton }: any) => {
  return (
    <Container>
      <Flex>
        {!!IconComponent && IconComponent}
        {title}
      </Flex>
      {extraButton}
    </Container>
  );
};

export default memo(ExpansionPanelTitle);
