import { requestModule as request } from './index';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandlerTS';
import { DealDocument, UpdateTDocumentDTO } from 'modules/manageDeals/types';

const serviceName = 'DocumentServices';

export default class DocumentServices {
  static create = async (
    dealId: number | string,
    payload: Omit<DealDocument, 'id' | 'dealId'>
  ) => {
    const response = await request.post(`${dealId}/documents/v1.0/`, payload);
    const responsesHandler = await buildHTTPResponseHandler(
      `${serviceName}.create()`
    );

    return responsesHandler(response);
  };

  static put = async (
    dealId: number,
    documenttId: number | string,
    payload: UpdateTDocumentDTO
  ) => {
    const response = await request.patch(
      `${dealId}/documents/v1.0/${documenttId}`,
      payload
    );
    const responsesHandler = await buildHTTPResponseHandler(
      `${serviceName}.put()`
    );

    return responsesHandler(response);
  };

  static delete = async (dealId: number, documenttId: number | string) => {
    const response = await request.delete(
      `${dealId}/documents/v1.0/${documenttId}`
    );
    const responsesHandler = await buildHTTPResponseHandler(
      `${serviceName}.delete()`
    );

    return responsesHandler(response);
  };

  static listStatements = async (dealId: number | string) => {
    const response = await request.get(`v1.0/${dealId}/statements/`);

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.getStatements()`
    );
    return responseHandler(response);
  };

  static showStatement = async (
    dealId: number | string,
    statementId: number | string
  ) => {
    const response = await request.get(
      `v1.0/${dealId}/statement/${statementId}`,
      {},
      '',
      true
    );

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.getStatements()`
    );
    return responseHandler(response);
  };

  static deleteTransactionalInvoice = async (
    transactionalId: number | string
  ) => {
    // const response = await request.delete(
    //   `invoice/${transactionalId}`);

    // const responseHandler = buildHTTPResponseHandler(
    //     `${serviceName}.deleteInvoice()`)

    // return responseHandler(response);

    return { succes: true, data: null };
  };
}
