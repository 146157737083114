import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import withStyles from '@material-ui/core/styles/withStyles';

export const styles = {
  root: {
    borderRadius: '50%',
    padding: '8px'
  },
};

const RoundedButton = ({ onClick, children, classes, ...props }) => {
  return <ButtonBase classes={{ root: classes.root }} {...props} onClick={onClick} >{children}</ButtonBase>;
}

export default withStyles(styles)(RoundedButton)