import React from 'react';
import styled from 'styled-components';
import { ButtonProps } from '@material-ui/core';

interface HypertextButtonComponent extends ButtonProps {
  red?: boolean;
  textDecoration?: string;
  fullHeight?: boolean;
  isDisabled?: boolean;
}

const getColor = ({
  red,
  isDisabled,
}: {
  red: boolean;
  isDisabled: boolean;
}) => {
  if (isDisabled) {
    return '#0000004D';
  }

  if (red) {
    return '#F44336';
  }

  return '#4443bc';
};

const Button = styled.button`
  border: none;
  background: transparent;
  font-family: 'Poppins Regular';
  text-decoration: ${({ textDecoration }) => textDecoration ?? 'underline'};
  display: flex;
  color: ${({ red = null, isDisabled = false }) =>
    getColor({ red, isDisabled })};
  font-size: 14px;
  padding: 0px 8px;
  border-radius: 4px;
  height: ${({ fullHeight = false }) => (fullHeight ? '100%' : 'initial')};
  align-items: center;
  justify-content: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  &:hover {
    background: ${({ isDisabled }) => (isDisabled ? '#ffffff' : '#e8e8e8')};
  }
`;

const HypertextButtonComponent = (props: HypertextButtonComponent) => {
  return (
    <Button disabled={props.isDisabled} {...props}>
      {props.children}
    </Button>
  );
};

export default HypertextButtonComponent;
