import { all, takeLatest, put, select } from 'redux-saga/effects';
import SearchService from 'services/searchServices';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import { Action } from 'types/ActionTypes';
import isEmpty from 'helpers/isEmpty';
import DealServices from 'modules/resale/services/DealServices';
import OrderDealActionCreators from '../orderDeal/actionCreators';

export function* getMakes() {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);
  try {
    const response = yield SearchService.getMakes(vehicleType);
    if (response.success) {
      yield put(ActionCreators.setMakes(response.data));
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getModels(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);

  try {
    const response = yield SearchService.getModels(
      action.payload?.vehicleType || vehicleType,
      action.payload.makeId
    );
    if (response.success) {
      yield put(ActionCreators.setModels(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getProductionYears(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);
  try {
    const response = yield SearchService.getProductionYears(
      action.payload?.vehicleType || vehicleType,
      action.payload.modelId
    );
    if (response.success) {
      yield put(ActionCreators.setProductionYears(response.data.years));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getTrims(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);

  try {
    const modelId = yield select(state => state.orderDeal.modelId);
    const response = yield SearchService.getTrims(
      action.payload?.vehicleType || vehicleType,
      modelId,
      action.payload.modelYear
    );
    if (response.success) {
      yield put(ActionCreators.setTrims(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getColors() {
  const colors = yield select(state => state.dealForm.colors);
  if (!isEmpty(colors)) {
    return;
  }
  try {
    const response = yield SearchService.getColors();
    if (response.success) {
      yield put(ActionCreators.setColors(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getTransmissions() {
  const transmissions = yield select(state => state.dealForm.transmissions);
  if (!isEmpty(transmissions)) {
    return;
  }
  try {
    const response = yield SearchService.getTransmissions();
    if (response.success) {
      yield put(ActionCreators.setTransmissions(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getDealerLocalities(action: Action) {
  const dealerLocality = yield select(state => state.dealForm.dealerLocality);

  if (!isEmpty(dealerLocality)) {
    return;
  }
  try {
    const response = yield DealServices.getDealerLocalities(
      action.payload.dealerId
    );
    if (response.success) {
      yield put(ActionCreators.setDealerLocality(response?.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getFuels() {
  const fuels = yield select(state => state.dealForm.fuels);
  if (!isEmpty(fuels)) {
    return;
  }

  try {
    const response = yield SearchService.getFuels();
    if (response.success) {
      yield put(ActionCreators.setFuels(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getFeatures() {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);
  try {
    const response = yield SearchService.getFeatures(vehicleType);
    if (response.success) {
      yield put(ActionCreators.setFeatures(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getConditionals() {
  try {
    const response = yield SearchService.getVehicleConditional(true);
    if (response.success) {
      yield put(ActionCreators.setConditionals(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getProductionYearsAndModelYears(action: Action) {
  try {
    const response = yield SearchService.getProductionYears(
      action.payload.action.payload.modelId
    );
    if (response.success) {
      yield put(ActionCreators.setProductionYears(response.data.years));
      yield put(ActionCreators.getModelYears(action.payload.productionYear));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getFipePrice(action: Action) {
  try {
    const response = yield SearchService.getValuePriceByFipe(
      action.payload.trimId,
      action.payload.modelYear,
      action.payload.deal0km
    );
    if (response.success) {
      yield put(ActionCreators.setFipePrice(response.data));
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getReportStatus() {
  const reportStatus = yield select(state => state.dealForm.reportStatus);
  if (!isEmpty(reportStatus)) {
    return;
  }
  try {
    const response = yield DealServices.getReportStatus();
    if (response.success) {
      yield put(ActionCreators.getReportStatusSuccess(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getJustMakes(action: Action) {
  try {
    const response = yield SearchService.getMakes(action.payload.vehicleType);
    if (response.success) {
      yield put(ActionCreators.setJustMakes(response.data));
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getJustModels(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);
  try {
    const response = yield SearchService.getModels(
      action.payload?.vehicleType || vehicleType,
      action.payload.makeId
    );
    if (response.success) {
      yield put(ActionCreators.setJustModels(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getJustProductionYears(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);

  try {
    const response = yield SearchService.getProductionYears(
      vehicleType,
      action.payload.modelId
    );
    if (response.success) {
      yield put(ActionCreators.setJustProductionYears(response.data.years));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getJustTrims(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);

  try {
    const response = yield SearchService.getTrims(
      action.payload?.vehicleType || vehicleType,
      action.payload.modelId,
      action.payload.modelYear
    );
    if (response.success) {
      yield put(ActionCreators.setJustTrims(response.data));
      yield put(OrderDealActionCreators.setDealLoaded());
      yield put(OrderDealActionCreators.searchedPlate());
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getJustProductionYearsAndModelYears(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);

  try {
    const response = yield SearchService.getProductionYears(
      action.payload?.vehicleType || vehicleType,
      action.payload.modelId
    );
    if (response.success) {
      yield put(ActionCreators.setJustProductionYears(response.data.years));
      yield put(
        ActionCreators.setJustModelYears(action.payload.productionYear)
      );
      return;
    }
  } catch (e) {
    console.log(e);
  }
}
export function* getVehicleTypes() {
  const vehicleTypes = yield select(state => state.dealForm.vehicleTypes);

  if (!isEmpty(vehicleTypes)) return;
  try {
    const response = yield SearchService.getVehicleTypesBase();
    if (response.success) {
      yield put(
        ActionCreators.getVehicleTypesSuccess(
          response.data.map(type => ({
            label: type.label,
            value: type.name,
          }))
        )
      );
      yield put(ActionCreators.getMakes());
    } else {
      yield put(ActionCreators.getVehicleTypesSuccess(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.getVehicleTypesSuccess(e));
  }
}
export function* getJustVehicleTypes() {
  const vehicleTypes = yield select(state => state.dealForm.vehicleTypes);

  if (!isEmpty(vehicleTypes)) return;
  try {
    const response = yield SearchService.getVehicleTypesBase();
    if (response.success) {
      yield put(
        ActionCreators.getJustVehicleTypesSuccess(
          response.data.map(type => ({
            label: type.label,
            value: type.name,
          }))
        )
      );
      yield put(ActionCreators.getMakes());
    } else {
      yield put(ActionCreators.getVehicleTypesError(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.getVehicleTypesError(e));
  }
}

export default all([
  takeLatest(ActionTypes.GET_MAKES, getMakes),
  takeLatest(ActionTypes.GET_MODELS, getModels),
  takeLatest(ActionTypes.GET_PRODUCTION_YEARS, getProductionYears),
  takeLatest(ActionTypes.GET_TRIMS, getTrims),
  takeLatest(ActionTypes.GET_COLORS, getColors),
  takeLatest(ActionTypes.GET_TRANSMISSIONS, getTransmissions),
  takeLatest(ActionTypes.GET_FUELS, getFuels),
  takeLatest(ActionTypes.GET_FEATURES, getFeatures),
  takeLatest(ActionTypes.GET_CONDITIONALS, getConditionals),
  takeLatest(
    ActionTypes.GET_PRODUCTION_YEARS_AND_MODEL_YEARS,
    getProductionYearsAndModelYears
  ),
  takeLatest(ActionTypes.GET_FIPE_PRICE, getFipePrice),
  takeLatest(ActionTypes.GET_REPORT_STATUS, getReportStatus),

  takeLatest(ActionTypes.GET_JUST_MAKES, getJustMakes),
  takeLatest(ActionTypes.GET_JUST_MODELS, getJustModels),
  takeLatest(ActionTypes.GET_JUST_PRODUCTION_YEARS, getJustProductionYears),
  takeLatest(ActionTypes.GET_JUST_TRIMS, getJustTrims),
  takeLatest(
    ActionTypes.GET_JUST_PRODUCTION_YEARS_AND_MODEL_YEARS,
    getJustProductionYearsAndModelYears
  ),
  takeLatest(ActionTypes.GET_VEHICLE_TYPES, getVehicleTypes),
  takeLatest(ActionTypes.GET_JUST_VEHICLE_TYPES, getJustVehicleTypes),
  takeLatest(ActionTypes.GET_DEALER_LOCALITIES, getDealerLocalities),
]);
