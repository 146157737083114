import React from 'react';

import BoxWithoutConsent from 'components/BoxConsent/BoxWithoutConsent';
import BoxWithConsent from 'components/BoxConsent/BoxWithConsent';

import { IBoxConsent, statusType } from './BoxConsentWrapper.types';

const BoxConsentWrapperComponent: React.FC<IBoxConsent> = ({
  status,
  imageId,
  imageVcode,
  idValue,
  ipValue,
  createData,
  expirationData,
  createHours,
  expirationHours,
  userAgentContent,
}) => {
  return (
    <>
      {status === statusType.CANCELED ? (
        <BoxWithoutConsent />
      ) : (
        <BoxWithConsent
          status={status}
          imageId={imageId}
          imageVcode={imageVcode}
          idValue={idValue}
          ipValue={ipValue}
          createData={createData}
          expirationData={expirationData}
          createHours={createHours}
          expirationHours={expirationHours}
          userAgentContent={userAgentContent}
        />
      )}
    </>
  );
};

export default BoxConsentWrapperComponent;
