import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import ListDeals from 'modules/financing/components/molecules/ListDeals';
import VehicleToTrimInfos from 'components/molecules/VehicleToTrimInfos';
import TabPanel from 'components/StyledComponents/TabPanel';
import StyledTab from 'components/StyledComponents/StyledTab';
import StyledTabs from 'components/StyledComponents/StyledTabs';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';

const DialogSelectVehicleToSimulateComponent = ({
  open,
  handleClose,
  handleClickSelectVehicleToSimulate,
  selectedVehicle,
  setSelectedVehicle,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (_, newTab) => {
    setCurrentTab(newTab);
  };

  const DialogAction = () => (
    <Box component="div" display="flex" justifyContent="flex-end" flexGrow={1}>
      <OutlinedButton onClick={handleClose}>Cancelar</OutlinedButton>
      <div style={{ width: 8 }} />
      <ThemeButton
        onClick={() => {
          handleClickSelectVehicleToSimulate({
            vehicle: selectedVehicle?.[0] ?? null,
          });
          handleClose();
        }}
        disabled={!(selectedVehicle?.[0]?.id || selectedVehicle?.[0]?.trim?.id)}
      >
        Continuar
      </ThemeButton>
    </Box>
  );

  return (
    <GenericDialog
      open={open}
      onClose={handleClose}
      title="Simular financiamento"
      DialogAction={DialogAction}
      maxWidth={null}
      fullWidth
    >
      <StyledTabs value={currentTab} onChange={handleChangeTab}>
        <StyledTab value={0} label="Meu Estoque" />
        <StyledTab value={1} label="Fora do estoque" />
      </StyledTabs>
      <TabPanel value={currentTab} index={0} key={'estoque'}>
        {currentTab === 0 && (
          <div style={{ marginTop: 8 }}>
            <ListDeals
              selectedVehicles={selectedVehicle}
              handleClickItem={vehicle => {
                setSelectedVehicle([vehicle]);
              }}
              allAvailable
            />
          </div>
        )}
      </TabPanel>
      <TabPanel value={currentTab} index={1} key={'Fora-do-estoque'}>
        {currentTab === 1 && (
          <div style={{ marginTop: 8 }}>
            <VehicleToTrimInfos
              handleClickItem={vehicle => {
                setSelectedVehicle(vehicle);
              }}
            />
          </div>
        )}
      </TabPanel>
    </GenericDialog>
  );
};

export default DialogSelectVehicleToSimulateComponent;
