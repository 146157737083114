import React from 'react';
import Wrapper from 'components/Wrapper';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import TextField from 'modules/financing/components/atoms/TextField';
import { ModalApprovalTypesTypesComponent } from './ModalAprovalTypes';

const ModalApprovalComponent = ({
  open,
  onClose,
  confirmReproveDocument,
  reason,
  setReason,
  isLoading,
}: ModalApprovalTypesTypesComponent) => {
  return (
    <GenericDialog
      open={open}
      title="Aprovar documento"
      onClose={onClose}
      maxWidth="sm"
      DialogAction={() => (
        <Wrapper flex justify="end" width="100%" gap="md">
          <OutlinedButton onClick={onClose} disabled={isLoading}>
            Cancelar
          </OutlinedButton>
          <ThemeButton
            onClick={confirmReproveDocument}
            disabled={isLoading}
            loading={isLoading}
          >
            Concluir
          </ThemeButton>
        </Wrapper>
      )}
    >
      <Wrapper width="100%" gap="lg" flex direction="column">
        <TextField
          type="textarea"
          value={reason}
          onChange={e => {
            setReason(e.target.value);
          }}
          label="Motivo"
          multiline
          rows={4}
        />
      </Wrapper>
    </GenericDialog>
  );
};

export default ModalApprovalComponent;
