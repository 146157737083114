import Request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import EnvVars from 'helpers/envVars';
import toBase64 from 'helpers/toBase64';

const domainImages = EnvVars('API_IMAGES_BASE_URL');
const serviceName = 'UploadService';

interface MediaProps {
  file: any;
  mediaOrigin:
    | 'RESALE_REPORT'
    | 'FINANCING_CET'
    | 'FINANCIAL'
    | 'PROPOSAL_INTERACTION';
  callback?: (props: any) => void;
}

export default class UploadService {
  static uploadMedia = async ({ file, mediaOrigin, callback }: MediaProps) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      return Request.post(`${domainImages}/media/v1.0`, formData, {
        params: {
          mediaOrigin: mediaOrigin,
        },
        onUploadProgress: callback,
      }).then(buildHTTPResponseHandler(`${serviceName}.uploadMedia()`) as any);
    } catch (e) {
      console.log(e);
    }
  };

  static uploadDealReport = async (file, callback) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      return Request.post(`${domainImages}/media/v1.0`, formData, {
        params: {
          mediaOrigin: 'RESALE_REPORT',
        },
        onUploadProgress: callback,
      }).then(
        buildHTTPResponseHandler(`${serviceName}.uploadDealReport()`) as any
      );
    } catch (e) {
      console.log(e);
    }
  };

  static uploadDealImage = async (
    image,
    imageOrigin,
    callback = () => {
      return;
    }
  ) => {
    try {
      const formData = new FormData();
      formData.append('file', image);
      formData.append('imgOrigin', imageOrigin);
      return await Request.post(`${domainImages}/images/v1.0`, formData, {
        onUploadProgress: callback,
      }).then(
        buildHTTPResponseHandler(
          `${serviceName}.uploadDealImageBinary()`
        ) as any
      );
    } catch (e) {
      console.log(e);
    }
  };

  static uploadDealImageBase64 = async (
    image,
    domainImages,
    imageOrigin,
    handleOnProgress
  ) => {
    try {
      const base64 = await toBase64(image);

      const obj = {
        fileName: image.name,
        imgOrigin: 'DEAL',
        mimeType: image.type,
        base64: base64.split(',')[1],
      };

      return await Request.post(`${domainImages}/images/v1.0/base64`, obj, {
        onUploadProgress: handleOnProgress,
      }).then(
        buildHTTPResponseHandler(`${serviceName}.uploadDealImageBy64()`) as any
      );
    } catch (e) {
      console.log(e);
    }
  };
}
