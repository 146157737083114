import React from 'react';
import styled from 'styled-components';

import { MenuItem, TextField, ClickAwayListener } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ButtonWithIcon from '../../mobiauto.web-ui-components.components.button-with-icon/ButtonWithIcon';
import colors from '../../mobiauto.web-ui-components.components.colors/colors';

import AutoComplete from './autoComplete';

const styles = theme => ({
  //SELECT WITH ERROR
  underlineError: {
    '&:before': {
      borderBottom: `2px solid ${colors.warning.light}`,
    },
    '&:after': {
      borderColor: colors.warning.medium,
    },
  },
  cssLabelWithError: {
    color: colors.warning.medium,
    '&$cssFocused': {
      color: colors.warning.medium,
    },
  },

  cssRootWithError: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    backgroundColor: 'white',
    borderColor: colors.warning.medium,
    '&:hover:not(.MuiFilledInput-disabled-247):not(.MuiFilledInput-focused-246):not(.MuiFilledInput-error-250):before': {
      borderBottom: `2px solid ${colors.warning.medium}`,
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus-within': {
      backgroundColor: 'white',
      borderColor: colors.warning.medium,
    },
  },

  //SELECT WITH SELECTED
  underlineSelected: {
    '&:before': {
      borderBottom: `2px solid ${colors.primary.light}`,
    },
    '&:after': {
      borderColor: colors.primary.light,
    },
  },
  cssLabelWithSelected: {
    color: colors.primary.medium,
    '&$cssFocused': {
      color: colors.primary.medium,
    },
  },
  cssRootWithSelected: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    backgroundColor: '#DDF1F1',
    borderColor: colors.primary.light,
    '&:hover:not(.MuiFilledInput-disabled-247):not(.MuiFilledInput-focused-246):not(.MuiFilledInput-error-250):before': {
      borderBottom: `2px solid ${colors.primary.medium}`,
    },
    '&:hover': {
      backgroundColor: '#DDF1F1',
    },
    '&:focus-within': {
      backgroundColor: '#DDF1F1',
      borderColor: colors.primary.medium,
    },
  },

  //SELECT DEFAULT
  cssLabel: {
    '&$cssFocused': {
      color: colors.primary.medium,
    },
  },

  cssRoot: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    backgroundColor: 'white',
    borderColor: colors.gray.light,
    '&:hover': {
      backgroundColor: 'white',
      borderColor: colors.gray.medium,
    },
    '&:focus-within': {
      backgroundColor: '#DDF1F1',
      borderColor: colors.primary.medium,
    },
  },

  //FOCUSED FOR ALL
  cssFocused: {
    '&:after': {
      borderColor: colors.primary.medium,
    },
  },

  //SELECT MINI
  cssLabelMini: {
    fontSize: '15px',
    left: '-1px',
    color: colors.gray.medium,
    '&$cssFocusedMini': {
      color: colors.primary.medium,
    },
  },
  cssFocusedMini: {
    '&:after': {
      borderBottom: `2px solid ${colors.primary.medium}`,
    },
  },
  focused: {
    '&:after': {
      borderBottom: `2px solid ${colors.primary.medium}`,
    },
  },
  notchedOutline: {},
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  cssRootMini: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    borderColor: colors.gray.light,
    backgroundColor: 'white',
    '&$focused $notchedOutline': {
      backgroundColor: '#DDF1F1',
      border: `1px solid ${colors.primary.medium}`,
    },
  },

  //SELECT MINI SELECTED
  cssLabelMiniSelected: {
    fontSize: '15px',
    left: '-1px',
    color: colors.primary.medium,
    borderRadius: '4px',
    width: '60px',
    backgroundColor: '#DDF1F1',
    '&$cssFocusedMini': {
      color: colors.primary.medium,
    },
  },
  cssRootMiniSelected: {
    backgroundColor: '#DDF1F1',
    '&$focused $notchedOutline': {
      backgroundColor: '#DDF1F1',
      border: `1px solid ${colors.primary.medium}`,
    },
  },
});

const Wrapper = styled.div`
  position: relative;
`;

const SelectCustomizable = styled.div`
  width: 100%;
  color: black;
  padding: 5px;
  cursor: pointer;
`;

const MenuOptions = styled.div`
  width: 100%;
  border: 1px normal black;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: white;
`;

const ListItem = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 6px 0px 6px 10px;
  &:hover {
    background-color: #ebebeb;
  }
`;

class Select extends React.Component {
  state = {
    name: '',
    isFocused: false,
    menuOption: false,
    customizableValue: 0,
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  setIsFocused = focused => {
    this.setState({ isFocused: focused });
  };

  optionValue = (options, value) => {
    const op = options.filter(option => option.value === value);
    return op[0].label;
  };
  render() {
    const {
      id,
      classes,
      selected,
      error,
      width,
      customizable,
      color,
      fontSize,
      textTransform,
      mini,
      style,
      name,
      label,
      value,
      options,
      onChange,
      variant,
      placeholder,
      autoComplete,
      withGroup,
      groupTitleStyle,
      selectedItemStyle,
      listItemStyle,
      listStyle,
      disabled,
      menuItemStyle,
      ...props
    } = this.props;
    const { menuOption } = this.state;
    const isMini = variant === 'mini';
    const autoCompleteVerification = autoComplete || variant === 'autoComplete';
    if (selected) {
      return (
        <TextField
          style={{
            width: props.width ? width : '100%',
          }}
          fullWidth={width ? false : true}
          variant="filled"
          select
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          SelectProps={{
            classes: {
              select: classes.select,
            },
          }}
          InputProps={{
            style: {
              height: isMini || mini ? '40px' : '',
              fontSize: isMini || mini ? '12px' : '',
              padding: isMini || mini ? '0px 0px 4px 0px' : '',
              ...style,
            },
            id,
            name,
            classes: {
              root: classes.cssRootWithSelected,
              underline: classes.underlineSelected,
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: isMini || mini ? '14px' : '',
              top: isMini || mini ? '-3px' : '',
            },
            classes: {
              root: classes.cssLabelWithSelected,
              focused: classes.cssFocused,
            },
          }}
        >
          {options.map(({ value, label }) => {
            return (
              <MenuItem style={{ ...menuItemStyle }} value={value} key={label}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
      );
    }
    if (error === true) {
      return (
        <TextField
          style={{
            width: props.width ? width : '100%',
            backgroundColor: 'transparent',
          }}
          fullWidth={width ? false : true}
          variant="filled"
          select
          label={label || 'Ocorreu um Erro'}
          value={value}
          onChange={onChange}
          disabled={disabled}
          SelectProps={{
            classes: {
              select: classes.select,
            },
          }}
          InputProps={{
            style: {
              height: isMini || mini ? '40px' : '',
              fontSize: isMini || mini ? '12px' : '',
              padding: isMini || mini ? '0px 0px 4px 0px' : '',
            },
            id,
            name,
            classes: {
              root: classes.cssRootWithError,
              underline: classes.underlineError,
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: isMini || mini ? '14px' : '',
              top: isMini || mini ? '-3px' : '',
            },
            classes: {
              root: classes.cssLabelWithError,
              focused: classes.cssFocused,
            },
          }}
        >
          {options.map(({ value, label }) => {
            return (
              <MenuItem value={value} key={label}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
      );
    }
    if (customizable) {
      return (
        <ClickAwayListener
          onClickAway={() => this.setState({ menuOption: false })}
        >
          <Wrapper>
            <SelectCustomizable
              style={{ ...selectedItemStyle }}
              onClick={() => this.setState({ menuOption: !menuOption })}
            >
              <div
                style={{
                  float: 'right',
                  marginRight: '5px',
                  position: 'relative',
                  bottom: '1px',
                }}
              >
                <ButtonWithIcon
                  iconName={'KeyboardArrowDown'}
                  iconProps={{ size: 16 }}
                  color={colors.gray.medium}
                  onClick={() => this.setState({ menuOption: !menuOption })}
                />
              </div>
              {this.optionValue(options, value)}
            </SelectCustomizable>

            {menuOption === false ? (
              ''
            ) : (
              <MenuOptions style={{ ...listStyle }}>
                {options.map(({ value, label }) => {
                  return (
                    <ListItem
                      key={label}
                      style={{ ...listItemStyle }}
                      onClick={() => {
                        onChange(value);
                        this.setState({
                          customizableValue: value,
                          menuOption: !menuOption,
                        });
                      }}
                    >
                      {label}
                    </ListItem>
                  );
                })}
              </MenuOptions>
            )}
          </Wrapper>
        </ClickAwayListener>
      );
    }
    if (autoCompleteVerification || mini) {
      return (
        <AutoComplete
          options={options}
          mini={mini}
          placeholder={placeholder}
          autoComplete={autoComplete}
          variant={variant}
          label={label}
          value={value}
          onChange={onChange}
          withGroup={withGroup}
          width={width}
          groupTitleStyle={groupTitleStyle}
        />
      );
    }
    return (
      <TextField
        style={{
          width: props.width ? width : '100%',
        }}
        fullWidth={width ? false : true}
        variant="filled"
        select
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        SelectProps={{
          classes: {
            select: classes.select,
          },
        }}
        InputProps={{
          style: {
            height: isMini || mini ? '40px' : '',
            fontSize: isMini || mini ? '12px' : '',
            padding: isMini || mini ? '0px 0px 4px 0px' : '',
            ...style,
          },
          id,
          name,
          classes: {
            root: classes.cssRoot,
            focused: classes.cssFocused,
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: isMini || mini ? '14px' : '',
            top: isMini || mini ? '-3px' : '',
          },
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
      >
        {options.map(({ value, label }) => {
          return (
            <MenuItem style={{ ...menuItemStyle }} value={value} key={label}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }
}

export default withStyles(styles)(Select);
