import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  padding: 0 5px;
  border-radius: 4px;

  background: #ffffff5c;

  > img {
    margin-left: auto;
    max-height: 52px;
    width: 58px;
    background-size: cover;
  }
`;
