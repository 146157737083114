import React from 'react';

import Box from '@material-ui/core/Box';

import CardBaseComponent from '../CardBase';

type propTypes = Partial<{
  Icon: React.ReactNode;
  date: string;
  description: string;
}>;

const CardIconWIthHourAndDescriptionComponent = ({
  Icon,
  date,
  description,
}: propTypes) => {
  return (
    <Box component="div" pt={1} pb={1} pl={2} pr={2}>
      <CardBaseComponent
        display="flex"
        alignItems="center"
        gridGap="16px"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
      >
        <Box component="div" display="flex" color="#2196F3">
          {Icon}
        </Box>
        <Box component="div" display="flex" flexDirection="column">
          <Box component="span" fontSize="18px" color="#1C1B1F">
            {date}
          </Box>
          <Box component="p" p={0} m={0} color="#1C1B1F" fontSize="14px">
            {description}
          </Box>
        </Box>
      </CardBaseComponent>
    </Box>
  );
};

export default CardIconWIthHourAndDescriptionComponent;
