import { EvaluationStatus } from 'pages/chats/components/SideBarComponents/Evaluation/sideBarEvaluationTypes';

export const getMessageStatus = (type?: EvaluationStatus) => {
  switch (type) {
    case 'PENDING':
      return 'Pendente';
    case 'EVALUATED':
      return 'Avaliado';
    case 'FINISHED':
      return 'Finalizado';
    case 'CLOSED':
      return 'Encerrado';
    case 'NOT_REQUESTED':
      return 'Não iniciado';
    default:
      return '-';
  }
};
