import React from 'react';
import styled from 'styled-components';

import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import colors from '../../mobiauto.web-ui-components.components.colors/colors';

const FlexWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: ${colors.primary.medium};
  font-weight: 700;
  font-size: 16px;
`;

const CustomProgress = withStyles({
  circleIndeterminate: {
    color: colors.primary.medium,
  },
})(CircularProgress);

interface Props extends CircularProgressProps {
  label?: any;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
}

export default function Progress({
  small,
  large,
  medium,
  label,
  ...props
}: Props) {
  let size;
  if (small) {
    size = 24;
  } else if (large) {
    size = 48;
  } else {
    size = 36;
  }
  return (
    <FlexWrapper>
      <CustomProgress
        className="progress"
        variant="indeterminate"
        size={size}
        thickness={7}
        {...props}
      />
      {label && <FlexWrapper>{label}</FlexWrapper>}
    </FlexWrapper>
  );
}
