import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import styled from 'styled-components';

export const Container = styled.div`
  height: 440px;
  overflow-y: auto;
`;

export const FlexCenterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  padding: ${({ padding }) => (padding ? '16px' : '0px')};
`;

export const FlexItem = styled.div`
  text-align: center;
  align-content: flex-start;
`;

export const CheckboxFilterWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 8px;
  max-width: 100px;
  line-height: 15px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PageInfo = styled.span`
  color: lightgray;
  font-size: 14px;
`;

export const ListLoading = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-left: 16px;
    font-size: 14px;
    color: #555;
  }
`;

export const LoadMoreButton = styled(Button)`
  text-transform: none !important;
  text-decoration: underline !important;
  font-weight: 300 !important;
  color: #03b2ab !important;
  background-color: transparent !important;
  width: 100% !important;
`;

export const EmptyListIconContainer = styled.div`
  border-radius: 35px;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #e0f3f1;
`;

export const IconComponent = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 12px;
  > svg {
    color: #da2e22;
  }
`;
