import styled from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => (padding ? '16px' : '0px')};
`;

export const Title = styled.div`
  font-family: Poppins bold;
  font-size: 14px;
  line-height: 24px;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

export const HightPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  width: 295px;
  height: 48px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: space-between;
`;

export const CirclePicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 90px;
  margin-right: 8px;
  border: 1px solid #eeeeee;
  object-fit: cover;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Price = styled.div`
  background: #f6f2fd;
  border-radius: 90px;
  padding: 2px 8px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ConfirmButton = styled(Button)`
  font-family: Poppins Regular !important;
  text-transform: initial !important;
  width: 100% !important;
  margin-left: 4px !important;
`;

export const CancelButton = styled(ConfirmButton)`
  background-color: transparent !important;
  border: 1px solid #e0e0e0 !important;
  color: #333333 !important;
  margin-left: 0px !important;
  margin-right: 4px !important;
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  border-radius: 90px;
  width: 54px;
  height: 54px;
  margin-bottom: 8px;
`;
