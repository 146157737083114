import { useMutation, useQueryClient } from 'react-query';

import * as API from './api';

interface HandleReturn {
  onError?: <Response>(data?: Response) => void;
  onSuccess?: <Response>(data?: Response) => void;
}

interface CustomMutation<Query, Response> {
  handleReturn?: HandleReturn;
  key: string;
  queriesToInvalidate?: string[];
  request: (params: Query) => Promise<Response>;
}

function useCustomMutation<Query, Response>({
  handleReturn,
  key,
  request,
  queriesToInvalidate,
}: CustomMutation<Query, Response>) {
  const queryClient = useQueryClient();
  return useMutation([key], (params: Query) => request(params), {
    onError: data => {
      if (handleReturn?.onError) handleReturn?.onError(data);
    },
    onSuccess: data => {
      if (queriesToInvalidate) {
        queriesToInvalidate.map(query => {
          queryClient.invalidateQueries(query);
        });
      }
      if (handleReturn?.onSuccess) handleReturn?.onSuccess(data);
    },
  });
}

export function useGetProposalMessageTemplates(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'getProposalMessageTemplates',
    request: API.getProposalMessageTemplates,
    handleReturn,
  });
}
