import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: ${props => {
    if (props.justifyStart) {
      return 'flex-start';
    } else if (props.justifyEnd) {
      return 'flex-end';
    } else if (props.center || props.justifyCenter) {
      return 'center';
    } else if (props.justifyBetween) {
      return 'space-between';
    } else if (props.justifyAround) {
      return 'space-around';
    } else {
      return 'initial';
    }
  }};
  align-items: ${props => {
    if (props.alignStart) {
      return 'flex-start';
    } else if (props.alignEnd) {
      return 'flex-end';
    } else if (props.center || props.alignCenter) {
      return 'center';
    } else if (props.alignBaseline) {
      return 'baseline';
    } else {
      return 'initial';
    }
  }};
`;

export default Flex;
