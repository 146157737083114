import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import SimulationCard from './components/SimulationCard';
import { SimulationList, PlateContainer, DividerLarge } from './styles';
import PanSimulationCard from './components/PanSimulationCard';

const Accordion = withStyles({
  root: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '0px',
  },
}))(MuiAccordionDetails);

const AccordionSummary = withStyles(() => ({
  root: {
    '&$expanded': {
      margin: '0px',
    },
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
}))(MuiAccordionSummary);

const SimulationHistory = ({
  simulationHistoryState,
  getSimulationsQueryResult,
  financingBancoPanEnabled,
  fandiIntegration,
}) => {
  return (
    <>
      {getSimulationsQueryResult.isLoading ? (
        <Box component="div" p={2}>
          <Progress />
        </Box>
      ) : (
        <>
          {!fandiIntegration && financingBancoPanEnabled ? (
            <Box component="div">
              {simulationHistoryState?.historyData?.content?.length ? (
                simulationHistoryState?.historyData?.content?.map(
                  (simulation, index) => {
                    const vehicleMakeName =
                      simulation?.vehicle?.trim?.model?.make?.name;
                    const vehicleModelName =
                      simulation?.vehicle?.trim?.model?.name;
                    const vehicleTrimName = simulation?.vehicle?.trim?.name;
                    const vehicleModelYear = simulation?.vehicle?.modelYear;

                    return (
                      <div key={index}>
                        <Box
                          component="div"
                          display="flex"
                          justifyContent="space-between"
                          m={2}
                          fontSize={14}
                          color="#424242"
                        >
                          <Box
                            component="div"
                            display="flex"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <Box component="div">
                              <Box
                                component="div"
                                color="#333333"
                                fontWeight={600}
                                fontFamily={'Poppins Medium'}
                              >
                                {simulation?.make || vehicleMakeName}{' '}
                                {simulation?.model || vehicleModelName}
                              </Box>
                              <Box
                                component="div"
                                color="#757575"
                                lineHeight={'12px'}
                              >
                                {simulation.trim || vehicleTrimName}{' '}
                                {simulation.modelYear || vehicleModelYear}
                              </Box>
                              <Box color="#424242" fontSize="12px">
                                {simulation?.id}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            display="flex"
                            alignItems="center"
                          >
                            <PlateContainer>
                              {simulation.plate || simulation?.vehicle?.plate}
                            </PlateContainer>
                          </Box>
                        </Box>
                        {/* //LIST CONTAINER */}
                        <Box component="div" fontSize={14} m={2}>
                          <Divider />
                          <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Box component="div" color="#757575">
                                Simulações realizadas
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'block' }}>
                              <Box component="div">
                                <SimulationList>
                                  <PanSimulationCard
                                    vehicleSimulation={simulation}
                                    simulationIndex={index}
                                  />
                                </SimulationList>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                        <DividerLarge />
                      </div>
                    );
                  }
                )
              ) : (
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="16px"
                >
                  Não há historico de simulações
                </Box>
              )}
            </Box>
          ) : (
            <Box component="div">
              {simulationHistoryState?.historyData?.length ? (
                simulationHistoryState?.historyData?.map(
                  (simulation, index) => {
                    return (
                      <div key={index}>
                        <Box
                          component="div"
                          display="flex"
                          justifyContent="space-between"
                          m={2}
                          fontSize={14}
                          color="#424242"
                        >
                          <Box
                            component="div"
                            display="flex"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <Box component="div">
                              <Box
                                component="div"
                                color="#333333"
                                fontWeight={600}
                                fontFamily={'Poppins Medium'}
                              >
                                {simulation?.make || simulation?.vehicle?.make}{' '}
                                {simulation?.model ||
                                  simulation?.vehicle?.model}
                              </Box>
                              <Box
                                component="div"
                                color="#757575"
                                lineHeight={'12px'}
                              >
                                {simulation.trim ||
                                  simulation?.vehicle?.trimName}{' '}
                                {simulation.modelYear ||
                                  simulation?.vehicle?.modelYear}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            display="flex"
                            alignItems="center"
                          >
                            <PlateContainer>
                              {simulation.plate || simulation?.vehicle?.plate}
                            </PlateContainer>
                          </Box>
                        </Box>
                        {/* //LIST CONTAINER */}
                        <Box component="div" fontSize={14} m={2}>
                          <Divider />
                          <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Box component="div" color="#757575">
                                Simulações realizadas
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'block' }}>
                              <Box component="div">
                                <SimulationList>
                                  {simulation?.simulations?.map(
                                    (simulationItem, index) => (
                                      <SimulationCard
                                        key={index}
                                        simulationIndex={index}
                                        simulation={simulation}
                                        simulationItem={simulationItem}
                                      />
                                    )
                                  )}
                                </SimulationList>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                        <DividerLarge />
                      </div>
                    );
                  }
                )
              ) : (
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="16px"
                >
                  Não há historico de simulações
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default SimulationHistory;
