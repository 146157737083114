import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import styled from 'styled-components';

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContainerMessage = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  gap: 8px;
  justify-content: ${props => (props.bot ? 'initial' : 'flex-end')};
`;

export const ContainerOvalBigger = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const OvalBigger = styled.div`
  padding: 0px 4px;
  height: 32px;
  width: 26px;
  background-color: ${props => (props.bot ? '#E0F3F1' : '#EEE')};
  font-family: Poppins Bold;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 0px;
`;

export const BoxMessage = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: #f3f6f7;
  color: #121616;
  margin: 0px 32px 0px 0px;
  padding: 12px 16px;
  border-radius: 4px;
`;

export const TitleMessage = styled.p`
  font-weight: 700;
  font-size: 14px;
`;
