import moment from 'moment';
export const returnHoursOrMin = hourOrMin => {
  if (hourOrMin === 60) {
    return `${moment()
      .startOf('day')
      .add(hourOrMin, 'minutes')
      .format('hh')} hora`;
  }
  if (hourOrMin > 60 && hourOrMin < 120) {
    return `${moment()
      .startOf('day')
      .add(hourOrMin, 'minutes')
      .format('hh')} hora e ${moment()
      .startOf('day')
      .add(hourOrMin, 'minutes')
      .format('mm')} min  `;
  }

  if (hourOrMin >= 120) {
    return `${moment()
      .startOf('day')
      .add(hourOrMin, 'minutes')
      .format('hh')} horas e ${moment()
      .startOf('day')
      .add(hourOrMin, 'minutes')
      .format('mm')} min  `;
  }

  return `${hourOrMin} minutos`;
};
