import styled from 'styled-components';

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .created-at {
    color: lightgrey;
  }
`;

export const DialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
`;
