import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
`;

export const FlexSpaceBetween = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Separator = styled.div`
  border-left: 1px solid #e0e0e0;
  margin: 0px 32px;
`;
export const Title = styled.div`
  font-family: 'Poppins Bold';
  font-size: 16px;
  margin-top: 8px;
`;

export const TicketPrice = styled.div`
  color: #757575;
  font-size: 14px;
  line-height: 22px;
`;

export const BankInfosContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
`;

export const ColumCenter = styled(Flex)`
  justify-content: center;
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 16px;
  line-height: 28px;
`;

export const SubLabel = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #757575;
`;
