import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import ActionCreators from 'modules/financing/store/reducers/actionCreators'
import PersonalInfosForm from './GuarantorInfosForm'

const GuarantorInfosFormContainer = ({ formData, onChange, disableAllInputs = false, enableCopyButton = false, disableCpfCnpj = false }) => {
  //REDUX_HOOKS
  const { genderOptions, civilStatusOptions, statesOptions } = useSelector(state => state.financing)
  const dispatch = useDispatch()
  //GET_OPTIONS
  useEffect(() => {
    dispatch(ActionCreators.getGendersOptions())
    dispatch(ActionCreators.getCivilStatusOptions())
  }, [])

  const viewComponentProps = {
    genderOptions,
    civilStatusOptions,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton,
    disableCpfCnpj,
    statesOptions
  }

  return <PersonalInfosForm {...viewComponentProps} />
}

export default GuarantorInfosFormContainer;