import { all } from 'redux-saga/effects';
import orderDeal from 'modules/resale/store/reducers/orderDeal/sagas';
import dealForm from 'modules/resale/store/reducers/dealForm/sagas';
import deals from 'modules/resale/store/reducers/deals/sagas';
import leads from 'modules/resale/store/reducers/leads/sagas';
import providers from 'modules/resale/store/reducers/provider/sagas';
import callCenter from 'modules/resale/store/reducers/callCenter/sagas';
import searchAndRescueCenter from 'modules/resale/store/reducers/searchAndRescueCenter/sagas';
import proposals from 'modules/resale/store/reducers/proposals/sagas';
import negotiation from 'modules/resale/store/reducers/negotiation/sagas';
import offerPortal from 'modules/resale/store/reducers/offerPortal/sagas';
import evaluation from 'modules/evaluation/store/sagas';
import scheduler from '../reducers/scheduler/sagas';
import dealerSaga from '../reducers/dealer/sagas';
import dealerStoreSagas from '../ducks/dealer/sagas';
import userStoreSagas from '../ducks/user/sagas';
import storeInventory from 'modules/store-inventory/store/sagas';

//OTHER MODULES
import { financialSagas } from 'modules/financial/store/';
import { manageDealsSagas } from 'modules/manageDeals/store';
import financing from 'modules/financing/store/sagas';
import order from 'modules/order/store/sagas';

export default function* sagas() {
  return yield all([
    orderDeal,
    dealForm,
    deals,
    leads,
    providers,
    callCenter,
    searchAndRescueCenter,
    proposals,
    negotiation,
    offerPortal,
    scheduler,
    evaluation,
    dealerSaga,
    financialSagas,
    manageDealsSagas,
    financing,
    order,
    storeInventory,
    dealerStoreSagas,
    userStoreSagas,
  ]);
}
