// this file is depreceated,
// please use one of them ['ErrorBoundaryBase', 'ErrorBoundaryDialog', 'ErrorBoundaryPage', 'ErrorBoundaryComponent']

import React from 'react';
import { connect } from 'store';
import isEmpty from 'helpers/isEmpty';

import Error500 from 'pages/error500';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  parseId = () => {
    const {
      userStore: { userSite },
    } = this.props;

    if (isEmpty(userSite) || isEmpty((userSite || {}).id)) {
      return undefined;
    }
    return parseInt(((userSite || {}).id || [])[0]);
  };

  render() {
    if (this.state.hasError) {
      return <Error500 />;
    }
    return this.props.children;
  }
}

const ErrorBoundaryConnected = connect(ErrorBoundary, [
  'userStore',
  'dealerStore',
]);

function ErrorBoundaryMain(props) {
  return <ErrorBoundaryConnected {...props} />;
}

export default ErrorBoundaryMain;
