import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ProposalOrderDelivery from 'services/proposalOrderDelivery';

type PayloadType = {
  deliveryDate: any;
  endInterval: any;
  startInterval: any;
  usersiteId: any;
};

type CreateDeliveryType = {
  id: number;
  payload: PayloadType;
  dealerId: number;
};

const useCreateDeliverySchedule = () => {
  const dispatch = useDispatch();

  return useMutation<any, any, CreateDeliveryType, unknown>(
    'create_posting',
    ({ id, dealerId, payload }) =>
      ProposalOrderDelivery.createScheduling(dealerId, id, payload),
    {
      onError: () => {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro inesperado.',
            true
          )
        );
      },
      onSuccess: data => {
        if (data.success) {
          dispatch(
            UIActionCreators.snackbarManagement(
              'success',
              'Agendamento realizado com sucesso!',
              true
            )
          );
          return;
        }
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            data?.data ?? 'Ocorreu um erro inesperado.',
            true
          )
        );
      },
    }
  );
};

export default useCreateDeliverySchedule;
