import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chip from 'modules/financing/components/atoms/Chip';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import RefreshIcon from '@material-ui/icons/Autorenew';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import handlePDFBlobFile from 'helpers/handlePDFBlobFile';
import OrderServices from 'modules/order/services/OrderServices';
import Columns from 'constants/columns';
import CancelOrderDialog from './components/CancelOrderDialog';
import ReOpenOrderDialog from './components/ReOpenOrderDialog';
import { Title, ProposalId, Row, Container } from './styles';

import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import CancelOrderModal from 'components/CancelOrderModal';

const SubHeader = ({ cancelHandleOpen }) => {
  const { dealerId, proposalId, checkoutData, cancelInvoice } = useSelector(
    state => state.order
  );

  const { currentDealer } = useSelector(state => state.dealerStore);
  const hasMobioneProduct = currentDealer.products.find(
    product => product.type === 'MOBIONE'
  );

  const [openCancelOrderDialog, setOpenCancelOrderDialog] = useState<any>(
    false
  );
  const [openReOpenOrderDialog, setOpenReOpenOrderDialog] = useState<any>(
    false
  );

  const handleSearchActionClick = async () => {
    try {
      const result: any = await OrderServices.downloadContract(
        dealerId,
        proposalId
      );
      if (result?.success) {
        handlePDFBlobFile(
          result.data,
          `pedido-${dealerId}-${proposalId}`,
          true
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isCanceled = checkoutData?.status?.proposal === 'CANCELED';

  return (
    <Container>
      <Row>
        <Title>Pedido</Title>
        <ProposalId>{`#${proposalId}`}</ProposalId>
        <Chip
          type={Columns[checkoutData?.status?.proposal]?.chipType ?? 'error'}
        >
          {Columns[checkoutData?.status?.proposal]?.label ?? ''}
        </Chip>
      </Row>
      <Row>
        <HypertextButton onClick={cancelHandleOpen}>
          <RemoveRedEyeOutlinedIcon style={{ fontSize: 18, marginRight: 4 }} />
          <span>Ver notas Fiscais</span>
        </HypertextButton>
        {!isCanceled && (
          <>
            <HypertextButton onClick={handleSearchActionClick}>
              <PrintIcon style={{ fontSize: 18, marginRight: 4 }} />
              Imprimir Pedido
            </HypertextButton>
          </>
        )}
        <HypertextButton onClick={() => setOpenReOpenOrderDialog(true)}>
          <RefreshIcon
            style={{ fontSize: 18, marginRight: 4, transform: `rotate(45deg)` }}
          />
          Reabrir pedido
        </HypertextButton>
        {!isCanceled && (
          <HypertextButton onClick={() => setOpenCancelOrderDialog(true)} red>
            <CancelIcon style={{ fontSize: 18, marginRight: 5 }} />
            Cancelar Pedido
          </HypertextButton>
        )}
        {/* <HypertextButton>Compartilhar</HypertextButton> */}
      </Row>
      {openCancelOrderDialog &&
        (hasMobioneProduct && cancelInvoice?.data?.notaFiscalDeSaida ? (
          <CancelOrderModal
            open={openCancelOrderDialog}
            onClose={() => setOpenCancelOrderDialog(false)}
          />
        ) : (
          <CancelOrderDialog
            open={openCancelOrderDialog}
            onClose={() => setOpenCancelOrderDialog(false)}
          />
        ))}
      {openReOpenOrderDialog && (
        <ReOpenOrderDialog
          open={openReOpenOrderDialog}
          onClose={() => setOpenReOpenOrderDialog(false)}
        />
      )}
    </Container>
  );
};

export default SubHeader;
