import useTypingEffect from 'hooks/useTypingEffect';
import React from 'react';
import { Lead360Component } from './Lead360Component';
import useGetLead360 from './hooks/useGetLead360';

const Lead360Container = () => {
  const { message, isLoading } = useGetLead360();
  const { displayedText, isTitleComplete } = useTypingEffect(message);

  return (
    <Lead360Component
      message={displayedText}
      isLoading={isLoading}
      isTitleComplete={isTitleComplete}
    />
  );
};

export default Lead360Container;
