import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export default {
  getCurrentProposalById: function(currentProposalId: number) {
    return {
      type: ActionTypes.GET_CURRENT_PROPOSAL_BY_ID,
      payload: { currentProposalId },
    };
  },

  getBuyerCurrentProposalById: function(currentProposalId: number) {
    return {
      type: ActionTypes.GET_BUYER_CURRENT_PROPOSAL_BY_ID,
      payload: { currentProposalId },
    };
  },

  setCurrentProposal: function(currentProposal: any): Action {
    return { type: ActionTypes.SET_CURRENT_PROPOSAL, payload: currentProposal };
  },

  getSalesNegotiations: function(
    currentProposalId: number,
    archived: boolean
  ): Action {
    return {
      type: ActionTypes.GET_SALES_NEGOTIATION,
      payload: { currentProposalId, archived },
    };
  },

  getBuyerNegotiations: function(
    currentProposalId: number,
    archived: boolean
  ): Action {
    return {
      type: ActionTypes.GET_BUYER_NEGOTIATION,
      payload: { currentProposalId, archived },
    };
  },

  getSalesComboNegotiations: function(
    currentProposalId: number,
    archived: boolean
  ): Action {
    return {
      type: ActionTypes.GET_SALES_COMBO_NEGOTIATION,
      payload: { currentProposalId, archived },
    };
  },

  setNegotiations: function(data: any): Action {
    return {
      type: ActionTypes.SET_NEGOTIATION,
      payload: data,
    };
  },

  setNegotiationArchived: function(
    negotiationId: number,
    currentProposalId: number
  ): Action {
    return {
      type: ActionTypes.SET_NEGOTIATION_ARCHIVED,
      payload: { negotiationId, currentProposalId },
    };
  },

  updateSnackbarState: function(snackbarState: any): Action {
    return {
      type: ActionTypes.UPDATE_SNACKBAR_STATE,
      payload: snackbarState,
    };
  },

  sendAcceptBid: function(dealId: number, bid: number): Action {
    return {
      type: ActionTypes.SEND_ACCEPT_BID,
      payload: { dealId, bid },
    };
  },

  sendAcceptBidCombo: function(): Action {
    return {
      type: ActionTypes.SEND_ACCEPT_BID_COMBO,
      payload: {},
    };
  },

  sendConfirmAcceptBid: function(
    negotiationId: number,
    currentProposalId: number,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SEND_CONFIRM_ACCEPT_BID,
      payload: { negotiationId, currentProposalId, isCombo },
    };
  },

  sendConfirmBuyerAcceptBid: function(
    negotiationId: number,
    currentProposalId: number,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SEND_CONFIRM_BUYER_ACCEPT_BID,
      payload: { negotiationId, currentProposalId, isCombo },
    };
  },

  sendRefuseBid: function(
    negotiationId: number,
    currentProposalId: number,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SEND_REFUSED_BID,
      payload: { negotiationId, currentProposalId, isCombo },
    };
  },

  sendBuyerRefuseBid: function(
    negotiationId: number,
    currentProposalId: number,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SEND_BUYER_REFUSED_BID,
      payload: { negotiationId, currentProposalId, isCombo },
    };
  },

  sendNewBid: function(
    negotiationObject: any,
    currentProposalId: number,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SEND_NEW_BID,
      payload: { negotiationObject, currentProposalId, isCombo },
    };
  },

  sendBuyerNewBid: function(
    negotiationObject: any,
    currentProposalId: number,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SEND_BUYER_NEW_BID,
      payload: { negotiationObject, currentProposalId, isCombo },
    };
  },

  getInteractions: function(
    currentProposalId: number,
    negotiationId: number,
    lastInteractionId: number
  ): Action {
    return {
      type: ActionTypes.GET_INTERACTIONS,
      payload: {
        negotiationId,
        currentProposalId,
        lastInteractionId,
      },
    };
  },

  setInteractions: function(
    interactions: Array<any>,
    negotiationId: number
  ): Action {
    return {
      type: ActionTypes.SET_INTERACTIONS_SUCCESS,
      payload: { interactions, negotiationId },
    };
  },

  sendInteraction: function(
    proposalResaleInteraction: any,
    currentProposalId: number,
    negotiationId: number,
    mediaType: string,
    isCombo: boolean,
    lastInteractionId: number
  ): Action {
    return {
      type: ActionTypes.SEND_INTERACTION,
      payload: {
        proposalResaleInteraction,
        currentProposalId,
        negotiationId,
        mediaType,
        isCombo,
        lastInteractionId,
      },
    };
  },

  sendBuyerInteraction: function(
    proposalResaleInteraction: any,
    currentProposalId: number,
    mediaType: string,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SEND_BUYER_INTERACTION,
      payload: {
        proposalResaleInteraction,
        currentProposalId,
        mediaType,
        isCombo,
      },
    };
  },

  manageHigherBidDialog: function(open: boolean): Action {
    return {
      type: ActionTypes.MANAGE_HIGHER_BID_DIALOG,
      payload: { open },
    };
  },

  manageConfirmationDialog: function(
    open: boolean,
    isAccept: boolean,
    isRefused: boolean
  ): Action {
    return {
      type: ActionTypes.MANAGE_CONFIRMATION_DIALOG,
      payload: { open, isAccept, isRefused },
    };
  },

  visualizeMessages: function(
    negotiationId: number,
    currentProposalId: number,
    fromBuyer: boolean
  ): Action {
    return {
      type: ActionTypes.VISUALIZE_MESSAGES,
      payload: { negotiationId, currentProposalId, fromBuyer },
    };
  },

  updateProposal: function(
    currentProposalId: number,
    vehicles: Array<any>,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.UPDATE_PROPOSAL,
      payload: { currentProposalId, vehicles, isCombo },
    };
  },

  setSellerToNegotiation: function(
    currentProposalId: number,
    negotiationId: number,
    sellerId: number,
    isCombo: boolean
  ): Action {
    return {
      type: ActionTypes.SET_SELLER_TO_NEGOTIATION,
      payload: {
        currentProposalId,
        negotiationId,
        sellerId,
        isCombo,
      },
    };
  },
};
