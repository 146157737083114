import { UsedVehicle } from './UsedVehicle';
import { PaymentDmsStatus } from './ProposalOrder';

export enum InstallmentsTypeEnum {
  OUTROS = 'OUTROS',
  CONSORCIO = 'CONSORCIO',
  FINANCEIRA = 'FINANCEIRA',
  FINANCIAMENTO = 'FINANCIAMENTO',
  VEICULO_USADO = 'VEICULO_USADO',
  TROCO = 'TROCO',
}

export enum TypeOfSale {
  FINANCED = 'FINANCED',
  IN_CASH = 'IN_CASH',
}

export type InstallmentsType = {
  id: number;
  name: InstallmentsTypeEnum;
  label: string;
};

export type OrderPaymentType = {
  id: number;
  name: string;
  installmentsType: InstallmentsTypeEnum;
  shouldHaveDiscount?: boolean;
  active: boolean;
  dueDays: number;
  unique: boolean;
  startInternalFinancing: boolean;
};

export type OrderPayment = {
  id?: number;
  position: number;
  paymentType: OrderPaymentType;
  value: number;
  financingValue?: number;
  date: string;
  dueDate: number;
  installmentDiscountItem?: OrderPayment;
  dealerPJFinancialInstitution?: DealerPJFinancialInstitution;
  paymentTypeInstallmentsType?: string;
  consorcioCota?: number;
  consorcioGrupo?: number;
  usedVehicle?: UsedVehicle;
  comments: string;
  financingTerm: string;
  financingInstallments: number;
  paymentDmsStatus?: PaymentDmsStatus;
};

export type DealerPJFinancialInstitution = {
  id: number;
  cnpj: number;
  name: string;
};
