import { QueueDataTypes } from '../store/reducers';
import { OrderPayment, TypeOfSale } from './OrderPayment';
import { OrderProduct } from './OrderProduct';
import { ProposalVehicleDto } from './ProposalVehicleDto';

export enum ProposalOrderStatus {
  NEW = 'NEW',
  IN_NEGOTIATION = 'IN_NEGOTIATION',
  FINISHED_WITH_SOLD = 'FINISHED_WITH_SOLD',
  FINISHED_WITHOUT_SOLD = 'FINISHED_WITHOUT_SOLD',
}

enum GeneralOrderWithOEMStep {
  ATTENDANCE = 0,
  PROPOSAL_ORDER_OEM = 1,
  ORDER = 2,
  CHECKOUT = 3,
}

enum GeneralOrderStep {
  ATTENDANCE = 0,
  ORDER = 1,
  CHECKOUT = 2,
}

export const getGeneralOrderStep = (hasProposalOEM: boolean) =>
  hasProposalOEM ? GeneralOrderWithOEMStep : GeneralOrderStep;

//Enum está na ordem dos passos
export enum ProposalOrderStep {
  //Proposal data
  VEHICLE = 'VEHICLE',
  PAYMENT = 'PAYMENT',
  COMMENTS = 'COMMENTS',
  //Client data
  PERSON = 'PERSON',
  ADDRESS = 'ADDRESS',
  REFERENCE = 'REFERENCE',
  OCCUPATION = 'OCCUPATION',
  COMPANY = 'COMPANY',
  BUYER_FINANCING_COMPANY = 'BUYER_FINANCING_COMPANY',
  BUYER_FINANCING_PERSON = 'BUYER_FINANCING_PERSON',
  BUYER_FINANCING_ADDRESS = 'BUYER_FINANCING_ADDRESS',
  BUYER_FINANCING_REFERENCE = 'BUYER_FINANCING_REFERENCE',
  BUYER_FINANCING_OCCUPATION = 'BUYER_FINANCING_OCCUPATION',
  BUYER_INCASH_PERSON = 'BUYER_INCASH_PERSON',
  BUYER_INCASH_COMPANY = 'BUYER_INCASH_COMPANY',
  //Proposal data_Queue
  VEHICLE_QUEUE = 'VEHICLE_QUEUE',
  PAYMENT_QUEUE = 'PAYMENT_QUEUE',
  DELIVERY = 'DELIVERY',
  COMMENTS_QUEUE = 'COMMENTS_QUEUE',
  //Client data Queue
  PERSON_QUEUE = 'PERSON_QUEUE',
  SUMMARY = 'SUMMARY',
  CLIENT_DATA = 'CLIENT_DATA',
}

export enum PaymentDmsStatus {
  EMITTED = 'EMITTED',
  NOT_EMITTED = 'NOT_EMITTED',
  RECEIVED = 'RECEIVED',
}

export type ProposalOrder = {
  id: number;
  dealerName: string;
  dealerCityName: string;
  cpf: number;
  typeOfSale: TypeOfSale;
  salePrice: number;
  saleDate: string;
  saleComments: string;
  vehiclesOrder: ProposalVehicleDto[];
  payments: OrderPayment[];
  products: OrderProduct[];
  birthDate: number;
  motherName: string;
  rgNumber: string;
  rgIssuedAt: number;
  rgIssuedState: string;
  rgIssuer: string;
  nationality: string;
  gender: string;
  civilStatus: string;
  zipcode: string;
  cityId: number;
  cityName: string;
  cityStateId: string;
  streetType: string;
  address: string;
  number: string;
  neighborhood: string;
  referenceBankId: number;
  referenceBankAgency: number;
  referenceBankAgencyDigit: number;
  referenceBankAccount: number;
  referenceBankAccountType: string;
  referenceName: string;
  referencePhone: string;
  referenceRelationship: string;
  monthlyIncome: number;
  careerId: number;
  employmentStartDate: number;
  employmentId: number;
  employmentCityId: number;
  employmentZipcode: string;
  employmentStreetType: string;
  employmentAddress: string;
  employmentNumber: string;
  employmentNeighborhood: string;
  employmentPhone: string;
  sellerName: string;
  cpfCnpj: string;
  percentageCommissionVDVI?: number;
  step: ProposalOrderStep;
  orderStatus: ProposalOrderStatus;
  queueData: QueueDataTypes;
  getGeneralOrderStep: (
    hasProposalOEM: boolean
  ) => GeneralOrderWithOEMStep | GeneralOrderStep;
  validity: string;
  manufactorDiscountValue?: number;
  manufactorBonusValue?: number;
};
