import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import TextField from 'modules/financing/components/atoms/TextField';
import styled from 'styled-components';

export const ListLoading = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-left: 16px;
    font-size: 14px;
    color: #555;
  }
`;

const CancelBlockedComponent = ({
  open,
  onClose,
  reason,
  handleChangeValue,
  cancelBlockVehicle,
  isLoading,
}) => {
  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title={'Cancelar Bloqueio'}
      maxWidth="sm"
      DialogAction={() => (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'end',
          }}
        >
          <OutlinedButton onClick={onClose}>Cancelar</OutlinedButton>
          <div style={{ width: 8 }} />
          <ThemeButton
            onClick={isLoading ? () => null : cancelBlockVehicle}
            disabled={!reason || isLoading}
          >
            Confirmar
            {isLoading && <Progress small />}
          </ThemeButton>
        </div>
      )}
    >
      {isLoading ? (
        <ListLoading>
          <CircularProgress />
          <span>Desbloqueando...</span>
        </ListLoading>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            Deseja cancelar o bloqueio?
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Motivo:"
              multiline
              rows="4"
              value={reason}
              onChange={handleChangeValue}
              name="reason"
              variant="outlined"
              inputProps={{ maxLength: 500 }}
              helperText={`${reason?.length || 0}/500`}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </GenericDialog>
  );
};

export default CancelBlockedComponent;
