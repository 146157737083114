import styled from 'styled-components'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled.div`
  display: flex;
  cursor: pointer;
`

export const styles = {
  root: {
    borderBottom: `1px solid #EEEEEE`,
    padding: `24px`,
  },
  title: {
    borderBottom: `1px solid #EEEEEE`,
  },
}
