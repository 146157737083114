import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextMaskCustom, CPFCNPJMaskCustom } from 'components/MaskTextInput';
import TextField from 'modules/financing/components/atoms/TextField';

const SimpleClientForm = ({ clientCurrent = {} }: { clientCurrent?: any }) => {
  return (
    <>
      <Grid container spacing={1} direction="row">
        <Grid item xs={6}>
          <TextField
            placeholder="Nome completo"
            label="Nome"
            name="name"
            value={clientCurrent.name}
            disabled={true}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="CPF/CNPJ"
            name="cpfCnpj"
            placeholder="CPF"
            value={clientCurrent.cpfCnpj}
            InputProps={{
              inputComponent: CPFCNPJMaskCustom,
            }}
            disabled={true}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Telefone 1"
            name="phone"
            placeholder="Telefone 1"
            value={clientCurrent.phone?.toString()}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Telefone 2"
            name="phone2"
            placeholder="Telefone 2"
            value={clientCurrent?.phone2?.toString()}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="E-mail"
            label="E-mail"
            name="email"
            type="email"
            value={clientCurrent.email}
            disabled={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SimpleClientForm;
