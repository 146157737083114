import React, { Fragment, lazy, useState } from 'react';
import { Grid } from '@material-ui/core';

import lazyRetry from 'helpers/lazyRetry';
import { useChatContext } from 'pages/chats/ChatsContext';

import ItemHeader from '../atoms/ItemHeader';
import Schedules from './Schedules';

import * as S from './styles';

const AddSchedule = lazy(() =>
  lazyRetry(() => import('components/AddSchedule'))
);

export default function Scheduling({ closedProposal = () => null }) {
  const [openModalAddScheduled, setOpenModalAddScheduled] = useState<boolean>(
    false
  );

  const { currentProposal, getCurrentProposal }: any = useChatContext();

  const handleClickOpenModalAddScheduling = () => {
    return setOpenModalAddScheduled(!openModalAddScheduled);
  };

  const handleClickCloseModalAddScheduling = () => {
    return setOpenModalAddScheduled(false);
  };

  return (
    <Fragment>
      <ItemHeader>Agendamento</ItemHeader>
      <S.ContainerScheduling>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ width: '100%' }}
        >
          {currentProposal.scheduleds &&
            currentProposal.scheduleds.map((item, id) => {
              return (
                <Schedules
                  key={id}
                  id={id}
                  item={item}
                  seller={currentProposal?.sellerName}
                  callbackOnSuccess={() => getCurrentProposal()}
                />
              );
            })}
        </Grid>
        {!closedProposal && (
          <S.Button onClick={handleClickOpenModalAddScheduling}>
            Agendar contato
          </S.Button>
        )}
      </S.ContainerScheduling>
      {openModalAddScheduled && (
        <AddSchedule
          open={openModalAddScheduled}
          handleClose={handleClickCloseModalAddScheduling}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() => {
            getCurrentProposal();
            handleClickCloseModalAddScheduling();
          }}
        />
      )}
    </Fragment>
  );
}
