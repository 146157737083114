import { Action } from 'types/ActionTypes';
import { DealerFromStore } from 'components/withDealer';
import ActionTypes from './actionTypes';
import manageLocalStorage from 'pages/callCenter/utils/manageLocalStorage';
import { DATES_BY_PERIOD } from 'helpers/predefinedDates';

export const defaultNonReceivedValue = null;

export const INITIAL_STATE: any = {
  currentGroup: DealerFromStore.getDealerGroupFromLocalStorage() || {
    id: null,
    name: null,
  },
  currentUser: {
    userType: 'SALES_ASSISTENT',
    subGroups: [],
  },
  proposals: {
    content: [],
  },
  proposalsIsLoading: false,
  proposalsIsError: false,
  //FILTERS
  filters: {
    periodId: manageLocalStorage.getFilter('periodId') ?? 'yesterDayAndToday',
    periodInit:
      DATES_BY_PERIOD?.[
        manageLocalStorage.getFilter('periodId')
      ]?.startDate.valueOf() ?? DATES_BY_PERIOD.yesterDay.startDate.valueOf(),
    periodFinal:
      DATES_BY_PERIOD?.[
        manageLocalStorage.getFilter('periodId')
      ]?.endDate.valueOf() ?? DATES_BY_PERIOD.today.endDate.valueOf(),
    query: null,
    deal0km: null,
    salesAssistantId: manageLocalStorage.getFilter('salesAssistantId'),
    dealerId: manageLocalStorage.getFilter('dealerId') || [],
    departmentCode: manageLocalStorage.getFilter('departmentCode') || null,
    origin: manageLocalStorage.getFilter('origin'),
    providerId: manageLocalStorage.getFilter('providerId'),
    tags: manageLocalStorage.getFilter('tags') || [],
  },
};

const updateProposalById = (proposals, newProposalData) => {
  const proposalsAux = proposals;
  const index = proposalsAux.findIndex(item => item.id === newProposalData.id);

  //find the element into content
  proposalsAux.splice(index, 1);
  //remove the element into content
  proposalsAux.splice(index, 0, newProposalData);
  //add new element into content

  return [...proposalsAux];
  //set content
};

export default function lead(state: any = INITIAL_STATE, action: Action): any {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_GROUP: {
      let currentGroup = action.payload;
      if (!currentGroup) {
        currentGroup = DealerFromStore.getDealerGroupFromLocalStorage();
      }
      return { ...state, currentGroup: currentGroup };
    }
    case ActionTypes.UPDATE_PROPOSAL_BY_ID:
      return {
        ...state,
        proposals: {
          ...state.proposals,
          content: updateProposalById(state.proposals.content, action.payload),
        },
      };
    case ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_SUCCESS:
      return { ...state, proposals: action.payload };
    case ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_ERROR:
      return { ...state, proposalsIsError: action.payload };
    case ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_LOADING:
      return { ...state, proposalsIsLoading: action.payload };
    case ActionTypes.MANAGEMENT_FILTER:
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case ActionTypes.SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case ActionTypes.CLEAN_STORE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
