import React from 'react';
import Chip from 'modules/financing/components/atoms/Chip';
import { Container, Title, SubTitle } from './styles';
import { userType, highAccessUserTypes } from 'constants/userAccess';
import isEmpty from 'helpers/isEmpty';
import ThemeButton from 'components/atoms/ThemeButton';
import creditScoreTypes from 'modules/financing/helpers/creditScoreTypes';

const ApprovalUserCard = ({
  userData,
  checkoutData,
  currentUser,
  setOpenEvaluationProposalDialog,
}) => {
  const { userName, userPhone, userEmail, status, dealerPJUserType } = userData;

  const checkUserAccepOrder = () => {
    return (
      isEmpty(checkoutData?.pendencies) &&
      currentUser.userName === userName &&
      !['APPROVED'].includes(checkoutData?.status?.approvals) &&
      !['APPROVED'].includes(status) &&
      ['NOT_APPLICABLE', 'APPROVED'].includes(checkoutData?.status.financing) &&
      (!highAccessUserTypes.includes(currentUser.dealerPJUserType)
        ? currentUser?.profiles?.includes('APPROVE_PROPOSAL_ORDER')
        : highAccessUserTypes.includes(currentUser.dealerPJUserType))
    );
  };

  return (
    <Container>
      <div>
        <Title>{`${userName} | ${userType[dealerPJUserType].label}`}</Title>
        <SubTitle>{userEmail}</SubTitle>
        <SubTitle>{userPhone}</SubTitle>
      </div>
      <div style={{ width: '61px' }} />
      <div>
        {checkUserAccepOrder() ? (
          <ThemeButton
            onClick={event => {
              event.stopPropagation();
              setOpenEvaluationProposalDialog(true);
            }}
          >
            Aprovar pedido
          </ThemeButton>
        ) : (
          <Chip type={creditScoreTypes[status]?.type}>
            {creditScoreTypes[status]?.name}
          </Chip>
        )}
      </div>
    </Container>
  );
};

export default ApprovalUserCard;
