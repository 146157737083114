import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import { Desktop, Mobile } from 'components/Responsive';
import MODE from 'constants/editInLineTypes';
import {
  Container,
  Item,
  LabelInfo,
  LabelBold,
  AlertStyled,
  Label,
} from './styles';

function ErrorMode({ handleOnChangeMode }) {
  return (
    <React.Fragment>
      <Desktop>
        <Container>
          <ListItem>
            <Item>
              <LabelInfo>Informações</LabelInfo>
              <LabelBold>
                <AlertStyled />
                Ops! aconteceu um erro ao salvar
              </LabelBold>
              <Label> Tente novamente mais tarde</Label>
            </Item>
          </ListItem>
          <ListItem>
            <Item>
              <Button
                variant="outlined"
                onClick={() => handleOnChangeMode(MODE.VISUALIZING)}
                style={{ marginRight: '16px' }}
              >
                Sair
              </Button>
            </Item>
            <Item>
              <Button onClick={() => handleOnChangeMode(MODE.EDITING)}>
                Tentar novamente
              </Button>
            </Item>
          </ListItem>
        </Container>
      </Desktop>
      <Mobile>
        <Container>
          <ListItem>
            <Item>
              <LabelInfo>Informações</LabelInfo>
              <LabelBold>Ops! aconteceu um erro ao salvar</LabelBold>
              <Label> Tente novamente mais tarde</Label>
            </Item>
          </ListItem>
          <ListItem>
            <Item>
              <Button
                variant="outlined"
                onClick={() => handleOnChangeMode(MODE.VISUALIZING)}
                style={{ marginRight: '16px' }}
              >
                Sair
              </Button>
            </Item>
            <Item>
              <Button onClick={() => handleOnChangeMode(MODE.EDITING)}>
                Tentar novamente
              </Button>
            </Item>
          </ListItem>
        </Container>
      </Mobile>
    </React.Fragment>
  );
}

export default ErrorMode;
