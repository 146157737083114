import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'FinancingService';
const domain = EnvVars('API_OPEN_FINANCING_BASE_URL');

interface GetFinancingSimulateParams {
  dealerId: number;
  page?: number;
  proposalId: number;
  size?: number;
}

interface SimulationFinancingParams {
  dealerId: number;
  proposalId: number;
  trimId?: number;
  dealId?: number;
  payload: {
    modelYear: number;
    vehiclePrice: number;
    cpf: number;
    name: string;
    email: string;
    phone: number;
    inputValue: number;
  };
}

export default class FinancingService {
  static getFinancingSimulate = ({
    dealerId,
    page = 0,
    proposalId,
    size = 10,
  }: GetFinancingSimulateParams) => {
    const params = {
      dealerId,
      page,
      proposalId,
      size,
    };

    return request
      .get(`${domain}/financing/v1.0/simulation`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.simulationFinancing()`) as any
      );
  };

  static simulationFinancing = ({
    dealerId,
    proposalId,
    trimId,
    dealId,
    payload,
  }: SimulationFinancingParams) => {
    return request
      .post(
        `${domain}/financing/v1.0/simulation?dealerId=${dealerId}&proposalId=${proposalId}&${
          trimId ? `trimId=${trimId}` : `dealId=${dealId}`
        }`,
        payload
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.simulationFinancing()`) as any
      );
  };
}
