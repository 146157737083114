import React from 'react';
import Grid from '@material-ui/core/Grid';
import Item from './Item';

const ViewComponent = ({ onCheckClick, dealerId, deals, checkeds }) => {
  return (
    <Grid container spacing={1}>
      {deals &&
        deals.map((deal, id) => {
          return (
            <Grid key={`${deal.trimName}_${id}_${deal.id}`} item xs={12}>
              <Item
                deal={deal}
                dealerId={dealerId}
                onCheckClick={onCheckClick}
                checkeds={checkeds}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default React.memo(ViewComponent);
