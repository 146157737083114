import styled, { css } from 'styled-components';
import { Divider as DividerMaterial } from '@material-ui/core';

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubTitle = styled.b`
  font-size: 14px;
`;

export const BookInfo = styled.p`
  font-size: 14px;
  color: #757575;
  line-height: 1em;
  display: flex;
  align-items: center;
`;

export const Divider = styled(DividerMaterial)`
  margin: 16px 0 !important;

  ${props =>
    props.end &&
    css`
      margin: 16px 0 0 0 !important;
    `}
`;

export const VericalDivider = styled.div`
  width: 1px;
  height: 100%;
  background: #e0e0e0;
  margin-left: auto;
  margin-right: 16px;
`;

export const ListLoading = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-left: 16px;
    font-size: 14px;
    color: #555;
  }
`;

export const Avatar = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  text-transform: uppercase;
  background-color: #eeeeee;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  font-size: 16px;
`;
