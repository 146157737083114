import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import EditIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import formatAmount from 'helpers/formatAmount';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import {
  InstallmentsType,
  InstallmentsTypeEnum,
  OrderPayment,
  OrderPaymentType,
} from 'modules/order/types/OrderPayment';
import RemovePaymentConfirmDialog from './RemovePaymentConfirmDialog/RemovePaymentConfirmDialog';
import FinancingProductsDialog from './AddPaymentDialog/FinancingProducts/FinancingProductsDialog';
import AddPaymentDialog from './AddPaymentDialog';

import * as S from './styles';

interface Props {
  paymentTypes: OrderPaymentType[];
  installmentsTypes: InstallmentsType[];
  isFinished?: boolean;
}

export default function PaymentListComponent({
  paymentTypes,
  installmentsTypes,
  isFinished = false,
}: Props) {
  const {
    paymentData: { payments },
  }: {
    paymentData: { payments: OrderPayment[] };
  } = useSelector(state => state.order);

  const { currentDealer } = useSelector(state => state.dealerStore);
  const { financialAdvanceOnDms } = currentDealer;

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [addDialogProps, setAddDialogProps] = useState({});
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
  const [paymentsToRemove, setPaymentsToRemove] = useState<OrderPayment[]>([]);

  const dispatch = useDispatch();

  const findIndexByPosition = (payments: OrderPayment[], position: number) => {
    return payments.findIndex(payment => payment.position === position);
  };

  const isTroco = (payment: OrderPayment) => {
    return payment.paymentType.installmentsType == InstallmentsTypeEnum.TROCO;
  };

  const reorderPayments = (paymentList: OrderPayment[]) => {
    return paymentList.sort((paymentA, paymentB) =>
      isTroco(paymentA) == isTroco(paymentB) ? 0 : isTroco(paymentA) ? 1 : -1
    );
  };

  const setPaymentIndexToPosition = (payment: OrderPayment, index: number) => {
    return {
      ...payment,
      position: index + 1,
    };
  };

  const updateInstallmentDiscountPosition = (
    paymentList: OrderPayment[],
    installmentDiscount: OrderPayment
  ) => {
    const paymentIndex = findIndexByPosition(
      paymentList,
      installmentDiscount.position
    );
    return setPaymentIndexToPosition(installmentDiscount, paymentIndex);
  };

  const updatePositions = (paymentList: OrderPayment[]) => {
    const positionsUpdated = paymentList
      .map(payment => {
        if (payment?.installmentDiscountItem) {
          const installmentDiscountItem = updateInstallmentDiscountPosition(
            paymentList,
            payment?.installmentDiscountItem
          );
          return { ...payment, installmentDiscountItem };
        }
        return payment;
      })
      .map((payment, index) => setPaymentIndexToPosition(payment, index));

    return positionsUpdated;
  };

  const setPaymentList = (paymentList: OrderPayment[]) => {
    const reorderedList = reorderPayments(paymentList);
    const updatedList = updatePositions(reorderedList);

    dispatch(ActionCreators.setPaymentData({ payments: updatedList }));
  };

  const handleOnCloseAddDialog = (payment?: OrderPayment) => {
    if (payment) {
      const isEdit = payments.some(row => row.position === payment.position);

      if (isEdit) {
        const newPaymentList = [
          ...payments.filter(row => row.position !== payment.position),
          payment,
        ];
        const sortedPaymentList = newPaymentList.sort(
          (a, b) => a.position - b.position
        );

        setPaymentList(sortedPaymentList);
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Pagamento alterado com sucesso!'
          )
        );
      } else {
        setPaymentList([...payments, payment]);
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Pagamento adicionado com sucesso!'
          )
        );
      }
    }
    setAddDialogProps({});
    setOpenAddDialog(false);
  };

  const handleOnCloseConfirmRemoveDialog = (confirm: boolean) => {
    if (confirm) {
      setPaymentList(
        payments.filter(
          (payment: OrderPayment) =>
            !paymentsToRemove.some(
              toRemove => toRemove.position === payment.position
            )
        )
      );
    }
    setPaymentsToRemove([]);
    setOpenConfirmRemoveDialog(false);
  };

  const handleOnDelete = (rowToDelete: OrderPayment) => {
    const linkedPayments = payments.filter(
      (payment: OrderPayment) =>
        payment.installmentDiscountItem?.position === rowToDelete.position
    );

    if (!linkedPayments.length)
      setPaymentList(payments.filter(row => row != rowToDelete));
    else {
      setPaymentsToRemove([rowToDelete, ...linkedPayments]);
      setOpenConfirmRemoveDialog(true);
    }
  };

  const handleOnEdit = (rowToEdit: OrderPayment) => {
    const linkedPayments = payments.filter(
      (payment: OrderPayment) =>
        payment.installmentDiscountItem?.position === rowToEdit.position
    );

    if (!linkedPayments.length) {
      setAddDialogProps({
        nextPosition: rowToEdit.position,
        editPayment: rowToEdit,
      });
      setOpenAddDialog(true);
    } else {
      setPaymentsToRemove([rowToEdit, ...linkedPayments]);
      setOpenConfirmRemoveDialog(true);
    }
  };

  const getNextPosition = () => {
    if (!payments.length) return 1;

    return (
      Math.max(...payments.map((payment: OrderPayment) => payment.position)) + 1
    );
  };

  const handleOnOpenAddDialog = () => {
    setAddDialogProps({
      nextPosition: getNextPosition(),
    });
    setOpenAddDialog(true);
  };

  const getPaymentValue = (payment: OrderPayment) => {
    return isTroco(payment) ? (
      <S.PaymentValue variant="subtract">
        - {formatAmount(payment.value, 'PT-BR', 'R$')}
      </S.PaymentValue>
    ) : (
      <S.PaymentValue variant="add">
        + {formatAmount(payment.value, 'PT-BR', 'R$')}
      </S.PaymentValue>
    );
  };

  const getInstallmentsTypeLabelByName = (
    name: InstallmentsTypeEnum
  ): string => {
    const type = installmentsTypes.find(type => type.name === name);
    return type?.label || name;
  };

  const checkEditAndDeletePayment = (paymentDmsStatus: any) => {
    if (
      (paymentDmsStatus === 'EMITTED' || paymentDmsStatus === 'RECEIVED') &&
      financialAdvanceOnDms
    ) {
      return false;
    }
    return (
      paymentDmsStatus === 'NOT_EMITTED' ||
      !paymentDmsStatus ||
      !financialAdvanceOnDms
    );
  };

  const isFinanceira = payments.some(
    obj => obj.financingInstallments && obj.financingTerm
  );

  return (
    <>
      <S.PaymentTable>
        <Table>
          <TableHead>
            <TableRow>
              <S.HeaderCell>Nº</S.HeaderCell>
              <S.HeaderCell>Meio de pagamento</S.HeaderCell>
              <S.HeaderCell>Tipo de pagamento</S.HeaderCell>
              <S.HeaderCell>Valor</S.HeaderCell>
              <S.HeaderCell>Data</S.HeaderCell>
              <S.HeaderCell>Vencimento</S.HeaderCell>
              <S.HeaderCell>Parcela a abater</S.HeaderCell>
              <S.HeaderCell>Inst. Financeira</S.HeaderCell>
              <S.HeaderCell>{isFinanceira ? 'Parcela' : 'Grupo'}</S.HeaderCell>
              <S.HeaderCell>{isFinanceira ? 'Prazo' : 'Cota'}</S.HeaderCell>
              <S.HeaderCell>Veículo</S.HeaderCell>
              <S.HeaderCell></S.HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map(row => {
              const canEditAndDeletePayment = checkEditAndDeletePayment(
                row?.paymentDmsStatus
              );
              return (
                <TableRow key={row.position}>
                  <S.BodyCell>{row.position}</S.BodyCell>
                  <S.BodyCell>
                    {getInstallmentsTypeLabelByName(
                      row.paymentType?.installmentsType
                    )}
                  </S.BodyCell>
                  <S.BodyCell>{row.paymentType.name}</S.BodyCell>
                  <S.BodyCell>
                    <S.PaymentValueContent>
                      {getPaymentValue(row)}
                      {row.paymentType.startInternalFinancing &&
                        row.financingValue !== row.value && (
                          <FinancingProductsDialog
                            financingValue={row.financingValue}
                            totalValue={row.value}
                          />
                        )}
                    </S.PaymentValueContent>
                  </S.BodyCell>
                  <S.BodyCell>
                    {typeof row.date === 'number'
                      ? moment(row.date).format('DD/MM/YYYY')
                      : row.date}
                  </S.BodyCell>
                  <S.BodyCell>
                    {row.dueDate
                      ? moment(row.dueDate).format('DD/MM/YYYY')
                      : '-'}
                  </S.BodyCell>
                  <S.BodyCell>
                    {row.installmentDiscountItem?.position}
                  </S.BodyCell>
                  <S.BodyCell>
                    {row.dealerPJFinancialInstitution?.name}
                  </S.BodyCell>
                  <S.BodyCell>
                    {isFinanceira
                      ? row.financingInstallments
                      : row.consorcioGrupo}
                  </S.BodyCell>
                  <S.BodyCell>
                    {isFinanceira ? row.financingTerm : row.consorcioCota}
                  </S.BodyCell>
                  <S.BodyCell>{row.usedVehicle?.plate}</S.BodyCell>
                  <S.BodyCell>
                    <Tooltip title="Alterar pagamento">
                      <IconButton
                        disabled={isFinished || !canEditAndDeletePayment}
                        onClick={() => handleOnEdit(row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir pagamento">
                      <IconButton
                        disabled={isFinished || !canEditAndDeletePayment}
                        onClick={() => handleOnDelete(row)}
                      >
                        <TrashIcon />
                      </IconButton>
                    </Tooltip>
                  </S.BodyCell>
                </TableRow>
              );
            })}
            <TableRow>
              {!payments.length ? (
                <S.BodyCell align="center" colSpan={12}>
                  <S.EmptyListText>
                    Você ainda não adicionou nenhuma forma de pagamento.
                  </S.EmptyListText>
                  <br />
                  <S.AddPaymentButton
                    onClick={handleOnOpenAddDialog}
                    variant="contained"
                  >
                    Adicionar pagamento
                  </S.AddPaymentButton>
                </S.BodyCell>
              ) : (
                <S.BodyCell align="left" colSpan={12}>
                  {!isFinished && (
                    <HypertextButton
                      onClick={handleOnOpenAddDialog}
                      style={{ width: 'fit-content', padding: 12 }}
                    >
                      + Adicionar um novo pagamento
                    </HypertextButton>
                  )}
                </S.BodyCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
        {openAddDialog && (
          <AddPaymentDialog
            open={openAddDialog}
            onClose={handleOnCloseAddDialog}
            paymentList={payments}
            installmentsTypes={installmentsTypes}
            paymentTypes={paymentTypes}
            {...addDialogProps}
          />
        )}
        {openConfirmRemoveDialog && (
          <RemovePaymentConfirmDialog
            open={openConfirmRemoveDialog}
            onClose={handleOnCloseConfirmRemoveDialog}
            removePaymentList={paymentsToRemove}
          />
        )}
      </S.PaymentTable>
    </>
  );
}
