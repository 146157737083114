import { useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import useDealerId from 'hooks/useDealerId';
import { getLeads } from 'pages/conversations/utils';
import { PeriodDate } from 'pages/conversations/interfaces';
import { selectedChatView } from 'pages/dashBoard/components/Container/contexts/atoms';

const useGetProposals = () => {
  const { filters, currentGroup } = useSelector(state => state.callCenter);
  const dealerId = useDealerId();
  const groupId = currentGroup?.id;
  const isCallCenter = useRecoilValue(selectedChatView) === 'CONVERSATIONS';
  const {
    departmentCode,
    channel,
    origin,
    tags,
    dealers,
    filterByDates: defaultDate,
    sellerId,
  } = useSelector(state => ({
    ...state.dealerStore,
    ...state.leads,
  }));

  const filterByDates: PeriodDate = {
    initialDate: isCallCenter
      ? filters.periodInit
      : defaultDate.initialDate || new Date().getTime(),
    finalDate: isCallCenter
      ? filters.periodFinal
      : defaultDate.finalDate || new Date().getTime(),
  };

  const params = isCallCenter
    ? {
        search: null,
        filterByDates,
        filterByActiveLead: true,
        groupId,
        isCallCenter,
        departmentCodes: filters.departmentCode,
        channel: filters.providerId,
        origin: filters.origin,
        tags: filters.tags,
        dealers: filters.dealerId,
        salesAssistantId: filters?.salesAssistantId,
      }
    : {
        search: null,
        filterByDates,
        filterByActiveLead: true,
        dealerId,
        isCallCenter,
        departmentCodes: departmentCode,
        channel,
        origin,
        tags,
        dealers,
        sellerId,
      };

  return useQuery({
    queryKey: 'get_leads',
    refetchOnWindowFocus: false,
    // enabled: false,
    queryFn: () => getLeads(params),
    // staleTime: Infinity,
  });
};

export default useGetProposals;
