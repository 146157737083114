import React, { useEffect, useState } from 'react';

import { TextFieldProps } from '@material-ui/core/TextField';
import TextField from 'modules/financing/components/atoms/TextField';

export interface ChangeCurrencyTextField {
  floatValue?: string;
  formattedValue: string;
  value: string;
}

type CurrencyTextField = TextFieldProps & {
  onChangeValue: (formatted: ChangeCurrencyTextField) => void;
  prefix?: string;
  placeholder?: string;
  variant?: 'filled' | 'outlined' | 'standard';
};

export default function CurrencyTextField({
  onChangeValue,
  prefix = 'R$ ',
  placeholder = 'R$ 0,00',
  variant = 'filled',
  ...props
}: CurrencyTextField) {
  const [value, setValue] = useState<string>(props.value as string);

  const dynamicCurrencyMask = (toFormat: string): ChangeCurrencyTextField => {
    let formattedValue = toFormat.replace(/\D/g, '');
    formattedValue = (Number(formattedValue) / 100).toFixed(2) + '';
    formattedValue = formattedValue.replace('.', ',');
    formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    const toParseFloat = formattedValue.split(',');
    const beforeDecimals = toParseFloat?.[0].replace(/\D/g, '');
    const afterDecimals = toParseFloat?.[1] ?? '00';
    const parsed = `${beforeDecimals}.${afterDecimals}`;

    return {
      floatValue: parsed,
      formattedValue: `${prefix}${formattedValue}`,
      value: toFormat,
    };
  };

  const handleChangeValue = currentValue => {
    const fixed = Number.isInteger(currentValue)
      ? currentValue.toFixed(2)
      : currentValue;

    const formatted = dynamicCurrencyMask(String(fixed));

    setValue(formatted.formattedValue);
    onChangeValue(formatted);
  };

  useEffect(() => {
    if (props?.value) handleChangeValue(props.value);
  }, [props?.value]);

  return (
    <TextField
      {...props}
      variant={variant}
      value={value}
      onChange={({ target }) => handleChangeValue(target.value)}
      placeholder={placeholder}
    />
  );
}
