import React, { useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import isEmpty from 'helpers/isEmpty';
import { aggregationsTypes } from 'helpers/sop';
import SearchInput from 'modules/financing/components/atoms/SearchInput';
import ListItem from './components/ListItem';
import { Container, FilterContainer, EmptyContainer } from './styles';

const PopoverFilter = ({
  filterType,
  selectedFilters,
  filtersOptions,
  onChange,
}) => {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState(filtersOptions);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClickFilter = () => {
    const filtred =
      filtersOptions?.filter(element =>
        String(element?.name || element?.id || '')
          ?.toLowerCase()
          .includes(query.toLowerCase())
      ) || [];
    setOptions([...filtred]);
  };

  useEffect(() => {
    handleClickFilter();
  }, [query]);

  return (
    <>
      <Container onClick={handleClick}>
        {aggregationsTypes[filterType].label}
        <ArrowDropDown />
      </Container>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FilterContainer>
          <SearchInput
            value={query}
            onChange={event => {
              setQuery(event.target.value);
            }}
            placeholder={aggregationsTypes[filterType].placeholder}
            searchFunction={handleClickFilter}
          />
          <div style={{ maxHeight: 200, overflowY: 'auto' }}>
            {isEmpty(options) ? (
              <EmptyContainer>Nenhum resultado encontrado.</EmptyContainer>
            ) : (
              options?.map(option => {
                return (
                  <ListItem
                    key={option.id}
                    option={option}
                    isChecked={(selectedFilters || []).includes(option.id)}
                    onChange={() =>
                      onChange(aggregationsTypes[filterType].sopKey, option.id)
                    }
                  />
                );
              })
            )}
          </div>
        </FilterContainer>
      </Popover>
    </>
  );
};

export default PopoverFilter;
