import React, { useState, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ReactComponent as EmptyTemplateMessages } from 'assets/empty-messages-template.svg';
import MessageTypes from 'constants/MessageTypes';
import ProposalMessage from 'components/ProposalMessage';
import * as S from './styles';

const ViewTextComponent = ({
  currentChannel,
  currentProposal,
  interactions,
  messagesMobiSmartChat,
  handleClickSuggestion,
  displaySuggestions,
  loadingSuggestions,
  canSendWhatsAppBusinessMessage,
}) => {
  const LIST_REF = useRef(null);
  const OUTER_LIST_REF = useRef(null);
  const audiosRef = useRef(null);
  const rowHeights = useRef(null);

  const [currentAudioPlaying, setCurrentAudioPlaying] = useState([]);
  const [onBottomList, setOnBottomList] = useState(false);
  const [messageFocus, setMessageFocus] = useState(null);

  const setRowHeight = (index, size) => {
    LIST_REF.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const getRowHeight = index => {
    return rowHeights?.current?.[index] || 0;
  };

  const scrollToBottomLastInteraction = () => {
    if (LIST_REF?.current) {
      setOnBottomList(true);
      LIST_REF.current.scrollToItem(interactions.length, 'end');
    }
  };

  function onScroll({ scrollDirection, scrollUpdateWasRequested }) {
    const ON_END_LIST =
      OUTER_LIST_REF.current?.scrollHeight -
        OUTER_LIST_REF.current?.scrollTop ===
      OUTER_LIST_REF.current?.clientHeight;
    if (scrollDirection === 'forward' && ON_END_LIST) {
      setOnBottomList(true);
      return;
    }
    if (!scrollUpdateWasRequested && scrollDirection === 'backward') {
      setOnBottomList(false);
      return;
    }
  }

  useEffect(() => {
    if (onBottomList && LIST_REF?.current) {
      setTimeout(() => scrollToBottomLastInteraction(), 250);
    }
  }, [
    onBottomList,
    LIST_REF.current,
    interactions.length,
    loadingSuggestions,
    messagesMobiSmartChat?.suggestion,
  ]);

  const Row = ({ index, style }) => {
    const rowRef = useRef(null);

    useEffect(() => {
      if (rowRef?.current?.clientHeight) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef, interactions.length]);

    const getMessageType = item => {
      if (item?.originalProposalInteraction) return MessageTypes.RESPONSE;
      if (item?.media) return item.media.type;

      return MessageTypes.TEXT;
    };

    const type = getMessageType(interactions[index]);

    const scrollToMessage = msgIdx => {
      setMessageFocus(msgIdx);
      LIST_REF.current.scrollToItem(msgIdx, 'center');
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        setMessageFocus(null);
      }, 1000);

      return () => clearTimeout(timeout);
    }, [1000, messageFocus]);

    return (
      <div style={style}>
        <ProposalMessage
          currentRef={rowRef}
          message={interactions[index]}
          type={type}
          clientName={currentProposal.clientUserName}
          sellerName={currentProposal.sellerName}
          currentChannel={currentChannel}
          currentAudioPlaying={currentAudioPlaying}
          setCurrentAudioPlaying={setCurrentAudioPlaying}
          audiosRef={audiosRef}
          messageFocus={messageFocus}
          focusOnTheMessage={scrollToMessage}
        />
      </div>
    );
  };

  return (
    <S.WrapperBg isWhatsApp={currentChannel === 'WHATSAPP'}>
      {currentChannel === 'WHATSAPP' && <S.Bg />}
      <S.Container>
        {interactions.length ? (
          <>
            <S.AutoSizerContainer>
              <AutoSizer>
                {({ width, height }) => (
                  <>
                    <List
                      height={height}
                      width={width}
                      itemCount={interactions.length}
                      itemSize={getRowHeight}
                      ref={LIST_REF}
                      outerRef={OUTER_LIST_REF}
                      onScroll={onScroll}
                    >
                      {Row}
                    </List>
                  </>
                )}
              </AutoSizer>
              {!!interactions.length && !onBottomList && (
                <S.GoToBottomButton onClick={scrollToBottomLastInteraction}>
                  <ArrowDownwardIcon />
                </S.GoToBottomButton>
              )}
            </S.AutoSizerContainer>
            {loadingSuggestions && (
              <>
                <S.ContainerSuggestionsMessages>
                  {[0, 1].map(skeleton => (
                    <S.SuggestionsMessages key={skeleton}>
                      <Skeleton
                        width="150px"
                        height="16px"
                        variant="rect"
                        animation="wave"
                        style={{
                          borderRadius: '8px',
                          backgroundColor: 'rgb(0 0 0 / 7%)',
                        }}
                      />
                    </S.SuggestionsMessages>
                  ))}
                </S.ContainerSuggestionsMessages>
              </>
            )}
            {canSendWhatsAppBusinessMessage && displaySuggestions() && (
              <>
                <S.ContainerSuggestionsMessages>
                  {messagesMobiSmartChat?.suggestion?.map((el, index) => (
                    <S.SuggestionsMessages
                      key={index}
                      onClick={() =>
                        handleClickSuggestion(el, scrollToBottomLastInteraction)
                      }
                    >
                      {el}
                    </S.SuggestionsMessages>
                  ))}
                </S.ContainerSuggestionsMessages>
              </>
            )}
          </>
        ) : (
          <S.EmptyContainer>
            <S.EmptyIcon>
              <EmptyTemplateMessages />
            </S.EmptyIcon>
            <span>Sem histórico de mensagens.</span>
          </S.EmptyContainer>
        )}
      </S.Container>
    </S.WrapperBg>
  );
};

export default ViewTextComponent;
