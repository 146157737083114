import React, { useEffect, useState } from 'react';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ConfirmButton from 'components/atoms/ConfirmButton';
import AddScheduleDeliveryComponent from './AddScheduleDeliveryComponent';
import useGetDeliveryOrdersById from '../../hooks/useGetDeliveryOrdersById';
import { ButtonWrapper } from './styles';

interface AddScheduleDeliveryProps {
  open: boolean;
  onClose: any;
  proposalSelectedKanban: any;
  refetchData?: any;
}

const AddScheduleDeliveryContainer = ({
  open,
  onClose,
  proposalSelectedKanban,
}: AddScheduleDeliveryProps) => {
  const [date, setDate] = useState<any>(new Date().getTime());

  const {
    data: proposalSelected,
    refetch: refetchData,
  } = useGetDeliveryOrdersById(proposalSelectedKanban?.proposal?.id);

  const handleCloseModalScheduling = () => {
    refetchData();
    onClose();
  };

  const DialogAction = () => (
    <ButtonWrapper>
      <OutlinedButton onClick={() => handleCloseModalScheduling()}>
        Fechar
      </OutlinedButton>
      <div style={{ width: 8 }} />
      <ConfirmButton
        disabled={false}
        onClick={() => handleCloseModalScheduling()}
      >
        Concluir
      </ConfirmButton>
    </ButtonWrapper>
  );

  useEffect(() => {
    if (proposalSelected?.schedule?.id) {
      setDate(new Date(proposalSelected?.schedule?.startTime).getTime());
      return;
    }
    setDate(new Date().getTime());
  }, [proposalSelected?.schedule?.id]);

  useEffect(() => {
    refetchData();
  }, [proposalSelectedKanban?.proposal?.id]);

  return (
    <AddScheduleDeliveryComponent
      open={open}
      onClose={handleCloseModalScheduling}
      DialogAction={DialogAction}
      proposalSelected={proposalSelected}
      date={date}
      setDate={setDate}
      refetchData={refetchData}
    />
  );
};

export default AddScheduleDeliveryContainer;
