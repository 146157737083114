export enum ActionFieldType {
  Alphanumeric = 'ALPHANUMERIC',
  Select = 'SELECT',
  Numeric = 'NUMERIC',
  CPFCNPJ = 'CPFCNPJ',
  Date = 'DATE',
  Price = 'PRICE',
}

export type AvailableFormFieldTypes = string | number | number[] | undefined;

export interface Option {
  label: string;
  value: number | string;
}

export interface Field {
  position: number;
  label: string;
  placeholder?: string;
  attribute: string;
  type: ActionFieldType | string;
  required: boolean;
  options?: Option[];
}

export interface FormStatus {
  id: number;
  label: string;
  status: string;
  position: number;
  department: number[];
  fields: Field[];
}

export interface GenericFormFields {
  [key: string]: AvailableFormFieldTypes;
}

export interface FormField {
  name: string;
  label: string;
  required: boolean;
  placeholder?: string;
  type: ActionFieldType | string;
  options?: any[];
}
