import request from 'helpers/request';
import envVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = envVars('API_DEALER_SITE_BASE_URL');
const imagesDomain = envVars('API_IMAGES_BASE_URL');
const imgOrigin = 'DEALER_SITE_DEFAULT';
const prefix = 'api/adm/v1';
const serviceName = 'DealerSiteService';

export default class DealerSiteService {
  static startSiteTheme(data) {
    return request
      .put(`${domain}/${prefix}/site_theme/startSiteTheme`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.startSiteTheme()`));
  }

  static updateSettingsData(data) {
    return request
      .put(`${domain}/${prefix}/site_theme/editSiteTheme`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateSettingsData()`));
  }

  static getSettingsData(params) {
    return request
      .get(`${domain}/${prefix}/site_theme/getSiteTheme`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getSettingsData()`));
  }

  static getMessages(params) {
    return request
      .get(`${domain}/${prefix}/message/getByPeriod`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getMessages()`));
  }

  static getLocations(params) {
    return request
      .get(`${domain}/${prefix}/store/get`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getLocations()`));
  }

  static updateLocations(params) {
    return request
      .put(`${domain}/${prefix}/store/set`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.updateLocations()`));
  }

  static getSociais(params) {
    return request
      .get(`${domain}/${prefix}/social_contacts/get`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getSociais()`));
  }

  static updateSocial(data) {
    return request
      .put(`${domain}/${prefix}/social_contacts/set`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateSocial()`));
  }

  static getGoogleData(params) {
    return request
      .get(`${domain}/${prefix}/gtagmanager_and_analytics/get`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getGoogleData()`));
  }

  static updateGoogleData(data) {
    return request
      .put(`${domain}/${prefix}/gtagmanager_and_analytics/set`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateGoogleData()`));
  }

  static getPagesData(params) {
    return request
      .get(`${domain}/${prefix}/page_components/get`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getPagesData()`));
  }

  static updatePage(data) {
    return request
      .put(`${domain}/${prefix}/page_components/set`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updatePage()`));
  }

  static getListTheme() {
    return request
      .get(`${domain}/${prefix}/site_theme/getAll`)
      .then(buildHTTPResponseHandler(`${serviceName}.getListTheme()`));
  }

  static getTheme(params) {
    return request
      .get(`${domain}/${prefix}/site_theme/get`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getTheme()`));
  }

  static setTheme(params) {
    return request
      .put(`${domain}/${prefix}/site_theme/set`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.setTheme()`));
  }

  static getThemeId(params) {
    return request
      .get(`${domain}/${prefix}/dealer_site_theme_id/getByDealerId`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getThemeId()`));
  }

  static getColors(params) {
    return request
      .get(`${domain}/${prefix}/colors_component/get`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getColors()`));
  }

  static updateColors(data) {
    return request
      .put(`${domain}/${prefix}/colors_component/set`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateColors()`));
  }

  static checkSubDomain(data) {
    return request
      .get(`${domain}/${prefix}/subdomain/checkSubdomainIsAvailable`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.checkSubDomain()`));
  }

  static getAllDealerBanners(params) {
    return request
      .get(`${domain}/${prefix}/banner/getAll`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getAllDealerBanners()`));
  }

  static addDealerBanner(body) {
    return request
      .put(`${domain}/${prefix}/banner/set`, body)
      .then(buildHTTPResponseHandler(`${serviceName}.addDealerBanner()`));
  }

  static getBannerTags() {
    const params = { imgOrigin };
    return request
      .get(`${imagesDomain}/images/v1.0/tags`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getBannerTags()`));
  }

  static getBannerWithTag(tag) {
    const params = { imgOrigin, tags: tag };
    return request
      .get(`${imagesDomain}/images/v1.0/find/bytag?`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getBannerTags()`));
  }

  static detachDealerBanner(params) {
    return request
      .delete(`${domain}/${prefix}/banner/delete`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.detachBanner()`));
  }

  static deleteStoreLocation(params) {
    return request
      .delete(`${domain}/${prefix}/store/delete`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteStoreLocation()`));
  }
}
