import React from 'react';
import Chip from 'components/atoms/Chip';
import getInitialsName from 'helpers/getInitialsName';
import columns from 'constants/columns';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
//import AddNewButton from 'modules/financial/pages/components/AddNewButton';

import {
  Container,
  Header,
  VisitContainer,
  ServiceStatus,
  CheckIcon,
  ErrorIcon,
  VisitScheduled,
  VisitStatus,
  HeaderTitle,
  Title,
  Text,
  DateContainer,
  DateWrapper,
  Footer,
  OvalBigger,
} from './styles';

const originOptions = {
  INDICATION: 'Indicação',
  INTERNET: 'Internet',
  RECEPTIVE_CONNECTION: 'Ligação Receptiva',
  PASSING: 'Passante',
  PROSPECTION: 'Prospecção',
  OLX_PF: 'OLX Pessoa Física',
  CRM: 'CRM',
};

const finishedStatus = [
  'FINISHED_WITH_SOLD',
  'FINISHED_AND_CONCLUDED',
  'FINISHED_BUT_NOT_CONCLUDED',
  'WITH_ORDER',
];

const finishedSuccess = [finishedStatus[0], finishedStatus[1]];

const HistoryCard = ({ historyData, handleSearchActionClick }) => {
  return (
    <Container>
      <Header>
        <VisitContainer>
          <ServiceStatus>
            {finishedStatus.includes(historyData.status) ? (
              finishedSuccess.includes(historyData.status) ? (
                <CheckIcon />
              ) : (
                <ErrorIcon />
              )
            ) : null}
            <HeaderTitle>
              {columns[historyData.status].label}{' '}
              {historyData?.orderId && (
                <HypertextButton
                  onClick={() =>
                    handleSearchActionClick(historyData?.proposalId)
                  }
                >
                  Ver pedido
                </HypertextButton>
              )}
            </HeaderTitle>
          </ServiceStatus>
          <VisitScheduled>
            <Title>Visita agendada: </Title>
            <VisitStatus>
              {historyData.scheduled ? <CheckIcon /> : <ErrorIcon />}
              <Text>{historyData.scheduled ? 'Com visita' : 'Sem visita'}</Text>
            </VisitStatus>
          </VisitScheduled>
        </VisitContainer>
        <OvalBigger>
          {getInitialsName(historyData.sellerName || 'Sem nome')}
        </OvalBigger>
      </Header>

      <DateContainer>
        <DateWrapper>
          <Title>Data Inicio</Title>
          <Text>{historyData.createdAt}</Text>
        </DateWrapper>

        <DateWrapper>
          <Title>Data término</Title>
          {historyData.finishedAt ? (
            <Text>{historyData.finishedAt}</Text>
          ) : (
            <Text>-</Text>
          )}
        </DateWrapper>
      </DateContainer>

      {historyData.origin && (
        <Footer>
          <Chip>{originOptions?.[historyData.origin]}</Chip>
          {/*<AddNewButton onClick={() => console.log('haduken')}>
          + Ver detalhes
        </AddNewButton>*/}
        </Footer>
      )}
    </Container>
  );
};

export default HistoryCard;
