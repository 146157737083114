import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';

import CloseIcon from '@material-ui/icons/Close';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import { FilterOption } from '../../../interfaces';

import * as S from './styles';

interface Props {
  disabled: boolean;
  disableSearch?: boolean;
  name: string;
  onChange: (option: FilterOption) => void;
  options: FilterOption[];
  selectedOption: FilterOption | null;
}

export default function RadioTemplate({
  disabled,
  disableSearch = false,
  name,
  onChange,
  options,
  selectedOption,
}: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentOptions, setCurrentOptions] = useState<FilterOption[]>([]);

  const getOptionByValue = (optionValue: string | number) => {
    return options.find(option => optionValue === option?.value);
  };

  const filterOptions = ({
    valueToSearch,
    optionsToSearch,
  }: {
    valueToSearch: string;
    optionsToSearch: FilterOption[];
  }) => {
    const formattedValue = valueToSearch.toLowerCase().trim();
    const filterRegex = new RegExp(formattedValue.replace(/\s+/, '|'));

    return optionsToSearch.filter(option => {
      const currentValue = option?.label.toLowerCase().trim();
      return currentValue.search(filterRegex) !== -1;
    });
  };

  useEffect(() => {
    if (options?.length) setCurrentOptions(options);
  }, [options]);

  useEffect(() => {
    if (!searchValue?.length && options?.length) {
      return setCurrentOptions(options);
    }
    const filtered = filterOptions({
      valueToSearch: searchValue,
      optionsToSearch: currentOptions,
    });
    return setCurrentOptions(filtered);
  }, [searchValue]);

  return (
    <S.Container>
      {!disableSearch && (
        <S.SearchBox>
          <DebounceInput
            variant="outlined"
            label="Buscar"
            value={searchValue}
            onChange={({ target }) => setSearchValue(target.value)}
            debounceTimeout={250}
            disabled={disabled}
            element={TextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    style={{ fontSize: '1.5rem', color: '#757575' }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <IconButton onClick={() => setSearchValue('')}>
            <CloseIcon />
          </IconButton>
        </S.SearchBox>
      )}
      <S.RadioGroup
        name={name}
        value={selectedOption?.value || null}
        onChange={({ target }) => {
          const selected = getOptionByValue(target.value);
          if (selected) onChange(selected);
        }}
      >
        {currentOptions?.length ? (
          <>
            {currentOptions.map(option => (
              <div key={`${option.label}-${option.value}`}>
                <FormControlLabel
                  label={option.label}
                  value={String(option.value)}
                  disabled={disabled}
                  control={<Radio />}
                />
              </div>
            ))}
          </>
        ) : (
          <S.EmptyMessage>Nenhum resultado encontrado</S.EmptyMessage>
        )}
      </S.RadioGroup>
    </S.Container>
  );
}
