import React from 'react';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ProposalStatusTip from 'components/ProposalStatusTip/ProposalStatusTip';
import { useChatContext } from 'pages/chats/ChatsContext';
import SellerInfos from '../../../components/SideBarComponents/SellerInfos';
import CallCenterInfo from '../../../components/SideBarComponents/CallCenterInfo';
import SearchAndRescueInfo from '../../../components/SideBarComponents/SearchAndRescueInfo';

interface Accordion {
  callcenter: boolean;
  seller: boolean;
  searchAndRescueInfo: boolean;
}

type propTypes = {
  closedProposal: boolean;
  accordion?: Accordion;
};

const AgentAccordionComponent = ({ closedProposal, accordion }: propTypes) => {
  const { currentProposal }: any = useChatContext();
  const [state, setState] = React.useState(
    accordion || {
      callcenter: true,
      seller: true,
      searchAndRescueInfo: true,
    }
  );

  const useStyles = makeStyles(() => ({
    container: {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    root: {
      boxShadow: 'none',
      margin: '0px',
    },
    heading: {
      padding: '0px 13px',
      fontWeight: 600,
      fontFamily: 'Poppins Medium',
      fontSize: '14px',
      margin: '0px !important',
      borderLeft: '4px solid #05b2ab',
      borderBottom: '1px solid rgb(204,204,204)',
      minHeight: '55px !important',
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
    },
    content: {},
    expanded: {},
    details: {
      padding: 0,
    },
  }));

  const classes = useStyles();

  const handleOpenAccordion = (name: string) => {
    setState(prevState => ({ ...prevState, [name]: !state[name] }));
  };

  return (
    <Box component="div" height="750px" className={classes.container}>
      <Accordion
        className={classes.root}
        expanded={state.callcenter}
        onChange={() => handleOpenAccordion('callcenter')}
      >
        <AccordionSummary
          classes={{
            root: classes.heading,
            content: classes.content,
            expanded: classes.expanded,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            component="div"
            display="flex"
            gridGap="10px"
            alignItems="center"
          >
            Central de Atendimento
            {currentProposal?.salesAssistantStatus && (
              <ProposalStatusTip
                status={currentProposal.salesAssistantStatus}
                statusDetail={currentProposal?.salesAssistantStatusDetailName}
              />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <CallCenterInfo closedProposal={closedProposal} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.root}
        expanded={state.seller}
        onChange={() => handleOpenAccordion('seller')}
      >
        <AccordionSummary
          className={classes.heading}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            component="div"
            display="flex"
            gridGap="10px"
            alignItems="center"
          >
            Loja
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <SellerInfos closedProposal={closedProposal} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.root}
        expanded={state.searchAndRescueInfo}
        onChange={() => handleOpenAccordion('searchAndRescueInfo')}
      >
        <AccordionSummary
          className={classes.heading}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            component="div"
            display="flex"
            gridGap="10px"
            alignItems="center"
          >
            Busca e Resgate
            {currentProposal.searchAndRescueStatus &&
              currentProposal.searchAndRescueStatusDetailName && (
                <ProposalStatusTip
                  status={currentProposal.searchAndRescueStatus}
                  statusDetail={currentProposal.searchAndRescueStatusDetailName}
                />
              )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <SearchAndRescueInfo closedProposal={closedProposal} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AgentAccordionComponent;
