import moment from 'moment';

const getDateByDay = (days = 0, startDay = false) => {
  const moreDays = moment().get('date') + Math.abs(days);
  const lassDays = moment().get('date') - Math.abs(days);
  if (startDay) {
    return moment().set({
      date: days >= 0 ? moreDays : lassDays,
      hour: 0,
      minute: 0,
      second: 0,
    });
  } else {
    return moment().set({
      date: days >= 0 ? moreDays : lassDays,
      hour: 23,
      minute: 59,
      second: 59,
    });
  }
};

// TODAY
const TODAY_START_DAY = getDateByDay(0, true);
const TODAY_END_DAY = getDateByDay();
//TREE_DAYS_AGO
const THREE_DAYS_AGO_START_DAY = getDateByDay(-3, true);
const THREE_DAYS_AGO_END_DAY = getDateByDay(-3);
// YESTERDAY
const YESTERDAY_START_DAY = getDateByDay(-1, true);
const YESTERDAY_END_DAY = getDateByDay(-1);
// SEVEN_DAYS_AGO
const SEVEN_DAYS_AGO_START_DAY = getDateByDay(-7, true);
// FIFTEEN_DAYS_AGO
const FIFTEEN_DAYS_AGO_START_DAY = getDateByDay(-15, true);
// THIRTY_DAYS_AGO
const THIRTY_DAYS_AGO = getDateByDay(-30, true);
// SIXTY_DAYS_AGO
const SIXTY_DAYS_AGO = getDateByDay(-60, true);
// NINETY_DAYS_AGO
const NINETY_DAYS_AGO = getDateByDay(-90, true);
// const LAST_SIX_MONTH = getDateByDay(-180, true);

// CURRENT_MONTH
const FIRST_DAY_CURRENT_MONTH = moment()
  .startOf('month')
  .set({ hour: 0, minute: 0, second: 0 });
const LAST_DAY_CURRENT_MONTH = moment()
  .endOf('month')
  .set({ hour: 23, minute: 59, second: 59 });
// LAST_MONTH
const FIRST_DAY_LAST_MONTH = moment()
  .subtract(1, 'months')
  .startOf('month')
  .set({ hour: 0, minute: 0, second: 0 });
const LAST_DAY_LAST_MONTH = moment()
  .subtract(1, 'months')
  .endOf('month')
  .set({ hour: 23, minute: 59, second: 59 });

const LAST_THREE_MONTH = moment()
  .subtract(3, 'months')
  .startOf('month')
  .set({ hour: 0, minute: 0, second: 0 });

const LAST_SIX_MONTH = moment()
  .subtract(6, 'months')
  .startOf('month')
  .set({ hour: 0, minute: 0, second: 0 });

const LAST_TWELVE_MONTH = moment()
  .subtract(12, 'months')
  .startOf('month')
  .set({ hour: 0, minute: 0, second: 0 });

const NEXT_SIX_MONTH = moment()
  .add(6, 'months');

const NEXT_TWELVE_MONTH = moment()
  .add(12, 'months');

const DATES_BY_PERIOD = {
  today: {
    id: 'today',
    label: 'Hoje',
    startDate: TODAY_START_DAY,
    endDate: TODAY_END_DAY,
  },
  yesterDay: {
    id: 'yesterDay',
    label: 'Ontem',
    startDate: YESTERDAY_START_DAY,
    endDate: YESTERDAY_END_DAY,
  },
  yesterDayAndToday: {
    id: 'yesterDayAndToday',
    label: 'Ontem + Hoje',
    startDate: YESTERDAY_START_DAY,
    endDate: TODAY_END_DAY,
  },
  sevenDays: {
    id: 'sevenDays',
    label: '7 dias',
    startDate: SEVEN_DAYS_AGO_START_DAY,
    endDate: TODAY_END_DAY,
  },
  fifteenDays: {
    id: 'fifteenDays',
    label: '15 dias',
    startDate: FIFTEEN_DAYS_AGO_START_DAY,
    endDate: TODAY_END_DAY,
  },
  thirtyDays: {
    id: 'thirtyDays',
    label: '30 dias',
    startDate: THIRTY_DAYS_AGO,
    endDate: TODAY_END_DAY,
  },
  sixtyDays: {
    id: 'sixtyDays',
    label: '60 dias',
    startDate: SIXTY_DAYS_AGO,
    endDate: TODAY_END_DAY,
  },
  ninetyDays: {
    id: 'ninetyDays',
    label: '90 dias',
    startDate: NINETY_DAYS_AGO,
    endDate: TODAY_END_DAY,
  },
  currentMonth: {
    id: 'currentMonth',
    label: 'Mês atual',
    startDate: FIRST_DAY_CURRENT_MONTH,
    endDate: LAST_DAY_CURRENT_MONTH,
  },
  lastMonth: {
    id: 'lastMonth',
    label: 'Mês anterior',
    startDate: FIRST_DAY_LAST_MONTH,
    endDate: LAST_DAY_LAST_MONTH,
  },
  lastThreeMonth: {
    id: 'lastThreeMonth',
    label: 'Último 3 meses',
    startDate: LAST_THREE_MONTH,
    endDate: FIRST_DAY_CURRENT_MONTH,
  },
  lastSixMonth: {
    id: 'lastSixMonth',
    label: 'Último 6 meses',
    startDate: LAST_SIX_MONTH,
    endDate: FIRST_DAY_CURRENT_MONTH,
  },
  lastTwelveMonth: {
    id: 'lastTwelveMonth',
    label: 'Último 12 meses',
    startDate: LAST_TWELVE_MONTH,
    endDate: FIRST_DAY_CURRENT_MONTH,
  },
  nextTwelveMonth: {
    id: 'nextTwelveMonth',
    label: 'Próximos 12 meses',
    startDate: FIRST_DAY_CURRENT_MONTH,
    endDate: NEXT_TWELVE_MONTH,
  },
  nextSixMonth: {
    id: 'nextSixMonth',
    label: 'Próximos 6 meses',
    startDate: FIRST_DAY_CURRENT_MONTH,
    endDate: NEXT_SIX_MONTH,
  },
};

export {
  DATES_BY_PERIOD,
  TODAY_START_DAY,
  TODAY_END_DAY,
  THREE_DAYS_AGO_START_DAY,
  THREE_DAYS_AGO_END_DAY,
  YESTERDAY_START_DAY,
  YESTERDAY_END_DAY,
  SEVEN_DAYS_AGO_START_DAY,
  FIFTEEN_DAYS_AGO_START_DAY,
  THIRTY_DAYS_AGO,
  FIRST_DAY_CURRENT_MONTH,
  LAST_DAY_CURRENT_MONTH,
  FIRST_DAY_LAST_MONTH,
  LAST_DAY_LAST_MONTH,
  SIXTY_DAYS_AGO,
  NINETY_DAYS_AGO,
  LAST_SIX_MONTH,
  LAST_TWELVE_MONTH,
  NEXT_SIX_MONTH,
  NEXT_TWELVE_MONTH
};
