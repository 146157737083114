import React from 'react';
import GenericDialog from 'components/GenericDialog';
import { SubTitle, Container, ResponseTitle, Response } from './styles';
import Wrapper from 'components/Wrapper';
import moment from 'moment';

const ComponentConfirmAppointment = ({
  dataResponse,
  open,
  onClose,
  DialogAction,
  date,
}) => {
  return (
    <GenericDialog
      title="Confirmar agendamento"
      open={open}
      onClose={onClose}
      DialogAction={DialogAction}
      maxWidth={'sm'}
    >
      <Wrapper flex ml={8} mr={16} direction={'column'}>
        <SubTitle>Confira os dados do agendamento abaixo:</SubTitle>
        <Container>
          <Wrapper flex direction="column">
            <ResponseTitle>Data da entrega:</ResponseTitle>
            <Response>
              {` ${moment(date).format('L')} - ${
                dataResponse[0].startInterval
              } ás ${dataResponse[dataResponse.length - 1].endInterval}`}
            </Response>
          </Wrapper>

          <Wrapper flex direction="column">
            <ResponseTitle>Responsável</ResponseTitle>
            <Response>{dataResponse[0].seller}</Response>
          </Wrapper>
        </Container>
      </Wrapper>
    </GenericDialog>
  );
};

export default ComponentConfirmAppointment;
