import React from 'react';
import Popover from '@material-ui/core/Popover';
import FinancingProducts, { FinancingProductsProps } from '../FinancingProducts';
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import { IconButton } from '@material-ui/core';

export default function FinancingProductsDialog(props: FinancingProductsProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton onClick={handleClick}>
        <InfoOutlined style={{fontSize: 18}} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{padding: 20}}>
          <FinancingProducts {...props}/>
        </div>
      </Popover>
    </div>
  );
}