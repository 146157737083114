import React, { useState, useEffect } from 'react';
import Rating from 'react-rating';
import { useDispatch } from 'react-redux';
import ProposalServices from 'services/proposalServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { useChatContext } from 'pages/chats/ChatsContext';
import { RatingContent, RatingIcon, MiniLoading } from './styles';

interface ProposalRatingComponent {
  showText?: boolean;
} 

function ProposalRatingComponent({ showText = true }: ProposalRatingComponent) {
  const { currentProposal }: any = useChatContext();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ratingValue, setRatingValue] = useState(currentProposal.rating);

  const saveRating = async value => {
    if (!loading) {
      setLoading(true);

      const response: any = await ProposalServices.addRating(
        currentProposal.dealerId,
        currentProposal.id,
        value
      );

      if (response?.success === false) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Não foi possível alterar a temperatura!'
          )
        );
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Temperatura alterada com sucesso!'
          )
        );
        setRatingValue(value);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    setRatingValue(currentProposal.rating);
  }, [currentProposal.rating]);

  return (
    <RatingContent loading={loading}>
      {showText && <span>Temperatura: </span>}
      <Rating
        start={1}
        stop={4}
        initialRating={ratingValue}
        emptySymbol={<RatingIcon href="#icon-star-empty" />}
        fullSymbol={<RatingIcon href="#icon-star-full" full />}
        onChange={saveRating}
      />
      {loading && <MiniLoading size={20} />}
    </RatingContent>
  );
}

export default ProposalRatingComponent;
