import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: #424242;
  
  > h1 {
    font-size: 24px;
  }

  > span {
    display: block;
    font-family: 'Poppins Regular';
    font-size: 16px;
    max-width: 761px;
    text-align: center;
  }
`;
