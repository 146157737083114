import { IconButton, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import formatAmount from 'helpers/formatAmount';
import moment from 'moment';
import { createUUID } from 'modules/order/helpers/CreateUuid';
import OrderServices from 'modules/order/services/OrderServices';
import {
  OrderProduct,
  OrderProductType,
} from 'modules/order/types/OrderProduct';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import AddOrderProductDialog from './AddOrderProductDialog';
import {
  AddButton,
  BodyCell,
  EmptyListText,
  HeaderCell,
  OrderTable,
  OrderProductsContainer,
} from './styles';
import { OrderPayment } from 'modules/order/types/OrderPayment';
import ActionCreators from 'modules/order/store/reducers/actionCreators';

function OrderProductsComponent() {
  const {
    dealerId,
    paymentData: { products, payments },
    isFinished,
  } = useSelector(state => state.order);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [editProduct, setEditProduct] = useState<OrderProduct>();
  const [productTypeList, setProductTypeList] = useState<OrderProductType[]>(
    []
  );

  const dispatch = useDispatch();

  const setProductList = (productList: OrderProduct[]) => {
    dispatch(ActionCreators.setPaymentData({ products: productList }));
  };

  const getProductTypes = async () => {
    try {
      const { data } = await OrderServices.getProductTypes(dealerId);
      setProductTypeList(data);
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Não foi possível consultar os tipos de serviços adicionais'
        )
      );
    }
  };

  const getFinancingPayment = () => {
    return (payments as OrderPayment[]).find(
      payment => payment.paymentType.startInternalFinancing
    );
  };

  const getFinancingProductsSum = () => {
    return (products as OrderProduct[])
      .filter(product => product.includedOnFinancing)
      .map(product => product.value)
      .reduce((a, b) => a + b, 0);
  };

  const setPaymentsList = (payments: OrderPayment[]) => {
    dispatch(ActionCreators.setPaymentData({ payments }));
  };

  const addPayment = (paymentToAdd: OrderPayment) => {
    const indexPaymentToChange = payments.findIndex(
      payment => payment.position === paymentToAdd.position
    );
    payments[indexPaymentToChange] = paymentToAdd;
    setPaymentsList([...payments]);
  };

  useEffect(() => {
    const financingPayment = getFinancingPayment();
    if (financingPayment) {
      const sumFinancingProducts = getFinancingProductsSum();
      financingPayment.value = Number(
        (financingPayment.financingValue || 0) + sumFinancingProducts
      );
      addPayment(financingPayment);
    }
  }, [products]);

  useEffect(() => {
    getProductTypes();
  }, []);

  const handleOnCloseAddDialog = (product?: OrderProduct) => {
    if (product) {
      const isEdit = products.some(row => row.uuid === product.uuid);
      if (isEdit) {
        const newProductList = [
          ...products.filter(row => row.uuid !== product.uuid),
          product,
        ];

        setProductList(newProductList);
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Serviço alterado com sucesso!'
          )
        );
      } else {
        const newProduct: OrderProduct = { ...product, uuid: createUUID() };
        setProductList([...products, newProduct]);
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Serviço adicionado com sucesso!'
          )
        );
      }
    }
    setEditProduct(undefined);
    setOpenAddDialog(false);
  };

  const handleOnDelete = (rowToDelete: OrderProduct) => {
    setProductList(products.filter(row => row != rowToDelete));
  };

  const handleOnEdit = (rowToEdit: OrderProduct) => {
    setEditProduct(rowToEdit);

    setOpenAddDialog(true);
  };

  const handleOnOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  return (
    <OrderProductsContainer>
      <OrderTable>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCell>Tipo</HeaderCell>
              <HeaderCell>Valor</HeaderCell>
              <HeaderCell>Data</HeaderCell>
              <HeaderCell>Cobrado</HeaderCell>
              <HeaderCell>Incluso no financiamento</HeaderCell>
              <HeaderCell></HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((row: OrderProduct) => (
              <TableRow key={row.uuid}>
                <BodyCell>{row.proposalProduct?.name}</BodyCell>
                <BodyCell>{formatAmount(row.value, 'PT-BR', 'R$')}</BodyCell>
                <BodyCell>{moment(row.date).format('DD/MM/YYYY')}</BodyCell>
                <BodyCell>
                  {row.proposalProduct.charged ? 'Sim' : 'Não'}
                </BodyCell>
                <BodyCell>{row.includedOnFinancing ? 'Sim' : 'Não'}</BodyCell>
                <BodyCell>
                  <Tooltip title="Alterar serviço adicional">
                    <IconButton
                      onClick={() => handleOnEdit(row)}
                      disabled={isFinished}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remover serviço adicional">
                    <IconButton
                      onClick={() => handleOnDelete(row)}
                      disabled={isFinished}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Tooltip>
                </BodyCell>
              </TableRow>
            ))}

            <TableRow>
              {!products.length ? (
                <BodyCell align="center" colSpan={12}>
                  <EmptyListText>
                    Você ainda não adicionou nenhum serviço.
                  </EmptyListText>
                  <br />
                  <AddButton
                    onClick={handleOnOpenAddDialog}
                    variant="contained"
                  >
                    Adicionar serviço
                  </AddButton>
                </BodyCell>
              ) : (
                <BodyCell align="left" colSpan={12}>
                  {!isFinished && (
                    <HypertextButton
                      onClick={handleOnOpenAddDialog}
                      style={{ width: 'fit-content', padding: 12 }}
                    >
                      + Adicionar um novo serviço
                    </HypertextButton>
                  )}
                </BodyCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
        {openAddDialog && (
          <AddOrderProductDialog
            open={openAddDialog}
            onClose={handleOnCloseAddDialog}
            editProduct={editProduct}
            orderProductTypes={productTypeList}
          />
        )}
      </OrderTable>
    </OrderProductsContainer>
  );
}

export default OrderProductsComponent;
