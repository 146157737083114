import React, { Fragment, memo, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Box, Popover } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';

import Checkbox from 'bit/mobiauto.web-ui-components.components.checkbox/Checkbox';

import { filterByUnread as filterByUnreadState } from 'pages/conversations/contexts/atoms';

import ActiveFilters from './components/ActiveFilters';
import { CheckboxTemplate, RadioTemplate } from './components/Templates';

import { Filter, FilterOption } from './interfaces';

import * as S from './styles';

export interface FilterObject {
  id: string | number;
  name: string;
  label: string;
  value: string | number;
}

export interface FilterPayload {
  filterGroupId: string;
  values:
    | string[]
    | {
        id: number | string;
        name: string;
        label: string;
        value: number | string;
      }[];
}

interface Props {
  filters?: Filter[];
  onSelect?: (filters: FilterPayload[]) => void;
  otherElements: React.ReactNode;
  popoverId: string;
  isSimple?: boolean;
}

const FilterAccordion = ({ title, content }) => {
  return (
    <S.Accordion>
      <S.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </S.AccordionSummary>
      <S.AccordionDetails>{content}</S.AccordionDetails>
    </S.Accordion>
  );
};

function PopoverFilters({
  popoverId,
  otherElements,
  filters,
  onSelect,
  isSimple = false,
}: Props) {
  const [anchorFilters, setAnchorFilters] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>([]);

  const handleClick = event => setAnchorFilters(event.currentTarget);
  const handleClose = () => setAnchorFilters(null);

  const isOpen = Boolean(anchorFilters);
  const filtersPopoverId = isOpen ? popoverId : undefined;
  const hasSelectedFilters = selectedFilters?.length > 0;
  const [filterByUnread, setFilterByUnread] = useRecoilState(
    filterByUnreadState
  );

  const handleOnSelect = (currentFilters: FilterOption[]) => {
    if (!onSelect) return;

    const FILTERS_REQUIRING_OBJECT_VALUES = [
      'providers',
      'origin',
      'tagIds',
      'departmentCode',
      'sellers',
    ];

    const payload: FilterPayload[] = filters.map(filter => ({
      filterGroupId: filter.apiProperty,
      values: [],
    }));

    currentFilters.forEach(filter => {
      const currentIndex = payload.findIndex(
        ({ filterGroupId }) => filterGroupId === filter.filterGroupId
      );

      if (currentIndex > -1) {
        if (FILTERS_REQUIRING_OBJECT_VALUES.includes(filter.filterGroupId)) {
          const updatedValues = [
            ...payload[currentIndex].values,
            {
              id: filter.value,
              name: filter?.label,
              label: filter?.label,
              value: filter.value,
            },
          ];
          payload[currentIndex].values = updatedValues as FilterObject[];
          return;
        }
        const updatedValues = [...payload[currentIndex].values, filter.value];
        payload[currentIndex].values = updatedValues as string[];
        return;
      }

      if (FILTERS_REQUIRING_OBJECT_VALUES.includes(filter.filterGroupId)) {
        return payload.push({
          filterGroupId: filter.filterGroupId,
          values: [
            {
              id: filter.value,
              name: filter?.label,
              label: filter?.label,
              value: filter.value,
            },
          ],
        });
      }

      return payload.push({
        filterGroupId: filter.filterGroupId,
        values: [filter.value],
      });
    });

    return onSelect(payload);
  };

  const handleDeleteAllFilters = () => {
    const someDisabledFilters = [
      ...selectedFilters.filter(filter => filter.disabled),
    ];
    setSelectedFilters(someDisabledFilters);
    handleOnSelect(someDisabledFilters);
  };

  const handleDeleteOneFilter = (option: FilterOption) => {
    const filtered = selectedFilters.filter(filter => filter.id !== option.id);
    setSelectedFilters(filtered);
    handleOnSelect(filtered);
  };

  const getOneSelectedOption = (filter: Filter) => {
    const filtered = selectedFilters.filter(
      ({ filterGroupId }) => filterGroupId === filter.apiProperty
    )?.[0];
    return filtered || null;
  };

  const getSelectedOptions = (filter: Filter) => {
    const filtered = selectedFilters.filter(
      ({ filterGroupId }) => filterGroupId === filter.apiProperty
    );
    return filtered || null;
  };

  const handleRadioChange = (selected: FilterOption) => {
    const selecteds = [...selectedFilters];

    const selectedIndex = selecteds.findIndex(
      ({ filterGroupId }) => filterGroupId === selected.filterGroupId
    );

    if (selectedIndex >= 0) selecteds[selectedIndex] = selected;
    else selecteds.push(selected);

    setSelectedFilters(selecteds);
    handleOnSelect(selecteds);
  };

  const handleCheckboxChange = ({
    currentSelecteds,
    filterGroupId,
  }: {
    currentSelecteds: FilterOption[];
    filterGroupId: string;
  }) => {
    const selecteds = [...selectedFilters];
    const filtered = selecteds.filter(
      selected => selected.filterGroupId !== filterGroupId
    );

    setSelectedFilters([...filtered, ...currentSelecteds]);
    handleOnSelect([...filtered, ...currentSelecteds]);
  };

  const getInitialValues = () => {
    const currentFilters = [];
    filters.map(filter => {
      if (filter.selectedOptions) {
        filter.selectedOptions.map(filterSelectedItem => {
          if (filterSelectedItem) {
            currentFilters.push({
              ...filterSelectedItem,
              disabled: filter?.disabled,
            });
          }
        });
      }
    });
    setSelectedFilters(currentFilters);
  };

  useEffect(() => {
    getInitialValues();
  }, [filters]);

  if (isSimple) {
    return (
      <Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          {otherElements}
          <S.FilterButton size="small" isActive={isOpen} onClick={handleClick}>
            <FilterListIcon fontSize="inherit" color="inherit" />
          </S.FilterButton>
          <Popover
            id={popoverId}
            open={isOpen}
            anchorEl={anchorFilters}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <S.PopoverContent>
              <S.FixedHeader>
                <S.MainTitle>Filtros</S.MainTitle>
                <S.ClearFiltersButton onClick={handleDeleteAllFilters}>
                  Restaurar filtros
                </S.ClearFiltersButton>
              </S.FixedHeader>
              <S.Content>
                <Box p={'8px 16px'}>
                  <Checkbox
                    label={'Filtrar por não lidos'}
                    checked={filterByUnread}
                    onChange={() =>
                      setFilterByUnread(previousState => !previousState)
                    }
                  />
                </Box>
                {filters.map(filter => {
                  return (
                    <FilterAccordion
                      key={`accordion-${filter.apiProperty}-${filter.type}`}
                      title={filter.title}
                      content={
                        <>
                          {filter.type === 'radio' && (
                            <RadioTemplate
                              name={filter.apiProperty}
                              options={filter.options}
                              disabled={filter.disabled}
                              onChange={handleRadioChange}
                              selectedOption={getOneSelectedOption(filter)}
                            />
                          )}
                          {filter.type === 'checkbox' && (
                            <CheckboxTemplate
                              options={filter.options}
                              disabled={filter.disabled}
                              onChange={options => {
                                handleCheckboxChange({
                                  currentSelecteds: options,
                                  filterGroupId: filter.apiProperty,
                                });
                              }}
                              selectedOptions={getSelectedOptions(filter)}
                            />
                          )}
                        </>
                      }
                    />
                  );
                })}
              </S.Content>
            </S.PopoverContent>
          </Popover>
        </Box>
        <ActiveFilters
          hasSelectedFilters={hasSelectedFilters}
          selectedFilters={selectedFilters}
          handleDeleteOneFilter={handleDeleteOneFilter}
          handleDeleteAllFilters={handleDeleteAllFilters}
          hiddenTitle
        />
      </Box>
    );
  }

  return (
    <Fragment>
      <S.Container className="popover-filters">
        <S.MainButton
          variant="outlined"
          onClick={handleClick}
          startIcon={<FilterListIcon />}
          aria-describedby={filtersPopoverId}
        >
          Filtros
        </S.MainButton>
        <S.OtherElements className="elements">{otherElements}</S.OtherElements>
      </S.Container>
      <ActiveFilters
        hasSelectedFilters={hasSelectedFilters}
        selectedFilters={selectedFilters}
        handleDeleteOneFilter={handleDeleteOneFilter}
        handleDeleteAllFilters={handleDeleteAllFilters}
      />
      <Popover
        id={popoverId}
        open={isOpen}
        anchorEl={anchorFilters}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <S.PopoverContent>
          <S.FixedHeader>
            <S.MainTitle>Filtros</S.MainTitle>
            <S.ClearFiltersButton onClick={handleDeleteAllFilters}>
              Restaurar filtros
            </S.ClearFiltersButton>
          </S.FixedHeader>
          <S.Content>
            {filters.map(filter => {
              return (
                <FilterAccordion
                  key={`accordion-${filter.apiProperty}-${filter.type}`}
                  title={filter.title}
                  content={
                    <Fragment>
                      {filter.type === 'radio' && (
                        <RadioTemplate
                          name={filter.apiProperty}
                          options={filter.options}
                          disabled={filter.disabled}
                          onChange={handleRadioChange}
                          selectedOption={getOneSelectedOption(filter)}
                        />
                      )}
                      {filter.type === 'checkbox' && (
                        <CheckboxTemplate
                          options={filter.options}
                          disabled={filter.disabled}
                          onChange={options => {
                            handleCheckboxChange({
                              currentSelecteds: options,
                              filterGroupId: filter.apiProperty,
                            });
                          }}
                          selectedOptions={getSelectedOptions(filter)}
                        />
                      )}
                    </Fragment>
                  }
                />
              );
            })}
          </S.Content>
        </S.PopoverContent>
      </Popover>
    </Fragment>
  );
}
export default memo(PopoverFilters);
