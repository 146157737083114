import React from 'react';
import Select from 'modules/financing/components/atoms/Select';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutoComplete from 'components/atoms/AutoComplete'
import { CEPMaskCustom } from 'components/MaskTextInput';
import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput'

const AddressInfosForm = props => {
  const {
    statesOptions,
    streetTypeOptions,
    addressTypeOptions,
    citiesOptionsState,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <Select
            id="addressType"
            name="addressType"
            label="Tipo do endereço"
            value={formData['addressType']}
            onChange={onChange}
            options={addressTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="zipcode"
            name="zipcode"
            label="CEP"
            value={formData['zipcode']}
            onKeyPress={handleKeyPress}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: CEPMaskCustom,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton onClick={getAddresInfosByZipCode} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={2}>
          <Select
            id="streetType"
            name="streetType"
            label="Tipo do logradouro"
            value={formData['streetType']}
            onChange={onChange}
            options={streetTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            id="address"
            name="address"
            label="Endereço"
            value={formData['address']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('address')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="number"
            name="number"
            label="Número"
            value={formData['number']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('number')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={12}>
          <TextInput
            id="complement"
            name="complement"
            label="Complemento (opcional)"
            value={formData['complement']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('complement')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="neighborhood"
            name="neighborhood"
            label="Bairro"
            value={formData['neighborhood']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('neighborhood')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="cityStateId"
            label="Estado*"
            options={statesOptions}
            value={formData?.['cityStateId'] ? {
              id: formData['cityStateId'],
              value: formData['cityStateId'],
              label: statesOptions.find(e => e.value === formData['cityStateId'])?.label,
              name: statesOptions.find(e => e.value === formData['cityStateId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e?.value, name: 'cityStateId' } })}
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="cityId"
            label="Cidade*"
            options={citiesOptionsState}
            value={formData?.['cityId'] ? {
              id: formData['cityId'],
              value: formData['cityId'],
              label: citiesOptionsState.find(e => e.value === formData['cityId'])?.label,
              name: citiesOptionsState.find(e => e.value === formData['cityId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e?.value, name: 'cityId' } })}
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddressInfosForm;
