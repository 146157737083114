import React from 'react';
import columns from 'constants/columns';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export default function ProposalStatusTip({
  deal0km = '',
  status,
  statusDetail = '',
  showNotConcluded = false,
  otherStatus = null,
}) {
  const getBackgroundColor = {
    //Store View
    FINISHED_WITH_SOLD: Colors.green.medium,
    FINISHED_AND_CONCLUDED: Colors.green.medium,
    FINISHED_BUT_NOT_CONCLUDED: Colors.orange.medium,
    FINISHED_BY_TIME: Colors.red.medium,
    //Group View
    NOT_CONTACTED: Colors.gray.medium,
    DISQUALIFIED: '#F44336',
    QUALIFIED: Colors.primary.medium,
    UNATTENDED: Colors.stars.medium,
    WITH_ORDER: '#03a9f4',
    FINANCED: '#4443BC',
    IN_CASH: '#009A8A',
    SUCCESS: '#009A8A',
    FAILURE: '#F44336',
    RETURNED: '#727373',
    INVOICED: '#ff4500',
    APPROVED: '#009A8A',
    NOT_FATURED: '#727373',
  };

  const Tip = styled.div`
    border-radius: 4px;
    color: ${({ status }) => (getBackgroundColor[status] ? '#fff' : '#26272A')};
    padding: 2px 8px;
    line-height: 16px;
    background-color: ${({ status }) =>
      getBackgroundColor[status] || Colors.stars.medium};
    height: 16px;
    white-space: nowrap;
    font-size: 12px !important;
  `;

  const isNotConcluded = [
    columns.NEW.id,
    columns.IN_NEGOTIATION.id,
    columns.IN_PROGRESS.id,
  ].includes(status);

  if (isNotConcluded && !showNotConcluded) {
    return <div></div>;
  }

  if (columns[status]) {
    return (
      <Tooltip
        title={statusDetail}
        disableHoverListener={!statusDetail}
        style={{ fontSize: 14 }}
      >
        <Tip status={status}>{otherStatus || columns[status].label}</Tip>
      </Tooltip>
    );
  }
  return deal0km != null ? (
    <Tip> {deal0km ? '0km' : 'Usado'} </Tip>
  ) : (
    <div></div>
  );
}
