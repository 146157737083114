import Request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'SearchService';
const domain = EnvVars('API_SEARCH_BASE_URL');

export default class SearchService {
  static getVehicleTypes = (vehicleType, inProduction = false) => {
    return Request.get(`${domain}/vehicle/v1.0/${vehicleType}/makes`, {
      inProduction: inProduction,
    }).then(buildHTTPResponseHandler(`${serviceName}.getVehicleTypes()`));
  };
  static getVehicleTypesBase = () => {
    return Request.get(`${domain}/vehicle/v1.0/vehicletype`).then(
      buildHTTPResponseHandler(`${serviceName}.getVehicleTypesBase()`)
    );
  };
  static getMakeByModel = (vehicleType, makeName, inProduction = false) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/models/${makeName}`,
      { inProduction: inProduction }
    ).then(buildHTTPResponseHandler(`${serviceName}.getMakeByModel()`));
  };
  static getMakeByModelById = (vehicleType, makeId, inProduction = false) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/models/id/${makeId}`,
      { inProduction: inProduction }
    ).then(buildHTTPResponseHandler(`${serviceName}.getMakeByModelById()`));
  };
  static getTrimByModelandYear = (
    vehicleType,
    modelName,
    year,
    inProduction = false
  ) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/trims/id/${modelName}/${year}`,
      { inProduction: inProduction }
    ).then(buildHTTPResponseHandler(`${serviceName}.getTrimByModelandYear()`));
  };
  static getTrimByModelId = (vehicleType, modelId) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/trims/id/${modelId}`
    ).then(buildHTTPResponseHandler(`${serviceName}.getTrimByModelandYear()`));
  };
  static getEngineByModelandYear = (
    vehicleType,
    modelName,
    year,
    inProduction = false
  ) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/trims/engine/${modelName}/${year}`,
      { inProduction: inProduction }
    ).then(
      buildHTTPResponseHandler(`${serviceName}.getEngineByModelandYear()`)
    );
  };
  static getYearsByModel = (vehicleType, model, inProduction = false) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/years/id/${model}`,
      { inProduction: inProduction }
    ).then(buildHTTPResponseHandler(`${serviceName}.getYearsByModel()`));
  };
  static getYearsByModelById = (vehicleType, modelId) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/years/id/${modelId}`
    ).then(buildHTTPResponseHandler(`${serviceName}.getYearsByModelById()`));
  };
  static getColors = () => {
    return Request.get(`${domain}/vehicle/v1.0/colors`).then(
      buildHTTPResponseHandler(`${serviceName}.getColors()`)
    );
  };
  static getInternalColors = () => {
    return Request.get(`${domain}/vehicle/v1.0/internal-colors`).then(
      buildHTTPResponseHandler(`${serviceName}.getInternalColors()`)
    );
  };
  static getTransmissions = () => {
    return Request.get(`${domain}/vehicle/v1.0/transmissions`).then(
      buildHTTPResponseHandler(`${serviceName}.getTransmissions()`)
    );
  };
  static getFuels = () => {
    return Request.get(`${domain}/vehicle/v1.0/fuels`).then(
      buildHTTPResponseHandler(`${serviceName}.getFuels()`)
    );
  };
  static getFeatures = vehicleType => {
    return Request.get(`${domain}/vehicle/v1.0/${vehicleType}/features`).then(
      buildHTTPResponseHandler(`${serviceName}.getFeatures()`)
    );
  };
  static getVehicleConditional = (isResale = false) => {
    return Request.get(`${domain}/vehicle/v1.0/vehicleconditionals`, {
      resale: isResale,
    }).then(buildHTTPResponseHandler(`${serviceName}.getVehicleConditional()`));
  };
  static getStates = () => {
    return Request.get(`${domain}/location/v1.0/states`).then(
      buildHTTPResponseHandler(`${serviceName}.getStates()`)
    );
  };
  static getCitiesByState = state => {
    return Request.get(`${domain}/location/v1.0/cities/${state}`).then(
      buildHTTPResponseHandler(`${serviceName}.getCitiesByState()`)
    );
  };
  static getLocationByZipcode = zipcode => {
    return Request.get(`${domain}/location/v2.0/zipcode/${zipcode}`).then(
      buildHTTPResponseHandler(`${serviceName}.getLocationByZipcode()`)
    );
  };
  static getValuePriceByFipe = (trimId, modelYear, deal0km) => {
    const params = { deal0km };
    return Request.get(
      `${domain}/fipe/v1.1/${trimId}/${modelYear}`,
      params
    ).then(buildHTTPResponseHandler(`${serviceName}.getValuePriceByFipe()`));
  };
  static getMakes = (vehicleType, inProduction = false) => {
    return Request.get(`${domain}/vehicle/v1.0/${vehicleType}/makes`, {
      inProduction: inProduction,
    }).then(buildHTTPResponseHandler(`${serviceName}.getMakes()`));
  };
  static getModels = (vehicleType, makeName, inProduction = false) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/models/${makeName}`,
      { inProduction: inProduction }
    ).then(buildHTTPResponseHandler(`${serviceName}.getModels()`));
  };
  static getTrims = (vehicleType, modelName, year, inProduction = false) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/trims/id/${modelName}/${year}`,
      { inProduction: inProduction }
    ).then(buildHTTPResponseHandler(`${serviceName}.getTrims()`));
  };
  static getProductionYears = (vehicleType, model, inProduction = false) => {
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/years/id/${model}`,
      { inProduction: inProduction }
    ).then(buildHTTPResponseHandler(`${serviceName}.getProductionsYears()`));
  };
  static getBodystyles = vehicleType => {
    return Request.get(`${domain}/vehicle/v1.0/${vehicleType}/bodystyles`).then(
      buildHTTPResponseHandler(`${serviceName}.getBodystyles()`)
    );
  };
  static searchBySop = (sop, params) => {
    return Request.get(`${domain}/deals/v1.0/sop/${sop}`, params).then(
      buildHTTPResponseHandler(`${serviceName}.searchBySop()`)
    );
  };

  //GET_VEHICLES_MY_MAKE
  static getVehiclesMyMakeId = (makeId, query = null, vehicleType = 'CAR') => {
    const params = { q: query, inProduction: true };
    return Request.get(
      `${domain}/vehicle/v1.0/${vehicleType}/trims/bymake/${makeId}`,
      params
    ).then(buildHTTPResponseHandler(`${serviceName}.getVehiclesMyMakeId()`));
  };

  static getVehicleById = id => {
    return Request.get(`${domain}/vehicle/v1.0/trim/${id}`).then(
      buildHTTPResponseHandler(`${serviceName}.getVehicleById()`)
    );
  };
}
