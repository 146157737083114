const getInitialsName = sellerName => {
  if (!sellerName) {
    return '';
  }
  const names = sellerName?.split(' ');
  return `${names[0].substring(0, 1).toUpperCase()}${
    names[1] ? names[1].substring(0, 1).toUpperCase() : ''
  }`;
};

export default getInitialsName;
