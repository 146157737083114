import styled from 'styled-components';

import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Chip as MUIChip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
`;

export const EmptyInfo = styled('p')`
  font-family: 'Poppins Regular';
  font-size: 0.875rem;
  text-align: center;
  margin: 0 auto;
`;

export const Items = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &[data-orientation='horizontal'] {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const Item = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &[data-orientation='horizontal'] {
    width: calc(100% / 3 - 0.75rem);
  }
`;

export const ItemTitle = styled('b')`
  font-family: 'Poppins Medium';
  font-size: 0.875rem;
`;

export const ItemSubtitle = styled('p')`
  font-size: 0.875rem;
  margin: 0;
  padding: 1rem 0 0.5rem;
  border-top: 1px solid #ddd;
`;

export const ItemContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const StatusChip = withStyles({
  root: {
    background: '#ceedf3',
    color: '#056274',
    fontFamily: "'Poppins Medium'",
    fontSize: '0.75rem',
  },
})(MUIChip);

export const Accordion = withStyles({
  root: {
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&.MuiPaper-root': {
      backgroundColor: 'inherit',
      border: '1px solid rgba(0, 0, 0, .125)',
      borderWidth: '1px 0',
      borderRadius: '0',
      boxShadow: 'none',
      margin: '0',
    },
  },
})(MUIAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: '0',
    '&.Mui-expanded': {
      minHeight: 'inherit',
    },
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '0.5rem',
      margin: '0',
      fontSize: '0.875rem',
    },
  },
})(MUIAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    padding: '0 0 1.25rem',
  },
})(MUIAccordionDetails);
