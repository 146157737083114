import styled from 'styled-components';

import { Box } from '@material-ui/core';

import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const ContainerScheduling = styled.div`
  padding: 16px;
  height: calc(100vh - 280px);
  overflow-y: auto;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #333333;
  font-family: 'Poppins Regular';
`;

export const Separator = styled.div`
  width: 2px;
  border: solid 1px ${Colors.blue.dark};
  background-color: ${Colors.blue.dark};
`;

export const LabelLight = styled.div`
  font-family: 'Poppins Regular';
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #333333;
  white-space: nowrap;
`;

export const ContainerMessage = styled.div`
  line-height: 18px;
  margin: 0px 10px 0px 10px;
  background: #fff7edbd;
  flex: 1;
  display: flex;
  padding: 6px;
  border-radius: 4px;
`;

export const LabelMessage = styled.div`
  max-width: 180px;
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.1);
`;

export const WrapperContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 60px;
  flex-direction: column;
  justify-content: space-around;
  margin: 12px 0px;
`;

export const Reminder = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OriginTag = styled.div`
  border-radius: 15px;
  padding: 6px 8px;
  background: #e6e6e6;
  line-height: 18px;
  color: #333333;
  text-align: center;
`;

export const ButtonLink = styled.div`
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
`;

export const Button = styled.div`
  font-family: 'Poppins Medium';
  text-transform: initial;
  background-color: transparent;
  border: none;
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
`;

export const MainBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 12px;
`;

export const AuthorBox = styled(Box)`
  display: flex;
  width: max-content;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  background-color: #eeeeee;
  padding: 0 12px;
  margin-right: 5px;
  border-radius: 90px;
`;
