import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Collapse,
  InputAdornment,
  TextField,
  ClickAwayListener,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ButtonWithIcon from '../../mobiauto.web-ui-components.components.button-with-icon/ButtonWithIcon';
import colors from '../../mobiauto.web-ui-components.components.colors/colors';
import IconReceived from '../../mobiauto.web-ui-components.components.icon';

const styles = theme => ({
  cssRoot: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    borderColor: `${colors.gray.light}`,
    borderRadius: '4px 4px 0px 0px ',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: `${colors.gray.medium}`,
      backgroundColor: 'white',
    },
    '&:focus-within': {
      backgroundColor: 'white',
      borderColor: `${colors.primary.medium}`,
    },
  },
  focused: {},
  cssLabel: {
    '&$cssFocused': {
      color: `${colors.primary.medium}`,
    },
  },
  cssFocused: {
    '&:after': {
      borderBottom: `1px solid ${colors.primary.medium}`,
    },
  },
});

const WrappCategories = styled.div`
  height: auto;
  margin-top: -1px;
  position: absolute;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 1px 1.8px 1.8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
`;

const Categories = styled.div`
  cursor: pointer;
  background: ${colors.primary.medium};
  padding: 2px 0px 2px 10px;
  border-radius: 5px;
  color: white;
  margin-top: -1px;
`;

const ListItem = styled.div`
  font-size: 14px;
  padding: 8px 0px 8px 10px;
  background-color: white;
  &:hover {
    background-color: #ebebeb;
  }
`;

const NotFoundText = styled.span`
  font-family: Poppins Regular;
`;

const GroupTitle = styled.span`
  font-size: ${({ mini }) => (mini ? '11px' : '')};
`;

class AutoComplete extends React.Component {
  state = {
    show: false,
    valueOption: '',
    valueClear: 'Clear',
    search: null,
    check: false,
  };

  handleClickSuggestion = (value, options, withGroup) => {
    if (withGroup) {
      const withGroups = options.map(data =>
        data.items.filter(
          item => parseInt(item.value, 10) === parseInt(value, 10)
        )
      );
      const result = withGroups.filter(dataSelected => dataSelected.length > 0);
      return result && result[0] && result[0][0] && result[0][0].label;
    } else {
      const groups = options.filter(
        item => parseInt(item.value, 10) === parseInt(value, 10)
      );
      return groups && groups[0] && groups[0].label;
    }
  };

  checkForValuesSearched = (data, search, getValue) => {
    const dataStringClean =
      data &&
      data
        .toString()
        .toLowerCase()
        .trim();

    const searchStringClean = search
      ? search
          .toString()
          .toLowerCase()
          .trim()
      : false;

    return (
      dataStringClean &&
      searchStringClean &&
      dataStringClean.includes(
        searchStringClean ||
          getValue
            .toString()
            .toLowerCase()
            .trim()
      )
    );
  };

  render() {
    const { show, search } = this.state;
    const {
      label,
      classes,
      options,
      placeholder,
      withGroup,
      value,
      width,
      onChange,
      mini,
      groupTitleStyle,
    } = this.props;

    const searchStringClean = search
      ? search
          .toString()
          .toLowerCase()
          .trim()
      : false;
    const getValue = this.handleClickSuggestion(value, options, withGroup);

    const userTypedInSearch = search && search !== '';
    const optionsToShow = userTypedInSearch
      ? options.filter(group =>
          this.checkForValuesSearched(group.label, search, getValue)
        )
      : options;

    const optionsToShowWithGroup =
      withGroup && userTypedInSearch
        ? options.filter(data => {
            const valuesSearched = data.items.filter(item =>
              this.checkForValuesSearched(item.label, search)
            );
            if (valuesSearched.length > 0) {
              return {
                title: data.title,
                items: valuesSearched,
              };
            }
            return '';
          })
        : options;
    return (
      <ClickAwayListener onClickAway={() => this.setState({ show: false })}>
        <div style={{ position: 'relative' }}>
          <TextField
            fullWidth
            variant="filled"
            style={{
              width: width ? width : '100%',
            }}
            label={label}
            value={
              search !== null
                ? search
                : this.handleClickSuggestion(value, options, withGroup)
            }
            placeholder={placeholder}
            onFocus={() => this.setState({ show: true })}
            onChange={event =>
              this.setState({ search: event.target.value.trim() })
            }
            InputLabelProps={{
              style: {
                fontSize: mini ? '14px' : '',
                top: mini ? '-4.5px' : '',
              },
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              style: {
                height: mini ? '40px' : '',
                fontSize: mini ? '12px' : '',
                padding: mini ? '0px 0px 4px 0px' : '',
              },
              classes: {
                root: classes.cssRoot,
                focused: classes.cssFocused,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    style={{
                      marginTop: mini ? '2px' : '-2px',
                    }}
                  >
                    <ButtonWithIcon
                      iconName={'Clear'}
                      iconProps={{ size: mini ? 14 : 16 }}
                      color={colors.gray.medium}
                      onClick={() => this.setState({ search: '' })}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: `${colors.gray.lighter}`,
                      width: '1.2px',
                      height: mini ? '18px' : '22px',
                      marginTop: mini ? '3px' : ' ',
                      marginLeft: '7px',
                      marginRight: '5px',
                    }}
                    onClick={() => this.setState({ show: !show })}
                  />
                  <div
                    style={{
                      marginRight: mini ? '14px' : '',
                      marginTop: mini ? '3px' : '',
                    }}
                  >
                    <ButtonWithIcon
                      iconName={
                        show === false ? 'KeyboardArrowDown' : 'KeyboardArrowUp'
                      }
                      iconProps={{ size: mini ? 22 : 24 }}
                      color={colors.gray.medium}
                      onClick={() => this.setState({ show: !show })}
                    />
                  </div>
                </InputAdornment>
              ),
            }}
          />
          {show && (
            <WrappCategories
              style={{
                width,
              }}
            >
              {withGroup ? (
                optionsToShowWithGroup.length > 0 ? (
                  optionsToShowWithGroup.map(group => {
                    const open =
                      this.state[group.title] === undefined
                        ? group.initiallyOpen
                        : this.state[group.title];
                    return (
                      <Fragment
                        key={`item-list-FragmentAutoComplete-${group.title}`}
                      >
                        <Categories
                          style={{ ...groupTitleStyle }}
                          onClick={() =>
                            this.setState(state => ({
                              [group.title]:
                                state[group.title] === undefined
                                  ? !group.initiallyOpen
                                  : !this.state[group.title],
                            }))
                          }
                        >
                          <GroupTitle mini={mini}>{group.title}</GroupTitle>
                          <IconReceived
                            name={open ? 'Remove' : 'Add'}
                            color="white"
                            size={mini ? 20 : 22}
                            style={{
                              backgroundColor: 'transparent',
                              float: 'right',
                              marginTop: '2px',
                              marginRight: '3px',
                            }}
                          />
                        </Categories>
                        <Collapse in={open}>
                          <Paper
                            style={{
                              maxHeight: '220px',
                              overflowY: 'scroll',
                              borderRadius: group.initiallyOpen
                                ? '0px 5px 5px 5px '
                                : '0px',
                              boxShadow: '0px 0px 0px 0px transparent',
                            }}
                          >
                            {group.items.map(
                              item =>
                                (item.label.toLowerCase().includes(
                                  searchStringClean ||
                                    (getValue &&
                                      getValue
                                        .toString()
                                        .toLowerCase()
                                        .trim())
                                ) ||
                                  search === '') &&
                                item.label && (
                                  <ListItem
                                    key={`item-list-AutoComplete-${item.value}`}
                                    style={{
                                      fontSize: mini ? '12px' : '14px',
                                      padding: mini ? '7px 0px 7px 10px' : '',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      onChange(item.value);
                                      this.setState({
                                        show: !show,
                                        search: null,
                                      });
                                    }}
                                  >
                                    {item.label}
                                  </ListItem>
                                )
                            )}
                          </Paper>
                        </Collapse>
                      </Fragment>
                    );
                  })
                ) : (
                  <ListItem
                    style={{
                      fontSize: mini ? '12px' : '14px',
                      padding: mini ? '7px 0px 7px 10px' : '',
                    }}
                  >
                    <NotFoundText>
                      {`Resultado não encontrado: "` + search + `"`}
                    </NotFoundText>
                  </ListItem>
                )
              ) : (
                <Fragment>
                  <Paper
                    style={{
                      maxHeight: mini ? '340px' : '480px',
                      overflowY: 'scroll',
                      borderTop: '1px solid black',
                      marginTop: mini ? '0.1px' : '0px',
                      borderRadius: '0px 5px 5px 5px ',
                      boxShadow: '0px 0px 0px 0px transparent',
                    }}
                  >
                    {optionsToShow.length > 0 ? (
                      optionsToShow.map(group => {
                        return (
                          (group.label.toLowerCase().includes(
                            searchStringClean ||
                              (getValue &&
                                getValue
                                  .toString()
                                  .toLowerCase()
                                  .trim())
                          ) ||
                            search === '') &&
                          group.label && (
                            <ListItem
                              key={`item-list-AutoComplete-${group.value}`}
                              style={{
                                fontSize: mini ? '12px' : '14px',
                                padding: mini ? '7px 0px 7px 10px' : '',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                onChange(group.value);
                                this.setState({
                                  show: !show,
                                  search: null,
                                });
                              }}
                            >
                              {group.label}
                            </ListItem>
                          )
                        );
                      })
                    ) : (
                      <ListItem
                        style={{
                          fontSize: mini ? '12px' : '14px',
                          padding: mini ? '7px 0px 7px 10px' : '',
                        }}
                      >
                        <NotFoundText>
                          {`Resultado não encontrado: "` + search + `"`}
                        </NotFoundText>
                      </ListItem>
                    )}
                  </Paper>
                </Fragment>
              )}
            </WrappCategories>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(AutoComplete);
