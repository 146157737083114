import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const domainImages = EnvVars('API_IMAGES_BASE_URL');

const serviceName = 'financingServices';

export default class DealerProviderAuthentication {
  static financingListing = (dealerId, page = 0, size = 20) => {
    return request
      .get(`${domain}/financing/${dealerId}/v1.0/listing`, { page, size })
      .then(buildHTTPResponseHandler(`${serviceName}.financingListing()`));
  };

  static getFinancingById = (dealerId, financingId) => {
    return request
      .get(`${domain}/financing/${dealerId}/v1.0/${financingId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getFinancingById()`));
  };

  static getAllFinancing = dealerId => {
    return request
      .get(`${domain}/financing/${dealerId}/v1.0`)
      .then(buildHTTPResponseHandler(`${serviceName}.getAllFinancing()`));
  };

  static financing = (dealerId, financingId = '', data) => {
    return request
      .post(
        `${domain}/financing/${dealerId}/v1.0${
          financingId ? `?financingId=${financingId}` : ''
        }`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.financing()`));
  };

  static simulate = (dealerId, financingId, data) => {
    return request
      .put(`${domain}/financing/${dealerId}/v1.0/simulate/${financingId}`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.simulate()`));
  };

  static simulateById = (dealerId, financingId, institutionId, data) => {
    return request
      .put(
        `${domain}/financing/${dealerId}/v1.0/simulate/${financingId}/${institutionId}`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.simulateById()`));
  };

  static sendClientForm = (dealerId, financingId, currentStep, data) => {
    return request
      .put(
        `${domain}/financing/${dealerId}/v1.0/creditanalyses/${financingId}/step/${currentStep}`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendClientForm()`));
  };

  static applyToCreditAnalysis = (dealerId, financingId, data) => {
    return request
      .put(
        `${domain}/financing/${dealerId}/v1.0/creditanalyses/${financingId}/apply`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.applyToCreditAnalysis()`));
  };

  static applyToSimulate = async (dealerId, financingId, data) => {
    return request
      .put(
        `${domain}/financing/${dealerId}/v1.0/simulate/${financingId}/apply`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.applyToSimulate()`));
  };

  static getCreditAnalysis = async (dealerId, financingId) => {
    return request
      .get(`${domain}/financing/${dealerId}/v1.0/creditanalyses/${financingId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getCreditAnalysis()`));
  };

  static contractFinancing = (dealerId, financingId, contractDto) => {
    return request
      .put(
        `${domain}/financing/${dealerId}/v1.0/contract/${financingId}`,
        contractDto
      )
      .then(buildHTTPResponseHandler(`${serviceName}.contractFinancing()`));
  };

  //OPTIONS
  //COMISSION_RETURN_OPTIONS
  static getcommissionReturnOptions = dealerId => {
    return request
      .get(`${domain}/financing/${dealerId}/v1.0/commission-return-options`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getcommissionReturnOptions()`)
      );
  };

  //GET_ALL_FINANCIAL_INSTITUTION_OPTIONS
  static getAllFinancialInstitution = () => {
    return request
      .get(`${domain}/financing/v1.0`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getAllFinancialInstitution()`)
      );
  };

  //BANK_ACCOUNT_TYPE
  static getBankAccountType = () => {
    return request
      .get(`${domain}/options/v1.0/bankaccount-type`)
      .then(buildHTTPResponseHandler(`${serviceName}.getBankAccountType()`));
  };
  // FINANCING NEW INSTITUTION
  static saveDataInstitution = (dealerId, data) => {
    return request
      .post(`${domain}/financing/institution/dealerpj/${dealerId}/v1.0`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.saveDataInstitution()`));
  };
  static getAllDealerPJFinancialInstitution = dealerId => {
    return request
      .get(`${domain}/financing/institution/dealerpj/${dealerId}/v1.0/all`)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllDealerPJFinancialInstitution()`
        )
      );
  };
  //DELETE_INSTITUTION
  static deleteInstitution = (dealerId, idInst) => {
    return request
      .delete(
        `${domain}/financing/institution/dealerpj/${dealerId}/v1.0/${idInst}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.deleteInstitution()`));
  };

  static updateInstitution = (dealerId, idInst, data) => {
    return request
      .put(
        `${domain}/financing/institution/dealerpj/${dealerId}/v1.0/${idInst}`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateInstitution()`));
  };

  static getByIdInstitution = (dealerId, idInst) => {
    return request
      .get(
        `${domain}/financing/institution/dealerpj/${dealerId}/v1.0/${idInst}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getByIdInstitution()`));
  };
  static uploadCETVehicle = (fileData, mediaOrigin) => {
    return request
      .post(`${domainImages}/media/v1.0?mediaOrigin=${mediaOrigin}`, fileData)
      .then(buildHTTPResponseHandler(`${serviceName}.uploadCETVehicle()`));
  };
  static getSafraOptions = dealerId => {
    return request
      .get(`${domain}/financing/${dealerId}/v1.0/safra/options`)
      .then(buildHTTPResponseHandler(`${serviceName}.uploadCETVehicle()`));
  };
  static getSafraFaixas = (dealerId, tabela, term, ano) => {
    return request
      .get(
        `${domain}/financing/${dealerId}/v1.0/safra/faixas/${tabela}/${term}/${ano}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getSafraFaixas()`));
  };
}
