import isEmpty from 'helpers/isEmpty';

const checkClientFormData = (currentForm, currentData) => {
  if (currentForm === 'PF') {
    if (
      isEmpty(currentData.name) ||
      isEmpty(currentData.birthDate) ||
      isEmpty(currentData.cpfCnpj) ||
      isEmpty(currentData.email) ||
      isEmpty(currentData.nationality) ||
      isEmpty(currentData.gender) ||
      isEmpty(currentData.civilStatus) ||
      isEmpty(currentData.rgNumber) ||
      isEmpty(currentData.rgIssuer) ||
      isEmpty(currentData.schoolingType) ||
      isEmpty(currentData.jobTitle) ||
      isEmpty(currentData.neighborhood) ||
      isEmpty(currentData.cityStateId) ||
      isEmpty(currentData.cityId)
    ) {
      return true;
    }
    return false;
  }

  if (currentForm === 'PJ') {
    if (
      isEmpty(currentData.companyStartDate) ||
      isEmpty(currentData.email) ||
      isEmpty(currentData.companyStateEnrollment) ||
      isEmpty(currentData.phone)
    ) {
      return true;
    }
    return false;
  }
};

export default checkClientFormData;
