import React from 'react';
import MicIcon from '@material-ui/icons/Mic';

// Style import
import { Container } from './styles';

interface AudioProps {
  audioTimeDuration: number;
}

export function Audio({ audioTimeDuration }: AudioProps) {

  const secondsToMinutes = (): string => {
    if (isNaN(audioTimeDuration) || audioTimeDuration < 0) return '0:00';

    const minutes = Math.floor(audioTimeDuration / 60);
    const seconds = Math.floor(audioTimeDuration % 60);
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${minutes}:${formattedSeconds}`;
  };

  return (
    <Container>
      <MicIcon />
      <p>{secondsToMinutes()}</p>
      <p>Áudio</p>
    </Container>
  );
}