/**
 * Checks if value is empty. checks arrays and objects too
 * Note: isEmpty([]) == true, isEmpty({}) == true,  == true, isEmpty({0:1}) == false
 * @param value
 * @returns {boolean}
 */

const isEmpty = value =>
  value === false ||
  typeof value === 'undefined' ||
  value === null ||
  !value ||
  value.length === 0 ||
  (Object.entries(value).length === 0 && value.constructor === Object);

export default isEmpty;
