import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  width: 240px;
  font-size: 14px;
  color: rgb(66, 66, 66);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
export const EmptyHistoryListIcon = styled.div`
  border-radius: 35px;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #e0f3f1;
`;
