import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const PlateContainer = styled.div`
  display: flex;
  border: 1px solid #757575;
  background-color: #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 22px;
  padding: 0px 4px;
  width: fit-content;
`;
