export default class StorageFactory {
  constructor(storage) {
    this.inMemoryStorage = storage;
  }

  isSupported() {
    try {
      const localStorage = window.localStorage;
      return this.testSetAndRemove() || localStorage;
    } catch (e) {
      if (this.isQuotaExceeded(e)) {
        //TODO: Storage full, maybe notify user or do some clean-up
      }
      return false;
    }
  }

  testSetAndRemove() {
    try {
      const key = 'mobiauto_test';
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  isQuotaExceeded(e) {
    let quotaExceeded = false;
    if (e) {
      if (e.code) {
        switch (e.code) {
          case 22:
            quotaExceeded = true;
            break;
          case 1014:
            // Firefox
            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              quotaExceeded = true;
            }
            break;
          default:
            quotaExceeded = true;
            break;
        }
      } else if (e.number === -2147024882) {
        // Internet Explorer 8
        quotaExceeded = true;
      }
    }
    return quotaExceeded;
  }

  clear() {
    if (this.isSupported()) {
      localStorage.clear();
    } else {
      this.inMemoryStorage = {};
    }
  }

  getItem(name) {
    if (this.isSupported()) {
      return localStorage.getItem(name);
    }
    if (Object.prototype.hasOwnProperty.call(this.inMemoryStorage, name)) {
      return this.inMemoryStorage[name];
    }
    return null;
  }

  key(index) {
    if (this.isSupported()) {
      return localStorage.key(index);
    } else {
      return Object.keys(this.inMemoryStorage)[index] || null;
    }
  }

  removeItem(name) {
    if (this.isSupported()) {
      localStorage.removeItem(name);
    } else {
      delete this.inMemoryStorage[name];
    }
  }

  setItem(name, value) {
    if (this.isSupported()) {
      localStorage.setItem(name, value);
    } else {
      this.inMemoryStorage[name] = String(value);
      // not everyone uses TypeScript
    }
  }
}
