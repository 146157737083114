import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import { ReactComponent as IconEmail } from 'assets/email.svg';
import { ReactComponent as IconMobile } from 'assets/baseline-phone-android-24-px.svg';
import PrintIcon from '@material-ui/icons/Print';
import { ReactComponent as IconCalendar } from 'assets/calendar.svg';
import { ReactComponent as IconID } from 'assets/ID.svg';

export const Button = styled.div`
  font-family: Poppins Medium;
  text-transform: initial;
  background-color: transparent;
  border: none;
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
`;

export const IconMobileStyled = styled(IconMobile)`
  cursor: pointer;
  fill: ${Colors.gray.dark};
  width: 12px;
  height: 12px;
  margin-right: 11px;
`;

export const CalendarTodayIconStyled = styled(IconCalendar)`
  cursor: pointer;
  color: ${Colors.gray.dark};
  width: 12px;
  height: 13px;
  margin-right: 11px;
`;

export const IDIconStyled = styled(IconID)`
  cursor: pointer;
  color: ${Colors.gray.dark};
  width: 12px;
  height: 10px;
  margin-right: 11px;
`;

export const PrintIconStyled = styled(PrintIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export const IconEmailStyled = styled(IconEmail)`
  cursor: pointer;
  fill: #000;
  width: 13px;
  height: 10px;
  margin-right: 11px;
`;

export const LabelInfo = styled.div`
  justify-content: space-between;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 16px;
  font-family: Poppins Bold;
  display: flex;
`;

export const NameProfile = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #333333;
  font-family: Poppins Bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

export const BlueTag = styled.div`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: Poppins Regular;
  font-size: 14px;
  line-height: 24px;
`;

export const UserInfoLabel = styled.div`
  margin-right: 8px;
`;

export const ContactSource = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

export const Item = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Poppins Regular;
`;

export const OvalBigger = styled.div`
  margin-right: 8px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: ${Colors.gray.nearWhite};
  font-family: Poppins Bold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const WrapperButtons = styled.div`
  width: 90%;
  display: flex;
  justify-content: right;
`;
