import React from 'react';
import SimulationHistory from './SimulationHistory';
import { useFinancingSimulationContext } from '../../context';

const SimulationHistoryContainer = () => {
  const {
    simulationHistoryState,
    getSimulationsQueryResult,
    financingBancoPanEnabled,
    fandiIntegration,
  }: any = useFinancingSimulationContext();

  const viewComponentProps = {
    simulationHistoryState,
    getSimulationsQueryResult,
    financingBancoPanEnabled,
    fandiIntegration,
  };
  return <SimulationHistory {...viewComponentProps} />;
};

export default SimulationHistoryContainer;
