import Lead360Icon from 'pages/chats/components/SideBarComponents/Lead360/assets/Lead360Icon';
import Lead360Logo from 'pages/chats/components/SideBarComponents/Lead360/assets/Lead360Logo';
import animationData from 'assets/lottie-loading-writing-three-points-darker.json';
import React from 'react';
import Lottie from 'react-lottie';
import ItemHeader from '../atoms/ItemHeader';
import {
  BoxMessage,
  ChatWrapper,
  ContainerMessage,
  ContainerOvalBigger,
  OvalBigger,
  TitleMessage,
} from './styles';

interface Lead360Props {
  message: string;
  isLoading: boolean;
  isTitleComplete: boolean;
}

export const Lead360Component = ({
  message,
  isLoading,
  isTitleComplete,
}: Lead360Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    isClickToPauseDisabled: false,
  };

  return (
    <>
      <ItemHeader>
        <Lead360Logo />
      </ItemHeader>
      <ChatWrapper>
        <ContainerMessage bot={true}>
          <ContainerOvalBigger>
            <OvalBigger bot={true}>
              <Lead360Icon />
            </OvalBigger>
          </ContainerOvalBigger>
          <BoxMessage bot={true}>
            {isLoading ? (
              <Lottie
                options={defaultOptions}
                height={20}
                width={40}
                speed={0.8}
                isStopped={false}
                isPaused={false}
                direction={1}
              />
            ) : (
              <div>
                <TitleMessage>{message.split('\n')?.[0]}</TitleMessage>
                {isTitleComplete && message.split('\n')?.[1]}
              </div>
            )}
          </BoxMessage>
        </ContainerMessage>
      </ChatWrapper>
    </>
  );
};
