import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import applyPhoneMask from 'helpers/applyPhoneMask';
import formatCpfCnpj from 'helpers/formatCpfCnpj';
import { escolarityTypeOptions } from 'modules/financing/helpers/defaultOptions';
import Wrapper from 'components/Wrapper';
import * as S from '../../styles';

const ClientDataPF = () => {
  const {
    queueData,
    genderOptions,
    civilStatusOptions,
    statesOptions,
    careersOptions,
  } = useSelector(state => ({
    queueData: state?.order?.queueData,
    genderOptions: state.financing?.genderOptions,
    civilStatusOptions: state.financing?.civilStatusOptions,
    statesOptions: state.financing?.statesOptions,
    careersOptions: state.financing?.careersOptions,
  }));

  const dataCompleted = queueData?.client?.formOem === 'FULL';

  return (
    <Wrapper flex direction="row">
      <Wrapper flex direction="column" width={'50%'}>
        <S.Text>
          Nome completo:
          {`  ${queueData?.client?.name ?? ''}`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Data de nascimento:
          {`  ${
            queueData?.client?.birthDate
              ? moment(queueData?.client?.birthDate).format('L')
              : ''
          }`}
        </S.Text>

        <S.Text>
          CPF:
          {`  ${
            queueData?.client?.cpfCnpj
              ? formatCpfCnpj(queueData?.client?.cpfCnpj)
              : ''
          }`}
        </S.Text>
        <S.Text>
          E-mail:
          {`  ${queueData?.client?.email ? queueData?.client?.email : ''}`}
        </S.Text>
        <S.Text>
          Telefone/Celular:
          {`  ${
            queueData?.client?.phone
              ? applyPhoneMask(queueData?.client?.phone)
              : ''
          }`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Telefone/Celular 2:
          {`  ${
            queueData?.client?.phone2
              ? applyPhoneMask(queueData?.client?.phone2)
              : ''
          }`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Nacionalidade:
          {`  ${queueData?.client?.nationality ?? ''}`}
        </S.Text>
      </Wrapper>

      <Wrapper flex direction="column">
        <S.Text notVisible={dataCompleted}>
          Sexo:
          {`  ${
            queueData?.client?.gender
              ? genderOptions?.find(
                  el => el.value === queueData?.client?.gender
                )?.label
              : ''
          }`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Estado civil:
          {`  ${
            queueData?.client?.civilStatus
              ? civilStatusOptions?.find(
                  el => el.value === queueData?.client?.civilStatus
                )?.label
              : ''
          }`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          RG:
          {`  ${queueData?.client?.rgNumber ?? ''}`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Orgão emissor:
          {`  ${queueData?.client?.rgIssuer ?? ''}`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          UF do órgão emissor:
          {`  ${
            queueData?.client?.rgIssuedState
              ? statesOptions?.find(
                  el => el.value === queueData?.client?.rgIssuedState
                )?.label
              : ''
          }`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Escolaridade:
          {`  ${
            queueData?.client?.schoolingType
              ? escolarityTypeOptions.find(
                  el => el.value === queueData?.client?.schoolingType
                )?.label
              : ''
          }`}
        </S.Text>

        <S.Text notVisible={dataCompleted}>
          Profissão:
          {`  ${
            queueData?.client?.jobTitle
              ? careersOptions?.find(
                  el => el.value == queueData?.client?.jobTitle
                )?.label
              : ''
          }`}
        </S.Text>
      </Wrapper>
    </Wrapper>
  );
};
export default ClientDataPF;
