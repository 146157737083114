import React from 'react';
import FileIcon from '@material-ui/icons/InsertDriveFile';

// Style import
import { Container } from './styles';

export function File() {
  return (
    <Container>
      <FileIcon />
      <p>Arquivo</p>
    </Container>
  );
}