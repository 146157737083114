import React from 'react';
import GenericDialog from 'components/GenericDialog';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';

const ComponentCancelPDMDialog = ({ open, onClose, cancelOrder }) => {
  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Cancelar consentimento"
      maxWidth="sm"
      DialogAction={() => (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button
            style={{
              fontWeight: 500,
              fontStyle: 'normal',
              textTransform: 'inherit',
              marginRight: '25px',
            }}
            onClick={onClose}
          >
            Voltar
          </Button>
          <Button
            style={{
              background: 'red',
              color: '#fff',
              textTransform: 'inherit',
              width: '185px',
              borderRadius: '4px',
            }}
            onClick={cancelOrder}
          >
            Cancelar
          </Button>
        </Box>
      )}
    >
      <>
        <Box
          component="div"
          fontWeight="400"
          margin="5px 0px"
          color="#757575"
          fontSize="14px"
          lineHeight="160%"
        >
          Você está cancelando um consentimento e essa ação não pode ser
          desfeita. Deseja cancelar a proposta?
        </Box>
      </>
    </GenericDialog>
  );
};

export default ComponentCancelPDMDialog;
