import React from 'react';

import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import * as S from './styles';

export function AlertSync() {
  return (
    <S.Container>
      <S.Title>
        <S.IconContainer>
          <InfoOutlinedIcon fontSize="small" />
        </S.IconContainer>
        <span>Carregando resultados</span>
      </S.Title>
      <S.ContentInfo>
        <span>Aguarde enquanto carregamos os resultados das cotações.</span>
      </S.ContentInfo>
    </S.Container>
  );
}
