import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import find from 'lodash.find';
import isEmpty from 'helpers/isEmpty';
import handlePDFBlobFile from 'helpers/handlePDFBlobFile';
import actionCreators from 'modules/order/store/reducers/actionCreators';
import OrderServices from 'modules/order/services/OrderServices';
import CheckoutComponent from './CheckoutComponent';
import useGetDeliveryOrdersById from 'modules/delivery/DeliveryKanban/hooks/useGetDeliveryOrdersById';

const CheckoutContainer = () => {
  //REDUX_HOOKS
  const {
    dealerId,
    proposalId,
    checkoutData,
    hasBankSlip,
    paymentData,
    loadingOrderStatus,
    confettiRun,
    isFinished,
    proposalDigitalEnabled,
    currentVehicle,
  } = useSelector(state => ({
    ...state.order,
    proposalDigitalEnabled:
      state.dealerStore.currentDealer?.proposalDigitalEnabled,
  }));
  const { currentDealer } = useSelector(state => state.dealerStore);
  const dispatch = useDispatch();

  const hasProductProposalDeliveryList = currentDealer?.products
    ? currentDealer?.products.some(product => product.type === 'DELIVERY_LIST')
    : false;

  const { data: proposalSelected, refetch } = useGetDeliveryOrdersById(
    proposalId
  );

  const [
    openEvaluationProposalDialog,
    setOpenEvaluationProposalDialog,
  ] = useState(false);
  const [
    openSchedulingDeliveryDialog,
    setOpenSchedulingDeliveryDialog,
  ] = useState(false);

  const [currentUser, setCurrentUser] = useState({});

  const getMeInfos = async () => {
    try {
      const { success, data }: any = await OrderServices.getMeInfo();
      if (success) {
        setCurrentUser(
          find(checkoutData?.approvals, { userId: data.id }) || {}
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickDownloadDigitalProposal = async event => {
    try {
      event.stopPropagation();
      const result: any = await OrderServices.downloadDigitalContract(
        dealerId,
        proposalId
      );
      if (result?.success) {
        handlePDFBlobFile(
          result.data,
          `pedido-${dealerId}-${proposalId}`,
          true
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDataDeliveryStep = () => {
    refetch();
  };

  useEffect(() => {
    if (!isFinished) {
      dispatch(actionCreators.setCheckoutOrder(true));
    }
    if (confettiRun) {
      setTimeout(() => dispatch(actionCreators.setConfettiRun(false)), 5000);
    }
    dispatch(actionCreators.getOrderStatus());
  }, []);

  useEffect(() => {
    getMeInfos();
  }, [checkoutData]);

  useEffect(() => {
    hasProductProposalDeliveryList && getDataDeliveryStep();
  }, [proposalId, hasProductProposalDeliveryList]);

  const isFinancing = paymentData.typeOfSale === 'FINANCED';

  const hasPendencies = !isEmpty(checkoutData?.pendencies);
  const viewComponentProps = {
    checkoutData,
    hasBankSlip,
    isFinancing,
    hasQuiver: currentDealer.externalDealerIdentification.find(
      externalDealer => externalDealer.externalProvider === 'QUIVER'
    ),
    openEvaluationProposalDialog,
    setOpenEvaluationProposalDialog,
    handleClickDownloadDigitalProposal,
    loadingOrderStatus,
    hasPendencies,
    confettiRun,
    currentUser,
    proposalDigitalEnabled,
    currentVehicle,
    openSchedulingDeliveryDialog,
    setOpenSchedulingDeliveryDialog,
    proposalSelected,
    getDataDeliveryStep,
    proposalId,
    hasProductProposalDeliveryList,
  };

  return <CheckoutComponent {...viewComponentProps} />;
};

export default CheckoutContainer;
