/* eslint-disable react/display-name */
import { ButtonBase, Chip, ChipProps, Tooltip } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Wrapper from 'components/Wrapper';
import { getMediaRequest, getMediaUrl } from 'components/getMedia';
import useModalStateManager from 'hooks/useModalStateManager';
import moment, { MomentInput } from 'moment';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { DocumentDTO } from 'services/proposalServices';
import useApproveDocument from '../../hooks/useApproveDocument';
import useDeleteDocument from '../../hooks/useDeleteDocument';
import useReproveDocument from '../../hooks/useReproveDocument';
import ModalApproval from '../ModalApproval';
import ReproveDocumentModal from '../ReproveDocumentModal';
import ComponentTableApprovals from './ComponentTableApprovals';

import { Publish, VisibilityOutlined } from '@material-ui/icons';
import UploadDocumentModal from '../UploadDocumentModal';
import { ObservationBox, ObservationText } from './styles';

export type ChipColors = Pick<ChipProps, 'color'>['color'];

export enum DocumentType {
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  PENDING = 'PENDING',
  NOT_STARTED = 'NOT_STARTED',
}

export interface DocumentStatus {
  type: DocumentType;
  label: string;
}

export interface Document {
  id?: number;
  name: string;
  required: boolean;
  documentConfigId: number;
  mediaId?: number;
  status?: {
    type: string;
    label: string;
  };
  updatedAt?: number;
  uploaded: boolean;
}

interface ContainerTableDocumentsProps {
  documents: Document[];
  updateDocumentsFunction?: () => void;
  showUploadButton?: boolean;
  dealerId?: number;
  showActionButtons?: boolean;
}

const ContainerTableDocuments = ({
  documents,
  updateDocumentsFunction,
  showUploadButton = true,
  dealerId,
  showActionButtons = true,
}: ContainerTableDocumentsProps) => {
  const queryClient = useQueryClient();
  const [currentDocument, setCurrentDocument] = useState(null);
  const uploadModalState = useModalStateManager();
  const confirmationModalState = useModalStateManager();
  const reproveDocumentModalState = useModalStateManager();
  const approvalDocumentModalState = useModalStateManager();

  const approveDocumentHook = useApproveDocument({ dealerId });
  const reproveDocumentHook = useReproveDocument({ dealerId });
  const deleteDocumentHook = useDeleteDocument({ dealerId });

  const isLoading = useMemo(
    () => approveDocumentHook.isLoading || deleteDocumentHook.isLoading,
    [approveDocumentHook.isLoading, deleteDocumentHook.isLoading]
  );

  const downloadOrViewMedia = async (docData, openInNewTab = false) => {
    if (openInNewTab) {
      const url = getMediaUrl({
        mediaId: docData.mediaId,
        mediaVcode: docData?.mediaVcode,
      });
      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${docData?.name}`);
      window.open(link, '_blank');
      return;
    }
    const media = await getMediaRequest({
      mediaId: docData?.mediaId,
      mediaVcode: docData?.mediaVcode,
    });
    const blob = new Blob([media?.data]);
    const url = window.URL.createObjectURL(blob);
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${docData?.name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleClickViewDocument = (docData: DocumentDTO) => {
    downloadOrViewMedia(docData, true);
  };

  const documentsOptions =
    documents?.length &&
    (documents || []).map(document => {
      return {
        label: document.name,
        value: document.documentConfigId,
      };
    });

  const rows = documents?.length
    ? (documents || [])?.map(document => {
        return {
          ...document,
          id: document?.id,
          documentType: document?.name,
          status: document?.status?.label || 'Aguardando',
          actions: '',
        };
      })
    : [];

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'updatedAt',
        headerName: 'Última modificação',
        flex: 0.9,
        disableColumnMenu: true,
        valueFormatter: params =>
          params?.value
            ? moment(params.value as MomentInput).format(
                'DD/MM/YYYY - HH:mm[h]'
              )
            : '-',
      },
      {
        field: 'name',
        headerName: 'Tipo de documento',
        flex: 1.5,
        disableColumnMenu: true,
      },
      {
        field: 'payment',
        headerName: 'Tipo de pagamento',
        flex: 1.5,
        disableColumnMenu: true,
      },
      {
        field: 'required',
        headerName: 'Natureza',
        flex: 1,
        disableColumnMenu: true,
        valueGetter: ({ value }) => (value ? 'Obrigatório' : 'Opcional'),
      },
      {
        field: 'reason',
        headerName: 'Observação',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ value }) => {
          return (
            <Tooltip title={value} placement="top">
              <ObservationBox>
                <ObservationText>{value ?? '-'}</ObservationText>
              </ObservationBox>
            </Tooltip>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        disableColumnMenu: true,
        renderCell: ({ row }) => {
          const chipColorOptions = {
            Aprovado: '#2E7D32',
            Reprovado: '#D32F2F',
            Pendente: '#ED6C02',
            Aguardando: '#757575',
          };

          const chipColor = chipColorOptions[row?.status];
          const chipLabel =
            row?.status === 'Aguardando' ? 'Não iniciado' : row?.status;

          return (
            <Chip
              label={chipLabel}
              variant="outlined"
              style={{
                width: '7.5rem',
                borderColor: chipColor,
                color: chipColor,
              }}
              component="div"
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Ações',
        flex: 1,
        headerClassName: 'table-header',
        cellClassName: 'table-body',
        sortable: false,
        renderCell: (params): JSX.Element => {
          const { row } = params;

          const deleteButtonIsDisabled =
            params.row.status === 'Aprovado' || isLoading;

          const notStarted = row.status === 'Aguardando';
          const approved = row.status === 'Aprovado';
          const disapproved = row.status === 'Reprovado';

          return (
            <Wrapper
              flex
              direction="row"
              height={'100%'}
              pt={16}
              pb={16}
              gap={16}
            >
              <Tooltip title="Adicionar documento" placement="top">
                <ButtonBase
                  disabled={approved}
                  onClick={() => {
                    setCurrentDocument(row);
                    uploadModalState.handleOpenModal();
                  }}
                >
                  <Wrapper flex>
                    <Publish color={approved ? 'disabled' : 'inherit'} />
                  </Wrapper>
                </ButtonBase>
              </Tooltip>

              <Tooltip title="Visualizar" placement="top">
                <ButtonBase
                  disabled={notStarted}
                  onClick={() =>
                    handleClickViewDocument(params.row as DocumentDTO)
                  }
                >
                  <Wrapper flex>
                    <VisibilityOutlined
                      color={notStarted ? 'disabled' : 'inherit'}
                    />
                  </Wrapper>
                </ButtonBase>
              </Tooltip>

              {showActionButtons && (
                <>
                  <Tooltip title="Concluir" placement="top">
                    <ButtonBase
                      onClick={() => {
                        setCurrentDocument(params.row);
                        approvalDocumentModalState.handleOpenModal();
                      }}
                      disabled={approved || notStarted}
                    >
                      <Wrapper
                        flex
                        color={isLoading ? '#7FD7D1' : '#00B4AA'}
                        fontSize={23}
                      >
                        <CheckCircleOutlineIcon
                          fontSize="inherit"
                          color={
                            approved || notStarted ? 'disabled' : 'inherit'
                          }
                        />
                      </Wrapper>
                    </ButtonBase>
                  </Tooltip>

                  <Tooltip title="Cancelar" placement="top">
                    <ButtonBase
                      onClick={() => {
                        setCurrentDocument(params.row);
                        reproveDocumentModalState.handleOpenModal();
                      }}
                      disabled={disapproved}
                    >
                      <Wrapper
                        flex
                        color={isLoading ? '#ffc266f0' : '#FF9800'}
                        fontSize={23}
                      >
                        <CancelOutlinedIcon
                          fontSize="inherit"
                          color={disapproved ? 'disabled' : 'inherit'}
                        />
                      </Wrapper>
                    </ButtonBase>
                  </Tooltip>
                </>
              )}
              {params.row.mediaId && (
                <Tooltip title="Apagar" placement="top">
                  <ButtonBase
                    onClick={() => {
                      setCurrentDocument(params.row);
                      confirmationModalState.handleOpenModal();
                    }}
                    disabled={deleteButtonIsDisabled}
                  >
                    <Wrapper
                      flex
                      color={deleteButtonIsDisabled ? '#D98C87' : '#F44336'}
                      fontSize={23}
                    >
                      <DeleteOutlinedIcon fontSize="inherit" color="inherit" />
                    </Wrapper>
                  </ButtonBase>
                </Tooltip>
              )}
            </Wrapper>
          );
        },
      },
    ],
    []
  );

  const viewComponentProps = {
    columns,
    rows,
    ...(showUploadButton && { uploadModalState }),
    documentsOptions,
    isLoading,
    updateDocumentsFunction,
  };

  return (
    <div>
      <ComponentTableApprovals {...viewComponentProps} />
      <ReproveDocumentModal
        open={reproveDocumentModalState.isOpen}
        onClose={() => {
          setCurrentDocument(null);
          reproveDocumentModalState.handleCloseModal();
        }}
        isLoading={reproveDocumentHook.isLoading}
        handleClickConfirm={reason => {
          reproveDocumentHook.mutate({
            documentId: currentDocument?.documentConfigId,
            reason,
            callbackOnSuccess: () => {
              setCurrentDocument(null);
              reproveDocumentModalState.handleCloseModal();
              updateDocumentsFunction();
              queryClient.invalidateQueries('get_all_summary_data');
            },
          });
        }}
      />

      <ModalApproval
        open={approvalDocumentModalState.isOpen}
        onClose={() => {
          setCurrentDocument(null);
          approvalDocumentModalState.handleCloseModal();
        }}
        isLoading={reproveDocumentHook.isLoading}
        handleClickConfirm={reason => {
          approveDocumentHook.mutate({
            documentId: currentDocument?.documentConfigId,
            reason,
            callbackOnSuccess: () => {
              setCurrentDocument(null);
              approvalDocumentModalState.handleCloseModal();
              updateDocumentsFunction();
              queryClient.invalidateQueries('get_all_summary_data');
            },
          });
        }}
      />

      <UploadDocumentModal
        open={uploadModalState.isOpen}
        onClose={() => {
          setCurrentDocument(null);
          uploadModalState.handleCloseModal();
        }}
        documentTypesOptions={documentsOptions}
        onSuccessUpload={updateDocumentsFunction}
        currentDocumentId={currentDocument?.documentConfigId}
        proposalOrderPaymentId={currentDocument?.proposalOrderPaymentId}
      />

      <ConfirmationDialog
        open={confirmationModalState.isOpen}
        handleOnClose={() => {
          setCurrentDocument(null);
          confirmationModalState.handleCloseModal();
        }}
        isDeleting={isLoading}
        title="Deseja excluir esse documento ?"
        labelWaitingConfirmation="Estamos deletando o documento..."
        handleOnDelete={() => {
          deleteDocumentHook.mutate({
            documentId: currentDocument?.id,
            callbackOnSuccess: () => {
              setCurrentDocument(null);
              confirmationModalState.handleCloseModal();
              updateDocumentsFunction();
              queryClient.invalidateQueries('get_all_summary_data');
            },
          });
        }}
      />
    </div>
  );
};

export default ContainerTableDocuments;
