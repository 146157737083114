import {
  EvaluationStatus,
  ColorStatus,
  ColorScheme,
} from 'pages/chats/components/SideBarComponents/Evaluation/sideBarEvaluationTypes';

const STATUS_COLOR: ColorStatus = {
  EVALUATED: {
    background: '#EDE7F6',
    color: '#311B92',
  },
  FINISHED: {
    background: '#E0F2F1',
    color: '#006064',
  },
  PENDING: {
    background: '#FCECBA',
    color: '#874900',
  },
  CLOSED: {
    background: '#00000014',
    color: '#000000D9',
  },
  NOT_REQUESTED: {
    background: '#00000014',
    color: '#000000D9',
  },
};

export const getColorStatus = (status: EvaluationStatus): ColorScheme => {
  const colorScheme = STATUS_COLOR[status] ?? {
    background: '#ffffff',
    color: '#000000',
  };

  return colorScheme;
};
