import * as React from 'react';
const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#00B2A9"
      d="M16.118 17.491a3.603 3.603 0 0 1-.703 2.012c-.763 1.012-1.938 1.571-3.494 1.66l-.018.002c-.067.005-1.358.096-2.603-.795a.641.641 0 0 1 .075-1.088l4.481-2.374a.473.473 0 0 0 .252-.408l.098-4.985a.774.774 0 0 1 1.174-.647l.786.476a.459.459 0 0 1 .22.414l-.268 5.733Z"
    />
    <path
      fill="#333"
      d="M5.83 19.07c-1.258-.155-2.33-.893-3.186-2.195l-.01-.015c-.038-.055-.763-1.128-.614-2.65a.641.641 0 0 1 .978-.481l4.3 2.692a.473.473 0 0 0 .479.013l4.364-2.41a.774.774 0 0 1 1.148.693l-.018.918a.459.459 0 0 1-.248.399l-5.1 2.637a3.6 3.6 0 0 1-2.092.398Z"
    />
    <path
      fill="#00B2A9"
      d="M1.409 10.554c-.497-1.167-.394-2.464.305-3.857l.008-.016c.029-.06.594-1.225 1.987-1.859a.641.641 0 0 1 .906.607l-.179 5.07a.473.473 0 0 0 .228.42l4.27 2.574a.774.774 0 0 1-.025 1.34l-.804.444a.459.459 0 0 1-.469-.015l-4.834-3.095a3.6 3.6 0 0 1-1.393-1.613Z"
    />
    <path
      fill="#333"
      d="M5.87 4.478c.033-.754.275-1.45.698-2.013C7.329 1.45 8.504.89 10.06.799l.018-.002c.067-.005 1.358-.098 2.604.79a.641.641 0 0 1-.073 1.088L8.131 5.057a.472.472 0 0 0-.25.409l-.09 4.985a.774.774 0 0 1-1.173.648l-.787-.474a.458.458 0 0 1-.222-.413l.26-5.734ZM20.29 15.248l-.008.016c-.029.06-.598 1.236-2.004 1.87a.641.641 0 0 1-.904-.608l.186-5.054a.472.472 0 0 0-.227-.421l-4.278-2.587a.774.774 0 0 1 .025-1.34l.805-.445a.459.459 0 0 1 .469.014l4.84 3.087a3.602 3.602 0 0 1 1.395 1.61c.498 1.166.398 2.464-.3 3.858Z"
    />
    <path
      fill="#00B2A9"
      d="M14.06 3.285a3.601 3.601 0 0 1 2.093-.402c1.26.151 2.332.888 3.19 2.189l.01.015c.038.055.765 1.126.62 2.65a.641.641 0 0 1-.979.481l-4.303-2.684a.472.472 0 0 0-.48-.013l-4.36 2.417a.774.774 0 0 1-1.149-.691l.017-.919a.459.459 0 0 1 .247-.398l5.095-2.645Z"
    />
  </svg>
);
export default SvgComponent;
