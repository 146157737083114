import styled from 'styled-components';

export const SimulationList = styled.div`
  overflow-y: auto;
  height: calc(100vh - 475px);
`;

export const PlateContainer = styled.div`
  display: flex;
  border: 1px solid #757575;
  background-color: #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 22px;
  padding: 0px 4px;
  width: fit-content;
`;

export const DividerLarge = styled.div`
  height: 8px;
  background-color: #eeeeee;
`;
