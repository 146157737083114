import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import inventoryServices from 'services/inventoryServices';
import getImage from 'components/getImage';
import { ImageItem } from './styles';

const Gallery = ({
  selectedPictures,
  setSelectedPictures,
  vehicleSelected,
}) => {
  const dispatch = useDispatch();
  const { currentProposal, currentDealer } = useSelector(state => ({
    ...state.proposalStore,
    ...state.dealerStore,
  }));

  const [vehiclePictures, setVehiclePictures] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getDealById = async dealId => {
    try {
      setLoading(true);
      const response: any = await inventoryServices.getInventoryById(
        currentDealer?.id ?? currentProposal?.dealerId,
        dealId
      );
      if (response.success) {
        const { success, data }: any = await inventoryServices.getDealByTrim(
          vehicleSelected.trimId
        );
        if (success) {
          setVehiclePictures(
            [
              { title: 'Imagens do Anúncio', images: response.data?.images },
              { title: 'Imagens Modelo', images: data.images },
            ] || []
          );
        } else {
          dispatch(
            UIActionCreators.snackbarManagement(
              'error',
              'Desculpe, Ocorreu um erro inesperado.',
              true
            )
          );
        }
      }
      setLoading(false);
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  };

  const getPicturesModelZeroKm = async () => {
    try {
      setLoading(true);

      const { success, data }: any = await inventoryServices.getDealByTrim(
        vehicleSelected.id
      );
      if (success) {
        setVehiclePictures(
          [{ title: 'Imagens Modelo', images: data.images }] || []
        );
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Desculpe, Ocorreu um erro inesperado.',
            true
          )
        );
      }
      setLoading(false);
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  };

  const useForceUpdate = () => {
    const [, setValue] = useState(0);
    return () => setValue(value => value + 1);
  };

  const forceUpdate = useForceUpdate();

  const handleSelectImage = pictureUrl => {
    const pictures = selectedPictures;
    if (pictures.includes(pictureUrl)) {
      pictures.splice(pictures.indexOf(pictureUrl), 1);
      setSelectedPictures(pictures);
      forceUpdate();
      return;
    }
    pictures.push(pictureUrl);
    setSelectedPictures(pictures);
    forceUpdate();
  };

  useEffect(() => {
    if (vehicleSelected.zeroKm) {
      getPicturesModelZeroKm();
      return;
    }
    getDealById(vehicleSelected.id);
  }, []);

  return (
    <div style={{ padding: 8 }}>
      {isLoading ? (
        <div>
          <Progress small />
        </div>
      ) : (
        <>
          {vehiclePictures.map((section, index) => {
            return (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div style={{ fontSize: 16 }}>{section.title}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container xs={12} spacing={1}>
                    {section.images.map((pic, index) => {
                      const pictureUrl =
                        getImage({
                          imageId: pic.imageId,
                          imageVcode: pic.imageVcode,
                        }) || require('assets/default-image.svg');
                      const isSelected = selectedPictures.includes(pictureUrl);
                      return (
                        <Grid item xs={4} key={index}>
                          <ImageItem
                            onClick={() => handleSelectImage(pictureUrl)}
                            isSelected={isSelected}
                          >
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                              src={pictureUrl}
                            />
                          </ImageItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      )}
    </div>
  );
};

export default Gallery;
