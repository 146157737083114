import React, { memo } from 'react';
import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import CompanyForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/CompanyForm';
import CompanyFullPayForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/CompanyFullPayForm';

import PersonalInfosForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/PersonalInfosForm';
import AddressInfosForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/AddressInfosForm';
import ReferencesForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/ReferencesForm';
import OccupationForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/OccupationForm';

import ActionsFormButton from './components/ActionsFormButton';

//EXPANSIONS_TITLES
const Employment = <ExpansionPanelTitle title={'Dados da empresa'} />;
const Client = <ExpansionPanelTitle title={'Dados do avalista'} />;
const Address = <ExpansionPanelTitle title={'Endereço do avalista'} />;
const References = <ExpansionPanelTitle title={'Referências do avalista'} />;
const Occupation = <ExpansionPanelTitle title={'Ocupação do avalista'} />;

const ClientDataPJ = props => {
  const {
    buyerCurrentData,
    handleChangeFormValues,
    handleChangeFormStep,
    handleClickFinishedStep,
    formStep,
    isFinished,
    typeOfSale,
    handleClickFinishedInCashForm
  } = props;

  return (
    <div>
      {typeOfSale === "IN_CASH" ? (
        <ExpansionPanel
          panel={{ title: Employment }}
          startExpanded
        >
          <CompanyFullPayForm
            formData={buyerCurrentData}
            onChange={handleChangeFormValues}
            disableAllInputs={isFinished}
            disableCpfCnpj
          />
          <ActionsFormButton
            currentForm="inCashCompany"
            handleChangeFormStep={() => handleClickFinishedInCashForm('inCashCompany')}
            isLastStep
            enableNext={false}
          />
        </ExpansionPanel>
      ) : (
        <>
          <ExpansionPanel
            panel={{ title: Employment }}
            openManual={isFinished ? null : formStep.employmentFormOpen.isCurrentStep}
          >
            <CompanyForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
              disableCpfCnpj
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="employmentFormOpen"
                nextFormName={'clientFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: Client }}
            openManual={isFinished ? null : formStep.clientFormOpen.isCurrentStep}
          >
            <PersonalInfosForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="clientFormOpen"
                nextFormName={'addressFormOpen'}
                returnFormName={'employmentFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                enableReturn
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: Address }}
            openManual={isFinished ? null : formStep.addressFormOpen.isCurrentStep}
          >
            <AddressInfosForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="addressFormOpen"
                nextFormName={'referencesFormOpen'}
                returnFormName={'clientFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                enableReturn
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: References }}
            openManual={isFinished ? null : formStep.referencesFormOpen.isCurrentStep}
          >
            <ReferencesForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="referencesFormOpen"
                nextFormName={'occupationFormOpen'}
                returnFormName={'addressFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                enableReturn
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: Occupation }}
            openManual={isFinished ? null : formStep.occupationFormOpen.isCurrentStep}
          >
            <OccupationForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="occupationFormOpen"
                returnFormName={'referencesFormOpen'}
                handleChangeFormStep={handleClickFinishedStep}
                enableReturn
                isLastStep
                enableNext={false}
              />
            )}
          </ExpansionPanel>
        </>
      )}
    </div>
  )
};

export default memo(ClientDataPJ);
