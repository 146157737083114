import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import {
  evaluationScoreType,
  evaluationArmorType,
} from 'modules/evaluation/types/evaluation';
import DealTypes from '../../../resale/store/reducers/orderDeal/actionTypes';

export default {
  createEvaluation: (dealerId, toEvaluationList) => {
    return {
      type: ActionTypes.CREATE_EVALUATION,
      payload: { dealerId, toEvaluationList },
    };
  },
  createEvaluationSuccess: () => {
    return {
      type: ActionTypes.CREATE_EVALUATION_SUCCESS,
    };
  },
  cleanDeal: function(): Action {
    return {
      type: ActionTypes.CLEAN_DEAL,
    };
  },
  getVehicleInfosByPlate: (
    vehicleType: string,
    plate: string,
    dealerId: string | number
  ): Action => ({
    type: ActionTypes.GET_VEHICLE_INFO_BY_PLATE,
    payload: { vehicleType, plate, dealerId },
  }),
  setVehicleType: (vehicleType: string): Action => ({
    type: DealTypes.SET_VEHICLE_TYPE,
    payload: vehicleType,
  }),
  setVehicleEvaluationType: (vehicleType: string): Action => ({
    type: ActionTypes.SET_EVALUATION_VEHICLE_TYPE,
    payload: vehicleType,
  }),
  setOwnerCPF: (ownerCPF: string): Action => ({
    type: ActionTypes.SET_EVALUATION_OWNER_CPF,
    payload: ownerCPF,
  }),
  setOwnerPhone: (ownerPhone: string): Action => ({
    type: ActionTypes.SET_EVALUATION_OWNER_PHONE,
    payload: ownerPhone,
  }),
  setOwnerEmail: (ownerEmail: string): Action => ({
    type: ActionTypes.SET_EVALUATION_OWNER_EMAIL,
    payload: ownerEmail,
  }),
  setOwnerName: (name: string): Action => ({
    type: ActionTypes.SET_EVALUATION_OWNER_NAME,
    payload: name,
  }),
  setFuelId: (fuelId: number): Action => ({
    type: ActionTypes.SET_EVALUATION_FUEL_ID,
    payload: fuelId,
  }),
  setVin: (vin: string): Action => ({
    type: ActionTypes.SET_EVALUATION_VIN,
    payload: vin,
  }),
  setTrim: (trimId: number, trimName: string): Action => ({
    type: ActionTypes.SET_EVALUATION_TRIM,
    payload: { trimId, trimName },
  }),
  setTradeIn: (tradein: boolean): Action => ({
    type: ActionTypes.SET_EVALUATION_TRADEIN,
    payload: tradein,
  }),
  setModel: (modelName: string, modelId: number): Action => {
    return {
      type: ActionTypes.SET_EVALUATION_MODEL,
      payload: {
        modelName,
        modelId,
      },
    };
  },
  setRenavam: (renavam: string): Action => ({
    type: ActionTypes.SET_EVALUATION_RENAVAM,
    payload: renavam,
  }),
  setColorId: (colorId: number): Action => ({
    type: ActionTypes.SET_EVALUATION_COLOR_ID,
    payload: colorId,
  }),
  setDealerId: (dealerId: number): Action => ({
    type: ActionTypes.SET_EVALUATION_DEALER_ID,
    payload: dealerId,
  }),
  setArmorType: (armorType: evaluationArmorType): Action => ({
    type: ActionTypes.SET_EVALUATION_ARMOR_TYPE,
    payload: armorType,
  }),
  setArmored: (armored: boolean): Action => ({
    type: ActionTypes.SET_EVALUATION_ARMORED,
    payload: armored,
  }),
  setDoors: (doors: number): Action => ({
    type: ActionTypes.SET_EVALUATION_DOORS,
    payload: doors,
  }),
  setEvaluationScore: (evaluationScore: evaluationScoreType): Action => ({
    type: ActionTypes.SET_EVALUATION_EVALUATION_SCORE,
    payload: evaluationScore,
  }),
  setEvaluator: (evaluator: string): Action => ({
    type: ActionTypes.SET_EVALUATION_EVALUATOR,
    payload: evaluator,
  }),
  set0km: (is0km: boolean): Action => ({
    type: ActionTypes.SET_EVALUATION_0KM,
    payload: is0km,
  }),
  setKm: (km: number): Action => ({
    type: ActionTypes.SET_EVALUATION_KM,
    payload: km,
  }),
  setPlate: (plate: string): Action => ({
    type: ActionTypes.SET_EVALUATION_PLATE,
    payload: plate,
  }),
  setTransmission: (transmissionId: number): Action => ({
    type: ActionTypes.SET_EVALUATION_TRANSMISSION,
    payload: transmissionId,
  }),
  setMake: (makeId: number, makeName: string): Action => ({
    type: ActionTypes.SET_EVALUATION_MAKE,
    payload: { makeId, makeName },
  }),
  setModelYear: (modelYear: number): Action => ({
    type: ActionTypes.SET_EVALUATION_MODEL_YEAR,
    payload: modelYear,
  }),
  setProductionYear: (productionYear: number): Action => ({
    type: ActionTypes.SET_EVALUATION_PRODUCTION_YEAR,
    payload: productionYear,
  }),
  setManufacturingPackageCode: (manufacturingPackageCode: string): Action => ({
    type: ActionTypes.SET_EVALUATION_MANUFACTURING_PACKAGE_CODE,
    payload: manufacturingPackageCode,
  }),
  searchTransmissions: (trim: any): Action => ({
    type: ActionTypes.SEARCH_TRANSMISSIONS,
    payload: trim,
  }),
  setFuel: (fuelId): Action => ({
    type: ActionTypes.SET_EVALUATION_FUEL_ID,
    payload: fuelId,
  }),
  searchFuels: (trim): Action => ({
    type: ActionTypes.SEARCH_FUELS,
    payload: trim,
  }),
  setVehicleInfosByPlate: (state: any): Action => ({
    type: ActionTypes.GET_EVALUATION_VEHICLE_INFOS_BY_PLATE,
    payload: state,
  }),
  searchingPlate: (): Action => ({
    type: ActionTypes.SEARCHING_BY_PLATE,
  }),
  searchingPlateError: (error: any): Action => ({
    type: ActionTypes.ERROR_SEARCHING_BY_PLATE,
    payload: error,
  }),
  searchedPlate: (): Action => ({
    type: ActionTypes.SEARCHED_BY_PLATE,
  }),
};
