import buildHTTPResponseHandler from "helpers/buildHTTPResponseHandlerTS";
import {requestModule as request} from "./index";

const serviceName = 'BankAccountServices';

export class BankAccountServices {
    static list = async (): Promise<any> => {
      const response = await request.get(
        'bankaccount/v1.0/bankaccounts');

      const responseHandler = buildHTTPResponseHandler(
        `${serviceName}.list()`
      );

    return responseHandler(response);
  };

  static show = async (id: number | string): Promise<any> => {
    const response = await request.get(
      `bankaccount/v1.0/bankaccounts/${id}`);

    const responseHandler = buildHTTPResponseHandler(
      `${serviceName}.show()`
    );

  return responseHandler(response);
  };

  static post = async (payload: {name: string}): Promise<any> => {
    const response = await request.get(
      `bankaccount/v1.0/bankaccounts`, payload);

    const responseHandler = buildHTTPResponseHandler(
      `${serviceName}.post()`
    );

  return responseHandler(response);
  };
}

export default BankAccountServices; 