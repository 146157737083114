import React from 'react';
import { useSelector } from 'react-redux';
import ClientDataPF from './ClientDataPF';
import ClientDataPJ from './ClientDataPJ';

const SummaryClientData = () => {
  const { queueData } = useSelector(state => state.order);

  return queueData?.client?.cpfCnpj?.replace(/\D/g, '')?.length > 11 ? (
    <ClientDataPJ />
  ) : (
    <ClientDataPF />
  );
};
export default SummaryClientData;
