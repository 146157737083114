import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

interface State {
  aggregations: object;
  deals: Array<any>;
  description: string;
  numResults: number;
  results: Array<any>;
  sop: string;
  paramsAndSop: any;
  steps: number;
  title: string;
  isLoading: boolean;
  filters: any;
}

export const INITIAL_STATE: State = {
  aggregations: {},
  deals: [],
  description: '',
  numResults: 0,
  results: [],
  sop: '',
  paramsAndSop: {
    sop: null,
    params: {
      page: 0,
      size: 20,
      sort: 4,
    },
  },
  steps: 0,
  title: '',
  isLoading: false,
  filters: {},
};

export default function proposal(
  state: State = INITIAL_STATE,
  action: Action
): State {
  switch (action.type) {
    case 'RESET_ALL_STORE':
      return { ...INITIAL_STATE };
    case ActionTypes.SET_DEALS_SUCESS: {
      return { ...state, ...action.payload.data };
    }
    case ActionTypes.SET_DEALS_LOADING: {
      return { ...state, isLoading: action.payload.isLoading };
    }
    case ActionTypes.SET_FILTERS: {
      return { ...state, filters: action.payload.currentFilters };
    }
    case ActionTypes.SET_PARAMS_AND_SOP: {
      return { ...state, paramsAndSop: action.payload.currentParamsAndSop };
    }
    default:
      return state;
  }
}
