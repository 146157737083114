import { all, takeLatest, put, select } from 'redux-saga/effects';
import { getNegotiationServicesByDealerProduct } from 'helpers/corporativeSaleServicesToggleWithResaleProposals';
import { dealerHasProductGeneration } from 'hooks/useDealerHasProducts';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import { Action } from 'types/ActionTypes';

function* getCurrentProposalById(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');

    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).getCurrentProposalById(
      currentDealer.id,
      action.payload.currentProposalId
    );
    if (response.success) {
      yield put(ActionCreators.setCurrentProposal(response.data));
    } else {
      yield put(ActionCreators.setCurrentProposal([]));
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Houve um erro!',
        isOpen: true,
      });
    }
  } catch (e) {
    yield put(ActionCreators.setCurrentProposal([]));
    ActionCreators.updateSnackbarState({
      variant: 'error',
      message: 'Houve um erro!',
      isOpen: true,
    });
  }
}

function* getBuyerCurrentProposalById(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).getBuyerCurrentProposalById(
      currentDealer.id,
      action.payload.currentProposalId
    );
    if (response.success) {
      yield put(ActionCreators.setCurrentProposal(response.data));
    } else {
      yield put(ActionCreators.setCurrentProposal([]));
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Houve um erro!',
        isOpen: true,
      });
    }
  } catch (e) {
    yield put(ActionCreators.setCurrentProposal([]));
    ActionCreators.updateSnackbarState({
      variant: 'error',
      message: 'Houve um erro!',
      isOpen: true,
    });
  }
}

function* getSalesNegotiations(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).getSalesNegotiations(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.archived
    );
    if (response.success) {
      yield put(ActionCreators.setNegotiations(response.data));
    } else {
      yield put(ActionCreators.setNegotiations([]));
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Houve um erro!',
        isOpen: true,
      });
    }
  } catch (e) {
    yield put(ActionCreators.setNegotiations([]));
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Houve um erro!',
        isOpen: true,
      })
    );
  }
}

function* getBuyerNegotiations(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).getBuyerNegotiations(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.archived
    );
    if (response.success) {
      yield put(ActionCreators.setNegotiations(response.data));
    } else {
      yield put(ActionCreators.setNegotiations([]));
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Houve um erro!',
        isOpen: true,
      });
    }
  } catch (e) {
    yield put(ActionCreators.setNegotiations([]));
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Houve um erro!',
        isOpen: true,
      })
    );
  }
}

function* getSalesComboNegotiations(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).getSalesComboNegotiations(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.archived
    );
    if (response.success) {
      yield put(ActionCreators.setNegotiations(response.data));
    } else {
      yield put(ActionCreators.setNegotiations([]));
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Houve um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(ActionCreators.setNegotiations([]));
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Houve um erro!',
        isOpen: true,
      })
    );
  }
}

function* setNegotiationArchived(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).setForNegotiationArchive(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.negotiationId
    );
    if (response.success) {
      yield put(
        ActionCreators.getSalesNegotiations(
          action.payload.currentProposalId,
          false
        )
      );
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Negociação arquivada',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendAcceptBidCombo(action: Action) {
  try {
    const isAccept = true;
    const isRefused = false;
    const openDialog = true;
    yield put(
      ActionCreators.manageConfirmationDialog(openDialog, isAccept, isRefused)
    );
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendAcceptBid(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const responseHigherCurrentBid = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).getHigherCurrentBid(
      currentDealer.id,
      action.payload.dealId,
      action.payload.bid
    );
    if (responseHigherCurrentBid.success) {
      if (!responseHigherCurrentBid.data.higherBid) {
        const isAccept = true;
        const isRefused = false;
        const openDialog = true;
        yield put(
          ActionCreators.manageConfirmationDialog(
            openDialog,
            isAccept,
            isRefused
          )
        );
      } else {
        yield put(ActionCreators.manageHigherBidDialog(true));
      }
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendConfirmAcceptBid(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).sendAcceptBid(
      currentDealer.id,
      action.payload.negotiationId,
      action.payload.currentProposalId
    );
    if (response.success) {
      if (action.payload.isCombo) {
        yield put(
          ActionCreators.getSalesComboNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      } else {
        yield put(
          ActionCreators.getSalesNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      }
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Proposta aceita!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendConfirmBuyerAcceptBid(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).sendBuyerAcceptBid(
      currentDealer.id,
      action.payload.negotiationId,
      action.payload.currentProposalId
    );
    if (response.success) {
      if (action.payload.isCombo) {
        yield put(
          ActionCreators.getSalesComboNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      } else {
        yield put(
          ActionCreators.getSalesNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      }
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Proposta aceita!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendRefuseBid(action: Action) {
  try {
    // yield put(ActionCreators.setIsLoading())
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).sendRefuseBid(
      currentDealer.id,
      action.payload.negotiationId,
      action.payload.currentProposalId
    );
    if (response.success) {
      if (action.payload.isCombo) {
        yield put(
          ActionCreators.getSalesComboNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      } else {
        yield put(
          ActionCreators.getSalesNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      }
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Proposta Recusada!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendBuyerRefuseBid(action: Action) {
  try {
    // yield put(ActionCreators.setIsLoading())
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).sendBuyerRefuseBid(
      currentDealer.id,
      action.payload.negotiationId,
      action.payload.currentProposalId
    );
    if (response.success) {
      if (action.payload.isCombo) {
        yield put(
          ActionCreators.getSalesComboNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      } else {
        yield put(
          ActionCreators.getSalesNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      }
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Proposta Recusada!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendNewBid(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).sendNewBid(currentDealer.id, action.payload.negotiationObject);
    if (response.success) {
      if (action.payload.isCombo) {
        yield put(
          ActionCreators.getSalesComboNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      } else {
        yield put(
          ActionCreators.getSalesNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      }
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Contra-proposta enviada!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendBuyerNewBid(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).sendBuyerNewBid(currentDealer.id, action.payload.negotiationObject);
    if (response.success) {
      if (action.payload.isCombo) {
        yield put(
          ActionCreators.getSalesComboNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      } else {
        yield put(
          ActionCreators.getSalesNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      }
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Contra-proposta enviada!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* getInteractions(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).getInteractionsByProposal(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.negotiationId,
      action.payload.lastInteractionId
    );
    if (response.success) {
      yield put(
        ActionCreators.setInteractions(
          response.data,
          action.payload.negotiationId
        )
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendInteraction(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    yield getNegotiationServicesByDealerProduct(hasProduct).sendInteraction(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.proposalResaleInteraction,
      action.payload.mediaType
    );

    const tempInteraction = [
      {
        date: Date.now(),
        fromBuyer: action.payload.proposalResaleInteraction.fromBuyer,
        id: action.payload.lastInteractionId + 1,
        message: action.payload.proposalResaleInteraction.message,
        systemic: false,
        visualized: true,
      },
    ];

    yield put(
      ActionCreators.setInteractions(
        tempInteraction,
        action.payload.negotiationId
      )
    );
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* sendBuyerInteraction(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).sendBuyerInteraction(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.proposalResaleInteraction,
      action.payload.mediaType
    );
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* visualizeMessages(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    yield getNegotiationServicesByDealerProduct(hasProduct).visualizeMessages(
      currentDealer.id,
      action.payload.negotiationId,
      action.payload.currentProposalId,
      action.payload.fromBuyer
    );
  } catch (e) {
    console.log(e);
  }
}

function* updateProposal(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).updateProposal(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.vehicles
    );
    if (response.success) {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Proposta atualizada com sucesso!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

function* setSellerToNegotiation(action: Action) {
  try {
    const { currentDealer }: any = yield select(state => state.dealerStore);
    const hasProduct = yield* dealerHasProductGeneration('CORPORATIVE_SALE');
    const response = yield getNegotiationServicesByDealerProduct(
      hasProduct
    ).setSellerToNegotiation(
      currentDealer.id,
      action.payload.currentProposalId,
      action.payload.negotiationId,
      action.payload.sellerId
    );
    if (response.success) {
      if (action.payload.isCombo) {
        yield put(
          ActionCreators.getSalesComboNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      } else {
        yield put(
          ActionCreators.getSalesNegotiations(
            action.payload.currentProposalId,
            false
          )
        );
      }
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'success',
          message: 'Vendedor alterado!',
          isOpen: true,
        })
      );
    } else {
      yield put(
        ActionCreators.updateSnackbarState({
          variant: 'error',
          message: 'Ocorreu um erro!',
          isOpen: true,
        })
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.updateSnackbarState({
        variant: 'error',
        message: 'Ocorreu um erro!',
        isOpen: true,
      })
    );
  }
}

export default all([
  takeLatest(ActionTypes.GET_CURRENT_PROPOSAL_BY_ID, getCurrentProposalById),
  takeLatest(
    ActionTypes.GET_BUYER_CURRENT_PROPOSAL_BY_ID,
    getBuyerCurrentProposalById
  ),
  takeLatest(ActionTypes.GET_SALES_NEGOTIATION, getSalesNegotiations),
  takeLatest(ActionTypes.GET_BUYER_NEGOTIATION, getBuyerNegotiations),
  takeLatest(
    ActionTypes.GET_SALES_COMBO_NEGOTIATION,
    getSalesComboNegotiations
  ),
  takeLatest(ActionTypes.SET_NEGOTIATION_ARCHIVED, setNegotiationArchived),
  takeLatest(ActionTypes.SEND_ACCEPT_BID_COMBO, sendAcceptBidCombo),
  takeLatest(ActionTypes.SEND_ACCEPT_BID, sendAcceptBid),
  takeLatest(ActionTypes.SEND_CONFIRM_ACCEPT_BID, sendConfirmAcceptBid),
  takeLatest(
    ActionTypes.SEND_CONFIRM_BUYER_ACCEPT_BID,
    sendConfirmBuyerAcceptBid
  ),
  takeLatest(ActionTypes.SEND_REFUSED_BID, sendRefuseBid),
  takeLatest(ActionTypes.SEND_BUYER_REFUSED_BID, sendBuyerRefuseBid),
  takeLatest(ActionTypes.SEND_NEW_BID, sendNewBid),
  takeLatest(ActionTypes.SEND_BUYER_NEW_BID, sendBuyerNewBid),
  takeLatest(ActionTypes.SEND_INTERACTION, sendInteraction),
  takeLatest(ActionTypes.SEND_BUYER_INTERACTION, sendBuyerInteraction),
  takeLatest(ActionTypes.VISUALIZE_MESSAGES, visualizeMessages),
  takeLatest(ActionTypes.UPDATE_PROPOSAL, updateProposal),
  takeLatest(ActionTypes.SET_SELLER_TO_NEGOTIATION, setSellerToNegotiation),
  takeLatest(ActionTypes.GET_INTERACTIONS, getInteractions),
]);
