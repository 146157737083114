import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { Select } from 'types/SelectType';
import { ContactCardInfosDTO } from 'modules/financial/types';

export default {
  //REDUCCERS
  setDealer: (dealer: any): Action => ({
    type: ActionTypes.SAVE_DEALER_INFOS,
    payload: { dealer },
  }),

  setBankAccountOptions: (bankAccounts: Select[]): Action => ({
    type: ActionTypes.SAVE_BANKACCOUNT,
    payload: { bankAccounts },
  }),

  setSellersIds: (sellersIds: Select[]): Action => ({
    type: ActionTypes.SAVE_SELLERS_IDS,
    payload: { sellersIds },
  }),

  setPaymentMethods: (paymentMethodsOptions: Select[]) => ({
    type: ActionTypes.SAVE_PAYMENT_METHOD,
    payload: { paymentMethodsOptions },
  }),

  saveContacts: (contacts: ContactCardInfosDTO[]): Action => ({
    type: ActionTypes.SAVE_CONTACTS,
    payload: { contacts },
  }),

  //SAGAS
  loadDealerDataOptions: (): Action => ({
    type: ActionTypes.LOAD_DEALER_DATA_OPTIONS,
    payload: {},
  }),

  getDealerContacts: (query: string): Action => ({
    type: ActionTypes.GET_DEALER_CONTACTS_REQUEST,
    payload: { query },
  }),
};
