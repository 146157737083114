import styled, { keyframes } from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MessagesScrollView = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 128px);
  overflow-y: auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 75px;
  overflow: hidden;
`;

export const ContainerMessage = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  justify-content: ${props => (props.bot ? 'initial' : 'flex-end')};
`;

export const ContainerTriangle = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: ${props => (props.bot ? '10px' : '0px')} solid transparent;
  border-right: ${props => (props.bot ? '0px' : '10px')} solid transparent;
  border-bottom: 10px solid ${props => (props.bot ? '#0b5895' : '#006B5D')};
`;

export const ContainerOvalBigger = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const OvalBigger = styled.div`
  padding: 0px 4px;
  height: 32px;
  width: 26px;
  background-color: ${props => (props.bot ? '#E0F3F1' : '#EEE')};
  font-family: Poppins Bold;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 0px;
`;

export const BoxMessage = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-color: ${props => (props.bot ? '#0b5895' : '#006B5D')};
  color: #ffffff;
  margin: ${props => (props.bot ? '0px 32px 0px 0px' : '0px 0px 0px 32px')};
  padding: 12px 16px;
  border-radius: ${props =>
    props.bot ? '4px 4px 4px 0px' : '4px 4px 0px 4px'};
`;

export const ContainerSugestionsMessages = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  height: auto;
  flex-wrap: wrap;
  flex-direction: row-wrap;
  padding: 8px;
  gap: 8px;
`;

export const SugestionsMessages = styled.div`
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background: #ffffff;
  color: #424242;
  border: 1px solid #bdbdbd;
  border-radius: 90px;
  padding: 12px 16px;
  height: max-content;
  width: max-content;
  :hover {
    cursor: pointer;
  }
`;

export const CopyMessageForInputWhatsApp = styled.div`
  display: flex;
  margin-left: 5px;
  height: 15px;
  width: 30px;
  :hover {
    cursor: pointer;
  }
`;

const leftoToRight = keyframes`
  from { opacity: 0; left:100px;}
  to { opacity: 1; left:0px;}
`;

export const AnimatedLeftToRight = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  animation: ${leftoToRight} 0.3s linear;
`;

export const GoToBottomButtom = styled.div`
  position: absolute;
  bottom: 73px;
  left: 50%;
  width: 35px;
  height: 35px;
  background: #ffd200;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 10;
`;
