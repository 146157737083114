import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Wrapper from 'components/Wrapper';
import * as S from '../../styles';

const SummaryDelivery = () => {
  const { queueData } = useSelector(state => state.order);

  return (
    <Wrapper flex direction="row" gap={24}>
      <S.Text>
        ID do pedido:
        {`  ${
          queueData?.delivery?.externalId ? queueData?.delivery?.externalId : ''
        }`}
      </S.Text>

      <S.Text>
        Previsão de entrega:
        {`  ${
          queueData?.delivery?.forecastDate
            ? moment(queueData?.delivery?.forecastDate).format('L')
            : ''
        } `}
      </S.Text>

      <S.Text>
        {queueData?.status?.value === 'NEW' ? '' : 'Pedido enviado à montadora'}
      </S.Text>
    </Wrapper>
  );
};
export default SummaryDelivery;
