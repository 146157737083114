import { init as initApm } from '@elastic/apm-rum';

export default {
  init: () => {
    initApm({
      serviceName: 'front-end-painel-digital',
      serverUrl:
        'https://9d800515e7e74042be4fc3b3d903c92d.apm.us-east1.gcp.elastic-cloud.com/',
      environment: 'production',
    });
  },
  logError: error => {
    const data = {};
    const isRequestError = error.type && error.type === 'RequestError';

    if (isRequestError) {
      data.extra = { request: error.request, response: error.response };
    } else {
      data.extra = { ...error };
    }
  },
};
