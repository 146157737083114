import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import EvaluationCard from './components/Card';
import ItemHeader from '../atoms/ItemHeader';
import PricingVehicle from './components/PricingVehicle';

import * as S from './styles';

export default function EvaluationComponent({
  handleOpenModal,
  currentDealer,
  modal,
  setModal,
  list = [],
  setList,
  currentProposal,
  loading = false,
  dealerBelongsToSagaGroup = false,
  isLoadingCheckDealerBelongsToGroup = false,
}) {
  return (
    <>
      <ItemHeader>Avaliação</ItemHeader>
      <S.MainContainer>
        <S.Container>
          {loading && (
            <div style={{ textAlign: 'center', margin: '1rem auto' }}>
              <CircularProgress />
            </div>
          )}
          {!loading && !!list?.length && (
            <>
              {list?.map(item => (
                <EvaluationCard
                  key={item.id}
                  item={item}
                  currentDealer={currentDealer}
                  currentProposal={currentProposal}
                  isLoading={loading}
                  dealerBelongsToSagaGroup={dealerBelongsToSagaGroup}
                />
              ))}
            </>
          )}
        </S.Container>
        <S.ButtonBox>
          <S.Button variant="contained" onClick={handleOpenModal}>
            + Solicitar avaliação
          </S.Button>
        </S.ButtonBox>
      </S.MainContainer>
      {modal && (
        <PricingVehicle
          modal={modal}
          setModal={setModal}
          currentProposal={currentProposal}
          setList={setList}
          dealerBelongsToSagaGroup={dealerBelongsToSagaGroup}
          isLoadingCheckDealerBelongsToGroup={
            isLoadingCheckDealerBelongsToGroup
          }
        />
      )}
    </>
  );
}
