import React from 'react';
import styled from 'styled-components';

import Button, { ButtonProps } from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

import colors from '../../mobiauto.web-ui-components.components.colors/colors';

const commonStyles: CSSProperties = {
  borderRadius: 4,
  height: 40,
  padding: '0 33px',
  fontSize: '14px',
  fontWeight: 'bold',
  fontFamily: ['Poppins', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
    ','
  ),
};

const DefaultButton = withStyles({
  root: {
    ...commonStyles,
    backgroundColor: colors.primary.medium,
    border: 0,
    color: 'white',
    '&:hover': {
      backgroundColor: '#00838c',
    },
    '&:active': {
      backgroundColor: '#00666e',
    },
    '&:disabled': {
      opacity: 0.5,
      color: 'white',
    },
  },
})(Button);

const BrightButton = withStyles({
  root: {
    ...commonStyles,
    backgroundColor: colors.primary.medium,
    boxShadow: `0px 0px 6px 0px ${colors.primary.medium}`,
    border: 0,
    color: 'white',
    '&:hover': {
      backgroundColor: '#00838c',
      boxShadow: `0px 0px 4px 0px #00838c `,
    },
    '&:active': {
      backgroundColor: '#00666e',
    },
    '&:disabled': {
      opacity: 0.5,
      color: 'white',
    },
  },
})(Button);

const OutlinedButton = withStyles({
  root: {
    ...commonStyles,
    background: 'transparent',
    border: 1,
    borderStyle: 'solid',
    borderColor: colors.primary.medium,
    color: colors.primary.medium,
    '&:hover': {
      color: colors.primary.dark,
      background: 'transparent',
      borderColor: colors.primary.dark,
    },
    '&:disabled': {
      opacity: 0.5,
      color: colors.primary.medium,
    },
  },
})(Button);

const TextButton = withStyles({
  root: {
    ...commonStyles,
    fontWeight: 700,
    background: 'transparent',
    border: 0,
    color: colors.primary.secondary,
    '&:hover': {
      color: '#000',
      background: 'transparent',
    },
    '&:disabled': {
      opacity: 0.5,
      color: colors.primary.secondary,
    },
  },
})(Button);

const UnstyledButton = withStyles({
  root: {
    margin: 0,
    border: 0,
    padding: 0,
    minWidth: 0,
  },
})(Button);

const HyperlinkButton = withStyles({
  root: {
    ...commonStyles,
    fontWeight: 700,
    height: 'auto',
    padding: '0 5px',
    background: 'transparent',
    border: 0,
    color: colors.primary.medium,
    textDecoration: 'underline',
    '&:hover': {
      background: 'transparent',
    },
    '&:disabled': {
      opacity: 0.5,
      color: colors.primary.medium,
    },
  },
})(Button);

const StyledHyperlinkButton = styled(HyperlinkButton)`
  color: ${props =>
    props.color === 'secondary'
      ? colors.secondary.medium
      : colors.primary.medium} !important;
`;

interface Props extends Omit<ButtonProps, 'variant'> {
  variant?:
    | 'bright'
    | 'outlined'
    | 'text'
    | 'hyperlink'
    | 'unstyled'
    | 'primary';
}

export default function CustomButton({ variant, ...props }: Props) {
  if (variant === 'bright') return <BrightButton {...props} />;
  if (variant === 'outlined') return <OutlinedButton {...props} />;
  if (variant === 'text') return <TextButton {...props} />;
  if (variant === 'hyperlink') return <StyledHyperlinkButton {...props} />;
  if (variant === 'unstyled') return <UnstyledButton {...props} />;
  return <DefaultButton {...props} />;
}
