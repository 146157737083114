import styled from 'styled-components';

import MUIRadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/styles';

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SearchBox = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  & > .MuiFormControl-root {
    flex: 1 auto;
  }
`;

export const EmptyMessage = styled('p')`
  color: #424242;
  margin: 0;
`;

export const RadioGroup = withStyles(() => ({
  root: {
    '& .MuiTypography-body1': {
      fontFamily: 'Poppins Regular',
      fontSize: '1rem',
      color: '#424242',
    },
  },
}))(MUIRadioGroup);
