import styled from 'styled-components';

export const IconContainer = styled.div`
  background-color: rgb(68, 67, 188);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

export const Space = styled.div`
  height: 16px;
`;
