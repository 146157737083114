import React from 'react';

import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

const useStyles = makeStyles({
  root: {
    '& .table-header': {
      fontFamily: 'Poppins Regular',
      fontWeight: 500,
      color: `${Colors.gray.dark}`,
    },
    '& .table-body': {
      fontFamily: 'Poppins Regular',
      color: `${Colors.gray.medium}`,
      borderBottom: `1px solid ${Colors.gray.nearWhite}`,
    },
    '& .MuiDataGrid-colCellTitle': {
      whiteSpace: 'nowrap !important',
    },
  },
});

interface TableProps extends DataGridProps {
  rows: Array<any>;
  columns: Array<any>;
  pageSize?: number;
  hidePagination?: boolean;
  [propName: string]: any;
}

export default function TableDataGrid({
  rows,
  columns,
  pageSize,
  hidePagination,
  extraStyles = {},
  ...rest
}: TableProps): JSX.Element {
  const classes = useStyles();
  return (
    <div style={{ width: '100%', height: 'auto', ...extraStyles }}>
      <DataGrid
        className={classes.root}
        rows={rows}
        columns={columns}
        rowCount={rows.length}
        pageSize={pageSize || null}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        hideFooterPagination={hidePagination || false}
        hideFooter={hidePagination || false}
        {...rest}
      />
    </div>
  );
}
