import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from 'components/atoms/Chip';
import InformationTooltipIcon from 'components/atoms/InformationTooltipIcon';

const ProposalCard = ({ text, creditStatus, error }) => {
  const creditTypes = {
    'Crédito aprovado': { type: 'high', label: 'Crédito aprovado' },
    'Proposta aprovada': { type: 'high', label: 'Proposta aprovada' },
    'Sujeito a análise': { type: 'medium', label: 'Sujeito a análise' },
    'Crédito recusado': { type: 'low', label: 'Crédito recusado' },
    Contraproposta: { type: 'medium', label: 'Contraproposta' },
    'Contraoferta Sujeito a análise': {
      type: 'small',
      label: 'Contraoferta Sujeito a análise',
    },
  };

  return (
    <Grid item xs={12}>
      {text && (
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          border="1px solid #E0E0E0"
          borderRadius="4px"
          padding="8px 16px"
          alignItems="center"
        >
          <Box component="div" display="flex">
            {text}
            <div style={{ width: 8 }} />
            {!!error && <InformationTooltipIcon color="#e00606" text={error} />}
          </Box>
          {creditTypes?.[creditStatus] && (
            <Chip
              type={creditTypes?.[creditStatus].type || 'medium'}
              shape="square"
              margin="0px"
              fontSize="12px"
            >
              {creditTypes?.[creditStatus].label}
            </Chip>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default ProposalCard;
