import React from 'react';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const ALERT_STATUS = {
  PRE_APPROVED: {
    text: `Você tem um crédito pré aprovado de <strong>R$ 50.000</strong> com
  parcela até R$ 1.895,00 com prazo de até 48x e 10% de entrada.`,
    backgroundColor: '#2196F3',
  },
  NOT_ELEGIVEL: {
    text: `Não elegível a um financiamento com o Banco PAN`,
    backgroundColor: '#F44336',
  },
  NOT_FINANCING_ELEGIVEL: {
    text: `Não elegível a um financiamento`,
    backgroundColor: '#F44336',
  },
};

const Alert = ({ status, message }: { status: string; message: string }) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        margin="16px 0px"
        padding="8px"
        borderRadius="4px"
        style={{ backgroundColor: ALERT_STATUS[status].backgroundColor }}
        color="#fff"
      >
        <Box component="div" display="flex" margin="10px">
          <CheckCircleOutlineIcon />
        </Box>
        <div
          style={{
            textAlign: 'justify',
            fontSize: '14px',
            lineHeight: '21px',
            fontFamily: 'Poppins Medium',
            wordSpacing: '2px',
          }}
        >
          {message}
        </div>
      </Box>
    </>
  );
};

export default Alert;
