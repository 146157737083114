import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const ImageItem = styled.div`
  display: flex;
  cursor: pointer;
  height: 200px;
  border: ${({ isSelected }) =>
    isSelected ? `4px solid ${Colors.primary.medium}` : '4px solid #fff'};
  &:hover {
    border-color: ${Colors.primary.medium};
  }
`;
