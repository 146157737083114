import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import envVars from 'helpers/envVars';
import request from 'helpers/request';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import { FilterOption } from '../interfaces';
import useCustomMutation from './useCustomMutation';

interface Props {
  apiProperty: string;
  selectedValues?: FilterOption[];
}

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'DealerServices';

export default function useDepartments({ apiProperty, selectedValues }: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[] | null>(
    null
  );

  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  const dealerId = currentDealer.id;

  function useGetDealerDepartment() {
    return useCustomMutation({
      key: 'getDealerDepartment',
      handleReturn: {
        onError: () => {
          dispatch(
            UIActionCreators.snackbarManagement(
              'error',
              'Ocorreu um erro ao consultar "Departamentos".'
            )
          );
        },
      },
      request: async () => {
        const response = await request
          .get(`${domain}/dealer/${dealerId}/department/v1.0`, {})
          .then(response => {
            buildHTTPResponseHandler(`${serviceName}.getDealerDepartment()`);
            return response?.data as any;
          });
        return response;
      },
    });
  }

  const {
    data,
    isLoading,
    mutate: getDealerDepartment,
  } = useGetDealerDepartment();

  useEffect(() => {
    if (options?.length) {
      if (!selectedValues?.length && selectedOptions) {
        setSelectedOptions(null);
      }
      if (selectedValues && selectedValues?.length) {
        const filtered = options.filter(option => {
          const selectedValue = selectedValues.some(selected => {
            return selected.value === option.value;
          });
          return selectedValue;
        });
        setSelectedOptions(filtered);
      }
    }
  }, [options, selectedValues]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(el => ({
        filterGroupId: apiProperty,
        id: el?.id,
        label: el?.name,
        value: el?.code,
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOptions,
    getDealerDepartment,
    isLoading,
  };
}
