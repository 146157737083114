import React from 'react';

import MaskedInput from 'react-text-mask';
import { strip } from '@fnando/cpf';

const mobileRegex = [
  '(',
  /[1-9]/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const cnpjRegex = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
const cpfRegex = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const timeRegex = [/(0[0-9]|1[0-9]|2[0-3])/, /:/, /[0-5]/, /[0-9]/];
const cepRegex = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
const webSiteRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
const rgRegex = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
];
const dateRegex = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const quotaRegex = [/\d/, /\d/, /\d/, /\d/, /\d/];
const groupRegex = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const formatPhone = value => {
  const cleanValue = value.replace(/\D/g, '');
  if (cleanValue.startsWith('0800')) {
    return [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  return [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
};

const TextMaskCustomAllPhoneTypes = props => {
  const { inputRef, value, ...other } = props;
  const mask = formatPhone(value);
  return <MaskedInput {...other} ref={inputRef} value={value} mask={mask} />;
};

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;
  return <MaskedInput {...other} ref={inputRef} mask={mobileRegex} />;
};

const PhoneMaskCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={mobileRegex}
      placeholderChar={'\u2000'}
    />
  );
};

const CNPJMaskCustom = props => {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={cnpjRegex} />;
};
const CPFMaskCustom = props => {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={cpfRegex} />;
};

const CPFCNPJMaskCustom = props => {
  const { inputRef, ...other } = props;

  const buildMask = rawValue =>
    strip(rawValue).length > 11 ? cnpjRegex : cpfRegex;

  return <MaskedInput {...other} ref={inputRef} mask={buildMask} />;
};

const CEPMaskCustom = props => {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={cepRegex} />;
};

const TimeMaskCustom = props => {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={timeRegex} />;
};

const WebSiteRegex = props => {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={webSiteRegex} />;
};

const RGMaskCustom = props => {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={rgRegex} />;
};

const DateMaskCustom = props => {
  const { inputRef, ...other } = props;

  return <MaskedInput {...other} ref={inputRef} mask={dateRegex} />;
};

const QuotaMaskCutom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput {...other} ref={inputRef} guide={false} mask={quotaRegex} />
  );
};

const GroupMaskCutom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput {...other} ref={inputRef} guide={false} mask={groupRegex} />
  );
};

export {
  TextMaskCustom,
  CNPJMaskCustom,
  CPFMaskCustom,
  CPFCNPJMaskCustom,
  CEPMaskCustom,
  PhoneMaskCustom,
  TimeMaskCustom,
  WebSiteRegex,
  RGMaskCustom,
  DateMaskCustom,
  QuotaMaskCutom,
  GroupMaskCutom,
  TextMaskCustomAllPhoneTypes,
};
