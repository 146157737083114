import styled from 'styled-components';

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vh;
  color: #464646;
  font-size: 16px;
`;

export const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  opacity: ${({ isFinished }) => (isFinished ? 0.5 : 1)};
`;

export const Content = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
  flex: 1;
`;
