import PROFILES from 'constants/profiles';
import { highAccessUserTypes } from 'constants/userAccess';

export function validateUserPermission(
  currentUserType: string,
  extraUserTypes: string[],
  someOwner = false,
  enableToPrivilegedEmail = false
) {
  if (enableToPrivilegedEmail) {
    return true;
  }

  if (currentUserType === PROFILES.CONSULTOR) {
    return true;
  }

  if (
    someOwner
      ? ['OWNER'].includes(currentUserType)
      : [...highAccessUserTypes, ...extraUserTypes].includes(currentUserType)
  ) {
    return true;
  }

  return false;
}
