import React, { useEffect } from 'react';
import { fileProps } from 'components/atoms/DragzoneBox/DragzoneBoxTypes';
import { uploadDocumentTypes } from './UploadDocumentTypes';
import UploadDocumentModalComponent from './UploadDocumentModalComponent';
import UploadService from 'services/uploadServices';
import useUploadDocument from './hooks/useUploadDocument';

const UploadDocumentModalContainer = ({
  open,
  onClose,
  documentTypesOptions,
  onSuccessUpload,
  currentDocumentId = null,
  proposalOrderPaymentId = null,
}: uploadDocumentTypes) => {
  const [typeDocument, setTypeDocument] = React.useState<number | string>(null);
  const [onUploadDocument, setOnUploadDocument] = React.useState<boolean>(
    false
  );
  const [file, setFile] = React.useState<fileProps[]>([]);

  const { mutate, isLoading } = useUploadDocument();

  const addDocument = async () => {
    try {
      setOnUploadDocument(true);
      const result: any = await UploadService.uploadMedia({
        file: file[0],
        mediaOrigin: 'PROPOSAL_INTERACTION',
        callback: () => {
          return;
        },
      });
      if (result.success) {
        mutate({
          documentConfigId: currentDocumentId,
          fileName: result?.data?.fileName,
          mediaId: result?.data?.id,
          mediaType: 'MEDIA',
          proposalOrderPaymentId: proposalOrderPaymentId,
          callbackOnSuccess: onSuccessUpload,
        });
      }
    } finally {
      setFile([]);
      setOnUploadDocument(false);
      onClose();
    }
  };

  useEffect(() => {
    setTypeDocument(currentDocumentId);
  }, [currentDocumentId]);

  return (
    <UploadDocumentModalComponent
      open={open}
      file={file}
      setFile={setFile}
      addDocument={addDocument}
      onClose={() => {
        setFile([]);
        onClose();
      }}
      typeDocument={typeDocument}
      setTypeDocument={setTypeDocument}
      documentTypesOptions={documentTypesOptions}
      isLoading={isLoading || onUploadDocument}
    />
  );
};

export default UploadDocumentModalContainer;
