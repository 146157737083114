import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TextField from 'modules/financing/components/atoms/TextField';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import {
  ActionsContent,
  NextPanelButton,
  PreviousPanelButton,
} from 'modules/order/pages/OrderSteps/Proposal/steps/ProposalData/styles';
import { PaymentContainer, PaymentContent, SaleDataContent } from './styles';

const schema = yup.object().shape({
  soldPrice: yup
    .number()
    .test(
      'positive',
      'Valor não pode ser zero',
      value => !!value && Number(value) > 0
    )
    .required('Valor de venda obrigatório')
    .default(0),
  signalPrice: yup
    .number()
    .optional()
    .default(0),
});

function PaymentDataComponent({ handlePreviousPanel, handleNextPanel }) {
  const dispatch = useDispatch();

  const { soldPrice, signalPrice, queueData } = useSelector(state => ({
    ...state.order.queueData.payment,
    ...state.order,
  }));

  const formik = useFormik({
    initialValues: schema.cast({ soldPrice, signalPrice }),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: schema,
    onSubmit: values => {
      dispatch(
        ActionCreators.setQueueData({
          RequiredFieldsForCurrentStepCompleted: true,
          payment: {
            soldPrice: values.soldPrice,
            signalPrice: values.signalPrice,
          },
        })
      );
    },
  });

  const queueFinished = ['FINISHED', ''].includes(queueData?.status.value);

  useEffect(() => {
    if (
      formik.values.soldPrice != 0 &&
      typeof formik.values.soldPrice != 'undefined'
    ) {
      formik.handleSubmit();

      return;
    }
    dispatch(
      ActionCreators.setQueueData({
        RequiredFieldsForCurrentStepCompleted: false,
      })
    );
  }, [formik.values.soldPrice, formik.values.signalPrice]);

  return (
    <PaymentContainer>
      <PaymentContent>
        <SaleDataContent>
          <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            allowLeadingZeros={false}
            placeholder="0"
            prefix="R$ "
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            label="Valor de venda*"
            value={formik.values.soldPrice}
            name="soldPrice"
            onValueChange={event => {
              formik.handleChange({
                target: {
                  name: 'soldPrice',
                  value: event.floatValue,
                },
              });
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.soldPrice && Boolean(formik.errors.soldPrice)}
            helperText={formik.touched.soldPrice && formik.errors.soldPrice}
            disabled={queueFinished}
          />
          <NumberFormat
            value={formik.values.signalPrice}
            onValueChange={event => {
              formik.handleChange({
                target: {
                  name: 'signalPrice',
                  value: event.floatValue,
                },
              });
            }}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            allowLeadingZeros={false}
            placeholder="0"
            prefix="R$ "
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            label="Valor do sinal"
            onBlur={formik.handleBlur}
            error={
              formik.touched.signalPrice && Boolean(formik.errors.signalPrice)
            }
            helperText={formik.touched.signalPrice && formik.errors.signalPrice}
            disabled={queueFinished}
          />
        </SaleDataContent>
      </PaymentContent>
      <ActionsContent>
        <PreviousPanelButton onClick={handlePreviousPanel}>
          Voltar
        </PreviousPanelButton>
        <NextPanelButton
          onClick={handleNextPanel}
          disabled={!formik.values.soldPrice}
        >
          Próximo
        </NextPanelButton>
      </ActionsContent>
    </PaymentContainer>
  );
}

export default PaymentDataComponent;
