import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import AutoComplete from 'components/atoms/AutoComplete';
import DragzoneBoxComponent from 'components/atoms/DragzoneBox/DragzoneBoxComponent';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ThemeButton from 'components/atoms/ThemeButton';
import GenericDialog from 'components/GenericDialog';
import Wrapper from 'components/Wrapper';

const DialogUpload = ({
  openModal,
  handleCloseDialogUpload,
  optionsTypesDoc,
  loadingUpload,
  formik,
}) => {
  return (
    <GenericDialog
      open={openModal}
      onClose={handleCloseDialogUpload}
      OnCloseButton
      maxWidth="sm"
      title={'Upload de documento'}
      DialogAction={() => (
        <Wrapper flex width={'100%'} justify={'end'}>
          <OutlinedButton onClick={handleCloseDialogUpload}>
            Cancelar
          </OutlinedButton>
          <div style={{ width: 10 }} />
          <ThemeButton
            type="submit"
            disabled={loadingUpload}
            onClick={formik.handleSubmit}
          >
            {loadingUpload ? <CircularProgress color="inherit" /> : 'Adicionar'}
          </ThemeButton>
        </Wrapper>
      )}
    >
      <form onSubmit={formik.handleSubmit}>
        <Wrapper height={230}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AutoComplete
                name="typeDoc"
                label="Tipo de documento"
                value={
                  formik?.values?.typeDoc
                    ? {
                        value: formik?.values?.typeDoc,
                        label: optionsTypesDoc?.find(
                          e => e?.id === formik?.values?.typeDoc
                        )?.label,
                      }
                    : null
                }
                onChange={event => {
                  formik.setFieldValue('typeDoc', event?.id);
                }}
                options={optionsTypesDoc}
                onBlur={formik.handleBlur}
                error={formik.touched.typeDoc && Boolean(formik.errors.typeDoc)}
                helperText={formik.errors.typeDoc}
              />
            </Grid>
            <Grid item xs={12}>
              <DragzoneBoxComponent
                fileState={formik?.values?.uploadDoc}
                setFileState={event =>
                  formik.setFieldValue('uploadDoc', event || [])
                }
                textDragzone={'Clique ou arraste o arquivo até aqui'}
                heigthBoxUpload={102}
              />
              {formik.touched.uploadDoc && formik.errors.uploadDoc && (
                <Wrapper style={{ color: 'red' }}>
                  {formik.errors.uploadDoc}
                </Wrapper>
              )}
            </Grid>
          </Grid>
        </Wrapper>
      </form>
    </GenericDialog>
  );
};

export default DialogUpload;
