import { Action } from 'types/ActionTypes';
import ActionTypes from './actionTypes';

export const INITIAL_STATE = {
  snackbar: {
    variant: '',
    message: '',
    open: false,
    autoHideDuration: 3000,
    data: {
      title: '',
      message: '',
      eventType: null,
      dealerId: null,
      proposalId: null,
    },
  },
};

export default function orderDeal(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case ActionTypes.SNACKBAR_MANAGEMENT:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...action.payload,
          variant: action.payload?.variant ?? state.snackbar.variant,
        },
      };
    default:
      return state;
  }
}
