import React from 'react';
import GenericDialog from 'components/GenericDialog';
import { Container, Text, Row, ConfirmButton, CancelButton } from './styles';

const ConfirmationFileProposal = ({
  open,
  handleClickCloseDialog,
  handleClickConfirm,
}: any) => {
  return (
    <GenericDialog
      onClose={handleClickCloseDialog}
      open={open}
      title="Arquivar lead"
      maxWidth="sm"
      DialogAction={() => (
        <Row>
          <CancelButton onClick={handleClickCloseDialog} variant="primary">
            Cancelar
          </CancelButton>
          <ConfirmButton onClick={handleClickConfirm} variant="primary">
            Sim, Arquivar
          </ConfirmButton>
        </Row>
      )}
    >
      <Container>
        <Text>
          Atenção: Arquivar este lead é uma ação irreversível e ele não poderá
          mais ser recuperado. Tem certeza de que deseja arquivar este lead?
        </Text>
      </Container>
    </GenericDialog>
  );
};

export default ConfirmationFileProposal;
