import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export default {
  snackbarManagement: function(
    variant = '',
    message = '',
    open = true,
    autoHideDuration = 3000,
    data = {}
  ): Action {
    return {
      type: ActionTypes.SNACKBAR_MANAGEMENT,
      payload: { variant, message, open, autoHideDuration },
    };
  },
};
