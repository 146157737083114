import styled from 'styled-components';

export const Label = styled.span`
  font-size: 14px;
`;

export const EmptyListIconContainer = styled.div`
  border-radius: 35px;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #e0f3f1;
`;

export const Container = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

export const FlexCenterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  padding: ${({ padding }) => (padding ? '16px' : '0px')};
`;
