import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ClickAwayListener, Divider, Grow, Popper } from '@material-ui/core';

import PaperBorder from 'components/PaperBorder';
import Icon from 'bit/mobiauto.web-ui-components.components.icon';

import routesPath from 'config/routesPath';
import validadeUserProfile from 'helpers/validateUserProfile';

import * as S from './styles';

import useDealerProducts from 'hooks/useDealerHasProducts';

function NewCarComponent({ hasResaleProduct, isResale = false }) {
  const [isOpen, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const { hasProduct } = useDealerProducts();
  const { userType, profiles } = useSelector(state => state.userStore);
  const hasProfileToEditDeal = validadeUserProfile(
    'ADD_EDIT_DEAL',
    profiles,
    userType
  );

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  if (!hasProfileToEditDeal) return null;

  if (hasResaleProduct) {
    return (
      <Fragment>
        <S.WrapperButton ref={anchorEl} onClick={handleClick}>
          <S.Text>Incluir anúncio</S.Text>
          <S.ExpandMore>
            {isOpen && <S.Overlay />}
            <Icon name={isOpen ? 'ExpandLess' : 'ExpandMore'} color="#fff" />
          </S.ExpandMore>
        </S.WrapperButton>
        <Popper
          open={isOpen}
          anchorEl={anchorEl.current}
          placement={'bottom-end'}
          disablePortal
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <PaperBorder>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <Link
                      to={routesPath.getRecursivePath(routesPath.dealForm)}
                      style={{ textDecoration: 'none' }}
                      onClick={() => setOpen(false)}
                    >
                      <S.Item>Incluir anúncio de Showroom</S.Item>
                    </Link>
                    <Divider />
                    <Link
                      to={routesPath.getRecursivePath(
                        routesPath.resaleDealForm
                      )}
                      onClick={() => setOpen(false)}
                      style={{ textDecoration: 'none' }}
                    >
                      <S.Item>
                        {hasProduct('CORPORATIVE_SALE')
                          ? ' Incluir anúncio Venda Corporativa'
                          : 'Incluir anúncio de Repasse'}{' '}
                      </S.Item>
                    </Link>
                  </div>
                </ClickAwayListener>
              </PaperBorder>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  }

  return (
    <Link
      to={
        !isResale
          ? routesPath.getRecursivePath(routesPath.dealForm)
          : routesPath.getRecursivePath(routesPath.resaleDealForm)
      }
      style={{ textDecoration: 'none', marginRight: 16 }}
    >
      <S.ButtonStyled variant="primary">Incluir anúncio</S.ButtonStyled>
    </Link>
  );
}

export default NewCarComponent;
