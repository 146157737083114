import React from 'react';
import styled from 'styled-components';

const ScrollViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ScrollViewContent = styled.div`
  width: 100%;
  min-height: 100%;
`;

const ScrollView = ({ children }) => {
  return (
    <ScrollViewWrapper>
      <ScrollViewContent>{children}</ScrollViewContent>
    </ScrollViewWrapper>
  );
};

export default ScrollView;
