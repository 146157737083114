import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PricingVehicleData } from '../PricingVehicleTypes';
import {useSelector} from 'react-redux';

export const plateValidation = /[a-zA-z]{3}[0-9][0-9A-Z][0-9]{2}/;
export const vinValidation = /^[A-HJ-NPR-Z0-9]{17}(R(EM)?)?$/;


const usePricingVehicleData = () => {
  const { currentDealer } = useSelector(state => state.dealerStore);

  const initialValues: PricingVehicleData = {
    plate: null,
    make: null,
    model: null,
    productionYear: null,
    modelYear: null,
    trim: null,
    doors: null,
    color: null,
    transmission: null,
    fuel: null,
    km: null,
    vin: null,
    renavam: null,
  };
  
  const schema = Yup.object().shape({
    plate: Yup.string().matches(plateValidation, 'Placa inválida').required('Campo obrigatório').nullable(),
    make: Yup.object().shape({
      value: Yup.number(),
      label: Yup.string()
    }).required('Campo obrigatório')
    .typeError('Campo obrigatório'),
    model: Yup.object().shape({
      value: Yup.number(),
      label: Yup.string(),
    }).required('Campo obrigatório')
    .typeError('Campo obrigatório'),
    productionYear: Yup.object().shape({
      value: Yup.number(),
      label: Yup.string(),
    }).required('Campo obrigatório')
    .typeError('Campo obrigatório'),
    modelYear: Yup.object().shape({
      value: Yup.number(),
      label: Yup.string(),
    }).required('Campo obrigatório')
    .typeError('Campo obrigatório'),
    trim: Yup.object().shape({
      value: Yup.number(),
      label: Yup.string(),
    }).required('Campo obrigatório')
    .typeError('Campo obrigatório'),
    doors: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
      }).nullable()
     ,
    color: Yup.object().shape({
      value: Yup.number().nullable(),
      label: Yup.string().nullable(),
    }).nullable(),
    transmission: Yup.object().shape({
      value: Yup.number().nullable(),
      label: Yup.string().nullable(),
    }).nullable(),
    fuel: Yup.object().shape({
      value: Yup.number(),
      label: Yup.string(),
    }).nullable(),
    km: Yup.number()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    ...currentDealer?.isSaga && 
      {
      vin: Yup.string().matches(vinValidation, 'Chassi Inválido.')
        .required('Campo obrigatório')
        .typeError('Campo obrigatório'),
        renavam: Yup.number().optional().nullable(),
      },
    
  });

  const { values, touched, errors, handleSubmit, ...rest } = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: initialValues,
    validationSchema: schema,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: (values): any => {
      return values;
    },
  });

  return {
    values,
    touched,
    errors,
    handleSubmit,
    ...rest,
  };
};

export { usePricingVehicleData as default }
export type { PricingVehicleData }