import React, { useEffect, useState } from 'react';
import EmojiPickerReact, { Props as EmojiPickerReactProps } from 'emoji-picker-react';

// Style import
import { Container } from './styles';

interface EmojiPickerProps extends EmojiPickerReactProps {
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function EmojiPicker({ onMouseLeave, ...rest }: EmojiPickerProps) {
  // State
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight <= 750);

  const handleRecalculate = () => setIsSmallScreen(window.innerHeight <= 750);

  useEffect(() => handleRecalculate(), [window.innerHeight]);

  return (
    <Container onMouseLeave={onMouseLeave}>
      <EmojiPickerReact
        height={isSmallScreen ? 420 : 500}
        width={400}
        {...rest}
      />
    </Container>
  );
}