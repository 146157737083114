import styled from 'styled-components';

export const ChatTabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 55px;
`;

export const ChatTab = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  background: ${({ disabled, isActive }) =>
    disabled ? '#e6e6e6' : isActive ? '#f0f6f6' : '#fff'};
  color: ${({ isActive }) => (isActive ? '#009a8a' : '#000')};
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;

  > svg {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    fill: ${({ isActive }) => (isActive ? '#009a8a' : '#000')};
    > path {
      fill: ${({ isActive }) => (isActive ? '#009a8a' : '#000')};
    }
  }
`;
