import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { OrderPayment } from 'modules/order/types/OrderPayment';
import {
  AlertBody,
  AlertContainer,
  AlertTitle,
  CancelButton,
  ConfirmButton,
  ErrorIconStyled,
  QuestionText
} from './styles';

import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { HeaderCell, PaymentTable } from '../styles';

type RemovePaymentConfirmDialogProps = {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  removePaymentList: OrderPayment[];
};

function RemovePaymentConfirmDialog({
  open,
  onClose,
  removePaymentList,
}: RemovePaymentConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md">
      <DialogTitle style={{ fontWeight: 'bolder' }}>Excluir pagamento</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AlertContainer>
            <ErrorIconStyled />
            <div>
              <AlertTitle>Pagamento com valor abatido</AlertTitle>
              <AlertBody>
                Você está deletando um pagamento que tem o valor abatido de
                outro.
              </AlertBody>
            </div>
          </AlertContainer>
          <QuestionText>
            <span>
              Você tem certeza que gostaria de deletar todos estes meios de
              pagamento?
            </span>
          </QuestionText>
          <PaymentTable>
            <TableHead>
              <TableRow>
                <HeaderCell>Nº</HeaderCell>
                <HeaderCell>Meio de pagamento</HeaderCell>
                <HeaderCell>Tipo de pagamento</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {removePaymentList.map(row => (
                <TableRow key={row.position}>
                  <TableCell>{row.position}</TableCell>
                  <TableCell>{row.paymentType.installmentsType}</TableCell>
                  <TableCell>{row.paymentType.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </PaymentTable>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '0 16px 16px 0' }}>
        <CancelButton onClick={() => onClose(false)} variant="outlined">
          Cancelar
        </CancelButton>
        <ConfirmButton
          onClick={() => onClose(true)}
          color="primary"
          variant="outlined"
        >
          Excluir pagamentos
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
}

export default RemovePaymentConfirmDialog;
