import { format as formatCpf, strip } from '@fnando/cpf';
import { format as formatCnpj } from '@fnando/cnpj'


export default function formatCpfCnpj(cpfCnpjWithMask) {
  const cpfCnpj = strip(cpfCnpjWithMask);

  if (cpfCnpj.length == 11) return formatCpf(cpfCnpj);

  if (cpfCnpj.length == 14) return formatCnpj(cpfCnpj);

  return cpfCnpj.length == 0;
}
