import React from 'react';
import Wrapper from 'components/Wrapper';

interface VehicleFormDataItemProps {
  title: string;
  value: string | number;
}

const VehicleFormDataItem = ({ title, value }: VehicleFormDataItemProps) => {
  return (
    <Wrapper
      backgroundColor="#eaeaea"
      p="sm"
      style={{
        borderRadius: '4px',
      }}
    >
      <Wrapper
        color="#3e3e3e"
        style={{ lineHeight: '16px', fontFamily: 'Poppins Medium' }}
      >
        {title}
      </Wrapper>
      <Wrapper fontSize={14}>{value}</Wrapper>
    </Wrapper>
  );
};

export default VehicleFormDataItem;
