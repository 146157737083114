import { action, observable, computed, decorate } from 'mobx';
import remotedev from 'mobx-remotedev';
import NProgress from 'nprogress';

import { localStore } from 'components/Storage';
import { DealerFromStore } from 'components/withDealer';

import {
  keycloakInstance,
  init as authInit,
  tryAuthenticated,
  getProfile,
  loginWithToken,
} from 'auth';

import request from 'helpers/request';
import shouldEmbedTokenForURL from 'helpers/shouldEmbedTokenForURL';

const _defaultInitialState = {
  id: null,
  email: null,
  username: null,
  firstName: null,
  attributes: null,
  accessToken: null,
  refreshToken: null,
  userPreferences: {
    hasFetched: false,
    hasFailed: false,
    isLoading: false,
    data: null,
  },
  auth: null,
  authenticated: null,
  disabledUser: false,
  profile: null,
};

class UserStore {
  constructor(initialState) {
    this.setInitialState(initialState || _defaultInitialState);
  }

  setInitialState = initialState => {
    const {
      id,
      email,
      username,
      firstName,
      attributes,
      accessToken,
      refreshToken,
      userPreferences,
      auth,
      authenticated,
      disabledUser,
      profile,
    } = initialState;

    this.auth = null;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.userPreferences = userPreferences;
    this.id = id;
    this.email = email;
    this.username = username;
    this.firstName = firstName;
    this.attributes = attributes;
    this.authenticated = authenticated;
    this.auth = auth;
    this.disabledUser = disabledUser;
    this.profile = profile;
    // Setup request transform to embed auth headers
    request.addRequestTransform(this.transformRequestWithToken);
  };

  setUserInfo = profile => {
    this.id = profile.id;
    this.email = profile.email;
    this.username = profile.username;
    this.firstName = profile.firstName;
    this.attributes = profile.attributes;
  };

  get isLogged() {
    const isLogged = this.getToken();
    return !!isLogged && ((this.auth || {}).token || this.accessToken);
  }

  setToken(newToken = null) {
    this.accessToken = newToken ?? this.auth.token;
  }

  getToken() {
    return (this.auth || {}).token || this.accessToken;
  }

  init() {
    return authInit();
  }

  setAuthenticated = authenticated => {
    this.authenticated = authenticated;
  };

  container = async () => {
    NProgress.start();
    const authenticated = await tryAuthenticated();
    if (authenticated) {
      this.setAuthenticated(authenticated);
      this.setToken();
      this.setUserInfo(await getProfile());
      NProgress.done();
      return true;
    } else {
      return this.container();
    }
  };

  createAuth = async token => {
    this.setAuthenticated(await loginWithToken(token));
    this.setToken();
    this.setUserInfo(await getProfile());
  };

  tryAuthenticated = async () => {
    this.auth = keycloakInstance;
    return this.container();
  };

  logout = () => {
    DealerFromStore.clearDealerFromLocalStorage();
    localStore.removeItem('completeLater');
    this.auth.logout();
  };

  login = async token => {
    this.auth = keycloakInstance;
    await this.createAuth(token);
  };

  transformRequestWithToken = request => {
    const shouldEmbedToken = shouldEmbedTokenForURL(request.url);
    if (shouldEmbedToken) {
      request.headers['Authorization'] = `Bearer ${this.accessToken}`;
    }
  };

  setUserProfile = profile => {
    this.profile = profile;
  };
}

export default remotedev(
  decorate(UserStore, {
    auth: observable,
    authenticated: observable,
    id: observable,
    email: observable,
    username: observable,
    firstName: observable,
    attributes: observable,
    accessToken: observable,
    refreshToken: observable,
    userPreferences: observable,
    init: action,
    tryAuthenticated: action,
    setToken: action,
    setAuthenticated: action,
    setInitialState: action,
    loadUser: action,
    setUserInfo: action,
    testingAsyncRequest: action,
    logout: action,
    isLogged: computed,
    container: action,
    login: action,
    createAuth: action,
    disabledUser: observable,
    setUserProfile: action,
    profile: observable,
  })
);
