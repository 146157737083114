import styled from 'styled-components';

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Text = styled.div`
  font-size: 14px;
  margin: 16px 0px;
  text-align: center;
`;
