import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  //SET_CURRENT_TAB
  SET_SELLER_PROPOSALS_CURRENT_TAB: `@${MODULE}/${getters.SET}_SELLER_PROPOSALS_CURRENT_TAB_${actionOptions.REQUEST}`,
  SET_BUYER_PROPOSALS_CURRENT_TAB: `@${MODULE}/${getters.SET}_BUYER_PROPOSALS_CURRENT_TAB_${actionOptions.REQUEST}`,
  //GET_SELLER_PROPOSALS
  GET_SELLER_PROPOSALS: `@${MODULE}/${getters.GET}_SELLER_PROPOSALS_${actionOptions.REQUEST}`,
  //GET_BUYER_PROPOSALS
  GET_BUYER_PROPOSALS: `@${MODULE}/${getters.GET}_BUYER_PROPOSALS_${actionOptions.REQUEST}`,
  //SET_PROPOSALS_COMBO_INDIVIDUAL
  SET_PROPOSALS_INDIVIDUAL_SUCCEDED: `@${MODULE}/${getters.SET}_PROPOSALS_INDIVIDUAL_${actionOptions.SUCCESS}`,
  SET_PROPOSALS_INDIVIDUAL_ERROR: `@${MODULE}/${getters.SET}_PROPOSALS_INDIVIDUAL_${actionOptions.ERROR}`,
  SET_PROPOSALS_COMBO_SUCCEDED: `@${MODULE}/${getters.SET}_PROPOSALS_COMBO_${actionOptions.SUCCESS}`,
  SET_PROPOSALS_COMBO_ERROR: `@${MODULE}/${getters.SET}_PROPOSALS_COMBO_${actionOptions.ERROR}`,
  SET_PROPOSALS_LOADING: `@${MODULE}/${getters.SET}_PROPOSALS_${actionOptions.LOADING}`,
  //SET_PROPOSALS_OPTIONS
  SET_SELLER_PROPOSALS_OPTIONS: `@${MODULE}/${getters.SET}_SET_SELLER_PROPOSALS_OPTIONS_${actionOptions.REQUEST}`,
  SET_BUYER_PROPOSALS_OPTIONS: `@${MODULE}/${getters.SET}_SET_BUYER_PROPOSALS_OPTIONS_${actionOptions.REQUEST}`,
};
