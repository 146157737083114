import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, OutlinedButton } from './styles'
import checkClientFormData from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/checkClientFormData'

interface Props {
  nextFormName?: string;
  returnFormName?: string;
  enableNext?: boolean;
  enableReturn?: boolean;
  currentForm: string;
  currentStep?: number;
  isLastStep?: boolean;
  handleChangeFormStep: (formName: string, isBack?: boolean) => void;
  skipInputsCheck?: boolean;
}

const ActionsFormButton = ({
  nextFormName = '',
  returnFormName = '',
  enableNext = true,
  enableReturn = false,
  currentForm,
  isLastStep,
  handleChangeFormStep,
  skipInputsCheck = false
}: Props): JSX.Element => {
  const {
    buyerCurrentData,
    isFinished,
  }: {
    buyerCurrentData: any;
    isFinished: boolean;
    paymentData: any;
  } = useSelector(state => state.order);
  const [disabledNextButton, setDisabledNextButton] = useState(true);

  const checkInputs = (): any => {
    if (skipInputsCheck) {
      return setDisabledNextButton(false)
    }
    setDisabledNextButton(checkClientFormData(currentForm, buyerCurrentData))
  };

  useEffect(() => {
    checkInputs();
  }, [buyerCurrentData]);

  return (
    <Container>
      {enableReturn && (
        <OutlinedButton
          onClick={() => handleChangeFormStep(returnFormName, true)}
        >
          Anterior
        </OutlinedButton>
      )}
      {enableNext && (
        <>
          <div style={{ width: 8 }} />
          <OutlinedButton
            disabled={skipInputsCheck ? false : disabledNextButton}
            onClick={() => handleChangeFormStep(nextFormName)}
          >
            Próximo
          </OutlinedButton>
        </>
      )}
      {!isFinished && isLastStep && (
        <>
          <div style={{ width: 8 }} />
          <OutlinedButton
            disabled={skipInputsCheck ? false : disabledNextButton}
            onClick={() => handleChangeFormStep(nextFormName)}
          >
            Salvar
          </OutlinedButton>
        </>
      )}
    </Container>
  );
};

export default ActionsFormButton;
