import React from 'react';
import ThemeButton from 'components/atoms/ThemeButton'
import { ReactComponent as ErrorIcon } from 'assets/error404.svg'
import { Container, Message } from './styles';

const ProposalErrorPage = () => {

  function handleClickReturn() {
    window.history.back();
  }

  return <Container>
    <ErrorIcon />
    <Message>
      Proposta não encontrada ou já finalizada.
    </Message>
    <ThemeButton onClick={handleClickReturn}>Voltar</ThemeButton>
  </Container>;
}

export default ProposalErrorPage;