import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  margin: 0px 16px;
`;

export const StepArrowButton = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const LabelStepArrowButton = styled.div`
  position: absolute;
  line-height: 24px;
  width: ${({ firstStep }): string => (firstStep ? '84px' : '120px')};
  text-align: center;
  color: ${({ isActive }): string => (isActive ? '#009a8a' : '#757575')};
  font-size: 12px;
`;
