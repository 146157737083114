import React from 'react';
import TableHeadBase from './components/TableHeadBase';

const columnsConfig = [
  {
    id: 'createdAt',
    padding: 'checkbox',
    align: 'center',
    label: 'Data',
    width: '100px',
  },
  {
    id: 'vehicle',
    label: 'Veículo',
    width: '200px',
  },
  {
    id: 'vehicle',
    label: 'Proposta',
    width: '100px',
  },
  {
    id: 'sellerName',
    label: 'Vendedor',
    width: '100px',
  },
  {
    id: 'scheduling',
    label: 'Visita agendada',
    width: '100px',
  },
  {
    id: 'futureScheduling',
    label: 'Visita Futura',
    width: '100px',
  },
  {
    id: 'status',
    label: 'Status Loja',
    width: '200px',
  },
  {
    id: 'salesAssistantStatus',
    label: 'Status Central de atendimento',
    width: '200px',
  },
];

function TableHeadComponent({ onRequestSort, order, orderBy }) {
  return (
    <TableHeadBase
      columnsConfig={columnsConfig}
      onRequestSort={onRequestSort}
      order={order}
      orderBy={orderBy}
    />
  );
}

export default TableHeadComponent;
