import styled from 'styled-components';
import panelLanding from 'assets/whatsapp-background.png';

export const WrapperBg = styled.div`
  height: 100%;
  position: relative;
  z-index: 5;
  background-color: ${({ isWhatsApp }) => (isWhatsApp ? '#e5ddd5' : 'none')};
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GoToBottomButton = styled.div`
  position: absolute;
  bottom: 8px;
  left: 50%;
  width: 35px;
  height: 35px;
  background: #ffd200;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const Bg = styled.div`
  background-image: url(${panelLanding});
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.06;
  width: 100%;
  height: 100%;
  background-size: 412.5px 749.25px;
  background-color: #e5ddd5;
  z-index: -1;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: #a7a7a7;
`;

export const EmptyContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  position: relative;
  height: 100%;
`;

export const EmptyIcon = styled.div`
  background: #e0f3f1;
  width: 64px;
  height: 64px;
  border-radius: 55px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ContainerSuggestionsMessages = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  flex-wrap: wrap;
  flex-direction: row-wrap;
  background-color: transparent;
  padding: 0 8px 8px;
  gap: 8px;
`;

export const SuggestionsMessages = styled.div`
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  background: #ffffff;
  color: #424242;
  border: 1px solid #bdbdbd;
  border-radius: 90px;
  padding: 12px 16px;
  height: max-content;
  width: max-content;
  :hover {
    cursor: pointer;
  }
`;

export const AutoSizerContainer = styled.div`
  display: block;
  float: left;
  width: 100%;
  height: 100%;
  margin: 8px auto;
`;
