/* eslint-disable @typescript-eslint/camelcase */
import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const MSChatserviceName = 'MOBI_SMART_CHAT';
const MSAdvertising = 'MOBI_SMART_ADVERTISING';
const MSConsultantserviceName = 'MOBI_SMART_CONSULTANT';
const MOBI_SMART_CHAT_DOMAIN = EnvVars('API_MOBI_SMART_CHAT_BASE_URL');
const MOBI_SMART_CONSULTANT_DOMAIN = EnvVars(
  'API_MOBI_SMART_CONSULTANT_BASE_URL'
);
const MOBI_SMART_ADVERTISING_DOMAING = EnvVars(
  'API_MOBI_SMART_ADVERTISING_BASE_URL'
);
const ML360ServiceName = 'lead360';
const MOBI_LEAD360 = EnvVars('API_MOBI_LEAD360');

interface Lead360Response {
  data: {
    summary: string;
  };
}

export default class MobiIAServices {
  static postMobiSmartChatSugestions = (id, proposalId, suggestion) => {
    const params = {
      id: id,
      proposal_id: proposalId,
      suggestion: suggestion,
    };

    return request
      .post(`${MOBI_SMART_CHAT_DOMAIN}/v1/sugestao`, params)
      .then(
        buildHTTPResponseHandler(
          `${MSChatserviceName}.postMobiSmartChatSugestions()`
        ) as any
      );
  };

  static getMobiSmartChatSugestions = (proposalId, dealerId) => {
    return request
      .get(
        `${MOBI_SMART_CHAT_DOMAIN}/v1/sugestao?proposal_id=${proposalId}&dealer_id=${dealerId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${MSChatserviceName}.getMobiSmartChatSugestions()`
        ) as any
      );
  };
  static getDealDescriptionSugestions = (dealerId, trimId, condition, km) => {
    const params = {
      dealer_id: dealerId,
      trim_id: trimId,
      condition: condition,
      km: km,
    };
    return request
      .get(`${MOBI_SMART_ADVERTISING_DOMAING}/v1/pj/descricao`, params)
      .then(
        buildHTTPResponseHandler(
          `${MSAdvertising}.getDealDescriptionSugestions()`
        ) as any
      );
  };
  static postDealDescriptionSugestions = (id, dealerId) => {
    const params = {
      id: Number(id),
      dealer_id: Number(dealerId),
    };
    return request
      .post(`${MOBI_SMART_ADVERTISING_DOMAING}/v1/pj/descricao`, params)
      .then(
        buildHTTPResponseHandler(
          `${MSAdvertising}.postDealDescriptionSugestions()`
        ) as any
      );
  };

  //MOBI_SMART_CONSULTANT
  static getChatMobiSmartConsultant = (
    proposalId,
    dealerId,
    message = null
  ) => {
    return request
      .get(
        `${MOBI_SMART_CONSULTANT_DOMAIN}/v1/mensagem?proposal_id=${proposalId}&dealer_id=${dealerId}`,
        {
          message: message,
        }
      )
      .then(
        buildHTTPResponseHandler(
          `${MSConsultantserviceName}.getChatMobiSmartConsultant()`
        ) as any
      );
  };

  static getLead360 = (
    dealer_id: string,
    body: any
  ): Promise<Lead360Response> => {
    return request
      .post(`${MOBI_LEAD360}/dealer/${dealer_id}/lead360`, body)
      .then(
        buildHTTPResponseHandler(`${ML360ServiceName}.getLead360()`) as any
      );
  };
}
