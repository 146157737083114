import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import clsx from 'clsx';
import { customIconStyles } from './styles';

//if need add icons to step 
const defaultIcons = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
};

const StepperCustomIcon = withStyles(customIconStyles)((props: any) => {
  const { active, completed, icons = defaultIcons, backgroundColor, allActive, hiddenCheckIcon, classes }: any = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
      style={{ backgroundColor: allActive ? backgroundColor : (completed || active) ? backgroundColor : null }}
    >
      {!hiddenCheckIcon && completed ? <CheckCircle style={{ fontSize: 18 }} /> : icons[String(props.icon)]}
    </div>
  );
})

export default StepperCustomIcon;