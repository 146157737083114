import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from 'bit/mobiauto.web-ui-components.components.checkbox/Checkbox';
import Flex from 'bit/mobiauto.web-ui-components.flex/Flex';
import getImage from 'components/getImage';
import routesPath from 'config/routesPath';

const styles = {
  card: {
    display: 'flex',
    width: '100%',
    borderRadius: '4px',
    boxShadow: '0 0 4px 0 rgba(51, 51, 51, 0.1)',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '0',
  },
  cover: {
    width: '122px',
    height: '90px',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    fontFamily: 'Poppins Regular',
    color: '#fff',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: '8px',
    objectFit: 'scale-down',
  },

  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '12px',
    padding: '8px',
  },
  modelName: {
    fontFamily: 'Poppins Regular',
    fontSsize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  spec: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '12px',
    height: '20px',
  },
  publicacao: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '12px',
  },
  carName: {
    fontSize: '12px',
    fontFamily: 'Poppins Regular',
    color: `${Colors.gray.dark}`,
    textAlign: 'left',
  },
  types: {
    fontSize: '12px',
    fontFamily: 'Poppins Regular',
    color: `${Colors.gray.medium}`,
  },
};

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalPrice: false,
      expanded: false,
      menuEditExpanded: false,
      screenWidth: null,
      mainImage: undefined,
      openModalDeleteDeal: false,
      checked: false,
    };
  }

  handleOnClickOpenModalPrice = (state = 'BR', trimId, productionYear) => {
    this.props.getPrice(state, trimId, productionYear);
    this.setState({ openModalPrice: true });
  };

  handleOnClickCloseModalPrice = () => {
    this.setState({ openModalPrice: false });
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleEditToggle = () => {
    this.setState(state => ({ menuEditExpanded: !state.menuEditExpanded }));
  };

  handleEditClose = event => {
    if (this.menuEditExpandedAnchor.contains(event.target)) {
      return;
    }

    this.setState({ menuEditExpanded: false });
  };

  handleOnClickOpenModalDeleteDeal = () => {
    this.setState({ openModalDeleteDeal: true });
  };

  handleOnClickCloseModalDeleteDeal = () => {
    this.setState({ openModalDeleteDeal: false });
  };

  render() {
    const { classes, deal } = this.props;

    const linkImage = getImage({ imageId: deal?.imageId }, 80, 290, 0);

    return (
      <Card className={classes.card}>
        <a
          href={routesPath.getRecursivePath(routesPath.updateDeal, {
            id: deal.id,
          })}
          target={'_blank'}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <CardMedia
            className={classes.cover}
            image={linkImage || require('assets/default-image.svg')}
            title="Carro"
          />
        </a>
        <CardContent
          classes={{ root: classes.details }}
          style={{ margin: 0, padding: 0 }}
        >
          <Grid container direction="row">
            <Flex column style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={12}>
                  <CardActions
                    classes={{ root: classes.title }}
                    style={{ margin: 0, flex: '1' }}
                  >
                    <Typography component="p" className={classes.modelName}>
                      {deal.makeName}
                    </Typography>
                    <Typography component="p" className={classes.carName}>
                      {`${deal.modelName} ${deal.trimName}`}
                    </Typography>
                  </CardActions>
                  <CardActions
                    classes={{ root: classes.spec }}
                    style={{ padding: '0 8px', margin: 0 }}
                  >
                    <Typography component="p" className={classes.types}>
                      {deal.productionYear}/{deal.modelYear}
                    </Typography>
                    <Typography component="p" className={classes.types}>
                      {deal.colorId}
                    </Typography>
                    <Typography component="p" className={classes.types}>
                      {deal.km}Km
                    </Typography>
                    <Checkbox
                      checked={this.props.checkeds.includes(deal.id)}
                      onChange={() => this.props.onCheckClick(deal.id)}
                    />
                  </CardActions>
                </Grid>
              </Grid>
            </Flex>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(Item);
