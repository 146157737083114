import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

export const EmptyContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
`;

export const EmptyIcon = styled.div`
  background: #e0f3f1;
  width: 64px;
  height: 64px;
  border-radius: 55px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TextMessage = styled.div`
  margin: 8px 0px;
  font-size: 14px;
`;

export const OverflowContainer = styled.div`
  display: flex;
  height: 100%;
  z-index: 10;
  margin-top: 35px;
`;
