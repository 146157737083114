import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import ProposalOrderDelivery from 'services/proposalOrderDelivery';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

const useGetDeliveryOrdersById = proposalId => {
  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  return useQuery({
    queryKey: 'use_get_delivery_orders_by_id',
    queryFn: () =>
      ProposalOrderDelivery.getProposalOrderDeliveryFindId(
        currentDealer.id,
        proposalId
      ),
    select: (data: any) => {
      return data?.data ? data.data : [];
    },
    refetchOnWindowFocus: false,
    enabled: false,
    onError() {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    },
  });
};

export default useGetDeliveryOrdersById;
