import React, { useState } from 'react';
import moment from 'moment';
import { Box, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import BoxConsent from 'components/BoxConsent/';
import ThemeButton from 'components/atoms/ThemeButton';
import TableProposal from 'components/TableDigitalProposal';
import { statusType } from 'components/BoxConsent/BoxConsentWrapper.types';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import CancelPDMDialog from './CancelPDMDialog';

const DigitalProposalComponent = ({ isFinancing, financingStatus }) => {
  const [openModal, setOpenModal] = useState(false);
  const { proposalDigital } = useSelector(state => state?.order);
  const dispatch = useDispatch();

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleRedux = {
    GET: () => dispatch(ActionCreators.getProposalDigital()),
    POST: () => dispatch(ActionCreators.sendProposalDigital()),
  };

  React.useEffect(() => {
    handleRedux.GET();
  }, []);

  const consentProposalDigital = proposalDigital?.data?.find(
    item => item?.status === statusType.APPROVED
  );
  const notConsentProposalDigital = proposalDigital?.data?.find(
    item =>
      item.status === statusType.APPROVED ||
      statusType.REPROVED ||
      statusType.WAITING
  );

  return (
    <Box component="div">
      {proposalDigital?.data?.length > 0 && (
        <Box component="div" display="flex" justifyContent="space-between">
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            width="50%"
            pr={2}
          >
            <Box
              component="p"
              style={{ textTransform: 'uppercase', fontSize: '16px' }}
            >
              Histórico
            </Box>
            <TableProposal rows={proposalDigital?.data || []} />
          </Box>
          <Box component="div" width="50%">
            <Box
              component="p"
              style={{ textTransform: 'uppercase', fontSize: '16px' }}
            >
              Consentimento
            </Box>
            {consentProposalDigital && (
              <BoxConsent
                status={consentProposalDigital?.status}
                idValue={consentProposalDigital?.id}
                imageId={consentProposalDigital?.imageId}
                imageVcode={consentProposalDigital?.imageVcode}
                createData={moment(consentProposalDigital?.createAT).format(
                  'L'
                )}
                expirationData={moment(
                  consentProposalDigital?.expirationDate
                ).format('L')}
                createHours={moment(consentProposalDigital?.createAT).format(
                  'LT'
                )}
                expirationHours={moment(
                  consentProposalDigital?.expirationDate
                ).format('LT')}
                userAgentContent={consentProposalDigital?.userAgent}
              />
            )}
            {!consentProposalDigital && notConsentProposalDigital && (
              <BoxConsent status={statusType.CANCELED} />
            )}
          </Box>
        </Box>
      )}
      {isFinancing && !['success', 'APPROVED'].includes(financingStatus) ? (
        <div style={{ textAlign: 'center', fontSize: 14 }}>
          Financiamento ainda não foi aprovado, para habilitar o envio da
          proposta digital.
        </div>
      ) : (
        <Box component="div" display="flex" justifyContent="flex-end" mt={2}>
          {!proposalDigital?.data.find(
            proposalDigital => proposalDigital.status === 'APPROVED'
          ) && (
            <ThemeButton onClick={() => handleRedux.POST()}>
              {proposalDigital?.data?.length > 0 ? 'Reenviar' : 'Enviar'}
            </ThemeButton>
          )}

          {proposalDigital?.data.find(
            proposalDigital => proposalDigital.status === 'APPROVED'
          ) && (
            <Button
              style={{
                background: 'red',
                color: '#fff',
                textTransform: 'inherit',
                width: '113px',
                borderRadius: '4px',
              }}
              onClick={() => setOpenModal(true)}
            >
              Cancelar
            </Button>
          )}
        </Box>
      )}

      {openModal && <CancelPDMDialog open={openModal} onClose={onCloseModal} />}
    </Box>
  );
};

export default DigitalProposalComponent;
