export {
  TextMaskCustom,
  CNPJMaskCustom,
  CPFMaskCustom,
  CPFCNPJMaskCustom,
  CEPMaskCustom,
  PhoneMaskCustom,
  TimeMaskCustom,
  RGMaskCustom,
  DateMaskCustom,
  TextMaskCustomAllPhoneTypes,
} from './mask';
