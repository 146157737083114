import { createStore, combineReducers } from 'redux';
import orderDeal from './reducers/orderDeal';
import dealForm from './reducers/dealForm';
import deals from './reducers/deals';
import leads from './reducers/leads';
import providers from './reducers/provider';
import callCenter from './reducers/callCenter';
import searchAndRescueCenter from './reducers/searchAndRescueCenter';
import proposals from './reducers/proposals';
import negotiation from './reducers/negotiation';
import offerPortal from './reducers/offerPortal';
import scheduler from './reducers/scheduler';
import dealer from './reducers/dealer';
import dealerStore from './ducks/dealer';
import proposalStore from './ducks/proposal';
import userStore from './ducks/user';
import UI from './reducers/ui';
import evaluation from 'modules/evaluation/store/reducers';

import financing from 'modules/financing/store/reducers';
import order from 'modules/order/store/reducers';
import storeInventory from 'modules/store-inventory/store/reducers';
import sagaMiddleware from 'helpers/middlewares/sagaMiddleware';
import enhancer from './enhancer';
import sagas from './sagas';

//OTHER MODULES
import { financialReducers } from 'modules/financial/store';
import { manageDealsReducers } from 'modules/manageDeals/store';

const reducers = combineReducers({
  orderDeal,
  dealForm,
  deals,
  leads,
  providers,
  callCenter,
  searchAndRescueCenter,
  proposals,
  negotiation,
  offerPortal,
  scheduler,
  dealer,

  //OUTSIDE MODULES
  evaluation,
  postings: financialReducers.postingsReducer,
  categories: financialReducers.categoriesReducer,
  manageDeals: manageDealsReducers.dealReducer,
  tempDealer: financialReducers.tempDealerReducer,
  financing,
  UI,
  order,
  storeInventory,

  //DUCKS
  dealerStore,
  proposalStore,
  userStore,
});

const store = createStore(reducers, enhancer);

sagaMiddleware.run(sagas);

export default store;
