import React from 'react';

import { Props } from './Wrapper.types';
import { ContainerWrapper } from './styles';

const Wrapper: React.FC<Props> = ({
  children,
  visible,
  beforeElement,
  afterElement,
  ...props
}) => {
  return visible ? (
    <ContainerWrapper {...props}>
      {beforeElement}
      {children}
      {afterElement}
    </ContainerWrapper>
  ) : null;
};

Wrapper.defaultProps = {
  visible: true,
};

export default Wrapper;
