import React from 'react';
import formatAmount from 'helpers/formatAmount';
import SquareImage from 'modules/financing/components/atoms/SquareImage';
// import PrintIcon from '@material-ui/icons/PrintOutlined';
// import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import {
  Flex,
  FlexSpaceBetween,
  BankName,
  ProposalId,
  Separator,
  ColumCenter,
  BankInfosContainer,
  Space,
  SubLabel,
  Label,
} from './styles';

const FinancingInfos = ({ financing }): JSX.Element => {
  const financingValue = formatAmount(financing.financingValue, 'PT-BR', 'R$');
  const installmentValue = formatAmount(financing.installmentValue, 'PT-BR', 'R$');

  return (
    <FlexSpaceBetween>
      <Flex>
        <BankInfosContainer>
          <SquareImage size={'48px'} imageId={financing.institutionImageId} />
          <BankName>{financing.institutionName}</BankName>
          <ProposalId>{`proposta #${financing.proposalCode}`}</ProposalId>
        </BankInfosContainer>
        <Separator />
        <ColumCenter>
          <Label>Parcelas</Label>
          <SubLabel>{`${financing.terms}x de ${installmentValue}`}</SubLabel>
        </ColumCenter>
        <Space />
        <ColumCenter>
          <Label>Valor financiado</Label>
          <SubLabel>{financingValue}</SubLabel>
        </ColumCenter>
      </Flex>
      {/* <ColumCenter>
        <HypertextButton>
          <PrintIcon style={{ fontSize: 18, marginRight: 5 }} />
          Imprimir CET
        </HypertextButton>
      </ColumCenter> */}
    </FlexSpaceBetween>
  );
};

export default FinancingInfos;
