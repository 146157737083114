import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import ContactServices from 'modules/resale/services/ContactServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ClientFormComponent from './ClientFormComponent';
import simpleContactSchema from './simpleContactSchema';

const ClientFormContainer = ({
  isCreating = false,
  handleClickCancel = () => {
    return;
  },
  currentClientToEditing = null,
  selectClientAfterSave,
  clientPhone = '',
}: {
  isCreating?: boolean;
  handleClickCancel: () => void;
  currentClientToEditing?: any;
  selectClientAfterSave?: any;
  clientPhone?: string;
}): JSX.Element => {
  //HOOKS
  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  const [isLoading, setIsLoading] = useState(false);

  const cleanData = clientData => {
    const cleanData = {};
    for (const key of Object.keys(clientData)) {
      cleanData[key] = clientData[key] === '' ? null : clientData[key];
    }
    return cleanData;
  };

  //TODO EDIT
  const updateContactData = async currentClientData => {
    try {
      currentClientData.cpfCnpj =
        currentClientData?.cpf || currentClientData?.cnpj;
      setIsLoading(true);
      const response: any = await ContactServices.updateContact(
        currentDealer.id,
        currentClientData.id,
        cleanData(currentClientData)
      );
      if (response.success) {
        handleClickCancel();
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const createContactData = async currentClientData => {
    try {
      setIsLoading(true);
      const response: any = await ContactServices.createContact(
        currentDealer.id,
        cleanData(currentClientData)
      );
      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Cliente criado com sucesso.'
          )
        );
        selectClientAfterSave({ ...currentClientData, id: response?.data?.id });
        setIsLoading(false);
        handleClickCancel();
        return;
      }
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: isCreating
      ? { ...simpleContactSchema.cast({}), phone: clientPhone }
      : currentClientToEditing,
    validationSchema: simpleContactSchema,
    onSubmit: values => {
      isCreating ? createContactData(values) : updateContactData(values);
    },
  });

  const viewComponentProps = {
    onChange: handleChange,
    handleBlur,
    formData: values,
    errors,
    touched,

    isCreating,
    handleSubmit,
    handleClickCancel,
    isLoading,
  };

  return <ClientFormComponent {...viewComponentProps} />;
};

export default ClientFormContainer;
