const theme = {
  colors: {
    superLightText: '#E0F3F1',
    lightText: '#757575',
    mediumText: '#424242',
    boldText: '#212121',
    green: '#009A8A',
    blue: '#2196F3',
    red: '#F44336',
    orange: '#FF9800',
    secondaryBackground: '#FAFAFA',
  }
}

export default theme;