import { useChatContext } from 'pages/chats/ChatsContext';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

const useTypingEffect = (message?: string) => {
  const [isLoadingFirstMessage, setIsLoadingFirstMessage] = useState<boolean>();
  const { currentProposal }: any = useChatContext();
  const [firstMessage, setFirstMessage] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const [isTitleComplete, setIsTitleComplete] = useState(false);
  const typingSpeed = 80;
  const queryClient = useQueryClient();
  const { dealerId, providerId } = currentProposal;
  const title = 'Resumo sobre este Lead: ';

  const createTypingEffect = (
    message: string,
    firstMessage?: string | boolean
  ) => {
    let currentIndex = 0;
    const typingDelay = 50;

    const typingInterval = setInterval(() => {
      if (currentIndex < message.length) {
        if (firstMessage) {
          setFirstMessage(prevMessage => prevMessage + message[currentIndex]);
          currentIndex++;

          return;
        }
      } else {
        clearInterval(typingInterval);
        setIsLoadingFirstMessage(false);
      }
    }, typingDelay);
  };

  useEffect(() => {
    if (!message) return;

    let index = 0;
    const fullText = title + '\n' + message;

    const timer = setInterval(() => {
      setDisplayedText(prevText => {
        if (index >= fullText.length) {
          clearInterval(timer);
          return prevText;
        }

        const nextChar = fullText[index++];

        if (index === title.length) {
          setIsTitleComplete(true);
        }

        return prevText + nextChar;
      });
    }, typingSpeed);

    return () => {
      clearInterval(timer);
      queryClient.removeQueries({
        queryKey: `get_lead_360_${providerId || dealerId}`,
      });
    };
  }, [message]);

  return {
    isLoadingFirstMessage,
    firstMessage,
    setIsLoadingFirstMessage,
    setFirstMessage,
    createTypingEffect,
    displayedText,
    setDisplayedText,
    isTitleComplete,
  };
};

export default useTypingEffect;
