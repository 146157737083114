import { useMutation } from 'react-query';
import { SelectedDownPaymentSimulation } from '../components/SimulationForm';
import ProposalServices from 'services/proposalServices';
import { useSelector } from 'react-redux';
import useSnackBar from 'hooks/useSnackBar';

export const useApplyDownPaymentSimulation = () => {
  const { dealerId, proposalId } = useSelector(
    (state: any) => state.proposalStore.currentProposal
  );

  const { handleSnackBarError, handleSnackBarSuccess } = useSnackBar();

  return useMutation<any, Error, SelectedDownPaymentSimulation>(
    'apply_simulation',
    selectedSimulation =>
      ProposalServices.applyDownPaymentSimulation(
        dealerId,
        proposalId,
        selectedSimulation
      ),
    {
      onError: () => {
        handleSnackBarError('Ocorreu um erro ao aplicar simulação.');
      },
      onSuccess: data => {
        if (data.success) {
          handleSnackBarSuccess('Simulação aplicada com sucesso.');
        }
      },
    }
  );
};
