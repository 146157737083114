import validateCPF from 'helpers/validateCPF';
import * as yup from 'yup';

const simulationSchema = yup.object().shape({
  clientCpf: yup
    .string()
    .required('Campo obrigatório')
    .default('')
    .test('cpfValidade', 'CPF Inválido', value => validateCPF(value)),
  inputValue: yup
    .number()
    .moreThan(0, 'Valor de entrada não pode ser igual a 0')
    .required('Campo obrigatório')
    .test({
      name: 'max',
      exclusive: false,
      params: {},
      message:
        'Valor de entrada não pode ser maior ou igual que o valor do veículo',
      test: function(value) {
        return value < parseFloat(this.parent.vehicle.price);
      },
    })
    .default(0),
  monthlyIncome: yup.number().optional(),
  vehicle: yup.object().shape({
    id: yup.number().optional(),
    trim: yup.object().optional(),
    trimId: yup.number().optional(),
    modelYear: yup
      .number()
      .required('Ano do Modelo obrigatório')
      .optional(),
    price: yup
      .number()
      .moreThan(0, 'Valor do veículo não pode ser igual a 0')
      .default(0)
      .optional(),
  }),
  installmentSelected: yup.number().optional(),
});

export default simulationSchema;
