import React, { useEffect } from 'react';
import { ApprovalDocumentTypes } from './ModalAprovalTypes';
import ModalApprovalComponent from './ModalApprovalComponent';

const ModalApprovalContainer = ({
  open,
  onClose,
  handleClickConfirm,
  isLoading,
}: ApprovalDocumentTypes) => {
  const [reason, setReason] = React.useState<string>('');

  useEffect(() => {
    if (!open) {
      setReason('');
    }
  }, [open]);

  return (
    <ModalApprovalComponent
      open={open}
      onClose={onClose}
      confirmReproveDocument={() => {
        handleClickConfirm(reason);
        return;
      }}
      reason={reason}
      setReason={setReason}
      isLoading={isLoading}
    />
  );
};

export default ModalApprovalContainer;
