import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import isEmpty from 'helpers/isEmpty';
import { buildSop, convertObjectSopToString, defaultSop } from 'helpers/sop';
import InventorySearchServices from 'modules/store-inventory/services/InventorySearchServices';
import { STOCK_KIND_TRANSLATE_ID } from 'modules/store-inventory/helpers/TranslateAggregations';
import ListDealsComponent from './ListDealsComponent';

export type ListDealsQueryOptions = {
  conditionStatus?: string;
  evaluation?: boolean;
  evaluationStatus?: string;
  resale?: boolean;
  showroom?: boolean;
};

export type ListDealsContainerProps = {
  selectedVehicles: any[];
  handleClickItem: any;
  alternativeDealerId?: number;
  allAvailable?: boolean;
  extraDefaultSopValues?: object;
  multipleSelection?: boolean;
};

function ListDealsContainer({
  selectedVehicles,
  handleClickItem,
  alternativeDealerId,
  extraDefaultSopValues,
  allAvailable = false,
  multipleSelection = false,
}: ListDealsContainerProps) {
  const [query, setQuery] = useState('');
  const [dealsPage, setDealsPage] = useState<any>({ results: [] });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [aggregations, setAggregations] = useState<any>(null);
  const [sop, setSop] = useState<any>({
    ...defaultSop,
    ...extraDefaultSopValues,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [
    firstRenderingOfTheVehicleStep,
    setFirstRenderingOfTheVehicleStep,
  ] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { currentDealer, currentProposal } = useSelector(state => ({
    ...state.dealerStore,
    ...state.proposalStore,
  }));

  const handleChangeSop = (
    filterKey: string,
    value: string | number | null,
    setVD?: boolean
  ): void => {
    let sopBuilded = buildSop(sop, filterKey, value);
    if (setVD) {
      sopBuilded = buildSop(
        sopBuilded,
        filterKey,
        STOCK_KIND_TRANSLATE_ID[2].id
      );
    }
    if (filterKey === 'ops_') {
      setCurrentPage(0);
    }
    setSop({
      ...sopBuilded,
    });
  };

  const getDealsStock = async () => {
    try {
      setIsLoading(true);
      if (
        isEmpty(aggregations) &&
        currentDealer?.stockType &&
        sop?.['skd_'] === null
      ) {
        const currentStockKindFilter = Object.values(
          STOCK_KIND_TRANSLATE_ID
        ).find(el => el.value === currentDealer.stockType);
        if (currentStockKindFilter) {
          await handleChangeSop(
            'skd_',
            currentStockKindFilter?.id,
            currentStockKindFilter.value === STOCK_KIND_TRANSLATE_ID[1].value
          );
          return { results: [] };
        }
      }

      const { success, data }: any = await InventorySearchServices.searchDeals({
        dealerId:
          alternativeDealerId || currentDealer?.id || currentProposal?.dealerId,
        page: currentPage,
        size: pageSize,
        order: 1,
        sop: convertObjectSopToString(sop),
      });
      if (success) {
        return data;
      }

      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const search = async () => {
    try {
      const data: any = await getDealsStock();

      setDealsPage(data);
      if (!aggregations) {
        setAggregations(data.aggregations);
      }
    } catch {
      console.log('error');
    }
  };

  const searchMore = async () => {
    try {
      const data = await getDealsStock();
      setDealsPage({
        ...data,
        results: [...dealsPage.results, ...data.results],
      });
    } catch {
      console.log('error');
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleChangeSop('ops_', query);
    }
  };

  const handleClickSearch = () => {
    handleChangeSop('ops_', query);
  };

  const handleChangeQuery = value => {
    setQuery(value);
  };

  const handleChangeFilter = (sopKey, value) => {
    handleChangeSop(sopKey, value);
  };

  const handleClickCleanFilter = () => {
    setQuery('');
    setSop({ ...defaultSop });
  };

  const handlePaginationTable = () => {
    search();
  };

  useEffect(() => {
    if (currentPage > 0) {
      searchMore();
    }
  }, [currentPage]);

  useEffect(() => {
    handlePaginationTable();
  }, [currentPage, sop]);

  useEffect(() => {
    if (selectedVehicles?.[0]?.plate || selectedVehicles?.[0]?.vin) {
      setQuery(selectedVehicles?.[0]?.plate || selectedVehicles?.[0]?.vin);
      handleChangeSop(
        'ops_',
        selectedVehicles?.[0]?.plate || selectedVehicles?.[0]?.vin
      );
    }
  }, []);

  const viewComponentProps = {
    handleChangeQuery,
    handleClickItem,
    selectedVehicles,
    dealsPage,
    aggregations,
    query,
    sop,
    handleChangeSop,
    handleChangeFilter,
    handleClickCleanFilter,
    handleClickSearch,
    handleClickSearchMore: () => setCurrentPage(currentPage + 1),
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    rowCount: dealsPage?.numResults,
    handleKeyPress,
    isLoading,
    isLoadingMore: isLoading,
    isLoadingTable: isLoading,
    search,
    allAvailable,
    firstRenderingOfTheVehicleStep,
    setFirstRenderingOfTheVehicleStep,
    multipleSelection,
  };

  return <ListDealsComponent {...viewComponentProps} />;
}

export default ListDealsContainer;
