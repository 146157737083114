import {
  HorizontalAlignmentProps,
  SelfAlignmentProps,
  SpacingProps,
  SpacingTypes,
  VerticalAlignmentProps,
} from './types';

export const SPACING: SpacingProps = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 48,
  none: 0,
};

export const VERTICAL_ALIGMENT: VerticalAlignmentProps = {
  stretch: 'stretch',
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  baseline: 'baseline',
};

export const HORIZONTAL_ALIGMENT: HorizontalAlignmentProps = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
};

export const SELF_ALIGNMENT: SelfAlignmentProps = {
  start: 'flex-start',
  end: 'flex-end',
  stretch: 'stretch',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
};

export const TIPOGRAPHY = {
  heading: [
    {
      fontSize: 48,
      lineHeight: 58,
    },
    {
      fontSize: 32,
      lineHeight: 48,
    },
    {
      fontSize: 24,
      lineHeight: 36,
    },
    {
      fontSize: 20,
      lineHeight: 32,
    },
    {
      fontSize: 18,
      lineHeight: 28,
    },
    {
      fontSize: 16,
      lineHeight: 24,
    },
  ],
};

export const cardShadow = `
    shadow-color: #000;
    shadow-opacity: 0.4;
    shadow-offset: 0px 6px;
    shadow-radius: 7px;
    elevation: 12;
  `;

export const defaultShadow = `
    box-shadow: 0px 2px 4px #3333331A;
  `;

export const scrollbar = `
  /* Estilização da barra de rolagem */
  ::-webkit-scrollbar {
    width: 6px; /* Largura da barra de rolagem */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd; /* Cor do "thumb" (alça da barra de rolagem) */
    border-radius: 4px; /* Borda arredondada do "thumb" */
    border-color: #ddd;
  }

  ::-webkit-scrollbar-track {
    background-color: #eee; /* Cor da pista da barra de rolagem */
  }
`;

export function getSize(measure: SpacingTypes | number) {
  return typeof measure === 'number' ? `${measure}px` : `${SPACING[measure]}px`;
}

export function getSquareSizeInNumber(measure: SpacingTypes | number) {
  const handleSquare = {
    xs: 24,
    sm: 30,
    md: 42,
    lg: 60,
    xl: 72,
    none: 0,
  };
  return typeof measure === 'number' ? measure : handleSquare[measure];
}

export function getSizeInNumber(measure: SpacingTypes | number) {
  return typeof measure === 'number' ? measure : SPACING[measure];
}
