import styled from 'styled-components';
import { makeStyles, RadioGroup } from '@material-ui/core';

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const PaymentContent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SaleTypeRadioGroup = styled(RadioGroup)`
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 12px;
`;

export const SaleDataContent = styled.div`
  display: flex;
  margin-top: 6px;
  margin-bottom: 24px;
  > * {
    margin-right: 10px !important;
    max-width: 350px !important;
  }
`;

export const PercentageCommissionContent = styled.div`
  margin-top: 6px;
  margin-bottom: 24px;
  > * {
    max-width: 350px !important;
  }
`;

export const useStyles = makeStyles({
  checked: {
    color: '#03b2ab !important',
  },
});
