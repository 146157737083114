import { localStore } from 'components/Storage';
import columns from 'constants/columns';
import { DATES_BY_PERIOD } from 'helpers/predefinedDates';

const LOCAL_STORE_NAME = 'callcenter-storage';

const INITIAL_STATE = {
  currentTab: 0,
  filters: {
    origin: [],
    providerId: [],
    salesAssistantId: null,
    periodId: DATES_BY_PERIOD.yesterDayAndToday.id,
    dealerId: null,
  },
  columnOrdenation: {},
};

class ManageCallCenterLocalStorage {
  // MANAGE_LOCAL_STORAGE_ITEM
  static createLocalStoraObject = () => {
    localStore.setItem(LOCAL_STORE_NAME, JSON.stringify(INITIAL_STATE));
    return this.getLocalStorageData();
  };

  static getLocalStorageData = () => {
    const localStorageData = JSON.parse(localStore.getItem(LOCAL_STORE_NAME));
    if (localStorageData) {
      return localStorageData;
    }
    return this.createLocalStoraObject();
  };

  static setLocalStorageData = data => {
    localStore.setItem(LOCAL_STORE_NAME, JSON.stringify(data));
  };

  // MANAGE_TAB
  static setCurrentTab = currentTab => {
    const localStorageData = this.getLocalStorageData();
    localStorageData.currentTab = currentTab;
    this.setLocalStorageData(localStorageData);
  };

  static getCurrentTab = () => {
    const localStorageData = this.getLocalStorageData();
    return localStorageData.currentTab;
  };

  // MANAGE_COLUMN_ORDENATION
  static setColumnOrdenation = (id, currentOrdenation) => {
    const localStorageData = this.getLocalStorageData();
    if (id) {
      localStorageData.columnOrdenation = {
        ...localStorageData.columnOrdenation,
        [id]: {
          id,
          currentOrdenation,
        },
      };
      this.setLocalStorageData(localStorageData);
    }
  };

  static getColumnOrdenation = columnId => {
    const localStorageData = this.getLocalStorageData();
    const currentColumnOrdenation =
      localStorageData?.columnOrdenation?.[columnId]?.currentOrdenation;

    if (currentColumnOrdenation) {
      return currentColumnOrdenation;
    }

    return columnId === columns.SCHEDULED.id ? 4 : 0;
  };

  // MANAGE_FILTERS
  static setFilters = (filterName, newValue) => {
    const localStorageData = this.getLocalStorageData();
    if (filterName) {
      localStorageData.filters = {
        ...localStorageData.filters,
        [filterName]: newValue,
      };
      this.setLocalStorageData(localStorageData);
    }
  };

  static getFilter = filterName => {
    const localStorageData = this.getLocalStorageData();
    const currentFilter = localStorageData?.filters?.[filterName];
    return currentFilter;
  };
}

export default ManageCallCenterLocalStorage;
