import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import DealerServices from 'services/dealerServices';

export const useGetDownPaymentSimulations = () => {
  const { dealerId, proposalId } = useSelector(
    (state: any) => state.proposalStore.currentProposal
  );

  return useQuery({
    queryKey: 'get_downPayment_simulations',
    queryFn: () =>
      DealerServices.getDownPaymentSimulations(dealerId, proposalId),
    select(data) {
      return data.success ? data.data : [];
    },
  });
};
