import React, { Fragment, lazy, useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { Tooltip, Grid } from '@material-ui/core';

import AutoComplete from 'components/atoms/AutoComplete';
import Wrapper from 'components/Wrapper';

import lazyRetry from 'helpers/lazyRetry';
import useSnackBar from 'hooks/useSnackBar';

import ProposalServices from 'services/proposalServices';
import { useChatContext } from 'pages/chats/ChatsContext';

import MessageDetailDialog from './components/MessageDetailDialog';
import { useGetNonAttendanceReasons } from './hooks/useGetNonAttendanceReasons';
import { OPTIONS_CLIENT_APPEARED } from './utils/constants';

import * as S from './styles';

const RemoveScheduled = lazy(() =>
  lazyRetry(() => import('components/RemoveScheduled'))
);

export default function Schedules(props) {
  const { item, seller } = props;

  const { error } = useSnackBar();
  const { currentProposal }: any = useChatContext();

  const defaultOption = OPTIONS_CLIENT_APPEARED[2];

  const [state, setState] = useState({
    loadingDoneSchedule: false,
    nonAttendanceReason: undefined,
    openModalMessageDetailDialog: false,
    openModalRemoveScheduleds: false,
    scheduleDoneSelected: defaultOption,
  });

  const {
    reasonsOptions,
    loadingDealerConfig,
    loadingReasonsOptions,
    showNonAttendanceReasons,
  } = useGetNonAttendanceReasons({
    dealerId: currentProposal.dealerId,
  });

  const messageSplit = item?.message?.split('Proposta:')?.[0] || item?.message;

  const loadingReasons = loadingDealerConfig || loadingDealerConfig;

  const showReasonsOptions =
    state.scheduleDoneSelected?.done === false && showNonAttendanceReasons;

  const getScheduleDoneSelected = useCallback(() => {
    if (!state.scheduleDoneSelected?.value) return null;

    const selected = OPTIONS_CLIENT_APPEARED.find(
      option => option.value === state.scheduleDoneSelected?.value
    );

    return selected;
  }, [state.scheduleDoneSelected]);

  const handleClickCloseModalRemoveScheduleds = () => {
    return setState(prevState => ({
      ...prevState,
      openModalRemoveScheduleds: false,
    }));
  };

  const putDoneSchedule = async ({ done, scheduledId, reasonId = null }) => {
    try {
      setState(prevState => ({
        ...prevState,
        loadingDoneSchedule: true,
      }));

      const { success }: any = await ProposalServices.changeDoneSchedule({
        dealerId: currentProposal.dealerId,
        proposalId: currentProposal.id,
        scheduledId,
        done,
        ...(reasonId && { reasonId }),
      });

      if (success) {
        setState(prevState => ({
          ...prevState,
          loadingDoneSchedule: false,
        }));

        props.callbackOnSuccess();
      }
    } catch {
      setState(prevState => ({
        ...prevState,
        loadingDoneSchedule: false,
      }));

      return error('Desculpe, ocorreu um erro inesperado!');
    }
  };

  const handleSelectedScheduled = ({
    scheduleDoneSelected,
    changeSelected,
  }) => {
    if (changeSelected) {
      putDoneSchedule({
        scheduledId: props.item?.id,
        done: scheduleDoneSelected?.done,
      });
    }

    if (!scheduleDoneSelected) {
      return setState(prevState => ({
        ...prevState,
        scheduleDoneSelected: defaultOption,
      }));
    }

    return setState(prevState => ({ ...prevState, scheduleDoneSelected }));
  };

  const handleNonAttendanceReason = option => {
    setState(prevState => ({ ...prevState, nonAttendanceReason: option }));

    return putDoneSchedule({
      done: false,
      reasonId: option?.value,
      scheduledId: props.item?.id,
    });
  };

  useEffect(() => {
    handleSelectedScheduled({
      changeSelected: false,
      scheduleDoneSelected: OPTIONS_CLIENT_APPEARED.find(
        option => option.done === item?.done
      ),
    });
  }, [item?.done]);

  useEffect(() => {
    if (!item?.reason) return;

    const nonAttendanceReason = reasonsOptions.find(
      option => option.value === item.reason.id
    );

    setState(prevState => ({ ...prevState, nonAttendanceReason }));
  }, [item?.reason, reasonsOptions]);

  return (
    <Fragment>
      <Grid xs={12} direction="column">
        <S.Container>
          <S.Separator />
          <S.MainBox>
            <S.WrapperContainer>
              <Wrapper
                flex
                width={'100%'}
                direction="row"
                justify="between"
                mb={8}
              >
                <Wrapper flex width={'100%'}>
                  <S.Text>
                    {item?.type?.name && (
                      <S.OriginTag>{item?.type?.name}</S.OriginTag>
                    )}
                  </S.Text>
                </Wrapper>
                {item.scheduledDate && (
                  <S.Reminder>
                    <S.LabelLight>
                      {moment(item.scheduledDate).format('DD/MM/YYYY HH:mm')}
                    </S.LabelLight>
                  </S.Reminder>
                )}
              </Wrapper>
              {item?.message && messageSplit.length > 2 && (
                <S.ContainerMessage>
                  <Tooltip title={'Mensagem: ' + messageSplit}>
                    <S.LabelMessage>{messageSplit}</S.LabelMessage>
                  </Tooltip>
                  {messageSplit.length > 59 && (
                    <S.ButtonLink
                      onClick={() =>
                        setState(prevState => ({
                          ...prevState,
                          openModalMessageDetailDialog: true,
                        }))
                      }
                    >
                      Ler tudo
                    </S.ButtonLink>
                  )}
                </S.ContainerMessage>
              )}
              <Wrapper width={'100%'} mb={8} mt={8}>
                <AutoComplete
                  options={OPTIONS_CLIENT_APPEARED}
                  label="Cliente compareceu?"
                  name="scheduleDoneSelected"
                  value={getScheduleDoneSelected()}
                  isLoading={loadingReasons || state.loadingDoneSchedule}
                  onChange={selectedOption => {
                    return handleSelectedScheduled({
                      changeSelected: true,
                      scheduleDoneSelected: selectedOption,
                    });
                  }}
                />
              </Wrapper>
              {showReasonsOptions && (
                <Wrapper width={'100%'} mb={8} mt={8}>
                  <AutoComplete
                    options={reasonsOptions}
                    name="nonAttendanceReason"
                    label="Motivo de não comparecimento"
                    value={state.nonAttendanceReason}
                    isLoading={
                      loadingReasonsOptions || state.loadingDoneSchedule
                    }
                    onChange={selectedOption => {
                      return handleNonAttendanceReason(selectedOption);
                    }}
                  />
                </Wrapper>
              )}
              {!!seller && (
                <Tooltip title={'Autor da anotação: ' + seller}>
                  <S.AuthorBox>{seller}</S.AuthorBox>
                </Tooltip>
              )}
            </S.WrapperContainer>
          </S.MainBox>
        </S.Container>
      </Grid>
      {state.openModalRemoveScheduleds && (
        <RemoveScheduled
          open={state.openModalRemoveScheduleds}
          item={item}
          {...props}
          handleClose={handleClickCloseModalRemoveScheduleds}
        />
      )}
      <MessageDetailDialog
        annotation={messageSplit}
        open={state.openModalMessageDetailDialog}
        handleClose={() =>
          setState(prevState => ({
            ...prevState,
            openModalMessageDetailDialog: false,
          }))
        }
      />
    </Fragment>
  );
}
