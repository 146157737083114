import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Item = styled.div`
  margin: 0;
  display: flex;
`;

export const CommonLabel = styled.div`
  font-style: normal;
  line-height: 20px;
  padding: ${({ padding }) => (padding ? padding : 'initial')};
  color: #757575;
  font-weight: 600;
  font-size: 12px;
`;

export const VehicleTitle = styled.div`
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const OvalBigger = styled.div`
  width: 30px;
  height: 30px;
  padding: 0px 4px;
  background-color: ${Colors.gray.nearWhite};
  font-family: Poppins Bold;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
`;

export const styles = () => ({
  root: {
    height: 42,
    cursor: 'pointer',
  },
});
