import styled from 'styled-components';

export const Container = styled.div`
  height: 54px;
  border-bottom: 1px solid rgb(204, 204, 204);
  border-left: 4px solid #05b2ab;
  display: flex;
  align-items: center;
  padding: 0px 13px;
  font-family: Poppins Bold;
  font-weight: 600;
  font-family: 'Poppins Medium';
  font-size: 14px;
`;
