import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  //CURRENT GROUP
  SET_CURRENT_GROUP: `@${MODULE}/${getters.SET}_CURRENT_GROUP_${actionOptions.SUCCESS}`,
  //SET_CURRENT_USER
  SET_CURRENT_USER: `@${MODULE}/${getters.SET}SET_CURRENT_USER`,
  //PROPOSALS BY GROUP
  GET_ALL_PROPOSALS_BY_GROUP: `@${MODULE}/${getters.GET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.REQUEST}`,
  SET_ALL_PROPOSALS_BY_GROUP_SUCCESS: `@${MODULE}/${getters.SET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.SUCCESS}`,
  SET_ALL_PROPOSALS_BY_GROUP_ERROR: `@${MODULE}/${getters.SET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.ERROR}`,
  SET_ALL_PROPOSALS_BY_GROUP_LOADING: `@${MODULE}/${getters.SET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.LOADING}`,

  UPDATE_PROPOSAL_BY_ID: `@${MODULE}/${getters.SET}_UPDATE_PROPOSAL_BY_ID`,
  //FILTERS
  MANAGEMENT_FILTER: `@${MODULE}/MANAGEMENT_FILTER`,
  // CLEAN_STORE
  CLEAN_STORE: `@${MODULE}/CLEAN_STORE`,
};
