import styled from 'styled-components';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Container = styled.div`
  margin: 15px;
  border: solid;
  border-width: 0.5px;
  border-color: #e0e0e0;
  border-radius: 4px;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid;
  border-width: 0.5px;
  border-color: #e0e0e0;
  padding-bottom: 12px;
`;

export const VisitContainer = styled.div``;

export const ServiceStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckIcon = styled(CheckCircleOutlineIcon)`
  margin-right: 6px;
  color: #009a8a;
  font-size: 17px;
`;

export const ErrorIcon = styled(HighlightOffIcon)`
  margin-right: 6px;
  color: #f44336;
  font-size: 17px;
`;

export const VisitScheduled = styled.div`
  display: flex;
  align-items: center;
`;

export const VisitStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: bolder;
  color: #424242;
  line-height: 120%;
  display: flex;
  gap: 8px;
`;

export const Title = styled.div`
  margin-right: 9px;
  font-size: 14px;
  color: #757575;
`;

export const Text = styled.div`
  margin-right: 9px;
  font-size: 14px;
  color: #424242;
`;

export const DateContainer = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;
  width: 100%;
`;

export const DateWrapper = styled.div`
  width: 50%;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid;
  border-width: 0.5px;
  border-color: #e0e0e0;
  padding-top: 12px;
`;

export const OvalBigger = styled.div`
  margin-right: 8px;
  width: 32px;
  height: 32px;
  background-color: ${Colors.gray.nearWhite};
  font-family: Poppins Bold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
