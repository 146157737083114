import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'DealServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const searchDomain = EnvVars('API_SEARCH_BASE_URL');

export default class DealServices {
  static getDeal(dealId) {
    return request
      .get(`${searchDomain}/deals/v1.0/${dealId}`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getDeal()`));
  }

  static getReportStatus = () => {
    return request
      .get(`${domain}/options/v1.0/reportstatus`)
      .then(buildHTTPResponseHandler(`${serviceName}.getReportStatus()`));
  };

  static createDeal = (dealerId, deal) => {
    return request
      .post(`${domain}/dealer/${dealerId}/inventory/resale/v1.0`, deal, {
        params: { resale: true },
      })
      .then(buildHTTPResponseHandler(`${serviceName}.createDeal()`));
  };

  static getDealById = (dealerId, dealId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/resale/v1.0/${dealId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getDealById()`));
  };

  static updateDeal = (dealerId, deal) => {
    //o erro esta aqui
    return request
      .put(
        `${domain}/dealer/${dealerId}/inventory/resale/v1.0/${deal.id}`,
        deal
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateDealById()`));
  };

  static deleteDeal = (dealerId, dealId) => {
    return request
      .delete(`${domain}/dealer/${dealerId}/inventory/resale/v1.0/${dealId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteDeal()`));
  };

  static getPerformanceFromDeal = (dealId, dealerId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/inventory/resale/v1.0/performance`, {
        dealId,
      })
      .then(
        buildHTTPResponseHandler(`${serviceName}.getPerformanceFromDeal()`)
      );
  };

  static getDealerLocalities = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/locality`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getDealerLocalities()`));
  };

  static activateDeal = (dealerId, dealId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/inventory/resale/v1.0/activate/${dealId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.activateDeal()`));
  };

  static inactivateDeal = (dealerId, dealId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/inventory/resale/v1.0/inactivate/${dealId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.inactivateDeal()`));
  };
}
