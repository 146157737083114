import { useSelector } from 'react-redux';

const useProposal = () => {
  const proposal = useSelector(
    ({ proposalStore }) => proposalStore.currentProposal
  );
  return proposal;
};

export default useProposal;
