import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';

import * as S from './styles';

export default function MessageDetailDialog({ annotation, open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth={true}>
      <DialogTitle>
        <S.DialogHeader>
          <span>Detalhes da Mensagem</span>
        </S.DialogHeader>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{annotation}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <S.DialogFooter>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </S.DialogFooter>
      </DialogActions>
    </Dialog>
  );
}
