import styled from 'styled-components';

export const IconButton = styled.div`
  display: flex;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background: #52525240;
  }
`;
