import styled from 'styled-components';

export const IconContainer = styled.div`
  background-color: rgb(68, 67, 188);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #424242;
`;

export const Text = styled.div`
  display: ${props => (props.notVisible === false ? 'none' : 'flex')};
  flex-direction: row;
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 300;
  color: #424242;
`;
