import Checkbox from 'bit/mobiauto.web-ui-components.components.checkbox/Checkbox';
import Radio from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import BookedTag from 'components/BookedTag/BookedTag';
import getImage from 'components/getImage';
import BookDetailDialog from 'pages/chats/components/SideBarComponents/BookVehicleInfos/BookedVehicleInfo/BookDetailDialog';
import React, { useState } from 'react';
import { Vehicle } from 'types/BookedVehicleTypes';
import {
  InfoTag,
  SelectableContainer,
  TootipTitle,
  VehicleBody,
  VehicleImage,
  VehicleItemContainer,
  VehicleTagLine,
  VehicleTitle,
  ViewBookedLink,
  BookedVehicleLine,
} from './styles';

type VehicleItemProps = {
  vehicle: Vehicle;
  onCheckClick?: (vehicle: Vehicle) => void;
  isChecked?: boolean;
  onBookCancelled?: () => void;
  isMultiple?: boolean;
  isSelectable?: boolean;
  showBooked?: boolean;
  showValue?: boolean;
};

export default function VehicleItem({
  vehicle,
  onCheckClick = () => '',
  isChecked,
  onBookCancelled,
  isMultiple = true,
  isSelectable = true,
  showBooked = true,
  showValue = false,
}: VehicleItemProps) {
  const [openViewBookedVehicle, setOpenViewBookedVehicle] = useState(false);

  const handleOpenViewBookedVehicle = () => {
    setOpenViewBookedVehicle(true);
  };

  const handleCloseViewBookedVehicle = () => {
    setOpenViewBookedVehicle(false);
  };

  const title =
    vehicle.title ||
    `${vehicle.makeName} ${vehicle.modelName} ${vehicle.trimName}`;
  const imageId = vehicle.imageId || vehicle.images?.[0]?.imageId;

  const linkImage = imageId
    ? getImage(
        { imageId: vehicle.imageId, imageVcode: vehicle.imageVcode },
        80,
        290,
        0
      )
    : require('assets/default-image.svg');

  const isEmpty = (str?: string): boolean => {
    return !str || str === '' || str === 'null';
  };

  return (
    <>
      <VehicleItemContainer isChecked={isChecked}>
        {vehicle?.imageId && <VehicleImage src={linkImage} />}
        <VehicleBody>
          <VehicleTitle>{title}</VehicleTitle>
          <VehicleTagLine>
            {!isEmpty(vehicle.plate) && (
              <Tooltip title={<TootipTitle>Placa</TootipTitle>}>
                <InfoTag>{vehicle.plate}</InfoTag>
              </Tooltip>
            )}
            {!isEmpty(vehicle.vin) && isEmpty(vehicle.plate) && (
              <Tooltip title={<TootipTitle>Chassi</TootipTitle>}>
                <InfoTag>...{vehicle.vin?.slice(-5)}</InfoTag>
              </Tooltip>
            )}
            <Tooltip title={<TootipTitle>Ano fabricação/modelo</TootipTitle>}>
              <InfoTag>
                {vehicle.productionYear}/{vehicle.modelYear}
              </InfoTag>
            </Tooltip>
            <Tooltip title={<TootipTitle>Kilometragem</TootipTitle>}>
              <InfoTag>{vehicle.km}km</InfoTag>
            </Tooltip>
            <Tooltip title={<TootipTitle>Cor</TootipTitle>}>
              <InfoTag>{vehicle?.color}</InfoTag>
            </Tooltip>
          </VehicleTagLine>
          {showBooked && vehicle.bookedActive && (
            <BookedVehicleLine>
              <Button onClick={handleOpenViewBookedVehicle}>
                <ViewBookedLink>Visualizar reserva</ViewBookedLink>
              </Button>
            </BookedVehicleLine>
          )}
          {showValue && vehicle.value && (
            <InfoTag accent>{vehicle.value}</InfoTag>
          )}
        </VehicleBody>

        {isSelectable && (
          <>
            {showBooked && vehicle.bookedActive ? (
              <SelectableContainer style={{ marginRight: 8 }}>
                <BookedTag bookedAt={vehicle.bookedAt} variant="mini" />
              </SelectableContainer>
            ) : (
              <SelectableContainer>
                {isMultiple ? (
                  <Checkbox
                    disabled={vehicle.bookedActive}
                    checked={isChecked}
                    onChange={() => onCheckClick(vehicle)}
                  />
                ) : (
                  <Radio
                    disabled={vehicle.bookedActive}
                    checked={isChecked}
                    onChange={() => onCheckClick(vehicle)}
                  />
                )}
              </SelectableContainer>
            )}
          </>
        )}
      </VehicleItemContainer>
      {vehicle.bookedActive && (
        <BookDetailDialog
          bookedId={vehicle.bookedId}
          open={openViewBookedVehicle}
          onClose={handleCloseViewBookedVehicle}
          onBookCancelled={onBookCancelled}
        />
      )}
    </>
  );
}
