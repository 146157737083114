import React from 'react';
import styled from 'styled-components';
import Close from '@material-ui/icons/Close';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import { Desktop, Mobile } from 'components/Responsive';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const Line = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
`;
const LineSpaceBetween = styled(Line)`
  justify-content: space-between;
  width: 100%;
`;

const FieldSpaceBetween = styled(Line)`
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
`;

const TitleText = styled.div`
  font-family: 'Poppins Bold';
  font-weight: bold;
  line-height: 1.5;
  color: ${Colors.gray.dark};
`;
const Label = styled.div`
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
  color: ${Colors.gray.dark};
  font-size: 16px;
`;

const styles = {
  root: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    display: 'flex',
  },
};
const ButtonAndDeleting = ({
  isDeleting,
  handleOnClose,
  labelWaitingConfirmation,
  handleOnDelete,
}) => {
  if (isDeleting) {
    return (
      <FieldSpaceBetween>
        <Label>{labelWaitingConfirmation}</Label>
      </FieldSpaceBetween>
    );
  }
  return (
    <LineSpaceBetween>
      <Button
        onClick={handleOnClose}
        variant="primary"
        style={{
          fontFamily: 'Poppins Regular',
          color: Colors.primary.medium,
          backgroundColor: 'transparent',
          borderRadius: '4px',
          marginRight: '32px',
          border: `solid 1px ${Colors.primary.medium}`,
        }}
      >
        Cancelar
      </Button>
      <Button
        variant="primary"
        onClick={handleOnDelete}
        width="40%"
        style={{ fontFamily: 'Poppins Regular' }}
      >
        Confirmar
      </Button>
    </LineSpaceBetween>
  );
};
const DeleteDialog = ({
  open,
  isDeleting,
  title,
  handleOnClose,
  labelWaitingConfirmation,
  handleOnDelete,
  classes,
}) => (
  <React.Fragment>
    <Desktop>
      <Dialog
        open={open}
        onClose={handleOnClose}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle>
          <LineSpaceBetween>
            <Grid item xs={10}>
              <TitleText>{title}</TitleText>
            </Grid>
            <Grid item xs={2}>
              <IconButton aria-label="Close" onClick={handleOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </LineSpaceBetween>
        </DialogTitle>

        <DialogContent>
          <ButtonAndDeleting
            isDeleting={isDeleting}
            handleOnClose={handleOnClose}
            handleOnDelete={handleOnDelete}
            labelWaitingConfirmation={labelWaitingConfirmation}
          />
        </DialogContent>
      </Dialog>
    </Desktop>
    <Mobile>
      <Dialog
        open={open}
        onClose={handleOnClose}
        aria-labelledby="simple-dialog-title"
        fullScreen
      >
        <DialogTitle>
          <LineSpaceBetween>
            <Grid item xs={10}>
              <TitleText>{title}</TitleText>
            </Grid>

            <Grid item xs={2}>
              <IconButton aria-label="Close" onClick={handleOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </LineSpaceBetween>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.root,
          }}
        >
          <ButtonAndDeleting
            isDeleting={isDeleting}
            handleOnClose={handleOnClose}
            handleOnDelete={handleOnDelete}
            labelWaitingConfirmation={labelWaitingConfirmation}
          />
        </DialogContent>
      </Dialog>
    </Mobile>
  </React.Fragment>
);

export default withStyles(styles)(DeleteDialog);
