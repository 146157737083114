import ErrorReport from 'services/ErrorReport';
function buildResponseString(data) {
  try {
    return JSON.stringify(data);
  } catch {
    return String(data);
  }
}
class RequestError {
  constructor(message, type = 'RequestError') {
    this.message = message;
    this.type = type;
  }
  toString() {
    const { message, request, response } = this;
    const data = {
      request,
      response,
      message,
    };
    return JSON.stringify(data, null, 2);
  }
}

class RequestExtendedError extends Error {
  constructor(message, request, response) {
    super();
    this.message = message;
    this.request = request;
    this.response = response;
  }
  toString() {
    const { message, request, response } = this;
    const data = {
      request,
      response,
      message,
    };
    return JSON.stringify(data, null, 2);
  }
}

export default function buildHTTPResponseHandler(methodName) {
  return ({ ok, data, status, problem, originalError, ...rest }) => {
    if (ok) return { success: ok, data };
    if ([400, 401, 404, 422, 500].includes(status)) {
      const errorMessage =
        originalError?.response?.data?.message ??
        data?.callBack ??
        Object.values(data?.errors || {})?.[0] ??
        'Erro não identificado';

      return {
        success: false,
        data: errorMessage,
      };
    }
    const shouldLogError = problem !== 'NETWORK_ERROR';
    const response = buildResponseString(data);
    const errorMessage = `RequestError: ${methodName} failed, status: ${status}, problem: ${problem}, response: ${response}`;
    const err = new RequestError(errorMessage, problem);

    err.request = {
      headers: buildResponseString(rest.config.headers),
      data: rest.config.data,
      url: rest.config.url,
    };
    // Remove redundant information
    try {
      delete rest.originalError.response.request;
      delete rest.originalError.response.config;
    } catch (_err) {
      // eslint-disable-next-line
    }
    err.response = {
      status,
      problem,
      response,
      headers: buildResponseString(rest?.originalError?.response),
    };
    if (shouldLogError) {
      ErrorReport.logError(err);
      throw new RequestExtendedError(err.message, err.response, err.request);
    }
    return {
      success: false,
      data: err,
    };
  };
}
