import styled from 'styled-components';

export const TextLink = styled.div`
  color: #4443bc;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`;
