import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

export const ExpansionPanelContainer = styled.div`
  margin: 30px;
`;

export const ExpansionPanelTitle = styled.div`
  font-size: 16px;
`;

export const PreviousPanelButton = styled(Button)`
  background: #ffffff !important;
  color: #424242 !important;
  text-transform: inherit !important;
  padding: 0 16px !important;
  margin-right: 16px !important;
`;

export const ActionsContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
export const NextPanelButton = styled(Button)`
  background: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  color: #424242 !important;
  text-transform: inherit !important;
  padding: 0 16px !important;
  margin-right: 16px !important;
  transition: width ease-in-out 500ms;
`;

export const MiniLoading = styled(CircularProgress)`
  margin-left: 8px;
  color: #424242 !important;
`;

export const IconContainer = styled.div`
  background-color: rgb(68, 67, 188);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
`;
