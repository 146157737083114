import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'OrderDocumentsServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class OrderDocumentsServices {
  static getMatrixDocumentsCheckout = (
    dealerId: number,
    proposalId: number
  ) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/documents/v1.0/${proposalId}/documents`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getMatrixDocumentsCheckout()`
        ) as any
      );
  };

  static approveDocumentById = (dealerId, documentId, reason) => {
    return request
      .patch(
        `${domain}/dealer/${dealerId}/proposal/order/documents/v1.0/${documentId}/approve`,
        {},
        {
          params: {
            reason,
          },
        }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.approveDocumentById()`) as any
      );
  };

  static deleteDocumentById = (dealerId, documentId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposal/order/documents/v1.0/${documentId}/delete`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.deleteDocumentById()`) as any
      );
  };

  static reproveDocumentById = (dealerId, documentId, reason) => {
    return request
      .patch(
        `${domain}/dealer/${dealerId}/proposal/order/documents/v1.0/${documentId}/reprove`,
        {},
        {
          params: {
            reason,
          },
        }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.reproveDocumentById()`) as any
      );
  };

  static uploadDocument = (
    dealerId,
    documentId,
    { documentConfigId, fileName, mediaId, mediaType, proposalOrderPaymentId }
  ) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/order/documents/v1.0/${documentId}/upload`,
        {},
        {
          params: {
            documentConfigId,
            fileName,
            mediaId,
            mediaType,
            proposalOrderPaymentId,
          },
        }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.uploadDocument()`) as any);
  };
}
