import { BookButton } from './styles';
import React, { useState } from 'react';
import BookVehicleDialog from './BookVehicleDialog';
import BookVehicleConfirmDialog from './BookVehicleConfirmDialog';

export default function ToBookVehicle(props) {
  const { updateProposal } = props;

  const [openBookVehicle, setOpenBookVehicle] = useState(false);
  const [openBookConfirm, setOpenBookConfirm] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState([]);

  const handleClickOpen = () => {
    setOpenBookVehicle(true);
  };

  const handleConfirmDialog = () => {
    setOpenBookVehicle(false);
    setOpenBookConfirm(true);
  };

  const handleCloseConfirm = reservedVehicle => {
    setOpenBookConfirm(false);

    if (reservedVehicle) updateProposal();
    else setOpenBookVehicle(true);
  };

  return (
    <>
      {!props.closedProposal && (
        <BookButton onClick={handleClickOpen}>Reservar veículo</BookButton>
      )}
      <BookVehicleDialog
        open={openBookVehicle}
        handleClickSelectVehicle={setCurrentVehicle}
        handleConfirmDialog={handleConfirmDialog}
        handleOnClose={() => setOpenBookVehicle(false)}
        currentVehicle={currentVehicle}
      />
      <BookVehicleConfirmDialog
        currentVehicle={currentVehicle?.[0] ?? null}
        open={openBookConfirm}
        onClose={handleCloseConfirm}
      />
    </>
  );
}
