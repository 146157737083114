import React from 'react';
import GenericDialog from 'components/GenericDialog';
import { MyText } from './styles';
import Wrapper from 'components/Wrapper/Wrapper';
import { Button } from '@material-ui/core';

interface DeleteDocDialogProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
  deleteDoc(): void;
}

const DeleteDocDialog: React.FC<DeleteDocDialogProps> = ({
  isOpen,
  setIsOpen,
  deleteDoc,
}) => {
  const onClose = (): void => setIsOpen(false);

  return (
    <GenericDialog
      open={isOpen}
      onClose={onClose}
      title="Excluir documento"
      maxWidth="sm"
      OnCloseButton
      DialogAction={(): JSX.Element => (
        <Wrapper flex gap={16} justify="end" align="end" width="100%">
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            style={{
              background: '#F44336',
              color: 'white ',
            }}
            onClick={(): void => {
              deleteDoc();
              setIsOpen(false);
            }}
          >
            excluir
          </Button>
        </Wrapper>
      )}
    >
      <MyText>
        Essa ação <b>não pode ser desfeita. </b>
        Deseja prosseguir?
      </MyText>
    </GenericDialog>
  );
};

export default DeleteDocDialog;
