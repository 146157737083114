import styled from 'styled-components';
import { CircularProgress, Divider } from '@material-ui/core';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

export const Line = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const SubTitle = styled.b`
  font-size: 14px;
`;

export const BookInfo = styled.p`
  font-size: 14px;
  color: #757575;
  line-height: 1em;
`;

export const BookDivider = styled(Divider)`
  margin: 16px 0 !important;
`;

export const ActionText = styled.span`
  text-transform: none;
  padding: 4px 12px;
  font-weight: 500;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 16px;
`;

export const DefaultButton = styled(Button)`
  margin: 0 5px !important;
  text-transform: none !important;
  color: #424242 !important;
  font-weight: 500 !important;
  border: 1px solid #bdbdbd !important;
`;

export const MiniLoading = styled(CircularProgress)`
  color: white !important;
  position: absolute;
  right: 8px;
`;

export const DaysToExpireNotFoundInfo = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    margin: 10px !important;
  }
`;

export const LoadingDealer = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-left: 16px;
    font-size: 14px;
    color: #555;
  }
`;
