import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import InputBase from '@material-ui/core/InputBase';
import SearchButton from 'modules/financing/components/atoms/SearchButton'
import InputAdornment from '@material-ui/core/InputAdornment';
import { WrapperInput, styles } from './styles';

const SearchInput = ({ classes, searchFunction, ...props }) => {
  return <WrapperInput>
    <InputBase
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      endAdornment={
        <InputAdornment position="end">
          <SearchButton onClick={searchFunction} />
        </InputAdornment>
      }
      {...props}
    />
  </WrapperInput>
}

export default withStyles(styles)(SearchInput);