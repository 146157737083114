import actionTypes from './actionTypes';
import { setAllBaseUrlsFromModules } from 'services';

const setBaseUrlWithDealeridAPiMiddleWare = store => next => action => {
  if (action.type == actionTypes.SAVE_DEALER_INFOS) {
    const selectDealer: any = payload => payload?.dealer;
    const dealer = selectDealer(action.payload);

    //MODULES WITH DEALER ID IN URL
    setAllBaseUrlsFromModules(dealer);
  }

  return next(action);
};

export { setBaseUrlWithDealeridAPiMiddleWare };
