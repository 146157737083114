interface CreateInsuranceProposalParams {
  clientEmail?: string;
  clientUserCpfCnpj?: string;
  clientUserName?: string;
  clientUserPhone?: string;
  modelOrModelName?: string;
  modelYear?: number;
  plate?: string;
  productionYear?: number;
  proposalId?: number;
  vin?: string;
}

enum InsuranceProposalParams {
  clientEmail = 'Padrao2000_E_mail',
  clientUserCpfCnpj = 'Padrao2000_Cgc_cpf',
  clientUserName = 'Padrao2000_Nome',
  clientUserPhone = 'Padrao2000_Telefone_celMod1',
  modelOrModelName = 'Padrao2000_Modelo',
  modelYear = 'Padrao2000_Anomodelo',
  plate = 'Padrao2000_PlacaMod3',
  productionYear = 'Padrao2000_Anofabricacao',
  proposalId = 'Padrao2000_CotacaoExterna',
  vin = 'Padrao2000_ChassiMod3',
}

export const getParamsToCreateInsuranceProposal = (
  params: CreateInsuranceProposalParams
) => {
  const cleanedParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => {
      return value !== undefined && String(value).trim().length > 0;
    })
  );

  const filteredParams: Record<string, string> = {};

  Object.entries(InsuranceProposalParams).forEach(([key, value]) => {
    if (key in cleanedParams) filteredParams[value] = cleanedParams[key];
  });

  const formattedParams = Object.entries(filteredParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return formattedParams;
};
