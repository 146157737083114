import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  GET_DEALS: `@${MODULE}/${getters.GET}_DEALS_${actionOptions.REQUEST}`,
  GET_DEALS_SUCCEDED: `@${MODULE}/${getters.GET}_DEALS_${actionOptions.SUCCESS}`,
  GET_DEALS_ERROR: `@${MODULE}/${getters.GET}_DEALS_${actionOptions.ERROR}`,
  GET_DEALS_LOADING: `@${MODULE}/${getters.GET}_DEALS_${actionOptions.LOADING}`,
  GET_INFO_CARD: `@${MODULE}/${getters.GET}_INFO_CARD_${actionOptions.REQUEST}`,
  GET_CONTACT_INFO: `@${MODULE}/${getters.GET}_CONTACT_INFO_${actionOptions.REQUEST}`,

  CREATE_CONTACT: `@${MODULE}/${getters.GET}_CREATE_CONTACT`,

  ADD_DEAL: `@${MODULE}/ADD_DEAL`,

  DELETE_DEAL: `@${MODULE}/${getters.GET}_DELETE_DEAL_${actionOptions.REQUEST}`,
  DELETE_DEAL_SUCCEDED: `@${MODULE}/${getters.GET}_DELETE_DEAL_${actionOptions.SUCCESS}`,
  DELETE_DEAL_ERROR: `@${MODULE}/${getters.GET}_DELETE_DEAL_${actionOptions.ERROR}`,
  DELETING_DEAL: `@${MODULE}/${getters.GET}_DELETING_DEAL`,
  RESET_DELETE_DEAL: `@${MODULE}/${getters.GET}_RESET_DELETE_DEAL`,

  ACTIVATE_DEAL: `@${MODULE}/${getters.GET}_ACTIVATE_DEAL_${actionOptions.REQUEST}`,
  INACTIVATE_DEAL: `@${MODULE}/${getters.GET}_INACTIVATE_DEAL_${actionOptions.REQUEST}`,
  ACTIVATE_DEAL_SUCCEDED: `@${MODULE}/${getters.GET}_ACTIVATE_DEAL_${actionOptions.SUCCESS}`,
  ACTIVATE_DEAL_ERROR: `@${MODULE}/${getters.GET}_ACTIVATE_DEAL_${actionOptions.ERROR}`,
  INACTIVATE_DEAL_SUCCEDED: `@${MODULE}/${getters.GET}_INACTIVATE_DEAL_${actionOptions.SUCCESS}`,
  INACTIVATE_DEAL_ERROR: `@${MODULE}/${getters.GET}_INACTIVATE_DEAL_${actionOptions.ERROR}`,
  ACTIVING_DEAL: `@${MODULE}/${getters.GET}_ACTIVING_DEAL`,
  INACTIVING_DEAL: `@${MODULE}/${getters.GET}_INACTIVING_DEAL`,

  UPDATE_DEAL: `@${MODULE}/UPDATE_DEAL`,

  RECALCULATE_DEALS_INFOS: `@${MODULE}/RECALCULATE_DEALS_INFOS`,
  SET_DEALS_INFOS: `@${MODULE}/${getters.SET}_DEALS_INFOS`,
  SET_TXT_SEARCH: `@${MODULE}/${getters.SET}_TXT_SEARCH`,
  SET_BY_ORDER: `@${MODULE}/${getters.SET}_BY_ORDER`,
  SET_INFO_CARD: `@${MODULE}/${getters.SET}SET_INFO_CARD`,
  SET_INFO_CARD_ERROR: `@${MODULE}/${getters.SET}_DEALS_${actionOptions.ERROR}`,
  SET_CONTACT_INFO_ERROR: `@${MODULE}/${getters.SET}_CONTACT_INFO_${actionOptions.ERROR}`,
  SET_CONTACT_INFO: `@${MODULE}/${getters.SET}_CONTACT_INFO`,
  SET_UPDATE_CONTACT_INFO: `@${MODULE}/${getters.SET}_UPDATE_CONTACT_INFO`,
  SET_CONTACT_INFO_LOADING: `@${MODULE}/${getters.SET}_CONTACT_INFO_LOADING`,
};
