import React from 'react';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Grid from '@material-ui/core/Grid';
import BackgroundWrapper from 'components/BackgroundWrapper';
import { ReactComponent as CarroBroken } from 'assets/illustra.svg';
import ThemeButton from 'components/atoms/ThemeButton';
const styles = {
  grid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: '1',
    width: '100%',
    backgroundColor: '#f2f2f2',
    boxSizing: 'border-box',
  },
};

const LabelLight = styled.div`
  font-family: Poppins Regular;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.gray.light};
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  width: 100%;
  font-family: Poppins Regular;
  padding: 16px;
  max-width: 1344px;
  margin: 0 auto;
`;

const TitleMobile = styled.div`
  font-family: Poppins Regular;
  font-size: 20px;
  text-align: center;
  color: #000;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const Error500Component = props => {
  const { classes } = props;

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Grid container justify="center">
      <Grid item lg={12} md={12} xs={12} className={classes.grid}>
        <BackgroundWrapper color="#f2f2f2">
          <Grid container justify="center">
            <Container
              style={{
                height: '675px',
                borderRadius: '4px',
                boxShadow: '0 2px 4px 0 #00000019',
                backgroundColor: '#ffffff',
                padding: '40px',
                margin: '32px 40px',
              }}
            >
              <CarroBroken
                style={{
                  cursor: 'pointer',
                  fill: '#fff',
                  width: '553px',
                  height: '412px',
                }}
              />

              <TitleMobile>
                <b>Ops!</b>
              </TitleMobile>
              <LabelLight style={{ marginTop: '24px' }}>
                Algo deu errado.
              </LabelLight>
              <LabelLight style={{ marginTop: '24px' }}>
                Não foi possível executar o comando desejado.
              </LabelLight>
              <div style={{ margin: 16 }}>
                <ThemeButton onClick={refreshPage}>
                  Recarregar página
                </ThemeButton>
              </div>
            </Container>
          </Grid>
        </BackgroundWrapper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Error500Component);
