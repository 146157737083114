import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { Image, DealRepasse } from 'types/DealTypes';
import { Select } from 'types/SelectType';
import { Conditional } from 'types/ConditionalTypes';

export default {
  createDeal: function(): Action {
    return {
      type: ActionTypes.CREATE_DEAL,
    };
  },

  searchingPlate: function(): Action {
    return {
      type: ActionTypes.SEARCHING_DEAL_BY_PLATE,
    };
  },

  searchedPlate: function(): Action {
    return {
      type: ActionTypes.SEARCHED_DEAL_BY_PLATE,
    };
  },

  searchingPlateError: function(error: any): Action {
    return {
      type: ActionTypes.ERROR_SEARCHING_DEAL_BY_PLATE,
      payload: error,
    };
  },

  setDealValidateBaseData: function(): Action {
    return {
      type: ActionTypes.SET_VALIDATE_BASE_VEHICLE_DATA,
    };
  },

  setVehicleValuesValidate: function(): Action {
    return {
      type: ActionTypes.SET_VEHICLE_VALUES_VALIDATE_DATA,
    };
  },

  saveDeal: function(dealerId: number, history: History): Action {
    return {
      type: ActionTypes.SAVE_DEAL,
      payload: { dealerId, history },
    };
  },

  setDealMake: function(makeId: number, makeName: string): Action {
    return {
      type: ActionTypes.SET_MAKE,
      payload: { makeId, makeName },
    };
  },

  setDealImageUploads: function(images: File[]): Action {
    return {
      type: ActionTypes.SET_DEAL_IMAGES_UPLOADING,
      payload: images,
    };
  },

  setDealModel: function(modelId: number, modelName: string): Action {
    return {
      type: ActionTypes.SET_MODEL,
      payload: { modelId, modelName },
    };
  },

  setDealProductionYear: function(productionYear: number): Action {
    return {
      type: ActionTypes.SET_PRODUCTION_YEAR,
      payload: productionYear,
    };
  },

  setDealModelYear: function(modelYear: number): Action {
    return {
      type: ActionTypes.SET_MODEL_YEAR,
      payload: modelYear,
    };
  },

  setDealTrim: function(trimId: number, trimName: string): Action {
    return {
      type: ActionTypes.SET_TRIM,
      payload: { trimId, trimName },
    };
  },

  setDealDoor: function(door: number): Action {
    return {
      type: ActionTypes.SET_DOOR,
      payload: door,
    };
  },

  setDealColor: function(colorId: number): Action {
    return {
      type: ActionTypes.SET_COLOR,
      payload: colorId,
    };
  },

  setDealTransmission: function(transmissionId: number): Action {
    return {
      type: ActionTypes.SET_TRANSMISSION,
      payload: transmissionId,
    };
  },

  setDealFuel: function(fuelId: number): Action {
    return {
      type: ActionTypes.SET_FUEL,
      payload: fuelId,
    };
  },

  setDealKilometer: function(kilometer: number): Action {
    return {
      type: ActionTypes.SET_KILOMETER,
      payload: kilometer,
    };
  },

  setDealPlate: function(plate: string): Action {
    return {
      type: ActionTypes.SET_PLATE,
      payload: plate,
    };
  },

  setDealArmored: function(armored: boolean): Action {
    return {
      type: ActionTypes.SET_ARMORED,
      payload: armored,
    };
  },

  setDealDealerPJLocality: function(dealerPJLocality: Select[]): Action {
    return {
      type: ActionTypes.SET_DEALER_PJ_LOCALITY,
      payload: dealerPJLocality,
    };
  },

  setDealAuction: function(auction: boolean): Action {
    return {
      type: ActionTypes.SET_AUCTION,
      payload: auction,
    };
  },

  setDealCasualty: function(casualty: boolean): Action {
    return {
      type: ActionTypes.SET_CASUALTY,
      payload: casualty,
    };
  },

  setDealArmorType: function(armorType: string): Action {
    return {
      type: ActionTypes.SET_ARMOR_TYPE,
      payload: armorType,
    };
  },

  getVehicleInfosByPlate: function(
    vehicleType: string,
    plate: string,
    dealerId: string | number
  ): Action {
    return {
      type: ActionTypes.GET_VEHICLE_INFO_BY_PLATE,
      payload: { vehicleType, plate, dealerId },
    };
  },

  setVehicleInfosByPlate: function(state: any): Action {
    return {
      type: ActionTypes.GET_VEHICLE_INFO_BY_PLATE_SUCCEDED,
      payload: state,
    };
  },

  searchTransmissions: function(trim: any): Action {
    return {
      type: ActionTypes.SEARCH_TRANSMISSIONS,
      payload: trim,
    };
  },

  searchFuels: function(trim: any): Action {
    return {
      type: ActionTypes.SEARCH_FUELS,
      payload: trim,
    };
  },

  setDealFeatures: function(features: Select[]): Action {
    return {
      type: ActionTypes.SET_FEATURES,
      payload: features,
    };
  },

  setDealResaleComments: function(resaleComments: string): Action {
    return {
      type: ActionTypes.SET_COMMENTS,
      payload: resaleComments,
    };
  },

  setDealConditionalsIds: function(id: number, value: any): Action {
    return {
      type: ActionTypes.SET_FEATURES_CONDITIONAL_VALUE,
      payload: { id, value },
    };
  },

  setDealConditionalsRepairPrice: function(
    id: number,
    repairPrice: number
  ): Action {
    return {
      type: ActionTypes.SET_FEATURES_CONDITIONAL_REPAIR_PRICE,
      payload: { id, repairPrice },
    };
  },

  setDealResalePrice: function(resalePrice: number): Action {
    return {
      type: ActionTypes.SET_PRICE,
      payload: resalePrice,
    };
  },

  setDealPurchasePrice: function(purchasePrice: number): Action {
    return {
      type: ActionTypes.SET_PURCHASE_PRICE,
      payload: purchasePrice,
    };
  },

  setDealResaleMinimumBidIncrement: function(
    minimumBidIncrement: number
  ): Action {
    return {
      type: ActionTypes.SET_MINIMUM_BID_INCREMENT,
      payload: minimumBidIncrement,
    };
  },

  setDealResaleMinPrice: function(resaleMinPrice: number): Action {
    return {
      type: ActionTypes.SET_MINIMUM_PRICE,
      payload: resaleMinPrice,
    };
  },

  setDealResaleBuyNowPrice: function(buyNowPrice: number): Action {
    return {
      type: ActionTypes.SET_BUY_NOW_PRICE,
      payload: buyNowPrice,
    };
  },

  setYoutubeId: function(youtubeId: string): Action {
    return {
      type: ActionTypes.SET_YOUTUBE_ID,
      payload: youtubeId,
    };
  },

  setDealReportId: function(report: any): Action {
    return {
      type: ActionTypes.SET_REPORT_UPLOAD_SUCCESS,
      payload: report,
    };
  },

  setDealReportUpload: function(file: File, callback: Function): Action {
    return {
      type: ActionTypes.SET_REPORT_UPLOAD,
      payload: { file, callback },
    };
  },

  setDealReportUrl: function(reportUrl: string): Action {
    return {
      type: ActionTypes.SET_REPORT_URL,
      payload: reportUrl,
    };
  },

  setDealReportUrlUploading: function(): Action {
    return {
      type: ActionTypes.SET_REPORT_UPLOADING,
    };
  },

  setDealImageUpload: function(
    image: File,
    handleOnProgress: Function
  ): Action {
    return {
      type: ActionTypes.SET_IMAGE_UPLOAD,
      payload: { image, handleOnProgress },
    };
  },
  setDealImageUploading: function(): Action {
    return {
      type: ActionTypes.SET_IMAGE_UPLOADING,
    };
  },

  setDealImageUploadUploaded: function(): Action {
    return {
      type: ActionTypes.SET_IMAGE_UPLOADED,
    };
  },

  setDealImageUploadError: function(error: any): Action {
    return {
      type: ActionTypes.SET_IMAGE_UPLOAD_ERROR,
      payload: error,
    };
  },

  setDealImageUploadFinished: function(imageId: number): Action {
    return {
      type: ActionTypes.SET_IMAGE_UPLOAD_SUCCESS,
      payload: imageId,
    };
  },

  setDealImagePositions: function(
    imageOrigin: Image,
    imageDestination: Image
  ): Action {
    return {
      type: ActionTypes.SET_IMAGE_POSITION,
      payload: { imageOrigin, imageDestination },
    };
  },

  deleteDealImage: function(image: Image): Action {
    return {
      type: ActionTypes.DELETE_IMAGE,
      payload: image,
    };
  },

  setDealSavedSuccess: function(deal: DealRepasse, history: any): Action {
    return {
      type: ActionTypes.SAVE_DEAL_SUCCESS,
      payload: { deal, history },
    };
  },

  setDealSavedError: function(error: any): Action {
    return {
      type: ActionTypes.SAVE_DEAL_ERROR,
      payload: error,
    };
  },

  clearDealReport: function(): Action {
    return {
      type: ActionTypes.CLEAR_DEAL_REPORT,
    };
  },

  getDeal: function(
    vehicleType: string,
    dealerId: number,
    dealId: number
  ): Action {
    return {
      type: ActionTypes.GET_DEAL,
      payload: { vehicleType, dealerId, dealId },
    };
  },

  setDealLoading: function(): Action {
    return {
      type: ActionTypes.DEAL_LOADING,
    };
  },

  setDealLoaded: function(): Action {
    return {
      type: ActionTypes.DEAL_LOADED,
    };
  },

  setDealUpdating: function(): Action {
    return {
      type: ActionTypes.DEAL_UPDATING,
    };
  },

  setDeal: function(deal: DealRepasse): Action {
    return {
      type: ActionTypes.SET_DEAL,
      payload: deal,
    };
  },

  validateBaseDate: function(): Action {
    return {
      type: ActionTypes.VALIDATE_BASE_DEAL,
    };
  },

  setDealErrorLoading: function(error: Error): Action {
    return {
      type: ActionTypes.SET_DEAL_ERROR_LOADING,
      payload: error,
    };
  },

  cleanErrors: function(): Action {
    return {
      type: ActionTypes.CLEAN_DEAL_ERRORS,
    };
  },

  setDealReportStatus: function(reportStatus: number): Action {
    return {
      type: ActionTypes.SET_DEAL_REPORT_STATUS,
      payload: reportStatus,
    };
  },

  setDealJustMake: function(makeId: number, makeName: string): Action {
    return {
      type: ActionTypes.SET_JUST_MAKE,
      payload: { makeId, makeName },
    };
  },

  setDealJustModel: function(modelId: number, modelName: string): Action {
    return {
      type: ActionTypes.SET_JUST_MODEL,
      payload: { modelId, modelName },
    };
  },

  setDealJustProductionYear: function(productionYear: number): Action {
    return {
      type: ActionTypes.SET_JUST_PRODUCTION_YEAR,
      payload: productionYear,
    };
  },

  setDealJustModelYear: function(modelYear: number): Action {
    return {
      type: ActionTypes.SET_JUST_MODEL_YEAR,
      payload: modelYear,
    };
  },

  setDealJustTrim: function(trimId: number, trimName: string): Action {
    return {
      type: ActionTypes.SET_JUST_TRIM,
      payload: { trimId, trimName },
    };
  },

  reportImageUpload: function(
    conditionalId: number,
    file: File,
    callbackOnProgress: Function
  ): Action {
    return {
      type: ActionTypes.UPLOAD_REPORT_IMAGE,
      payload: { conditionalId, file, callbackOnProgress },
    };
  },

  setImageReportUploading: function(
    conditionalId: number,
    uploading: boolean
  ): Action {
    return {
      type: ActionTypes.UPLOADING_REPORT_IMAGE,
      payload: { conditionalId, uploading },
    };
  },

  reportImageUploaded: function(
    conditionalId: number,
    imageId: number
  ): Action {
    return {
      type: ActionTypes.UPLOADED_REPORT_IMAGE,
      payload: { conditionalId, imageId },
    };
  },

  reportImageUploadedError: function(
    conditionalId: number,
    error: Error
  ): Action {
    return {
      type: ActionTypes.ERROR_UPLOAD_REPORT_IMAGE,
      payload: { conditionalId, error },
    };
  },

  removeReportImage: function(conditionalId: number): Action {
    return {
      type: ActionTypes.REMOVE_REPORT_IMAGE,
      payload: { conditionalId },
    };
  },

  setTradeIn: function(isTradeIn: boolean): Action {
    return {
      type: ActionTypes.SET_TRADE_IN,
      payload: { isTradeIn },
    };
  },

  setOwnerName: function(ownerName: string): Action {
    return {
      type: ActionTypes.SET_TRADE_IN_OWNER_NAME,
      payload: { ownerName },
    };
  },

  setOwnerEmail: function(ownerEmail: string): Action {
    return {
      type: ActionTypes.SET_TRADE_IN_OWNER_EMAIL,
      payload: { ownerEmail },
    };
  },

  setOwnerPhone: function(ownerPhone: string): Action {
    return {
      type: ActionTypes.SET_TRADE_IN_OWNER_PHONE,
      payload: { ownerPhone },
    };
  },

  setOwnerCPF: function(ownerCPF: string): Action {
    return {
      type: ActionTypes.SET_TRADE_IN_OWNER_CPF,
      payload: { ownerCPF },
    };
  },

  validateTradeInfo: function(): Action {
    return {
      type: ActionTypes.VALIDATE_TRADE_IN_INFO,
    };
  },

  setReports: function(reports: Conditional[]): Action {
    return {
      type: ActionTypes.SET_REPORTS,
      payload: { reports },
    };
  },

  setDealVehicleType: function(vehicleType: string): Action {
    return {
      type: ActionTypes.SET_VEHICLE_TYPE,
      payload: { vehicleType },
    };
  },

  setDealJustVehicleType: function(vehicleType: string): Action {
    return {
      type: ActionTypes.SET_JUST_VEHICLE_TYPE,
      payload: { vehicleType },
    };
  },

  setDealVin: function(vin: string): Action {
    return {
      type: ActionTypes.SET_VIN,
      payload: { vin },
    };
  },

  setDealRenavam: function(renavam: string): Action {
    return {
      type: ActionTypes.SET_RENAVAM,
      payload: { renavam },
    };
  },

  setDealManufacturingPackageCode: function(
    manufacturingPackageCode: string
  ): Action {
    return {
      type: ActionTypes.SET_MANUFACTURING_PACKAGE_CODE,
      payload: { manufacturingPackageCode },
    };
  },
  setPublishDate: function(date: string): Action {
    return {
      type: ActionTypes.SET_DATE_PUBLISH,
      payload: date,
    };
  },
  setDateCountdown: function(date: string): Action {
    return {
      type: ActionTypes.SET_DATE_COUNTDOWN,
      payload: date,
    };
  },
};
