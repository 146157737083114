const DEFAULT_AUDIO_FORMAT = 'audio/mp3';
const typeAudioObj = { type: DEFAULT_AUDIO_FORMAT };
const audioPositionDefault = { x: 0, y: 0 };
const messageAudioRecordingMobile = '< Deslize para cancelar';
const CannotHaveAccessToMicrophone =
  'Não conseguimos obter acesso ao microfone';
const CannotHaveAccessToCamera = 'Não conseguimos obter acesso a camera';
const WithoutPermissionToCapturedAudio =
  'Sem acesso ao microfone, para gravar aúdio, por favor concedá-a';
const WithoutPermissionToCapturedCamera =
  'Sem acesso a câmera, para tirar foto, por favor concedá-a';

const AccessToCameraIsNoSupportedToYourBrowser =
  'Seu navegador não tem suporte para abrir a câmera';
const AccessToMicrophoneIsNoSupportedToYourBrowser =
  'Seu navegador não tem suporte para captura de aúdio';
export {
  DEFAULT_AUDIO_FORMAT,
  typeAudioObj,
  audioPositionDefault,
  messageAudioRecordingMobile,
  CannotHaveAccessToMicrophone,
  CannotHaveAccessToCamera,
  WithoutPermissionToCapturedAudio,
  WithoutPermissionToCapturedCamera,
  AccessToMicrophoneIsNoSupportedToYourBrowser,
  AccessToCameraIsNoSupportedToYourBrowser,
};
