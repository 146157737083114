import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FilterOption } from 'pages/proposals/tabs/PopoverFilters/interfaces';
import useCustomMutation from 'pages/proposals/tabs/PopoverFilters/hooks/useCustomMutation';
import dealerGroupServices from 'services/dealerGroupServices';
import useSnackBar from 'hooks/useSnackBar';

interface Props {
  apiProperty: string;
  selectedValue?: string;
}

export default function useGroupSalesAssistant({
  apiProperty,
  selectedValue,
}: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<FilterOption | null>(
    null
  );

  const { handleSnackBarError } = useSnackBar();
  const { currentGroup } = useSelector(state => state.callCenter);
  const groupId = currentGroup.id;

  function useGetGroupSalesAssistant() {
    return useCustomMutation({
      key: 'getGroupSalesAssistant',
      handleReturn: {
        onError: () => {
          handleSnackBarError(
            'Ocorreu um erro ao consultar "Assistentes de Vendas".'
          );
        },
      },
      request: async () =>
        dealerGroupServices
          .getSalesAssistant(groupId)
          .then(response => response?.data),
    });
  }

  const {
    data,
    isLoading,
    mutate: getGroupSalesAssistant,
  } = useGetGroupSalesAssistant();

  useEffect(() => {
    if (options.length) {
      const selected = options.find(option => option.value === selectedValue);
      setSelectedOption(selected);
    }
  }, [options, selectedValue]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(salesAssistant => ({
        filterGroupId: apiProperty,
        id: salesAssistant?.userSite.id,
        label: salesAssistant?.userSite.name,
        value: `${salesAssistant?.userSite.id}`,
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOption,
    getGroupSalesAssistant,
    isLoading,
  };
}
