import React from 'react';
import NumberFormat from 'react-number-format';
import Select from 'modules/financing/components/atoms/Select';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutoComplete from 'components/atoms/AutoComplete'
import { CEPMaskCustom } from 'components/MaskTextInput';
import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import getDateByDatePickerComponent from 'modules/financing/helpers/getDateByDatePickerComponent'
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput'



const OccupationForm = (props) => {
  const {
    statesOptions,
    employmentCitiesOptions,
    streetTypeOptions,
    careersOptions,
    employmentsOptions,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  } = props
  const isAutonomous = formData['guarantorEmploymentId'] === 4
  return (
    <div>
      {/* GRID_CONTAINER */}
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <Select
            id="guarantorEmploymentId"
            name="guarantorEmploymentId"
            label="Tipo de Ocupação"
            value={formData['guarantorEmploymentId']}
            onChange={onChange}
            options={employmentsOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={6}>
          <AutoComplete
            name="guarantorCareerId"
            label="Profissão*"
            options={careersOptions}
            value={formData?.['guarantorCareerId'] ? {
              id: formData['guarantorCareerId'],
              value: formData['guarantorCareerId'],
              label: careersOptions.find(e => e.value === formData['guarantorCareerId'])?.label,
              name: careersOptions.find(e => e.value === formData['guarantorCareerId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e.value, name: 'guarantorCareerId' } })}
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="guarantorCompanyName"
            name="guarantorCompanyName"
            label="Nome da empresa"
            value={formData['guarantorCompanyName']}
            onChange={onChange}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="guarantorJobTitle"
            name="guarantorJobTitle"
            label="Cargo na empresa"
            value={formData['guarantorJobTitle']}
            onChange={onChange}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="guarantorEmploymentSocialName"
            name="guarantorEmploymentSocialName"
            label="Nome do empregador"
            value={formData['guarantorEmploymentSocialName']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorEmploymentSocialName')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="guarantorEmploymentStartDate"
            name="guarantorEmploymentStartDate"
            type="date"
            label="Data que iniciou na empresa"
            value={getDateByDatePickerComponent(formData['guarantorEmploymentStartDate'])}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorEmploymentStartDate')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberFormat
            value={formData['guarantorMonthlyIncome']}
            onValueChange={({ floatValue }): void => { onChange({ target: { name: 'guarantorMonthlyIncome', value: floatValue } }) }}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            allowLeadingZeros={false}
            placeholder="0"
            prefix="R$ "
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextInput}
            label={'Renda'}
            disabled={isAutonomous || disableAllInputs}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={3}>
          <TextInput
            id="guarantorEmploymentZipcode"
            name="guarantorEmploymentZipcode"
            label="CEP da ocupação"
            value={formData['guarantorEmploymentZipcode']}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              inputComponent: CEPMaskCustom,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton onClick={getAddresInfosByZipCode} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            id="guarantorEmploymentStreetType"
            name="guarantorEmploymentStreetType"
            label="Tipo do logradouro"
            value={formData['guarantorEmploymentStreetType']}
            onChange={onChange}
            options={streetTypeOptions}
            disabled={isAutonomous || disableAllInputs}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            id="guarantorEmploymentAddress"
            name="guarantorEmploymentAddress"
            label="Endereço"
            value={formData['guarantorEmploymentAddress']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorEmploymentAddress')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="guarantorEmploymentNumber"
            name="guarantorEmploymentNumber"
            label="Número"
            value={formData['guarantorEmploymentNumber']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorEmploymentNumber')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={12}>
          <TextInput
            id="guarantorEmploymentComplement"
            name="guarantorEmploymentComplement"
            label="Complemento"
            value={formData['guarantorEmploymentComplement']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorEmploymentComplement')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="guarantorEmploymentNeighborhood"
            name="guarantorEmploymentNeighborhood"
            label="Bairro"
            value={formData['guarantorEmploymentNeighborhood']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorEmploymentNeighborhood')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="guarantorEmploymentCityStateId"
            label="Estado*"
            options={statesOptions}
            value={formData?.['guarantorEmploymentCityStateId'] ? {
              id: formData['guarantorEmploymentCityStateId'],
              value: formData['guarantorEmploymentCityStateId'],
              label: statesOptions.find(e => e.value === formData['guarantorEmploymentCityStateId'])?.label,
              name: statesOptions.find(e => e.value === formData['guarantorEmploymentCityStateId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e.value, name: 'guarantorEmploymentCityStateId' } })}
            disabled={isAutonomous || disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="guarantorEmploymentCityId"
            label="Cidade*"
            options={employmentCitiesOptions}
            value={formData?.['guarantorEmploymentCityId'] ? {
              id: formData['guarantorEmploymentCityId'],
              value: formData['guarantorEmploymentCityId'],
              label: employmentCitiesOptions.find(e => e.value === formData['guarantorEmploymentCityId'])?.label,
              name: employmentCitiesOptions.find(e => e.value === formData['guarantorEmploymentCityId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e.value, name: 'guarantorEmploymentCityId' } })}
            disabled={isAutonomous || disableAllInputs}
            minHeight="58px"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OccupationForm;
