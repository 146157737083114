import React, { Suspense } from 'react';
import store from 'store';
import moment from 'moment';
import { Provider } from 'mobx-react';
import { Provider as ProviderRedux } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { RecoilRoot } from 'recoil';
import routes from 'config/routes';
import storeRedux from 'modules/resale/store';
import MobileBlockPage from 'pages/MobileBlockPage';
import ErrorBoundary from 'components/ErrorBoundary';
import { Desktop, Mobile } from 'components/Responsive';
import DynamicImports from './DynamicImports';
import 'components/BaseCSS';
import 'react-image-lightbox/style.css';
import 'helpers/requestModules/load';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/pt-br';
import RenderLoadingAuthentication from 'components/RenderLoadingAuthentication';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

const locale = 'pt-br';
moment.locale(locale);

function App() {
  const queryClient = new QueryClient();
  const location = window.location.pathname;

  return (
    <>
      <StylesProvider generateClassName={generateClassName}>
        <Desktop>
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <Provider {...store}>
                <ProviderRedux store={storeRedux}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={locale}
                  >
                    <BrowserRouter>
                      <ErrorBoundary>
                        <Suspense fallback={<RenderLoadingAuthentication />}>
                          <Switch>
                            <DynamicImports routes={routes} />
                          </Switch>
                        </Suspense>
                      </ErrorBoundary>
                    </BrowserRouter>
                  </MuiPickersUtilsProvider>
                </ProviderRedux>
              </Provider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </RecoilRoot>
        </Desktop>
        <Mobile>
          {String(location) === '/' ? (
            <RecoilRoot>
              <QueryClientProvider client={queryClient}>
                <Provider {...store}>
                  <ProviderRedux store={storeRedux}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale={locale}
                    >
                      <BrowserRouter>
                        <ErrorBoundary>
                          <Suspense fallback={<RenderLoadingAuthentication />}>
                            <Switch>
                              <DynamicImports routes={routes} />
                            </Switch>
                          </Suspense>
                        </ErrorBoundary>
                      </BrowserRouter>
                    </MuiPickersUtilsProvider>
                  </ProviderRedux>
                </Provider>
              </QueryClientProvider>
            </RecoilRoot>
          ) : (
            <MobileBlockPage />
          )}
        </Mobile>
      </StylesProvider>
    </>
  );
}

export default App;
