import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'StatsServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class PerformanceServices {
  static getReport = (dealerId, options = {}) => {
    const params = this.getOptions(options);

    return request
      .get(`${domain}/performance/v1.0/dealer/${dealerId}/perday`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getReport()`));
  };
  static getReportByCar = (dealerId, page = 0, size = 20, options = {}) => {
    const params = this.getOptions({ page, size, ...options });
    return request
      .get(
        `${domain}/performance/v1.0/dealer/${dealerId}/stockperformance/pagination`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getReport()`));
  };
  static getOptions = ({ isZero, isUsados, ...props }) => {
    if (isZero || isUsados) {
      return {
        deal0km: isZero,
        usado: isUsados,
        ...props,
      };
    }
    return { ...props };
  };
}
