import { connect } from 'react-redux';
import OrderStepsContainer from './OrderStepsContainer';

function mapStateToProps(state) {
  return {
    products: state.dealerStore.currentDealer.products,
    currentStep: state.order.currentStep,
    loadingOrder: state.order.loadingOrder,
    hasProductProposalApplication: state.dealerStore?.currentDealer?.products
      ? state.dealerStore?.currentDealer?.products.some(
          product =>
            product.type === 'PROPOSAL_APPLICATION' ||
            product.type === 'MOBIONE'
        )
      : false,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderStepsContainer);
