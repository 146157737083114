import React, { useEffect, useState, useCallback } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import { isValid as isValidCpf, strip } from '@fnando/cpf';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import TextInput from 'bit/mobiauto.web-ui-components.components.text-input/TextInput';
import isEmpty from 'helpers/isEmpty';
import validateEmail from 'helpers/validateEmail';
import MODE from 'constants/editInLineTypes';
import ProposalServices from 'services/proposalServices';
import { CPFCNPJMaskCustom, PhoneMaskCustom } from 'components/MaskTextInput';
import { useChatContext } from 'pages/chats/ChatsContext';
import {
  Container,
  Item,
  LabelInfo,
  NameProfile,
  ContactSource,
  BlueTag,
} from './styles';

const EditingMode = ({ handleOnChangeMode }) => {
  const { currentProposal, getCurrentProposal } = useChatContext();

  const [state, setState] = useState({
    name: currentProposal?.clientUserName || null,
    email: currentProposal?.clientEmail || null,
    phone: currentProposal?.clientUserPhone || '',
    cpfCnpj: currentProposal?.clientUserCpfCnpj || null,
    phoneError: null,
    emailError: null,
    cpfCnpjError: null,
  });

  const saveClientProposalInfo = async ({
    email = null,
    name = null,
    phone = null,
    cpfCnpj = null,
  }) => {
    const result = await ProposalServices.saveClientProposalInfo(
      currentProposal.dealerId,
      currentProposal.id,
      email,
      name,
      phone,
      cpfCnpj
    );
    const { success } = result;
    if (success) {
      getCurrentProposal();
      return;
    }
    throw new Error('Error to save client profile');
  };

  const handleOnClickOnSave = async () => {
    if (state.emailError || state.phoneError || state.cpfCnpjError) {
      return;
    }

    if (isEmpty(state.email?.trim()) && isEmpty(state.phone?.trim())) {
      return;
    }

    try {
      const userProfile = {
        email:
          !state.email || state.email.trim() === '' ? null : state.email.trim(),
        name: !state.name || state.name.trim() === '' ? null : state.name,
        phone:
          !state.phone || state.phone.trim() === ''
            ? null
            : state.phone.replace(/\D/g, ''),
        cpfCnpj:
          !state.cpfCnpj || state.cpfCnpj?.trim() === ''
            ? null
            : state.cpfCnpj.trim(),
      };

      await saveClientProposalInfo(userProfile);
      handleOnChangeMode(MODE.SAVED);
    } catch (e) {
      handleOnChangeMode(MODE.ERROR);
      console.error(e);
    }
  };

  const isPhoneInvalid = () => {
    const phone = state.phone.replace(/\D/g, '');
    const maxNumberLength = 10;
    //size of the phone in pt br
    return (
      phone.length !== 0 &&
      (phone.length > maxNumberLength || phone.length < maxNumberLength - 1)
    );
  };

  const verifyPhone = () => {
    if (isPhoneInvalid()) {
      setState(prevState => ({ ...prevState, phoneError: true }));
    } else {
      setState(prevState => ({ ...prevState, phoneError: false }));
    }
  };

  const verifyCpfCnpj = cpfCnpj => {
    if (cpfCnpj.length == 11)
      setState(prevState => ({
        ...prevState,
        cpfCnpjError: !isValidCpf(cpfCnpj),
      }));
    else if (cpfCnpj.length == 14)
      setState(prevState => ({
        ...prevState,
        cpfCnpjError: !isValidCnpj(cpfCnpj),
      }));
    else
      setState(prevState => ({
        ...prevState,
        cpfCnpjError: cpfCnpj.length > 0,
      }));

    return state.cpfCnpjError;
  };

  const handleOnChangePhone = event => {
    setState(prevState => ({ ...prevState, phone: event.target.value }));
    verifyPhone();
  };

  const handleOnChangeEmail = event => {
    setState(prevState => ({ ...prevState, email: event.target.value }));
  };

  const handleOnChangeCpfCnpj = event => {
    setState(prevState => ({ ...prevState, cpfCnpj: event.target.value }));

    const cpfCnpj = strip(event.target.value);
    verifyCpfCnpj(cpfCnpj);
  };

  const validateEmailFunc = useCallback(() => {
    if (isEmpty(state.email?.trim())) {
      setState(prevState => ({ ...prevState, emailError: false }));
      return;
    }

    if (validateEmail(state.email)) {
      setState(prevState => ({ ...prevState, emailError: false }));
    } else {
      setState(prevState => ({ ...prevState, emailError: true }));
    }
  }, [state.email]);

  useEffect(() => {
    if (state.email) {
      validateEmailFunc();
    }
  }, [state.email, validateEmailFunc]);

  return (
    <Container>
      <ListItem>
        <Item>
          <LabelInfo>Informações</LabelInfo>
          <NameProfile>
            <TextInput
              name="Nome"
              label="Nome"
              value={state.name}
              onChange={event =>
                setState(prevState => ({
                  ...prevState,
                  name: event.target.value,
                }))
              }
              style={{ padding: 0, margin: 0 }}
            />
          </NameProfile>
          <ContactSource>
            <BlueTag>
              <TextInput
                name="Telefone"
                label="Telefone"
                value={state.phone}
                error={state.phoneError}
                onChange={handleOnChangePhone}
                inputProps={{
                  inputComponent: PhoneMaskCustom,
                }}
              />
            </BlueTag>
          </ContactSource>

          <ContactSource>
            <BlueTag>
              <TextInput
                name="Email"
                label="Email"
                type="email"
                value={state.email}
                error={state.emailError}
                onChange={handleOnChangeEmail}
              />
            </BlueTag>
          </ContactSource>

          <ContactSource>
            <BlueTag>
              <TextInput
                name="CPF/CNPJ"
                label="CPF/CNPJ"
                value={state.cpfCnpj}
                inputProps={{
                  inputComponent: CPFCNPJMaskCustom,
                  value: state.cpfCnpj,
                  onChange: event => handleOnChangeCpfCnpj(event),
                }}
                error={state.cpfCnpjError}
                placeholder=""
              />
            </BlueTag>
          </ContactSource>
        </Item>
      </ListItem>
      <ListItem>
        <Item>
          <Button
            variant="outlined"
            onClick={() => handleOnChangeMode(MODE.VISUALIZING)}
            style={{ marginRight: '16px' }}
          >
            Cancelar
          </Button>
        </Item>
        <Item>
          <Button onClick={handleOnClickOnSave}>Salvar</Button>
        </Item>
      </ListItem>
    </Container>
  );
};

export default EditingMode;
