import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 400px;
  padding: 16px 24px 16px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  align-items: center;
  gap: 16px;
  background: #ffffff;
  flex-direction: row;
`;

export const TextDate = styled.div`
  display: flex;
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const ButtonCancel = styled.div`
  :hover {
    cursor: pointer;
  }
`;
