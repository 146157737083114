import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import {
  ProposalInfosType,
  ActivityType,
  ConvertionChartType,
  ScheduleItemType,
  allowedTypes,
  ProvidersType,
  SellersType,
} from 'types/LeadsTypes';

export default {
  //PROPOSALS INFOS
  getSellerProposalsInfos: function(
    dealerID: number,
    sellerID: number
  ): Action {
    return {
      type: ActionTypes.GET_SELLER_PROPOSALS_INFOS,
      payload: { dealerID, sellerID },
    };
  },
  getManagerProposalsInfos: function(dealerId: number): Action {
    return {
      type: ActionTypes.GET_MANAGER_PROPOSALS_INFOS,
      payload: { dealerId },
    };
  },
  setProposalsInfosSuccess: function(
    proposalsInfos: ProposalInfosType
  ): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_INFOS_SUCCEDED,
      payload: proposalsInfos,
    };
  },
  setProposalsInfosError: function(error: boolean): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_INFOS_ERROR,
      payload: error,
    };
  },
  setProposalsInfosLoading: function(isLoading: boolean): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_INFOS_LOADING,
      payload: isLoading,
    };
  },
  //CHANGE_FILTER_DATE
  setFilterDate: function(newDates: {
    initialDate: number;
    finalDate: number;
    filterSelectedId: number | string;
  }): Action {
    return {
      type: ActionTypes.SET_FILTER_DATE,
      payload: newDates,
    };
  },

  //CHANGE_FILTER_DATE_QUALIFIED_TAB
  setFilterDateQualified: function(newDates: {
    initialDate: number;
    finalDate: number;
    filterSelectedId: number | string;
  }): Action {
    return {
      type: ActionTypes.SET_FILTER_DATE_QUALIFIED,
      payload: newDates,
    };
  },
  //CHANGE_FILTER_BY_SUB_GROUP
  setFilterBySubGroups: groupId => {
    return {
      type: ActionTypes.SET_FILTER_BY_SUB_GROUPS,
      payload: groupId,
    };
  },
  //CHANGE_FILTER_BY_DEALER_ID
  setFilterByDealerId: dealerId => {
    return {
      type: ActionTypes.SET_FILTER_BY_DEALER_ID,
      payload: dealerId,
    };
  },
  //CHANGE_FILTER_SELLER_ASSISTANT
  setFilterSalesAssistant: (salesAssistant: { salesAssistantId: number }) => {
    return {
      type: ActionTypes.SET_SALES_ASSISTANT_FILTER,
      payload: salesAssistant,
    };
  },

  //CHANGE_TYPE_OF_SALE_FILTER
  setFilterTypeOfSale: typeOfSale => {
    return {
      type: ActionTypes.SET_TYPE_OF_SALE_FILTER,
      payload: typeOfSale,
    };
  },
  //CONVERTION
  getSellerConvertion: function(
    dealerId: number,
    sellerId: number,
    start?: any,
    end?: any
  ): Action {
    return {
      type: ActionTypes.GET_SELLER_CONVERTION,
      payload: { dealerId, sellerId, start, end },
    };
  },
  getManagerConvertion: function(dealerId: number): Action {
    return {
      type: ActionTypes.GET_MANAGER_CONVERTION,
      payload: { dealerId },
    };
  },
  setConvertionSuccess: function(convertion: ConvertionChartType[]): Action {
    return {
      type: ActionTypes.SET_CONVERTION_SUCCEDED,
      payload: convertion,
    };
  },
  setConvertionError: function(error: any): Action {
    return {
      type: ActionTypes.SET_CONVERTION_ERROR,
      payload: error,
    };
  },
  setConvertionLoading: function(isLoading: boolean): Action {
    return {
      type: ActionTypes.SET_CONVERTION_LOADING,
      payload: isLoading,
    };
  },

  //SELLERS PROPOSALS
  getSellersProposals: function(dealerId: number): Action {
    return {
      type: ActionTypes.GET_SELLERS_PROPOSALS,
      payload: { dealerId },
    };
  },
  setSellersProposalsSuccess: function(sellers: SellersType): Action {
    return {
      type: ActionTypes.SET_SELLERS_PROPOSALS_SUCCEDED,
      payload: sellers,
    };
  },
  setSellersProposalsError: function(error: any): Action {
    return {
      type: ActionTypes.SET_SELLERS_PROPOSALS_ERROR,
      payload: error,
    };
  },
  setSellersProposalsLoading: function(isLoading: any): Action {
    return {
      type: ActionTypes.SET_SELLERS_PROPOSALS_LOADING,
      payload: isLoading,
    };
  },

  //PROVIDERS PROPOSALS
  getProvidersProposals: function(dealerId: number): Action {
    return {
      type: ActionTypes.GET_PROVIDERS_PROPOSALS,
      payload: { dealerId },
    };
  },
  setProvidersProposalsSuccess: function(providers: ProvidersType): Action {
    return {
      type: ActionTypes.SET_PROVIDERS_PROPOSALS_SUCCEDED,
      payload: providers,
    };
  },
  setProvidersProposalsError: function(error: any): Action {
    return {
      type: ActionTypes.SET_PROVIDERS_PROPOSALS_ERROR,
      payload: error,
    };
  },
  setProvidersProposalsLoading: function(isLoading: boolean): Action {
    return {
      type: ActionTypes.SET_PROVIDERS_PROPOSALS_LOADING,
      payload: isLoading,
    };
  },

  //SELLER SCHEDULE
  getSellerSchedule: function(
    dealerId: number,
    sellerId: number,
    start: any,
    end: any
  ): Action {
    return {
      type: ActionTypes.GET_SELLER_SCHEDULE,
      payload: { dealerId, sellerId, start, end },
    };
  },
  setSellerScheduleSuccess: function(shedule: number[]): Action {
    return {
      type: ActionTypes.SET_SELLER_SCHEDULE_SUCCEDED,
      payload: shedule,
    };
  },
  setSellerScheduleError: function(error: any): Action {
    return {
      type: ActionTypes.SET_SELLER_SCHEDULE_ERROR,
      payload: error,
    };
  },

  setSellerScheduleLoading: function(isLoading: any): Action {
    return {
      type: ActionTypes.SET_SELLER_SCHEDULE_LOADING,
      payload: isLoading,
    };
  },

  //SELLER SCHEDULE INFOS
  getSellerScheduleInfos: function(
    dealerId: number,
    sellerId: number,
    start: any,
    end: any
  ): Action {
    return {
      type: ActionTypes.GET_SELLER_SCHEDULE_INFOS,
      payload: { dealerId, sellerId, start, end },
    };
  },
  getSellerScheduleInfosSuccess: function(shedule: ScheduleItemType[]): Action {
    return {
      type: ActionTypes.GET_SELLER_SCHEDULE_INFOS_SUCCEDED,
      payload: shedule,
    };
  },
  getSellerScheduleInfosError: function(error: any): Action {
    return {
      type: ActionTypes.GET_SELLER_SCHEDULE_INFOS_ERROR,
      payload: error,
    };
  },

  setSellerScheduleInfosLoading: function(isLoading: boolean): Action {
    return {
      type: ActionTypes.SET_SELLER_SCHEDULE_INFOS_LOADING,
      payload: isLoading,
    };
  },

  //INITIAL ANNOTATIONS
  setInitialAnnotationsIndex: function(
    initialAnnotationsIndex: number
  ): Action {
    return {
      type: ActionTypes.SET_INITIAL_ANNOTATIONS_INDEX,
      payload: { initialAnnotationsIndex },
    };
  },
  setInitialAnnotationsIndexSuccess: function(
    initialAnnotationsIndex: number
  ): Action {
    return {
      type: ActionTypes.SET_INITIAL_ANNOTATIONS_INDEX_SUCCEDED,
      payload: initialAnnotationsIndex,
    };
  },
  setInitialAnnotationsIndexError: function(error: any): Action {
    return {
      type: ActionTypes.SET_INITIAL_ANNOTATIONS_INDEX_ERROR,
      payload: error,
    };
  },

  //SELLER ACTIVITIES
  getSellerActivities: function(
    dealerID: number,
    sellerID: number,
    start: any,
    end: any
  ): Action {
    return {
      type: ActionTypes.GET_SELLER_ACTIVITIES,
      payload: { dealerID, sellerID, start, end },
    };
  },
  setSellerActivitiesSuccess: function(activities: ActivityType[]): Action {
    return {
      type: ActionTypes.SET_SELLER_ACTIVITIES_SUCCEDED,
      payload: activities,
    };
  },
  setSellerActivitiesError: function(error: any): Action {
    return {
      type: ActionTypes.SET_SELLER_ACTIVITIES_ERROR,
      payload: error,
    };
  },
  setSellerActivitiesLoading: function(isLoading: boolean): Action {
    return {
      type: ActionTypes.SET_SELLER_ACTIVITIES_LOADING,
      payload: isLoading,
    };
  },

  setUserType: function(userType: allowedTypes) {
    return {
      type: ActionTypes.SET_CURRENT_USER_TYPE,
      payload: userType,
    };
  },

  setDisplayedVision: function(displayedVision: allowedTypes) {
    return {
      type: ActionTypes.SET_DISPLAYED_VISION,
      payload: displayedVision,
    };
  },

  setSellerId: function(sellerId: number) {
    return {
      type: ActionTypes.SET_SELLER_ID,
      payload: sellerId,
    };
  },

  setSellerName: function(sellerName: string) {
    return {
      type: ActionTypes.SET_SELLER_NAME,
      payload: sellerName,
    };
  },

  setSellers: function(
    sellers: Array<{
      label: string;
      value: string | number;
    }>
  ): Action {
    return {
      type: ActionTypes.SET_SELLERS_OPTIONS,
      payload: sellers,
    };
  },

  changeTabView: function(tabNewValue: number): Action {
    return {
      type: ActionTypes.CHANGE_TAB_VALUE,
      payload: tabNewValue,
    };
  },

  setChannel: function(currentChannel: string): Action {
    return {
      type: ActionTypes.SET_CHANNEL,
      payload: currentChannel,
    };
  },

  getChannelOptions: function(
    isCallCenterOrGroupView?: boolean,
    groupId?: string
  ): Action {
    return {
      type: ActionTypes.GET_CHANNEL_OPTIONS,
      payload: { isCallCenterOrGroupView, groupId },
    };
  },

  setChannelOptions: function(
    options: Array<{
      label: string;
      value: string | number;
    }>
  ): Action {
    return {
      type: ActionTypes.SET_CHANNEL_OPTIONS,
      payload: options,
    };
  },

  getDepartmentOptions: function(): Action {
    return {
      type: ActionTypes.GET_DEPARTMENT_OPTIONS,
    };
  },

  setDepartmentOptions: function(
    departmentOptions: Array<{
      label: string;
      value: string | number;
    }>
  ): Action {
    return {
      type: ActionTypes.SET_DEPARTMENT_OPTIONS,
      payload: departmentOptions,
    };
  },

  setDepartment: function(
    currentDepartment: {
      label: string;
      value: string;
      isDisabled?: boolean;
    }[]
  ): Action {
    return {
      type: ActionTypes.SET_DEPARTMENT,
      payload: currentDepartment,
    };
  },

  getOriginOptions: function(
    isCallCenterOrGroupView?: boolean,
    groupId?: string,
    leadCreation?: boolean
  ): Action {
    return {
      type: ActionTypes.GET_ORIGIN_OPTIONS,
      payload: { isCallCenterOrGroupView, groupId, leadCreation },
    };
  },

  setOriginOptions: function(
    options: Array<{
      label: string;
      value: string | number;
    }>
  ): Action {
    return {
      type: ActionTypes.SET_ORIGIN_OPTIONS,
      payload: options,
    };
  },

  setOrigin: function(currentOrigin: string): Action {
    return {
      type: ActionTypes.SET_ORIGIN,
      payload: currentOrigin,
    };
  },
  setTags: function(currentTag: string): Action {
    return {
      type: ActionTypes.SET_TAGS,
      payload: currentTag,
    };
  },

  setDealers: function(dealers: object[]): Action {
    return {
      type: ActionTypes.SET_DEALERS,
      payload: dealers,
    };
  },
};
