import { useDispatch } from 'react-redux';

import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import {
  useCheckDealerBelongsToGroup,
  useCreateEvaluation,
  useGetEvaluations,
  useGetReferencePrices,
  useStartPreviousCreatedEvaluation,
} from '../services/queries';

interface Props {
  dealerId: number;
  onSuccessCreate?: () => void;
  onSuccessUpdateStatus?: () => void;
  proposalId: number;
}

const useEvaluation = ({
  dealerId,
  proposalId,
  onSuccessCreate,
  onSuccessUpdateStatus,
}: Props) => {
  const dispatch = useDispatch();

  const {
    data: dataEvaluations,
    mutate: getEvaluations,
    isLoading: loadingGetEvaluations,
  } = useGetEvaluations({});

  const refreshEvaluations = () => {
    return setTimeout(() => {
      getEvaluations({
        dealerId,
        proposalId,
      });
    }, 500);
  };

  const {
    mutate: createEvaluation,
    data: createEvaluationData,
    isLoading: loadingCreate,
  } = useCreateEvaluation({
    onSuccess: response => {
      refreshEvaluations();

      if (onSuccessCreate) onSuccessCreate();

      dispatch(
        UIActionCreators.snackbarManagement(
          'success',
          'Avaliação criada com sucesso!'
        )
      );

      return response;
    },
  });

  const {
    mutate: startPreviousCreatedEvaluation,
    isLoading: loadingStartPreviousCreatedEvaluation,
  } = useStartPreviousCreatedEvaluation({
    onSuccess: () => {
      if (onSuccessUpdateStatus) onSuccessUpdateStatus();

      return dispatch(
        UIActionCreators.snackbarManagement(
          'success',
          'Avaliação solicitada com sucesso!'
        )
      );
    },
    onError: () => {
      return dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Falha ao solicitar avaliação!'
        )
      );
    },
  });

  const {
    data: dataReferencePrices,
    mutate: getReferencePrices,
    isLoading: loadingGetReferencePrices,
  } = useGetReferencePrices({});

  const {
    data: dealerBelongsToGroup,
    mutate: checkDealerBelongsToGroup,
    isLoading: loadingCheckDealerBelongsToGroup,
  } = useCheckDealerBelongsToGroup({});

  const isLoading =
    loadingCheckDealerBelongsToGroup ||
    loadingCreate ||
    loadingGetEvaluations ||
    loadingGetReferencePrices ||
    loadingStartPreviousCreatedEvaluation;

  return {
    checkDealerBelongsToGroup,
    createEvaluation,
    createEvaluationData,
    dataEvaluations,
    dataReferencePrices,
    dealerBelongsToGroup,
    getEvaluations,
    getReferencePrices,
    isLoading,
    loadingGetReferencePrices,
    refreshEvaluations,
    startPreviousCreatedEvaluation,
  };
};

export default useEvaluation;
