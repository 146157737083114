import envVars from 'helpers/envVars';

const firebaseConfig = new URLSearchParams({
  apiKey: envVars('FIREBASE_API_KEY'),
  authDomain: envVars('FIREBASE_AUTH_DOMAIN'),
  databaseURL: envVars('FIREBASE_DATABASE_URL'),
  projectId: envVars('FIREBASE_PROJECT_ID'),
  storageBucket: envVars('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: envVars('FIREBASE_MESSAGING_SENDER_ID'),
  appId: envVars('FIREBASE_APP_ID'),
  measurementId: envVars('FIREBASE_MEASUREMENT_ID'),
}).toString();

export const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js?${firebaseConfig}`;
