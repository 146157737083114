/* eslint-disable no-return-assign */
import React, { memo } from 'react';
import { getMediaUrl } from 'components/getMedia';

const MessageVideo = ({ message, isPF = false }) => {
  const mediaLink = getMediaUrl({
    mediaId: message.media.id,
    mediaVcode: message.media?.vcode,
  });

  return (
    <a
      href={mediaLink}
      download
      id="download"
      target="_blank"
      rel="noreferrer"
      style={{ color: isPF ? '#00b2a9' : '-webkit-link' }}
    >
      Baixar arquivo
    </a>
  );
};

export default memo(MessageVideo);
