import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ItemHeader from '../atoms/ItemHeader';
import HistoryCard from './components/HistoryCard';
import { Container, LoadingContainer } from './styles';

const ServiceHistoryComponent = ({
  serviceData,
  handleSearchActionClick,
  isLoading,
}) => {
  return (
    <>
      <ItemHeader>Histórico de negociação</ItemHeader>
      <Container>
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : serviceData.length ? (
          serviceData.map((historyData, index) => (
            <HistoryCard
              key={index}
              historyData={historyData}
              handleSearchActionClick={handleSearchActionClick}
            />
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              padding: '16px',
              fontSize: '14px',
            }}
          >
            Não há histórico de atendimentos para esse contato.
          </div>
        )}
      </Container>
    </>
  );
};

export default ServiceHistoryComponent;
