import styled from 'styled-components';

export const VehicleDataContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VehicleDataForm = styled.div`
  display: flex;
  max-width: 800px;
  gap: 16px;
`;

export const SubTitle = styled.span`
  line-height: 3em;
  font-size: 14px;
`;

export const WrapperVehicleInfo = styled.div`
  background: #eaeaea;
  padding: 8px;
  border-radius: 4px;
`;

export const VehicleInfoTitle = styled.div`
  line-height: 16px;
  font-family: Poppins Medium;
  color: #3e3e3e;
`;

export const VehicleInfoData = styled.div`
  font-size: 14px;
`;
