import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import { ReactComponent as ClientDataFilter } from 'assets/client-data-filter.svg';
import { ReactComponent as ClientDataFilterActive } from 'assets/client-data-filter-active.svg';
import { ReactComponent as ProposalDataFilterActive } from 'assets/proposal-data-filter-active.svg';
import CancelOrderDialog from 'modules/order/pages/OrderSteps/Checkout/components/SubHeader/components/CancelOrderDialog';
import { Row, Container } from './styles';
import CancelOrderModal from 'components/CancelOrderModal';

const SubHeader = ({ currentStep, onChangeStep, clickable = false }) => {
  const [openCancelOrderDialog, setOpenCancelOrderDialog] = useState<any>(
    false
  );

  const { checkoutData, cancelInvoice } = useSelector(state => state.order);
  const { currentDealer } = useSelector(state => state.dealerStore);

  const hasMobioneProduct = currentDealer.products.find(
    product => product.type === 'MOBIONE'
  );

  const isCanceled = checkoutData?.status?.proposal === 'CANCELED';
  return (
    <Container>
      <Row>
        <ProposalDataFilterActive
          onClick={() => clickable && onChangeStep(0)}
          style={{ cursor: clickable ? 'pointer' : 'normal' }}
        />
        {currentStep === 1 ? (
          <ClientDataFilterActive
            onClick={() => clickable && onChangeStep(1)}
            style={{ cursor: clickable ? 'pointer' : 'normal' }}
          />
        ) : (
          <ClientDataFilter
            onClick={() => clickable && onChangeStep(1)}
            style={{ cursor: clickable ? 'pointer' : 'normal' }}
          />
        )}
      </Row>
      {!isCanceled && (
        <HypertextButton onClick={() => setOpenCancelOrderDialog(true)} red>
          <CancelIcon style={{ fontSize: 18, marginRight: 5 }} />
          Cancelar Pedido
        </HypertextButton>
      )}
      {openCancelOrderDialog &&
        (hasMobioneProduct && cancelInvoice?.data?.notaFiscalDeSaida ? (
          <CancelOrderModal
            open={openCancelOrderDialog}
            onClose={() => setOpenCancelOrderDialog(false)}
          />
        ) : (
          <CancelOrderDialog
            open={openCancelOrderDialog}
            onClose={() => setOpenCancelOrderDialog(false)}
          />
        ))}
    </Container>
  );
};

export default SubHeader;
