import { Deal } from 'modules/manageDeals/types';
import actionTypes from './actionTypes';

interface InitialStateDeals {
  deal: Deal;
  docsLoading: boolean;
}

const INITIAL_STATE: InitialStateDeals = {
  deal: {
    documents: [],
    transactionals: [],
  } as Deal,
  docsLoading: false,
};

const dealReduccer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case actionTypes.ADD_TRANSACTIONAL_DEAL: {
      const { transactionalType } = payload.transactional;
      const tran = state.deal.transactionals.filter(
        tran => tran?.transactionalType !== transactionalType
      );

      return {
        ...state,
        deal: {
          ...state.deal,
          transactionals: [...tran, payload.transactional],
        },
      };
    }
    case actionTypes.UPDATE_TRANSACTIONAL_DEAL: {
      const { transactionalType } = payload.transactional;
      let { transactionals } = state.deal;
      const tran = transactionals.find(
        tran => tran?.transactionalType == transactionalType
      );

      transactionals = tran
        ? transactionals.map(tran =>
            tran?.transactionalType == transactionalType
              ? payload.transactional
              : tran
          )
        : [...transactionals, payload.transactional];

      return {
        ...state,
        deal: {
          ...state.deal,
          transactionals: transactionals,
        },
      };
    }
    case actionTypes.DELETE_TRANSACTIONAL_DEAL: {
      const { id } = payload.transactional;
      const trans = state.deal.transactionals;
      return {
        ...state,
        deal: {
          ...state.deal,
          transactionals: trans.filter(tran => tran.id !== id),
        },
      };
    }
    case actionTypes.LOADING_DOCUMENTS:
      return {
        ...state,
        docsLoading: payload.loading,
      };
    case actionTypes.ADD_DOCUMENT_DEAL:
      return {
        ...state,
        deal: {
          ...state.deal,
          documents: [...state.deal.documents, payload.document],
        },
        docsLoading: false,
      };
    case actionTypes.UPDATE_DOCUMENT_DEAL: {
      const { id, docUpdate } = payload.document;
      const docs = state.deal.documents;
      docs.forEach(doc => (doc.id == id ? { id, ...docUpdate } : doc));
      return {
        ...state,
        deal: {
          ...state.deal,
          documents: docs,
        },
      };
    }
    case actionTypes.DELETE_DOCUMENT_DEAL: {
      const { id } = payload.document;
      const docs = state.deal.documents;
      return {
        ...state,
        deal: {
          ...state.deal,
          documents: docs.filter(doc => doc.id !== id),
        },
        docsLoading: false,
      };
    }
    case actionTypes.SELECT_DEAL:
      return { ...state, deal: payload.deal };
    case actionTypes.UPDATE_DEAL:
      return { ...state, deal: { ...state.deal, status: payload.status } };
    default:
      return state;
  }
};

export default dealReduccer;
