import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import VinForm from './components/VinForm'
import OutlinedButton from 'components/atoms/OutlinedButton'
import OrderServices from 'modules/order/services/OrderServices'
import actionCreators from 'modules/order/store/reducers/actionCreators'
import { FlexEnd } from './styles'

//FUNCTION_TO_GET_PENDENCY_TYPE
function getPendency(pendency, type): JSX.Element | string {
  switch (type) {
    case "VEHICLE_VIN":
      return <VinForm pendency={pendency} />
    default:
      return 'Pendencia não identificada.'
  }
}
// COMPONENT
const Pendencies = () => {
  //REDUX_HOOKS
  const dispatch = useDispatch()
  const { dealerId, proposalId, checkoutData } = useSelector(state => state.order)

  const handleClickSave = async () => {
    try {
      const { success }: any = await OrderServices.fixPendings(dealerId, proposalId, checkoutData.pendencies)
      if (success) {
        dispatch(actionCreators.getOrderStatus());
      }
    } catch {
      console.error('Ocorreu um erro inesperado.')
    }
  }

  if (!checkoutData?.pendencies.length) {
    return <div>nenhuma pendencia.</div>
  }

  return <div>
    {checkoutData?.pendencies?.map(pendency => {
      return getPendency(pendency, pendency?.type)
    })}
    <FlexEnd >
      <OutlinedButton onClick={handleClickSave}>Salvar</OutlinedButton>
    </FlexEnd>
  </div>

};

export default Pendencies;
