import React from 'react';
import { useSelector } from 'react-redux';
import ApprovalUserCard from './components/ApprovalUserCard';
import { highAccessUserTypes } from 'constants/userAccess';
import { FlexWraper, CardContainer, Label } from './styles';
import usePayment from 'modules/order/pages/OrderSteps/Proposal/steps/ProposalData/steps/PaymentData/PaymentTotalValues/usePayment';
import { STOCK_KIND_TRANSLATE_ID } from 'modules/store-inventory/helpers/TranslateAggregations';

const ApprovalStep = ({ currentUser, setOpenEvaluationProposalDialog }) => {
  const { checkoutData, currentVehicle } = useSelector(state => state.order);
  const {
    totalChargedProducts,
    totalInserted,
    hasProductServices,
    totalServicesSubtractionTotalTroco,
    percentageCommissionVDVI,
  } = usePayment();
  return (
    <>
      <FlexWraper>
        {checkoutData?.approvals?.map(user => {
          return (
            <ApprovalUserCard
              key={user.id}
              userData={user}
              checkoutData={checkoutData}
              currentUser={currentUser}
              setOpenEvaluationProposalDialog={setOpenEvaluationProposalDialog}
            />
          );
        })}
      </FlexWraper>
      {highAccessUserTypes.includes(currentUser.dealerPJUserType) &&
        checkoutData?.vehiclePriceSimulation && (
          <CardContainer>
            <FlexWraper>
              <Label>Margem:</Label>
              <span>
                R${' '}
                {checkoutData?.vehiclePriceSimulation?.marginValueResult?.toLocaleString()}
              </span>
              {!!checkoutData?.vehiclespanriceSimulation
                ?.marginPercentageResult && (
                <>
                  <span style={{ margin: '0px 8px' }}>-</span>
                  <span>
                    {checkoutData?.vehiclespanriceSimulation?.marginPercentageResult?.toLocaleString()}
                    %
                  </span>
                </>
              )}
            </FlexWraper>

            <FlexWraper>
              <Label>Desconto:</Label>
              <span>
                R${' '}
                {checkoutData?.vehiclePriceSimulation?.totalDiscount?.toLocaleString()}
              </span>
              {!!checkoutData?.vehiclePriceSimulation?.discountPercentage && (
                <>
                  <span style={{ margin: '0px 8px' }}>-</span>
                  <span>
                    {checkoutData?.vehiclePriceSimulation?.discountPercentage?.toLocaleString()}
                    %
                  </span>
                </>
              )}
            </FlexWraper>
            <FlexWraper>
              <Label>Serviços Cobrados:</Label>
              <span>R$ {totalChargedProducts?.toLocaleString()}</span>
            </FlexWraper>

            <FlexWraper>
              <Label>Total Inserido:</Label>
              <span>R$ {totalInserted?.toLocaleString()}</span>
            </FlexWraper>

            {hasProductServices && (
              <FlexWraper>
                <Label>Troco - Serviços cobrados:</Label>
                <span>
                  R$ {totalServicesSubtractionTotalTroco?.toLocaleString()}
                </span>
              </FlexWraper>
            )}
            {(currentVehicle?.stockKind === STOCK_KIND_TRANSLATE_ID[2]?.id ||
              currentVehicle?.stockKind === STOCK_KIND_TRANSLATE_ID[2]?.value ||
              currentVehicle?.stockKind === 'DIRECT_SALE') && (
              <FlexWraper>
                <Label>Percentual de comissão do vendedor:</Label>
                <span>{percentageCommissionVDVI?.toLocaleString()} %</span>
              </FlexWraper>
            )}
          </CardContainer>
        )}
    </>
  );
};

export default ApprovalStep;
