import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import TextField from 'modules/financing/components/atoms/TextField';
import AutoComplete from 'components/atoms/AutoComplete';
import isEmpty from 'helpers/isEmpty';
import NewCar from 'components/NewCar';
import { TextMaskCustom, CPFCNPJMaskCustom } from 'components/MaskTextInput';
import View from './View';
import { TitleText, EmptyDealerContainer, EmptyDealerText } from './styles';

function NewCardCallCenter({
  originOptions,
  formData,
  errors,
  touched,
  handleBlur,
  handleKeyPressToSearchDeals,
  handleChange,
  handleOnChangeSearchDeal,
  query,
  searchDeals,
  dealersList,
  deals,
  handleClickCheckDeal,
  dealsCheckeds,
  channelOptions,
  departmentOptions,
  isLoadingDeals,
  isLoadingDealers,
}) {
  return (
    <Grid container spacing={1}>
      {/* ROW */}
      <Grid item xs={12}>
        <TextField
          name="name"
          label="Nome Completo"
          value={formData.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched?.name && Boolean(errors?.name)}
          helperText={touched?.name && errors?.name}
          required
          mini
        />
      </Grid>
      {/* ROW */}
      <Grid item xs={4}>
        <TextField
          name="mobile"
          label="Telefone"
          value={formData.mobile}
          InputProps={{
            inputComponent: TextMaskCustom,
            value: formData.mobile,
            onChange: handleChange,
          }}
          onBlur={handleBlur}
          error={touched?.mobile && Boolean(errors?.mobile)}
          helperText={touched?.mobile && errors?.mobile}
          required
          mini
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="email"
          label="E-mail"
          type="email"
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched?.email && Boolean(errors?.email)}
          helperText={touched?.email && errors?.email}
          required
          mini
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="cpfCnpj"
          label="CPF/CNPJ (Opcional)"
          value={formData.cpfCnpj}
          InputProps={{
            inputComponent: CPFCNPJMaskCustom,
            value: formData.cpfCnpj,
            onChange: handleChange,
          }}
          placeholder=""
          mini
        />
      </Grid>
      {/* ROW */}
      <Grid item xs={3}>
        <AutoComplete
          name="currentDealerSelected"
          label="Lojas"
          value={
            formData.currentDealerSelected
              ? {
                  value: formData.currentDealerSelected,
                  label: dealersList.find(
                    el => el.value === formData.currentDealerSelected
                  )?.label,
                }
              : null
          }
          onChange={event =>
            handleChange({
              target: {
                name: 'currentDealerSelected',
                value: event?.value || null,
              },
            })
          }
          onBlur={handleBlur}
          error={
            touched?.currentDealerSelected &&
            Boolean(errors?.currentDealerSelected)
          }
          helperText={
            touched?.currentDealerSelected && errors?.currentDealerSelected
          }
          options={dealersList}
          disabled={isLoadingDealers || !dealersList.length}
        />
      </Grid>
      <Grid item xs={3}>
        <AutoComplete
          name="departmentCode"
          label="Departamento"
          value={
            formData.departmentCode !== null
              ? {
                  value: formData.departmentCode,
                  label: departmentOptions.find(
                    el => el.value === formData.departmentCode
                  )?.label,
                }
              : null
          }
          onChange={event =>
            handleChange({
              target: { name: 'departmentCode', value: event?.value },
            })
          }
          options={departmentOptions}
          disabled={!departmentOptions.length}
        />
      </Grid>
      <Grid item xs={3}>
        <AutoComplete
          name="originSelected"
          label="Origem do lead"
          value={
            formData.originSelected !== null
              ? {
                  value: formData.originSelected,
                  label: originOptions.find(
                    el => el.value === formData.originSelected
                  )?.label,
                }
              : null
          }
          onChange={event =>
            handleChange({
              target: { name: 'originSelected', value: event?.value },
            })
          }
          options={originOptions}
          disabled={!originOptions.length}
        />
      </Grid>
      <Grid item xs={3}>
        <AutoComplete
          name="channelSelected"
          label="Canal"
          value={
            formData.channelSelected !== null
              ? {
                  value: formData.channelSelected,
                  label: channelOptions.find(
                    el => el.value === formData.channelSelected
                  )?.label,
                }
              : null
          }
          onChange={event =>
            handleChange({
              target: { name: 'channelSelected', value: event?.value },
            })
          }
          options={channelOptions}
          disabled={!channelOptions.length}
        />
      </Grid>
      {/* ROW */}
      <Grid item xs={12}>
        <TextField
          name="query"
          label="Buscar anúncios"
          value={query || ''}
          onKeyPress={
            formData.currentDealerSelected && handleKeyPressToSearchDeals
          }
          placeholder=""
          onChange={handleOnChangeSearchDeal}
          disabled={!formData.currentDealerSelected}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Buscar"
                  onClick={formData.currentDealerSelected && searchDeals}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          mini
        />
      </Grid>
      {/* ROW */}
      <Grid item xs={12}>
        {isLoadingDeals ? (
          <Box component="div" alignItems="center" justifyContent="center">
            <Progress />
          </Box>
        ) : !formData.currentDealerSelected ? (
          <EmptyDealerContainer>
            <EmptyDealerText>
              Selecione uma loja para buscar e adicionar veículos na proposta
            </EmptyDealerText>
          </EmptyDealerContainer>
        ) : (
          <Box maxHeight={'320px'} width="100%" overflow={'scroll'}>
            {isEmpty(deals.content) ? (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <TitleText>
                  Não é possível criar proposta, pois não há anúncios no
                  estoque.
                  <br />
                  Cadastre um anúncio para poder adicionar uma proposta.
                </TitleText>
                <NewCar />
              </Grid>
            ) : (
              <View
                onCheckClick={handleClickCheckDeal}
                checkeds={dealsCheckeds}
                deals={deals.content}
              />
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default NewCardCallCenter;
