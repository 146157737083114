import request from 'helpers/request';

import * as DTO from './dto';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import { TypeOfSale } from 'modules/order/types/OrderPayment';
import { ProposalVehicleDto } from 'modules/order/types/ProposalVehicleDto';
import { PaymentData } from 'modules/order/store/reducers';
import { OrderProduct } from 'modules/order/types/OrderProduct';

const serviceName = 'OrderServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class OrderServices {
  static getProposalById = (dealerId: number, proposalId: number) => {
    return request
      .get(`${domain}/dealer/${dealerId}/proposal/order/v1.0/${proposalId}`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalById()`) as any
      );
  };

  static getOrderById = (dealerId, orderId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/by-order/${orderId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getOrderById()`) as any);
  };

  static getInstallmentsTypes = (dealerId: number, saleType: TypeOfSale) => {
    return request
      .get<any>(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/installments-type/${saleType}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getInstallmentsTypes()`) as any
      );
  };

  static getPaymentTypes = (dealerId: number, saleType: TypeOfSale) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/payment-type/${saleType}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getPaymentTypes()`) as any
      );
  };

  //INSTITUTION_OPTIONS
  static getAllFinancialInstitution = async dealerId => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/financing-institutions`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllFinancialInstitution()`
        ) as any
      );
  };

  static savePaymentData = (
    dealerId: number,
    proposalId: number,
    paymentData: PaymentData
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/payment/${proposalId}`,
        paymentData
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.savePaymentData()`) as any
      );
  };

  static saveProducts = (
    dealerId: number,
    proposalId: number,
    products: OrderProduct[]
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/product/${proposalId}`,
        products
      )
      .then(buildHTTPResponseHandler(`${serviceName}.saveProducts()`) as any);
  };

  static getDealerPJFinancialInstitutions = (
    dealerId: number,
    installmentsTypeName: string
  ) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/financing-institutions/${installmentsTypeName}`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getDealerPJFinancialInstitutions()`
        ) as any
      );
  };

  static saveSaleComments = (
    dealerId: number,
    proposalId: number,
    saleComments: string
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/comments/${proposalId}`,
        saleComments
      )
      .then(buildHTTPResponseHandler(`${serviceName}.saveProducts()`) as any);
  };

  static getOrderStatus = async (dealerId: string, proposalId: string) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/status/${proposalId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getOrderStatus()`) as any);
  };

  static sendBuyerForm = async (dealerId, financingId, currentStep, data) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/buyer/${financingId}/step/${currentStep}`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.sendBuyerForm()`) as any);
  };

  static getProductTypes = (dealerId: number) => {
    return request
      .get<any>(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/products-type`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProductTypes()`) as any
      );
  };

  static setProposalVehicle = (
    proposalId: number,
    dealerId: number,
    proposalVehicleDto: ProposalVehicleDto
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/vehicle/${proposalId}`,
        proposalVehicleDto
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.setProposalVehicle()`) as any
      );
  };

  static approveProposal = (
    dealerId,
    proposalId,
    approvalStatus,
    refuseReason = null
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/approve/${proposalId}?approvalStatus=${approvalStatus}`,
        { refuseReason }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.approveProposal()`) as any
      );
  };

  static downloadContract = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/contract/${proposalId}/pdf`,
        {},
        { responseType: 'blob' }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.downloadContract()`) as any
      );
  };

  static downloadDigitalContract = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/contract/proposal/digital/${proposalId}/pdf`,
        {},
        { responseType: 'blob' }
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.downloadDigitalContract()`
        ) as any
      );
  };

  //FINISH
  static finishOrder = (dealerId, proposalId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/finish/${proposalId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.finishOrder()`) as any);
  };

  //CANCEL_ODER
  static cancelOrder = (dealerId, proposalId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/cancel/${proposalId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.cancelOrder()`) as any);
  };

  //ORDER_KANBAN_SERVICES
  static getOrders = (dealerId, sellerId = '', params) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0${
          sellerId ? `?sellerId=${sellerId}` : ''
        }`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getOrders()`) as any);
  };

  //PENDING_CHECKOUT_STEP
  static fixPendings = (dealerId, proposalId, pendings) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/pendencies/${proposalId}`,
        pendings
      )
      .then(buildHTTPResponseHandler(`${serviceName}.fixPendings()`) as any);
  };

  static getDigitalProposal = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/proposal-digital/${proposalId}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getDigitalProposal`) as any
      );
  };

  static sendDigitalProposal = (dealerId, proposalId) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/proposal-digital/${proposalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.sendDigitalProposal`) as any
      );
  };

  static cancelDigitalProposal = (dealerId, proposalId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/proposal-digital/${proposalId}/cancel`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.cancelDigitalProposal`) as any
      );
  };

  static sendCancelInvoice = (dealerId, proposalId) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/order/dms/v1.0/invoice/cancel/${proposalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.sendCancelInvoice`) as any
      );
  };

  static getCancelInvoice = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/dms/v1.0/invoice/${proposalId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getCancelInvoice`) as any);
  };

  static getMeInfo = () => {
    return request
      .get(`${domain}/user/v1.0/me`)
      .then(buildHTTPResponseHandler(`${serviceName}.getMeInfo()`) as any);
  };
  //
  static reopenOrder = (dealerId, proposalId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/reopen/${proposalId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getMeInfo()`) as any);
  };

  static getValuationsOnDmsApiByVehiclePlate = (dealerId, plate) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/evaluations/${plate}`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getValuationsOnDmsApiByVehiclePlate()`
        ) as any
      );
  };

  static removeVehicleOrder = (dealerId, proposalId, dealId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/vehicle/${proposalId}/${dealId}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.removeVehicleOrder()`) as any
      );
  };

  //INSURANCE
  static sendInsuranceQuoteToSpecialist = (dealerId, proposalId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/insurance/${proposalId}/send-quote-to-specialist`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.sendInsuranceQuoteToSpecialist()`
        ) as any
      );
  };

  static sendInsuranceQuoteToCustomer = (dealerId, proposalId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/insurance/${proposalId}/send-quote-to-customer`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.sendInsuranceQuoteToCustomer()`
        ) as any
      );
  };
  //FINANCIAL_ADVANCED
  static getFinancialAdvanced = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/financial-advance/${proposalId}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getFinancialAdvanced()`) as any
      );
  };

  static createFinancialAdvanced = (dealerId, proposalId) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/financial-advance/${proposalId}/create`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.createFinancialAdvanced()`
        ) as any
      );
  };

  static getColorsByDms = ({
    colorChoice,
    dealerId,
  }: DTO.GetColorByDmsProps) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/stock/colors/${colorChoice}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getColorsByDms()`) as any);
  };

  static getFinancedFandiData = ({
    dealerId,
    proposalId,
  }: {
    dealerId: number;
    proposalId: number;
  }) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/financial-fandi/${proposalId}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getFinancedFandiData()`) as any
      );
  };
}
