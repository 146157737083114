export const customIconStyles = props => {
  return {
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 32,
      height: 32,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundColor: '#009A8A',
    },
    completed: {
      backgroundColor: '#009A8A',
    },
  };
};
