import {
  createPostingDTO,
  putPostingPayloadDTO,
} from 'modules/financial/types';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandlerTS';
import { requestModule as request } from './index';
import { PostingFilters } from 'modules/financial/store/postings/reducers';

const serviceName = 'PostingServices';

class PostingServices {
  static list = async (
    startDate: number,
    endDate: number,
    query: PostingFilters
  ) => {
    let queryParams = '';

    Object.keys(query).forEach(key => {
      if (query[key] !== undefined) {
        queryParams += queryParams ? '&' : '?';
        queryParams += `${key}=${query[key]}`;
      }
    });

    const response = await request.get(
      `posting/v1.0/postings/${startDate}/${endDate}${queryParams}`
    );
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.list()`
    );
    return responseHandler(response);
  };

  static show = async (postingId: number | string) => {
    const response = await request.get(`posting/v1.0/${postingId}`);
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.show()`
    );
    return responseHandler(response);
  };

  static post = async (payload: createPostingDTO) => {
    const response = await request.post('posting/v1.0', payload);
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.post()`
    );
    return responseHandler(response);
  };

  static put = async (
    postingId: number | string,
    payload: putPostingPayloadDTO
  ) => {
    const response = await request.put(`posting/v1.0/${postingId}`, payload);
    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.put()`
    );
    return responseHandler(response);
  };
}

export default PostingServices;
