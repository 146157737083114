import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

export const MobiSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#00B0AA',
    },
    '&$checked + $track': {
      backgroundColor: '#00B0AA',
    },
  },
  checked: {},
  track: {},
})(Switch);
