import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { CommonLabel, EditButton, styles } from './styles';

function TableItemRow({
  order,
  handleClickListItem,
  handleClickEditItem,
  classes,
}) {
  const handleClickEdit = event => {
    event.stopPropagation();
    handleClickEditItem();
  };

  return (
    <TableRow
      tabIndex={-1}
      className={classes.root}
      onClick={handleClickListItem}
      hover
    >
      <TableCell align="left" padding={'checkbox'}>
        <CommonLabel padding={'0px 14px'}>{order.id}</CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>{order.name}</CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>{order.cpfCnpj}</CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>{order.phone}</CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>{order.email}</CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'checkbox'}>
        <CommonLabel>
          <EditButton onClick={handleClickEdit}>
            <CreateIcon />
          </EditButton>
        </CommonLabel>
      </TableCell>
    </TableRow>
  );
}

export default withStyles(styles)(TableItemRow);
