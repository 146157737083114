import React from 'react';
import TableDataGrid from 'components/atoms/TableDataGrid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    border: 'none',
    '& .table-header': {
      fontFamily: 'Poppins Regular',
      fontWeight: '500',
      fontStyle: 'normal',
      color: '#464646',
      fontSize: '16px',
      background: 'white',
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: '500',
    },
    '& .table-body': {
      fontFamily: 'Poppins Regular',
      fontWeight: '400',
      fontStyle: 'normal',
      color: '#424242',
      fontSize: '16px',
      background: 'white',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-footer': {
      background: 'white',
    },
  },
});

const ListStoresComponent = ({
  state,
  columns,
  buildDataGridDate,
  customNoRowsOverlay,
  loadingOverlay,
}) => {
  const classes = useStyles();

  return (
    <TableDataGrid
      columns={columns}
      rows={buildDataGridDate(state?.dataTable || [])}
      pageSize={5}
      className={classes.root}
      loading={state?.isLoading}
      components={{
        NoRowsOverlay: customNoRowsOverlay,
        LoadingOverlay: loadingOverlay,
      }}
    />
  );
};

export default ListStoresComponent;
