import React from 'react';
import { useSelector } from 'react-redux';
import ClientDataPF from './ClientDataPF';
import ClientDataPJ from './ClientDataPJ';

const ClientDataContainer = () => {
  const { queueData } = useSelector(state => state.order);

  const buyerCurrentData = queueData?.client;
  const isPJ = buyerCurrentData?.cpfCnpj?.replace(/\D/g, '')?.length > 11;

  const viewComponentProps = {
    buyerCurrentData,
  };

  return (
    <div style={{ margin: 30 }}>
      {isPJ ? (
        <ClientDataPJ {...viewComponentProps} />
      ) : (
        <ClientDataPF {...viewComponentProps} />
      )}
    </div>
  );
};

export default ClientDataContainer;
