import { connect } from 'react-redux';
import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';
import buildPathWithDealerGroup from 'components/TitlePage/components/buildPathWithDealerGroup';
import NewCardContainer from './NewCardContainer';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {
    snackbarManagement: (type, message) =>
      dispatch(UIActionCreators.snackbarManagement(type, message)),
    openProposal: task => {
      const pathWithDealerGroup = buildPathWithDealerGroup();
      dispatch(
        proposalCreators.setProposalModalOpen(true, {
          dealerId: task.dealerId,
          proposalId: task.proposalId,
          groupId: pathWithDealerGroup[0]?.groupId ?? null,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCardContainer);
