import React from 'react';
import { Container, Label } from './styles';

const Chip = ({
  type = 'default',
  children,
  shape = 'round',
  margin = '4px',
  fontSize = '14px',
}) => {
  return (
    <Container type={type} shape={shape} margin={margin}>
      <Label type={type} fontSize={fontSize}>
        {children}
      </Label>
    </Container>
  );
};

export default Chip;
