import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import ProposalServices from 'services/proposalServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { useChatContext } from 'pages/chats/ChatsContext';
import ItemHeader from '../../../atoms/ItemHeader';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import InformationTooltipIcon from 'components/atoms/InformationTooltipIcon';
import getImage from 'components/getImage';
import { Container, List, EmptyUserInfo } from './styles';

function ProfileAndInformation() {
  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);
  const { currentProposal } = useChatContext();

  const [classificationInformation, setClassificationInformation] = useState({
    infos: [],
    isFetching: false,
  });

  const customerClassificationInformation = useCallback(async () => {
    if (
      (!(currentProposal.dealerId || currentDealer?.id), !currentProposal?.id)
    ) {
      return;
    }
    try {
      setClassificationInformation(prevState => ({
        ...prevState,
        isFetching: true,
      }));
      const response = await ProposalServices.getCustomerClassificationInformation(
        currentProposal.dealerId || currentDealer?.id,
        currentProposal.id
      );
      if (response.success && response.data?.length) {
        setClassificationInformation(prevState => ({
          ...prevState,
          infos: response.data,
        }));
        return;
      }
      setClassificationInformation(prevState => ({
        ...prevState,
        infos: [],
      }));
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    } finally {
      setClassificationInformation(prevState => ({
        ...prevState,
        isFetching: false,
      }));
    }
  }, [currentProposal.dealerId, currentProposal?.id]);

  useEffect(() => {
    customerClassificationInformation();
  }, [customerClassificationInformation]);

  // if (classificationInformation.hiddenComponent) {
  //   return null;
  // }

  return (
    <Box component="div" display="grid" flexDirection="row">
      <>
        <ItemHeader>Perfil e informações</ItemHeader>
        {classificationInformation.isFetching ? (
          <List>
            <Progress small />
          </List>
        ) : !classificationInformation?.infos?.length ? (
          <EmptyUserInfo>
            Não há informações disponíveis para esse cliente.
          </EmptyUserInfo>
        ) : (
          <List>
            {classificationInformation?.infos?.map((item, index) => {
              if (typeof item === 'object') {
                return (
                  <Container key={index} bgColor={item?.colorHex}>
                    <Box
                      fontWeight="400"
                      fontSize="14px"
                      display="flex"
                      alignSelf="center"
                    >
                      {item?.label || item?.name || item?.key}
                      {item?.description && (
                        <InformationTooltipIcon text={item.description} />
                      )}
                    </Box>

                    <Box
                      fontWeight="400"
                      fontSize="14px"
                      color="#808080"
                      lineHeight={'16px'}
                      alignSelf={'center'}
                    >
                      {item?.iconId ? (
                        <img
                          src={getImage(
                            {
                              imageId: item.iconId,
                              imageVcode: item.imageVcode,
                            },
                            80
                          )}
                          width="72px"
                          height="24px"
                          style={{ objectFit: 'contain' }}
                        />
                      ) : item?.iconUrl ? (
                        <img
                          src={item.iconUrl}
                          width="72px"
                          height="24px"
                          style={{ objectFit: 'contain' }}
                        />
                      ) : (
                        <>{item?.value}</>
                      )}
                    </Box>
                  </Container>
                );
              }
            })}
          </List>
        )}
      </>
    </Box>
  );
}

export default ProfileAndInformation;
