import styled from 'styled-components';

export const Item = styled.div`
  margin: 0;
  display: flex;
`;

export const CommonLabel = styled.div`
  font-style: normal;
  line-height: 20px;
  padding: ${({ padding }) => (padding ? padding : 'initial')};
  color: #757575;
  font-weight: 600;
  font-size: 12px;
`;

export const EditButton = styled.div`
  display: flex;
  width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background: #00000012;
  }
`;

export const styles = () => ({
  root: {
    height: 42,
    cursor: 'pointer',
  },
});
