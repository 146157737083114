import request from 'helpers/request';
import EnvVar from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVar('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'dealerDepartmentServices';

export const getDealerConfig = dealerId => {
  return request
    .get(`${domain}/dealer/config/v1.0/${dealerId}/config`, {})
    .then(buildHTTPResponseHandler(`${serviceName}.getDealerConfig()`));
};

export const getAllProviders = () => {
  return request
    .get(`${domain}/dealer/config/v1.0/providers`, {})
    .then(buildHTTPResponseHandler(`${serviceName}.getAllProviders()`));
};

export const addReasonNotSale = (dealerId, reasonToAdd) => {
  return request
    .post(`${domain}/dealer/config/v1.0/${dealerId}/reason`, reasonToAdd)
    .then(buildHTTPResponseHandler(`${serviceName}.addReasonNotSale()`));
};

export const editReason = (dealerId, id, reasonToAdd) => {
  return request
    .put(`${domain}/dealer/config/v1.0/${dealerId}/reason/${id}`, reasonToAdd)
    .then(buildHTTPResponseHandler(`${serviceName}.addReasonNotSale()`));
};

export const deleteReason = (dealerId, id) => {
  return request
    .delete(`${domain}/dealer/config/v1.0/${dealerId}/reason/${id}`, {})
    .then(buildHTTPResponseHandler(`${serviceName}.addReasonNotSale()`));
};

export const listAllNonSaleReason = dealerId => {
  return request
    .get(`${domain}/dealer/config/v1.0/${dealerId}/reason`, {})
    .then(buildHTTPResponseHandler(`${serviceName}.listAllNonSaleReason()`));
};

export const getAllOrigins = () => {
  return request
    .get(`${domain}/dealer/config/v1.0/origins`, {})
    .then(buildHTTPResponseHandler(`${serviceName}.getAllOrigins()`));
};

export const updateConfig = (dealerId, configObj) => {
  return request
    .put(`${domain}/dealer/config/v1.0/${dealerId}/lead-manager`, configObj)
    .then(buildHTTPResponseHandler(`${serviceName}.updateConfig()`));
};
