import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ProposalOrderDelivery from 'services/proposalOrderDelivery';

const useGetUsersAndHours = date => {
  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  return useQuery({
    queryKey: 'get_users_and_hours',
    queryFn: () =>
      ProposalOrderDelivery.getAvailableWorkTimetableGrade(
        currentDealer.id,
        date?.date
      ),
    select: (data: any) => {
      return data?.data?.length ? data.data : [];
    },
    refetchOnWindowFocus: false,
    enabled: false,
    onError() {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    },
  });
};

export default useGetUsersAndHours;
