import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ListItemAvatar, Avatar } from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

// Util import
import { DealerFromStore } from 'components/withDealer';

// Context import
import {
  selectedLead,
  chatListOptions,
} from 'pages/conversations/contexts/atoms';
import { selectedChatView } from 'pages/dashBoard/components/Container/contexts/atoms';
import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';

// Interface import
import { ChatData } from 'pages/conversations/interfaces';

// Style import
import { Container, MessageText, BadgeAndTimeWrapper, Badge } from './styles';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';

interface LeadChatProps {
  lead: ChatData;
}

function generateRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

export function LeadChat({ lead }: LeadChatProps) {
  // Hooks
  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  // States
  const [color] = useState(generateRandomColor());
  const [selectedChatLead, setSelectedChatLead] = useRecoilState(selectedLead);
  const chatListOption = useRecoilValue(chatListOptions);
  const isCallCenter = useRecoilValue(selectedChatView) === 'CONVERSATIONS';

  const { clientUserName, lastMessage, unseenMessages, id, createdAt } = lead;
  const nameParts = clientUserName
    ? (clientUserName || '').split(' ')
    : 'Usuário sem nome';
  const firstLetterFirstName = nameParts[0].charAt(0).toUpperCase();
  const firstLetterLastName =
    nameParts.length >= 2 ? nameParts[1].charAt(0).toUpperCase() : '';

  const backgroundColor = chatListOption === 'CONVERSATIONS' ? color : null;

  const handleSelectLead = () => {
    if (!checkCallCenterOsSearchAndRescueRoute() && currentDealer?.closeLead) {
      return;
    }
    const dealerGroupData = DealerFromStore.getDealerGroupFromLocalStorage();

    dispatch(
      proposalCreators.setProposalModalOpen(false, {
        dealerId: lead.dealerId,
        proposalId: lead.id,
        groupId: isCallCenter ? dealerGroupData?.id : null,
        viewType: null,
      })
    );

    setSelectedChatLead({ ...lead });
  };

  return (
    <Container active={selectedChatLead?.id === id} onClick={handleSelectLead}>
      <ListItemAvatar>
        <Avatar style={{ backgroundColor }}>
          {firstLetterFirstName}
          {firstLetterLastName}
        </Avatar>
      </ListItemAvatar>
      <MessageText primary={clientUserName} secondary={lastMessage?.message} />

      <BadgeAndTimeWrapper hasMessage={unseenMessages >= 1}>
        <span>
          {lastMessage?.date
            ? moment.utc(lastMessage?.date).format('HH:mm')
            : moment(createdAt).format('HH:mm')}
        </span>
        {unseenMessages >= 1 && <Badge>{unseenMessages}</Badge>}
      </BadgeAndTimeWrapper>
    </Container>
  );
}
