import { httpCallStatus } from '../acctionOptions';

const MODULE = 'modules/financial/categories';

export default {
  //REDUCERS
  RELOAD_ALL_CATEGORIES: `@${MODULE}/RELOAD_ALL_CATEGORIES`,
  SAVE_CATEGORIE: `@${MODULE}/SAVE_CATEGORIE`,
  UPDATE_CATEGORY: `@${MODULE}/UPDATE_CATEGORY`,
  REMOVE_CATEGORY: `@${MODULE}/REMOVE_CATEGORY`,

  //SAGAS
  GET_CATEGORIES_REQUEST: `@${MODULE}/GET_CATEGORIES_${httpCallStatus.REQUEST}`,
  GET_CATEGORIES_SUCCESS: `@${MODULE}/GET_CATEGORIES_${httpCallStatus.SUCCESS}`,
  GET_CATEGORIES_ERROR: `@${MODULE}/GET_CATEGORIES_${httpCallStatus.ERROR}`,

  PUT_CATEGORIES_REQUEST: `@${MODULE}/PUT_CATEGORIES_${httpCallStatus.REQUEST}`,
  PUT_CATEGORIES_SUCCESS: `@${MODULE}/PUT_CATEGORIES_${httpCallStatus.SUCCESS}`,
  PUT_CATEGORIES_ERROR: `@${MODULE}/PUT_CATEGORIES_${httpCallStatus.ERROR}`,

  DELETE_CATEGORIE_REQUEST: `@${MODULE}/DELETE_CATEGORIES_${httpCallStatus.REQUEST}`,
  DELETE_CATEGORIE_SUCCESS: `@${MODULE}/DELETE_CATEGORIES_${httpCallStatus.SUCCESS}`,
  DELETE_CATEGORIE_ERROR: `@${MODULE}/DELETE_CATEGORIES_${httpCallStatus.ERROR}`,
};
