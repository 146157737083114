// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then(component => {
        // success so reset the refresh
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          // we are now going to refresh
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          // refresh the page
          return window.location.reload();
        }
        // Default error behaviour as already tried refresh
        reject(error);
      });
  });
};

export default lazyRetry;
