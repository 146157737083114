import React, { useEffect } from 'react';
import { reproveDocumentTypes } from './ReproveDocumentModalTypes';
import ReproveDocumentModalComponent from './ReproveDocumentModalComponent';

const ReproveDocumentModalContainer = ({
  open,
  onClose,
  handleClickConfirm,
  isLoading,
}: reproveDocumentTypes) => {
  const [reproveReason, setReproveReason] = React.useState<string>('');

  useEffect(() => {
    if (!open) {
      setReproveReason('');
    }
  }, [open]);

  return (
    <ReproveDocumentModalComponent
      open={open}
      onClose={onClose}
      confirmReproveDocument={() => {
        handleClickConfirm(reproveReason);
        return;
      }}
      reproveReason={reproveReason}
      setReproveReason={setReproveReason}
      isLoading={isLoading}
    />
  );
};

export default ReproveDocumentModalContainer;
