import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
// import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import Chip from 'components/atoms/Chip';
import formatAmount from 'helpers/formatAmount';
import InformationTooltipIcon from 'components/atoms/InformationTooltipIcon';

const creditTypes = {
  'Crédito aprovado': { type: 'high', label: 'Crédito aprovado' },
  'Proposta aprovada': { type: 'high', label: 'Proposta aprovada' },
  'Sujeito a análise': { type: 'medium', label: 'Sujeito a análise' },
  'Crédito recusado': { type: 'low', label: 'Crédito recusado' },
  Contraproposta: { type: 'medium', label: 'Contraproposta' },
};

const ProposalCard = ({
  simulacao,
  // selectedProposal,
  // hancleClickSelectProposal,
}) => {
  return (
    <Grid item xs={12}>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border={`1px solid ${Colors.gray.nearWhite}`}
        borderRadius={4}
        p={2}
      >
        <Box component="div">
          <Box component="div" display="flex">
            {simulacao?.mensagemUsuario && (
              <>
                <Chip
                  type={
                    creditTypes?.[simulacao?.mensagemUsuario]?.type || 'medium'
                  }
                  shape="square"
                  margin="0px"
                  fontSize="12px"
                >
                  {simulacao?.mensagemUsuario}
                </Chip>
                <div style={{ width: 8 }} />
              </>
            )}
            {simulacao?.error && (
              <InformationTooltipIcon text={simulacao?.error} />
            )}
          </Box>
          {simulacao?.valorParcela && (
            <Box component="span" fontSize={16}>
              {simulacao?.prazo}x
              <Box
                component="span"
                color={Colors.primary.medium}
                fontFamily="Poppins Bold"
              >
                {' '}
                de {formatAmount(simulacao.valorParcela, 'PT-BR', 'R$')}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ProposalCard;
