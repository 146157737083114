// export default useUploadDocument;
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import OrderDocumentsServices from 'modules/order/services/OrderDocumentsServices';

interface Props {
  dealerId?: number;
}

const useApproveDocument = ({ dealerId: paramDealerId }: Props) => {
  const { currentProposal } = useSelector(state => state.proposalStore);
  const dispatch = useDispatch();

  const dealerId = paramDealerId || currentProposal.dealerId;

  return useMutation<any, any, any>(
    'approve_document',
    ({
      documentId,
      reason,
    }: {
      documentId: string | number;
      reason?: string;
    }) =>
      OrderDocumentsServices.approveDocumentById(dealerId, documentId, reason),
    {
      onError: err => {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro ao tentar aprovar o documento.'
          )
        );
      },
      onSuccess(data, { callbackOnSuccess }) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Documento aprovado com sucesso.'
          )
        );
        typeof callbackOnSuccess === 'function' && callbackOnSuccess();
        return data;
      },
    }
  );
};

export default useApproveDocument;
