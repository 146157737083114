import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Container = styled(Row)`
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  min-height: 54px;
`;
