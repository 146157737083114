import { all, takeLatest, put } from 'redux-saga/effects';
import { Action } from 'types/ActionTypes';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import PostingServices from 'modules/financial/services/PostingServices';

function* post(action: Action) {
  const { posting } = action.payload;
  const { success, data } = yield PostingServices.post(posting);

  if (success) {
    yield put(ActionCreators.save([data]));
    yield put(ActionCreators.postingCreated(data.id));
  } else {
    //POST_POSTING_ERROR
  }
}

function* get(action: Action) {
  const { startDate, endDate, query } = action.payload;
  yield put(ActionCreators.setIsLoading(true));
  const response = yield PostingServices.list(startDate, endDate, query);

  if (response.success) {
    yield put(ActionCreators.reloadAll(response.data));
  }
  yield put(ActionCreators.setIsLoading(false));
}

function* getById(action: Action) {
  const { success, data } = yield PostingServices.show(action.payload.id);
  success
    ? yield put(ActionCreators.save([data]))
    : console.log('getById saga postings ERROR');
}

function* putPosting(action: Action) {
  const {
    id,
    bankAccount,
    category,
    contact,
    deal,
    paymentMethod,
    seller,
    ...attrs
  }: any = action.payload.posting;

  if (id) {
    const response = yield PostingServices.put(id, attrs);
    if (response.success) {
      yield put(ActionCreators.upateExisting(response.data));
    }
  }
}

export default all([
  takeLatest(ActionTypes.POST_POSTING_REQUEST, post),
  takeLatest(ActionTypes.GET_POSTING_REQUEST, get),
  takeLatest(ActionTypes.PUT_POSTING_REQUEST, putPosting),
  takeLatest(ActionTypes.SHOW_POSTING_REQUEST, getById),
]);
