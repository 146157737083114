import React, { useState, useRef, useEffect } from 'react';
import { ButtonBase } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ViewWeekOutlinedIcon from '@material-ui/icons/ViewWeekOutlined';
import Wrapper from 'components/Wrapper';
import {
  FilterColumnsButton,
  FilterColumnsBody,
  Label,
  CustomButton,
} from './styles';

function ViewColumns({ columns, setDynamicColumns, mini = false }) {
  const [displayFilter, setDisplayFilter] = useState(false);
  const filterColumnsBodyRef = useRef(null);
  const filterColumnsButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        filterColumnsButtonRef.current &&
        !filterColumnsButtonRef.current.contains(event.target) &&
        filterColumnsBodyRef.current &&
        !filterColumnsBodyRef.current.contains(event.target)
      ) {
        setDisplayFilter(false);
      }
    };

    if (displayFilter) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [displayFilter]);

  const handleOpenFilterColumnsBody = () => {
    setDisplayFilter(!displayFilter);
  };

  const handleChangeCheckBox = index => {
    const checkBoxFiltersTemp: any = [...columns];
    checkBoxFiltersTemp[index].hide = !checkBoxFiltersTemp[index].hide;
    setDynamicColumns(checkBoxFiltersTemp);

    localStorage.setItem(
      'selectedColumns',
      JSON.stringify(checkBoxFiltersTemp)
    );
  };

  if (mini) {
    return (
      <div>
        <div
          style={{
            position: 'relative',
          }}
        >
          <ButtonBase
            ref={filterColumnsButtonRef}
            onClick={handleOpenFilterColumnsBody}
          >
            <CustomButton>Exibir colunas</CustomButton>
          </ButtonBase>

          {displayFilter && (
            <FilterColumnsBody
              id="FilterColumnsBody"
              ref={filterColumnsBodyRef}
            >
              {(columns || []).map((el, index) => {
                return (
                  <Wrapper flex direction="row" key={el.value}>
                    <Checkbox
                      checked={!el.hide}
                      onChange={() => handleChangeCheckBox(index)}
                    />

                    <Label>{el?.headerNameFilterLabel ?? el.headerName}</Label>
                  </Wrapper>
                );
              })}
            </FilterColumnsBody>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '274px',
          marginBottom: '16px',
          position: 'relative',
        }}
      >
        <FilterColumnsButton
          ref={filterColumnsButtonRef}
          onClick={handleOpenFilterColumnsBody}
        >
          <ViewWeekOutlinedIcon htmlColor="black" fontSize="small" /> Exibir
          colunas{' '}
        </FilterColumnsButton>

        {displayFilter && (
          <FilterColumnsBody id="FilterColumnsBody" ref={filterColumnsBodyRef}>
            {(columns || []).map((el, index) => {
              return (
                <Wrapper flex direction="row" key={el.value}>
                  <Checkbox
                    checked={!el.hide}
                    onChange={() => handleChangeCheckBox(index)}
                  />

                  <Label>{el?.headerNameFilterLabel ?? el.headerName}</Label>
                </Wrapper>
              );
            })}
          </FilterColumnsBody>
        )}
      </div>
    </div>
  );
}

export default ViewColumns;
