import styled from 'styled-components';

interface SizeProps {
  size: string;
}

export const SquareImageComponent = styled.img`
  width: ${({ size }: SizeProps): string => size};
  height: ${({ size }: SizeProps): string => size};
  background: #e0e0e0;
  border-radius: 4px;
  object-fit: cover;
  overflow: hidden;
`;
