import React, { useState } from 'react';
import DialogSelectVehicleToSimulateComponent from './DialogSelectVehicleToSimulateComponent';

const DialogSelectVehicleToSimulateContainer = ({
  open,
  handleClose,
  handleClickSelectVehicleToSimulate,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState([]);

  const viewComponentProps = {
    open,
    handleClose,
    handleClickSelectVehicleToSimulate,
    selectedVehicle,
    setSelectedVehicle,
  };
  return <DialogSelectVehicleToSimulateComponent {...viewComponentProps} />;
};

export default DialogSelectVehicleToSimulateContainer;
