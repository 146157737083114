import React from 'react';

import Box, { BoxProps } from '@material-ui/core/Box';

const CardBaseComponent = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      component="div"
      border="1px solid #BDBDBD"
      borderRadius="4px"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default CardBaseComponent;
