import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import { CancelOutlined } from '@material-ui/icons';
import moment from 'moment';
import ThemeButton from 'components/atoms/ThemeButton';
import Wrapper from 'components/Wrapper';
import CancelScheduling from './components/CancelScheduling';
import { Container, TextDate, ButtonCancel } from './styles';
import Alert from '@material-ui/lab/Alert';

const DeliveryDetails = ({
  proposalSelected,
  setOpenSchedulingDeliveryDialog,
  getDataDeliveryStep,
}) => {
  const { orderStatus } = useSelector(state => state.order);
  const [openCancelSchedulingModal, setOpenCancelSchedulingModal] = useState(
    false
  );
  const handleCloseModalCancelScheduling = () => {
    setOpenCancelSchedulingModal(false);
  };

  const translateDeliverymanUserType = userType => {
    if (userType === 'SELLER') {
      return 'Vendedor';
    }
    return 'Entregador Técnico';
  };

  const orderInvoiced = orderStatus === 'INVOICED';

  return (
    <>
      {proposalSelected?.schedule?.id ? (
        <Container>
          <Wrapper flex direction="column">
            <TextDate>
              Data da entrega:{' '}
              {` ${moment(proposalSelected?.schedule?.startTime).format(
                'L'
              )} - ${moment(proposalSelected?.schedule?.startTime).format(
                'LT'
              )} - ${moment(proposalSelected?.schedule?.endTime).format('LT')}`}
            </TextDate>
            <Wrapper flex fontSize={14} fontWeight={400}>
              {`${
                proposalSelected?.schedule?.deliverymanName
              } | ${translateDeliverymanUserType(
                proposalSelected?.schedule?.deliverymanUsertype
              )}`}
            </Wrapper>
          </Wrapper>
          <Tooltip title="Cancelar agendamento">
            <ButtonCancel onClick={() => setOpenCancelSchedulingModal(true)}>
              <CancelOutlined color="error" />
            </ButtonCancel>
          </Tooltip>
        </Container>
      ) : (
        <Wrapper flex gap={8} align="center">
          <ThemeButton
            onClick={() => setOpenSchedulingDeliveryDialog(true)}
            disabled={!orderInvoiced}
          >
            + Agendar entrega
          </ThemeButton>
          {!orderInvoiced && (
            <Alert severity="warning">
              Pedido não Faturado não pode ser agendado.
            </Alert>
          )}
        </Wrapper>
      )}

      {openCancelSchedulingModal && (
        <CancelScheduling
          open={openCancelSchedulingModal}
          onClose={handleCloseModalCancelScheduling}
          proposalSelected={proposalSelected}
          refetchData={getDataDeliveryStep}
        />
      )}
    </>
  );
};

export default DeliveryDetails;
