import ActionTypes from './actionTypes';
import { defaultOptions } from 'modules/financing/helpers/defaultOptions';

const FINANCING_STATUS = {
  CREATING: 'CREATING',
  REVIEW: 'REVIEW',
};

export const INITIAL_STATE: any = {
  _financingSystemStatus: FINANCING_STATUS.CREATING,
  isFinished: false,
  currentDealerId: null,
  //
  status: null,
  //
  financingListingIsLoading: true,
  financingListing: [],
  campaignsListing: [],
  //
  steps: [
    { label: 'Dados do Cliente' },
    { label: 'Simulação' },
    { label: 'Análise de crédito' },
    { label: 'Resultado da análise' },
  ],
  stepperConfig: { currentStep: 0, completed: [] },
  //
  financingOrderByIdIsLoading: false,
  //
  clientCurrent: null,
  financing: null,
  //CREATE_CLIENT_IS_LOADING
  createContactIsLoading: false,
  //
  clientCurrentData: {
    //PERSONAL_FORM
    name: null,
    birthDate: null,
    cpf: null,
    fatherName: null,
    motherName: null,
    rgNumber: null,
    rgIssuer: null,
    rgIssuedState: null,
    rgIssuedAt: null,
    nationality: null,
    schoolingType: 0,
    supplierServiceTypeId: 0,
    supplierServiceTypeName: null,
    // country: 0,
    civilStatus: 0,
    gender: 0,
    phone: null,
    email: null,
    //ADDRESS_FORM
    zipcode: null,
    address: null,
    number: null,
    cityStateId: 0,
    complement: null,
    neighborhood: null,
    cityId: 0,
    addressType: 0,
    streetType: 0,
    //REFERENCES_FORM
    referenceBankId: 0,
    referenceBankAccountType: 0,
    referenceBankAgency: null,
    referenceBankAccount: null,
    referenceBankAgencyDigit: null,
    referenceName: null,
    referencePhone: null,
    referenceRelationship: null,
    //OCUPPATION_FORM
    employmentId: 0,
    careerId: 0,
    employmentName: null,
    employmentStartDate: null,
    monthlyIncome: 0,
    employmentZipcode: null,
    employmentAddressType: 0,
    employmentStreetType: 0,
    employmentAddress: null,
    employmentNumber: null,
    employmentComplement: null,
    employmentNeighborhood: null,
    employmentCityStateId: 0,
    employmentCityId: 0,
  },
  //
  vehicleCurrent: null,
  //
  salePrice: 0,
  downPayment: 0,
  inputValue: 0,
  financingValue: 0,
  term: 36,
  commissionReturn: 'R0',
  //
  simulateIsLoading: false,
  applySimulationLoading: false,
  banksToSimulate: [],
  banksToFinancing: [],
  applications: {},
  //
  analysisResultStepEnable: false,
  banksToResultAnalysis: [],
  resultAnalysisIsLoading: false,
  //FORM_OPTIONS
  statesOptions: defaultOptions,
  citiesOptions: defaultOptions,
  genderOptions: defaultOptions,
  civilStatusOptions: defaultOptions,
  streetTypeOptions: defaultOptions,
  addressTypeOptions: defaultOptions,
  careersOptions: defaultOptions,
  employmentsOptions: defaultOptions,
  financialInstitutionsOptions: defaultOptions,
  commissionReturnOptions: defaultOptions,
  bankAccountTypeOptions: defaultOptions,
  serviceTypesOptions: defaultOptions,
  //FINANCIAL_INSTITUTION_MODULE
  currentFinancialInstitution: null,
  financialInstitutionCurrentTab: 'campaign',
  dataCampaign: {},
  institutionData: {},
  dealerPJfinancingInstitutionListing: [],
};

const evaluation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_FINANCING_SYSTEM_STATUS:
      return {
        ...state,
        _financingSystemStatus: action.payload,
      };
    case ActionTypes.RESET_FINANCING_STORE:
      return { ...INITIAL_STATE };
    case ActionTypes.SET_CURRENT_DEALER_ID:
      return { ...state, currentDealerId: action.payload };
    case ActionTypes.SET_FINANCING_LISTING:
      return { ...state, financingListing: action.payload };
    case ActionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        stepperConfig: {
          ...state.stepperConfig,
          currentStep: action.payload,
        },
      };
    case ActionTypes.SET_COMPLETE_STEP:
      return {
        ...state,
        stepperConfig: {
          ...state.stepperConfig,
          completed: action.payload,
        },
      };
    //SET_CREATE_CLIENT_IS_LOADING
    case ActionTypes.SET_CREATE_CONTACT_IS_LOADING:
      return {
        ...state,
        createContactIsLoading: action.payload,
      };
    case ActionTypes.SET_CLIENT_CURRENT:
      return {
        ...state,
        clientCurrent: action.payload,
        clientCurrentData: {
          ...state.clientCurrentData,
          ...action.payload,
          cpf: action.payload?.cpfCnpj,
        },
      };
    case ActionTypes.SET_VEHICLE_CURRENT:
      return {
        ...state,
        vehicleCurrent: action.payload,
      };
    case ActionTypes.SET_FINANCING:
      return {
        ...state,
        financing: { financingId: action.payload.id },
      };
    case ActionTypes.SET_DOWN_PAYMENT:
      return {
        ...state,
        downPayment: action.payload,
      };
    case ActionTypes.SET_APPLY_SIMULATION_LOADING: {
      return {
        ...state,
        applySimulationLoading: action.payload,
      };
    }
    case ActionTypes.SET_SIMULATE_BANK_DATA_BY_ID: {
      const bankIndex = state.banksToSimulate.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      const banksTemp = state.banksToSimulate;
      banksTemp[bankIndex] = {
        ...banksTemp[bankIndex],
        ...action.payload.data,
        errorMessage: action.payload.data?.errorMessage ?? null,
      };

      return { ...state, banksToSimulate: [...banksTemp] };
    }
    case ActionTypes.CHANGE_DOWN_PAYMENT_BY_BANK: {
      const bankIndex = state.banksToSimulate.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      const banksTemp = state.banksToSimulate;
      banksTemp[bankIndex].downPayment = action.payload.downPayment;

      // if bank has selected
      const bankToFinancingIndex = state.banksToFinancing.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      let bankToFinancingTemp: any = [];

      if (bankToFinancingIndex !== -1) {
        bankToFinancingTemp = state.banksToFinancing;
        bankToFinancingTemp[bankToFinancingIndex].downPayment =
          action.payload.downPayment;
      }
      return {
        ...state,
        banksToSimulate: [...banksTemp],
        banksToFinancing: bankToFinancingTemp.length
          ? [...bankToFinancingTemp]
          : state.banksToFinancing,
      };
    }
    case ActionTypes.CHANGE_TERMS_BY_BANK: {
      const bankIndex = state.banksToSimulate.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      const banksTemp = state.banksToSimulate;
      banksTemp[bankIndex].term = action.payload.term;

      // if bank has selected
      const bankToFinancingIndex = state.banksToFinancing.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      let bankToFinancingTemp: any = [];
      if (bankToFinancingIndex !== -1) {
        bankToFinancingTemp = state.banksToFinancing;
        bankToFinancingTemp[bankToFinancingIndex].term = action.payload.term;
      }

      return {
        ...state,
        banksToSimulate: [...banksTemp],
        banksToFinancing: bankToFinancingTemp.length
          ? [...bankToFinancingTemp]
          : state.banksToFinancing,
      };
    }
    case ActionTypes.CHANGE_SIMULATE_TERM_BY_BANK: {
      const bankIndex = state.banksToSimulate.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      const banksTemp = state.banksToSimulate;
      banksTemp[bankIndex].simulateTerm = action.payload.simulateTerm;

      // if bank has selected
      const bankToFinancingIndex = state.banksToFinancing.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      let bankToFinancingTemp: any = [];
      if (bankToFinancingIndex !== -1) {
        bankToFinancingTemp = state.banksToFinancing;
        bankToFinancingTemp[bankToFinancingIndex].simulateTerm =
          action.payload.simulateTerm;
      }

      return {
        ...state,
        banksToSimulate: [...banksTemp],
        banksToFinancing: bankToFinancingTemp.length
          ? [...bankToFinancingTemp]
          : state.banksToFinancing,
      };
    }
    case ActionTypes.CHANGE_COMMISSIONRETURN_BY_BANK: {
      const bankIndex = state.banksToSimulate.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      const banksTemp = state.banksToSimulate;
      banksTemp[bankIndex].commissionReturn = action.payload.commissionReturn;

      // if bank has selected
      const bankToFinancingIndex = state.banksToFinancing.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      let bankToFinancingTemp: any = [];
      if (bankToFinancingIndex !== -1) {
        bankToFinancingTemp = state.banksToFinancing;
        bankToFinancingTemp[bankToFinancingIndex].commissionReturn =
          action.payload.commissionReturn;
      }

      return {
        ...state,
        banksToSimulate: [...banksTemp],
        banksToFinancing: bankToFinancingTemp.length
          ? [...bankToFinancingTemp]
          : state.banksToFinancing,
      };
    }
    case ActionTypes.ADD_RATES_CONFIG_BY_BANK: {
      const bankIndex = state.banksToSimulate.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      const banksTemp = state.banksToSimulate;
      banksTemp[bankIndex] = { ...action.payload.newRateConfigs };

      // if bank has selected
      const bankToFinancingIndex = state.banksToFinancing.findIndex(
        bank =>
          bank.dealerPJFinancingInstitution.id === action.payload.institutionId
      );
      let bankToFinancingTemp: any = [];
      if (bankToFinancingIndex !== -1) {
        bankToFinancingTemp = state.banksToFinancing;
        bankToFinancingTemp[bankToFinancingIndex] = {
          ...action.payload.newRateConfigs,
        };
      }

      return {
        ...state,
        banksToSimulate: [...banksTemp],
        banksToFinancing: bankToFinancingTemp.length
          ? [...bankToFinancingTemp]
          : state.banksToFinancing,
      };
    }
    case ActionTypes.SET_CURRENT_VEHICLE_PRICE:
      return {
        ...state,
        vehicleCurrent: { ...state.vehicleCurrent, price: action.payload },
      };
    case ActionTypes.SET_CURRENT_VEHICLE_INFO:
      return {
        ...state,
        vehicleCurrent: {
          ...state.vehicleCurrent,
          [action.payload.name]: action.payload.value,
        },
      };
    case ActionTypes.SET_INSTALLMENT:
      return {
        ...state,
        term: action.payload,
      };
    case ActionTypes.SET_COMISSION_RETURN:
      return {
        ...state,
        commissionReturn: action.payload,
      };
    case ActionTypes.SET_FINANCING_VALUE:
      return {
        ...state,
        financingValue: action.payload,
      };
    case ActionTypes.SET_COMISSION_RETURN_OPTIONS:
      return {
        ...state,
        commissionReturnOptions: [
          ...state.commissionReturnOptions,
          ...action.payload,
        ],
      };
    case ActionTypes.SET_BANKS_TO_SIMULATE: {
      //ADD_TERM_AND_DOWNPATMENT_DEFAULT
      const temp: any = [];
      action.payload.map(item => {
        item.term = item?.term ?? state.term;
        item.downPayment = item?.downPayment ?? state.downPayment;
        temp.push(item);
      });
      return {
        ...state,
        banksToSimulate: temp,
      };
    }
    case ActionTypes.SET_SIMULATE_LOADING:
      return {
        ...state,
        simulateIsLoading: action.payload,
      };
    case ActionTypes.SELECT_BANK_TO_FINANCING:
      if (
        state.banksToFinancing.find(
          bank =>
            bank.dealerPJFinancingInstitution.id ===
            action.payload.dealerPJFinancingInstitution.id
        )
      ) {
        return {
          ...state,
          banksToFinancing: state.banksToFinancing.filter(
            bank =>
              bank.dealerPJFinancingInstitution.id !==
              action.payload.dealerPJFinancingInstitution.id
          ),
        };
      }
      return {
        ...state,
        banksToFinancing: [...state.banksToFinancing, action.payload],
      };
    case ActionTypes.SET_FINANCING_LISTING_IS_LOADING:
      return { ...state, financingListingIsLoading: action.payload };
    case ActionTypes.CHANGE_CLIENT_CURRENT_DATA:
      //if change state reset cityId
      if (action.payload.dataName === 'addressState') {
        return {
          ...state,
          clientCurrentData: {
            ...state.clientCurrentData,
            [action.payload.dataName]: action.payload.dataValue,
            cityId: 0,
          },
        };
      }
      return {
        ...state,
        clientCurrentData: {
          ...state.clientCurrentData,
          [action.payload.dataName]: action.payload.dataValue,
        },
      };
    case ActionTypes.SET_FINANCING_ORDER_BY_ID_LOADING:
      return {
        ...state,
        financingOrderByIdIsLoading: action.payload,
      };
    case ActionTypes.SET_FINANCING_ORDER_BY_ID: {
      const orderById: any = {
        _financingSystemStatus: FINANCING_STATUS.REVIEW,
        banksToResultAnalysis: action.payload.creditAnalyses,
        banksToSimulate: action.payload.simulations,
        banksToFinancing: action.payload.creditAnalyses.map(el => ({
          ...el,
          dealerPJFinancingInstitution: {
            id: el?.dealerPJFinancingInstitution.id,
            offline: el?.dealerPJFinancingInstitution.offline,
            institution: {
              id: el?.dealerPJFinancingInstitution.institution.id,
              name: el?.dealerPJFinancingInstitution.institution.name,
              imageId: el?.dealerPJFinancingInstitution.institution.imageId,
            },
          },
        })),
        vehicleCurrent: {
          dealId: action.payload.dealId,
          //PROPS_TO_DEAL_CARD_COMPONENT
          vehiclePrice: action.payload.vehiclePrice,
          fullName: `${action.payload.vehicleMake} ${action.payload.vehicleTrim}`,
          makeName: action.payload.vehicleMake,
          modelName: action.payload.vehicleModel,
          trimName: action.payload.vehicleTrim,
          productionYear: action.payload.vehicleProductionYear,
          modelYear: action.payload.vehicleModelYear,
          imageId: action.payload.vehicleImageId,
          vin: action.payload.vehicleVin,
          //NEW_ATTRIBUTTES_API
          vehicleMake: action.payload.vehicleMake,
          vehicleModel: action.payload.vehicleModel,
          vehicleTrim: action.payload.vehicleTrim,
          vehicleTrimId: action.payload.vehicleTrimId,
          vehicleModelYear: action.payload.vehicleModelYear,
          vehicleProductionYear: action.payload.vehicleProductionYear,
          vehicleImageId: action.payload.vehicleImageId,
          vehicleFipe: action.payload.vehicleFipe,
          vehicleMolicar: action.payload.vehicleMolicar,
        },
        clientCurrent: {
          name: action.payload.name,
          id: action.payload.contactId,
          email: action.payload.email,
          cpfCnpj: action.payload.cpf,
          phone: action.payload.phone,
        },
        financing: { ...action.payload.financing },
        clientCurrentData: {
          ...state.clientCurrentData,
          ...action.payload,
        },
        stepperConfig: { currentStep: 1, completed: [0, 1] },
        applications: action.payload.contractInstitutionId
          ? {
              dealerPJFinancingInstitution: {
                id: action.payload?.contractInstitutionId,
              },
              downPayment: action.payload?.contractDownPayment,
              installmentValue: action.payload?.contractInstallmentValue,
              commissionReturnValue: action?.payload?.contractCommissionReturn,
              term: action.payload?.contractTerm,
              interestRate: action.payload?.contractInterestedRate,
              proposalCode: action.payload?.contractProposalCode,
              cetId: action.payload?.contractCetId,
              financingValue: action.payload?.contractfinancingValue,
              firstInstallmentDate: action.payload?.firstInstallmentDate,
              assistanceValue: action.payload?.assistanceValue,
            }
          : {},
        salePrice: action.payload.salePrice,
        isFinished: action.payload.finished,
        downPayment: action.payload.downPayment,
        term: action.payload.term,
        commissionReturn: action.payload.commissionReturn,
        financingValue: action.payload.financingValue,
        status: action.payload.status,
        proposalId: action.payload?.proposalId,
      };
      //IF_CREDIT_ANALYSES_IS_ZERO_TO_TO_THIRD_STEP
      if (action.payload.creditAnalyses.length !== 0) {
        orderById.stepperConfig = { currentStep: 3, completed: [0, 1, 2, 3] };
        orderById.analysisResultStepEnable = true;
      }
      //IF_SIMULATION_IS_ZERO_TO_TO_FIRST_STEP
      if (
        !action.payload.vehicleMolicar ||
        action.payload.simulations.length === 0
      ) {
        orderById.stepperConfig = { currentStep: 0, completed: [0] };
      }

      return {
        ...state,
        ...orderById,
      };
    }
    case ActionTypes.SET_ENABLE_STEP_RESULT_ANALYSIS:
      return {
        ...state,
        analysisResultStepEnable: action.payload,
      };
    case ActionTypes.SET_BANKS_RESULT_ANALYSIS:
      return {
        ...state,
        banksToResultAnalysis: action.payload,
      };
    case ActionTypes.SET_RESULT_ANALYSIS_LOADING:
      return {
        ...state,
        resultAnalysisIsLoading: action.payload,
      };
    case ActionTypes.SET_STATES:
      return {
        ...state,
        statesOptions: action.payload,
      };
    case ActionTypes.SET_CITIES_BY_STATE:
      return {
        ...state,
        citiesOptions: action.payload,
      };
    case ActionTypes.SET_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
      };
    //OPTIONS
    case ActionTypes.SET_GENDER_OPTIONS:
      return {
        ...state,
        genderOptions: [...state.genderOptions, ...action.payload],
      };
    case ActionTypes.SET_CIVIL_STATUS_OPTIONS:
      return {
        ...state,
        civilStatusOptions: [...state.civilStatusOptions, ...action.payload],
      };
    case ActionTypes.SET_SERVICE_TYPES_OPTIONS:
      return {
        ...state,
        serviceTypesOptions: [...state.serviceTypesOptions, ...action.payload],
      };
    case ActionTypes.SET_STREET_TYPE_OPTIONS:
      return {
        ...state,
        streetTypeOptions: [...state.streetTypeOptions, ...action.payload],
      };
    case ActionTypes.SET_ADDRESS_TYPE_OPTIONS:
      return {
        ...state,
        addressTypeOptions: [...state.addressTypeOptions, ...action.payload],
      };
    case ActionTypes.SET_CAREERS_OPTIONS:
      return {
        ...state,
        careersOptions: [...state.careersOptions, ...action.payload],
      };
    case ActionTypes.SET_BANK_ACCOUNT_TYPE_OPTIONS:
      return {
        ...state,
        bankAccountTypeOptions: [
          ...state.bankAccountTypeOptions,
          ...action.payload,
        ],
      };
    case ActionTypes.SET_EMPLOYMENTS_OPTIONS:
      return {
        ...state,
        employmentsOptions: [...state.employmentsOptions, ...action.payload],
      };
    case ActionTypes.SET_ALL_FINANCIAL_INSTITUTION_OPTIONS:
      return {
        ...state,
        financialInstitutionsOptions: [
          ...state.financialInstitutionsOptions,
          ...action.payload,
        ],
      };
    case ActionTypes.SET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION:
      return {
        ...state,
        dealerPJfinancingInstitutionListing: [...action.payload],
      };
    //FINANCIAL_INSTITUTION_MODULE
    case ActionTypes.SET_FINANCIAL_INSTITUTION_CURRENT_TAB:
      return {
        ...state,
        financialInstitutionCurrentTab: action.payload,
      };
    case ActionTypes.SET_CURRENT_FINANCIAL_INSTITUTION:
      return {
        ...state,
        currentFinancialInstitution: action.payload,
      };
    case ActionTypes.SET_CAMPAIGNS:
      return {
        ...state,
        campaignsListing: [...action.payload],
      };
    case ActionTypes.SET_CAMPAIGN:
      return {
        ...state,
        dataCampaign: action.payload,
      };
    //FINANCING INSTITUTION
    case ActionTypes.SET_INSTITUTION:
      return {
        ...state,
        institutionData: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default evaluation;
