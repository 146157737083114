import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SummaryClientData from './components/SummaryClientData';
import SummaryAddress from './components/SummaryAddress';
import SummaryVehicle from './components/SummaryVehicle';
import SummaryPayment from './components/SummaryPayment';
import SummaryDelivery from './components/SummaryDelivery';
import * as S from './styles';

const Summary = () => {
  const { queueData } = useSelector(state => state.order);
  const dataCompleted = queueData?.client?.formOem === 'FULL';

  return (
    <Box>
      <Box bgcolor="#F5F5F5" margin="24px" padding="16px">
        <Box display="flex" flexDirection="row">
          <S.IconContainer>
            <LibraryBooksIcon />
          </S.IconContainer>
          <Box
            fontWeight="500"
            fontSize="16px"
            color=" #424242"
            marginLeft="16px"
            marginTop="4px"
          >
            Resumo
          </Box>
        </Box>
        <Box
          fontWeight="600"
          fontSize="16px"
          color=" #424242"
          margin="32px auto 16px 0px"
        >
          Confira os dados do pedido abaixo:
        </Box>

        <Box
          bgcolor="#FFFFFF"
          padding="16px"
          border="1px solid #E0E0E0"
          borderRadius="4px"
        >
          <Box marginBottom="24px">
            <Box
              fontSize="16px"
              color=" #424242"
              fontFamily={'Poppins Medium'}
              fontWeight={500}
            >
              Dados de cliente
            </Box>
            <SummaryClientData />
          </Box>
          {dataCompleted && (
            <Box marginBottom="24px">
              <Box
                fontFamily={'Poppins Medium'}
                fontSize="16px"
                color=" #424242"
                fontWeight={500}
              >
                Endereço
              </Box>
              <SummaryAddress />
            </Box>
          )}
          <Box marginBottom="24px">
            <Box fontSize="16px" color=" #424242" fontFamily={'Poppins Medium'}>
              Dados do veículo
            </Box>

            <SummaryVehicle />
          </Box>
          <Box marginBottom="24px">
            <Box fontSize="16px" color=" #424242" fontFamily={'Poppins Medium'}>
              Pagamento
            </Box>
            <SummaryPayment />
          </Box>
          <Box marginBottom="24px">
            <Box fontSize="16px" color=" #424242" fontFamily={'Poppins Medium'}>
              Entrega
            </Box>

            <SummaryDelivery />
          </Box>
          <Box>
            <Box fontSize="16px" color=" #424242" fontFamily={'Poppins Medium'}>
              Observações
            </Box>
            <S.Text>
              {queueData?.comments
                ? queueData?.comments
                : 'Pedido sem observações.'}
            </S.Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Summary;
