import React from 'react';

// Component import
import ProposalRating from 'pages/chats/components/ProposalRating'
import ArchiveProposal from 'pages/chats/components/SubHeader/components/ArchiveProposal';

// Style import
import { Wrapper } from '../styles';

export function LeadTemperature() {
  return (
    <Wrapper>
      <span>Temperatura do lead:</span>

      <div style={{ display: 'flex', marginTop: 10 }}>
        <ProposalRating showText={false} />
        <div style={{ borderRight: '1px solid #b6b6b6', margin: '0px 8px' }} />
        <ArchiveProposal />
      </div>
    </Wrapper>
  );
}
