import { getters, httpCallStatus } from '../acctionOptions';

const MODULE = 'modules/manageDeals/deals';

export default {
  //REDUCERS
  SELECT_DEAL: `@${MODULE}/DEAL_SELECTED`,
  UPDATE_DEAL: `@${MODULE}/UPDATE_DEAL/DEAL_SELECTED`,

  ADD_DOCUMENT_DEAL: `@${MODULE}/ADD_DOCUMENT`,
  UPDATE_DOCUMENT_DEAL: `@${MODULE}/UPDATE_DOCUMENTE_DEAL`,
  DELETE_DOCUMENT_DEAL: `@${MODULE}/DELETE_DOCUMENT`,
  LOADING_DOCUMENTS: `@${MODULE}/LOADING_DOCUMENTS`,

  ADD_TRANSACTIONAL_DEAL: `@${MODULE}/ADD_TRANSACTIONAL_DEAL`,
  UPDATE_TRANSACTIONAL_DEAL: `@${MODULE}/UPDATE_TRANSACTIONAL_DEAL`,
  DELETE_TRANSACTIONAL_DEAL: `@${MODULE}/DELETE_TRANSACTIONAL_DEAL`,

  //SAGAS
  PUT_DEAL_REQUEST: `@${MODULE}/PUT_DEAL_${httpCallStatus.REQUEST}`,
  POST_INVOICE_REQUEST: `@${MODULE}/POST_INVOICE_${httpCallStatus.REQUEST}`,
  DELETE_TRANSACTIONAL_INVOICE_REQUEST: `@${MODULE}/DELETE_TRANSACTIONAL_INVOICE_${httpCallStatus.REQUEST}`,

  POST_DOCUMENT_REQUEST: `@${MODULE}/POST_DOCUMENT_${httpCallStatus.REQUEST}`,
  PUT_DOCUMENT_REQUEST: `@${MODULE}/PUT_DOCUMENT_${httpCallStatus.REQUEST}`,
  DELETE_DOCUMENT_REQUEST: `@${MODULE}/DELETE_DOCUMENT_${httpCallStatus.REQUEST}`,

  POST_TRANSACTIONAL_REQUEST: `@${MODULE}/POST_TRANSACTIONAL_${httpCallStatus.REQUEST}`,
  PUT_TRANSACTIONAL_REQUEST: `@${MODULE}/PUT_TRANSACTIONAL_${httpCallStatus.REQUEST}`,
  DELETE_TRANSACTIONAL_REQUEST: `@${MODULE}/DELETE_TRANSACTIONAL_${httpCallStatus.REQUEST}`,

  GET_DEAL_BY_ID_REQUEST: `@${MODULE}/GET_DEAL_BY_ID_${httpCallStatus.REQUEST}`,
  GET_DEAL_BY_ID_ERROR: `@${MODULE}/GET_DEAL_BY_ID_${httpCallStatus.ERROR}`,
  GET_DEAL_BY_ID_SUCCESS: `@${MODULE}/GET_DEAL_BY_ID_${httpCallStatus.SUCCESS}`,
};
