import React from 'react';

import { Container } from './styles';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IChip {
  type?: string;
  fontSize?: string;
  borderRadius?: string | number;
  children: JSX.Element | React.ReactNode;
}

const Chip = ({
  type = 'gray',
  fontSize = '12px',
  borderRadius = '20px',
  children,
}: IChip) => {
  return (
    <Container type={type} fontSize={fontSize} borderRadius={borderRadius}>
      {children}
    </Container>
  );
};

export default Chip;
