import React, { useEffect, useState } from 'react';
import useGetUsersAndHours from 'modules/delivery/DeliveryKanban/hooks/useGetUsersAndHours';
import SchenduleComponent from './SchedulingComponent';

const SchedulingContainer = ({
  date = null,
  proposalSelected,
  refetchData,
}) => {
  const dateFormated = new Date(date).getTime();

  const { data, refetch, isFetching } = useGetUsersAndHours({
    date: dateFormated,
  });

  const [dataTable, setDataTable] = useState<any[]>([]);
  const [greenCells, setGreenCells] = useState<any[]>([]);
  const [selectedCells, setSelectedCells] = useState<any[]>([]);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [thereIsScheduling, setThereIsScheduling] = useState(false);

  const generateTimeSlots = () => {
    const startTime = new Date();
    startTime.setHours(8, 0, 0);
    const endTime = new Date();
    endTime.setHours(18, 3, 0);

    const timeSlots = [];

    while (startTime < endTime) {
      timeSlots.push(new Date(startTime));
      startTime.setMinutes(startTime.getMinutes() + 30);
    }

    return timeSlots;
  };

  const timeSlots = generateTimeSlots();

  const createSelectedCell = (rowIndex, columnIndex, sellerName, sellerId) => {
    const startTime = timeSlots[columnIndex];
    const newEndTime = new Date(startTime.getTime() + 30 * 60000);
    return {
      row: rowIndex,
      col: columnIndex,
      seller: sellerName,
      sellerId,
      startInterval: startTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
      endInterval: newEndTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    };
  };

  const getSellerName = rowIndex => {
    return dataTable?.[rowIndex]?.userName || '';
  };

  const getSellerId = rowIndex => {
    return dataTable?.[rowIndex]?.userId || '';
  };

  const thereIsSchedulingVerify = () => {
    const allIntervals = [];

    dataTable.forEach(item => {
      if (item.intervals && Array.isArray(item.intervals)) {
        allIntervals.push(...item.intervals);
      }
    });
    setThereIsScheduling(
      typeof proposalSelected?.schedule?.id != 'undefined' &&
        allIntervals.find(
          el => el?.scheduleId === proposalSelected?.schedule?.id
        )
        ? true
        : false
    );
  };

  const handleMouseEnter = (rowIndex, columnIndex) => {
    if (thereIsScheduling) {
      return;
    }
    const updatedGreenCells = greenCells.filter(cell => cell.row === rowIndex);
    updatedGreenCells.push({ row: rowIndex, col: columnIndex });
    setGreenCells(updatedGreenCells);
  };

  const translateUserType = userType => {
    if (userType === 'SELLER') {
      return 'Vendedor';
    } else {
      return 'Entregador Técnico';
    }
  };

  const handleCellClick = rowIndex => {
    if (thereIsScheduling) {
      return;
    }
    setSelectedCells([]);
    const newSelectedCells = greenCells
      .filter(cell => cell.row === rowIndex)
      .map(cell =>
        createSelectedCell(
          cell.row,
          cell.col,
          getSellerName(cell.row),
          getSellerId(cell.row)
        )
      )
      .sort((cellA, cellB) => {
        // Ordenar por coluna
        if (cellA.col < cellB.col) {
          return -1;
        }
        if (cellA.col > cellB.col) {
          return 1;
        }
        return 0;
      });

    setSelectedCells(newSelectedCells);
    setOpenModalConfirmation(true);
  };

  const handleCloseModalConfirmation = () => {
    refetchData();
    setOpenModalConfirmation(false);
    setSelectedCells([]);
  };

  const handleRowMouseLeave = () => {
    setGreenCells([]);
  };

  useEffect(() => {
    thereIsSchedulingVerify();
  }, [dataTable]);

  useEffect(() => {
    refetch();
  }, [dateFormated]);
  useEffect(() => {
    if (data && Array.isArray(data)) {
      setDataTable(data);
    }
  }, [data]);

  return (
    <SchenduleComponent
      date={date}
      dataTable={dataTable}
      proposalSelected={proposalSelected}
      timeSlots={timeSlots}
      handleRowMouseLeave={handleRowMouseLeave}
      translateUserType={translateUserType}
      handleMouseEnter={handleMouseEnter}
      handleCellClick={handleCellClick}
      greenCells={greenCells}
      selectedCells={selectedCells}
      thereIsScheduling={thereIsScheduling}
      openModalConfirmation={openModalConfirmation}
      handleCloseModalConfirmation={handleCloseModalConfirmation}
      isFetching={isFetching}
    />
  );
};

export default SchedulingContainer;
