import { useQuery } from 'react-query';
import useSnackBar from 'hooks/useSnackBar';
import ProposalServices from 'services/proposalServices';

function useGetProposalStatus({
  draggableItemId,
  isCallCenter,
  departmentId,
  isOem,
  currentProposalId,
}) {
  const { handleSnackBarError } = useSnackBar();

  return useQuery({
    queryKey: 'get_proposal_status',
    queryFn: () =>
      ProposalServices.getProposalStatus(
        draggableItemId,
        isCallCenter,
        departmentId,
        isOem,
        currentProposalId
      ),
    select: (data: any) => {
      return data;
    },
    refetchOnWindowFocus: false,
    onError() {
      handleSnackBarError('Ocorreu um erro');
    },
  });
}

export default useGetProposalStatus;
