import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 24px;
  align-self: flex-end;
`;

export const OutlinedButton = styled(Button)`
  background: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  color: #424242 !important;
  text-transform: inherit !important;
`;
