import React from 'react';
import { useHistory } from 'react-router-dom';
import routesPath from 'config/routesPath';
import ChatHeader from 'pages/chats/components/Header';
import { useChatContext } from 'pages/chats/ChatsContext';
import ProposalErrorPage from 'pages/chats/components/ProposalErrorPage';
import Page from 'components/Page';
import OrderStepper from 'modules/order/components/atoms/OrderStepper';

const OrderStepsComponent = ({
  currentStepComponent,
  handleOnClickClose,
  onModal,
}) => {
  const history = useHistory();
  const { otherStates, currentProposal }: any = useChatContext();

  const handleOnClickBack = () => {
    history.push(routesPath.getRecursivePath(routesPath.proposals));
  };

  return (
    <Page withoutPadding>
      <ChatHeader
        error={otherStates.proposalError}
        currentProposal={currentProposal}
        handleOnClickBack={handleOnClickBack}
        onModal={onModal}
        handleOnClickClose={handleOnClickClose}
        extraComponent={
          !otherStates.proposalError && (
            <div style={{ width: '40%' }}>
              <OrderStepper />
            </div>
          )
        }
      />
      {otherStates.proposalError ? <ProposalErrorPage /> : currentStepComponent}
    </Page>
  );
};

export default OrderStepsComponent;
