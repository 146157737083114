import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import InventoryService from 'services/inventoryServices';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import DealFormActions from '../dealForm/actionCreators';
import { Action } from 'types/ActionTypes';
import { Select } from 'types/SelectType';
import UploadServices from 'services/uploadServices';
import DealServices from 'modules/resale/services/DealServices';
import isEmpty from 'helpers/isEmpty';
import { DealRepasse } from 'types/DealTypes';
import DealsActions from '../deals/actionCreators';
import findReportIndexByConditionalId from 'helpers/findReportIndexByConditionalId';
import VEHICLE_TYPE from 'constants/vehicleTypes';
import routesPath from 'config/routesPath';
import { FUEL_FLEX_ID } from 'constants/fuelFlexId';

function* getFuel(action: Action) {
  const fuels = yield select(state => state.dealForm.fuels);

  if (action.payload.fuel2Id || action.payload.fuel2Name) {
    const element = fuels.find((fuel: Select) => fuel.name === 'Flex');
    yield put(ActionCreators.setDealFuel(element?.id || 0));
  } else {
    const element = fuels.find(
      (fuel: Select) => fuel.name === action.payload.fuelId
    );

    yield put(ActionCreators.setDealFuel(element?.id || 0));
  }
}

function* getTransmission(action: Action) {
  const transmissions = yield select(state => state.dealForm.transmissions);

  const transmission = transmissions.find(
    (item: Select) => item.name === action.payload.transmission
  );
  if (!isEmpty(transmission)) {
    yield put(ActionCreators.setDealTransmission((transmission || {}).id));
  }
}

function getFeatures(features: Select[]): (number | undefined)[] {
  return features.map((feature: Select): number | undefined => feature.id);
}

export function* getVehicleInfoByPlate(action: Action) {
  const vehicleTypeFromState = yield select(
    state => state.orderDeal.vehicleType
  );
  try {
    yield put(ActionCreators.searchingPlate());
    const response = yield InventoryService.getVehicleByPlate(
      action.payload.dealerId,
      action.payload.plate
    );
    if (response.success) {
      const { modelYear, productionYear, trim, deal0km, vin } = response.data;
      const vehicleType = trim?.vehicleType ?? vehicleTypeFromState;

      let fuelId = 0;

      if (trim?.fuel2Id && trim?.fuelId) {
        fuelId = FUEL_FLEX_ID;
      } else {
        fuelId = trim?.fuelId;
      }

      const deal = {
        modelYear,
        productionYear,
        deal0km,
        trimId: trim?.id,
        trimName: trim?.name,
        plate: action.payload.plate,
        fuelId,
        vin,
        ...trim,
      };

      if (trim?.features) {
        deal.featureIds = getFeatures(trim.features);
      }

      //DELETE_DEAL_ID_BECAUSE_THIS_ID_IS_TRIM_ID_NOT_REALLY_DEAL_ID
      delete deal.id;

      yield put(DealFormActions.getJustMakes(vehicleType));
      yield put(DealFormActions.getJustModels(vehicleType, deal.makeName));
      yield put(
        DealFormActions.getJustProdutionYearsAndModelYears(
          vehicleType,
          deal.modelId,
          deal.productionYear
        )
      );
      yield put(
        DealFormActions.getJustTrims(vehicleType, deal.modelId, deal.modelYear)
      );
      yield put(ActionCreators.searchTransmissions(trim));
      yield put(ActionCreators.searchFuels(trim));
      yield put(ActionCreators.setVehicleInfosByPlate({ ...deal, fuelId }));
      yield put(
        DealFormActions.getFipePrice(deal.trimId, deal.modelYear, deal.deal0km)
      );
    } else {
      yield put(ActionCreators.searchingPlateError(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.searchingPlateError(e));
    console.log(e);
  }
}

function* uploadDealReport(action: Action) {
  try {
    yield put(ActionCreators.setDealReportUrlUploading());
    const response = yield UploadServices.uploadDealReport(
      action.payload.file,
      action.payload.callback
    );
    if (response.success) {
      yield put(ActionCreators.setDealReportId(response.data));
      return;
    }
  } catch (e) {
    console.log(e);
  }
}

function* uploadDealImage(image: File) {
  const imageOrigin = 'DEAL';
  try {
    const response = yield UploadServices.uploadDealImage(image, imageOrigin);
    if (response.success) {
      yield put(ActionCreators.setDealImageUploadFinished(response.data));
      return;
    } else {
      yield put(ActionCreators.setDealImageUploadError(response.data));
    }
  } catch (e) {
    yield put(ActionCreators.setDealImageUploadError(e));
  }
}

function* uploadImages(action: Action) {
  yield put(ActionCreators.setDealImageUploading());
  const images = action.payload;
  yield all(images.map((image: File) => call(uploadDealImage, image)));
  yield put(ActionCreators.setDealImageUploadUploaded());
}

function* uploadReportImage(action: Action) {
  const imageOrigin = 'DEAL';
  try {
    yield put(
      ActionCreators.setImageReportUploading(action.payload.conditionalId, true)
    );
    const response = yield UploadServices.uploadDealImage(
      action.payload.file,
      imageOrigin,
      action.payload.callbackOnProgress
    );
    if (response.success) {
      yield put(
        ActionCreators.reportImageUploaded(
          action.payload.conditionalId,
          response.data.id
        )
      );
      return;
    } else {
      yield put(
        ActionCreators.reportImageUploadedError(
          action.payload.conditionalId,
          response.problem
        )
      );
    }
  } catch (e) {
    yield put(
      ActionCreators.reportImageUploadedError(
        action.payload.conditionalId,
        e as Error
      )
    );
  }
}

const createDealObject = ({
  id,
  armorType,
  armored,
  deal0km,
  colorId,
  resaleComments,
  doors,
  featureIds,
  fuelId,
  images,
  km,
  makeId,
  makeName,
  modelId,
  modelName,
  modelYear,
  plate,
  resalePrice,
  minimumBidIncrement,
  minimumPriceProposalResale,
  buyNowPrice,
  productionYear,
  transmissionId,
  trimId,
  trimName,
  reports,
  reportId,
  reportUrl,
  reportStatus,
  tradein,
  ownerName,
  ownerEmail,
  ownerPhone,
  ownerCPF,
  vin,
  renavam,
  manufacturingPackageCode,
  vehicleType,
  youtubeId,
  publishDate,
  expirationDate,
  dealerPJLocality,
  auction,
  casualty,
}: DealRepasse) => {
  const door = vehicleType !== VEHICLE_TYPE[1].label ? doors : undefined;
  return {
    id,
    armorType,
    armored,
    deal0km,
    colorId,
    resaleComments,
    doors: door,
    featureIds,
    fuelId,
    images,
    km,
    makeId,
    makeName,
    modelId,
    modelName,
    modelYear,
    plate,
    resalePrice,
    minimumBidIncrement,
    minimumPriceProposalResale,
    buyNowPrice,
    productionYear,
    transmissionId,
    trimId,
    trimName,
    reports,
    reportId,
    reportUrl,
    reportStatus,
    tradein,
    ownerName,
    ownerEmail,
    ownerPhone,
    ownerCPF,
    vin,
    renavam,
    manufacturingPackageCode,
    youtubeId,
    publishDate,
    expirationDate,
    dealerPJLocality: {
      id: dealerPJLocality?.id,
    },
    auction,
    casualty,
  };
};
const updateOrCreateDeal = (dealerId: number, deal: DealRepasse): any => {
  const objToSend = createDealObject({ ...deal });

  if (deal.id) {
    return DealServices.updateDeal(dealerId, objToSend);
  }
  return DealServices.createDeal(dealerId, objToSend);
};

function* saveDeal(action: Action) {
  const deal = yield select((state): DealRepasse => state.orderDeal);
  yield put(ActionCreators.setDealUpdating());
  try {
    const response = yield updateOrCreateDeal(action.payload.dealerId, deal);
    if (response.success) {
      if (deal.id) {
        yield put(
          ActionCreators.setDealSavedSuccess(deal, action.payload.history)
        );
      } else {
        yield put(
          ActionCreators.setDealSavedSuccess(
            { ...deal, id: response.data.id },
            action.payload.history
          )
        );
      }
    } else {
      yield put(ActionCreators.setDealSavedError(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.setDealSavedError(e));
  }
}

export function* getDeal(action: Action) {
  try {
    yield put(ActionCreators.setDealLoading());
    yield put(DealFormActions.getJustVehicleTypes());
    const response = yield DealServices.getDealById(
      action.payload.dealerId,
      action.payload.dealId
    );
    if (response.success) {
      const deal = response.data;

      yield put(ActionCreators.setDeal(deal));
      yield put(ActionCreators.setDealJustVehicleType(deal.vehicleType));
      yield put(DealFormActions.getJustMakes(deal.vehicleType));
      yield put(DealFormActions.getJustModels(deal.vehicleType, deal.makeName));
      yield put(
        DealFormActions.getJustProdutionYearsAndModelYears(
          deal.vehicleType,
          deal.modelId,
          deal.productionYear
        )
      );
      yield put(
        DealFormActions.getJustTrims(
          deal.vehicleType,
          deal.modelId,
          deal.modelYear
        )
      );
      yield put(
        DealFormActions.getFipePrice(deal.trimId, deal.modelYear, deal.deal0km)
      );
      yield put(ActionCreators.setDealLoaded());
    } else {
      yield put(ActionCreators.setDealErrorLoading(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.setDealErrorLoading(e as Error));
  }
}

export function* saveDealEffects(action: Action) {
  try {
    yield put(DealsActions.add(action.payload.deal));
    action.payload.history.push(
      routesPath.getRecursivePath(routesPath.resaleStock)
    );
  } catch (e) {
    console.log(e);
  }
}

export function* updatedDealEffects(action: Action) {
  try {
    yield put(DealsActions.update(action.payload));
    action.payload.history.push(
      routesPath.getRecursivePath(routesPath.resaleStock)
    );
  } catch (e) {
    console.log(e);
  }
}

export function* setVehicleTypes() {
  yield put(DealFormActions.getMakes());
  yield put(DealFormActions.getFeatures());
}

export function* setTrimEffects(action: Action) {
  try {
    const { trims, modelYear, deal0km } = yield select(state => ({
      trims: state.dealForm.trims,
      modelYear: state.orderDeal.modelYear,
      deal0km: state.orderDeal.deal0km,
    }));
    if (isEmpty(trims)) return;
    const trim = trims.find((trim: any) => trim.id === action.payload.trimId);
    yield put(
      DealFormActions.getFipePrice(action.payload.trimId, modelYear, deal0km)
    );
    yield put(ActionCreators.searchTransmissions(trim));
    yield put(ActionCreators.searchFuels(trim));
    yield put(ActionCreators.setDealDoor(trim.doors));
  } catch (e) {
    console.log(e);
  }
}

export function* validationReports(action: Action) {
  const { reports, conditionals } = yield select(state => ({
    reports: state.orderDeal.reports,
    conditionals: state.dealForm.conditionals,
  }));

  const reportIndex = findReportIndexByConditionalId(
    reports,
    action.payload.id
  );
  const conditional = conditionals.find(
    (conditional: any) => conditional.id === action.payload.id
  );
  if (!conditional) {
    throw new Error('This should not happen');
  }
  if (reportIndex === -1) {
    reports.push({
      conditionalId: action.payload.id,
      value: action.payload.value,
    });
    yield put(ActionCreators.setReports(reports));
    return;
  }

  const valueOption = conditional.options.find(
    (option: any) => action.payload.value === option.name
  );
  if (!valueOption) {
    throw new Error('This should not happen');
  }
  if (!valueOption.requireComplement) {
    reports[reportIndex] = {
      conditionalId: action.payload.id,
      value: action.payload.value,
    };
  } else {
    reports[reportIndex] = {
      ...reports[reportIndex],
      conditionalId: action.payload.id,
      value: action.payload.value,
    };
  }
  yield put(ActionCreators.setReports(reports));
}

export default all([
  takeLatest(ActionTypes.GET_VEHICLE_INFO_BY_PLATE, getVehicleInfoByPlate),
  takeLatest(ActionTypes.SEARCH_FUELS, getFuel),
  takeLatest(ActionTypes.SEARCH_TRANSMISSIONS, getTransmission),
  takeLatest(ActionTypes.SET_IMAGE_UPLOAD, uploadDealImage),
  takeLatest(ActionTypes.SET_REPORT_UPLOAD, uploadDealReport),
  takeLatest(ActionTypes.SAVE_DEAL, saveDeal),
  takeLatest(ActionTypes.GET_DEAL, getDeal),
  takeLatest(ActionTypes.SAVE_DEAL_SUCCESS, saveDealEffects),
  takeLatest(ActionTypes.UPDATED_DEAL_SUCCESS, updatedDealEffects),
  takeLatest(ActionTypes.SET_TRIM, setTrimEffects),
  takeLatest(ActionTypes.SET_DEAL_IMAGES_UPLOADING, uploadImages),
  takeLatest(ActionTypes.UPLOAD_REPORT_IMAGE, uploadReportImage),
  takeLatest(ActionTypes.SET_FEATURES_CONDITIONAL_VALUE, validationReports),
  takeLatest(ActionTypes.SET_VEHICLE_TYPE, setVehicleTypes),
]);
