import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const TitleText = styled.div`
  font-family: 'Poppins Bold';
  font-weight: bold;
  line-height: 48px;
  color: ${Colors.gray.dark};
  height: 100px;
`;

export const EmptyDealerContainer = styled.div`
  width: 100%;
  height: 200;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyDealerText = styled.div`
  width: 267;
  height: 48;
  color: #757575;
  text-align: center;
  font-size: 14;
  line-height: 24px;
`;
