import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import DealerServices from 'services/dealerServices';
import BookVehicleServices from 'services/bookVehicleServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import DealCard from 'modules/financing/components/atoms/DealListCard';
import DateTimeHightLight from 'components/atoms/DateTimeHighLight/DateTimeHighLight';
import { useChatContext } from 'pages/chats/ChatsContext';
import {
  BookDivider,
  BookInfo,
  DefaultButton,
  SubTitle,
  MiniLoading,
  DaysToExpireNotFoundInfo,
  LoadingDealer,
  Line,
} from './styles';

function BookVehicleDialog({ open, onClose, currentVehicle }): any {
  const { currentDealer } = useSelector(state => state.dealerStore);
  const { currentProposal }: any = useChatContext();

  const dispatch = useDispatch();

  const [isBooking, setIsBooking] = useState(false);
  const [dateToBookVehicle, setDateToBookVehicle] = useState<
    moment.Moment | undefined
  >(undefined);
  const [loadingDealer, setLoadingDealer] = useState(false);

  const getCurrentDealer = async () => {
    if (currentDealer.daysToBookVehicle) {
      return currentDealer;
    } else {
      setLoadingDealer(true);
      const result: any = await DealerServices.getCadastro(
        currentProposal.dealerId
      );
      setLoadingDealer(false);
      return result?.data;
    }
  };

  const getDealerDaysToBookVehicle = async () => {
    const currentDealer = await getCurrentDealer();
    if (currentDealer?.daysToBookVehicle)
      setDateToBookVehicle(
        moment().add(currentDealer.daysToBookVehicle, 'days')
      );
  };

  useEffect(() => {
    if (open) getDealerDaysToBookVehicle();
  }, [open]);

  const onFinish = () => {
    onClose(currentVehicle);
  };

  const createBook = () => {
    setIsBooking(true);
    const { dealerId, id } = currentProposal;
    BookVehicleServices.bookVehicle(dealerId, id, currentVehicle.id)
      .then(() => {
        setIsBooking(false);
        onFinish();
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Veículo reservado com sucesso',
            true
          )
        );
      })
      .catch(() => {
        setIsBooking(false);
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Não foi possível reservar o veículo',
            true
          )
        );
      });
  };

  const DialogAction = () => (
    <Line>
      <OutlinedButton onClick={onClose}>Voltar</OutlinedButton>
      <div style={{ width: 8 }} />
      <ThemeButton onClick={createBook} disabled={isBooking}>
        Confirmar reserva
        {isBooking && <MiniLoading size={20} />}
      </ThemeButton>
    </Line>
  );

  if (loadingDealer) {
    return (
      <GenericDialog
        open={open}
        onClose={onClose}
        title="Confirmar reserva do veículo"
        aria-labelledby="Confirmar reserva do veículo"
      >
        <LoadingDealer>
          <CircularProgress />
          <span>Carregando dados da loja...</span>
        </LoadingDealer>
      </GenericDialog>
    );
  }

  if (!dateToBookVehicle) {
    return (
      <GenericDialog
        open={open}
        onClose={onClose}
        title="Confirmar reserva do veículo"
        aria-labelledby="Confirmar reserva do veículo"
      >
        <DaysToExpireNotFoundInfo>
          <span>
            A sua loja precisa ter cadastrado <b>Dias para expirar a reserva</b>{' '}
            para continuar com a reserva. Entre em contato com o administrador
            da loja.
          </span>
          <DefaultButton color="default" variant="outlined" onClick={onClose}>
            Fechar
          </DefaultButton>
        </DaysToExpireNotFoundInfo>
      </GenericDialog>
    );
  }

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Confirmar reserva do veículo"
      aria-labelledby="Confirmar reserva do veículo"
      DialogAction={DialogAction}
    >
      <SubTitle>Veículo</SubTitle>
      <DealCard deal={currentVehicle} />
      <BookDivider />
      <SubTitle>Reserva</SubTitle>
      <BookInfo>
        Este veículo ficará reservado até o dia &nbsp;
        <DateTimeHightLight date={dateToBookVehicle} />
      </BookInfo>
    </GenericDialog>
  );
}

export default BookVehicleDialog;
