export const MODULE = 'order';

export default {
  RESET_STORE: `@${MODULE}/RESET_STORE`,
  //SET_DEALER_ID_PROPOSAL_ID_
  SET_DEALER_ID_PROPOSAL_ID: `@${MODULE}/SET_DEALER_ID_PROPOSAL_ID`,
  GET_PROPOSAL_ORDER: `@${MODULE}/GET_PROPOSAL_ORDER`,
  SET_LOADING_ORDER: `@${MODULE}/SET_LOADING_ORDER`,
  //PROPOSAL_DATA
  SET_CURRENT_VEHICLE: `@${MODULE}/SET_CURRENT_VEHICLE`,
  RESET_CURRENT_VEHICLE: `@${MODULE}/RESET_CURRENT_VEHICLE`,
  SET_PAYMENT_DATA: `@${MODULE}/SET_PAYMENT_DATA`,
  SET_SALE_COMMENTS: `@${MODULE}/SET_SALE_COMMENTS`,
  //BANKS_INSTITUTION_OPTIONS
  GET_ALL_FINANCIAL_INSTITUTION_OPTIONS: `@${MODULE}/GET_ALL_FINANCIAL_INSTITUTION_OPTIONS`,
  SET_ALL_FINANCIAL_INSTITUTION_OPTIONS: `@${MODULE}/SET_ALL_FINANCIAL_INSTITUTION_OPTIONS`,
  //ORDER
  GET_ORDER_BY_ID: `@${MODULE}/GET_ORDER_BY_ID`,
  SET_CURRENT_ORDER: `@${MODULE}/SET_CURRENT_ORDER`,
  SET_CURRENT_BUYER: `@${MODULE}/SET_CURRENT_BUYER`,
  FINISH_ORDER: `@${MODULE}/FINISH_ORDER`,
  SET_CHECKOUT_ORDER: `@${MODULE}/SET_CHECKOUT_ORDER`,
  //STEPS
  SET_ACTIVE_STEP: `@${MODULE}/SET_ACTIVE_STEP`,
  SET_COMPLETED_STEPS: `@${MODULE}/SET_COMPLETED_STEP`,
  REMOVE_COMPLETED_STEP: `@${MODULE}/REMOVE_COMPLETED_STEP`,
  SET_PROPOSAL_STEP: `@${MODULE}/SET_PROPOSAL_STEP`,
  //CHECKOUT
  GET_ORDER_STATUS: `@${MODULE}/GET_ORDER_STATUS`,
  SET_ORDER_STATUS: `@${MODULE}/SET_ORDER_STATUS`,
  SET_LOADING_ORDER_STATUS: `@${MODULE}/SET_LOADING_ORDER_STATUS`,
  APPROVE_PROPOSAL: `@${MODULE}/APPROVE_PROPOSAL`,
  SET_APPROVE_IS_LOADING: `@${MODULE}/SET_APPROVE_IS_LOADING`,
  SET_CONFETTI_RUN: `@${MODULE}/SET_CONFETTI_RUN`,
  //CHECKOUT PROPOSAL DIGITAL
  SEND_PROPOSAL_DIGITAL: `@${MODULE}/SEND_PROPOSAL_DIGITAL`,
  SET_PROPOSAL_DIGITAL: `@${MODULE}/SET_PROPOSAL_DIGITAL`,
  GET_PROPOSAL_DIGITAL: `@${MODULE}/GET_PRPOSAL_DIGITAL`,
  //CHECKOUT_PENDENCIES
  CHANGE_PENDENCIES_INFOS_BY_ID: `@${MODULE}/CHANGE_PENDENCIES_INFOS_BY_ID`,
  //BUYER_DATA
  CHANGE_BUYER_CURRENT_DATA: `@${MODULE}/CHANGE_BUYER_CURRENT_DATA`,
  SEND_BUYER_FORM: `@${MODULE}/SEND_BUYER_FORM`,
  //QUEUE
  GET_QUEUE_BUY_ID: `@${MODULE}/GET_QUEUE_BUY_ID`,
  SET_QUEUE_DATA: `@${MODULE}/SET_QUEUE_DATA`,
  SET_DELIVERY_LIST: `@${MODULE}/SET_DELIVERY_LIST`,
  //CANCEL_INVOICE
  GET_CANCEL_INVOICE: `@${MODULE}/GET_CANCEL_INVOICE`,
  SEND_CANCEL_INVOICE: `@${MODULE}/SEND_CANCEL_INVOICE`,
  SET_CANCEL_INVOICE: `@${MODULE}/SET_CANCEL_INVOICE`,
};
