import EnvVars from 'helpers/envVars';

const domainImages = EnvVars('API_IMAGES_BASE_URL');

const getTransformationInfo = (quality, width, height) => {
  let transformationInfo = '';
  if (!!quality) {
    transformationInfo += `q_${quality},`;
  }
  if (!!width) {
    transformationInfo += `w_${width},`;
  }
  if (!!height) {
    transformationInfo += `h_${height},`;
  }
  return transformationInfo;
};

export default class ImageService {
  static getImage = async (
    image,
    quality = '100',
    width = null,
    height = null
  ) => {
    const transformationInfo = getTransformationInfo(quality, width, height);

    return `${domainImages}/images/v1.0/${image}/transform/${transformationInfo}`;
  };
}
