import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import TextField from 'modules/financing/components/atoms/TextField';

const DatePickerComponent = ({
  label,
  disabled = false,
  value,
  onChange,
  mini = true,
  onBlur = () => null,
  error = null,
  format = 'DD/MM/YYYY',
  helperText = null,
  ...rest
}) => {
  const [state, setState] = useState({ open: false });

  return (
    <>
      <TextField
        label={label}
        value={value ? moment(value).format(format) : ''}
        onChange={() => null}
        onClick={() => {
          setState({ open: true });
        }}
        mini={mini}
        disabled={disabled}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
      />
      <div style={{ display: 'none' }}>
        <DatePicker
          open={state.open}
          onClose={() => setState({ open: false })}
          inputVariant="outlined"
          value={value}
          onChange={onChange}
          invalidDateMessage="Data inválida"
          format={format}
          {...rest}
        />
      </div>
    </>
  );
};

export default DatePickerComponent;
