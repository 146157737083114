//TO EXPORT TO EXTERNAL MAIN REDUCERS MODULE
import { all } from 'redux-saga/effects';

import postingsReducer from './postings/reducers';
import categoriesReducer from './categories/reducers';
import tempDealerReducer from './tempDealer/reducers';

import postingsSaga from './postings/sagas';
import categoriesSaga from './categories/sagas';
import tempDealerSaga from './tempDealer/sagas';

export const financialReducers = { 
        postingsReducer,
        categoriesReducer,
        tempDealerReducer
    };

export const financialSagas = all([
        postingsSaga,
        categoriesSaga,
        tempDealerSaga
    ]);



