import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import PerformanceService from 'services/performanceServices';

const _defaultInitialState = {
  performanceData: [],
  performanceByCarData: [],
  periodInit: null,
  periodFinal: null,
};

class PerformanceStore {
  constructor(initialState) {
    this.setInitialState(initialState || _defaultInitialState);
  }

  setInitialState = initialState => {
    const {
      performanceData,
      performanceByCarData,
      periodInit,
      periodFinal,
    } = initialState;

    this.performanceData = performanceData;
    this.performanceByCarData = performanceByCarData;
    this.periodInit = periodInit;
    this.periodFinal = periodFinal;
  };
  setDates = (periodInit = null, periodFinal = null) => {
    this.periodInit = periodInit;
    this.periodFinal = periodFinal;
  };
  setPerformanceData = performanceData => {
    this.performanceData = performanceData;
  };
  setPerformanceByCarData = performanceByCarData => {
    this.performanceByCarData = performanceByCarData;
  };

  fetchPerformanceData = async (dealerId, options = {}) => {
    const responseFromApi = await PerformanceService.getReport(dealerId, {
      ...options,
      periodInit: (options || {}).periodInit || null,
      periodFinal: (options || {}).periodFinal || null,
    });
    this.setDates(options.periodInit || null, options.periodFinal || null);

    const { data, success } = responseFromApi;

    if (success) {
      this.setPerformanceData(data);
    } else {
      this.setPerformanceData(null);
    }
  };

  fetchPerformanceByCar = async (
    dealerId,
    page = 0,
    size = 20,
    options = {}
  ) => {
    const responseFromApi = await PerformanceService.getReportByCar(
      dealerId,
      page,
      size,
      {
        periodInit: (options || {}).periodInit || this.periodInit || null,
        periodFinal: (options || {}).periodFinal || this.periodFinal || null,
      }
    );

    const { data, success } = responseFromApi;

    if (success) {
      this.setPerformanceByCarData(data);
    } else {
      this.setPerformanceByCarData(null);
    }
  };
  emptyDates = () => {
    this.periodInit = null;
    this.periodFinal = null;
  };
}

export default remotedev(
  decorate(PerformanceStore, {
    performanceData: observable,
    performanceByCarData: observable,
    setPerformanceData: action,
    fetchPerformanceData: action,
    setInitialState: action,
    fetchPerformanceByCar: action,
    setPerformanceByCarData: action,
    periodInit: observable,
    periodFinal: observable,
    setDates: action,
    emptyDates: action,
  })
);
