import ActionTypes from './actionTypes';
import moment from 'moment';
import { Action } from 'types/ActionTypes';
import { LeadsState, ScheduleItemType } from 'types/LeadsTypes';
import {
  THIRTY_DAYS_AGO,
  TODAY_END_DAY,
  YESTERDAY_START_DAY,
} from 'helpers/predefinedDates';

export const defaultNonReceivedValue = null;

export const INITIAL_STATE: LeadsState = {
  // For security terms the standart vision is for sellers
  currentUserType: undefined,
  displayedVision: undefined,
  sellerId: undefined,
  sellerName: undefined,
  tabValue: 0,
  proposalsInfos: {
    totalNewers: defaultNonReceivedValue,
    totalInNegociation: defaultNonReceivedValue,
    totalSold: defaultNonReceivedValue,
    totalNotSold: defaultNonReceivedValue,
    visitScheduled: defaultNonReceivedValue,
  },
  proposalsInfosIsLoading: false,
  proposalsInfosIsError: false,
  convertion: [],
  convertionIsLoading: false,
  convertionIsError: false,
  sellers: [],
  sellersIsLoading: false,
  sellersIsError: false,
  providers: [],
  providersIsLoading: false,
  providersIsError: false,
  schedule: [],
  scheduleIsLoading: false,
  scheduleIsError: false,
  scheduleInfos: [],
  scheduleInfosIsLoading: false,
  initialAnnotationsIndex: 0,
  activities: [],
  activitiesIsLoading: false,
  activitiesIsError: false,
  filterByDates: {
    initialDate: moment(THIRTY_DAYS_AGO).valueOf(),
    finalDate: moment(TODAY_END_DAY).valueOf(),
  },
  filterByDatesQualified: {
    initialDate: moment(YESTERDAY_START_DAY).valueOf(),
    finalDate: moment(TODAY_END_DAY).valueOf(),
    periodId: 'yesterDayAndToday',
  },
  filterSalesAssistant: {
    salesAssistantId: null,
  },
  filterByDealerId: null,
  filterBySubGroups: null,
  filterTypeOfSale: '',
  channel: null,
  channelOptions: [],
  origin: null,
  originOptions: [],
  tags: null,
  departmentCode: null,
  departmentOptions: [],
  dealers: [],
  sellersOptions: [],
};

export default function lead(
  state: LeadsState = INITIAL_STATE,
  action: Action
): LeadsState {
  switch (action.type) {
    case 'RESET_ALL_STORE':
      return { ...INITIAL_STATE };
    case ActionTypes.SET_CURRENT_USER_TYPE:
      return { ...state, currentUserType: action.payload };
    case ActionTypes.SET_DISPLAYED_VISION:
      return {
        ...state,
        displayedVision: action.payload || state.currentUserType,
      };
    case ActionTypes.SET_SELLER_ID:
      return { ...state, sellerId: action.payload };
    case ActionTypes.SET_SELLER_NAME:
      return { ...state, sellerName: action.payload };
    case ActionTypes.CHANGE_TAB_VALUE:
      return { ...state, tabValue: action.payload };
    //CHANGE_FILTERS
    case ActionTypes.SET_DEPARTMENT_OPTIONS:
      return { ...state, departmentOptions: action.payload };
    case ActionTypes.SET_DEPARTMENT:
      return { ...state, departmentCode: action.payload };
    case ActionTypes.SET_CHANNEL:
      return { ...state, channel: [...action.payload] };
    case ActionTypes.SET_CHANNEL_OPTIONS:
      return { ...state, channelOptions: action.payload };
    case ActionTypes.SET_ORIGIN:
      return { ...state, origin: [...action.payload] };
    case ActionTypes.SET_TAGS:
      return { ...state, tags: action.payload };
    case ActionTypes.SET_DEALERS:
      return { ...state, dealers: [...action.payload] };
    case ActionTypes.SET_ORIGIN_OPTIONS:
      return { ...state, originOptions: action.payload };
    case ActionTypes.SET_SELLERS_OPTIONS:
      return { ...state, sellersOptions: action.payload };
    case ActionTypes.SET_FILTER_BY_DEALER_ID:
      return { ...state, filterByDealerId: action.payload };
    case ActionTypes.SET_FILTER_BY_SUB_GROUPS:
      return { ...state, filterBySubGroups: action.payload };

    //CHANGE_DATE_FILTERS
    case ActionTypes.SET_FILTER_DATE:
      return { ...state, filterByDates: action.payload };
    //CHANGE_DATE_FILTERS_CALL_CENTER_TAB_QUALIFIED
    case ActionTypes.SET_FILTER_DATE_QUALIFIED:
      return { ...state, filterByDatesQualified: action.payload };
    // CHANGE_SALES_ASSISTENT_FILTER
    case ActionTypes.SET_SALES_ASSISTANT_FILTER: {
      return {
        ...state,
        filterSalesAssistant: { salesAssistantId: action.payload },
      };
    }
    //CHANGE TYPE_OF_SALE
    case ActionTypes.SET_TYPE_OF_SALE_FILTER: {
      return { ...state, filterTypeOfSale: action.payload };
    }
    //PROPOSALS INFOS
    case ActionTypes.SET_PROPOSALS_INFOS_SUCCEDED:
      return { ...state, proposalsInfos: action.payload };
    case ActionTypes.SET_PROPOSALS_INFOS_ERROR:
      return { ...state, proposalsInfosIsError: action.payload };
    case ActionTypes.SET_PROPOSALS_INFOS_LOADING:
      return { ...state, proposalsInfosIsLoading: action.payload };
    //CONVERTION
    case ActionTypes.SET_CONVERTION_SUCCEDED:
      return { ...state, convertion: action.payload };
    case ActionTypes.SET_CONVERTION_ERROR:
      return { ...state, convertionIsError: action.payload };
    case ActionTypes.SET_CONVERTION_LOADING:
      return { ...state, convertionIsLoading: action.payload };
    //SELLERS PROPOSALS
    case ActionTypes.SET_SELLERS_PROPOSALS_SUCCEDED:
      return { ...state, sellers: action.payload };
    case ActionTypes.SET_SELLERS_PROPOSALS_ERROR:
      return { ...state, sellersIsError: action.payload };
    case ActionTypes.SET_SELLERS_PROPOSALS_LOADING:
      return { ...state, sellersIsLoading: action.payload };
    //PROPOSALS PROVIDER
    case ActionTypes.SET_PROVIDERS_PROPOSALS_SUCCEDED:
      return { ...state, providers: action.payload };
    case ActionTypes.SET_PROVIDERS_PROPOSALS_ERROR:
      return { ...state, providersIsError: action.payload };
    case ActionTypes.SET_PROVIDERS_PROPOSALS_LOADING:
      return { ...state, providersIsLoading: action.payload };
    //SELLER SCHEDULE
    case ActionTypes.SET_SELLER_SCHEDULE_SUCCEDED:
      return {
        ...state,
        schedule: action.payload.map(
          (scheduleItem: number) => new Date(scheduleItem)
        ),
      };
    case ActionTypes.SET_SELLER_SCHEDULE_ERROR:
      return {
        ...state,
        scheduleIsError: action.payload,
      };
    case ActionTypes.SET_SELLER_SCHEDULE_LOADING:
      return {
        ...state,
        scheduleIsLoading: action.payload,
      };
    //SELLER SCHEDULE INFOS
    case ActionTypes.GET_SELLER_SCHEDULE_INFOS_SUCCEDED:
      return {
        ...state,
        scheduleInfos: action.payload.map((scheduleItem: ScheduleItemType) => ({
          ...scheduleItem,
          reminderDate: new Date(scheduleItem.reminderDate),
        })),
      };
    case ActionTypes.SET_SELLER_SCHEDULE_INFOS_LOADING:
      return { ...state, scheduleInfosIsLoading: action.payload };
    //SELLER ANNOTATIONS
    case ActionTypes.SET_INITIAL_ANNOTATIONS_INDEX_SUCCEDED:
      return { ...state, initialAnnotationsIndex: action.payload };
    //SELLER ACTIVITIES
    case ActionTypes.SET_SELLER_ACTIVITIES_SUCCEDED:
      return { ...state, activities: action.payload };
    case ActionTypes.SET_SELLER_ACTIVITIES_ERROR:
      return { ...state, activitiesIsError: action.payload };
    case ActionTypes.SET_SELLER_ACTIVITIES_LOADING:
      return { ...state, activitiesIsLoading: action.payload };
    default:
      return state;
  }
}
