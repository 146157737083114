import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  padding: 0px 8px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  height: 40px;
  :hover {
    background: #e0e0e0;
  }
`;

export const FilterContainer = styled.div`
  padding: 16px 16px 0px 16px;
`;

export const EmptyContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
`;
