import { defaultSelectObject } from './defaultSelect';

export const status = { ACTIVE: 'Ativo', NON_ACTIVE: 'Inativo' };
export const statusType = [
  defaultSelectObject,
  { label: status.ACTIVE, value: true },
  { label: status.NON_ACTIVE, value: false },
];

export const positionType = [
  defaultSelectObject,
  { label: 'Gerente', value: 1 },
  { label: 'Vendedor', value: 2 },
  { label: 'Administrador', value: 3 },
];

export const userType = {
  OWNER: { name: 'OWNER', label: 'Proprietário' },
  SALES_MANAGER: { name: 'SALES_MANAGER', label: 'Gerente', chipColor: 'info' },
  RECEPTIONIST: { name: 'RECEPTIONIST', label: 'Recepcionista' },
  DIRECTOR: { name: 'DIRECTOR', label: 'Diretor' },
  EVALUATOR: { name: 'EVALUATOR', label: 'Avaliador' },
  SELLER: { name: 'SELLER', label: 'Vendedor', chipColor: 'success' },
  SALES_ASSISTENT: {
    name: 'SALES_ASSISTENT',
    label: 'Assistente de Vendas',
    chipColor: 'waiting',
  },
  MARKETING: { name: 'MARKETING', label: 'Marketing' },
  FINANCIAL: { name: 'FINANCIAL', label: 'Financeiro' },
  FINANCING: { name: 'FINANCING', label: 'Mesa de Financiamento' },
  API_INTEGRATION: { name: 'API_INTEGRATION', label: 'Integração API' },
  SALES_ASSISTANT_MANAGER: {
    name: 'SALES_ASSISTANT_MANAGER',
    label: 'Gestor de Assistentes de Vendas',
  },
  INVOICE_MANAGER: {
    name: 'INVOICE_MANAGER',
    label: 'Gestor de Faturamento',
  },
  INVOICE_ANALYST: {
    name: 'INVOICE_ANALYST',
    label: 'Analista de Faturamento',
  },
  AFTERSALES_ASSISTENT: {
    name: 'AFTERSALES_ASSISTENT',
    label: 'Operador de Agendamento',
  },
  AFTERSALES_CONSULTANT: {
    name: 'AFTERSALES_CONSULTANT',
    label: 'Consultor',
  },
  AFTERSALES_MECHANIC: {
    name: 'AFTERSALES_MECHANIC',
    label: 'Mecânico',
  },
};

export const highAccessUserTypes = [
  userType.OWNER.name,
  userType.SALES_MANAGER.name,
  userType.DIRECTOR.name,
];
