import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { Wrapper, ColorView, styles } from './styles'

const ColorInput = (props: { classes: { input: string }; value: string; onChange: (value: string) => void; border?: string }): JSX.Element => {
  const { classes, value, onChange, border } = props;
  return <Wrapper border={border}>
    <ColorView type="color" value={value} onChange={(event): any => onChange(event.target.value)} />
    <InputBase className={classes.input} value={value} onChange={(event): any => onChange(event.target.value)} />
  </Wrapper>
}

export default withStyles(styles)(ColorInput) 