import isEmpty from 'helpers/isEmpty';

export default function updateItemIntoContent(element, paginationObject) {
  if (isEmpty(paginationObject) || isEmpty((paginationObject || {}).content)) {
    return;
  }

  const pagination = JSON.parse(JSON.stringify(paginationObject));

  const index = pagination.content.findIndex(item => item.id === element.id); //find the element into content
  pagination.content.splice(index, 1); //remove the element into content
  pagination.content.splice(index, 0, element); //add new element into content
  return JSON.parse(JSON.stringify(pagination));
}
