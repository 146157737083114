import React from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';

type propTypes = Omit<TypographyProps, 'color'> &
  Partial<{
    fontSize: string;
    fontFamily: 'Poppins Regular' | 'Poppins Medium' | 'Poppins bold';
    fontWeight: number;
    color: string;
    cursor: string;
    extraStyle: object;
  }>;

const TextComponent = ({
  variant = 'body1',
  children,
  color = '#000',
  fontFamily = 'Poppins Regular',
  fontSize = '16px',
  cursor,
  fontWeight = 400,
  extraStyle = {},
  ...rest
}: propTypes) => {
  return (
    <Typography
      variant={variant}
      style={{
        color: color,
        fontSize: fontSize,
        fontWeight: fontWeight,
        fontFamily: fontFamily,
        cursor: cursor,
        margin: 0,
        padding: 0,
        ...extraStyle,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TextComponent;
