import React from 'react';

// Component import
import { ChatHeader } from 'pages/conversations/components';
import ChatComponent from 'pages/chats/components/Chat';

// Style import
import * as S from 'pages/conversations/styles';

export function Chat() {
  return (
    <S.ChatContainer>
      <ChatHeader />

      <S.ChatWrapper>
        <ChatComponent />
      </S.ChatWrapper>
    </S.ChatContainer>
  );
}
