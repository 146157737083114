import React from 'react';
import MODE from 'constants/editInLineTypes';

export default function EditInLine({
  mode = MODE.VISUALIZING,
  VisualizingComponent = null,
  EditingComponent = null,
  SavingComponent = null,
  SavedComponent = null,
  ErrorComponent = null,
  handleOnChangeMode = () => null,
  closedProposal = false,
}) {
  switch (mode) {
    case MODE.VISUALIZING:
      return (
        <VisualizingComponent
          key={MODE.VISUALIZING}
          handleOnChangeMode={handleOnChangeMode}
          closedProposal={closedProposal}
        />
      );
    case MODE.EDITING:
      return (
        <EditingComponent
          key={MODE.EDITING}
          handleOnChangeMode={handleOnChangeMode}
        />
      );
    case MODE.SAVING:
      return (
        <SavingComponent
          key={MODE.SAVING}
          handleOnChangeMode={handleOnChangeMode}
        />
      );

    case MODE.SAVED:
      return (
        <SavedComponent
          key={MODE.SAVED}
          handleOnChangeMode={handleOnChangeMode}
        />
      );
    case MODE.ERROR:
      return (
        <ErrorComponent
          key={MODE.ERROR}
          handleOnChangeMode={handleOnChangeMode}
        />
      );
    default:
      return null;
  }
}
