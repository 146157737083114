import React, { useEffect, useRef, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  OutlinedInput,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { Search as SearchIcon } from '@material-ui/icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';

// Context import
import {
  chatListOptions,
  fetchingLeads,
  leadsList,
  inactiveLeadsList,
} from 'pages/conversations/contexts/atoms';
import { selectedChatView } from 'pages/dashBoard/components/Container/contexts/atoms';
import ProposalsFilterShowroom from 'pages/proposals/tabs/Kanban/components/ProposalsFilter';
import ProposalsFilterCallCenter from 'pages/proposals/tabs/Kanban/components/ProposalsFilterCallCenter';

// Interface import
import { PeriodDate } from 'pages/conversations/interfaces';

// Util import
import { getLeads } from 'pages/conversations/utils';

// Hook import
import useSnackBar from 'hooks/useSnackBar';
import useDealerId from 'hooks/useDealerId';

// Style import
import { Flex } from '../styles';

type SearchProps = Partial<typeof OutlinedInput>;

export function Search({ ...rest }: SearchProps) {
  // States
  const [lastKeyPressed, setLastKeyPressed] = useState<string | null>(null);
  const setLeads = useSetRecoilState(leadsList);
  const setInactiveLeads = useSetRecoilState(inactiveLeadsList);
  const setFetchingLeads = useSetRecoilState(fetchingLeads);
  const [leadSearchValue, setLeadSearchValue] = useState('');
  const chatListOption = useRecoilValue(chatListOptions);
  const isCallCenter = useRecoilValue(selectedChatView) === 'CONVERSATIONS';

  // Refs
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  // Hooks
  const queryClient = useQueryClient();
  const { handleSnackBarError } = useSnackBar();
  const dealerId = useDealerId();
  const { filterByDates: defaultDate } = useSelector(state => state.leads);
  const filterByDates: PeriodDate = {
    initialDate: defaultDate.initialDate || new Date().getTime(),
    finalDate: defaultDate.finalDate || new Date().getTime(),
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
    setLastKeyPressed(event.key);

  const handleSearchLead = async (value: string) => {
    try {
      setFetchingLeads(true);

      const leads = await getLeads({
        dealerId,
        ...(value
          ? {
              // Por estar pesquisando, quero visualizar leads ativos e finalizados.
              search: value,
              getInactiveLeads: true,
              filterByActiveLead: true,
            }
          : {
              search: null,
              getInactiveLeads: chatListOption === 'INACTIVE_LEADS',
              filterByActiveLead: chatListOption === 'CONVERSATIONS',
            }),
        filterByDates,
        isCallCenter,
      });

      if (chatListOption === 'INACTIVE_LEADS') return setInactiveLeads(leads);

      setLeads(leads);
    } catch (error) {
      handleSnackBarError('Não foi possível encontrar este lead.');
    } finally {
      setFetchingLeads(false);
    }
  };

  useEffect(() => {
    const timeoutDebounce = setTimeout(() => {
      if (leadSearchValue || lastKeyPressed === 'Backspace')
        handleSearchLead(leadSearchValue);
    }, 1000);

    return () => clearTimeout(timeoutDebounce);
  }, [chatListOption, leadSearchValue, 1000]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      setLeadSearchValue('');
    }
  }, [chatListOption]);

  return (
    <Flex>
      {isCallCenter ? (
        <ProposalsFilterCallCenter
          getProposals={() => {
            queryClient.invalidateQueries({
              queryKey: ['get_leads'],
            });
          }}
          someUnreadMessages={null}
          setSomeUnreadMessages={null}
          tasks={[]}
          isSimple
          otherElements={
            <TextField
              type="text"
              placeholder="Busque por conversas, contatos..."
              variant="outlined"
              fullWidth
              size="small"
              onChange={({ target }) => setLeadSearchValue(target.value)}
              inputRef={searchInputRef}
              style={{ margin: '0 auto' }}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...rest}
            />
          }
        />
      ) : (
        <ProposalsFilterShowroom
          history={{}}
          filters={{}}
          tasks={[]}
          getProposals={() => {
            queryClient.invalidateQueries({
              queryKey: ['get_leads'],
            });
          }}
          handleChangeFilter={() => {
            return;
          }}
          someUnreadMessages={null}
          setSomeUnreadMessages={null}
          hasNewProposals={false}
          isSimple
          otherElements={
            <TextField
              type="text"
              placeholder="Busque por conversas, contatos..."
              variant="outlined"
              fullWidth
              size="small"
              onChange={({ target }) => setLeadSearchValue(target.value)}
              inputRef={searchInputRef}
              style={{ margin: '0 auto' }}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...rest}
            />
          }
        />
      )}
    </Flex>
  );
}
