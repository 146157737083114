export const defaultOptions = [{ value: null, label: 'Selecione...' }];

export const termOptions = [
  ...defaultOptions,
  { value: 12, label: '12x' },
  { value: 24, label: '24x' },
  { value: 36, label: '36x' },
  { value: 48, label: '48x' },
  { value: 60, label: '60x' },
];

export const escolarityTypeOptions = [
  ...defaultOptions,
  { value: 'SUPERIOR_INCOMPLETO', label: 'Superior Incompleto' },
  { value: 'MEDIO_COMPLETO', label: 'Ensino Médio Completo' },
  { value: 'MEDIO', label: 'Ensino Médio Incompleto' },
  { value: 'SUPERIOR', label: 'Superior' },
  { value: 'MESTRADO', label: 'Mestrado' },
  { value: 'DOUTORADO', label: 'Doutorado' },
  { value: 'POS_GRADUADO', label: 'Pós Graduado' },
  { value: 'FUNDAMENTAL', label: 'Fundamental' },
  { value: 'ANALFABETO', label: 'Analfabeto' },
];
