import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { NumberFormatValues } from 'react-number-format';
import { format as formatCnpj } from '@fnando/cnpj';
import { useDispatch, useSelector } from 'react-redux';
import formatAmount from 'helpers/formatAmount';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { UsedVehicle } from 'modules/order/types/UsedVehicle';
import { OrderProduct } from 'modules/order/types/OrderProduct';
import OrderServices from 'modules/order/services/OrderServices';
import AddPaymentDialog from './AddPaymentDialog';
import {
  InstallmentsType,
  InstallmentsTypeEnum,
  DealerPJFinancialInstitution,
  OrderPayment,
  OrderPaymentType,
} from 'modules/order/types/OrderPayment';

export type AddPaymentContainerProps = {
  editPayment?: OrderPayment;
  paymentTypes: OrderPaymentType[];
  nextPosition?: number;
  paymentList: OrderPayment[];
  installmentsTypes: InstallmentsType[];
  externalDealerId?: number;
  onClose: (payment?: OrderPayment) => void;
  open: boolean;
};

const AddPaymentDialogContainer = ({
  paymentList,
  nextPosition,
  paymentTypes,
  onClose,
  externalDealerId,
  installmentsTypes,
  editPayment,
  open,
}: AddPaymentContainerProps) => {
  const dispatch = useDispatch();
  const {
    dealerId,
    paymentData: { products, totalRemaining },
  } = useSelector(state => state.order);
  const { currentDealer } = useSelector(state => state.dealerStore);
  const paymentTotalTroco = paymentList
    .filter(payment => payment.paymentTypeInstallmentsType === 'TROCO')
    .filter(
      payment =>
        payment?.installmentDiscountItem?.position === editPayment?.position
    )
    .map(troco => troco.value)
    .reduce((a, b) => a + b, 0);
  const [paymentValue, setPaymentValue] = useState<number | undefined>();
  const [financingInstallments, setFinancingInstallments] = useState<
    number | undefined
  >();
  const [financingTerm, setFinancingTerm] = useState<string | undefined>('');
  const [financingValue, setFinancingValue] = useState<number | undefined>();
  const [paymentDate, setPaymentDate] = useState(moment().format('YYYY-MM-DD'));
  const [installmentsType, setInstallmentsType] = useState<
    InstallmentsType | undefined
  >(undefined);
  const [paymentType, setPaymentType] = useState<OrderPaymentType | undefined>(
    undefined
  );
  const [paymentToDiscount, setPaymentToDiscount] = useState<
    OrderPayment | undefined
  >(undefined);
  const [institution, setInstitution] = useState<
    DealerPJFinancialInstitution | undefined
  >(undefined);
  const [quota, setQuota] = useState<number | undefined>(undefined);
  const [group, setGroup] = useState<number | undefined>(undefined);
  const [usedVehicle, setUsedVehicle] = useState<UsedVehicle | undefined>(
    undefined
  );
  const [comments, setComments] = useState('');
  const [paymentDueDate, setPaymentDueDate] = useState<number | undefined>(
    moment().valueOf()
  );
  const [filteredPaymentTypes, setFilteredPaymentTypes] = useState<
    OrderPaymentType[]
  >([]);
  const [hasValueError, setHasValueError] = useState<boolean | null>(null);
  const [hasTrocoError, setHasTrocoError] = useState<boolean | null>(null);
  const [hasPaymentTypeError, setHasPaymentTypeError] = useState(false);
  const [loadingInstitutionList, setIsLoadingInstitutionList] = useState(false);
  const [institutionList, setInstitutionList] = useState<
    DealerPJFinancialInstitution[]
  >([]);
  const [totalFinancingProducts, setTotalFinancingProducts] = useState(0);

  const parcelToDiscountList = paymentList.filter(
    payment => payment.paymentType.shouldHaveDiscount
  );

  const today = moment().format('YYYY-MM-DD');

  const allowsMonetaryCustomChanges = Boolean(
    currentDealer?.allowsMonetaryChangeDeductAllValue
  );

  const isDiscountPayment = () => {
    return paymentType?.installmentsType === InstallmentsTypeEnum.TROCO;
  };
  const isConsorcio = () => {
    return paymentType?.installmentsType === InstallmentsTypeEnum.CONSORCIO;
  };
  const isUsedVehicle = () => {
    return paymentType?.installmentsType === InstallmentsTypeEnum.VEICULO_USADO;
  };
  const isFinanceira = () => {
    return paymentType?.installmentsType === InstallmentsTypeEnum.FINANCEIRA;
  };
  const isFinanciamento = () => {
    return paymentType?.installmentsType === InstallmentsTypeEnum.FINANCIAMENTO;
  };

  const useTotalReamining = () => {
    if (isFinanciamento()) {
      setFinancingValue(totalRemaining);
      setPaymentValue(totalRemaining + totalFinancingProducts);
      return;
    }
    setPaymentValue(totalRemaining);
  };

  const getPaymentTypeOption = (option: OrderPaymentType) => ({
    value: option.id,
    label: option.name,
    ...option,
  });

  const filterPaymentTypes = (installmentsTypeName?: InstallmentsTypeEnum) => {
    if (!installmentsTypeName) setFilteredPaymentTypes([]);

    const filteredPaymentTypes = paymentTypes
      .filter(type => type.installmentsType === installmentsTypeName)
      .map(getPaymentTypeOption);

    setFilteredPaymentTypes(filteredPaymentTypes);
  };

  const getInstallmentsTypeByName = (
    name: InstallmentsTypeEnum
  ): InstallmentsType | undefined => {
    return installmentsTypes.find(type => type.name === name);
  };
  const handleOnChangeDueDate = (newDate: Date) =>
    setPaymentDueDate(moment(newDate).valueOf());

  const handleOnChangeInstallmentsType = event => {
    const newInstallmentsType = getInstallmentsTypeByName(event.target?.value);
    setInstallmentsType(newInstallmentsType);

    setPaymentType(undefined);
    setFinancingTerm(null);
    setFinancingInstallments(undefined);
  };

  const handleOnChangePaymentType = value => {
    setPaymentType(value);

    if (value === InstallmentsTypeEnum.TROCO) setPaymentToDiscount(undefined);

    if (value === InstallmentsTypeEnum.VEICULO_USADO) setPaymentValue(0);

    setFinancingValue(undefined);
    setPaymentValue(0);
  };
  const handleOnChangePaymentValue = (event: NumberFormatValues) => {
    setPaymentValue(event.floatValue as number);
  };
  const handleOnChangeFinancingValue = (event: NumberFormatValues) => {
    setFinancingValue(event.floatValue as number);
    setPaymentValue((event.floatValue as number) + totalFinancingProducts);
  };
  const handleOnChangeParcelToSlaughter = event => {
    const parcelToSlaughter = paymentList.find(
      payment => payment.position === event.target?.value
    );
    setPaymentToDiscount(parcelToSlaughter);
  };
  const handleOnChangeInstitution = value => {
    setInstitution(value);
  };
  const handleOnChangeQuota = event => {
    setQuota(event.target?.value);
  };
  const handleOnChangeGroup = event => {
    setGroup(event.target?.value);
  };
  const handleOnChangeVehicle = vehicle => {
    setUsedVehicle(vehicle);
    handleOnChangePaymentValue({
      floatValue: vehicle?.evaluationFinalPrice.toFixed(2),
      formattedValue: vehicle?.evaluationFinalPrice,
      value: vehicle?.evaluationFinalPrice,
    });
  };

  const getInstitutionOption = (option: DealerPJFinancialInstitution) => ({
    value: option.id,
    label: `${option.name} (${formatCnpj(option.cnpj?.toString())})`,
    ...option,
  });

  const getInstitutionList = async () => {
    try {
      setIsLoadingInstitutionList(true);
      const result: any = await OrderServices.getDealerPJFinancialInstitutions(
        externalDealerId ? externalDealerId : dealerId,
        installmentsType?.name as string
      );
      const resultList: DealerPJFinancialInstitution[] = result?.data || [];
      const optionList = resultList.map(getInstitutionOption);
      setInstitutionList(optionList);
      setIsLoadingInstitutionList(false);
    } catch (error) {
      setIsLoadingInstitutionList(false);
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Não foi possível consultar a lista de instituições financeiras!'
        )
      );
    }
  };

  const verifyPaymentUniqueError = () => {
    if (paymentType?.unique) {
      const paymentTypeDuplicated = paymentList
        .filter(payment => payment.position !== editPayment?.position)
        .some(payment => payment.paymentType.id === paymentType.id);

      setHasPaymentTypeError(paymentTypeDuplicated);
    } else {
      setHasPaymentTypeError(false);
    }
  };

  const sumTotalFinancingProducts = () => {
    const productsSum = (products as OrderProduct[])
      .filter(product => product.includedOnFinancing)
      .map(product => product.value)
      .reduce((a, b) => a + b, 0);

    setTotalFinancingProducts(productsSum);
  };

  const getDiscountLimitValue = () => {
    if (paymentToDiscount) {
      const totalDiscount = paymentList
        .filter(
          payment =>
            payment.installmentDiscountItem?.position ===
            paymentToDiscount.position
        )
        .filter(payment => payment.position !== editPayment?.position)
        .map(troco => troco.value)
        .reduce((a, b) => a + b, 0);
      return paymentToDiscount.value - totalDiscount;
    }
    return null;
  };

  const getFormatAmount = (amount: any) => formatAmount(amount, 'PT-BR', 'R$');

  const getValueHelperText = () => {
    if (!paymentValue) return 'Valor é obrigatório';

    if (!Number(paymentValue)) {
      return 'Valor deve ser maior que R$0,00';
    }

    if (paymentValue <= paymentTotalTroco) {
      return `Deve ser maior que ${getFormatAmount(
        paymentTotalTroco
      )} referente ao troco`;
    }

    const limitValue = getDiscountLimitValue();

    const shouldBeSmallerRule =
      limitValue && Number(paymentValue) >= Number(limitValue.toFixed(2));

    if (shouldBeSmallerRule && !allowsMonetaryCustomChanges) {
      return `Deve ser menor que ${getFormatAmount(limitValue)}`;
    }

    if (
      limitValue &&
      Number(paymentValue) > Number(limitValue.toFixed(2)) &&
      allowsMonetaryCustomChanges
    ) {
      return `Deve ser menor ou igual que ${getFormatAmount(limitValue)}`;
    }

    return '';
  };

  const handleOnSave = () => {
    const payment: OrderPayment = {
      ...editPayment,
      position: nextPosition as number,
      paymentType: paymentType as OrderPaymentType,
      value: Math.abs(paymentValue) as number,
      financingValue,
      date: paymentDate,
      dueDate: paymentDueDate as number,
      installmentDiscountItem: paymentToDiscount,
      dealerPJFinancialInstitution: institution,
      consorcioGrupo: group || editPayment?.consorcioGrupo,
      consorcioCota: quota || editPayment?.consorcioCota,
      usedVehicle,
      comments,
      financingTerm: financingTerm || editPayment?.financingTerm,
      financingInstallments:
        financingInstallments || editPayment?.financingInstallments,
    };

    onClose(payment);
  };

  const handleOnClose = () => {
    onClose();
  };

  const getHasErrors = () => {
    return (
      hasValueError ||
      hasPaymentTypeError ||
      hasTrocoError ||
      !installmentsType ||
      !paymentType ||
      !paymentValue ||
      !paymentDate ||
      (isDiscountPayment() && !paymentToDiscount) ||
      (isFinanceira() && !institution) ||
      (isConsorcio() && (!quota || !group || !institution)) ||
      (isUsedVehicle() && !usedVehicle)
    );
  };
  useEffect(() => {
    if (editPayment) {
      setInstallmentsType(
        getInstallmentsTypeByName(editPayment.paymentType.installmentsType)
      );
      setPaymentType(getPaymentTypeOption(editPayment.paymentType));
      setPaymentValue(editPayment.value);
      setPaymentDate(editPayment.date);
      setPaymentToDiscount(
        paymentList.find(
          item =>
            item?.position === editPayment?.installmentDiscountItem?.position
        )
      );
      setInstitution(
        editPayment.dealerPJFinancialInstitution
          ? getInstitutionOption(editPayment.dealerPJFinancialInstitution)
          : undefined
      );
      setQuota(editPayment.consorcioCota);
      setGroup(editPayment.consorcioGrupo);
      setUsedVehicle(editPayment.usedVehicle);
      setComments(editPayment.comments);
      setFinancingValue(editPayment.financingValue);
    }
    sumTotalFinancingProducts();
  }, []);

  useEffect(() => {
    filterPaymentTypes(installmentsType?.name);
  }, [installmentsType]);

  useEffect(() => {
    if (paymentType) {
      const newDueDate = moment(today)
        .add(paymentType?.dueDays, 'days')
        .valueOf();
      setPaymentDueDate(newDueDate);
    } else {
      setPaymentDueDate(moment(today).valueOf());
    }
  }, [paymentType]);

  useEffect(() => {
    verifyPaymentUniqueError();
    if (isFinanceira() || isConsorcio()) {
      getInstitutionList();
    }
  }, [paymentType]);

  useEffect(() => {
    if (paymentTotalTroco) {
      setHasTrocoError(
        !Number(paymentValue) || paymentValue <= paymentTotalTroco
      );
      return;
    }

    const limitValue = getDiscountLimitValue();

    const shouldBeSmallerRule =
      paymentToDiscount &&
      (!allowsMonetaryCustomChanges
        ? paymentValue >= Number((limitValue || 0).toFixed(2))
        : paymentValue > Number((limitValue || 0).toFixed(2)));

    setHasValueError(!Number(paymentValue) || shouldBeSmallerRule);
  }, [
    paymentValue,
    paymentToDiscount,
    paymentTotalTroco,
    getDiscountLimitValue,
  ]);

  const viewComponentProps = {
    paymentValue,
    paymentTotalTroco,
    installmentsTypes,
    installmentsType,
    editPayment,
    filteredPaymentTypes,
    loadingInstitutionList,
    institutionList,
    parcelToDiscountList,
    paymentType,
    hasPaymentTypeError,
    hasValueError,
    hasTrocoError,
    financingValue,
    paymentDueDate,
    today,
    paymentToDiscount,
    institution,
    totalFinancingProducts,
    usedVehicle,
    quota: quota || editPayment?.consorcioCota,
    group: group || editPayment?.consorcioGrupo,
    comments,
    open,
    totalRemaining,
    setComments,
    handleOnClose,
    isFinanciamento,
    handleOnChangeInstallmentsType,
    handleOnChangePaymentType,
    getValueHelperText,
    isUsedVehicle,
    isDiscountPayment,
    handleOnChangeFinancingValue,
    handleOnChangePaymentValue,
    handleOnChangeParcelToSlaughter,
    isFinanceira,
    isConsorcio,
    handleOnChangeInstitution,
    handleOnChangeQuota,
    getHasErrors,
    handleOnSave,
    handleOnChangeGroup,
    handleOnChangeVehicle,
    useTotalReamining,
    financingTerm: financingTerm || editPayment?.financingTerm,
    setFinancingTerm,
    financingInstallments:
      financingInstallments || editPayment?.financingInstallments,
    setFinancingInstallments,
    handleOnChangeDueDate,
  };

  return <AddPaymentDialog {...viewComponentProps} />;
};

export default AddPaymentDialogContainer;
