import MessageTypes from 'constants/MessageTypes';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import EnvVars from 'helpers/envVars';
import request from 'helpers/request';
import { SelectedDownPaymentSimulation } from 'pages/chats/components/SideBarComponents/DownPaymentSimulation/components/SimulationForm';
import CallCenterServices from './callCenterServices';
import SearchAndRescueServices from './searchAndRescueCenterServices';
import { buildQueryParams } from 'helpers/buildQueryParams';

const serviceName = 'ProposalService';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

type dealerId = number | string;
type proposalId = number | string;

export interface DocumentDTO {
  id?: number;
  documentTyped?:
    | 'CRLV'
    | 'CRV'
    | 'CNH'
    | 'CPF'
    | 'RG'
    | 'RESIDENCE_PROOF'
    | 'OTHER';
  mediaType?: 'IMAGE' | 'AUDIO' | 'VIDEO' | 'MEDIA';
  mediaId: number;
  mediaVcode?: string;
  name: string;
  createdAt?: number;
  documentType?:
    | 'CRLV'
    | 'CRV'
    | 'CNH'
    | 'CPF'
    | 'RG'
    | 'RESIDENCE_PROOF'
    | 'OTHER';
}

interface ProposalsFilter {
  status?: string;
  sellerId?: string | number;
  periodEnd?: number;
  periodInit?: number;
  departmentCode?: string | number;
  origin?: string;
  providerId?: string | number;
  search?: string;
}

export interface DownPaymentDTO {
  downPayment: number | string;
  partnerId: number | string;
}

export default class ProposalServices {
  static createProposal = (dealerId, obj) => {
    return request
      .post(`${domain}/dealer/${dealerId}/proposals/v1.0`, obj)
      .then(buildHTTPResponseHandler(`${serviceName}.createProposal()`) as any);
  };

  static getProposalById = (dealerId, proposalId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/proposals/v1.1/${proposalId}`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalById()`) as any
      );
  };

  //DEPRECATED
  static alterStatusProposal = (dealerId, proposalId, data, extraData) => {
    const params = extraData;
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/status`,
        `${data}`,
        { params }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.alterStatusProposal()`) as any
      );
  };

  //NEW_API_VERSION_ALTER_STATUS_PROPOSAL_API
  static changeStatusProposal = (
    dealerId,
    proposalId,
    status,
    customFields
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.1/${proposalId}/status/${status}`,
        {
          customFields,
        },
        {
          params: {
            statusDetailId: customFields.statusDetailId,
            orderId: customFields.orderId,
            orderCpfCnpj: customFields.orderCpfCnpj,
            reasonNotToSell: customFields.reasonNotToSell,
          },
        }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.changeStatusProposal()`) as any
      );
  };

  static setSellers = (dealerId, proposalId, sellerId) => {
    const url = `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/seller/${sellerId}`;
    return request
      .put(url, {})
      .then(buildHTTPResponseHandler(`${serviceName}.setSellers()`) as any);
  };

  static setDealer = (dealerId, proposalId, newDealerId) => {
    const url = `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/dealer/${newDealerId}`;
    return request
      .put(url, {})
      .then(buildHTTPResponseHandler(`${serviceName}.setDealer()`) as any);
  };

  static setAnnotation = (dealerId, proposalId, obj) => {
    const url = `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/addannotation`;
    return request
      .post(url, obj)
      .then(buildHTTPResponseHandler(`${serviceName}.setAnnotation()`) as any);
  };

  static setVehicles = (dealerId, proposalId, obj) => {
    const url = `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/addvehicles`;
    return request
      .post(url, obj)
      .then(buildHTTPResponseHandler(`${serviceName}.setVehicles()`) as any);
  };

  static setInteraction = (dealerId, proposalId, obj) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/addinteraction`,
        obj
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.setInteraction-TEXT()`) as any
      );
  };

  static getInteractionsById = (dealerId, proposalId, channel, systemic) => {
    const url = `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/interactions`;
    return request
      .get(url, systemic ? { systemic } : { channel, systemic })
      .then(
        buildHTTPResponseHandler(`${serviceName}.getInteractionsById()`) as any
      );
  };

  static deleteProposal = (dealerId, dealId) => {
    return request
      .delete(`${domain}/dealer/${dealerId}/proposals/v1.0/${dealId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteProposal()`) as any);
  };
  static deleteAnnotation = (dealerId, proposalId, annotationId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/unlinkannotation/${annotationId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.deleteAnnotation()`) as any
      );
  };
  static changeDoneAnnotation = (dealerId, proposalId, scheduledId, done) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/annotation/${scheduledId}/done?done=${done}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.changeDoneAnnotation()`) as any
      );
  };
  static deleteVehicle = (dealerId, proposalId, vehicleId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/unlinkvehicle/${vehicleId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.deleteVehicle()`) as any);
  };
  static setVisualized = (dealerId, proposalId) => {
    if (!!dealerId && !!proposalId) {
      return request
        .put(
          `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/visualizemessages`,
          {}
        )
        .then(
          buildHTTPResponseHandler(`${serviceName}.setVisualized()`) as any
        );
    }
  };

  static getProposalStatusDetail = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/proposals/v1.0/status-detail/options`)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getProposalStatusDetail()`
        ) as any
      );
  };

  static getProposals = (dealerId, options: ProposalsFilter = {}) => {
    const params = {
      status: options.status,
      sellerId: options.sellerId,
      dateFinal: new Date(options.periodEnd).getTime(),
      dateInitial: new Date(options.periodInit).getTime(),
      departmentCode: options.departmentCode ? options.departmentCode : null,
      origin: options?.origin,
      providerId: options.providerId,
      query: options.search,
    };
    const url = `${domain}/dealer/${dealerId}/proposals/v2.0`;
    return request
      .get(url, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposals()`) as any);
  };

  static addInteraction = (
    dealerId,
    proposalId,
    mediaType,
    data,
    dateISO = new Date().toISOString(),
    isBase64 = false
  ) => {
    if (mediaType === MessageTypes.TEXT) {
      return ProposalServices.setInteraction(dealerId, proposalId, data);
    }
    const formData = new FormData();

    //IF IMAGE CAPTURED FROM CAMERA
    if (
      ProposalServices.isImageCapturedFromCamera(isBase64, mediaType) as any
    ) {
      const params = data.file.split(',');
      if (params.length === 2) {
        const obj = {
          base64imgFile: {
            base64: params[1],
            fileName: `${dateISO}.webp`,
            mimeType: 'image/webp',
          },
          groupBy: dateISO,
          message: '',
        };
        return request
          .post(
            `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/addinteraction/base64image`,
            obj
          )
          .then(
            buildHTTPResponseHandler(`${serviceName}.addInteraction()`) as any
          );
      }
      throw new Error('Imagem inválida');
    }

    formData.append('file', data.file);
    formData.append('groupBy', dateISO);

    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/addinteraction/${mediaType}?channel=${data.channel}`,
        formData
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.addInteraction-IMAGE-AUDIO()`
        ) as any
      );
  };

  static isImageCapturedFromCamera(isBase64, mediaType) {
    return isBase64 && mediaType === MessageTypes.IMAGE;
  }

  static saveClientProposalInfo = (
    dealerId,
    proposalId,
    email = null,
    name = null,
    phone = null,
    cpfCnpj = null
  ) => {
    const params = {
      email,
      name,
      phone,
      cpfCnpj,
    };
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/updateclientinfo`,
        {},
        { params: params }
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.saveClientProposalInfo()`
        ) as any
      );
  };

  static createProposalSchedule = (dealerId, proposalId, data) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/scheduled`,
        data
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.createProposalSchedule()`
        ) as any
      );
  };

  static deleteProposalScheduled = (dealerId, proposalId, scheduledId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/scheduled/${scheduledId}`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.deleteProposalScheduled()`
        ) as any
      );
  };

  static getSchedulingTypes = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/schedule/v1.0/types`, {})
      .then(
        buildHTTPResponseHandler(`${serviceName}.getSchedulingTypes()`) as any
      );
  };

  static countChatPerWhatsApp(dealerId, proposalId) {
    if (!dealerId)
      throw new Error(`${serviceName} "dealerId" parameter is required`);
    if (!proposalId)
      throw new Error(`${serviceName} "proposalId" parameter is required`);
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/whatsapp`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.countChatPerWhatsapp()`) as any
      );
  }

  static getDistributionSeller(dealerId) {
    return request
      .get(`${domain}/proposal/distribution/${dealerId}/v1.0/seller`, {})
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getDistributionSeller()`
        ) as any
      );
  }

  static getDistributionSalesAssistant(dealerId) {
    return request
      .get(
        `${domain}/proposal/distribution/${dealerId}/v1.0/sales-assistant`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getDistributionSalesAssistant()`
        ) as any
      );
  }

  static updateDistributionSeller(
    dealerId,
    distributionType,
    minutesToWait = 0
  ) {
    const params = { minutesToWait };
    return request
      .put(
        `${domain}/proposal/distribution/${dealerId}/v1.0/seller/${distributionType}`,
        {},
        { params: params }
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.updateDistributionSeller()`
        ) as any
      );
  }

  static updateDistributionSalesAssistant(
    dealerId,
    distributionType,
    minutesToWait = 0
  ) {
    const params = { minutesToWait };
    return request
      .put(
        `${domain}/proposal/distribution/${dealerId}/v1.0/sales-assistant/${distributionType}`,
        {},
        { params: params }
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.updateDistributionSalesAssistant()`
        ) as any
      );
  }

  static createProposalResaleDealer(dealerId, obj) {
    return request
      .put(`${domain}/dealer/${dealerId}/resale/proposal/v1.0`, obj)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.createProposalResaleDealer()`
        ) as any
      );
  }

  static alterStatusProposalSalesAssistant = ({
    groupId,
    proposalId,
    newStatus,
    newStatusDetailId,
  }) => {
    const isSearchAndRescue = window.location.pathname.includes(
      'busca-e-resgate'
    );
    if (isSearchAndRescue) {
      return SearchAndRescueServices.alterStatusProposalSalesAssistant(
        groupId,
        proposalId,
        newStatus,
        newStatusDetailId
      );
    }
    return CallCenterServices.alterStatusProposalSalesAssistant(
      groupId,
      proposalId,
      newStatus,
      newStatusDetailId
    );
  };

  static setSalesAssistantOnCallcenterOrSearchRescue = (
    groupId,
    proposalId,
    salesAssistantId
  ) => {
    const isSearchAndRescue = window.location.pathname.includes(
      'busca-e-resgate'
    );
    if (isSearchAndRescue) {
      return SearchAndRescueServices.setProposalSalesAssistant(
        groupId,
        proposalId,
        salesAssistantId
      );
    }
    return CallCenterServices.setProposalSalesAssistant(
      groupId,
      proposalId,
      salesAssistantId
    );
  };

  static setSalesAssistantShowroom = (
    dealerId,
    proposalId,
    salesAssistantId
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/sales-assistant/${salesAssistantId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.setSalesAssistantShowroom()`
        ) as any
      );
  };

  static associateASalesAssistantToAProposal = (
    groupId,
    proposalListId,
    salesAssistantId
  ) => {
    const isSearchAndRescue = window.location.pathname.includes(
      'busca-e-resgate'
    );
    if (isSearchAndRescue) {
      return request
        .put(
          `${domain}/searchandrescuecenter/${groupId}/v1.0/sales-assistant/${salesAssistantId}`,
          proposalListId
        )
        .then(
          buildHTTPResponseHandler(
            `${serviceName}.associateASalesAssistantToAProposal()`
          ) as any
        );
    }
    return request
      .put(
        `${domain}/callcenter/${groupId}/v1.0/sales-assistant/${salesAssistantId}`,
        proposalListId
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.associateASalesAssistantToAProposal()`
        ) as any
      );
  };

  //GET_PROPOSAL_BY_CONTACT_ID
  static getProposalsByContactId(dealerId, contactId) {
    return request
      .get(`${domain}/dealer/${dealerId}/proposals/v1.0/contact/${contactId}`)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getProposalsByContactId()`
        ) as any
      );
  }

  //PROPOSAL_CHAT_SIMULATE_TOOL
  static getFinancingSimulate = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/financing/simulation/${proposalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getByIdInstitution()`) as any
      );
  };

  static getSearchSimulations = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/${proposalId}/v2.0/simulations`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getSearchSimulations()`) as any
      );
  };

  static simulationFinancing = (dealerId, proposalId, simulateData) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/financing/simulation/${proposalId}`,
        simulateData
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.simulationFinancing()`) as any
      );
  };

  static coldSimulationFinancing = (dealerId, proposalId, simulateData) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/financing/simulation/cold-calculator/${proposalId}`,
        simulateData
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.coldSimulationFinancing()`
        ) as any
      );
  };

  static addRating = (dealerId, proposalId, rating) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/rating/${rating}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.addRating()`) as any);
  };

  //MESSAGES_TEMPLATE

  static getMessagesTemplate = (dealerId, proposalId, channel) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/messages-template/${proposalId}`,
        {
          channel,
        }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getMessagesTemplate()`) as any
      );
  };

  static canSendWhatsAppBusinessMessage = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/can-send-message-whatsappbusiness/${proposalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.CanSendWhatsappBusinessMessage()`
        ) as any
      );
  };

  static getProposalToFile = (dealerId, initialDate, finalDate) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/proposal/listing/file?initialDate=${initialDate}&finalDate=${finalDate}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalToFile()`) as any
      );
  };

  static changeDoneSchedule = ({
    dealerId,
    done,
    proposalId,
    scheduledId,
    reasonId = null,
  }) => {
    const params = buildQueryParams({ done, ...(reasonId && { reasonId }) });

    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/scheduled/${scheduledId}/done?${params}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.changeDoneSchedule()`) as any
      );
  };

  static getProposalStatus = (
    dealerId,
    isCallcenter,
    departmentId = null,
    isOem = null,
    currentProposalId = null
  ) => {
    return request
      .get(`${domain}/dealer/${dealerId}/proposals/v1.0/status/options`, {
        callcenter: isCallcenter,
        departmentId,
        oem: isOem,
        proposalId: currentProposalId,
      })
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalStatus()`) as any
      );
  };

  static archiveProposalById = (dealerId, proposalId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/archive`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.archiveProposalById()`) as any
      );
  };

  static createPreMessage = (dealerId, proposalMessageTemplateDto) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/message-template/v1.0`,
        proposalMessageTemplateDto
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.createPreMessage()`) as any
      );
  };

  //OVERVIEW_PAGE_V2

  static getOverviewInfos = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/overview`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getOverviewInfos()`) as any
      );
  };

  static getSalesFunnel = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/sales-funnel`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getSalesFunnel()`) as any);
  };

  static getTMA = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/tma`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getTMA()`) as any);
  };

  static getLeadsTotalVolumeByStatus = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/leads-volume-status`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getLeadsTotalVolumeByStatus()`
        ) as any
      );
  };

  static getTimeRangeOfInactiveLeadsInNegotiation = params => {
    return request
      .get(
        `${domain}/dealer/dashboard/v1.0/inactiveleads-negotiation-time-range`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getTimeRangeOfInactiveLeadsInNegotiation()`
        ) as any
      );
  };

  static getTimeRangeOfDelayedNewLeads = params => {
    return request
      .get(
        `${domain}/dealer/dashboard/v1.0/newleads-delayed-time-range`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getTimeRangeOfDelayedNewLeads()`
        ) as any
      );
  };

  static getSellerServiceTime = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/seller-service-time`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getSellerServiceTime()`) as any
      );
  };

  static getPerformaceTopSeven = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/performance`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getPerformaceTopSeven()`
        ) as any
      );
  };

  static getEvaluationsFunnel = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/evaluations-funnel`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getEvaluationsFunnel()`) as any
      );
  };

  static getActivities = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/activities`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getActivities()`) as any);
  };

  static getChampionsCallCenter = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/champions`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getChampionsCallCenter()`
        ) as any
      );
  };

  static getChampions = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.1/champions`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getChampions()`) as any);
  };

  static getConversion = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/conversion`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getConversion()`) as any);
  };

  static getReasonsDisqualifyingLeads = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/disqualified`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getReasonsDisqualifyingLeads()`
        ) as any
      );
  };

  static getPersonDetail = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/person-detail`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getPersonDetail()`) as any
      );
  };

  static getSellerSchedule = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/schedule/dates`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getSellerSchedule()`) as any
      );
  };

  static getSellerScheduleInfos = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/schedule/detail`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getSellerScheduleInfos()`
        ) as any
      );
  };

  static getDealerDetail = params => {
    return request
      .get(`${domain}/dealer/dashboard/v1.0/dealer-detail`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getDealerDetail()`) as any
      );
  };

  static getProposalsV2 = (dealerId, options) => {
    const params = {
      dealerId,
      status: options.status,
      dateFinal: new Date(options.periodEnd).getTime(),
      dateInitial: new Date(options.periodInit).getTime(),
      query: options.search,
      filterByCall: options.filterByCall,
      filterByInactiveLead: options.filterByInactiveLead,
      filterByActiveLead: options.filterByActiveLead,
      filterByLeadWithSold: options.filterByLeadWithSold,
      filterByNewLeadDelayed: options.filterByNewLeadDelayed,
      filterByScheduledDelayed: options.filterByScheduledDelayed,
      groupId: options?.groupId,
      salesAssistantId: options?.salesAssistantId,
      attendanceType: options?.attendanceType,
      ...(options?.departmentCodes && {
        departmentCodes: String(options?.departmentCodes),
      }),
      view: options?.view,
      ...(options?.sellerId && { sellerId: String(options.sellerId) }),
      ...(options?.sellers && { sellers: String(options.sellers) }),
      ...(options?.origin && { origins: String(options.origin) }),
      ...(options?.tags && { tags: String(options.tags) }),
      ...(options?.providerId && { providers: String(options.providerId) }),
      ...(options?.dealers && { dealers: String(options.dealers) }),
      ...options.extraFilter,
    };

    return request
      .get<any>(`${domain}/dealer/dashboard/v1.0`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposalsV2()`) as any);
  };

  static getAllProposalsToExport = (dealerId, options) => {
    const params = {
      dealerId,
      status: options?.status,
      dateFinal: new Date(options.periodEnd).getTime(),
      dateInitial: new Date(options.periodInit).getTime(),
      departmentCodes: options?.departmentCodes
        ? options?.departmentCodes
        : null,
      query: options?.search,
      filterByCall: options?.filterByCall,
      filterByInactiveLead: options?.filterByInactiveLead,
      filterByLeadWithSold: options?.filterByLeadWithSold,
      filterByNewLeadDelayed: options?.filterByNewLeadDelayed,
      filterByScheduledDelayed: options?.filterByScheduledDelayed,
      ...(options?.origins && { origins: String(options?.origins) }),
      ...(options?.providers && { providers: String(options?.providers) }),
      ...(options?.salesAssistantId && {
        salesAssistantId: Number(options.salesAssistantId),
      }),
      ...(options?.sellers && { sellers: String(options.sellers) }),
      ...(options?.tags && { tags: String(options?.tags) }),
      ...options?.extraFilter,
    };

    return request
      .get(`${domain}/dealer/dashboard/v1.0/export`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllProposalsToExport()`
        ) as any
      );
  };

  static getLeadsProviderByDay = params => {
    return request
      .get(`${domain}/dealer/dashboard/v2.0/leads-provider-by-day`, params)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getLeadsProviderByDay()`
        ) as any
      );
  };

  //INTEREST_PRODUCTS
  static getInterestProducts = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/interest-product`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllProposalsToExport()`
        ) as any
      );
  };
  static addInterestProduct = (dealerId, proposalId, interestProduct) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/interest-product`,
        interestProduct
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllProposalsToExport()`
        ) as any
      );
  };

  static removeInterestProducts = (dealerId, proposalId, vehicleId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/interest-product/${vehicleId}`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllProposalsToExport()`
        ) as any
      );
  };

  //TEMPLATE MESSAGE

  static getAllMessageTemplate = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/proposal/message-template/v1.0`)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllMessageTemplate()`
        ) as any
      );
  };

  static getAllCategories = dealerId => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/message-template/category/v1.0`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getAllCategories()`) as any
      );
  };

  static createCategory = (dealerId, CategoryMessageDto) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/message-template/category/v1.0`,
        CategoryMessageDto
      )
      .then(buildHTTPResponseHandler(`${serviceName}.createCategory()`) as any);
  };

  static updateCategory = (dealerId, currentCategoryId, CategoryMessageDto) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/message-template/category/v1.0/${currentCategoryId}`,
        CategoryMessageDto
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateCategory()`) as any);
  };

  static deleteMessageTemplate = (dealerId, id) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposal/message-template/v1.0/${id}`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.deleteMessageTemplate()`
        ) as any
      );
  };

  static updateMessageTemplate = (dealerId, id, proposalMessageTemplateDto) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/message-template/v1.0/${id}`,
        proposalMessageTemplateDto
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.updateMessageTemplate()`
        ) as any
      );
  };

  //ORIGEM_FILTER_OPTIONS
  static getProviderOptions = dealerId => {
    return request
      .get(`${domain}/dealer/${dealerId}/proposals/v1.0/providers`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProviderOptions()`) as any
      );
  };

  // Schedule Reminder
  static getSellerScheduleInfosWithReminders = (
    dealerId,
    start,
    finish,
    id,
    reminderType,
    done?: ''
  ) => {
    const params = {
      sellerId: id,
      reminderType: reminderType,
      done,
    };
    return request
      .get(
        `${domain}/dealer/${dealerId}/schedule/v1.0/reminders/dates/${start}/${finish}`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getSellerScheduleInfosWithReminders()`
        ) as any
      );
  };

  static getSellerScheduleInfosDetailsWithReminders = (
    dealerId: string | number,
    start: string | number,
    finish: string | number,
    id: string | number,
    reminderType: string,
    done?: boolean | any,
    openField?: boolean
  ) => {
    const params = {
      sellerId: id,
      reminderType: reminderType,
      done: done,
      openField: openField,
    };
    return request
      .get(
        `${domain}/dealer/${dealerId}/schedule/v1.0/reminders/detailed/${start}/${finish}`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getSellerScheduleInfosDetailsWithReminders()`
        ) as any
      );
  };

  static postObtainFileCSVAndReturnListWithProposals = (dealerId, file) => {
    return request
      .post(`${domain}/dealer/${dealerId}/proposals/v1.0/proposal/upload`, file)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.postobtainFileCSVAndReturnListWithProposals()`
        ) as any
      );
  };

  static postImportProposalsFromCSV = (
    dealerId,
    proposalsCsvSimplifiedDto,
    config
  ) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/proposal/import`,
        proposalsCsvSimplifiedDto,
        config
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.postImportProposalsFromCSV()`
        ) as any
      );
  };

  // contact history
  static getContactHistory = (dealerId, contactId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/contact/${contactId}/history`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getContactHistory()`) as any
      );
  };

  static transferAllActiveProposalsFromOtherSeller = (
    dealerId,
    oldSellerId,
    newSellerId
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/transfer-seller-proposal/${oldSellerId}/to/${newSellerId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.transferAllActiveProposalsFromOtherSeller()`
        ) as any
      );
  };

  //LEADS_TREE
  static getLeadsTree = ({
    groupId,
    dealersId = null,
    departmentCodes = null,
    attendanceType = null,
    origins = null,
    providers = null,
    start = null,
    end = null,
    groups = null,
  }) => {
    return request
      .get(
        `${domain}/dealer/dashboard/v1.0/leads-tree`,
        {},
        {
          params: {
            groupId,
            dealersId,
            departmentCodes,
            attendanceType,
            origins,
            providers,
            start,
            end,
            groups,
          },
        }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getLeadsTree()`) as any);
  };

  //LEADS_TREE_DETAIL
  static getLeadsTreeDetail = ({
    groupId,
    dealerId = null,
    departmentCodes = null,
    attendanceType = null,
    origins = null,
    providers = null,
    start = null,
    end = null,
    groups = null,
  }) => {
    return request
      .get(
        `${domain}/dealer/dashboard/v1.0/leads-tree-provider-detail`,
        {},
        {
          params: {
            groupId,
            dealerId,
            departmentCodes,
            attendanceType,
            origins,
            providers,
            start,
            end,
            groups,
          },
        }
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getLeadsTreeDetail()`) as any
      );
  };

  static associateASellerToSelectedProposals = (
    dealerId,
    sellerId,
    proposalIdList
  ) => {
    const url = `${domain}/dealer/${dealerId}/proposals/v1.0/seller/${sellerId}`;
    return request
      .put(url, proposalIdList)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.associateASellerToSelectedProposals()`
        ) as any
      );
  };

  static getEvaluationShowroomDealsForListing = (
    dealerId,
    proposalId,
    proposalShowroomEvaluationFilterForListing
  ) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/evaluation/v1.0/${proposalId}/listing`,
        proposalShowroomEvaluationFilterForListing
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getEvaluationShowroomDealsForListing()`
        ) as any
      );
  };

  static createAEvaluation = (dealerId, proposalId, dealDto) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/evaluation/v1.0/${proposalId}`,
        dealDto
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.createAEvaluation()`) as any
      );
  };

  static deleteTag = (dealerId, proposalId, tagId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposals/v1.0/proposal/${proposalId}/tag/${tagId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.deleteTag()`) as any);
  };

  //PROPOSAL_DOCUMENT
  static getDocuments = (dealerId: dealerId, proposalId: proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/proposal/${proposalId}/documents`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getDocuments()`) as any);
  };

  static deleteDocument = (
    dealerId: dealerId,
    proposalId: proposalId,
    documentId: number
  ) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposals/v1.0/proposal/${proposalId}/document/${documentId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.deleteDocument()`) as any);
  };

  static addDocument = (
    dealerId: dealerId,
    proposalId: proposalId,
    documentDTO: DocumentDTO
  ) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/v1.0/proposal/${proposalId}/document`,
        documentDTO
      )
      .then(buildHTTPResponseHandler(`${serviceName}.addDocument()`) as any);
  };

  static addTag(dealerId, proposalId, data) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/proposal/${proposalId}/tags?tags=${data}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.createTag()`) as any);
  }

  static getCustomerClassificationInformation = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/v1.0/external-information/${proposalId}`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getCustomerClassificationInformation()`
        ) as any
      );
  };

  static sendWhatsAppLocation = (dealerId, proposalId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/send-whatsapp-location`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.sendWhatsappLocation()`) as any
      );
  };

  static getPaymentsCategory = dealerId => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposal/order/documents/v1.0/payments`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getPaymentsCategory()`) as any
      );
  };

  static calculateDownPayment = (
    dealerId: dealerId,
    proposalId: proposalId,
    downPayment: DownPaymentDTO
  ) => {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposals/down-payment/v1.0/${proposalId}`,
        downPayment
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.calculateDownPayment()`) as any
      );
  };

  static applyDownPaymentSimulation = (
    dealerId: dealerId,
    proposalId: proposalId,
    selectedSimulation: SelectedDownPaymentSimulation
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/down-payment/v1.0/${proposalId}`,
        selectedSimulation
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.applyDownPaymentSimulation()`
        ) as any
      );
  };
}
