import styled from 'styled-components';

const Line = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
`;
export const LineSpaceBetween = styled(Line)`
  justify-content: flex-end;
  width: 100%;
`;
