import styled from 'styled-components';

export const RepliedMessageContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  flex: 1;
  
  background-color: #00b2a9;
  color: #FFF;

  cursor: pointer;

  border-left: 2.5px solid #FFF;
  border-radius: 8px;

  padding: 3px 6px;

  transition: background-color 0.2s ease-in-out;

  /* Quebra de texto */
  > span, p {
    max-width: 230px;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden; 
    font-size: 12px;
  }

  > span {
    display: block;
    width: 230px;
    font-family: 'Poppins Medium';
    font-weight: 500;

    border-bottom: 1px solid #ffffff50;
  }

  > p {
    font-family: 'Poppins Regular';
    margin: 0;
  }

  &:hover {
    background-color: #06cf9de1;
  }
`;

export const MessageContainer = styled.div<{ noSpace: boolean }>`
  margin: ${props => props.noSpace ? 0 : "10px 0 5px"};
  width: 100%;

  /* Caso a mensagem respondida seja um texto eu tiro a margem do texto */
  > p {
    margin: 0px;
  }
`;