import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Container = styled.div`
  padding: 16px;
  height: calc(100vh - 366px);
  overflow-y: auto;
`;

export const ItemContainer = styled.div`
  display: flex;
  min-height: 50px;
`;

export const ControllersIcones = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 11px;

  .timelineDot {
    display: flex;
    > svg {
      width: 12px;
      height: 12px;
      fill: ${Colors.primary.medium};
      margin-top: 2px;
    }
  }

  .timelineConnect {
    height: 100%;
    background: ${Colors.primary.medium};
    width: 1px;
    margin: 5px 0;
  }
`;

export const ItemContent = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50px;
  flex-grow: 1;
  .timelineTitle {
    line-height: 17px;
    font-size: 14px;
    color: ${Colors.gray.medium};
  }
`;

export const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .timelineDate {
    color: ${Colors.primary.medium};
    font-weight: 600;
  }

  .timelineNameContent {
    display: flex;
    align-items: center;
  }

  .timelineOwnerName {
    margin-left: 8px;
    color: ${Colors.gray.medium};
  }
`;

export const OvalBigger = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${Colors.gray.nearWhite};
  font-family: Poppins Bold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
