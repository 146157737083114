import { atom } from 'recoil';

// Interface import
import { ChatListShow, ChatData } from 'pages/conversations/interfaces'

export const chatListOptions = atom<ChatListShow>({
  key: 'CHAT_LIST_OPTIONS',
  default: 'CONVERSATIONS'
});

export const selectedLead = atom<ChatData>({
  key: 'LEAD_SELECTED',
  default: null
});

export const sidebarIsOpen = atom<boolean>({
  key: "SIDEBAR_IS_OPEN",
  default: false
});

export const filterByUnread = atom<boolean>({
  key: "FILTER_BY_UNREAD",
  default: false
});

export const fetchingLeads = atom<boolean>({
  key: "FETCHING_LEADS",
  default: false
});

export const leadsList = atom<ChatData[]>({
  key: "LEADS_LIST",
  default: []
});

export const inactiveLeadsList = atom<ChatData[]>({
  key: "INACTIVE_LEADS_LIST",
  default: []
});