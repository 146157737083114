import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

interface CustomQuery<Query, Response> {
  enabled?: boolean;
  key: string | string[];
  params: Query;
  request: (params: Query) => Promise<Response>;
  options?: UseQueryOptions<Response, unknown, Response, QueryKey>;
}

function useCustomQuery<Query, Response>({
  enabled = true,
  key,
  params,
  request,
  options = {},
}: CustomQuery<Query, Response>) {
  return useQuery({
    enabled,
    queryFn: () => request(params),
    queryKey: ([key] as unknown) as QueryKey,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 1,
    retryDelay: 3000,
    staleTime: 120000,
    ...options,
  });
}

export default useCustomQuery;
