import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import OrderServices from 'modules/order/services/OrderServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import { ActionsContent, MiniLoading, PreviousPanelButton } from '../../styles';
import { FinishButton, OrderNotesContainer, useStyles } from './styles';

function OrderNotesComponent({
  handlePreviousPanel,
  handleNextPanel,
  handleSaveComments = null,
  value = null,
  isQueue = false,
}) {
  const classes = useStyles();
  const {
    dealerId,
    proposalId,
    saleComments,
    isFinished,
    proposalStep,
    queueData,
  } = useSelector((state: any) => state.order);
  const dispatch = useDispatch();

  const [loadingSave, setLoadingSave] = useState(false);

  const queueFinished = ['FINISHED', ''].includes(queueData?.status?.value);

  const setSaleComments = (saleComments: string) => {
    dispatch(ActionCreators.setSaleComments(saleComments));
    dispatch(
      ActionCreators.setQueueData({
        comments: saleComments,
      })
    );
  };

  const saveSaleComments = async () => {
    if (isFinished) {
      handleNextPanel();
    }
    setLoadingSave(true);
    if (handleSaveComments) {
      await handleSaveComments({
        dealerId,
        proposalId,
        saleComments,
        callbackOnSuccess: handleNextPanel,
      });
    } else {
      const { success, data }: any = await OrderServices.saveSaleComments(
        dealerId,
        proposalId,
        saleComments || ' '
      );
      if (success) handleNextPanel();
      else {
        console.error(data);
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            data?.message ||
              'Não foi possível salvar a lista de serviços adicionais na proposta!'
          )
        );
      }
    }
    setLoadingSave(false);
  };

  return (
    <OrderNotesContainer>
      <TextField
        className={classes.root}
        placeholder="Observações"
        multiline
        rows="4"
        value={value || saleComments}
        onChange={event => setSaleComments(event.target.value)}
        variant="outlined"
        inputProps={{ maxLength: 500 }}
        helperText={`${saleComments?.length || 0}/500`}
        disabled={isQueue ? queueFinished : isFinished}
        fullWidth
      />

      <ActionsContent style={{ marginTop: 20 }}>
        {!isFinished && (
          <PreviousPanelButton onClick={handlePreviousPanel}>
            Voltar
          </PreviousPanelButton>
        )}
        {!(proposalStep === 'COMMENTS_QUEUE') && (
          <FinishButton onClick={saveSaleComments}>
            Continuar para dados do cliente
            {loadingSave && <MiniLoading size={20} />}
          </FinishButton>
        )}
      </ActionsContent>
    </OrderNotesContainer>
  );
}

export default OrderNotesComponent;
