import React, { useEffect, useState } from 'react';
import ClientData from './steps/ClientData';
import ProposalData from './steps/ProposalData';
import SubHeader from './components/SubHeader';
import OrderStepperActions from 'modules/order/components/atoms/OrderStepperActions';
import { useSelector, useDispatch } from 'react-redux';
import { ProposalOrderStep } from 'modules/order/types/ProposalOrder';
// import HypertextButton from 'modules/financing/components/atoms/HypertextButton'
import ActionCreators from 'modules/order/store/reducers/actionCreators';
export enum ProposalSubStep {
  PROPOSAL_DATA,
  CLIENT_DATA,
}

const clientDataSteps = [
  ProposalOrderStep.PERSON,
  ProposalOrderStep.ADDRESS,
  ProposalOrderStep.REFERENCE,
  ProposalOrderStep.OCCUPATION,
  ProposalOrderStep.COMPANY,
  ProposalOrderStep.BUYER_FINANCING_COMPANY,
  ProposalOrderStep.BUYER_FINANCING_PERSON,
  ProposalOrderStep.BUYER_FINANCING_ADDRESS,
  ProposalOrderStep.BUYER_FINANCING_REFERENCE,
  ProposalOrderStep.BUYER_FINANCING_OCCUPATION,
  ProposalOrderStep.BUYER_INCASH_PERSON,
  ProposalOrderStep.BUYER_INCASH_COMPANY,
];

const ProposalStep = () => {
  const { proposalStep } = useSelector(state => state.order);
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(ProposalSubStep.PROPOSAL_DATA);

  useEffect(() => {
    if (clientDataSteps.includes(proposalStep))
      setCurrentStep(ProposalSubStep.CLIENT_DATA);
    else setCurrentStep(ProposalSubStep.PROPOSAL_DATA);
  }, [proposalStep]);

  const handleChangeStep = (step: ProposalSubStep) => {
    setCurrentStep(step);
  };

  const goBackToProposalData = () => {
    dispatch(ActionCreators.setProposalStep(ProposalOrderStep.COMMENTS));
  };

  const getCurrentStep = (currentStep: ProposalSubStep) => {
    switch (currentStep) {
      case ProposalSubStep.PROPOSAL_DATA:
        return (
          <>
            <ProposalData />
            <OrderStepperActions onlyBackButton={true} />
          </>
        );
      case ProposalSubStep.CLIENT_DATA:
        return (
          <>
            <ClientData />
            <OrderStepperActions customBackFunction={goBackToProposalData} />
          </>
        );
      default:
        return (
          <>
            <ProposalData />
            <OrderStepperActions />
          </>
        );
    }
  };

  return (
    <div>
      <SubHeader currentStep={currentStep} onChangeStep={handleChangeStep} />
      {getCurrentStep(currentStep)}
    </div>
  );
};

export default ProposalStep;
