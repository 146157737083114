const getters = { GET: 'GET', SET: 'SET', DELETE: 'DELETE' };

export { getters };

export const httpCallStatus =  {
  SUCCESS: 'SUCCESS',
  REQUEST: 'REQUEST',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
};
