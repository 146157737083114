import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

const LoadingInnerPages = () => (
  <Box p={2}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rect" animation="wave" width={'100%'} height={144} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" animation="wave" width={'100%'} height={400} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" animation="wave" width={'100%'} height={200} />
      </Grid>
    </Grid>
  </Box>
);
export default LoadingInnerPages;
