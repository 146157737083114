import React from 'react';
import usePricingVehicleFormData from './hooks/usePricingVehicleFormData';
import usePricingVehicleStore from './hooks/usePricingVehicleStore';
import { PricingVehicleData, PricingVehicleState } from './PricingVehicleTypes';
import { FormikProps } from 'formik';

type PricingVehicleContext = {
  pricingVehicleFormData: FormikProps<PricingVehicleData>;
} & PricingVehicleState;

const PricingVehicleContext = React.createContext<PricingVehicleContext>(
  {} as PricingVehicleContext
);

interface PricingVehicleProviderProps {
  children: React.ReactNode;
}

const PricingVehicleProvider: React.FC<PricingVehicleProviderProps> = ({
  children,
}) => {
  const pricingVehicleFormData = usePricingVehicleFormData();
  const pricingVehicleStore = usePricingVehicleStore();

  return (
    <PricingVehicleContext.Provider
      value={{ pricingVehicleFormData, ...pricingVehicleStore }}
    >
      {children}
    </PricingVehicleContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withPricingVehicleProvider = (Component: any) => (props: any) => (
  <PricingVehicleProvider>
    <Component {...props} />
  </PricingVehicleProvider>
);

const usePricingVehicleContext = () => {
  return React.useContext(PricingVehicleContext);
};

export {
  PricingVehicleProvider as default,
  usePricingVehicleContext,
  withPricingVehicleProvider,
};
