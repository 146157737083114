import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export const INITIAL_STATE = {
  dealerInfos: {},
};

export default function dealer(state = INITIAL_STATE, action: Action): any {
  switch (action.type) {
    case ActionTypes.SET_DEALER:
      return { ...state, dealerInfos: action.payload.dealer };
    default:
      return state;
  }
}
