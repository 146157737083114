import { requestModule as request } from './index';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandlerTS';

const serviceName = 'TransactionalServices';

export default class TransactionalServices {
  static get = async (startDate: number, endDate: number) => {
    const response = await request.get(
      `v1.0/transactionals/${startDate}/${endDate}`
    );

    const responseHandler = await buildHTTPResponseHandler(
      `${serviceName}.get()`
    );

    return responseHandler(response);
  };
}
