import MODULE from './module'
import actionOptions, { getters } from '../actionsOptions'

export default {
  //CURRENT GROUP
  SET_CURRENT_GROUP: `@${MODULE}/${getters.SET}_CURRENT_GROUP_${actionOptions.SUCCESS}`,
  //PROPOSALS BY GROUP
  GET_ALL_PROPOSALS_BY_GROUP: `@${MODULE}/${getters.GET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.REQUEST}`,
  SET_ALL_PROPOSALS_BY_GROUP_SUCCESS: `@${MODULE}/${getters.SET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.SUCCESS}`,
  SET_ALL_PROPOSALS_BY_GROUP_ERROR: `@${MODULE}/${getters.SET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.ERROR}`,
  SET_ALL_PROPOSALS_BY_GROUP_LOADING: `@${MODULE}/${getters.SET}_ALL_PROPOSALS_BY_GROUP_${actionOptions.LOADING}`,
}
