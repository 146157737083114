import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import { HeaderText } from './styles';

function TableHeadComponent({ onRequestSort, columnsConfig, order, orderBy }) {
  const createSortHandler = property => event => {
    if (!onRequestSort) {
      return;
    }
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columnsConfig.map(row => (
          <TableCell
            key={row.id}
            align={row.align || 'left'}
            width={row.width ? row.width : null}
            sortDirection={orderBy === row.id ? order : false}
          >
            <Tooltip
              title={row.label}
              placement={row.numeric ? 'bottom-end' : 'bottom-start'}
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                <HeaderText>{row.label}</HeaderText>
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadComponent;
