import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { ReactComponent as LegsRightChatBubble } from 'assets/legs-left-chat-bubble.svg';
import { ReactComponent as MobibeeAvatar } from 'assets/mobibee-avatar.svg';
import formatTime from 'helpers/formatTime';
import getInitialsName from 'helpers/getInitialsName';
import MessageTypes from 'constants/MessageTypes';
import { addLinkOnMessage } from './components/utils/addLinkOnMessage';
import MessageAudio from './MessageAudio';
import MessageImage from './MessageImage';
import MessageVideo from './MessageVideo';
import MessageMedia from './MessageMedia';
import MessageResponse from './components/RepliedMessage';
import SystemicMessage from './SystemicMessage';

// Context import
import { useChatContext } from 'pages/chats/ChatsContext';

const OvalBigger = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${props => props.backgroundColor || Colors.gray.nearWhite};
  font-family: Poppins Bold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const CheckReadContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px 0px 0px 8px;
  align-items: center;
  > svg {
    font-size: 16px;
    color: ${({ readMessage }) => (readMessage ? Colors.stars.medium : '#fff')};
  }
`;

const ContainerIcon = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const ContainerMessage = styled.div`
  display: flex;
  flex-flow: ${props => props.flexDirection || 'wrap-reverse'};
  padding: ${props => props.paddingRight || '0'};
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;

  > svg > path {
    fill: ${props => (props.failed ? '#ff6666' : '#00B2A9')};
  }
`;

const FooterMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 7px;
`;

const BoxPurple = styled.div`
  max-width: 80%;
  padding: 8px 8px 8px 16px;
  background-color: #00b2a9;
  animation: background-color ease-in-out 200ms;
  color: #ffffff;
  border-radius: 8px 8px 0px 8px;
  font-family: Poppins Regular;
  font-size: 12px;
`;

const DateMessage = styled.div`
  font-family: Poppins Regular;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
`;

const MessagePJ = props => {
  // Hooks
  const { currentProposal } = useChatContext();

  const {
    message,
    sellerName,
    currentChannel,
    currentRef,
    currentAudioPlaying,
    setCurrentAudioPlaying,
    audiosRef,
    messageFocusIndex,
  } = props;

  const animatedMessage =
    currentProposal?.interactions[messageFocusIndex]?.id === message.id;

  if (message.systemic) {
    return <SystemicMessage message={message} sellerName={sellerName} />;
  }
  const dateText = `${moment(message.date).format('DD/MM')} - ${formatTime(
    new Date(message.date)
  )}`;

  const userCapitilize = getInitialsName(message.ownerName || sellerName);

  const MessageType = messageType => {
    switch (messageType) {
      case MessageTypes.AUDIO:
        return (
          <MessageAudio
            message={message}
            currentAudioPlaying={currentAudioPlaying}
            setCurrentAudioPlaying={setCurrentAudioPlaying}
            audiosRef={audiosRef}
          />
        );
      case MessageTypes.IMAGE:
        return <MessageImage message={message} />;
      case MessageTypes.VIDEO:
        return <MessageVideo message={message} />;
      case MessageTypes.MEDIA:
        return <MessageMedia message={message} />;
      case MessageTypes.RESPONSE:
        return (
          <MessageResponse
            message={message}
            currentAudioPlaying={currentAudioPlaying}
            setCurrentAudioPlaying={setCurrentAudioPlaying}
            audiosRef={audiosRef}
          />
        );
      default:
        return addLinkOnMessage(message?.message ?? '');
    }
  };

  const getMessageStatus = (
    failed,
    sent,
    delivered,
    errorDetail = null,
    read
  ) => {
    if (failed) {
      return {
        Icon: <ErrorOutlineOutlinedIcon />,
        message: errorDetail ?? 'Erro ao enviar a mensagem',
      };
    }
    if (sent && delivered) {
      return {
        Icon: <DoneAllIcon />,
        message: read ? 'Mensagem lida' : 'Mensagem enviada e entregue',
      };
    }
    if (sent && !delivered) {
      return { Icon: <DoneIcon />, message: 'Mensaagem enviada' };
    }
    if (!sent) {
      return { Icon: <AccessTimeIcon />, message: 'Aguardando envio' };
    }
    return null;
  };

  return (
    <Container ref={currentRef}>
      <ContainerMessage failed={message.failed}>
        <BoxPurple
          style={{
            backgroundColor: message.failed
              ? '#ff6666'
              : animatedMessage && '#035f5b',
          }}
        >
          <div
            style={{
              paddingRight: 8,
              fontSize: 14,
              marginBottom: 8,
              whiteSpace: 'pre-wrap',
            }}
          >
            {MessageType(props.type)}
          </div>
          <FooterMessage>
            <DateMessage>{dateText}</DateMessage>
            {currentChannel !== 'CHAT' &&
              moment('20210816').valueOf() < moment(message.date).valueOf() && (
                <Tooltip
                  title={
                    getMessageStatus(
                      message.failed,
                      message.sent,
                      message.delivered,
                      message?.errorDetail,
                      message.read
                    ).message
                  }
                >
                  <ContainerIcon>
                    <CheckReadContainer readMessage={message.read}>
                      {
                        getMessageStatus(
                          message.failed,
                          message.sent,
                          message.delivered,
                          message?.errorDetail,
                          message.read
                        ).Icon
                      }
                    </CheckReadContainer>
                  </ContainerIcon>
                </Tooltip>
              )}
          </FooterMessage>
        </BoxPurple>
        <LegsRightChatBubble />
        <Tooltip title={message.ownerName || sellerName}>
          {message.ownerName === 'BOT' ? (
            <MobibeeAvatar />
          ) : (
            <ContainerIcon>
              <OvalBigger style={{ margin: ' 0 16px' }}>
                {userCapitilize}
              </OvalBigger>
            </ContainerIcon>
          )}
        </Tooltip>
      </ContainerMessage>
    </Container>
  );
};

export default React.memo(MessagePJ);
