import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import routesPath from 'config/routesPath';
import ChatShowroomComponent from './ChatShowroomComponent';
import { useChatContext } from 'pages/chats/ChatsContext';

const ChatShowroomContainer = ({
  handleOnClickClose = () => null,
  onModal = false,
}) => {
  const history = useHistory();
  const { currentProposal, otherStates }: any = useChatContext();

  const handleOnClickBack = () => {
    history.push(routesPath.getRecursivePath(routesPath.proposals));
  };

  const viewComponentsProps = {
    onModal,
    handleOnClickBack,
    handleOnClickClose,
    currentProposal,
    proposalError: otherStates.proposalError,
  };

  return <ChatShowroomComponent {...viewComponentsProps} />;
};

export default memo(ChatShowroomContainer);
