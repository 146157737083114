function validateDealerHasProducts(dealerProducts = [], productsRequired = []) {
  const arrayProducts = productsRequired.map(
    currentProduct =>
      !!dealerProducts.map(product => product?.type).includes(currentProduct)
  );

  return !!arrayProducts.includes(true);
}

export default validateDealerHasProducts;
