import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  AlertActions,
  AlertContainer,
  AlertTitle,
  CancelButton,
  ConfirmButton,
  ErrorIcon,
  QuestionText
} from './styles';

type ChangeSaleTypeConfirmDialogProps = {
  open: boolean;
  onClose: (confirmed: boolean) => void;
};

function ChangeSaleTypeConfirmDialog({
  open,
  onClose,
}: ChangeSaleTypeConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md">
      <DialogContent>
        <AlertContainer>
          <ErrorIcon>!</ErrorIcon>
          <AlertTitle>Alterar tipo de venda</AlertTitle>
          <QuestionText>
            <span>
              Ao alterar o tipo de venda, todos os items da lista de pagamento e serviços adicionais serão removidos.
              <br />
              Você deseja prosseguir com esta ação?
            </span>
          </QuestionText>
        </AlertContainer>
      </DialogContent>
      <AlertActions>
        <CancelButton onClick={() => onClose(false)} variant="outlined">
          Cancelar
          </CancelButton>
        <ConfirmButton
          onClick={() => onClose(true)}
          color="primary"
          variant="outlined"
        >
          Alterar tipo de venda
          </ConfirmButton>
      </AlertActions>
    </Dialog>
  );
}

export default ChangeSaleTypeConfirmDialog;
