import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';
import StepperComponent from 'modules/financing/components/atoms/StepperComponent';
import { OrderStepperState } from 'modules/order/store/reducers';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import { stepperClasses } from './styles';

function OrderStepperContainer({ classes }) {
  const { currentDealer } = useSelector(state => state.dealerStore);
  const { steps, currentStep, completedSteps } = useSelector(
    state => state.order
  ) as OrderStepperState;
  const dispatch = useDispatch();

  const handleClickToGoStep = index => {
    dispatch(ActionCreators.setActiveStep(index));
  };

  const hasProductProposalOrderOEM = currentDealer.products
    ? currentDealer.products.some(
        product => product.type === 'PROPOSAL_ORDER_OEM'
      )
    : false;

  return (
    <StepperComponent
      steps={steps(hasProductProposalOrderOEM)}
      stepperConfig={{ currentStep, completedSteps }}
      classes={classes}
      handClickToGoStep={handleClickToGoStep}
    />
  );
}

export default withStyles(stepperClasses)(OrderStepperContainer);
