import React, { memo } from 'react';

// Context import
import { useChatContext } from 'pages/chats/ChatsContext';

// Constant import
import MessageTypes from 'constants/MessageTypes';

// Component import
import MessageAudio from '../../MessageAudio';
import MessageImage from '../../MessageImage';
import MessageVideo from '../../MessageVideo';
import MessageMedia from '../../MessageMedia';
import RepliedMessage from '../RepliedMessage';
import getImage from 'components/getImage';
import { Audio, Image, File } from '../TypesResponse';

// Style import
import { RepliedMessageContainer, MessageContainer } from './styles';

type TypeOfMessage = typeof MessageTypes[keyof typeof MessageTypes];

interface MessageComponentProps {
  type: TypeOfMessage;
  isAnswer: boolean;
}

const MessageResponse = props => {
  // Hooks
  const { currentProposal }: any = useChatContext();

  const {
    message,
    currentAudioPlaying,
    setCurrentAudioPlaying,
    audiosRef,
    focusOnTheMessage,
    sellerName,
  } = props;

  const {
    ownerName,
    message: messageToRespond,
    id,
  } = message?.originalProposalInteraction;

  const nameToRespondTo = `Você (${ownerName || sellerName})`;
  const hasInteractions =
    Array.isArray(currentProposal?.interactions) &&
    !!currentProposal?.interactions[0]?.id;
  const messageIndex =
    hasInteractions &&
    currentProposal?.interactions.findIndex(item => item.id === id);

  const currentMessageType = message.media?.type || MessageTypes.TEXT;
  const messageToRespondToType =
    message.originalProposalInteraction.media?.type || MessageTypes.TEXT;

  const audioTimeDuration = audiosRef?.current?.[message.id]?.duration ?? 0;

  const Message = ({ type, isAnswer }: MessageComponentProps) => {
    const finalMessageObject = isAnswer
      ? message.originalProposalInteraction
      : message;

    const imageId = finalMessageObject.media?.id;
    const imageVcode = finalMessageObject.media?.vcode;
    const sourceImage = getImage(
      { imageId, imageVcode },
      'auto',
      58,
      0
    ) as string;

    switch (type) {
      case MessageTypes.AUDIO:
        if (isAnswer) return <Audio audioTimeDuration={audioTimeDuration} />;
        return (
          <MessageAudio
            message={finalMessageObject}
            currentAudioPlaying={currentAudioPlaying}
            setCurrentAudioPlaying={setCurrentAudioPlaying}
            audiosRef={audiosRef}
          />
        );
      case MessageTypes.IMAGE:
        if (isAnswer) return <Image source={sourceImage} />;
        return <MessageImage message={finalMessageObject} />;
      case MessageTypes.VIDEO:
        if (isAnswer) return <File />;
        return <MessageVideo message={finalMessageObject} />;
      case MessageTypes.MEDIA:
        if (isAnswer) return <File />;
        return <MessageMedia message={finalMessageObject} />;
      case MessageTypes.RESPONSE:
        return <RepliedMessage message={finalMessageObject} />;
      default:
        return <p>{isAnswer ? messageToRespond : message.message}</p>;
    }
  };

  return (
    <>
      <RepliedMessageContainer onClick={() => focusOnTheMessage(messageIndex)}>
        <span>{nameToRespondTo}</span>

        <MessageContainer
          noSpace={messageToRespondToType === MessageTypes.TEXT}
        >
          {Message({ type: messageToRespondToType, isAnswer: true })}
        </MessageContainer>
      </RepliedMessageContainer>

      <MessageContainer noSpace={false}>
        {Message({ type: currentMessageType, isAnswer: false })}
      </MessageContainer>
    </>
  );
};

export default memo(MessageResponse);
