import styled from 'styled-components';
import { Popover as MUIPopover } from '@material-ui/core';

export const Popover = styled(MUIPopover)`
  overflow-y: scroll;
  overflow-x: hidden;

  &.open-date-filter .MuiPaper-root.MuiPopover-paper {
    overflow: initial;
    max-height: initial;
  }
`;

export const DateFilterButton = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #757575;
  border: 1px solid #e0e0e0;
  padding: 13.5px 8px 13.5px 16.5px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  white-space: nowrap;
  height: 100%;
  width: 100%;

  .svg-calendar-icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
    color: #757575;
  }

  .svg-arrow-icon {
    margin-left: 8px;
    font-size: 18px;
  }
`;

export const Container = styled.div`
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-top: 0.5rem;

  .filterButtons {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  .title {
    margin: 0 8px;
    color: #757575;
    font-family: 'Poppins Bold';
    font-size: 14px;
  }

  .filterItem {
    height: 40px;
    border-bottom: 1px solid #ebebeb;
    padding: 0 8px;
    color: #757575;
    align-items: center;
    display: flex;
    cursor: pointer;
    width: 100%;
    font-family: 'Poppins Regular';
  }

  .dataRangeContainer {
    display: flex;
    padding: 0px 8px;
  }

  .actionButtons {
    display: flex;
    padding: 12px 8px;
    border-top: 1px solid #ebebeb;
    justify-content: flex-end;
  }
`;

export const styles = {
  root: { flex: 1, width: '100%' },
};
