import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setCurrentDealer: ['dealer'],
  setDealerIsSaga: ['isSaga'],
  searchDealers: ['options'],
  setDealers: ['dealerList'],
  setDealersListIsLoading: ['isLoading'],
  setDealersByGroupList: ['dealersGroupList'],
  getDealersByGroupId: ['groupId'],
  getSellersByProposalId: ['dealerId', 'proposalId'],
  setSellersByProposalId: ['sellersByProposalObj'],
});

export const INITIAL_STATE = {
  currentDealer: {
    isSaga: false,
  },
  dealersList: {},
  dealersByGroupList: [],
  currentUserType: 'SELLER',
  dealersListIsLoading: true,
  sellersByProposalId: {
    isLoading: false,
    hasError: false,
    isUpdating: false,
    list: [],
  },
};

export const setCurrentDealer = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentDealer: {
      ...action.dealer,
      isSaga: false,
    },
  };
};

export const setDealerIsSaga = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    currentDealer: {
      ...state.currentDealer,
      isSaga: action.isSaga,
    },
  };
};

export const setDealersList = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dealersList: action.dealerList,
  };
};

export const setDealersListIsLoading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dealersListIsLoading: action.isLoading,
  };
};

export const setDealersByGroupList = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dealersByGroupList: action.dealersGroupList,
  };
};

export const setSellersByProposalId = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    sellersByProposalId: {
      ...state.sellersByProposalId,
      ...action.sellersByProposalObj,
    },
  };
};

export const HANDLERS = {
  [Types.SET_CURRENT_DEALER]: setCurrentDealer,
  [Types.SET_DEALERS]: setDealersList,
  [Types.SET_DEALERS_LIST_IS_LOADING]: setDealersListIsLoading,
  [Types.SET_DEALERS_BY_GROUP_LIST]: setDealersByGroupList,
  [Types.SET_SELLERS_BY_PROPOSAL_ID]: setSellersByProposalId,
  [Types.SET_DEALER_IS_SAGA]: setDealerIsSaga,
};

export default createReducer(INITIAL_STATE, HANDLERS);
