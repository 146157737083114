import React from 'react';
import styled from 'styled-components';
import TextInput from 'modules/financing/components/atoms/TextField';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const Line = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
`;

const LineEnd = styled(Line)`
  align-items: flex-end;
  font-family: Poppins Bold, Roboto, Helvetica, Arial, sans-serif;
`;

const Field = styled(Line)`
  flex-direction: column;
  align-items: initial;
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
`;

const Label = styled.div`
  font-family:  Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
  color: ${Colors.gray.dark};
  font-size: 12px;
  margin-bottom: 4px;
  text-align: left;
  b: {
    font-family: Poppins Bold, Roboto, Helvetica, Arial, sans-serif;
    font-weight: bold;  
  },
`;

const PlateBox = styled.div`
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  min-height: 128px;
  background-color: #f2f2f2;
`;

interface PlateSearchComponentProps {
  deal0km: boolean;
  plate: string;
  plateError: boolean;
  handleOnChangePlate?(): void;
  handleOnClickSubmit?(): void;
}

const PlateSearchComponent: React.FunctionComponent<PlateSearchComponentProps> = ({
  plate,
  plateError = false,
  handleOnChangePlate,
  handleOnClickSubmit,
}) => {
  return (
    <PlateBox>
      <Line>
        <Label>
          Coloque a placa do veículo e<b> preencha automaticamente </b> as
          informações abaixo
        </Label>
      </Line>

      <LineEnd>
        <Field>
          <TextInput
            data-test="deal-plate-search"
            label="Placa do veículo "
            helperText=""
            error={plateError}
            value={plate}
            onChange={handleOnChangePlate}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Click to search"
                    onClick={handleOnClickSubmit}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Field>
      </LineEnd>
    </PlateBox>
  );
};

export default PlateSearchComponent;
