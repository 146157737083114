import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import {
  ActionsContent,
  NextPanelButton,
  PreviousPanelButton,
} from 'modules/order/pages/OrderSteps/Proposal/steps/ProposalData/styles';
import DeliveryInfos from 'components/DeliveryInfos';

function Delivery({ handlePreviousPanel, handleNextPanel }) {
  const dispatch = useDispatch();
  const { queueData, proposalStep } = useSelector(state => state.order);

  useEffect(() => {
    if (
      queueData?.currentStepProposalQueue === 0 &&
      !!queueData?.orderPlacedAtTheAutomaker &&
      typeof queueData?.delivery?.forecastDate === 'undefined'
    ) {
      dispatch(
        ActionCreators.setQueueData({
          RequiredFieldsForCurrentStepCompleted: false,
        })
      );
    }

    if (
      (proposalStep === 'DELIVERY' && !queueData?.orderPlacedAtTheAutomaker) ||
      (queueData?.orderPlacedAtTheAutomaker &&
        typeof queueData?.delivery?.forecastDate !== 'undefined')
    ) {
      dispatch(
        ActionCreators.setQueueData({
          RequiredFieldsForCurrentStepCompleted: true,
        })
      );
    }
  }, [queueData?.orderPlacedAtTheAutomaker, queueData?.delivery?.forecastDate]);
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" flexDirection="column" marginLeft="6px" width="800px">
        <DeliveryInfos renderCheckBoxOrderMadeAtTheAssembler />
      </Box>
      <ActionsContent>
        <PreviousPanelButton onClick={handlePreviousPanel}>
          Voltar
        </PreviousPanelButton>
        <NextPanelButton
          onClick={handleNextPanel}
          disabled={
            queueData?.orderPlacedAtTheAutomaker &&
            !queueData?.delivery?.forecastDate
          }
        >
          Próximo
        </NextPanelButton>
      </ActionsContent>
    </Box>
  );
}

export default Delivery;
