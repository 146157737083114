import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import Cancel from '@material-ui/icons/CancelOutlined';
import EventBusy from '@material-ui/icons/EventBusy';
import getInitialsName from 'helpers/getInitialsName';
import ProposalStatusTip from 'components/ProposalStatusTip';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import { CommonLabel, OvalBigger, VehicleTitle, styles } from './styles';

function TableItemRow({ order, classes }) {
  const buildOvalLetter = (sellerName: string) => {
    return sellerName ? getInitialsName(sellerName) : <div>-</div>;
  };

  const proposalData = new Date(order.createdAt).toLocaleDateString();

  return (
    <TableRow
      tabIndex={-1}
      className={classes.root}
      onClick={() => {
        return;
      }}
      hover
    >
      <TableCell align="left" padding={'checkbox'}>
        <CommonLabel padding={'0px 14px'}>{proposalData}</CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>
          <Tooltip title={order.vehicle} placement="top">
            <VehicleTitle>{order.vehicle}</VehicleTitle>
          </Tooltip>
        </CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>{`#${order.proposalId}`}</CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>
          <Tooltip title={order.sellerName} placement="top">
            <OvalBigger>{buildOvalLetter(order.sellerName)}</OvalBigger>
          </Tooltip>
        </CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>
          {order.delayedScheduling ? (
            <div>
              <Tooltip title="Visita em atraso" placement="top">
                <EventBusy style={{ color: Colors.red.medium }} />
              </Tooltip>
            </div>
          ) : order.schedulingDone ? (
            <div>
              <Tooltip title="Visita agendada" placement="top">
                <CheckCircle style={{ color: Colors.green.medium }} />
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip title="Nenhuma visita agendada" placement="top">
                <Cancel style={{ color: Colors.red.medium }} />
              </Tooltip>
            </div>
          )}
        </CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>
          {order.futureScheduling ? (
            <div>
              <Tooltip title="Visita agendada" placement="top">
                <CheckCircle style={{ color: Colors.green.medium }} />
              </Tooltip>
            </div>
          ) : (
            <div>
              <Tooltip title="Nenhuma visita agendada" placement="top">
                <Cancel style={{ color: Colors.red.medium }} />
              </Tooltip>
            </div>
          )}
        </CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>
          <div style={{ display: 'flex' }}>
            <ProposalStatusTip status={order.status} />
          </div>
        </CommonLabel>
      </TableCell>
      <TableCell align="left" padding={'none'}>
        <CommonLabel>
          <div style={{ display: 'flex' }}>
            <ProposalStatusTip status={order.salesAssistantStatus} />
          </div>
        </CommonLabel>
      </TableCell>
    </TableRow>
  );
}

export default withStyles(styles)(TableItemRow);
