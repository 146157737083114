import React from 'react';
import TextInput from 'bit/mobiauto.web-ui-components.components.text-input/TextInput';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutoComplete from 'components/atoms/AutoComplete';
import * as S from './styles';

const FiltersComponent = ({
  onChangeSearch,
  state,
  setState,
  stateOptions,
  cityOptions,
  brandOptions,
  clearFilters,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextInput
          name="search"
          label="Buscar"
          value={state?.search}
          size="100%"
          onKeyPress={onChangeSearch}
          onChange={onChangeSearch}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Buscar" onClick={onChangeSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <AutoComplete
          name="state"
          label="Estado"
          options={stateOptions}
          value={state?.stateSelected?.value ? state?.stateSelected : null}
          onChange={(event: any) => {
            setState(prevState => ({
              ...prevState,
              stateSelected: { value: event?.value, label: event?.label },
            }));
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AutoComplete
          name="city"
          label="Cidade"
          options={cityOptions}
          value={state?.citySelected?.value ? state?.citySelected : null}
          onChange={(event: any) => {
            setState(prevState => ({
              ...prevState,
              citySelected: { value: event?.value, label: event?.label },
            }));
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <AutoComplete
          name="brand"
          label="Marca"
          options={brandOptions}
          value={state?.brandSelected?.value ? state?.brandSelected : null}
          onChange={(event: any) => {
            setState(prevState => ({
              ...prevState,
              brandSelected: { value: event?.value, label: event?.label },
            }));
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <S.CleanFilterText onClick={clearFilters}>
          Limpar filtros
        </S.CleanFilterText>
      </Grid>
    </Grid>
  );
};

export default FiltersComponent;
