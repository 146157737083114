import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'alsListServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default {
  getSellerProposals: function(dealerId, options = {}) {
    return request
      .get(`${domain}/dealer/${dealerId}/resale/proposal/v1.2`, options)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposals()`));
  },
  getBuyerProposals: function(dealerId, options = {}) {
    return request
      .get(`${domain}/buyer/${dealerId}/resale/proposal/v1.1`, options)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposals()`));
  },
};
