import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandlerTS';
import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
// import { requestModule as request} from "./index";

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'DealerDocumentsServices';

class DealerDocumentsServices {
  static postOfx = async (
    dealerId,
    ofxFile: File,
    bankAccountId: string | number
  ) => {
    const formData = new FormData();
    formData.append('file', ofxFile);

    return request
      .post(
        `${domain}/financial/${dealerId}/posting/v1.0/ofx?bankAccountId=${bankAccountId}`,
        formData
      )
      .then(buildHTTPResponseHandler(`${serviceName}.postOfx()`));
  };

  //CREATE_PRODUCT_INVOICE
  static createProductInvoice = async (
    dealerId: number,
    invoiceDto: { transactionalId: number }
  ) => {
    return request
      .post(`${domain}/financial/${dealerId}/productinvoice/v1.0`, invoiceDto)
      .then(buildHTTPResponseHandler(`${serviceName}.createProductInvoice()`));
  };

  static uploadEntryInvoice = async (
    dealerId: number,
    dealId: number,
    file
  ) => {
    return request
      .post(
        `${domain}/financial/${dealerId}/productinvoice/v1.0/upload?dealId=${dealId}`,
        file
      )
      .then(buildHTTPResponseHandler(`${serviceName}.uploadEntryInvoice()`));
  };

  static viewInvoice = async (dealerId: number, productInvoiceId: number) => {
    return request
      .get(`${domain}/financial/${dealerId}/productinvoice/v1.0/pdf`, {
        productInvoiceId,
      })
      .then(buildHTTPResponseHandler(`${serviceName}.viewInvoice()`));
  };

  static deletePostingInvoice = async (postingId: number | string) => {
    console.log(`DELTE INVOICE posting ${postingId}`);

    // const response = await request.delete(
    //   `posting/v1.0/invoice/${postingId}`);

    // const responseHandler = buildHTTPResponseHandler(
    //     `${serviceName}.deleteInvoice()`)

    //return responseHandler(response);
    return { success: true, data: null };
  };
}

export default DealerDocumentsServices;
