import React from 'react';

import InvoiceCard from 'components/atoms/InvoiceCard';

import IListInvoiceCard from './ListInvoiceTypes';

const ListInvoiceCardComponent: React.FC<IListInvoiceCard> = ({ title, invoice }) => {
  return (
    <>
      <InvoiceCard
        key={`key-${invoice?.chaveNfe}`}
        title={title}
        status={invoice?.status}
        date={invoice?.dataEmissao}
        keyValue={invoice?.chaveNfe}
        moviment={invoice?.movimento}
        number={invoice?.numero}
        refNote={invoice?.notaDeRef}
        mt={2}
      />
    </>
  );
};

export default ListInvoiceCardComponent;
