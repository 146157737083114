import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'ProviderService';
const domainPainelDigital = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class ProviderServices {
  static getTabs = dealerId => {
    return request
      .get(`${domainPainelDigital}/dealer/${dealerId}/v1.0/types`)
      .then(buildHTTPResponseHandler(`${serviceName}.getTabs()`));
  };

  static connect = (
    dealerId,
    providerId,
    providerType,
    credentialsDto = {}
  ) => {
    return request
      .post(
        `${domainPainelDigital}/dealer/${dealerId}/v1.0/provider/${providerId}/type/${providerType}/connect`,
        credentialsDto
      )
      .then(buildHTTPResponseHandler(`${serviceName}.connect()`));
  };

  static disconnectProvider = (dealerId, providerId, integrationType) => {
    return request
      .delete(
        `${domainPainelDigital}/dealer/${dealerId}/v1.0/provider/${providerId}/type/${integrationType}/disconnect`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.disconnectProvider()`));
  };

  static changeActivationProvider = (
    dealerId,
    providerId,
    integrationType,
    status
  ) => {
    return request
      .put(
        `${domainPainelDigital}/dealer/${dealerId}/v1.0/provider/${providerId}/type/${integrationType}/status/${status}
    `,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.changeActivationProvider()`)
      );
  };

  static getProviderByType = (dealerId, type) => {
    return request
      .get(`${domainPainelDigital}/dealer/${dealerId}/v1.0/type/${type}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getProviderByType()`));
  };

  static getProviderLeadByDealer = (dealerId, leadCreation = false) => {
    return request
      .get(
        `${domainPainelDigital}/dealer/${dealerId}/proposals/v1.0/providers`,
        leadCreation ? { action: 'CREATE_LEAD' } : {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProviderLeadByDealer()`)
      );
  };

  static getProviderLeadByDealerCallCenter = dealerId => {
    return request
      .get(`${domainPainelDigital}/provider/v1.0/${dealerId}/options/lead`)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getProviderLeadByDealerCallCenter()`
        )
      );
  };

  static getOriginsOptions = (dealerId, leadCreation = false) => {
    return request
      .get(
        `${domainPainelDigital}/dealer/${dealerId}/proposals/v1.0/origins`,
        leadCreation ? { action: 'CREATE_LEAD' } : {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getOriginsOptions()`));
  };

  static putAnswerProvider = (
    dealerId,
    providerId,
    integrationType,
    answerProvider
  ) => {
    return request
      .put(
        `${domainPainelDigital}/integration/v1.0/provider/dealer/${dealerId}/${providerId}/${integrationType}/${answerProvider}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.putAnswerProvider()`));
  };

  static getFacebookBusinessPage = dealerId => {
    return request
      .get(
        `${domainPainelDigital}/integration/v1.0/dealer/${dealerId}/facebook/pages`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getFacebookBusinessPage()`)
      );
  };
}
