export const OPTIONS_CLIENT_APPEARED = [
  {
    label: 'Sim',
    value: 1,
    done: true,
  },
  {
    label: 'Não',
    value: 2,
    done: false,
  },
  {
    label: 'Sem Resposta',
    value: 3,
    done: null,
  },
];
