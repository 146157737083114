import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import { useChatContext } from 'pages/chats/ChatsContext';
import { highAccessUserTypes } from 'constants/userAccess';

import lazyRetry from 'helpers/lazyRetry';
import { returnHoursOrMin } from 'helpers/returnHoursOrMin';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';
import { usePermissions } from 'hooks/usePermissions';

import CardSellerProfileAndLeadInfosComponent from '../../CardSellerProfileAndLeadInfos';

import { Split, Button } from './styles';

const ChangeSalesAssistantByDialog = React.lazy(() =>
  lazyRetry(() => import('../ChangeSalesAssistantByDialog'))
);

const SearchAndRescueInfo = ({ closedProposal }) => {
  const { userType } = useSelector(state => state.userStore);
  const { currentProposal, getCurrentProposal } = useChatContext();

  const [state, setState] = useState({
    openModalChangeSeller: false,
    thisTypeUserCanChange: false,
  });

  const checkUserCanChangeSeller = () => {
    let userTypeStore = null;

    if (!checkCallCenterOsSearchAndRescueRoute()) {
      userTypeStore = userType;
    } else {
      userTypeStore = 'SALES_ASSISTENT';
    }

    setState(prevState => ({
      ...prevState,
      thisTypeUserCanChange: [
        ...highAccessUserTypes,
        'SALES_ASSISTENT',
      ].includes(userTypeStore),
    }));
  };

  const handleClickOpenModalChangeSalesAssistant = () => {
    setState(prevState => ({
      ...prevState,
      openModalChangeAssistante: !state.openModalChangeAssistante,
    }));
  };

  const handleClickCloseModalChangeSalesAssistant = () => {
    setState(prevState => ({
      ...prevState,
      openModalChangeAssistante: false,
    }));
    getCurrentProposal(currentProposal.dealerId, currentProposal.id);
  };

  const { hasPermission } = usePermissions();
  const canChangeAgent = hasPermission('PROPOSAL_CHANGE_AGENT');

  useEffect(() => {
    checkUserCanChangeSeller();
  }, []);

  const text = currentProposal.searchAndRescueAssistantName
    ? 'Alterar Assistente de Vendas'
    : 'Adicionar Assistente de Vendas';

  const enableChange = checkCallCenterOsSearchAndRescueRoute()
    ? canChangeAgent
    : !closedProposal && canChangeAgent;

  return (
    <React.Fragment>
      <div style={{ padding: '8px' }}>
        {currentProposal.searchAndRescueAssistantName ? (
          <Split>
            <Box component="div" justifyContent="center">
              <CardSellerProfileAndLeadInfosComponent
                salesName={currentProposal?.searchAndRescueAssistantName}
                occupation="Assistente de Vendas"
                serviceTime={
                  currentProposal?.searchAndRescueAssistantTma
                    ? returnHoursOrMin(
                        currentProposal?.searchAndRescueAssistantTma
                      )
                    : ''
                }
                buttonText="Alterar assistente de vendas"
                onClick={handleClickOpenModalChangeSalesAssistant}
                enableChangeSeller={enableChange}
              />
            </Box>
          </Split>
        ) : (
          <div style={{ pading: 16 }}>
            Nenhum assistente de vendas vinculado.
          </div>
        )}
        {!currentProposal.searchAndRescueAssistantName && enableChange && (
          <Button onClick={handleClickOpenModalChangeSalesAssistant}>
            {text}
          </Button>
        )}
      </div>
      {state.openModalChangeAssistante && (
        <ChangeSalesAssistantByDialog
          open={state.openModalChangeSalesAssistant}
          handleClose={handleClickCloseModalChangeSalesAssistant}
        />
      )}
    </React.Fragment>
  );
};

export default SearchAndRescueInfo;
