import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
const serviceName = 'ProposalService';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

interface ProposalsFilter {
  status?: string;
  sellerId?: string | number;
  periodEnd?: number;
  periodInit?: number;
  departmentCode?: string | number;
  origin?: string;
  providerId?: string | number;
  search?: string;
  exteriorColors?: [] | any;
  modelId?: object;
  trimsId?: [] | any;
}

export default class QueueServices {
  static createProposal = (
    dealerId,
    proposalId,
    proposalOrderOEMDto,
    sentAutomaker = false
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/order/oem/v1.0/${proposalId}?sentAutomaker=${sentAutomaker}`,
        proposalOrderOEMDto
      )
      .then(buildHTTPResponseHandler(`${serviceName}.createProposal()`) as any);
  };

  static alterStatusProposal = (dealerId, proposalId, status) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/${proposalId}/order/oem/v1.0/${status}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.alterStatusProposal()`) as any
      );
  };

  static alterStatusProposalForFinished = (
    dealerId,
    proposalId,
    proposalStatus,
    params
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/${proposalId}/order/oem/v1.0/finish/${proposalStatus}${
          proposalStatus === 'WITH_ORDER' && params.orderCpfCnpj
            ? `?orderCpfCnpj=${params.orderCpfCnpj}`
            : ''
        }${
          proposalStatus === 'FINISHED_BUT_NOT_CONCLUDED' && params.reasonId
            ? `?reasonId=${params.reasonId}`
            : ''
        }`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.alterStatusProposalForFinished()`
        ) as any
      );
  };

  static alterStatusProposalForTransit = (
    dealerId,
    proposalId,
    deliveryDate,
    externalId
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/${proposalId}/order/oem/v1.0/transit?deliveryDate=${deliveryDate}&externalId=${externalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.alterStatusProposalForTransit()`
        ) as any
      );
  };

  static alterStatusProposalForRequestedAutomaker = (
    dealerId,
    proposalId,
    deliveryDate,
    externalId
  ) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposal/${proposalId}/order/oem/v1.0/request-automaker?deliveryDate=${deliveryDate}&externalId=${externalId}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.alterStatusProposalForRequestedAutomaker()`
        ) as any
      );
  };

  static getProposalsQueue = (dealerId, options: ProposalsFilter) => {
    const params = {
      sellersId: options.sellerId || null,
      exteriorColor: options.departmentCode ? options.departmentCode : null,
      query: options.search,
      exteriorColors:
        options.exteriorColors != 'undefined' ? options.exteriorColors : null,
      modelId: options.modelId ? options.modelId : null,
      trimsId: options.trimsId != 'undefined' ? options.trimsId : null,
    };
    const url = `${domain}/dealer/${dealerId}/proposal/order/oem/v1.0/${options.periodInit}/${options.periodEnd}`;
    return request
      .get(url, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalsQueue()`) as any
      );
  };

  static getProposalQueueForId = (dealerId, proposalId) => {
    return request
      .get(`${domain}/dealer/${dealerId}/proposal/${proposalId}/order/oem/v1.0`)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getProposalQueueForId()`
        ) as any
      );
  };

  static cancelOrder = (dealerId, proposalId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/proposal/${proposalId}/order/oem/v1.0/cancel`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.cancelOrder()`) as any);
  };
}
