import { useSelector } from 'react-redux';

interface Props {
  hasProduct: (product: string) => boolean;
}

export default function useProduct(): Props {
  const { products } = useSelector(
    ({ dealerStore }: any) => dealerStore.currentDealer
  );

  const hasProduct = (product: string) => {
    return products?.some(({ type }: any) => type === product);
  };

  return { hasProduct };
}
