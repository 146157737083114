// Em conformidade com a Resolução 780/2019 CONTRAN, de 26 de junho de 2019,
// a expressão regular abaixo valida todas as placas em uso no Brasil
// incluindo aquelas no formato pré-Mercosul:

function validadePlate(plate) {
  const regex = '[A-Z]{3}[0-9][0-9A-Z][0-9]{2}';
  return !!plate.match(regex);
}

export default validadePlate;
