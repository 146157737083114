import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import * as moment from 'moment';

export default function AnnotationDetailDialog({
  annotation,
  open,
  handleClose,
}) {
  const reminder = moment(annotation.reminderDate).format(
    'DD/MM/YYYY [às] HH:mm'
  );
  const createdAt = moment(annotation.date).format('DD/MM/YYYY [às] HH:mm');

  const DialogHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .created-at {
      color: lightgray;
    }
  `;

  const DialogFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  `;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth={true}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <DialogHeader>
            <span>Detalhe da anotação</span>
            <span className="created-at">Criado dia {createdAt}</span>
          </DialogHeader>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {annotation.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogFooter>
            <span>{annotation.useReminder && <>Lembrar dia {reminder}</>}</span>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogFooter>
        </DialogActions>
      </Dialog>
    </div>
  );
}
