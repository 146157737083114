import { all, call, put, takeLatest } from 'redux-saga/effects';
import DealerServices from 'services/dealerServices';
import CallCenterServices from 'services/callCenterServices';
import {
  Types as DealerTypes,
  Creators as DealerCreators,
} from 'modules/resale/store/ducks/dealer';
import ActionCreators from 'modules/resale/store/reducers/leads/actionCreators';
import moment from 'moment';
import {
  TODAY_END_DAY,
  THIRTY_DAYS_AGO,
  TODAY_START_DAY,
} from 'helpers/predefinedDates';

function* searchDealers(action) {
  try {
    yield put(DealerCreators.setDealersListIsLoading(true));
    const response = yield call(
      DealerServices.searchDealersByConsultor,
      action.options
    );
    if (response.success) {
      yield put(DealerCreators.setDealers(response.data));
    } else {
      yield put(DealerCreators.setDealers({}));
    }
    yield put(DealerCreators.setDealersListIsLoading(false));
  } catch (e) {
    yield put(DealerCreators.setDealersListIsLoading(false));
    console.log(e);
  }
}

function* changeDateBySetCurrentDealer(action) {
  if (action.dealer?.daysToFilterLead) {
    yield put(
      ActionCreators.setFilterDate({
        initialDate: moment(TODAY_START_DAY)
          .subtract(action.dealer?.daysToFilterLead, 'days')
          .valueOf(),
        finalDate: moment(TODAY_END_DAY).valueOf(),
        filterSelectedId: 'personalized',
      })
    );
  } else {
    yield put(
      ActionCreators.setFilterDate({
        initialDate: moment(THIRTY_DAYS_AGO).valueOf(),
        finalDate: moment(TODAY_END_DAY).valueOf(),
        filterSelectedId: 'personalized',
      })
    );
  }
}

function* getDealersByGroupId(action) {
  try {
    const result = yield call(
      CallCenterServices.getDealersByGroup,
      action.groupId
    );
    const { data, success } = result;
    if (success) {
      yield put(DealerCreators.setDealersByGroupList(data));
    } else {
      yield put(DealerCreators.setDealersByGroupList([]));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getSellersByProposalId(action) {
  try {
    yield put(
      DealerCreators.setSellersByProposalId({
        isLoading: true,
        list: [],
      })
    );
    const result = yield call(
      DealerServices.getSellers,
      action.dealerId,
      action.proposalId
    );
    const { data, success } = result;
    if (success) {
      yield put(
        DealerCreators.setSellersByProposalId({
          hasError: false,
          isLoading: false,
          list: data,
        })
      );
    } else {
      yield put(
        DealerCreators.setSellersByProposalId({
          hasError: false,
          isLoading: false,
          list: [],
        })
      );
    }
  } catch (e) {
    yield put(
      DealerCreators.setSellersByProposalId({
        hasError: true,
        isLoading: false,
        list: [],
      })
    );
    console.log(e);
  }
}

export default all([
  takeLatest(DealerTypes.SEARCH_DEALERS, searchDealers),
  takeLatest(DealerTypes.GET_DEALERS_BY_GROUP_ID, getDealersByGroupId),
  takeLatest(DealerTypes.SET_CURRENT_DEALER, changeDateBySetCurrentDealer),
  takeLatest(DealerTypes.GET_SELLERS_BY_PROPOSAL_ID, getSellersByProposalId),
]);
