import React from 'react';
import Wrapper from 'components/Wrapper';
import VehicleFormDataItem from './VehicleFormDataItem';

type Vehicle = {
  vin?: string;
  renavam?: string;
  modelYear?: number;
  productionYear?: number;
  color?: string;
  interiorColor?: string;
};

interface VehicleFormDataProps {
  vehicle: Vehicle;
}

const VehicleFormData = ({ vehicle }: VehicleFormDataProps) => {
  return (
    <Wrapper flex gap="md">
      {vehicle?.vin?.length > 0 && vehicle?.renavam?.length > 0 ? (
        <>
          <VehicleFormDataItem title="Chassi:" value={vehicle.vin} />
          <VehicleFormDataItem title="Renavan:" value={vehicle.renavam} />
        </>
      ) : (
        <>
          {vehicle?.productionYear && (
            <VehicleFormDataItem
              title="Ano de Fabricação:"
              value={vehicle.productionYear}
            />
          )}
          {vehicle?.modelYear && (
            <VehicleFormDataItem
              title="Ano do Modelo:"
              value={vehicle.modelYear}
            />
          )}
          {vehicle?.color && (
            <VehicleFormDataItem title="Cor Externa:" value={vehicle.color} />
          )}
          {vehicle?.interiorColor && (
            <VehicleFormDataItem
              title="Cor Interna:"
              value={vehicle.interiorColor}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default VehicleFormData;
