import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import TextField from 'modules/financing/components/atoms/TextField';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import ExpansionPanel from 'components/ExpansionPanel';
import { CPFCNPJMaskCustom, PhoneMaskCustom } from 'components/MaskTextInput';

//EXPANSIONS_TITLES
const Employment = <ExpansionPanelTitle title={'Dados do cliente'} />;

const ClientData = props => {
  const queueFinished = ['FINISHED', ''].includes(
    props.queueData?.status.value
  );
  return (
    <div>
      <ExpansionPanel panel={{ title: Employment }} startExpanded>
        <Grid container spacing={1}>
          {/* ROW */}
          <Grid item xs={6}>
            <TextField
              label="Nome*"
              variant="outlined"
              inputProps={{
                maxLength: 500,
              }}
              value={props.formik.values.name}
              name="name"
              onChange={event => {
                props.formik.handleChange({
                  target: {
                    name: 'name',
                    value: event.target.value,
                  },
                });
              }}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.name && Boolean(props.formik.errors.name)
              }
              helperText={props.formik.touched.name && props.formik.errors.name}
              disabled={queueFinished}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="CPF/CNPJ*"
              InputProps={{
                inputComponent: CPFCNPJMaskCustom,
              }}
              value={props.formik.values.cpfCnpj}
              name="cpfCnpj"
              onChange={event => {
                props.formik.handleChange({
                  target: {
                    name: 'cpfCnpj',
                    value: event.target.value,
                  },
                });
              }}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.cpfCnpj &&
                Boolean(props.formik.errors.cpfCnpj)
              }
              helperText={
                props.formik.touched.cpfCnpj && props.formik.errors.cpfCnpj
              }
              disabled={queueFinished}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="phone"
              label="Telefone*"
              InputProps={{
                inputComponent: PhoneMaskCustom,
              }}
              value={props.formik.values.phone}
              name="phone"
              onChange={event => {
                props.formik.handleChange({
                  target: {
                    name: 'phone',
                    value: event.target.value,
                  },
                });
              }}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.phone && Boolean(props.formik.errors.phone)
              }
              helperText={
                props.formik.touched.phone && props.formik.errors.phone
              }
              disabled={queueFinished}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="email"
              label="E-mail*"
              value={props.formik.values.email}
              name="email"
              onChange={event => {
                props.formik.handleChange({
                  target: {
                    name: 'email',
                    value: event.target.value,
                  },
                });
              }}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.email && Boolean(props.formik.errors.email)
              }
              helperText={
                props.formik.touched.email && props.formik.errors.email
              }
              disabled={queueFinished}
            />
          </Grid>
        </Grid>
      </ExpansionPanel>
    </div>
  );
};

export default memo(ClientData);
