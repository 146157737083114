import React from 'react';
import { Route } from 'react-router-dom';
class DynamicImports extends React.PureComponent {
  render() {
    const { routes } = this.props;
    return (
      routes &&
      routes.map(route => (
        <Route
          key={route.key}
          {...route}
          render={props => <route.Component {...route} {...props} />}
        />
      ))
    );
  }
}

export default DynamicImports;
