import styled from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import { Divider, DialogActions, CircularProgress } from '@material-ui/core';

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleText = styled.div`
  font-family: 'Poppins Bold';
  font-weight: bold;
  line-height: 1.5;
  color: ${Colors.gray.dark};
`;

export const SubTitle = styled.b`
  font-size: 14px;
`;

export const BookInfo = styled.p`
  font-size: 14px;
  color: #757575;
  line-height: 1em;
`;

export const BookDivider = styled(Divider)`
  margin: 16px 0 !important;
`;

export const CancelButton = styled(Button)`
  margin: 0 5px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  background: #f44336 !important;
`;

export const DialogActionsContainer = styled(DialogActions)`
  padding: 16px !important;
`;

export const MiniLoading = styled(CircularProgress)`
  color: white !important;
  position: absolute;
  right: 8px;
`;
