import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';

import * as S from './styles';

const InformationTooltipIcon = ({
  text,
  position = 'initial',
  top = '4px',
  right = '4px',
  color = '#757575',
  fontSize = '18px',
}) => {
  return (
    <S.Container position={position} top={top} right={right}>
      <Tooltip title={text}>
        <ErrorOutlineIcon style={{ fontSize: fontSize, color }} />
      </Tooltip>
    </S.Container>
  );
};

export default InformationTooltipIcon;
