import React from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import { ReactComponent as LogoBancoPan } from 'assets/Logo-BancoPAN.svg';
import formatAmount from 'helpers/formatAmount';
import getImage from 'components/getImage';
import ProposalCard from './components/ProposalCard';

const SimulationCard = ({ simulation, simulationIndex, simulationItem }) => {
  const linkImage = getImage(
    {
      imageId:
        simulationItem?.dealerPJFinancingInstitution?.financingInstitution
          ?.imageId,
      imageVcode:
        simulationItem?.dealerPJFinancingInstitution?.financingInstitution
          ?.imageVcode,
    },
    100,
    35
  );
  return (
    <Box
      component="div"
      display="flex"
      flex={1}
      flexDirection="column"
      borderRadius="4px"
      border="1px solid #E0E0E0"
      padding="16px"
      mb="16px"
    >
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Box component="div">
          <Box
            component="div"
            fontSize={'14px'}
            lineHeight="12px"
            color="#009A8A"
            fontFamily={'Poppins Bold'}
          >
            Simulação {simulationIndex + 1}
          </Box>
          <Box
            component="div"
            color={'#757575'}
            fontSize={'12px'}
            lineHeight="18px"
          >
            {simulation?.date ||
              (simulationItem?.updatedAt &&
                moment(simulation?.date || simulationItem?.updatedAt).format(
                  'DD/MM/YYYY [ás] HH:mm'
                ))}
          </Box>
        </Box>
        {simulationItem?.dealerPJFinancingInstitution?.financingInstitution
          ?.imageId ? (
          <Tooltip
            title={
              simulationItem?.dealerPJFinancingInstitution?.financingInstitution
                ?.name
            }
          >
            <img
              src={String(linkImage)}
              width="35px"
              height="auto"
              alt={`logo ${simulationItem?.dealerPJFinancingInstitution?.financingInstitution?.name}`}
            />
          </Tooltip>
        ) : (
          !simulationItem?.coldSimulation && (
            <div>
              <LogoBancoPan />
            </div>
          )
        )}
      </Box>
      <Divider />
      <Box component="div" mb={2} mt={2}>
        <Box component="div" fontSize={'14px'} fontFamily={'Poppins Bold'}>
          Condições
        </Box>
        <Box component="div" color={'#757575'} lineHeight="18px">
          Entrada:{' '}
          <Box component="span" color={'#424242'}>
            {formatAmount(
              simulation?.inputValue || simulationItem?.downPayment,
              'PT-BR',
              'R$',
              true
            )}
          </Box>
        </Box>
        {(!!simulation?.vehiclePrice || !!simulation?.vehicle?.price) &&
          (!!simulation?.inputValue || !!simulationItem?.downPayment) && (
            <Box component="div" color={'#757575'} lineHeight="18px">
              Valor a ser liberado:{' '}
              <Box component="span" color={'#424242'}>
                {formatAmount(
                  (simulation?.vehiclePrice || simulation?.vehicle?.price) -
                    (simulation?.inputValue || simulationItem?.downPayment),
                  'PT-BR',
                  'R$',
                  true
                )}
              </Box>
            </Box>
          )}
        {/* <div>UF do licenciamento: São Paulo - SP</div> */}
      </Box>
      <Box component="div">
        <Box component="div" fontSize={'14px'} fontFamily={'Poppins Bold'}>
          Propostas
        </Box>
        <Grid container spacing={1}>
          {simulationItem?.simulations ? (
            simulationItem?.simulations?.map((simulation, index) => {
              return (
                <ProposalCard
                  key={index}
                  text={`${simulation?.installment}x ${
                    simulation?.installmentValue
                      ? `de ${formatAmount(
                          simulation?.installmentValue,
                          'PT-BR',
                          'R$'
                        )}`
                      : ''
                  } `}
                  error={simulation?.error}
                  creditStatus={simulation?.message}
                />
              );
            })
          ) : (
            <ProposalCard
              key={simulationIndex}
              text={`${simulationItem?.term}x ${
                simulationItem?.installmentValue
                  ? `de ${formatAmount(
                      simulationItem?.installmentValue,
                      'PT-BR',
                      'R$'
                    )}`
                  : ''
              } `}
              error={simulationItem?.error}
              creditStatus={simulationItem?.message}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default SimulationCard;
