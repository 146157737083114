import React from 'react';
import { CardButton, CardInfos, CardWrapper, ContentInfo, Row } from '../style';
import _ from 'lodash';

interface HistoryCardProps {
  id: string;
  date: string;
  partner: string;
  amount: string;
  handleOpenDetails: (id: number) => void;
}

const HistoryCard = ({
  id,
  date,
  partner,
  amount,
  handleOpenDetails,
}: HistoryCardProps) => {
  return (
    <CardWrapper>
      <CardInfos>
        <Row>
          <ContentInfo>#{id}</ContentInfo>
          <ContentInfo>{date}</ContentInfo>
        </Row>
        <Row>
          <ContentInfo>Parceiro:</ContentInfo>
          <ContentInfo>{_.capitalize(partner)}</ContentInfo>
        </Row>
        <Row>
          <ContentInfo>Entrada:</ContentInfo>
          <ContentInfo className="bold">{amount}</ContentInfo>
        </Row>
      </CardInfos>
      <CardButton
        variant="outlined"
        onClick={() => handleOpenDetails(Number(id))}
      >
        Ver
      </CardButton>
    </CardWrapper>
  );
};

export default HistoryCard;
