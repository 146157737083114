/* eslint-disable @typescript-eslint/camelcase */
import { decorate, action, observable, set } from 'mobx';
import DealerSiteService from 'services/dealerSiteService';
import remotedev from 'mobx-remotedev';

const socialFramework = {
  link: null,
  active: false,
  id: null,
};

const whiteColor = '#FFFFFF';

const pageFramework = {
  id: null,
  dealer_site_theme_id: null,
  name: null,
  active: null,
  title: null,
  title_text_color: '#FFFFFF',
  created_at: null,
  updated_at: null,
  text: null,
  images: [],
};

const _defaultInitialState = {
  messages: {
    data: [],
    loading: true,
  },
  sociais: {
    data: {
      // keep order
      facebook: { ...socialFramework, social_contact_list_id: 1 },
      youtube: { ...socialFramework, social_contact_list_id: 3 },
      instagram: { ...socialFramework, social_contact_list_id: 2 },
      twitter: { ...socialFramework, social_contact_list_id: 4 },
    },
    loading: true,
  },
  settings: {
    data: {
      title: null,
      domain: null,
      subdomain: null,
    },
    loading: true,
  },
  google: {
    data: {
      analytics: null,
      tagManager: null,
    },
    loading: true,
  },
  themes: {
    data: [],
    list: [],
    loading: false,
    dealerId: null,
    themeId: null,
    siteThemeId: null,
    checkTheme: false,
  },
  colors: {
    data: {
      site: {
        main: whiteColor,
        text: whiteColor,
        background: whiteColor,
        buttonSelected: whiteColor,
      },
      card: {
        text1: whiteColor,
        text2: whiteColor,
        background: whiteColor,
      },
      filter: {
        text: whiteColor,
        background: whiteColor,
      },
      form: {
        text: whiteColor,
        background: whiteColor,
      },
      header: {
        text: whiteColor,
        background: whiteColor,
      },
      footer: {
        text: whiteColor,
        background: whiteColor,
      },
    },
    loading: true,
  },
  pages: {
    home: { ...pageFramework, loading: false, name: 'home' },
    list: { ...pageFramework, loading: false, name: 'list' },
    about: { ...pageFramework, loading: false, name: 'about' },
    location: {
      locations: [],
      loading: false,
      name: 'location',
    },
    contact: { ...pageFramework, loading: false, name: 'contact' },
    loading: true,
    financing: { ...pageFramework, loading: false, name: 'financing' },
  },
  galleryImages: {
    data: [],
    bannerTags: [],
    bannersSelected: [],
    loading: true,
    loadingModal: true,
  },
  snackbar: {
    variant: '',
    message: '',
    isOpen: false,
    autoHideDuration: 1000,
  },
};

class DealerSiteStore {
  constructor(initialState) {
    this.setInitialState(initialState);
  }

  setInitialState(state = _defaultInitialState) {
    this.messages = state.messages;
    this.sociais = state.sociais;
    this.settings = state.settings;
    this.google = state.google;
    this.themes = state.themes;
    this.pages = state.pages;
    this.colors = state.colors;
    this.galleryImages = state.galleryImages;
    this.snackbar = state.snackbar;
  }

  snackbarManagement = (variant = '', message = '', open = false) => {
    this.updateField('snackbar', {
      variant: variant,
      message: message,
      isOpen: open,
    });
  };

  startSiteTheme = async dataParam => {
    try {
      if (!dataParam.name || !dataParam.url_internal) {
        this.snackbarManagement(
          'error',
          'O campo titulo e o campo subdomínio não podem ser vazio.',
          true
        );
        return;
      }
      const response = await DealerSiteService.startSiteTheme({
        dealer_id: this.themes.dealerId,
        ...dataParam,
      });

      this.updateField('themes', {
        ...this.themes,
        loading: false,
        themeId: response.data.dealer_site_theme_id,
        checkTheme: true,
      });
      this.updateField('settings', {
        ...this.settings,
        data: {
          domain: response.data.url_external,
          subdomain: response.data.url_internal,
          title: dataParam.name,
        },
      });
      this.snackbarManagement('success', 'Site criado com sucesso!', true);
    } catch (error) {
      this.snackbarManagement('error', 'Ocorreu um erro ao criar o site', true);
    }
  };

  updateSettingsData = async data => {
    try {
      if (!data.name || !data.url_internal) {
        this.snackbarManagement(
          'error',
          'O campo titulo e o campo subdomínio não podem ser vazio.',
          true
        );
        return;
      }
      await DealerSiteService.updateSettingsData({
        dealer_site_theme_id: this.themes.themeId,
        name: data.name,
      });

      this.updateField('settings', {
        ...this.settings,
        data: {
          ...this.data,
          dealer_site_theme_id: this.themes.themeId,
          domain: data.url_external,
          subdomain: data.url_internal,
          title: data.name,
        },
      });
      this.snackbarManagement(
        'success',
        'Configuração salva com sucesso!',
        true
      );
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao salvar as configurações',
        true
      );
    }
  };

  getSettingsData = async () => {
    this.updateField('settings', {
      ...this.settings,
      loading: true,
    });

    try {
      const response = await DealerSiteService.getSettingsData({
        dealer_site_theme_id: this.themes.themeId,
      });

      this.updateField('settings', {
        ...this.settings,
        data: {
          title: response.data.name,
          domain: response.data.url_external,
          subdomain: response.data.url_internal,
        },
        loading: false,
      });
    } catch (error) {
      console.log(
        '>> ERROR: falha no retorno dos dados de configuração',
        error
      );
    }
  };

  getLocationsData = async () => {
    try {
      const response = await DealerSiteService.getLocations({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
      });

      this.updateField('pages', {
        ...this.pages,
        location: {
          ...this.pages.location,
          locations: response.data,
        },
        loading: false,
      });
    } catch (error) {
      console.log(
        '>> ERROR: falha no retorno dos dados de configuração',
        error
      );
    }
  };

  updateLocationsData = async data => {
    try {
      const stores = [];
      data.map(({ name, id, other_dealer_id, store_address }) =>
        stores.push({
          name,
          id,
          other_dealer_id,
          ...store_address,
        })
      );

      await DealerSiteService.updateLocations({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        store: stores,
      });

      await this.getLocationsData();

      this.snackbarManagement(
        'success',
        'Localizações salvas com sucesso!',
        true
      );
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao salvar as localizações.',
        true
      );
    }
  };

  getMessages = async data => {
    try {
      const response = await DealerSiteService.getMessages({
        ...data,
        dealer_site_theme_id: this.themes.themeId,
      });

      this.updateField('messages', {
        ...this.messages,
        data: response.data.data,
        loading: false,
      });
    } catch (error) {
      console.log('>> ERROR: falha no retorno das mensagens', error);
    }
  };

  getSociais = async () => {
    try {
      const response = await DealerSiteService.getSociais({
        dealer_site_theme_id: this.themes.themeId,
      });

      const sociais = { ...this.sociais.data };
      response.data.map(({ social_contact_list, value, active }) => {
        if (social_contact_list.id === 1) {
          sociais.facebook = {
            link: value,
            active,
            social_contact_list_id: 1,
          };
        }

        if (social_contact_list.id === 2) {
          sociais.instagram = {
            link: value,
            active,
            social_contact_list_id: 2,
          };
        }

        if (social_contact_list.id === 3) {
          sociais.youtube = {
            link: value,
            active,
            social_contact_list_id: 3,
          };
        }

        if (social_contact_list.id === 4) {
          sociais.twitter = {
            link: value,
            active,
            social_contact_list_id: 4,
          };
        }
      });

      this.updateField('sociais', {
        ...this.sociais,
        data: sociais,
        loading: false,
      });
    } catch (error) {
      this.updateField('sociais', {
        ...this.sociais,
        loading: false,
      });
    }
  };

  updateSociais = data => {
    try {
      const sociais = Object.keys(data).map(i => data[i]);
      sociais.map(async social => {
        DealerSiteService.updateSocial({
          ...social,
          value: social.link,
          dealer_site_theme_id: this.themes.themeId,
        });
      });

      this.updateField('sociais', {
        loading: false,
        data,
      });
      this.snackbarManagement(
        'success',
        'informações salvas com sucesso!',
        true
      );
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao salvar as informações.',
        true
      );
    }
  };

  getValueByName = (obj, name) => {
    return obj.find(element => element.name === name)?.value || null;
  };

  getColors = async () => {
    try {
      const response = await DealerSiteService.getColors({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
      });

      this.updateField('colors', {
        ...this.colors,
        data: {
          site: {
            main: response.data.basic_colors[0].buttons_and_icons || whiteColor,
            text: response.data.basic_colors[0].text || whiteColor,
            background: response.data.basic_colors[0].background || whiteColor,
          },
          card: {
            text1:
              this.getValueByName(response.data.cardVehicle, 'text1') ||
              whiteColor,
            text2:
              this.getValueByName(response.data.cardVehicle, 'text2') ||
              whiteColor,
            background:
              this.getValueByName(
                response.data.cardVehicle,
                'background-color'
              ) || whiteColor,
          },
          filter: {
            text:
              this.getValueByName(response.data.filter, 'text1') || whiteColor,
            background:
              this.getValueByName(response.data.filter, 'background-color') ||
              whiteColor,
          },
          form: {
            text:
              this.getValueByName(response.data.contactForm, 'text1') ||
              whiteColor,
            background:
              this.getValueByName(
                response.data.contactForm,
                'background-color'
              ) || whiteColor,
          },
          header: {
            text: this.getValueByName(response.data.navBarTop, 'text1'),
            background:
              this.getValueByName(
                response.data.navBarTop,
                'background-color'
              ) || whiteColor,
            buttonSelected:
              this.getValueByName(response.data.navBarTop, 'button-selected') ||
              whiteColor,
          },
          footer: {
            text: this.getValueByName(response.data.footer, 'text1'),
            background:
              this.getValueByName(response.data.footer, 'background-color') ||
              whiteColor,
          },
        },
        loading: false,
      });
    } catch (error) {
      console.log('>> ERROR: falha no get das cores', error);
    }
  };

  updateColors = async data => {
    try {
      this.updateField('colors', { ...this.colors, loading: true });
      const requestData = {
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        components: [
          {
            component: 'basic_color',
            item: 'buttons_and_icons',
            color: data.site.main,
          },
          {
            component: 'basic_color',
            item: 'text',
            color: data.site.text,
          },
          {
            component: 'navBarTop',
            item: 'button-selected',
            color: data.header.buttonSelected,
          },
          {
            component: 'basic_color',
            item: 'background',
            color: data.site.background,
          },
          {
            component: 'cardVehicle',
            item: 'text1',
            color: data.card.text1,
          },
          {
            component: 'cardVehicle',
            item: 'text2',
            color: data.card.text2,
          },
          {
            component: 'cardVehicle',
            item: 'background-color',
            color: data.card.background,
          },
          {
            component: 'filter',
            item: 'text1',
            color: data.filter.text,
          },
          {
            component: 'filter',
            item: 'background-color',
            color: data.filter.background,
          },
          {
            component: 'contactForm',
            item: 'text1',
            color: data.form.text,
          },
          {
            component: 'contactForm',
            item: 'background-color',
            color: data.form.background,
          },
          {
            component: 'navBarTop',
            item: 'text1',
            color: data.header.text,
          },
          {
            component: 'navBarTop',
            item: 'background-color',
            color: data.header.background,
          },
          {
            component: 'footer',
            item: 'text1',
            color: data.footer.text,
          },
          {
            component: 'footer',
            item: 'background-color',
            color: data.footer.background,
          },
        ],
      };
      await DealerSiteService.updateColors(requestData);
      this.updateField('colors', { ...this.colors, loading: false });
      this.snackbarManagement('success', 'As cores foram salva!', true);
    } catch (error) {
      this.updateField('colors', { ...this.colors, loading: false });
      this.snackbarManagement('error', 'Houve algum problema', true);
    }
  };

  getGoogleData = async () => {
    try {
      const response = await DealerSiteService.getGoogleData({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
      });

      this.updateField('google', {
        ...this.google,
        data: {
          analytics: response.data.id_google_analytics,
          tagManager: response.data.id_google_tag_manager,
        },
        loading: false,
      });
    } catch (error) {
      console.log('>> ERROR: falha no retorno dos dados do Google');
    }
  };

  updateGoogleData = async data => {
    try {
      await DealerSiteService.updateGoogleData({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        ...data,
      });
      this.snackbarManagement(
        'success',
        'Configurações salva com sucesso!',
        true
      );
    } catch (error) {
      this.snackbarManagement('error', 'Ocorreu um erro ao salvar', true);
    }
  };

  getPagesData = async () => {
    try {
      const response = await DealerSiteService.getPagesData({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
      });

      const pages = { ...this.pages };
      response.data.map(item => {
        if (item.name === 'home') {
          pages.home = item;
        }

        if (item.name === 'list') {
          pages.list = item;
        }

        if (item.name === 'about') {
          pages.about = item;
        }

        if (item.name === 'contact') {
          pages.contact = item;
        }
        if (item.name === 'financing') {
          pages.financing = item;
        }
      });

      this.updateField('pages', {
        ...pages,
        loading: false,
      });
    } catch (error) {
      console.log('>> ERROR: falha no retorno dos dados das páginas');
    }
  };

  getAllImagesSelected = async (isMobile = false) => {
    try {
      this.updateField('galleryImages', {
        ...this.galleryImages,
        loading: true,
      });
      const params = {
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        is_mobile: isMobile,
      };
      let bannersSelected = [];
      bannersSelected = await DealerSiteService.getAllDealerBanners(params);
      this.updateField('galleryImages', {
        ...this.galleryImages,
        bannersSelected: [...bannersSelected.data],
        loading: false,
      });
    } catch (error) {
      console.log('>> ERRROR: falha ao pegar todas as imagens');
    }
  };

  deleteStoreLocation = async locationStoreId => {
    try {
      const params = {
        store_id: locationStoreId,
        dealer_site_theme_id: this.themes.themeId,
      };
      await DealerSiteService.deleteStoreLocation(params);
      this.getLocationsData();
      this.snackbarManagement('success', 'Localização da loja removida!', true);
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao deletar a localização da loja',
        true
      );
    }
  };

  addDealerBanner = async (imgId, isMobile = false) => {
    try {
      const body = {
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        is_mobile: isMobile,
        images: [
          {
            id: imgId,
            index: 0,
            src: imgId,
            title: '',
            show_title: false,
            link: '',
            show_link: false,
            title_color: '#000',
          },
        ],
      };
      await DealerSiteService.addDealerBanner(body);
      this.getAllImagesSelected(isMobile);
      this.snackbarManagement(
        'success',
        'Banner adicionado com sucesso!',
        true
      );
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao adicionar o banner',
        true
      );
    }
  };

  updateDealerBanner = async (data, isMobile = false) => {
    try {
      const body = {
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        is_mobile: isMobile,
        images: data.length > 0 ? data : [data],
      };
      await DealerSiteService.addDealerBanner(body);
      this.snackbarManagement(
        'success',
        'Informações atualizada com sucesso!',
        true
      );
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao atualizar as informações',
        true
      );
    }
  };

  getBannerTags = async () => {
    try {
      const req = await DealerSiteService.getBannerTags();
      const selectTags = [];
      req.data.map(item => {
        if (item !== 'MOBILE' && item !== 'DESKTOP') {
          selectTags.push({ label: item, value: item });
        }
      });
      this.updateField('galleryImages', {
        ...this.galleryImages,
        bannerTags: selectTags,
      });
    } catch (error) {
      console.log('>>> ERROR: falha ao pegar as tags dos banners');
    }
  };

  getBannerWithTag = async tag => {
    try {
      this.updateField('galleryImages', {
        ...this.galleryImages,
        loadingModal: true,
      });
      const req = await DealerSiteService.getBannerWithTag(tag);
      this.updateField('galleryImages', {
        ...this.galleryImages,
        data: req.data,
        loadingModal: false,
      });
    } catch (error) {
      console.log('>> ERROR: falha ao pegar os banners');
    }
  };

  detachDealerBanner = async (data, isMobile = false) => {
    try {
      const body = {
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        banner_image_id: data,
      };
      await DealerSiteService.detachDealerBanner(body);
      this.getAllImagesSelected(isMobile);
      this.snackbarManagement('success', 'Banner removido com sucesso!', true);
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao remover o banner',
        true
      );
    }
  };

  updatePage = async data => {
    delete data.settings;
    try {
      const params = {
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        name: data.name,
        active:
          data.active != undefined || data.active != null ? data.active : 1,
        title: data.title || '',
        title_text_color: data.title_text_color || '#000000',
        text: data.text || null,
        images: data.images,
      };
      await DealerSiteService.updatePage(params);
      await this.getPagesData();
      this.snackbarManagement('success', 'Pagina salva com sucesso!', true);
    } catch (error) {
      this.snackbarManagement(
        'error',
        'Ocorreu um erro ao salvar a pagina',
        true
      );
    }
  };

  cleanImages = async page => {
    try {
      this.updateField('pages', {
        ...this.pages,
        [page]: {
          ...this.pages[page],
          images: [],
        },
      });
    } catch (error) {
      console.log('>> ERROR: falha limpas as imagens');
    }
  };

  getListTheme = async () => {
    try {
      const response = await DealerSiteService.getListTheme();
      this.updateField('themes', {
        ...this.themes,
        list: response.data,
      });
    } catch (error) {
      console.log('>> ERROR: falha ao carregar a lista de themas');
    }
  };

  getTheme = async () => {
    try {
      const response = await DealerSiteService.getTheme({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
      });
      this.updateField('themes', {
        ...this.themes,
        siteThemeId: response.data.site_theme_id,
      });
    } catch (error) {
      console.log('>> ERROR: falha ao receber o site theme id');
    }
  };

  setTheme = async themeId => {
    try {
      await DealerSiteService.setTheme({
        dealer_id: this.themes.dealerId,
        dealer_site_theme_id: this.themes.themeId,
        site_theme_id: themeId,
      });

      this.updateField('themes', {
        ...this.themes,
        siteThemeId: themeId,
      });
    } catch (error) {
      console.log('>> ERROR: falha a alterar o thema');
    }
  };

  getThemeId = async data => {
    try {
      const response = await DealerSiteService.getThemeId(data);
      this.updateField('themes', {
        ...this.themes,
        dealerId: data.dealer_id,
        themeId: response.data.dealer_site_theme_id,
        checkTheme: true,
      });
      this.getSettingsData();
      this.getGoogleData();
    } catch (error) {
      this.setInitialState();
      this.updateField('themes', {
        ...this.themes,
        dealerId: data.dealer_id,
        checkTheme: false,
      });
      this.updateField('settings', {
        ...this.settings,
        loading: false,
      });
      this.updateField('google', {
        ...this.google,
        loading: false,
      });
    }
  };

  checkSubDomain = async data => {
    try {
      const param = {
        subdomain: data,
      };
      const response = await DealerSiteService.checkSubDomain(param);
      return response.data;
    } catch (error) {
      console.log('>> ERROR: falha check domain');
      return false;
    }
  };

  updateField(name, value) {
    set(this, name, value);
  }
}

export default remotedev(
  decorate(DealerSiteStore, {
    messages: observable,
    sociais: observable,
    settings: observable,
    google: observable,
    themes: observable,
    colors: observable,
    pages: observable,
    galleryImages: observable,
    snackbar: observable,

    setInitialState: action,
    updateField: action,
  })
);
