import React, { Fragment, useEffect, useMemo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';

import DateFilter from 'components/atoms/DateFilter';
import { highAccessUserTypes } from 'constants/userAccess';
import TextInput from 'modules/financing/components/atoms/TextField';
import ColumnFilter from './components/ColumnFilter';

import {
  useChannels,
  useDepartments,
  useOrigins,
  useSellers,
  useTags,
} from 'pages/proposals/tabs/PopoverFilters/hooks';

import PopoverFilters from '../../../PopoverFilters';

const CHANNELS_API_PROPERTY = 'providers';
const DEPARTMENT_API_PROPERTY = 'departmentCode';
const ORIGINS_API_PROPERTY = 'origin';
const SELLERS_API_PROPERTY = 'sellers';
const TAGS_API_PROPERTY = 'tagIds';

export default function ProposalFiltersComponent(props) {
  const {
    periodInit,
    periodFinal,
    tags,
    origin,
    search,
    channels,
    sellers,
    departmentCode,
    someUnreadMessages,
    setSomeUnreadMessages,
    handleOnClickToSearch,
    handleOnChangePeriod,
    handleChangeFilter,
    handleChangeDepartment,
    handleChangeChannel,
    handleChangeOrigin,
    handleChangeTags,
    handleClearAllFilters,
    handleChangeSellers,
    userStore,
    tasks,
    periodId,
  } = props;
  const { userType } = useSelector(state => state?.userStore);

  const {
    getChannels,
    options: channelOptions,
    selectedOptions: selectedChannels,
  } = useChannels({
    apiProperty: CHANNELS_API_PROPERTY,
    selectedValues: channels || [],
  });

  const {
    getDealerDepartment,
    options: departmentOptions,
    selectedOptions: selectedDepartments,
  } = useDepartments({
    apiProperty: DEPARTMENT_API_PROPERTY,
    selectedValues: departmentCode || [],
  });

  const {
    getOrigins,
    options: originsOptions,
    selectedOptions: selectedOrigins,
  } = useOrigins({
    apiProperty: ORIGINS_API_PROPERTY,
    selectedValues: origin || [],
  });

  const {
    getSellers,
    options: sellerOptions,
    selectedOptions: selectedSellers,
  } = useSellers({
    apiProperty: SELLERS_API_PROPERTY,
    selectedValues: sellers || [],
  });

  const {
    getAllTags,
    options: tagsOptions,
    selectedOptions: selectedTags,
  } = useTags({
    apiProperty: TAGS_API_PROPERTY,
    selectedValues: tags || [],
  });

  const triggerActions = filters => {
    if (!filters?.length) return handleClearAllFilters();

    return filters.forEach(filter => {
      switch (filter?.filterGroupId) {
        case CHANNELS_API_PROPERTY:
          return handleChangeChannel(filter?.values);
        case DEPARTMENT_API_PROPERTY:
          return handleChangeDepartment(filter?.values);
        case ORIGINS_API_PROPERTY:
          return handleChangeOrigin(filter?.values);
        case SELLERS_API_PROPERTY:
          return handleChangeSellers(filter?.values);
        case TAGS_API_PROPERTY:
          return handleChangeTags(filter?.values);
        default:
          return;
      }
    });
  };

  const allFilters = useMemo(() => {
    const filterConfig = [
      {
        type: 'checkbox',
        title: 'Departamento',
        apiProperty: DEPARTMENT_API_PROPERTY,
        selectedOptions: selectedDepartments || null,
        options: departmentOptions,
        disabled: Boolean(userStore?.departments?.length),
      },
      {
        type: 'checkbox',
        title: 'Tags',
        apiProperty: TAGS_API_PROPERTY,
        options: tagsOptions,
        selectedOptions: selectedTags || null,
      },
      {
        type: 'checkbox',
        title: 'Origem',
        apiProperty: ORIGINS_API_PROPERTY,
        options: originsOptions,
        selectedOptions: selectedOrigins || null,
      },
      {
        type: 'checkbox',
        title: 'Canal',
        apiProperty: CHANNELS_API_PROPERTY,
        options: channelOptions,
        selectedOptions: selectedChannels || null,
      },
      {
        type: 'checkbox',
        title: 'Vendedor',
        apiProperty: SELLERS_API_PROPERTY,
        options: sellerOptions,
        selectedOptions: selectedSellers || null,
      },
    ];

    const isHighAccessUser = highAccessUserTypes.includes(userType);

    const filteredItems = filterConfig.filter(filter => {
      return !(
        filter.apiProperty === SELLERS_API_PROPERTY && !isHighAccessUser
      );
    });

    return filteredItems;
  }, [
    channelOptions,
    departmentOptions,
    highAccessUserTypes,
    originsOptions,
    selectedChannels,
    selectedDepartments,
    selectedOrigins,
    selectedSellers,
    selectedTags,
    sellerOptions,
    tagsOptions,
    userStore?.departments?.length,
    userType,
  ]);

  useEffect(() => {
    getAllTags({});
    getChannels({});
    getDealerDepartment({});
    getOrigins({});
    getSellers({});
  }, []);

  return (
    <PopoverFilters
      popoverId="proposals-kanban"
      onSelect={filters => triggerActions(filters)}
      filters={allFilters}
      isSimple={props?.isSimple}
      otherElements={
        props?.otherElements ?? (
          <Fragment>
            <TextInput
              mini
              name="search"
              label="Busca"
              value={search}
              placeholder="nome, telefone ou placa"
              onKeyPress={e => e.key === 'Enter' && handleOnClickToSearch()}
              onChange={e =>
                handleChangeFilter({
                  target: { name: 'search', value: e.target.value },
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Clique para buscar"
                      onClick={handleOnClickToSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ColumnFilter
              someUnreadMessages={someUnreadMessages}
              setSomeUnreadMessages={setSomeUnreadMessages}
              tasks={tasks}
            />
            <DateFilter
              currentFilterSelected={periodId}
              currentStartDate={periodInit}
              currentEndDate={periodFinal}
              handleClickFilter={dates =>
                handleOnChangePeriod({
                  periodId: dates.filterType,
                  initialDate: dates.initialDateTimeStemp,
                  finalDate: dates.finalDateTimeStemp,
                })
              }
            />
          </Fragment>
        )
      }
    />
  );
}
