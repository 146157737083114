/* eslint-disable @typescript-eslint/no-use-before-define */
import ActionTypes from './actionTypes';
import moment from 'moment';
import DealFormActionTypes from '../dealForm/actionTypes';
import { Action } from 'types/ActionTypes';
import dealBase from 'constants/dealBase';
import findReportIndexByConditionalId from 'helpers/findReportIndexByConditionalId';
import {
  DealRepasse,
  ValidateDeal,
  ValidateTradeIn,
  Image,
  VehicleValuesValidate,
} from 'types/DealTypes';
import { defaultSelectObject } from 'constants/defaultSelect';
import plateValidator from 'helpers/plateValidator';
import { Conditional } from 'types/ConditionalTypes';
import validateEmail from 'helpers/validateEmail';
import validatePhone from 'helpers/validatePhone';
import validateCPF from 'helpers/validateCPF';

const validateBaseDataObject: ValidateDeal = {
  makeId: null,
  modelId: null,
  productionYear: null,
  modelYear: null,
  trimId: null,
  doors: null,
  colorId: null,
  transmissionId: null,
  fuelId: null,
  km: null,
  plate: null,
  vin: null,
};

const vehicleValuesValidate: VehicleValuesValidate = {
  resalePrice: null,
};

const validateTradeInObject: ValidateTradeIn = {
  ownerName: null,
  ownerEmail: null,
  ownerPhone: null,
  ownerCPF: null,
};
export const INITIAL_STATE = {
  ...dealBase,
  validate: { ...validateBaseDataObject },
  vehicleValuesValidate: { ...vehicleValuesValidate },
};

export default function orderDeal(
  state: DealRepasse = INITIAL_STATE,
  action: Action
): DealRepasse {
  switch (action.type) {
    case DealFormActionTypes.CLEAN_DEAL:
      return INITIAL_STATE;
    case ActionTypes.CREATE_DEAL:
      return JSON.parse(JSON.stringify({ ...INITIAL_STATE }));
    case ActionTypes.SET_VEHICLE_TYPE:
      return {
        ...state,
        vehicleType: action.payload.vehicleType,
        makeId: INITIAL_STATE.makeId,
        makeName: INITIAL_STATE.makeName,
        modelId: INITIAL_STATE.modelId,
        modelName: INITIAL_STATE.modelName,
        productionYear: INITIAL_STATE.productionYear,
        modelYear: INITIAL_STATE.modelYear,
        trimId: INITIAL_STATE.trimId,
        fuelId: INITIAL_STATE.fuelId,
        trimName: INITIAL_STATE.trimName,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SET_MAKE:
      return {
        ...state,
        makeId: action.payload.makeId,
        makeName: action.payload.makeName,
        modelId: INITIAL_STATE.modelId,
        modelName: INITIAL_STATE.modelName,
        productionYear: INITIAL_STATE.productionYear,
        modelYear: INITIAL_STATE.modelYear,
        trimId: INITIAL_STATE.trimId,
        fuelId: INITIAL_STATE.fuelId,
        trimName: INITIAL_STATE.trimName,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SET_MODEL:
      return {
        ...state,
        modelId: action.payload.modelId,
        modelName: action.payload.modelName,
        productionYear: INITIAL_STATE.productionYear,
        modelYear: INITIAL_STATE.modelYear,
        trimId: INITIAL_STATE.trimId,
        trimName: INITIAL_STATE.trimName,
        fuelId: INITIAL_STATE.fuelId,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SET_PRODUCTION_YEAR:
      return {
        ...state,
        productionYear: action.payload,
        modelYear: INITIAL_STATE.modelYear,
        trimId: INITIAL_STATE.trimId,
        trimName: INITIAL_STATE.trimName,
        fuelId: INITIAL_STATE.fuelId,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SET_MODEL_YEAR:
      return {
        ...state,
        modelYear: action.payload,
        trimId: INITIAL_STATE.trimId,
        trimName: INITIAL_STATE.trimName,
        fuelId: INITIAL_STATE.fuelId,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SET_TRIM:
      return {
        ...state,
        trimId: action.payload.trimId,
        trimName: action.payload.trimName,
        fuelId: INITIAL_STATE.fuelId,
      };
    case ActionTypes.SET_COLOR:
      return {
        ...state,
        colorId: action.payload,
      };
    case ActionTypes.SET_DOOR:
      return {
        ...state,
        doors: action.payload,
      };
    case ActionTypes.SET_TRANSMISSION:
      return {
        ...state,
        transmissionId: action.payload,
      };
    case ActionTypes.SET_FUEL:
      return {
        ...state,
        fuelId: action.payload,
      };
    case ActionTypes.SET_KILOMETER:
      return {
        ...state,
        km: action.payload,
        validate: { ...(state.validate || validateBaseDataObject), km: null },
      };
    case ActionTypes.SET_PLATE:
      return {
        ...state,
        plate: action.payload,
      };
    case ActionTypes.SET_ARMORED:
      return {
        ...state,
        armored: action.payload,
      };
    case ActionTypes.SET_DEALER_PJ_LOCALITY:
      return {
        ...state,
        dealerPJLocality: action.payload,
      };
    case ActionTypes.SET_AUCTION:
      return {
        ...state,
        auction: action.payload,
      };
    case ActionTypes.SET_CASUALTY:
      return {
        ...state,
        casualty: action.payload,
      };
    case ActionTypes.SET_ARMOR_TYPE:
      return {
        ...state,
        armorType: action.payload,
      };
    case ActionTypes.SET_YOUTUBE_ID:
      return {
        ...state,
        youtubeId: action.payload,
      };
    case ActionTypes.GET_VEHICLE_INFO_BY_PLATE_SUCCEDED: {
      const deal = action.payload;
      return {
        ...state,
        ...deal,
      };
    }
    case ActionTypes.SET_FEATURES:
      return {
        ...state,
        featureIds: action.payload,
      };

    case ActionTypes.SET_COMMENTS:
      return {
        ...state,
        resaleComments: action.payload,
      };

    case ActionTypes.SET_REPORTS:
      return {
        ...state,
        reports: [...action.payload.reports],
      };
    case ActionTypes.SET_FEATURES_CONDITIONAL_REPAIR_PRICE: {
      const index = findReportIndexByConditionalId(
        state.reports,
        action.payload.id
      );
      const reportsConditionalsPrice = [...state.reports];
      if (index === -1) {
        reportsConditionalsPrice.push({
          conditionalId: action.payload.id,
          repairPrice: action.payload.value,
          value: undefined,
        });
      } else {
        reportsConditionalsPrice[index] = {
          ...reportsConditionalsPrice[index],
          conditionalId: action.payload.id,
          repairPrice: action.payload.repairPrice,
        };
      }

      return {
        ...state,
        reports: [...reportsConditionalsPrice],
      };
    }
    case ActionTypes.SET_PRICE:
      return {
        ...state,
        resalePrice: action.payload,
      };
    case ActionTypes.SET_PURCHASE_PRICE:
      return {
        ...state,
        purchasePrice: action.payload,
      };
    case ActionTypes.SET_MINIMUM_BID_INCREMENT:
      return {
        ...state,
        minimumBidIncrement: action.payload,
      };
    case ActionTypes.SET_MINIMUM_PRICE:
      return {
        ...state,
        minimumPriceProposalResale: action.payload,
      };
    case ActionTypes.SET_BUY_NOW_PRICE:
      return {
        ...state,
        buyNowPrice: action.payload,
      };

    case ActionTypes.SET_REPORT_UPLOAD_SUCCESS:
      return {
        ...state,
        reportId: action.payload.id,
        reportUrl: '',
        uploadingReport: undefined,
      };

    case ActionTypes.SET_REPORT_URL:
      return {
        ...state,
        reportUrl: action.payload,
        reportId: 0,
        uploadingReport: undefined,
      };
    case ActionTypes.SET_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadingImage: false,
        images: [
          ...state.images,
          {
            imageId: action.payload.id,
            position: state.images.length,
          },
        ],
      };
    case ActionTypes.SET_IMAGE_POSITION: {
      const images = [...state.images];
      images[action.payload.imageDestination.position] =
        action.payload.imageOrigin;
      images[action.payload.imageOrigin.position] =
        action.payload.imageDestination;
      images.map((image, index) => ({ ...image, position: index }));
      return {
        ...state,
        images: [...images],
      };
    }
    case ActionTypes.SET_IMAGE_UPLOADED:
      return {
        ...state,
        uploadingImage: false,
      };
    case ActionTypes.SET_IMAGE_UPLOADING:
      return {
        ...state,
        uploadingImage: true,
      };
    case ActionTypes.SET_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        uploadingImage: false,
      };
    case ActionTypes.SET_REPORT_UPLOADING:
      return {
        ...state,
        uploadingReport: true,
      };

    case ActionTypes.CLEAR_DEAL_REPORT:
      return {
        ...state,
        reports: INITIAL_STATE.reports,
        reportId: INITIAL_STATE.reportId,
        reportUrl: INITIAL_STATE.reportUrl,
        uploadingReport: false,
      };

    case ActionTypes.DEAL_LOADING:
      return {
        ...INITIAL_STATE,
        loading: true,
        errorDetails: undefined,
        errorUpDetails: undefined,
        errorLoading: undefined,
        errorUploading: undefined,
      };
    case ActionTypes.DEAL_LOADED:
      return {
        ...state,
        loading: false,
        errorDetails: undefined,
        errorUpDetails: undefined,
        errorLoading: undefined,
        errorUploading: undefined,
      };
    case ActionTypes.DEAL_UPDATING:
      return {
        ...state,
        updating: true,
        errorDetails: undefined,
        errorUpDetails: undefined,
        errorLoading: undefined,
        errorUploading: undefined,
      };
    case ActionTypes.DEAL_UPDATING_ERROR:
    case ActionTypes.SAVE_DEAL_ERROR:
    case ActionTypes.SET_DEAL_ERROR_UPLOADING:
      return {
        ...state,
        updating: false,
        loading: false,
        errorDetails: undefined,
        errorLoading: undefined,
        errorUploading: true,
        errorUpDetails: action.payload,
        validate: { ...validateBaseDataObject },
      };
    case ActionTypes.SET_DEAL:
      return {
        ...state,
        ...action.payload,
        updating: undefined,
        loading: undefined,
      };

    case ActionTypes.SET_DEAL_ERROR_LOADING:
      return {
        ...state,
        loading: undefined,
        errorLoading: true,
        errorDetails: action.payload,
      };
    case ActionTypes.SET_DEAL_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
      };

    case ActionTypes.CLEAN_DEAL_ERRORS:
      return {
        ...state,
        loading: false,
        updating: false,
        errorDetails: undefined,
        errorUpDetails: undefined,
        errorLoading: undefined,
        errorUploading: undefined,
      };
    case ActionTypes.SET_JUST_MAKE:
      return {
        ...state,
        makeId: action.payload.makeId,
        makeName: action.payload.makeName,
      };
    case ActionTypes.SET_JUST_MODEL:
      return {
        ...state,
        modelId: action.payload.modelId,
        modelName: action.payload.modelName,
      };
    case ActionTypes.SET_JUST_PRODUCTION_YEAR:
      return {
        ...state,
        productionYear: action.payload,
        modelYear: INITIAL_STATE.modelYear,
      };
    case ActionTypes.SET_JUST_MODEL_YEAR:
      return {
        ...state,
        modelYear: action.payload,
      };
    case ActionTypes.SET_JUST_TRIM:
      return {
        ...state,
        trimId: action.payload.trimId,
        trimName: action.payload.trimName,
      };
    case ActionTypes.SEARCHING_DEAL_BY_PLATE:
      return {
        ...state,
        searchingPlate: true,
      };
    case ActionTypes.ERROR_SEARCHING_DEAL_BY_PLATE:
      return {
        ...state,
        searchingPlate: false,
        searchingPlateDetail: action.payload,
      };
    case ActionTypes.SEARCHED_DEAL_BY_PLATE:
      return {
        ...state,
        searchingPlate: false,
        searchingPlateDetail: action.payload,
      };
    case ActionTypes.VALIDATE_BASE_DEAL:
    case ActionTypes.SET_VALIDATE_BASE_VEHICLE_DATA: {
      const validate = validateBaseData(state);
      return {
        ...state,
        validate: { ...validate },
      };
    }
    case ActionTypes.SET_VEHICLE_VALUES_VALIDATE_DATA: {
      const vehicleValuesValidate = validateVehicleValues(state);

      return {
        ...state,
        vehicleValuesValidate: { ...vehicleValuesValidate },
      };
    }
    case ActionTypes.UPLOADING_REPORT_IMAGE: {
      const reportState = getReportsWithUploading(
        state.reports,
        action.payload.conditionalId,
        action.payload.uploading
      );

      return {
        ...state,
        reports: [...reportState],
      };
    }
    case ActionTypes.REMOVE_REPORT_IMAGE: {
      const reportState2 = getReportsRemovingImage(
        state.reports,
        action.payload.conditionalId
      );

      return {
        ...state,
        reports: [...reportState2],
      };
    }
    case ActionTypes.UPLOADED_REPORT_IMAGE: {
      const reports = getReportsWithImageId(
        state.reports,
        action.payload.conditionalId,
        action.payload.imageId
      );

      return {
        ...state,
        reports: [...reports],
      };
    }
    case ActionTypes.ERROR_UPLOAD_REPORT_IMAGE: {
      const reports3 = getReportsWithError(
        state.reports,
        action.payload.conditionalId
      );

      return {
        ...state,
        reports: [...reports3],
      };
    }
    case ActionTypes.SET_TRADE_IN: {
      const ownerName = action.payload.isTradeIn ? state.ownerName : '';
      const ownerEmail = action.payload.isTradeIn ? state.ownerEmail : '';
      const ownerPhone = action.payload.isTradeIn ? state.ownerPhone : '';
      const ownerCPF = action.payload.isTradeIn ? state.ownerCPF : '';
      const errors = action.payload.isTradeIn ? state.errors : {};
      return {
        ...state,
        tradein: action.payload.isTradeIn,
        ownerName: ownerName,
        ownerEmail: ownerEmail,
        ownerPhone: ownerPhone,
        ownerCPF: ownerCPF,
        errors: errors,
      };
    }
    case ActionTypes.SET_TRADE_IN_OWNER_NAME:
      return {
        ...state,
        ownerName: action.payload.ownerName,
      };
    case ActionTypes.SET_TRADE_IN_OWNER_EMAIL:
      return {
        ...state,
        ownerEmail: action.payload.ownerEmail,
      };
    case ActionTypes.SET_TRADE_IN_OWNER_PHONE:
      return {
        ...state,
        ownerPhone: action.payload.ownerPhone,
      };
    case ActionTypes.SET_TRADE_IN_OWNER_CPF:
      return {
        ...state,
        ownerCPF: action.payload.ownerCPF,
      };
    case ActionTypes.VALIDATE_TRADE_IN_INFO:
      return {
        ...state,
        errors: validateTradeInInfo(state),
      };
    case ActionTypes.DELETE_IMAGE: {
      const imagesClones = state.images
        .filter(
          (image: Image): boolean => image.imageId !== action.payload.imageId
        )
        .map(
          (image: Image, index: number): Image => ({
            ...image,
            position: index,
          })
        );

      return { ...state, images: imagesClones };
    }
    case ActionTypes.SET_VIN:
      return {
        ...state,
        vin: action.payload.vin,
      };
    case ActionTypes.SET_RENAVAM:
      return {
        ...state,
        renavam: action.payload.renavam,
      };
    case ActionTypes.SET_MANUFACTURING_PACKAGE_CODE:
      return {
        ...state,
        manufacturingPackageCode: action.payload.manufacturingPackageCode,
      };
    case ActionTypes.SET_JUST_VEHICLE_TYPE:
      return {
        ...state,
        vehicleType: action.payload.vehicleType,
      };
    case ActionTypes.SET_DATE_PUBLISH:
      return { ...state, publishDate: moment(action.payload).valueOf() };
    case ActionTypes.SET_DATE_COUNTDOWN:
      return { ...state, expirationDate: moment(action.payload).valueOf() };
    default:
      return state;
  }
}
function getReportsWithUploading(
  reports: Conditional[],
  conditionalId: number,
  uploading: boolean
): Conditional[] {
  const reportIndex = findReportIndexByConditionalId(reports, conditionalId);
  if (reportIndex === -1) {
    throw new Error('this should not happen');
  }
  const reportsImage = [...reports];
  reportsImage[reportIndex] = {
    ...reportsImage[reportIndex],
    conditionalId: conditionalId,
    uploading: uploading,
  };
  return reportsImage;
}

function getReportsWithImageId(
  reports: Conditional[],
  conditionalId: number,
  imageId: number
): Conditional[] {
  const reportIndex = findReportIndexByConditionalId(reports, conditionalId);
  if (reportIndex === -1) {
    throw new Error('this should not happen');
  }

  const reportsImage = [...reports];
  const { uploading, ...report } = reportsImage[reportIndex];
  reportsImage[reportIndex] = { ...report, imageId };

  return reportsImage;
}

function getReportsWithError(
  reports: Conditional[],
  conditionalId: number
): Conditional[] {
  const reportIndex = findReportIndexByConditionalId(reports, conditionalId);
  if (reportIndex === -1) {
    throw new Error('this should not happen');
  }

  const reportsImage = [...reports];
  const { uploading, imageId, ...report } = reportsImage[reportIndex];
  reportsImage[reportIndex] = { ...report };

  return reportsImage;
}

function getReportsRemovingImage(
  reports: Conditional[],
  conditionalId: number
): Conditional[] {
  const reportIndex = findReportIndexByConditionalId(reports, conditionalId);
  if (reportIndex === -1) {
    throw new Error('this should not happen');
  }
  const reportsImage = [...reports];
  const { imageId, uploading, ...report } = reportsImage[reportIndex];
  reportsImage[reportIndex] = report;
  return reportsImage;
}
function validateBaseData(state: DealRepasse): any {
  const objValidate = { ...validateBaseDataObject };
  if (state.makeId === defaultSelectObject.value) {
    objValidate.makeId = true;
  }
  if (state.modelId === defaultSelectObject.value) {
    objValidate.modelId = true;
  }
  if (state.productionYear === defaultSelectObject.value) {
    objValidate.productionYear = true;
  }
  if (state.modelYear === defaultSelectObject.value) {
    objValidate.modelYear = true;
  }
  if (state.trimId === defaultSelectObject.value) {
    objValidate.trimId = true;
  }
  if (state.doors === defaultSelectObject.value) {
    objValidate.doors = true;
  }
  if (state.colorId === defaultSelectObject.value) {
    objValidate.colorId = true;
  }
  if (state.transmissionId === defaultSelectObject.value) {
    objValidate.transmissionId = true;
  }
  if (state.fuelId === defaultSelectObject.value) {
    objValidate.fuelId = true;
  }
  if (state.km <= 0) {
    objValidate.km = true;
  }
  if (!state.plate || !plateValidator(state.plate)) {
    objValidate.plate = true;
  }

  return objValidate;
}

function validateVehicleValues(state: DealRepasse): any {
  const objValidate = { ...vehicleValuesValidate };

  if (!state.resalePrice) {
    objValidate.resalePrice = true;
  } else {
    objValidate.resalePrice = false;
  }

  return objValidate;
}

function validateTradeInInfo(state: DealRepasse): ValidateTradeIn {
  if (!state.tradein) return { ...validateTradeInObject };

  const objValidate = { ...validateTradeInObject };

  if (!state.ownerName || state.ownerName.length < 3)
    objValidate.ownerName = true;
  if (!state.ownerEmail || !validateEmail(state.ownerEmail))
    objValidate.ownerEmail = true;
  if (!state.ownerPhone || !validatePhone(state.ownerPhone))
    objValidate.ownerPhone = true;
  if (!state.ownerCPF || state.ownerCPF.length === 0) {
    objValidate.ownerCPF = false;
  } else {
    objValidate.ownerCPF = !validateCPF(state.ownerCPF.toString());
  }

  return objValidate;
}
