import currency from 'currency.js';

function fallbackFormatter(amount, useDecimal) {
  return currency(amount, {
    separator: '.',
    decimal: ',',
    precision: useDecimal ? 2 : 0,
  }).format();
}

export default function formatAmount(
  amount,
  language = 'pt-BR',
  currencySymbol = null,
  shortAmount = false
) {
  const amountIsValid = typeof amount === 'number';
  if (!amountIsValid) return amount;

  return currencySymbol
    ? `${currencySymbol} ${fallbackFormatter(amount, !shortAmount)}`
    : fallbackFormatter(amount, shortAmount);
}
