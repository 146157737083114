import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import PROFILES from 'constants/profiles';
import bookVehicleService from 'modules/store-inventory/services/InventorySearchServices';
import BlockedDetailsComponent from './BlockedDetailsComponent';
import { highAccessUserTypes } from 'constants/userAccess';

const BlockedDetailsContainer = ({
  open,
  onClose,
  currentDeal,
  callbackOnSuccess,
  someView = false,
}) => {
  const { userStore } = useSelector(state => ({
    userStore: state.userStore,
  }));

  const [blokedData, setBlokedData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [blockedState, setBlockedState] = useState({
    openAddBlocked: false,
    openCancelBlocked: false,
  });

  const getBlockDetails = async () => {
    try {
      if (!currentDeal.blockedId) {
        setLoading(false);
        return;
      }
      const response: any = await bookVehicleService.getBlockDetails(
        currentDeal.dealerId,
        currentDeal.id
      );
      if (response.success) {
        setBlokedData(response.data);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const isManagerOrOwner = () => {
    if (userStore?.userProfile === PROFILES.CONSULTOR) {
      return true;
    }
    return highAccessUserTypes.includes(userStore.userType);
  };

  useEffect(() => {
    getBlockDetails();
  }, []);

  const componentProps = {
    open,
    onClose,
    dealerId: currentDeal.dealerId,
    dealId: currentDeal.id,
    currentDeal,
    blokedData,
    isLoading,
    setBlockedState,
    blockedState,
    callbackOnSuccess,
    getBlockDetails,
    isManagerOrOwner,
    someView,
  };
  return <BlockedDetailsComponent {...componentProps} />;
};

export default memo(BlockedDetailsContainer);
