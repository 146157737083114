import React from 'react';
import DownPaymentSimulationComponent from './DownPaymentSimulationComponent';
import formatTime from 'helpers/formatTime';
import formatDate from 'helpers/formatDate';
import { useGetDownPaymentSimulations } from './hooks/useGetDownPaymentSimulations';
import { SelectedDownPaymentSimulation } from './components/SimulationForm';
import { useQueryClient } from 'react-query';

const formatIdSimulation = (id: number) => {
  if (id < 10) {
    return '00' + id;
  } else if (id >= 10 && id < 100) {
    return '0' + id;
  } else {
    return id?.toString();
  }
};

const formatTimestamp = (timestamp: number) => {
  const effectiveTimestamp = timestamp ?? Date.now();

  const date = formatDate(effectiveTimestamp, 'DD/MM/YY');
  const time = formatTime(effectiveTimestamp, 'HH:mm');

  return `${date} ${time}`;
};

const DownPaymentSimulationContainer = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedSimulation, setSelectedSimulation] = React.useState(null);

  const {
    data: simulations,
    refetch: refetchSimulations,
  } = useGetDownPaymentSimulations();

  const viewComponentProps = {
    currentTab,
    openModal,
    selectedSimulation,
    simulations,
    setCurrentTab,
    setOpenModal,
    setSelectedSimulation,
    formatIdSimulation,
    formatTimestamp,
    refetchSimulations,
  };
  return <DownPaymentSimulationComponent {...viewComponentProps} />;
};

export default DownPaymentSimulationContainer;
