import styled from 'styled-components';

export const FilterColumnsButton = styled.div`
  box-sizing: border-box;
  margin-right: 16px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 155px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #424242;
`;

export const FilterColumnsBody = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  background: #ffffff;
  padding: 8px;
  top: 100%;
  height: 200px;
  overflow-y: auto;
  z-index: 1;
  font-family: Poppins Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  color: black;
  font-size: 14px;
  line-height: normal;
`;

export const CustomButton = styled.div`
  border: 1px solid black;
  padding: 2px 8px;
  border-radius: 4px;
  border-color: #bdbdbd;
  color: #424242;
`;
