import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Icon from '../../mobiauto.web-ui-components.components.icon';

const style = { textTransform: 'none', fontWeight: 700 };

const UnstyledButton = withStyles({
  root: {
    margin: 0,
    border: 0,
    padding: 0,
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(Button);

export default function ButtonWithIcon({
  iconName,
  children,
  color,
  onClick,
  disabled,
  buttonProps,
  iconProps,
}) {
  return (
    <UnstyledButton
      onClick={onClick}
      style={style}
      disabled={disabled}
      {...buttonProps}
    >
      <Icon name={iconName} color={color} size="30" {...iconProps} />
      <span style={{ color }}>{children}</span>
    </UnstyledButton>
  );
}
