import styled, { css } from 'styled-components';

export const PaymentListFooter = styled.div`
  display: flex;
  padding-top: 24px;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background: #e0e0e0;
  margin: 0 24px;
  margin-right: 16px;
`;

export const TotalValueLabel = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #757575;
`;

const TotalValueColors = {
  primary: '#4443BC',
  warning: '#F9A825',
  default: '#757575',
  green: '#4caf50',
  red: '#f44336',
};

export const TotalValueTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  ${props =>
    css`
      color: ${TotalValueColors[props.variant] || TotalValueColors.default};
    `}
`;
