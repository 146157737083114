import styled from 'styled-components';
import { LinkExternal } from 'components/Link';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Button = styled.div`
  font-family: Poppins Medium;
  text-transform: initial;
  background-color: transparent;
  border: none;
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
`;

// VehicleItem

export const LinkExternalStyled = styled(LinkExternal)`
  font-family: Poppins Regular;
  color: #757575 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
`;

export const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VehicleTitle = styled.div`
  color: #009a8a;
  font-size: 14px;
`;

export const VehicleCardContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin-bottom: 8px;
`;

export const Line = styled.div`
  display: flex;
  font-size: 14px;
`;

export const Label = styled.div`
  color: #757575;
  margin-right: 4px;
`;
