import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const VehicleMainInfo = styled('b')`
  font-family: 'Poppins Bold';
  font-size: 1.5rem;
  font-weight: 700;
  color: #424242;
`;

export const VehicleExtraInfo = styled('span')`
  font-family: 'Poppins Regular';
  font-size: 1.5rem;
  font-weight: 400;
  color: #757575;
`;

export const Plate = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0.3rem 1rem;
  font-family: 'Poppins Medium';
  font-size: 0.875rem;
  font-weight: 500;
  color: #757575;
`;

export const ReferenceBox = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;
