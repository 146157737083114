import request from 'helpers/request'
import EnvVars from 'helpers/envVars'
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler'

const serviceName = 'BookVehicleService'
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL')

export default class BookVehicleServices {

  static getBookedVehicleById = (dealerId, bookId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/book/v1.0/${bookId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getBookedVehicleById()`))
  }

  static bookVehicle = (dealerId, proposalId, dealId) => {
    return request
      .put(
        `${domain}/dealer/${dealerId}/proposals/v1.0/${proposalId}/book/${dealId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.bookVehicle()`))
  }

  static cancelBookedVehicle = (dealerId, bookedId, reasonId) => {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/book/v1.0/${bookedId}/${reasonId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.cancelBookedVehicle()`))
  }

  static getCancellationReasons = (dealerId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/book/v1.0/cancellation-reasons`)
      .then(buildHTTPResponseHandler(`${serviceName}.getCancellationReasons()`));
  }
}
