import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { useStyles } from './styles';

const TextFieldComponent = ({
  variant = 'filled',
  InputProps,
  error,
  ...props
}: any) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth error={error}>
      <TextField
        fullWidth
        variant={variant}
        {...props}
        InputProps={{
          classes: {
            root: `${classes.styledInput} ${error ? classes.errorInput : ''}`,
            disabled: classes.disabled,
            input: props.mini ? classes.miniInput : null,
          },
          ...InputProps,
        }}
        InputLabelProps={{
          shrink: (['date', 'time'].includes(props?.type)
            ? true
            : props?.value || false) as boolean,
          classes: {
            root: `${classes.cssLabel} ${error ? classes.errorLabel : ''}`,
          },
        }}
      />
    </FormControl>
  );
};

export default TextFieldComponent;
