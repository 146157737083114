import React from 'react';
import { OrderStepperState } from 'modules/order/store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import {
  InstallmentsTypeEnum,
  OrderPayment,
} from 'modules/order/types/OrderPayment';
import ActionCreators from 'modules/order/store/reducers/actionCreators';

type PaymentValues = {
  salePrice: string;
  payments: Array<object>;
  products: Array<object>;
  totalRemaining: number;
  totalChargedProducts: number;
  totalInserted: number;
  totalServicesSubtractionTotalTroco: number;
  hasProductServices: boolean;
  percentageCommissionVDVI?: string;
};

const usePayment = () => {
  const {
    paymentData: {
      salePrice,
      payments,
      totalRemaining,
      products,
      totalChargedProducts,
      totalInserted,
      totalServicesSubtractionTotalTroco,
      percentageCommissionVDVI,
    },
  }: OrderStepperState = useSelector(state => state.order);

  const dispatch = useDispatch();

  const isInstallmentTypeTroco = (payment: OrderPayment) => {
    return payment.paymentType.installmentsType == InstallmentsTypeEnum.TROCO;
  };

  const setTotalRemaining = (value: number) => {
    dispatch(ActionCreators.setPaymentData({ totalRemaining: value }));
  };

  const setTotalServicesSubtractionTotalTroco = (value: number) => {
    dispatch(
      ActionCreators.setPaymentData({
        totalServicesSubtractionTotalTroco: value,
      })
    );
  };

  const setTotalInserted = (value: number) => {
    dispatch(ActionCreators.setPaymentData({ totalInserted: value }));
  };

  const setTotalChargedProducts = (value: number) => {
    dispatch(ActionCreators.setPaymentData({ totalChargedProducts: value }));
  };

  const sum = (previousValue, currentValue) => previousValue + currentValue;

  React.useEffect(() => {
    if (payments) {
      const paymentSum = payments
        .filter(payment => !isInstallmentTypeTroco(payment))
        .map(payment => payment.value)
        .reduce(sum, 0);

      const paymentSubtract = payments
        .filter(payment => isInstallmentTypeTroco(payment))
        .map(payment => payment.value)
        .reduce(sum, 0);

      const newTotalInserted =
        Number((paymentSum || 0).toFixed(2)) -
        Number((paymentSubtract || 0).toFixed(2));

      const totalRemaining = Number(
        ((Number(salePrice) || 0) - Number(newTotalInserted || 0)).toFixed(2)
      );

      setTotalInserted(Number(newTotalInserted));
      setTotalRemaining(totalRemaining);
    }
  }, [payments, salePrice]);

  React.useEffect(() => {
    if (products) {
      const productsSum = products
        .filter(product => product.proposalProduct.charged)
        .map(product => product.value)
        .reduce(sum, 0);
      setTotalChargedProducts(productsSum);
    }
  }, [products]);

  React.useEffect(() => {
    if (payments || products) {
      const paymentSubtract = payments
        .filter(payment => isInstallmentTypeTroco(payment))
        .map(payment => payment.value)
        .reduce(sum, 0);
      const productsSum = products
        .filter(product => product.proposalProduct.charged)
        .map(product => product.value)
        .reduce(sum, 0);
      const total = paymentSubtract - productsSum;
      setTotalServicesSubtractionTotalTroco(total);
    }
  }, [payments, products]);

  const hasProductServices = !!products
    .filter(product => product.proposalProduct.charged)
    .map(product => product.value)
    .reduce(sum, 0);

  const paymentValues: PaymentValues = {
    salePrice,
    payments,
    totalRemaining,
    products,
    totalChargedProducts,
    totalInserted,
    hasProductServices,
    totalServicesSubtractionTotalTroco,
    percentageCommissionVDVI,
  };

  return paymentValues;
};

export default usePayment;
