import styled from 'styled-components';

export const CleanFilterText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4443bc;
  cursor: pointer;
  :hover {
    opacity: 90%;
  }
  :active {
    opacity: 70%;
  }
`;
