import React, { useState } from 'react';
import VisualizingMode from './components/VisualizingMode';
import EditingMode from './components/EditingMode';
import ErrorMode from './components/ErrorMode';
import MODE from 'constants/editInLineTypes';
import EditInLine from 'components/EditInLine';
import ItemHeader from '../atoms/ItemHeader';
import ProfileAndInformation from './components/ProfileAndInformation';

const UserProfile = ({ closedProposal }) => {
  const [currentMode, setMode] = useState(MODE.VISUALIZING);

  const handleOnChangeMode = mode => {
    setMode(mode);
  };

  return (
    <>
      <ItemHeader>Dados do cliente</ItemHeader>
      <EditInLine
        mode={currentMode}
        VisualizingComponent={VisualizingMode}
        EditingComponent={EditingMode}
        SavingComponent={VisualizingMode}
        SavedComponent={VisualizingMode}
        ErrorComponent={ErrorMode}
        handleOnChangeMode={handleOnChangeMode}
        closedProposal={closedProposal}
      />
      <ProfileAndInformation />
    </>
  );
};
export default UserProfile;
