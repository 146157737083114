import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const ChipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border-radius: 16px;
  background-color: ${Colors.primary.medium};
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  cursor: pointer;
  margin-left: ${props => props.spacing && '16px'};
  gap: 0.25rem;

  &:hover {
    padding: 0.25rem;
    .label {
      display: inline;
    }
  }
`;

export const LabelContainer = styled.span`
  display: none;
`;
