import request from 'helpers/request';
import envVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'GroupServices';
export default class GroupServices {
  static getGroupOverview(groupId) {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/inventory/overview`)
      .then(buildHTTPResponseHandler(`${serviceName}.getInventoryOverview()`));
  }

  static getGroupDetail(groupId) {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/inventory/detail`)
      .then(buildHTTPResponseHandler(`${serviceName}.getGroupDetail()`));
  }

  static getProposalConversionPerProvider(
    groupId,
    initialDate,
    finalDate,
    type
  ) {
    const params = { initialDate, finalDate, type };
    return request
      .get(
        `${domain}/dealer-group/${groupId}/v1.0/proposal/conversion-provider`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getProposalConversionPerProvider()`
        )
      );
  }

  static getProposalFinishDetailByGroup(groupId, initialDate, finalDate, type) {
    const params = { initialDate, finalDate, type };
    return request
      .get(
        `${domain}/dealer-group/${groupId}/v1.0/proposal/finish-detail`,
        params
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getProposalFinishDetailByGroup()`
        )
      );
  }

  static getProposalForListing(groupId, initialDate, finalDate, type) {
    const params = { initialDate, finalDate, type };
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/proposal/listing`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposalForListing()`));
  }

  static getProposalPerOrigin(groupId, initialDate, finalDate, type) {
    const params = { initialDate, finalDate, type };
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/proposal/origin`, params)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposalPerOrigin()`));
  }

  static getProposalOverviewByGroup(groupId, initialDate, finalDate, type) {
    const params = { initialDate, finalDate, type };
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/proposal/overview`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalOverviewByGroup()`)
      );
  }

  static getProposalPerProvider(groupId, initialDate, finalDate, type) {
    const params = { initialDate, finalDate, type };
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/proposal/provider`, params)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getProposalPerProvider()`)
      );
  }

  static getProposalToFile(groupId, initialDate, finalDate) {
    const params = { initialDate, finalDate };
    return request
      .get(
        `${domain}/dealer-group/${groupId}/v1.0/proposal/listing/file`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getProposalToFile()`));
  }

  static getMeInfos(groupId) {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/me`)
      .then(buildHTTPResponseHandler(`${serviceName}.getMeInfos()`));
  }

  static getChannelsOptionsByGroupId = groupId => {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/providers`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getChannelsOptionsByGroupId()`)
      );
  };

  static getOriginsOptionsByGroupId = groupId => {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/origins`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getOriginsOptionsByGroupId()`)
      );
  };

  //GET_GROUP_EVALUATION
  static getGroupEvaluations = ({ groupId, dealForListingFilter, params }) => {
    const { size, page } = params;
    return request
      .post(
        `${domain}/dealer-group/${groupId}/v2.0/evaluations`,
        dealForListingFilter,
        { params: { page, size } }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getGroupEvaluations()`));
  };
}
