import isEmpty from 'helpers/isEmpty';
export default function formatToSelectInput(array) {
  const select = [];
  if (isEmpty(array)) {
    return select;
  }

  select.push(
    ...array.map(element => {
      const { id, name, ...remaining } = element;
      return { value: id, label: name, ...remaining };
    })
  );
  return select;
}
