import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import ConfirmButton from 'components/atoms/ConfirmButton';
import { Container, NextButton, BackButton } from './styles';
import checkClientFormData from 'modules/order/pages/OrderSteps/QueueStep/steps/ClientDataCompleted/components/checkClientFormData';
import Wrapper from 'components/Wrapper';

function StepperActions({
  customBackFunction = null,
  onlyBackButton = false,
  customHandleNext = null,
  isQueue = false,
}) {
  const {
    steps,
    currentStep,
    completedSteps,
    isFinished,
    proposalStep,
    queueData,
  } = useSelector(state => state.order);

  const { currentDealer } = useSelector(state => state.dealerStore);

  const dispatch = useDispatch();

  const hasProposalOEM = !!currentDealer.products.find(
    product => product.type === 'PROPOSAL_ORDER_OEM'
  );

  const handleBack = () => {
    dispatch(ActionCreators.setActiveStep(currentStep - 1));
  };

  const handleNext = () => {
    if (customHandleNext) {
      customHandleNext();
      return;
    }

    const currentStepObject = steps(hasProposalOEM).find(
      step => step.value === currentStep
    );

    if (!isFinished && currentStepObject?.label === 'Pedido') {
      dispatch(ActionCreators.finishOrder());
    } else {
      dispatch(ActionCreators.setActiveStep(currentStep + 1));
    }
  };

  const handleNextAndRequest = async () => {
    await dispatch(
      ActionCreators.setQueueData({
        ...queueData,
        orderPlacedAtTheAutomaker: true,
      })
    );
  };

  const isNextStepAvailable = () => completedSteps.includes(currentStep);

  const isLastStep = useCallback(() => {
    const currentStepObject = steps(hasProposalOEM).find(
      step => step.value === currentStep
    );
    return currentStepObject?.label === 'Checkout';
  }, [currentStep]);

  const isOrderStep = useCallback(() => {
    const currentStepObject = steps(hasProposalOEM).find(
      step => step.value === currentStep
    );
    return currentStepObject?.label === 'Pedido';
  }, [currentStep]);

  const isQueueStep = useCallback(() => {
    const currentStepObject = steps(hasProposalOEM).find(
      step => step.value === currentStep
    );
    return currentStepObject?.label === 'Fila de Espera';
  }, [currentStep]);

  const queueOrderDataComplete = !['COMMENTS_QUEUE', 1, 2].includes(
    proposalStep
  );

  const queueClientDataComplete =
    proposalStep === 1 &&
    queueData?.client?.formOem === 'FULL' &&
    checkClientFormData(
      queueData?.client?.cpfCnpj?.replace(/[^\d]/g, '')?.length > 11
        ? 'PJ'
        : 'PF',
      queueData?.client
    );

  useEffect(() => {
    if (queueData.orderPlacedAtTheAutomaker) {
      handleNext();
    }
  }, [queueData.orderPlacedAtTheAutomaker]);

  return (
    <Container>
      <BackButton onClick={customBackFunction ?? handleBack}>Voltar</BackButton>
      {!isQueue && !onlyBackButton && !isLastStep() && (
        <NextButton
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={isFinished ? false : !isNextStepAvailable()}
        >
          {!isFinished && (isOrderStep() || isQueueStep())
            ? 'Finalizar'
            : 'Continuar'}
        </NextButton>
      )}
      {isQueue && !onlyBackButton && !isLastStep() && (
        <NextButton
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={queueOrderDataComplete || queueClientDataComplete}
        >
          {proposalStep === 2 ? 'Salvar' : 'Continuar'}
        </NextButton>
      )}
      {isQueue &&
        !onlyBackButton &&
        !isLastStep() &&
        proposalStep === 2 &&
        queueData?.status?.value === 'NEW' && (
          <ConfirmButton
            variant="contained"
            color="primary"
            onClick={handleNextAndRequest}
            disabled={queueOrderDataComplete}
          >
            {proposalStep === 2 && 'Enviar para montadora'}
          </ConfirmButton>
        )}
    </Container>
  );
}

export default StepperActions;
