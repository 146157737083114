import MessageTypes from 'constants/MessageTypes';
import getIsoTime from 'helpers/getIsoTime';
import { typeAudioObj } from 'constants/AccessMessages';
const convertBlobToFile = (blob, type = MessageTypes.AUDIO) => {
  if (type === MessageTypes.AUDIO)
    return new File(
      [blob],
      'audio-' + getIsoTime().replace(/:|\./g, '-') + '.mp3',
      typeAudioObj
    );
  if (type === MessageTypes.IMAGE)
    return new File(
      [blob],
      'image-' + getIsoTime().replace(/:|\./g, '-') + '.jpg',
      { type: 'image/jpeg' }
    );
  return new File([blob]);
};

export default convertBlobToFile;
