import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import GenericDialog from 'components/GenericDialog';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ThemeButton from 'components/atoms/ThemeButton';
import OrderServices from 'modules/order/services/OrderServices';
import actionCreators from 'modules/order/store/reducers/actionCreators';
import { Flex, Text } from './styles';

const ReOpenOrderDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const { dealerId, proposalId } = useSelector(state => state.order);

  const reOpenOrder = async () => {
    try {
      const response: any = await OrderServices.reopenOrder(
        dealerId,
        proposalId
      );
      if (response.success) {
        dispatch(actionCreators.getOrderById());
        dispatch(actionCreators.setCheckoutOrder(false));
        onClose();
        //TODO: talvez seja utilizado
        // dispatch(actionCreators.getOrderStatus(dealerId, proposalId));
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Pedido reaberto com sucesso!',
            true
          )
        );
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Desculpe, Ocorreu um erro inesperado.',
            true
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  };

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Reabrir pedido"
      maxWidth="sm"
      DialogAction={() => (
        <Flex>
          <OutlinedButton onClick={onClose}>Voltar</OutlinedButton>
          <div style={{ width: 10 }} />
          <ThemeButton onClick={reOpenOrder}>Reabrir Pedido</ThemeButton>
        </Flex>
      )}
    >
      <div>
        <Text>Você tem certeza que gostaria de reabrir este pedido ?</Text>
      </div>
    </GenericDialog>
  );
};

export default ReOpenOrderDialog;
