import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';

// Component import
import { ModalTags } from 'components/ModalTags';
import Wrapper from 'components/Wrapper';
import TagChip from 'components/atoms/TagChip';

// Style import
import { TagColor } from './styles';

const MAX_TAGS_VIEW = 4;

const ProposalsTags = ({
  tags,
  proposalId,
  proposalDealerId,
  disabledAdd = false,
  handleClickDeleteTag = null,
  callbackOnSuccess = tags => {
    return tags;
  },
}) => {
  const [openManageTagsModal, setOpenManageTagsModal] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);

  const getLimitedTags = () => {
    return showAllTags ? tags?.slice(0) : tags?.slice(0, MAX_TAGS_VIEW);
  };

  const manageManageTagsModal = isOpen => {
    setOpenManageTagsModal(isOpen);
  };

  return (
    <>
      <Wrapper
        flex
        align="center"
        gap="sm"
        mt="sm"
        style={{ flexWrap: 'wrap' }}
      >
        {getLimitedTags()?.map(item => (
          <TagChip
            label={item?.name}
            key={item?.id}
            tagId={item?.id}
            color={item?.color}
            tagOwnerId={item?.tagOwnerId}
            deleteTag={handleClickDeleteTag}
            disabledCheck
          />
        ))}
        {tags?.length > MAX_TAGS_VIEW && showAllTags === false && (
          <ButtonBase
            onClick={event => {
              event.stopPropagation();
              setShowAllTags(true);
            }}
          >
            <Tooltip title="Ver mais tags">
              <Box
                component="div"
                display="flex"
                alignItems={'center'}
                justifyContent={'center'}
                height={23}
                px={1}
                bgcolor="#eee"
                borderRadius={4}
              >
                +{tags?.length - MAX_TAGS_VIEW}
              </Box>
            </Tooltip>
          </ButtonBase>
        )}
        {!disabledAdd && (
          <ButtonBase
            onClick={event => {
              event.stopPropagation();
              manageManageTagsModal(true);
            }}
          >
            <Tooltip title="Adicionar nova tag">
              <TagColor onClick={() => manageManageTagsModal(true)}>+</TagColor>
            </Tooltip>
          </ButtonBase>
        )}
      </Wrapper>

      {openManageTagsModal && (
        <ModalTags
          open={openManageTagsModal}
          proposalId={proposalId}
          handleOnClickCloseModal={() => manageManageTagsModal(false)}
          currentTaskTags={tags}
          callbackOnSuccess={callbackOnSuccess}
          proposalDealerId={proposalDealerId}
        />
      )}
    </>
  );
};

export default ProposalsTags;
