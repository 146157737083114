import React from 'react';
import { Grid } from '@material-ui/core';
import TextComponent from 'modules/mobiOne/components/Text';
import Wrapper from 'components/Wrapper';
import { EvaluationInProgress } from 'pages/chats/components/SideBarComponents/Evaluation/sideBarEvaluationTypes';
import HypertextButtonComponent from 'modules/financing/components/atoms/HypertextButton';
import { ReactComponent as TextSnippetIcon } from 'assets/text_snippet.svg';
import styled from 'styled-components';
import { getColorStatus } from 'pages/chats/helpers/getColorStatus';
import moment from 'moment';
import { getMessageStatus } from 'pages/chats/helpers/getMessageStatus';
import { generateReport } from 'pages/chats/helpers/generateReport';
import useSnackBar from 'hooks/useSnackBar';
import { usePricingVehicleContext } from '../PricingVehicle/PricingVehicleContext';

interface EvaluationInProgressItemProps {
  evaluationInProgress: EvaluationInProgress;
}

const EvaluationInProgressWrapper = styled(Wrapper).attrs({
  p: 16,
  r: 'xs',
  backgroundColor: '#ffffff',
})`
  & + div {
    margin-top: 16px;
  }
`;

const getFormattedDate = (date: string | number) => {
  return moment(date).format('L');
};

const EvaluationInProgressItem: React.FC<EvaluationInProgressItemProps> = ({
  evaluationInProgress,
}) => {
  const { currentDealer } = usePricingVehicleContext();

  const snackbar = useSnackBar();

  const enabledReport =
    evaluationInProgress.evaluationStatus === 'EVALUATED' ||
    evaluationInProgress.evaluationStatus === 'FINISHED';

  return (
    <EvaluationInProgressWrapper>
      <Wrapper flex gap="md" justify="between">
        <Wrapper>
          <TextComponent
            fontSize="14px"
            fontWeight={500}
            fontFamily="Poppins Medium"
          >
            Data da avaliação
          </TextComponent>
          <TextComponent fontSize="14px" fontWeight={400}>
            {getFormattedDate(evaluationInProgress.createdAt)}
          </TextComponent>
        </Wrapper>
        {Number(currentDealer?.id) !==
          Number(evaluationInProgress.dealerId) && (
          <Wrapper>
            <TextComponent
              fontSize="14px"
              fontWeight={500}
              fontFamily="Poppins Medium"
            >
              Loja
            </TextComponent>
            <TextComponent fontSize="14px" fontWeight={400}>
              {evaluationInProgress?.dealerName}
            </TextComponent>
          </Wrapper>
        )}
        <Wrapper>
          <TextComponent
            fontSize="14px"
            fontWeight={500}
            fontFamily="Poppins Medium"
          >
            Status
          </TextComponent>
          <Wrapper
            fontSize={12}
            fontWeight={600}
            px={8}
            py={2}
            backgroundColor={
              getColorStatus(evaluationInProgress.evaluationStatus).background
            }
            color={getColorStatus(evaluationInProgress.evaluationStatus).color}
            width="max-content"
            r="xl"
          >
            {getMessageStatus(evaluationInProgress.evaluationStatus)}
          </Wrapper>
        </Wrapper>
        <Wrapper>
          <TextComponent
            fontSize="14px"
            fontWeight={500}
            fontFamily="Poppins Medium"
          >
            Validade da avaliação
          </TextComponent>
          <TextComponent fontSize="14px" fontWeight={400}>
            {evaluationInProgress.evaluationStatus === 'FINISHED' ||
            evaluationInProgress.evaluationStatus === 'EVALUATED'
              ? `${getFormattedDate(evaluationInProgress.evaluationValidity)}`
              : '-'}
          </TextComponent>
        </Wrapper>
        <Grid sm="auto" item>
          <Wrapper
            fontSize={14}
            flex
            justify="end"
            alignSelf="end"
            mb={4}
            height="100%"
          >
            <HypertextButtonComponent
              textDecoration="none"
              isDisabled={!enabledReport}
              style={{ alignSelf: 'end' }}
              onClick={async () => {
                const result = await generateReport({
                  dealerId: Number(evaluationInProgress.dealerId),
                  evaluationId: evaluationInProgress.id,
                  enabledReport,
                });

                if (!result.success) {
                  snackbar.error('Erro ao baixar PDF');
                }
              }}
            >
              <TextSnippetIcon
                fill={!enabledReport ? '#0000004D' : '#4443bc'}
              />
              <Wrapper ml="sm">Ver laudo</Wrapper>
            </HypertextButtonComponent>
          </Wrapper>
        </Grid>
      </Wrapper>
    </EvaluationInProgressWrapper>
  );
};

export default EvaluationInProgressItem;
