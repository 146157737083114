import React from 'react';
import {
  Divider,
  GridList,
  InstallmentValue,
  Item,
  NumberOfInstallments,
  ProgressWrapper,
  TabPanelContainer,
  TitleResult,
  TotalAmount,
  WrapperCalcButton,
  WrapperList,
} from '../style';
import Select from 'modules/financing/components/atoms/Select';
import { CircularProgress, Grid, useTheme } from '@material-ui/core';
import TextField from 'modules/financing/components/atoms/TextField';
import NumberFormat from 'react-number-format';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ScrollView from 'components/ScrollView';
import { useGetDownPaymentsPartners } from '../hooks/useGetDownPaymentsPartners';
import { useCalculateDownPayment } from '../hooks/useCalculateDownPayment';
import formatAmount from 'helpers/formatAmount';
import { useApplyDownPaymentSimulation } from '../hooks/useApplyDownPaymentSimulation';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';

interface SimulationProps {
  disabled?: boolean;
  refetchSimulations: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<any, unknown>>;
}

interface SimulationItem {
  numberOfInstallments: number;
  installmentValue: string;
  selected: boolean;
}

export interface SelectedDownPaymentSimulation {
  id: number;
  name: string;
  createdAt: number;
  downPayment: number;
  simulation: [
    {
      installments: number;
      installmentValue: number;
      selected: boolean;
    }
  ];
  applied: boolean;
}

function buildSelectedSimulation(calculatedData: any, simulationItem: any) {
  return {
    ...calculatedData,
    applied: true,
    simulation: calculatedData.simulation.map(item =>
      item.installments === simulationItem.installments
        ? { ...item, selected: true }
        : item
    ),
  };
}

const SimulationForm = ({ disabled, refetchSimulations }: SimulationProps) => {
  const theme = useTheme();

  const { data: partners, isLoading } = useGetDownPaymentsPartners();

  const [partnerId, setPartnerId] = React.useState<number | string | undefined>(
    undefined
  );

  const [downPaymentValue, setDownPaymentValue] = React.useState<
    number | string
  >('');

  const {
    mutate: mutateCalculateDownpayment,
    data: calculatedData,
    isLoading: isLoadingCalculate,
  } = useCalculateDownPayment();

  const {
    mutate: mutateApplyDownpaymentSimulation,
    // isLoading: isLoadingApply,
  } = useApplyDownPaymentSimulation();

  const [_simulationItem, setSimulationItem] = React.useState(null);

  const [
    _selectedSimulation,
    setSelectedSimulation,
  ] = React.useState<SelectedDownPaymentSimulation | null>(null);

  const handleCalculate = () => {
    mutateCalculateDownpayment(
      {
        downPayment: downPaymentValue,
        partnerId: partnerId,
      },
      {
        onSuccess: () => {
          refetchSimulations();
        },
      }
    );
  };

  const handleSelect = (item: SimulationItem, calculatedData: any) => {
    setSimulationItem(item);

    const buildedSimulation = buildSelectedSimulation(calculatedData, item);

    setSelectedSimulation(buildedSimulation);
  };

  const handleChangeOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPartnerId(event.target.value);
  };

  const handleValueChange = (values: {
    floatValue: number;
    formattedValue: string;
  }) => {
    setDownPaymentValue(values.floatValue);
  };

  // const handleApply = () => {
  //   mutateApplyDownpaymentSimulation(selectedSimulation);
  // };

  if (isLoading) {
    return (
      <ProgressWrapper>
        <CircularProgress />
      </ProgressWrapper>
    );
  }

  return (
    <>
      <TabPanelContainer disabled={disabled}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              label="Parceiro de pagamento"
              onChange={handleChangeOption}
              options={partners}
              value={partnerId}
              mini
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={8}>
            <NumberFormat
              label="Valor da entrada"
              value={downPaymentValue}
              onValueChange={handleValueChange}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              allowLeadingZeros={false}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="R$ "
              customInput={TextField}
              mini
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <WrapperCalcButton>
              <OutlinedButton
                variant="primary"
                fullWidth
                disabled={!downPaymentValue || !partnerId}
                onClick={handleCalculate}
              >
                {isLoadingCalculate ? (
                  <CircularProgress size={'1.5rem'} />
                ) : (
                  'Calcular'
                )}
              </OutlinedButton>
            </WrapperCalcButton>
          </Grid>
          {calculatedData?.success && (
            <>
              <Grid item xs={12}>
                <TitleResult>Resultado da simulação</TitleResult>
              </Grid>
              <GridList item xs={12} disabled={disabled}>
                <ScrollView>
                  <WrapperList>
                    {React.Children.toArray(
                      calculatedData?.data?.simulation?.map(item => (
                        <Item
                          theme={theme}
                          disabled={disabled}
                          onClick={() =>
                            handleSelect(item, calculatedData?.data)
                          }
                        >
                          {/* SELEÇÃO DE PARCELA */}

                          {/* <StyledRadio
                            theme={theme}
                            disabled={disabled}
                            checked={simulationItem === item}
                          /> */}
                          <NumberOfInstallments>
                            {item.installments}x
                          </NumberOfInstallments>
                          <InstallmentValue>
                            {formatAmount(item.installmentValue, 'PT-BR', 'R$')}
                          </InstallmentValue>
                          <TotalAmount>
                            {formatAmount(
                              item.installmentValue * item.installments,
                              'PT-BR',
                              'R$'
                            )}
                          </TotalAmount>
                        </Item>
                      ))
                    )}
                  </WrapperList>
                </ScrollView>
              </GridList>
              <Divider />
            </>
          )}
        </Grid>
      </TabPanelContainer>
      {/* APLICAÇÃO AO PEDIDO */}
      {/* <WrapperSubmitButton disabled={disabled}>
        <ThemeButton
          fullWidth
          disabled={!selectedSimulation}
          onClick={handleApply}
        >
          {isLoadingApply ? (
            <CircularProgress size={'1.5rem'} color="inherit" />
          ) : (
            'Aplicar ao pedido'
          )}
        </ThemeButton>
      </WrapperSubmitButton> */}
    </>
  );
};

export default SimulationForm;

// AS PARTES QUE ESTÃO COMENTADAS SÃO DA MUDANÇA DE ESCOPO DA FEATURE
// QUE REMOVEU TEMPORARIAMENTE A SELEÇÃO DE PARCELAS E APLICAÇÃO AO PEDIDO
