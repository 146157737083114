import styled from 'styled-components';

export const TitleDoc = styled.div`
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const ObservationBox = styled.div`
  max-width: 140px;
`;

export const ObservationText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  word-wrap: break-word;
`;
