import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import useEvaluation from '../../hooks/useEvaluation';
import { triggerRefetchEvaluations } from '../../contexts/atom';
import PricingVehicleComponent from './PricingVehicleComponent';
import {
  usePricingVehicleContext,
  withPricingVehicleProvider,
} from './PricingVehicleContext';
import { FieldType } from './PricingVehicleTypes';
import { Pricing } from 'pages/chats/components/SideBarComponents/Evaluation/services/dto';

export interface ReferencesPrice {
  FIPE?: number;
  MOBIAUTO?: number;
  PRIMEIRA_MAO?: number;
}

const PricingVehicleContainer = ({
  modal,
  setModal,
  currentProposal,
  setList,
  dealerBelongsToSagaGroup = false,
  isLoadingCheckDealerBelongsToGroup = false,
}) => {
  const refetchEvaluationsList = useSetRecoilState(triggerRefetchEvaluations);

  const { currentDealer, pricingVehicleFormData } = usePricingVehicleContext();

  const handleCloseModal = () => {
    setModal(false);
    refetchEvaluationsList(true);
  };

  const {
    startPreviousCreatedEvaluation,
    createEvaluation,
    createEvaluationData,
    dataEvaluations,
  } = useEvaluation({
    dealerId: currentDealer?.id,
    proposalId: currentProposal?.id,
    onSuccessUpdateStatus: () => {
      handleCloseModal();
    },
  });

  const handleClickOnStartEvaluation = () => {
    const formattedData = createEvaluationData?.data;

    startPreviousCreatedEvaluation({
      dealerId: currentDealer?.id,
      dealId: Number(formattedData?.id),
      proposalId: currentProposal?.id,
    });
  };

  const handleConfirm = (pricing: Pricing[], evaluateLater = true) => {
    const {
      color,
      doors,
      fuel,
      km,
      make,
      model,
      modelYear,
      plate,
      productionYear,
      transmission,
      trim,
      vin,
      renavam,
    } = pricingVehicleFormData?.values;

    return createEvaluation(
      {
        dealerId: currentDealer?.id,
        proposalId: currentProposal?.id,
        evaluate: evaluateLater,
        evaluationDto: {
          colorId: color?.value,
          doors: doors?.value,
          fuelId: fuel?.value,
          km,
          makeId: make?.value,
          modelId: model?.value,
          modelYear: modelYear?.value,
          plate,
          productionYear: productionYear?.value,
          transmissionId: transmission?.value,
          trimId: trim.value,
          vin,
          renavam,
          pricing,
        },
      },
      {
        onSuccess: () => {
          if (evaluateLater) {
            handleCloseModal();
          }
        },
      }
    );
  };

  const handleOnChangeValue = ({
    field,
    newValue,
  }: {
    field: FieldType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newValue: any;
  }) => {
    if (field === 'make') {
      pricingVehicleFormData.setValues({
        ...pricingVehicleFormData?.values,
        make: newValue,
        model: null,
        productionYear: null,
        modelYear: null,
        trim: null,
      });

      return;
    }

    if (field === 'model') {
      pricingVehicleFormData.setValues({
        ...pricingVehicleFormData?.values,
        model: newValue,
        productionYear: null,
        modelYear: null,
        trim: null,
      });

      return;
    }

    if (field === 'productionYear') {
      pricingVehicleFormData.setValues({
        ...pricingVehicleFormData?.values,
        productionYear: newValue,
        modelYear: null,
        trim: null,
      });

      return;
    }

    if (field === 'modelYear') {
      pricingVehicleFormData.setValues({
        ...pricingVehicleFormData?.values,
        modelYear: newValue,
        trim: null,
      });

      return;
    }

    pricingVehicleFormData.setValues({
      ...pricingVehicleFormData?.values,
      [field]: newValue,
    });
  };

  const handleEvaluateLater = pricing => {
    return handleConfirm(pricing, false);
  };

  useEffect(() => {
    if (dataEvaluations) setList(dataEvaluations);
  }, [dataEvaluations]);

  const viewComponentProps = {
    dealerId: currentDealer?.id,
    proposalId: currentProposal?.id,
    handleCloseModal,
    handleConfirm,
    handleEvaluateLater,
    handleOnChangeValue,
    handleClickOnStartEvaluation,
    loading: isLoadingCheckDealerBelongsToGroup,
    modal: modal,
    dealerBelongsToSagaGroup,
  };

  return <PricingVehicleComponent {...viewComponentProps} />;
};

export default withPricingVehicleProvider(PricingVehicleContainer);
