import React from 'react'
import { ReactComponent as AppleStore } from 'assets/apple-store.svg'
import { ReactComponent as GooglePlay } from 'assets/google-play.svg'
import { ReactComponent as Mobiauto } from 'assets/mobiauto.svg'
import { LinkExternal } from 'components/Link'
import {
  Container,
  TopContainer,
  BottomContainer,
  MobigestorText,
  Title,
  Text,
  MobiLogo,
} from './styles'

const GOOGLE_PLAY_APP_LINK =
  'https://play.google.com/store/apps/details?id=br.com.mobiauto.revendedor'
const APPLE_STORE_APP_LINK =
  'https://apps.apple.com/br/app/mobiauto-gestor/id1469897220'

const MobileBlockPage = () => {
  return (
    <Container>
      <TopContainer>
        <Title>Para acessar essa área no celular, baixe nosso App.</Title>
      </TopContainer>
      <BottomContainer>
        <MobiLogo>
          <Mobiauto />
        </MobiLogo>
        <div>
          <MobigestorText>Mobigestor</MobigestorText>
          <Text>
            Gerencie seu estoque, anuncie em diversos portais e venda seus
            veículos com facilidade usando nosso gestor.
          </Text>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <LinkExternal href={GOOGLE_PLAY_APP_LINK}>
            <GooglePlay style={{ width: 'auto', height: '40px' }} />
          </LinkExternal>
          <LinkExternal href={APPLE_STORE_APP_LINK}>
            <AppleStore style={{ width: 'auto', height: '45px' }} />
          </LinkExternal>
        </div>
      </BottomContainer>
    </Container>
  )
}

export default MobileBlockPage
