import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export default {
  setFinancingSystemStatus: (status): Action => {
    return { type: ActionTypes.SET_FINANCING_SYSTEM_STATUS, payload: status };
  },
  resetFinancingStore: (): Action => {
    return { type: ActionTypes.RESET_FINANCING_STORE };
  },
  getFinancingListing: (): Action => {
    return { type: ActionTypes.GET_FINANCING_LISTING };
  },
  setFinancingListingIsLoading: (isLoading): Action => {
    return {
      type: ActionTypes.SET_FINANCING_LISTING_IS_LOADING,
      payload: isLoading,
    };
  },
  setFinancingListing: (financings): Action => {
    return { type: ActionTypes.SET_FINANCING_LISTING, payload: financings };
  },
  setCurrentDealerId: (dealerId): Action => {
    return { type: ActionTypes.SET_CURRENT_DEALER_ID, payload: dealerId };
  },
  setActiveStep: (stepValue): Action => {
    return {
      type: ActionTypes.SET_ACTIVE_STEP,
      payload: stepValue,
    };
  },
  setCompletedStep: (completedArray): Action => {
    return {
      type: ActionTypes.SET_COMPLETE_STEP,
      payload: completedArray,
    };
  },
  //CLIENT_DATA:
  createContact: (dealerId, clientData, callback, selectContact): Action => {
    return {
      type: ActionTypes.CREATE_CONTACT,
      payload: { dealerId, clientData, callback, selectContact },
    };
  },
  setCreateContactIsLoading: (isLoading): Action => {
    return {
      type: ActionTypes.SET_CREATE_CONTACT_IS_LOADING,
      payload: isLoading,
    };
  },
  getContactById: (): Action => {
    return {
      type: ActionTypes.GET_CONTACT_DATA_BY_ID,
    };
  },
  setClientCurrent: (clientObject): Action => {
    return {
      type: ActionTypes.SET_CLIENT_CURRENT,
      payload: clientObject,
    };
  },
  //VEHICLE_DATA
  setCurrentVehicle: (deal): Action => {
    return {
      type: ActionTypes.SET_VEHICLE_CURRENT,
      payload: deal,
    };
  },
  //SELECT_BANK_TO_FINANCING
  selectBankToFinancing: (banks): Action => {
    return {
      type: ActionTypes.SELECT_BANK_TO_FINANCING,
      payload: banks,
    };
  },
  //CHANGE CURRENT CLIENT DARA
  changeCurrentClientData: (dataName, dataValue): Action => {
    return {
      type: ActionTypes.CHANGE_CLIENT_CURRENT_DATA,
      payload: { dataName, dataValue },
    };
  },
  getStates: (): Action => {
    return {
      type: ActionTypes.GET_STATES,
    };
  },
  setStates: (statesOptions): Action => {
    return {
      type: ActionTypes.SET_STATES,
      payload: statesOptions,
    };
  },
  getCitieByState: (uf): Action => {
    return {
      type: ActionTypes.GET_CITIES_BY_STATE,
      payload: uf,
    };
  },
  setCitiesByState: (citiesOptions): Action => {
    return {
      type: ActionTypes.SET_CITIES_BY_STATE,
      payload: citiesOptions,
    };
  },
  //CREATE_FINANCING
  createFinancing: (callback): Action => {
    return {
      type: ActionTypes.CREATE_FINANCING,
      payload: { callback },
    };
  },
  setFinancing: (financing): Action => {
    return {
      type: ActionTypes.SET_FINANCING,
      payload: financing,
    };
  },
  setFinancingOrderById: (financingOrder): Action => {
    return {
      type: ActionTypes.SET_FINANCING_ORDER_BY_ID,
      payload: financingOrder,
    };
  },
  setFinancingOrderbyIdLoading: (isLoading): Action => {
    return {
      type: ActionTypes.SET_FINANCING_ORDER_BY_ID_LOADING,
      payload: isLoading,
    };
  },
  getFinancingById: (dealerId, financingId, callback): Action => {
    return {
      type: ActionTypes.GET_FINANCING_ORDER_BY_ID,
      payload: { dealerId, financingId, callback },
    };
  },
  //SIMULATION
  getcommissionReturnOptions: (): Action => {
    return {
      type: ActionTypes.GET_COMISSION_RETURN_OPTIONS,
    };
  },
  setcommissionReturnOptions: commissionReturnOptions => {
    return {
      type: ActionTypes.SET_COMISSION_RETURN_OPTIONS,
      payload: commissionReturnOptions,
    };
  },
  setCurrentVehiclePrice: (vehiclePrice): Action => {
    return {
      type: ActionTypes.SET_CURRENT_VEHICLE_PRICE,
      payload: vehiclePrice,
    };
  },
  setCurrentvehicleInfo: (name, value): Action => {
    return {
      type: ActionTypes.SET_CURRENT_VEHICLE_INFO,
      payload: { name, value },
    };
  },
  setDownPayment: (downPayment): Action => {
    return {
      type: ActionTypes.SET_DOWN_PAYMENT,
      payload: downPayment,
    };
  },
  setinstallment: (installment): Action => {
    return {
      type: ActionTypes.SET_INSTALLMENT,
      payload: installment,
    };
  },
  setcommissionReturn: (commissionReturn): Action => {
    return {
      type: ActionTypes.SET_COMISSION_RETURN,
      payload: commissionReturn,
    };
  },
  setFinancingValue: (financingValue): Action => {
    return {
      type: ActionTypes.SET_FINANCING_VALUE,
      payload: financingValue,
    };
  },
  simulate: (): Action => {
    return {
      type: ActionTypes.SIMULATE,
    };
  },
  simulateById: (institutionId, currentBank): Action => {
    return {
      type: ActionTypes.SIMULATE_BY_ID,
      payload: { institutionId, currentBank },
    };
  },
  setSimulateBankDataById: (institutionId, data) => {
    return {
      type: ActionTypes.SET_SIMULATE_BANK_DATA_BY_ID,
      payload: { institutionId, data },
    };
  },
  //BANKS_TO_SIMULATE
  setBanksToSimulate: (banks): Action => {
    return {
      type: ActionTypes.SET_BANKS_TO_SIMULATE,
      payload: banks,
    };
  },
  setSimulateLoading: (isLoading): Action => {
    return {
      type: ActionTypes.SET_SIMULATE_LOADING,
      payload: isLoading,
    };
  },
  changeDownPaymentByBankId: (institutionId, value): Action => {
    return {
      type: ActionTypes.CHANGE_DOWN_PAYMENT_BY_BANK,
      payload: { institutionId, downPayment: value },
    };
  },
  changeCommissionReturnByBankId: (institutionId, value): Action => {
    return {
      type: ActionTypes.CHANGE_COMMISSIONRETURN_BY_BANK,
      payload: { institutionId, commissionReturn: value },
    };
  },
  changeTermsByBankId: (institutionId, value): Action => {
    return {
      type: ActionTypes.CHANGE_TERMS_BY_BANK,
      payload: { institutionId, term: value },
    };
  },
  changeSimulateTermByBankId: (institutionId, value): Action => {
    return {
      type: ActionTypes.CHANGE_SIMULATE_TERM_BY_BANK,
      payload: { institutionId, simulateTerm: value },
    };
  },
  addRatesConfigByBank: (institutionId, newRateConfigs): Action => {
    return {
      type: ActionTypes.ADD_RATES_CONFIG_BY_BANK,
      payload: { institutionId, newRateConfigs },
    };
  },
  applySimulation: (callback): Action => {
    return {
      type: ActionTypes.APPLY_SIMULATION,
      payload: { callback },
    };
  },
  setApplySimulationLoading: isLoading => {
    return {
      type: ActionTypes.SET_APPLY_SIMULATION_LOADING,
      payload: isLoading,
    };
  },
  applyCreditAnalysis: (callback): Action => {
    return {
      type: ActionTypes.APPLY_CREDIT_ANALYSIS,
      payload: { callback },
    };
  },
  sendClientForm: (
    currentStep,
    callback = () => {
      return;
    }
  ): Action => {
    return {
      type: ActionTypes.SEND_CLIENT_FORM,
      payload: { currentStep, callback },
    };
  },
  setEnableStepResultAnalysis: (isEnable): Action => {
    return {
      type: ActionTypes.SET_ENABLE_STEP_RESULT_ANALYSIS,
      payload: isEnable,
    };
  },
  //SET_BANKS_RESULT_ANALYSIS
  setApplications: (application): Action => {
    return {
      type: ActionTypes.SET_APPLICATIONS,
      payload: application,
    };
  },
  setBanksResultAnalysis: (banks): Action => {
    return {
      type: ActionTypes.SET_BANKS_RESULT_ANALYSIS,
      payload: banks,
    };
  },
  getCreditAnalyses: (): Action => {
    return {
      type: ActionTypes.GET_CREDIT_ANALYSIS,
    };
  },
  setCreditAnalysesLoading: (isLoading): Action => {
    return {
      type: ActionTypes.SET_RESULT_ANALYSIS_LOADING,
      payload: isLoading,
    };
  },
  sendContractFinancing: (status, callback): Action => {
    return {
      type: ActionTypes.CONTRACT_FINANCING,
      payload: { status, callback },
    };
  },
  //OPTIONS
  getGendersOptions: (): Action => {
    return {
      type: ActionTypes.GET_GENDER_OPTIONS,
    };
  },
  setGendersOptions: (options): Action => {
    return {
      type: ActionTypes.SET_GENDER_OPTIONS,
      payload: options,
    };
  },
  getCivilStatusOptions: (): Action => {
    return {
      type: ActionTypes.GET_CIVIL_STATUS_OPTIONS,
    };
  },
  setCivilStatusOptions: (options): Action => {
    return {
      type: ActionTypes.SET_CIVIL_STATUS_OPTIONS,
      payload: options,
    };
  },
  getStreetTypeOptions: (): Action => {
    return {
      type: ActionTypes.GET_STREET_TYPE_OPTIONS,
    };
  },
  setStreetTypeOptions: (options): Action => {
    return {
      type: ActionTypes.SET_STREET_TYPE_OPTIONS,
      payload: options,
    };
  },
  getAddressTypeOptions: (): Action => {
    return {
      type: ActionTypes.GET_ADDRESS_TYPE_OPTIONS,
    };
  },
  setAddressTypeOptions: (options): Action => {
    return {
      type: ActionTypes.SET_ADDRESS_TYPE_OPTIONS,
      payload: options,
    };
  },
  getCareersOptions: (): Action => {
    return {
      type: ActionTypes.GET_CAREERS_OPTIONS,
    };
  },
  setCareersOptions: (options): Action => {
    return {
      type: ActionTypes.SET_CAREERS_OPTIONS,
      payload: options,
    };
  },
  getEmploymentsOptions: (): Action => {
    return {
      type: ActionTypes.GET_EMPLOYMENTS_OPTIONS,
    };
  },
  setEmploymentsOptions: (options): Action => {
    return {
      type: ActionTypes.SET_EMPLOYMENTS_OPTIONS,
      payload: options,
    };
  },
  getAllFinancialInstitutionOptions: (): Action => {
    return {
      type: ActionTypes.GET_ALL_FINANCIAL_INSTITUTION_OPTIONS,
    };
  },
  setAllFinancialInstitutionOptions: (options): Action => {
    return {
      type: ActionTypes.SET_ALL_FINANCIAL_INSTITUTION_OPTIONS,
      payload: options,
    };
  },
  getBankAccountTypeOptions: (): Action => {
    return {
      type: ActionTypes.GET_BANK_ACCOUNT_TYPE_OPTIONS,
    };
  },
  setBankAccountTypeOptions: (options): Action => {
    return {
      type: ActionTypes.SET_BANK_ACCOUNT_TYPE_OPTIONS,
      payload: options,
    };
  },
  getAllDealerPJFinancialInstitution: (delaerId): Action => {
    return {
      type: ActionTypes.GET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION,
      payload: delaerId,
    };
  },
  setAllDealerPJFinancialInstitution: (data): Action => {
    return {
      type: ActionTypes.SET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION,
      payload: data,
    };
  },
  setCurrentFinancialInstitution: (currentFinancialInstitution): Action => {
    return {
      type: ActionTypes.SET_CURRENT_FINANCIAL_INSTITUTION,
      payload: currentFinancialInstitution,
    };
  },
  //FINANCING INSTITUTION
  changeFinancialInstitutionCurrentTab: currentTab => {
    return {
      type: ActionTypes.SET_FINANCIAL_INSTITUTION_CURRENT_TAB,
      payload: currentTab,
    };
  },
  saveNewInstitution: (dealerId, data, callback): Action => {
    return {
      type: ActionTypes.POST_NEW_INSTITUTION,
      payload: { dealerId, data, callback },
    };
  },
  getInstitutionById: (dealerId, id): Action => {
    return {
      type: ActionTypes.GET_BY_INSTITUTION,
      payload: { dealerId, id },
    };
  },
  updateInstitution: (dealerId, id, dataUpdate): Action => {
    return {
      type: ActionTypes.UPDATE_INSTITUTION,
      payload: { dealerId, id, dataUpdate },
    };
  },
  setInstitution: (data): Action => {
    return {
      type: ActionTypes.SET_INSTITUTION,
      payload: data,
    };
  },

  //CAMPAIGN
  setCampaigns: (data): Action => {
    return {
      type: ActionTypes.SET_CAMPAIGNS,
      payload: data,
    };
  },
  setCampaign: (data): Action => {
    return {
      type: ActionTypes.SET_CAMPAIGN,
      payload: data,
    };
  },
  createCampaign: (dealerId, data, callback): Action => {
    return {
      type: ActionTypes.CREATE_CAMPAIGN,
      payload: { dealerId, data, callback },
    };
  },
  updadeCampaign: (dealerId, campaignId, dataUpdate, callback): Action => {
    return {
      type: ActionTypes.UPDATE_CAMPAIGN,
      payload: { dealerId, campaignId, dataUpdate, callback },
    };
  },
  deleteCampaign: (dealerId, campaignId): Action => {
    return {
      type: ActionTypes.DELETE_CAMPAIGN,
      payload: { dealerId, campaignId },
    };
  },
  getCampaignById: (dealerId, campaignId): Action => {
    return {
      type: ActionTypes.GET_CAMPAIGN_BY_ID,
      payload: { dealerId, campaignId },
    };
  },
  //PRINT_OUT_ORDER
  printOutOrder: (dealerId, proposalId): Action => {
    return {
      type: ActionTypes.PRINT_OUT_ORDER,
      payload: { dealerId, proposalId },
    };
  },
};
