import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import lazyRetry from 'helpers/lazyRetry';
import isEmpty from 'helpers/isEmpty';
import { ReactComponent as AnotationIcon } from 'assets/anotationIcon.svg';
import { useChatContext } from 'pages/chats/ChatsContext';
import Annotation from './Annotation';
import ItemHeader from '../atoms/ItemHeader';

const AddAnnotation = React.lazy(() =>
  lazyRetry(() => import('components/AddAnnotation'))
);

export const Button = styled.div`
  font-family: Poppins Medium;
  text-transform: initial;
  background-color: transparent;
  border: none;
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
  margin-left: 16px;
  margin-bottom: 8px;
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #e0f2f1;
  border-radius: 90px;
  margin-right: 8px;
`;

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > span {
    color: #333333;
    font-size: 16px;
    line-height: 4em;
  }
`;
const AnnotationScroll = styled.div`
  padding: 16px;
  max-height: 568px;
  overflow-y: auto;
`;
const Annotations = () => {
  const [state, setState] = useState({
    openModalAddAnnotation: false,
  });

  const { currentProposal, getCurrentProposal } = useChatContext();

  const handleClickOpenModalAddAnnotation = () => {
    setState({
      openModalAddAnnotation: !state.openModalAddAnnotation,
    });
  };

  const handleClickCloseModalAddAnnotation = () => {
    setState({ openModalAddAnnotation: false });
  };

  if (isEmpty(currentProposal)) return null;

  return (
    <React.Fragment>
      <ItemHeader>Anotações</ItemHeader>
      <AnnotationScroll>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ width: '100%' }}
        >
          {currentProposal.annotations &&
            currentProposal.annotations.map((item, id) => (
              <Annotation
                key={id}
                id={id}
                item={item}
                last={currentProposal.annotations?.length - 1 == id}
              />
            ))}
          {!currentProposal.annotations?.length && (
            <EmptyListContainer>
              <IconContainer>
                <AnotationIcon />
              </IconContainer>
              <span>Nenhuma anotação criada</span>
              <Button onClick={handleClickOpenModalAddAnnotation}>
                Adicionar anotação
              </Button>
            </EmptyListContainer>
          )}
        </Grid>
      </AnnotationScroll>
      {!!currentProposal.annotations?.length && (
        <Button onClick={handleClickOpenModalAddAnnotation}>
          Adicionar anotação
        </Button>
      )}
      {state.openModalAddAnnotation && (
        <AddAnnotation
          open={state.openModalAddAnnotation}
          handleClose={handleClickCloseModalAddAnnotation}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() => {
            getCurrentProposal();
            handleClickCloseModalAddAnnotation();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Annotations;
