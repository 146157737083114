import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchServices from 'services/searchServices';
import ActionCreators from 'modules/financing/store/reducers/actionCreators';
import GuarantorAddressInfosForm from './GuarantorAddressInfosForm';

const getCitieByState = async (ufData, callback) => {
  try {
    if (!ufData) {
      return;
    }
    const { success, data }: any = await SearchServices.getCitiesByState(ufData);
    if (success) {
      const arr: any = [{ value: 0, label: 'Selecione...' }];
      data.map(el => {
        arr.push({ label: el.name, value: el.id });
      });
      callback(arr);
    }
  } catch (e) {
    console.log(e);
  }
}

const GuarantorAddressInfosFormContainer = ({ formData, onChange, disableAllInputs = false, enableCopyButton = false }) => {
  //REDUX_HOOKS
  const {
    statesOptions,
    citiesOptions,
    streetTypeOptions,
    addressTypeOptions,
  } = useSelector(state => state.financing);
  const dispatch = useDispatch();
  //CITIES
  const [citiesOptionsState, setCitiesOptions] = useState(citiesOptions);
  //GET_ADDRESS_INFO_BY_ZIP
  const getAddresInfosByZipCode = async () => {
    try {
      const { success, data }: any = await SearchServices.getLocationByZipcode(
        formData['guarantorZipcode']
      );
      if (success) {
        if (data.uf) {
          dispatch(ActionCreators.getCitieByState(data.uf));
          onChange({ target: { name: 'guarantorCityStateId', value: data.uf } });
          onChange({ target: { name: 'guarantorAddress', value: data.logradouro } });
          onChange({ target: { name: 'guarantorCityId', value: data.cidadeId } });
          onChange({ target: { name: 'guarantorStreetType', value: data.tipoEndereco } });
          onChange({ target: { name: 'guarantorNeighborhood', value: data.bairro } });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  //GET_ADDRES_INFOS_BY_CEP_ON_PRESS_ENTER
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      getAddresInfosByZipCode();
    }
  };
  //GET_OPTIONS
  useEffect(() => {
    dispatch(ActionCreators.getStreetTypeOptions());
    dispatch(ActionCreators.getAddressTypeOptions());
  }, []);

  //GET_CITIES_OPTIONS_ON_CHANGE_STATE
  useEffect(() => {
    getCitieByState(formData['guarantorCityStateId'], setCitiesOptions);
  }, [formData['guarantorCityStateId']]);

  const viewComponentProps = {
    statesOptions,
    streetTypeOptions,
    addressTypeOptions,
    citiesOptionsState,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  };

  return <GuarantorAddressInfosForm {...viewComponentProps} />;
};

export default GuarantorAddressInfosFormContainer;
