import { Box } from '@material-ui/core';
import TagChip from 'components/atoms/TagChip';
import InputColor from 'components/InputColor';
import TextField from 'modules/financing/components/atoms/TextField';
import dealerServices from 'services/dealerServices';
import { useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import React, { useState } from 'react';
import Wrapper from 'components/Wrapper';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';

export interface ModalCreateTagsProps {
  open: boolean;
  handleCloseModal: () => void;
  callBackOnSaveSuccess: () => void;
  proposalDealerId: string | number;
}

interface TagObject {
  name: string;
  color: string;
}

const ModalCreateTags = ({
  open,
  handleCloseModal,
  callBackOnSaveSuccess,
  proposalDealerId,
}: ModalCreateTagsProps) => {
  const dispatch = useDispatch();

  const [currentTag, updateCurrentTag] = useState<TagObject>({
    name: '',
    color: '#00FFFF',
  });

  const handleChangeCurrentTagInfos = (key, value) => {
    updateCurrentTag(prevState => ({ ...prevState, [key]: value }));
  };

  const createNewTag = async newTag => {
    try {
      const response: any = await dealerServices.createTag(
        proposalDealerId,
        newTag
      );
      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Alteração salva com sucesso.'
          )
        );
        handleCloseModal();
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement('error', 'Ocorreu um erro.')
      );
    }
  };

  const saveTags = async () => {
    try {
      await createNewTag(currentTag);
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro indesperado.'
        )
      );
    } finally {
      handleCloseModal();
      callBackOnSaveSuccess();
    }
  };

  return (
    <GenericDialog
      open={open}
      onClose={handleCloseModal}
      title={'Criar nova tag'}
      maxWidth="sm"
      DialogAction={() => (
        <>
          <Wrapper
            flex
            justify="end"
            direction={'row'}
            gap={16}
            style={{ width: '100%' }}
          >
            <OutlinedButton onClick={handleCloseModal}>Cancelar</OutlinedButton>
            <ThemeButton onClick={saveTags} disabled={!currentTag.name?.length}>
              Salvar
            </ThemeButton>
          </Wrapper>
        </>
      )}
    >
      <Box component="div" display="flex">
        <TextField
          name="tagLabel"
          label="Título"
          placeholder={'Ex: Lead Prioritário'}
          value={currentTag.name}
          onChange={event =>
            handleChangeCurrentTagInfos('name', event.target.value)
          }
          inputProps={{ maxLength: 26 }}
          helperText={
            currentTag?.name?.length < 26 ? (
              `${currentTag?.name?.length || 0}/26`
            ) : (
              <Box color="#F44336">
                {currentTag?.name?.length}/26 Limite de 26 caracteres atingido!
              </Box>
            )
          }
          mini
        />

        <Box component="div" margin="0px 10px">
          <InputColor
            value={currentTag.color}
            onChange={value => handleChangeCurrentTagInfos('color', value)}
          />
        </Box>
      </Box>

      <Box component="div">
        <Box
          component="div"
          display="flex"
          height="55px"
          margin="8px 28px"
          bgcolor="#EBEBEB"
          justifyContent="center"
          alignItems="center"
          position={'relative'}
        >
          <div
            style={{
              position: 'absolute',
              left: 8,
              top: 0,
              color: '#6c6b6b',
            }}
          >
            Pré-visualização
          </div>
          {!!currentTag?.name?.length && (
            <TagChip
              label={currentTag.name}
              tagId={null}
              color={currentTag.color}
              onClickCancel={() => null}
            />
          )}
        </Box>
      </Box>
    </GenericDialog>
  );
};
export default ModalCreateTags;
