import firebase from 'firebase/app';
import '@firebase/messaging';
import { postCloudMessageToken } from './services/cloudMessageServices';
import envVars from 'helpers/envVars';

export const initFirebase = () => {
  firebase.initializeApp({
    apiKey: envVars('FIREBASE_API_KEY'),
    authDomain: envVars('FIREBASE_AUTH_DOMAIN'),
    databaseURL: envVars('FIREBASE_DATABASE_URL'),
    projectId: envVars('FIREBASE_PROJECT_ID'),
    storageBucket: envVars('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: envVars('FIREBASE_MESSAGING_SENDER_ID'),
    appId: envVars('FIREBASE_APP_ID'),
    measurementId: envVars('FIREBASE_MEASUREMENT_ID'),
  });
};

export const getPermissionToNotifications = async () => {
  const messaging = firebase.messaging();
  try {
    Notification.requestPermission();
    const token = await messaging.getToken();
    if (token) {
      postCloudMessageToken(token);
    }
    return messaging;
  } catch (error) {
    console.error(error);
  }
};
