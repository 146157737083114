import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import MCIIcon from 'pages/chats/components/SideBarComponents/MobiSmartConsultant/assets/MCIIcon';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import clsx from 'clsx';
import useTypingEffect from 'hooks/useTypingEffect';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import ItemHeader from '../atoms/ItemHeader';
import MCILogo from './assets/MCILogo';
import {
  AnimatedLeftToRight,
  BoxMessage,
  ChatWrapper,
  ContainerMessage,
  ContainerOvalBigger,
  ContainerSugestionsMessages,
  ContainerTriangle,
  CopyMessageForInputWhatsApp,
  GoToBottomButtom,
  InputWrapper,
  MessagesScrollView,
  OvalBigger,
  SugestionsMessages,
  Triangle,
} from './styles';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
    marginBottom: '15px',
  },
  input: {
    flex: 1,
    lineHeight: '26px',
    height: 'auto',
    minHeight: '48px',
    paddingLeft: 16,
    background: '#EEEEEE',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: '5px',
    borderRadius: '90px',
    '& .MuiInputBase-inputMultiline': {
      paddingLeft: '30px',
    },
  },
}));

const MobiSmartConsultantComponent = ({
  data,
  ovalLetter,
  lastMessageUser,
  handleClickSuggestion,
  isFetching,
  defaultOptions,
  messageWriteInput,
  setMessageWriteInput,
  handleKeyPress,
  sendMessageWriteToMobiSmartConsultant,
  messageEnv,
  handleCopyMessageForWhatsAppInput,
  currentProposal,
}) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    createTypingEffect,
    firstMessage,
    isLoadingFirstMessage,
    setIsLoadingFirstMessage,
  } = useTypingEffect();

  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [onBottomList, setOnBottomList] = useState(true);
  const [dataToAdd, setDataToAdd] = useState([]);
  const canShowSuggestion =
    !isLoadingFirstMessage &&
    data?.suggestions?.length &&
    !lastMessageUser &&
    !isFetching;

  const handleIntercomButton = (displayValue: string) => {
    const intercomElement = document.getElementsByName(
      'intercom-launcher-frame'
    )?.[0];

    if (intercomElement) {
      intercomElement.style.display = displayValue;
    }
  };

  const scrollToBottomLastInteraction = () => {
    const container = containerRef.current;

    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    if (!isLoadingFirstMessage && !!(firstMessage?.length < 117)) {
      setIsLoadingFirstMessage(true);
      createTypingEffect(
        'Boas vindas ao Mobi Consultor Inteligente! Estou aqui para te auxiliar no dia a dia e responder suas perguntas da melhor forma possível.',
        true
      );
      return;
    }
  }, [currentProposal?.id]);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      if (container) {
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        if (scrollHeight - scrollTop === clientHeight) {
          setOnBottomList(true);
        } else {
          setOnBottomList(false);
        }

        if (
          container.scrollTop ===
          container.scrollHeight - container.clientHeight
        ) {
          setIsUserScrolling(false);
        } else {
          setIsUserScrolling(true);
        }
      }
    };

    handleIntercomButton('none');
    container.addEventListener('scroll', handleScroll);

    return () => {
      handleIntercomButton('block');
      setDataToAdd([]);
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    if (!isUserScrolling) {
      container.scrollTop = container.scrollHeight;
    }
  }, [
    data,
    messageEnv,
    lastMessageUser,
    isUserScrolling,
    firstMessage,
    isLoadingFirstMessage,
  ]);

  useEffect(() => {
    const messages = data?.chat;
    const hasAlreadyread = data?.exist;

    if (!messages?.length || isFetching) {
      return;
    }

    if (dataToAdd.length === messages?.length) {
      return;
    }

    if (hasAlreadyread) {
      setDataToAdd(prevDataToAdd => [...prevDataToAdd, ...messages]);
      scrollToBottomLastInteraction();
      return;
    }

    const interval = setInterval(() => {
      let prev = [];
      setDataToAdd(prevDataToAdd => {
        prev = prevDataToAdd;
        return [...prevDataToAdd, messages?.[prevDataToAdd.length]];
      });
      scrollToBottomLastInteraction();

      if (messages && prev.length === messages.length - 1) {
        clearInterval(interval);
      }
    }, 1500);
  }, [isFetching]);

  return (
    <>
      <ItemHeader>
        <MCILogo />
      </ItemHeader>
      <ChatWrapper>
        <MessagesScrollView ref={containerRef}>
          <ContainerMessage bot={true}>
            <ContainerOvalBigger>
              <OvalBigger bot={true}>
                <MCIIcon />
              </OvalBigger>
            </ContainerOvalBigger>
            <ContainerTriangle>
              <Triangle bot={true} />
            </ContainerTriangle>
            <BoxMessage bot={true}>{firstMessage}</BoxMessage>
          </ContainerMessage>
          {!isLoadingFirstMessage &&
            dataToAdd?.map((el, i) =>
              el?.role === 'bot' ? (
                <ContainerMessage key={i} bot={true}>
                  <ContainerOvalBigger>
                    <OvalBigger bot={true}>
                      <MCIIcon />
                    </OvalBigger>
                  </ContainerOvalBigger>
                  <ContainerTriangle>
                    <Triangle bot={true} />
                  </ContainerTriangle>
                  <BoxMessage bot={true}>
                    {el.message?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />{' '}
                      </React.Fragment>
                    ))}

                    <Tooltip title={'Copiar e editar essa mensagem'}>
                      <CopyMessageForInputWhatsApp
                        onClick={() =>
                          handleCopyMessageForWhatsAppInput(el?.message)
                        }
                      >
                        <AssignmentOutlinedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      </CopyMessageForInputWhatsApp>
                    </Tooltip>
                  </BoxMessage>
                </ContainerMessage>
              ) : (
                <ContainerMessage key={i} bot={false}>
                  <BoxMessage bot={false}>{el?.message}</BoxMessage>
                  <ContainerTriangle>
                    <Triangle bot={false} />
                  </ContainerTriangle>
                  <ContainerOvalBigger bot={false}>
                    <OvalBigger>{ovalLetter}</OvalBigger>
                  </ContainerOvalBigger>
                </ContainerMessage>
              )
            )}
          {canShowSuggestion && (
            <ContainerSugestionsMessages>
              {data?.suggestions?.map((el, index) => (
                <SugestionsMessages
                  key={index}
                  onClick={() => handleClickSuggestion(el)}
                >
                  {el}
                </SugestionsMessages>
              ))}
            </ContainerSugestionsMessages>
          )}
          {!isLoadingFirstMessage && lastMessageUser && (
            <ContainerMessage bot={false}>
              <BoxMessage bot={false}>{lastMessageUser}</BoxMessage>
              <ContainerTriangle>
                <Triangle bot={false} />
              </ContainerTriangle>
              <ContainerOvalBigger bot={false}>
                <OvalBigger>{ovalLetter}</OvalBigger>
              </ContainerOvalBigger>
            </ContainerMessage>
          )}
          {(!isFetching || !lastMessageUser) && isLoadingFirstMessage && (
            <ContainerMessage bot={true}>
              <ContainerOvalBigger>
                <OvalBigger bot={true}>
                  <MCIIcon />
                </OvalBigger>
              </ContainerOvalBigger>
              <ContainerTriangle>
                <Triangle bot={true} />
              </ContainerTriangle>
              <BoxMessage bot={true}>
                <Lottie
                  options={defaultOptions}
                  height={20}
                  width={40}
                  speed={0.8}
                  isStopped={false}
                  isPaused={false}
                  direction={1}
                />
              </BoxMessage>
            </ContainerMessage>
          )}
        </MessagesScrollView>
        <InputWrapper>
          {!!dataToAdd.length && !onBottomList && (
            <GoToBottomButtom onClick={scrollToBottomLastInteraction}>
              <ArrowDownwardIcon />
            </GoToBottomButtom>
          )}
          <AnimatedLeftToRight>
            <InputBase
              className={clsx(classes.margin, classes.input)}
              multiline
              value={messageWriteInput}
              placeholder={`Fale com o Mobi Consultor Inteligente`}
              rowsMax="2"
              onChange={event => setMessageWriteInput(event.target.value)}
              onKeyPress={event => handleKeyPress(event)}
              disabled={currentProposal?.proposalFinished}
              endAdornment={
                isFetching && lastMessageUser ? (
                  <CircularProgress
                    size="20px"
                    style={{ marginRight: '20px' }}
                  />
                ) : (
                  <>
                    {!currentProposal?.proposalFinished ? (
                      <InputAdornment position="end">
                        {messageWriteInput && (
                          <IconButton
                            aria-label="clear text"
                            onMouseDown={null}
                            disabled={
                              isFetching ||
                              lastMessageUser ||
                              !messageWriteInput
                            }
                            onClick={() => setMessageWriteInput('')}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            sendMessageWriteToMobiSmartConsultant()
                          }
                          onMouseDown={null}
                          disabled={
                            isFetching || lastMessageUser || !messageWriteInput
                          }
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null}
                  </>
                )
              }
            />
          </AnimatedLeftToRight>
        </InputWrapper>
      </ChatWrapper>
    </>
  );
};

export default MobiSmartConsultantComponent;
