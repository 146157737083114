export const numberFormat = ({
  value,
  maximumSignificantDigits = 3,
  maximumFractionDigits = 0,
}: {
  value: number;
  maximumSignificantDigits?: number;
  maximumFractionDigits?: number;
}) => {
  return new Intl.NumberFormat('pt-BR', {
    maximumSignificantDigits,
    maximumFractionDigits,
  }).format(value);
};
