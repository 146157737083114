import {
  Button,
  Dialog,
  DialogContent,
  withStyles,
  DialogActions,
} from '@material-ui/core';
import styled, { css } from 'styled-components';

export const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleText = styled.h1`
  font-weight: bold;
  font-size: 20px;
  color: #424242;
`;

export const CloseDialogButton = styled(Button)`
  text-transform: none !important;
  color: #424242 !important;
  font-weight: bold !important;
`;

export const SavePaymentButton = styled(Button)`
  text-transform: none !important;
  color: white !important;
  background-color: #4c5fae !important;
  font-weight: bold !important;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5 !important;
    `}
`;

export const FormLine = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  > * {
    margin-right: 10px !important;
  }
`;

export const HelperAlertText = styled.b`
  color: #f9a825;
`;
export const StyledDialog = withStyles({
  paper: {
    overflowY: 'visible',
  },
})(Dialog);

export const StyledDialogContent = withStyles({
  root: {
    overflowY: 'visible',
  },
})(DialogContent);

export const SubTitle = styled.span`
  font-size: 14px;
`;
export const CheckboxContent = styled.div`
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DialogActionsContainer = styled(DialogActions)`
  padding: 16px !important;
`;
