import * as colors from '@material-ui/core/colors';
import styled, { css } from "styled-components";

export const SubTitle = styled.span`
  font-size: 14px;
  color: #424242;
`

export const TableCard = styled.div`
  border-radius: 4px;
  border: 1px solid #e0e0e0;
`

export const ProductValue = styled.span`
  color: ${colors.green[500]}
`

export const CalcContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  >span{
    margin: 0px 28px;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.blueGrey[500]}
  }
`

export const CalcItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  span{
    font-size: 10px;
    text-transform: uppercase;
    ${({variant}) => variant === 'accent' 
    ? css`
      color: ${colors.blueGrey[800]}
    `
    : css`
      color: ${colors.grey[500]}
    `}
  }
  h4{
    margin: 0;
    line-height: 1em;
    font-size: 16px;
    ${({variant}) => variant === 'accent' 
    ? css`
      font-weight: 700;
      color: ${colors.green[500]}
    `
    : css`
      font-weight: 500;
      color: ${colors.blueGrey[500]}
    `}
  }
`