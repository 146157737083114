import React from 'react';
import ListDeals from 'modules/financing/components/molecules/ListDeals';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import { Line } from './styles';

function BookVehicleDialog({
  currentVehicle,
  handleOnClose,
  handleClickSelectVehicle,
  handleConfirmDialog,
  open,
}) {
  const DialogAction = () => (
    <Line>
      <OutlinedButton onClick={handleOnClose}>Cancelar</OutlinedButton>
      <div style={{ width: 8 }} />
      <ThemeButton
        onClick={handleConfirmDialog}
        disabled={!currentVehicle?.[0]?.id}
      >
        Continuar
      </ThemeButton>
    </Line>
  );

  return (
    <GenericDialog
      open={open}
      onClose={handleOnClose}
      title="Reservar Veículo"
      aria-labelledby="Reservar Veículo"
      DialogAction={DialogAction}
      maxWidth={null}
      fullWidth
    >
      <ListDeals
        selectedVehicles={currentVehicle}
        handleClickItem={vehicle => handleClickSelectVehicle([vehicle])}
      />
    </GenericDialog>
  );
}

export default BookVehicleDialog;
