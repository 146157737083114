import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { OrderProduct } from 'modules/order/types/OrderProduct';
import { CalcContainer, CalcItem, ProductValue, SubTitle, TableCard } from './styles'
import formatAmount from 'helpers/formatAmount';
import { useSelector } from 'react-redux';

export type FinancingProductsProps = {
  financingValue?: number;
  totalValue?: number;
}

export default function FinancingProducts({ financingValue = 0, totalValue = 0 }: FinancingProductsProps) {

  const { paymentData: { products } } = useSelector(state => state.order)

  const [financingProducts, setFinancingProducts] = useState<OrderProduct[]>([])
  const [totalFinancingProducts, setTotalFinancingProducts] = useState<number>(0)

  const getFinancingProducts = () => {
    const mappedFinancingProducts = (products as OrderProduct[])
      .filter(product => product.includedOnFinancing)

    setFinancingProducts(mappedFinancingProducts)

    const sumFinancingProducts = mappedFinancingProducts
      .map(product => product.value)
      .reduce((a, b) => a + b, 0)

    setTotalFinancingProducts(sumFinancingProducts)
  }

  useEffect(() => {
    getFinancingProducts()
  }, [])

  return (
    <>
      <SubTitle>Serviços adicionais inclusos no financiamento</SubTitle>
      <TableContainer component={TableCard}>
        <Table>
          <TableBody>
            {financingProducts.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.proposalProduct.name}
                </TableCell>
                <TableCell align="right">
                  <ProductValue>
                    +{formatAmount(row.value, "PT-BR", "R$")}
                  </ProductValue>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CalcContainer>
        <CalcItem>
          <span>Valor financiado</span>
          <h4>{formatAmount(financingValue, "PT-BR", "R$")}</h4>
        </CalcItem>
        <span>+</span>
        <CalcItem>
          <span>Serviços inclusos</span>
          <h4>{formatAmount(totalFinancingProducts, "PT-BR", "R$")}</h4>
        </CalcItem>
        <span>=</span>
        <CalcItem variant="accent">
          <span>Total financiado</span>
          <h4>{formatAmount(totalValue, "PT-BR", "R$")}</h4>
        </CalcItem>
      </CalcContainer>
    </>
  );
}