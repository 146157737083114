import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'mobifiService';
const domain = EnvVars('API_MOBIFI_BASE_URL');

/*const options = 'options'
const  = 'vehicle-type'*/
const apiVersion = 'v1.0';

//const baseUrl = `${options}/${apiVersion}/${vehicleType}`

export const getVisitNow = id => {
  return request
    .get(`${domain}/mobifi/${apiVersion}/${id}`)
    .then(buildHTTPResponseHandler(`${serviceName}.getVisitNow()`));
};

export const getNewVisits = id => {
  return request
    .get(`${domain}/mobifi/${apiVersion}/${id}/new_visits`)
    .then(buildHTTPResponseHandler(`${serviceName}.getNewVisits()`));
};

export const getRecurrentVisits = id => {
  return request
    .get(`${domain}/mobifi/${apiVersion}/${id}/recurrent_visits`)
    .then(buildHTTPResponseHandler(`${serviceName}.getRecurrentVisits()`));
};

export const getAllVisits = (id, dates = 0) => {
  let params = {
    inicialDate: dates[0],
    finalDate: dates[1],
  };
  return request
    .get(`${domain}/mobifi/${apiVersion}/${id}/all_visits`, params)
    .then(buildHTTPResponseHandler(`${serviceName}.getAllVisits()`));
};
