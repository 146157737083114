import Request from 'helpers/request';
import EnvVars from 'helpers/envVars';

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

const uploadCSV = async (file, dealerId, setProgressFunc) => {
  const config = {
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (setProgressFunc) setProgressFunc(percentCompleted);
    },
  };

  let formData = new FormData();
  formData.append('file', file);

  const response = await Request.post(
    `${domain}/${dealerId}/dealbulk/v1.0/upload?createDeal=false`,
    formData,
    config
  );

  if (response.ok) {
    return { success: true, data: response.data };
  } else {
    return { success: false, data: response.data };
  }
};

export default uploadCSV;
