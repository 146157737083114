import envVars from 'helpers/envVars';
import request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

import * as DTO from './dto';

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'messageTemplate';

const formatObjectToSearchParams = (obj: object) => {
  const formatted = Object.entries(obj)
    .filter(([, value]) => {
      return !(Array.isArray(value) && value.length === 0);
    })
    .map(([key, value]) => `${key}=${String(value)}`)
    .join('&');

  return formatted?.length ? `?${formatted}` : '';
};

export async function getProposalMessageTemplates({
  channel,
  dealerId,
  proposalId,
}: DTO.GetProposalMessageTemplatesQ) {
  const params = formatObjectToSearchParams({ channel });

  const response = await request
    .get<DTO.GetProposalMessageTemplatesR>(
      `${domain}/dealer/${dealerId}/proposals/v1.0/messages-template/${proposalId}${params}`
    )
    .then(response => {
      buildHTTPResponseHandler(`${serviceName}.getProposalMessageTemplates()`);
      return response?.data;
    });

  return response;
}
