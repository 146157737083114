import { useSelector } from 'react-redux';

const useDealerId = () => {
  const dealerId = useSelector(
    ({ dealerStore }) => dealerStore.currentDealer.id
  );
  return dealerId;
};

export default useDealerId;
