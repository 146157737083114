import styled, { css } from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  margin-right: 30px;
  gap: 16px;
`;

export const BackButton = styled(Button)`
  background: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  color: #424242 !important;
  text-transform: inherit !important;
`;

export const NextButton = styled(Button)`
  background: ${({ theme }) => `${theme.primary}`} !important;
  color: #fff !important;
  text-transform: inherit !important;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5 !important;
    `}
`;
