import isEmpty from 'helpers/isEmpty'
import { Select } from 'types/SelectType'

export default function(array: number[] = []): Select[] {
  const aux = [...array].reverse()
  const productionYears: Select[] = []
  if (isEmpty(aux)) {
    return productionYears
  }
  productionYears.push(
    ...aux.map(element => {
      return { value: element, label: element.toString() }
    })
  )
  productionYears.push({
    value: aux[aux.length - 1] - 1,
    label: String((aux[aux.length - 1] - 1).toString()),
  })
  return productionYears
}
