import { all, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'types/ActionTypes';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import DealServices from 'modules/manageDeals/services/DealServices';
import DealDocumentServices from 'modules/manageDeals/services/DealDocumentServices';
import DealerDocumentServices from 'modules/financial/services/DealerDocumentServices';

//DEALS
function* getById(action: Action) {
  const response = yield DealServices.show(action.payload.dealId);
  if (response.success) {
    yield put(ActionCreators.selectDeal(response.data));
  }
}

//DOCUMENTS
function* putRequest(action: Action) {
  const { dealId, ...deal } = action.payload.deal;
  const response = yield DealServices.put(dealId, deal.status);
  if (response.success) {
    yield put(ActionCreators.updateDeal(deal.status));
  }
}

function* postDocument(action: Action) {
  const { dealId, payload } = action.payload;
  yield put(ActionCreators.loadingDocs(true));
  const { success, data } = yield DealDocumentServices.create(dealId, payload);
  if (success) {
    yield put(ActionCreators.addDocument(data));
  }
}

function* putDocument(action: Action) {
  const { dealId, documentId, payload } = action.payload;
  const { success, data } = yield DealDocumentServices.put(
    dealId,
    documentId,
    payload
  );
  if (success) {
    yield put(ActionCreators.updateDocument(data));
  }
}

function* deleteDocument(action: Action) {
  const { dealId, documentId } = action.payload;
  yield put(ActionCreators.loadingDocs(true));
  const { success, data } = yield DealDocumentServices.delete(
    dealId,
    documentId
  );
  if (success) {
    yield put(ActionCreators.delDocument(data));
  }
}

//TRANSACTIONALS
function* postTransactional(action: Action) {
  const { payload } = action.payload;
  const { success, data } = yield DealServices.postTransactionalFromDeal(
    payload
  );
  if (success) {
    yield put(ActionCreators.addTransactional(data));
  }
}

function* putTransactional(action: Action) {
  const { id, payload } = action.payload;
  const { success, data } = yield DealServices.putTransactionalFromDeal(
    id,
    payload
  );
  if (success) {
    yield put(ActionCreators.updateTransactional(data));
  }
}

function* deleteTransactional(action: Action) {
  const { id } = action.payload;
  const { success, data } = yield DealServices.deleteTransactionalFromDeal(id);
  if (success) {
    yield put(ActionCreators.delTransactional(data));
  }
}

//INVOICE
function* createProductInvoice(action: Action) {
  try {
    const { transactional } = action.payload;
    const { currentDealer }: any = yield select(
      ({ dealerStore }) => dealerStore
    );
    const { success, data } = yield DealerDocumentServices.createProductInvoice(
      currentDealer.id,
      {
        transactionalId: transactional.id,
      }
    );
    if (success) {
      yield put(
        ActionCreators.updateTransactional({
          ...transactional,
          invoiceUrl: data,
        })
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteTransactionalInvoice(action: Action) {
  const {
    transactional: { invoicedUrl, ...tran },
  } = action.payload;
  const {
    success,
    data,
  } = yield DealDocumentServices.deleteTransactionalInvoice(tran.id);
  if (success) {
    yield put(ActionCreators.updateTransactional(data));
  }
}

export default all([
  takeLatest(ActionTypes.GET_DEAL_BY_ID_REQUEST, getById),
  takeLatest(ActionTypes.PUT_DEAL_REQUEST, putRequest),
  takeLatest(ActionTypes.POST_DOCUMENT_REQUEST, postDocument),
  takeLatest(ActionTypes.PUT_DOCUMENT_REQUEST, putDocument),
  takeLatest(ActionTypes.DELETE_DOCUMENT_REQUEST, deleteDocument),
  takeLatest(ActionTypes.POST_TRANSACTIONAL_REQUEST, postTransactional),
  takeLatest(ActionTypes.PUT_TRANSACTIONAL_REQUEST, putTransactional),
  takeLatest(ActionTypes.DELETE_TRANSACTIONAL_REQUEST, deleteTransactional),
  takeLatest(ActionTypes.POST_INVOICE_REQUEST, createProductInvoice),
  takeLatest(
    ActionTypes.DELETE_TRANSACTIONAL_INVOICE_REQUEST,
    deleteTransactionalInvoice
  ),
]);
