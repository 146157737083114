import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import GenericDialog from 'components/GenericDialog';
import Scheduling from './components/Scheduling';

const AddScheduleDeliveryComponent = ({
  currentDate = null,
  open,
  onClose,
  DialogAction,
  proposalSelected,
  date,
  setDate,
  refetchData,
}) => {
  return (
    <GenericDialog
      title="Agendar entrega"
      open={open}
      onClose={onClose}
      DialogAction={DialogAction}
    >
      <div style={{ margin: '8px 8px' }}>
        <div className="dataRangeContainer">
          <DatePicker
            disabled={proposalSelected?.schedule?.id}
            label="Data de entrega"
            inputVariant="outlined"
            value={date}
            format="DD/MM/YYYY"
            required={true}
            onChange={newValue => setDate(newValue)}
          />
        </div>
      </div>
      <div>
        <Scheduling
          date={date}
          proposalSelected={proposalSelected}
          refetchData={refetchData}
        />
      </div>
    </GenericDialog>
  );
};

export default AddScheduleDeliveryComponent;
