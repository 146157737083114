import React, { memo } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Stepper from '@material-ui/core/Stepper';
import StepConnector from '@material-ui/core/StepConnector';
import Step from '@material-ui/core/Step';
import StepperCustomIcon from 'modules/financing/components/atoms/StepperCustomIcon';
import {
  customConnectorStyles,
  componentStyles,
  StyledStepLabel,
} from './styles';

const ColorlibConnector = withStyles(customConnectorStyles)(StepConnector);

function StepperComponent({
  steps,
  stepperConfig,
  steperCustomIcons,
  orientation = 'horizontal',
  stepContent,
  handClickToGoStep,
  classes,
}: {
  steps: any;
  stepperConfig: any;
  handClickToGoStep?: any;
  orientation?: any;
  steperCustomIcons?: any;
  stepContent?: any;
  classes: any;
}): JSX.Element {
  const { currentStep, completedSteps } = stepperConfig;

  const isHorizontal = orientation === 'horizontal';

  const isCompleted = (step: number) => {
    return (
      completedSteps &&
      completedSteps.some(completedStep => completedStep === step)
    );
  };
  const isAvailable = (step: number) => {
    return step > currentStep && isCompleted(step - 1);
  };
  const isActive = (step: number) => {
    return currentStep === step;
  };
  const isClickable = (step: number) => {
    return !isActive(step) && (isAvailable(step) || isCompleted(step));
  };
  const handleClick = (step: number) => {
    if (handClickToGoStep && isClickable(step)) handClickToGoStep(step);
  };
  return (
    <Stepper
      classes={{
        root: isHorizontal ? classes.stepperRoot : classes.stepperRootVertical,
      }}
      alternativeLabel={isHorizontal}
      connector={isHorizontal ? <ColorlibConnector /> : undefined}
      activeStep={currentStep}
      orientation={orientation}
    >
      {steps.map(({ label, value, extraComponent }, index) => (
        <Step key={index} classes={{ root: classes.stepRoot }}>
          <StyledStepLabel
            classes={{
              label: isHorizontal ? classes.label : null,
              iconContainer:
                isHorizontal && isAvailable(value)
                  ? classes.iconContainerAvailable
                  : null,
            }}
            StepIconComponent={steperCustomIcons ?? StepperCustomIcon}
            clickable={isClickable(value)}
            onClick={() => handleClick(value)}
          >
            <div
              className={
                isHorizontal
                  ? isActive(value)
                    ? classes.horizontalLabel
                    : null
                  : classes.verticalLabel
              }
            >
              {label}
              {extraComponent}
            </div>
          </StyledStepLabel>
          {stepContent}
          {orientation !== 'horizontal' && <div style={{ height: 16 }} />}
        </Step>
      ))}
    </Stepper>
  );
}

export default withStyles(componentStyles)(memo(StepperComponent));
