import { ContactCardInfosDTO } from 'modules/financial/pages/components/CreateClient/components/ClientCardOption/types';
import { createPostingDTO, Posting } from 'modules/financial/types';
import { Deal } from 'modules/manageDeals/types';
import { Action } from 'types/ActionTypes';
import actionTypes from './actionTypes';
import { PostingFilters } from './reducers';

export default {
  //SAGAS
  post: (posting: createPostingDTO): Action => ({
    type: actionTypes.POST_POSTING_REQUEST,
    payload: { posting },
  }),

  get: (startDate: number, endDate: number, query: PostingFilters): Action => ({
    type: actionTypes.GET_POSTING_REQUEST,
    payload: { startDate, endDate, query },
  }),

  show: (id: number | string): Action => ({
    type: actionTypes.SHOW_POSTING_REQUEST,
    payload: { id },
  }),

  put: (posting: any): Action => ({
    type: actionTypes.PUT_POSTING_REQUEST,
    payload: { posting },
  }),

  setIsLoading: (isLoading: boolean): Action => ({
    type: actionTypes.SET_IS_LOADING,
    payload: isLoading,
  }),

  //REDUCERS
  //used afer GET(al) method
  reloadAll: (postings: Posting[]): Action => ({
    type: actionTypes.RELOAD_ALL_POSTINGS,
    payload: { postings },
  }),

  postingCreated: (createdPostingId: number = null): Action => ({
    type: actionTypes.POST_POSTING_SUCCESS,
    payload: { createdPostingId },
  }),

  //used after POST and GET(by id) methods
  save: (postings: Posting[]): Action => ({
    type: actionTypes.SAVE_POSTING,
    payload: { postings },
  }),

  //used after PUT and PATCH methods
  upateExisting: (updatedPosting: Posting): Action => ({
    type: actionTypes.UPDATE_POSTING,
    payload: { updatedPosting },
  }),

  updateSelected: (
    posting: Posting | createPostingDTO | null | any
  ): Action => ({
    type: actionTypes.UPDATE_SELECTED_POSTING,
    payload: { posting },
  }),

  selectDeal: (deal: Deal | null): Action => ({
    type: actionTypes.DEAL_SELECTED,
    payload: { deal },
  }),

  selectContact: (contact: ContactCardInfosDTO | null): Action => ({
    type: actionTypes.CONTACT_SELECTED,
    payload: { contact },
  }),

  setNewContactZipcodeInfo: (info: any): Action => ({
    type: actionTypes.NEW_CONTACT_ZIPCODE_INFO,
    payload: { info },
  }),
};
