import React from 'react';
import Select from 'modules/financing/components/atoms/Select';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CEPMaskCustom, PhoneMaskCustom } from 'components/MaskTextInput';
import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import getDateByDatePickerComponent from 'modules/financing/helpers/getDateByDatePickerComponent';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput';

const CompanyFullPayForm = ({
  statesOptions,
  streetTypeOptions,
  citiesOptionsState,
  handleKeyPress,
  getAddresInfosByZipCode,
  formData,
  onChange,
  disableAllInputs,
  enableCopyButton,
  disableCpfCnpj,
}) => {
  return (
    <>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="companyName"
            name="companyName"
            label="Nome da empresa"
            value={formData['companyName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companySocialName"
            name="companySocialName"
            label="Nome fantasia"
            value={formData['companySocialName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companySocialName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="cnpj"
            name="cnpj"
            label="CNPJ"
            value={formData['cnpj']}
            onChange={onChange}
            disabled={disableAllInputs || disableCpfCnpj}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('cnpj')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyStartDate"
            name="companyStartDate"
            type="date"
            label="Data de criação da empresa"
            value={getDateByDatePickerComponent(formData['companyStartDate'])}
            onChange={onChange}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyEmail"
            name="companyEmail"
            label="Email"
            value={formData['companyEmail']}
            type="email"
            onChange={onChange}
            disabled={disableAllInputs}
            required
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyEmail')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyInscricaoEstadual"
            name="companyInscricaoEstadual"
            label="Inscrição estadual"
            value={formData['companyInscricaoEstadual']}
            onChange={onChange}
            disabled={disableAllInputs}
            required
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() =>
                    contentCopyByNameInput('companyInscricaoEstadual')
                  }
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyPhone"
            name="companyPhone"
            label="Telefone 1"
            value={formData['companyPhone']}
            onChange={onChange}
            disabled={disableAllInputs}
            required
            InputProps={{
              inputComponent: PhoneMaskCustom,
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyPhone')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="companyPhone2"
            name="companyPhone2"
            label="Telefone 2"
            value={formData['companyPhone2']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: PhoneMaskCustom,
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('companyPhone2')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>

        <div style={{ margin: '24px 0px 8px' }}>Endereço da empresa</div>
        <Grid container spacing={1}>
          {/* ROW */}
          <Grid item xs={3}>
            <TextInput
              id="companyZipcode"
              name="companyZipcode"
              label="CEP"
              value={formData['companyZipcode']}
              onKeyPress={handleKeyPress}
              onChange={onChange}
              disabled={disableAllInputs}
              InputProps={{
                inputComponent: CEPMaskCustom,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchButton onClick={getAddresInfosByZipCode} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              id="companyStreetType"
              name="companyStreetType"
              label="Tipo do logradouro"
              value={formData['companyStreetType']}
              onChange={onChange}
              options={streetTypeOptions}
              disabled={disableAllInputs}
            />
          </Grid>
          <Grid item xs={5}>
            <TextInput
              id="companyAddress"
              name="companyAddress"
              label="Endereço"
              value={formData['companyAddress']}
              onChange={onChange}
              disabled={disableAllInputs}
              InputProps={{
                endAdornment: enableCopyButton ? (
                  <RoundedButton
                    onClick={() => contentCopyByNameInput('companyAddress')}
                  >
                    <ContentCopyIcon />
                  </RoundedButton>
                ) : null,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextInput
              id="companyNumber"
              name="companyNumber"
              label="Número"
              value={formData['companyNumber']}
              onChange={onChange}
              disabled={disableAllInputs}
              InputProps={{
                endAdornment: enableCopyButton ? (
                  <RoundedButton
                    onClick={() => contentCopyByNameInput('companyNumber')}
                  >
                    <ContentCopyIcon />
                  </RoundedButton>
                ) : null,
              }}
            />
          </Grid>
          {/* ROW */}
          <Grid item xs={12}>
            <TextInput
              id="companyComplement"
              name="companyComplement"
              label="Complemento (opcional)"
              value={formData['companyComplement']}
              onChange={onChange}
              disabled={disableAllInputs}
              InputProps={{
                endAdornment: enableCopyButton ? (
                  <RoundedButton
                    onClick={() => contentCopyByNameInput('companyComplement')}
                  >
                    <ContentCopyIcon />
                  </RoundedButton>
                ) : null,
              }}
            />
          </Grid>
          {/* ROW */}
          <Grid item xs={4}>
            <TextInput
              id="companyNeighborhood"
              name="companyNeighborhood"
              label="Bairro"
              value={formData['companyNeighborhood']}
              onChange={onChange}
              disabled={disableAllInputs}
              InputProps={{
                endAdornment: enableCopyButton ? (
                  <RoundedButton
                    onClick={() =>
                      contentCopyByNameInput('companyNeighborhood')
                    }
                  >
                    <ContentCopyIcon />
                  </RoundedButton>
                ) : null,
              }}
            />
          </Grid>
          {/* // */}
          <Grid item xs={4}>
            <Select
              id="companyCityStateId"
              name="companyCityStateId"
              label="Estado"
              value={formData['companyCityStateId']}
              onChange={onChange}
              options={statesOptions}
              disabled={disableAllInputs}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              id="companyCityId"
              name="companyCityId"
              label="Cidade"
              value={formData['companyCityId']}
              onChange={onChange}
              options={citiesOptionsState}
              disabled={disableAllInputs}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyFullPayForm;
