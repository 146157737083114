import React from 'react';
import FiltersComponent from './FiltersComponent';

const FiltersContainer = ({ onChangeSearch, state, setState }) => {
  const uniqueCityLabels: { [label: string]: boolean } = {};
  const uniqueStateLabels: { [label: string]: boolean } = {};
  const uniqueBrandLabels: { [label: string]: boolean } = {};

  const cityOptions = (state?.dataTableAux || []).reduce(
    (result: any[], el: any) => {
      const labelCity = el?.city;

      if (
        labelCity &&
        state?.dataTable.some(item => item?.city === labelCity) &&
        !uniqueCityLabels[labelCity]
      ) {
        uniqueCityLabels[labelCity] = true;

        const newPosition = result.length;

        result.push({
          label: labelCity,
          value: newPosition + 1,
        });
      }

      return result;
    },
    []
  );

  const stateOptions = (state?.dataTableAux || []).reduce(
    (result: any[], el: any) => {
      const labelState = el?.state;

      if (
        labelState &&
        state?.dataTable.some(item => item?.state === labelState) &&
        !uniqueStateLabels[labelState] &&
        labelState?.length
      ) {
        uniqueStateLabels[labelState] = true;

        result.push({
          label:
            state?.states?.find(el => el?.id === labelState)?.name ||
            labelState,
          value: labelState,
        });
      }

      return result;
    },
    []
  );

  const brandOptions = (state?.dataTableAux || []).reduce(
    (result: any[], el: any) => {
      const labelBrand = el?.makeName;

      if (
        labelBrand &&
        state?.dataTable.some(item => item?.makeName === labelBrand) &&
        !uniqueBrandLabels[labelBrand]
      ) {
        uniqueBrandLabels[labelBrand] = true;

        const newPosition = result.length;

        result.push({
          label: labelBrand,
          value: newPosition + 1,
        });
      }

      return result;
    },
    []
  );

  const clearFilters = () => {
    setState(prevState => ({
      ...prevState,
      search: '',
      stateSelected: null,
      citySelected: null,
      brandSelected: null,
    }));
  };

  const viewComponentProps = {
    onChangeSearch,
    state,
    setState,
    stateOptions,
    cityOptions,
    brandOptions,
    clearFilters,
  };

  return <FiltersComponent {...viewComponentProps} />;
};

export default FiltersContainer;
