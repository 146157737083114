import React from 'react';
import { useSelector } from 'react-redux';
import Wrapper from 'components/Wrapper';
import * as S from '../../styles';

const SummaryAddress = () => {
  const {
    queueData,
    addressTypeOptions,
    statesOptions,
    citiesOptions,
  } = useSelector(state => ({
    queueData: state?.order?.queueData,
    addressTypeOptions: state.financing?.addressTypeOptions,
    statesOptions: state.financing?.statesOptions,
    citiesOptions: state.financing?.citiesOptions,
  }));

  return (
    <Wrapper flex direction="row">
      <Wrapper flex direction="column" width={'50%'}>
        <S.Text>
          Tipo de endereço:
          {`  ${
            queueData?.client?.addressType
              ? addressTypeOptions?.find(
                  el => el.value === queueData?.client?.addressType
                )?.label
              : ''
          }`}
        </S.Text>

        <S.Text>
          CEP:
          {`  ${queueData?.client?.zipcode ?? ''}`}
        </S.Text>

        <S.Text>
          Endereço:
          {`  ${queueData?.client?.address ?? ''}`}
        </S.Text>
        <S.Text>
          Número:
          {`  ${queueData?.client?.number ?? ''}`}
        </S.Text>
        <S.Text>
          Tipo de logradouro:
          {`  ${queueData?.client?.streetType ?? ''}`}
        </S.Text>
      </Wrapper>

      <Wrapper flex direction="column">
        <S.Text>
          Complemento:
          {`  ${queueData?.client?.complement ?? ''}`}
        </S.Text>

        <S.Text>
          Bairro:
          {`  ${queueData?.client?.neighborhood ?? ''}`}
        </S.Text>

        <S.Text>
          Estado:
          {`  ${
            queueData?.client?.cityStateId
              ? statesOptions?.find(
                  el => el.value === queueData?.client?.cityStateId
                )?.label
              : ''
          }`}
        </S.Text>

        <S.Text>
          Cidade:
          {`  ${
            queueData?.client?.cityId
              ? citiesOptions?.find(e => e.value === queueData?.client?.cityId)
                  ?.label
              : ''
          }`}
        </S.Text>
      </Wrapper>
    </Wrapper>
  );
};
export default SummaryAddress;
