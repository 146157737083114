import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MODE from 'constants/editInLineTypes';
import getInitialsName from 'helpers/getInitialsName';
import handlePDFBlobFile from 'helpers/handlePDFBlobFile';
import { useChatContext } from 'pages/chats/ChatsContext';
import ContactServices from 'modules/resale/services/ContactServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import {
  Item,
  ContactSource,
  IconMobileStyled,
  BlueTag,
  IconEmailStyled,
  NameWrapper,
  OvalBigger,
  CalendarTodayIconStyled,
  PrintIconStyled,
  IDIconStyled,
  WrapperButtons,
  Button,
} from './styles';

function VisualingModeMain({ handleOnChangeMode, closedProposal }) {
  const { currentProposal } = useChatContext();
  const dispatch = useDispatch();
  const { dealerId, contactId } = currentProposal;

  // const [editDialog, setEditDialog] = useState(false);
  // const [clientData, setClientData] = useState({});
  // const [loadingContactData, setLoadingContactData] = useState(false);

  // function handleCloseModal() {
  //   setEditDialog(false);
  // }

  // const getClientData = useCallback(async () => {
  //   try {
  //     setLoadingContactData(true);
  //     const response = await ContactServices.getContactById(
  //       dealerId,
  //       contactId
  //     );
  //     if (response.success) {
  //       setClientData(response.data);
  //     } else {
  //       console.log('Nao foi possivel fazer requisição');
  //     }
  //     setLoadingContactData(false);
  //   } catch (error) {
  //     setLoadingContactData(false);
  //   }
  // }, [contactId, dealerId]);

  // useEffect(() => {
  //   if (contactId) {
  //     getClientData();
  //   }
  // }, [contactId, getClientData]);

  const handlePrintClientData = useCallback(async () => {
    try {
      const response = await ContactServices.printContact(dealerId, contactId);
      if (response.success) {
        handlePDFBlobFile(response.data, `contato-${contactId}`, true);
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Dados alterados com sucesso.'
          )
        );
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Desculpe, Ocorreu um erro inesperado.'
          )
        );
      }
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  }, [contactId, dealerId, dispatch]);

  return (
    <>
      <ListItem>
        {/* {loadingContactData ? (
          <Progress small />
        ) : ( */}
        <Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <NameWrapper>
              <OvalBigger>
                {getInitialsName(currentProposal?.clientUserName || 'Sem nome')}
              </OvalBigger>
              {currentProposal?.clientUserName || 'Sem nome'}
            </NameWrapper>

            <Box component="div" display="flex" flexDirection="row">
              {contactId && (
                <OvalBigger>
                  <IconButton onClick={handlePrintClientData}>
                    <PrintIconStyled />
                  </IconButton>
                </OvalBigger>
              )}
            </Box>
          </div>

          {currentProposal?.contactId && (
            <ContactSource>
              <IDIconStyled />
              <BlueTag>{currentProposal?.contactId}</BlueTag>
            </ContactSource>
          )}

          <ContactSource>
            <IconMobileStyled />
            <BlueTag>
              {currentProposal?.clientUserPhone || 'Sem número'}
            </BlueTag>
          </ContactSource>

          <ContactSource>
            <CalendarTodayIconStyled />
            <BlueTag>{currentProposal?.birthday || 'Sem data'}</BlueTag>
          </ContactSource>

          <ContactSource>
            <IconEmailStyled />
            <BlueTag>{currentProposal?.clientEmail || 'Sem email'}</BlueTag>
          </ContactSource>
        </Item>
        {/* )} */}
      </ListItem>
      {!closedProposal && (
        <>
          <WrapperButtons>
            <Button onClick={() => handleOnChangeMode(MODE.EDITING)}>
              Editar dados
            </Button>
          </WrapperButtons>
        </>
      )}
      {/* {!!editDialog && (
        <EditClientDialog
          open={editDialog}
          handleCloseModal={handleCloseModal}
          clientData={clientData}
          getClientData={getClientData}
        />
      )} */}
    </>
  );
}

export default VisualingModeMain;
