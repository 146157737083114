import styled from 'styled-components';

export const MatSelectContainer = styled.div`
  width: 100%;
`;

export const ListMessageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListMessage = styled.span`
  color: #4e4c4c;
`;

export const TextFieldInput = styled.div`
  display: flex !important;
`;

export const styles = theme =>
  ({
    singleValue: {
      overflow: 'hidden',
      position: 'absolute',
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
    },
    paper: {
      position: 'absolute',
      zIndex: 5,
      marginTop: 0,
      left: 0,
      right: 0,
    },
  } as any);
