import React from 'react';
class Error500Component extends React.Component {
  render() {
    const ViewComponent = this.props.viewComponent;
    const viewComponentProps = {};
    return <ViewComponent {...viewComponentProps} {...this.props} />;
  }
}

export default Error500Component;
