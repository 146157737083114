import React from 'react';
import Checkbox from 'bit/mobiauto.web-ui-components.components.checkbox/Checkbox';
import { Container } from './styles';

const ListItem = ({ option, isChecked, onChange }) => {
  return (
    <Container onClick={onChange}>
      <label>{`${option?.name || option?.id} (${
        option?.numElementsFounded
      })`}</label>
      <Checkbox checked={isChecked} onChange={onChange} />
    </Container>
  );
};

export default ListItem;
