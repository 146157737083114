import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericDialog from 'components/GenericDialog';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import TextInput from 'bit/mobiauto.web-ui-components.components.text-input/TextInput';
import ActionCreator from 'modules/order/store/reducers/actionCreators';
import { Flex, RadioLabel, NextButton } from './styles';

const APPROVAL_STATUS = {
  APPROVED: 'APPROVED',
  DISAPPROVED: 'DISAPPROVED',
};

const DEFAULT_STATE = {
  approvalStatus: '',
  refuseReason: null,
};

const EvaluationProposalDialog = ({ open, onClose }) => {
  const [evaluationOptions, setEvaluationOptions] = useState(DEFAULT_STATE);
  const { approveIsLoading } = useSelector(state => state.order);
  const dispatch = useDispatch();

  function handleClickConclude() {
    dispatch(
      ActionCreator.approvalProposal(
        evaluationOptions.approvalStatus,
        evaluationOptions.refuseReason || null,
        onClose
      )
    );
  }

  return (
    <GenericDialog
      open={open}
      onClose={onClose}
      title="Aprovar pedido"
      maxWidth="xs"
      DialogAction={() =>
        approveIsLoading ? (
          <NextButton
            onClick={() => {
              return;
            }}
          >
            <Progress small />
          </NextButton>
        ) : (
          <NextButton onClick={handleClickConclude}>Concluir</NextButton>
        )
      }
    >
      <div>
        <Flex>
          <RadioButton
            onClick={() => {
              setEvaluationOptions({
                ...DEFAULT_STATE,
                approvalStatus: APPROVAL_STATUS.APPROVED,
              });
            }}
            checked={
              evaluationOptions.approvalStatus === APPROVAL_STATUS.APPROVED
            }
          />
          <RadioLabel>Aprovar</RadioLabel>
        </Flex>
        <Flex>
          <RadioButton
            onClick={() => {
              setEvaluationOptions({
                ...DEFAULT_STATE,
                approvalStatus: APPROVAL_STATUS.DISAPPROVED,
              });
            }}
            checked={
              evaluationOptions.approvalStatus === APPROVAL_STATUS.DISAPPROVED
            }
          />
          <RadioLabel>Recusar</RadioLabel>
        </Flex>
        {evaluationOptions.approvalStatus === APPROVAL_STATUS.DISAPPROVED && (
          <TextInput
            type="textarea"
            name="textarea"
            label="Justificativa"
            multiline
            rowsMax="10"
            placeholder=""
            error={false}
            value={evaluationOptions.refuseReason}
            onChange={event =>
              setEvaluationOptions(prevState => ({
                ...prevState,
                refuseReason: event.target.value,
              }))
            }
          />
        )}
      </div>
    </GenericDialog>
  );
};

export default EvaluationProposalDialog;
