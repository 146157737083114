import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';

import MUIAccordion from '@material-ui/core/Accordion';
import MUIAccordionSummary from '@material-ui/core/AccordionSummary';
import MUIAccordionDetails from '@material-ui/core/AccordionDetails';
import MUIChip from '@material-ui/core/Chip';
import MUIButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

export const PopoverContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 368px;
`;

export const FixedHeader = styled('div')`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid #e0e0e0;
`;

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

export const OtherElements = styled('div')`
  flex: 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const MainTitle = styled('h3')`
  font-family: 'Poppins Bold';
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #424242;
`;

export const Content = styled('div')`
  max-height: calc(60vh - 56px);
  overflow-y: auto;
`;

export const SelectedContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 0;
  margin-top: 1rem;
`;

export const SelectedTitle = styled('h4')`
  font-family: 'Poppins Bold';
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #424242;
  letter-spacing: 0.35px;
  margin: 0;
  text-wrap: nowrap;
`;

export const CurrentFilters = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const FilterButton = styled(IconButton)<{ isActive: boolean }>`
  margin-left: 8px !important;

  ${props =>
    props.isActive
      ? css`
          background-color: #00b4aa !important;
          color: #fff !important;
        `
      : css`
          color: #4443bc !important;
        `};
`;

export const MainButton = withStyles(() => ({
  root: {
    width: '120px',
    height: '3rem',
    color: '#424242',
    textTransform: 'capitalize',
    fontFamily: 'Poppins Medium',
    fontSize: '0.875rem',
    borderColor: '#e0e0e0',
    backgroundColor: '#FFF',
  },
}))(MUIButton);

export const ClearFiltersButton = withStyles(() => ({
  root: {
    height: '1.5rem',
    color: '#4443BC',
    textTransform: 'none',
    fontFamily: 'Poppins Regular',
    fontSize: '0.875rem',
  },
}))(MUIButton);

export const SelectedChip = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    height: '1.5rem',
    color: '#424242',
    textTransform: 'none',
    fontFamily: 'Poppins Regular',
    fontSize: '0.75rem',
    '&:hover, &:focus, &:active': {
      backgroundColor: '#FFF',
    },
    '& > .MuiChip-deleteIcon': {
      color: '#424242',
    },
  },
}))(MUIChip);

export const Accordion = withStyles(() => ({
  root: {
    '&.Mui-expanded': {
      margin: '0',
      '&:before': {
        opacity: '1',
      },
    },
    '& > .MuiAccordionSummary-root': {
      '&.Mui-expanded': {
        minHeight: 'auto',
        '& > .MuiAccordionSummary-content.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
  },
}))(MUIAccordion);

export const AccordionSummary = withStyles(() => ({
  root: {
    height: '56px',
    '& > .MuiAccordionSummary-content': {
      lineHeight: '1.5rem',
      color: '#757575',
      fontSize: '1rem',
      fontFamily: 'Poppins Medium',
      margin: '0',
    },
  },
}))(MUIAccordionSummary);

export const AccordionDetails = withStyles(() => ({
  root: {
    border: '1px solid #e0e0e0',
    borderWidth: '1px 0',
    padding: '1rem',
  },
}))(MUIAccordionDetails);
