import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import styled from 'styled-components';

export const InfosContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 355px;
`;

export const styles = theme => ({
  card: {
    display: 'flex',
    width: '100%',
    border: '1px solid',
    borderColor: '#f0f8ff',
    borderRadius: '4px',
    boxShadow: '0 0 4px 0 rgba(51, 51, 51, 0.1)',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    marginBottom: 10,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main || Colors.primary.medium,
    },
  },
  cover: {
    minWidth: '122px',
    height: '85px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    objectFit: 'scale-down',
    backgroundSize: 'contain',
  },

  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '12px',
    padding: '8px',
  },
  modelName: {
    fontFamily: 'Poppins Regular',
    fontSsize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'start',
  },
  spec: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '12px',
    height: '20px',
  },
  types: {
    fontSize: '12px',
    fontFamily: 'Poppins Regular',
    color: `${Colors.gray.medium}`,
    background: '#F5F5F5',
    borderRadius: 20,
    padding: '4px 8px',
  },
  bookedAt: {
    fontSize: '12px',
    fontFamily: 'Poppins Regular',
    color: `#4443BC`,
    background: '#E9EAF9',
    borderRadius: 20,
    padding: '4px 8px',
    whiteSpace: 'nowrap',
  },
});
