import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import DeliveryInfosComponent from './DeliveryInfosComponent';

const DeliveryInfosContainer = ({ renderCheckBoxOrderMadeAtTheAssembler }) => {
  const dispatch = useDispatch();

  const { queueData } = useSelector(state => state.order);

  const [deliveryForecast, setDeliveryForecast] = useState<any>(
    queueData?.delivery?.forecastDate
      ? moment(queueData?.delivery?.forecastDate).format('YYYY-MM-DDTHH:mm:ss')
      : null
  );

  const [externalId, setExternalId] = React.useState();

  const handleChangeDeliveryForecast = value => {
    setDeliveryForecast(value);
    dispatch(
      ActionCreators.setQueueData({
        delivery: {
          ...queueData.delivery,
          forecastDate: new Date(value).getTime(),
        },
      })
    );
  };

  const handleChangeExternalId = value => {
    setExternalId(value);
    dispatch(
      ActionCreators.setQueueData({
        delivery: {
          ...queueData.delivery,
          externalId: value,
        },
      })
    );
  };

  useEffect(() => {
    if (queueData?.delivery?.externalId > 0) {
      handleChangeExternalId(queueData?.delivery?.externalId);
    }
  }, [externalId, queueData?.delivery?.externalId]);

  useEffect(() => {
    if (queueData?.delivery?.forecastDate) {
      setDeliveryForecast(
        moment(queueData?.delivery?.forecastDate).format('YYYY-MM-DDTHH:mm:ss')
      );
    }
  }, [queueData?.delivery?.forecastDate]);

  const viewComponentProps = {
    renderCheckBoxOrderMadeAtTheAssembler,
    deliveryForecast,
    handleChangeDeliveryForecast,
    handleChangeExternalId,
    externalId,
    queueData,
  };

  return <DeliveryInfosComponent {...viewComponentProps} />;
};

export default DeliveryInfosContainer;
