import styled, { css } from 'styled-components';

// Interface import
import { ChatListShow } from 'pages/conversations/interfaces';

export const Container = styled.header<{
  display: ChatListShow;
  unreadView: boolean;
}>`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;

  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  height: ${props => (props.display === 'CONVERSATIONS' ? 'auto' : '120px')};
  height: ${props => props.unreadView && 'auto'};
`;

export const ConversationWrapper = styled.div<{ display: ChatListShow }>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;

  h1 {
    font-family: 'Poppins Bold';
    color: #424242;
  }

  ${props =>
    props.display === 'INACTIVE_LEADS' &&
    css`
      justify-content: start;

      h1 {
        margin-left: 1rem;
      }
    `};

  > button > svg {
    color: #4443bc;
  }
`;

export const LeadStatusDisplay = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;
  border-bottom: 1px solid #e8e8e8;

  > div {
    display: flex;
    align-items: center;

    color: #757575;

    cursor: pointer;
  }

  p {
    font-family: 'Poppins Regular';
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0063rem;

    margin-left: 0.75rem;

    > span {
      color: #757575;
      margin-left: 8px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  > h1 {
    font-size: 20px;
    letter-spacing: 0.1px;
    font-style: normal;
    color: #424242;
    font-family: 'Poppins Regular';
  }

  > p {
    color: #757575;
  }
`;
