import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchServices from 'services/searchServices';
import ActionCreators from 'modules/financing/store/reducers/actionCreators';
import GuarantorOccupationForm from './GuarantorOccupationForm'

const GuarantorOccupationFormContainer = ({ formData, onChange, disableAllInputs = false, enableCopyButton = false }) => {
  //REDUX_HOOKS
  const {
    statesOptions,
    citiesOptions,
    streetTypeOptions,
    addressTypeOptions,
    careersOptions,
    employmentsOptions,
  } = useSelector(state => state.financing);
  const dispatch = useDispatch();
  //CITIES
  const [employmentCitiesOptions, setEmploymentCitiesOptions] = useState(
    citiesOptions
  );
  //GET_ADDRESS_INFO_BY_ZIP
  const getAddresInfosByZipCode = async () => {
    try {
      const { success, data }: any = await SearchServices.getLocationByZipcode(
        formData['guarantorEmploymentZipcode']
      );
      if (success) {
        if (data.uf) {
          dispatch(ActionCreators.getCitieByState(data.uf));
          onChange({ target: { name: 'guarantorEmploymentCityStateId', value: data.uf } });
          onChange({
            target: { name: 'guarantorEmploymentAddress', value: data.logradouro },
          });
          onChange({
            target: { name: 'guarantorEmploymentCityId', value: data.cidadeId },
          });
          onChange({ target: { name: 'guarantorEmploymentStreetType', value: data.tipoEndereco } });
          onChange({ target: { name: 'guarantorEmploymentNeighborhood', value: data.bairro } });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  //GET_ADDRES_INFOS_BY_CEP_ON_PRESS_ENTER
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      getAddresInfosByZipCode();
    }
  };
  //GET_OPTIONS
  useEffect(() => {
    dispatch(ActionCreators.getStreetTypeOptions());
    dispatch(ActionCreators.getAddressTypeOptions());
    dispatch(ActionCreators.getCareersOptions());
    dispatch(ActionCreators.getEmploymentsOptions());
  }, []);
  //UPDATE_CITIES_OPTIONS_ON_CITIES_OPTIONS_CHANGE
  useEffect(() => {
    setEmploymentCitiesOptions(citiesOptions);
  }, [citiesOptions]);
  //GET_CITIES_OPTIONS_ON_CHANGE_STATE
  useEffect(() => {
    dispatch(ActionCreators.getCitieByState(formData['guarantorEmploymentCityStateId']));
  }, [formData['guarantorEmploymentCityStateId']]);

  useEffect(() => {
    if (formData['guarantorEmploymentId'] === 4) {
      onChange({
        target: { name: 'guarantorEmploymentSocialName', value: '' },
      });
      onChange({
        target: { name: 'guarantorEmploymentStartDate', value: null },
      });
      onChange({
        target: { name: 'guarantorMonthlyIncome', value: null },
      });
      onChange({
        target: { name: 'guarantorEmploymentZipcode', value: null },
      });
      onChange({
        target: { name: 'guarantorEmploymentStreetType', value: null },
      });
      onChange({
        target: { name: 'guarantorEmploymentAddress', value: '' },
      });
      onChange({
        target: { name: 'guarantorEmploymentNumber', value: '' },
      });
      onChange({
        target: { name: 'guarantorEmploymentComplement', value: '' },
      });
      onChange({
        target: { name: 'guarantorEmploymentNeighborhood', value: '' },
      });
      onChange({
        target: { name: 'guarantorEmploymentCityStateId', value: null },
      });
      onChange({
        target: { name: 'guarantorEmploymentCityId', value: null },
      });
    }
  }, [formData['guarantorEmploymentId']])

  const viewComponentProps = {
    statesOptions,
    employmentCitiesOptions,
    streetTypeOptions,
    addressTypeOptions,
    careersOptions,
    employmentsOptions,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  };

  return <GuarantorOccupationForm {...viewComponentProps} />;
};

export default GuarantorOccupationFormContainer;
