import { Deal, Transactional } from '../../types';
import actionTypes from './actionTypes';

import {
  DealDocument,
  UpdateTDocumentDTO,
  createTransactionalDTO,
} from 'modules/manageDeals/types';

export default {
  //REDUCERS
  selectDeal: (deal: Deal) => ({
    type: actionTypes.SELECT_DEAL,
    payload: { deal },
  }),
  updateDeal: (status: string) => ({
    type: actionTypes.UPDATE_DEAL,
    payload: { status },
  }),

  //DOCUMENTS
  addDocument: (document: DealDocument) => ({
    type: actionTypes.ADD_DOCUMENT_DEAL,
    payload: { document },
  }),
  updateDocument: (document: DealDocument) => ({
    type: actionTypes.UPDATE_DOCUMENT_DEAL,
    payload: { document },
  }),
  delDocument: (document: DealDocument) => ({
    type: actionTypes.DELETE_DOCUMENT_DEAL,
    payload: { document },
  }),
  loadingDocs: (loading: boolean) => ({
    type: actionTypes.LOADING_DOCUMENTS,
    payload: { loading },
  }),

  //TRANSACTIONALS
  addTransactional: (transactional: Transactional) => ({
    type: actionTypes.ADD_TRANSACTIONAL_DEAL,
    payload: { transactional },
  }),
  updateTransactional: (transactional: Transactional) => ({
    type: actionTypes.UPDATE_TRANSACTIONAL_DEAL,
    payload: { transactional },
  }),
  delTransactional: (transactional: Transactional) => ({
    type: actionTypes.DELETE_TRANSACTIONAL_DEAL,
    payload: { transactional },
  }),

  //SAGAS
  getDealById: (dealId: string | number) => ({
    type: actionTypes.GET_DEAL_BY_ID_REQUEST,
    payload: { dealId },
  }),

  put: (deal: Deal | any) => ({
    type: actionTypes.PUT_DEAL_REQUEST,
    payload: { deal },
  }),

  //DOCUMENTS
  postDocument: (dealId: string | number, payload: any) => ({
    type: actionTypes.POST_DOCUMENT_REQUEST,
    payload: { dealId, payload },
  }),

  putDocument: (
    dealId: string | number,
    documentId: string | number,
    payload: UpdateTDocumentDTO
  ) => ({
    type: actionTypes.PUT_DOCUMENT_REQUEST,
    payload: { dealId, documentId, payload },
  }),

  delDocumentReq: (dealId: string | number, documentId: string | number) => ({
    type: actionTypes.DELETE_DOCUMENT_REQUEST,
    payload: { dealId, documentId },
  }),

  //TRANSACTIONALS
  postTransactional: (payload: createTransactionalDTO) => ({
    type: actionTypes.POST_TRANSACTIONAL_REQUEST,
    payload: { payload },
  }),
  putTransactional: (id: string | number, payload: createTransactionalDTO) => ({
    type: actionTypes.PUT_TRANSACTIONAL_REQUEST,
    payload: { id, payload },
  }),
  deleteTransactional: (id: string | number) => ({
    type: actionTypes.DELETE_TRANSACTIONAL_REQUEST,
    payload: { id },
  }),

  //INVOICE
  createProductInvoice: (transactional: Transactional) => ({
    type: actionTypes.POST_INVOICE_REQUEST,
    payload: { transactional },
  }),
  deleteTransactionalInvoice: (transactional: Transactional) => ({
    type: actionTypes.DELETE_TRANSACTIONAL_INVOICE_REQUEST,
    payload: { transactional },
  }),
};
