import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DocumentDTO } from 'services/proposalServices';
import { TextLink } from './styles';

const useStyles = makeStyles({
  root: {
    borderTop: '1px solid #e0e0e0',
    justifyContent: 'end',
    padding: '8px 16px',
  },
  title: {
    fontSize: 14,
    fontFamily: 'Poppins Bold',
    overflowWrap: 'anywhere',
  },
  dataTitle: {
    fontSize: 12,
    fontFamily: 'Poppins Bold',
  },
  data: {
    fontSize: 14,
    color: '#757575',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '24px',
    alignItems: 'center',
  },
});

interface Props {
  handleClickDelete: (documentId: string | number) => void;
  docData: DocumentDTO;
  isDeleting: boolean;
  handleClickViewDocument: (docData: DocumentDTO) => void;
  handleClickDownloadDocument: (docData: DocumentDTO) => void;
  optionsTypesDoc?: any;
}

const DocumentCard: React.FC<Props> = ({
  docData,
  handleClickDelete,
  isDeleting,
  handleClickViewDocument,
  handleClickDownloadDocument,
  optionsTypesDoc,
}) => {
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <div className={classes.line}>
          <div className={classes.title}>{docData.name}</div>
          <ButtonBase
            onClick={() => handleClickDelete(docData.id)}
            disabled={isDeleting}
          >
            <DeleteOutlineIcon style={{ color: '#F44336' }} />
          </ButtonBase>
        </div>
        {docData?.documentType && (
          <>
            <Divider style={{ margin: '8px 0px' }} />
            <div className={classes.line}>
              <div className={classes.dataTitle}>Tipo</div>
              <div className={classes.data}>
                {
                  optionsTypesDoc?.find(e => e?.id === docData?.documentType)
                    ?.label
                }
              </div>
            </div>
          </>
        )}
        <Divider style={{ margin: '8px 0px' }} />
        <div className={classes.line}>
          <div className={classes.dataTitle}>Data de envio</div>
          <div className={classes.data}>
            {docData.createdAt
              ? `${moment(docData.createdAt).format('L')} - 
            ${moment(docData.createdAt).format('LT')}`
              : '-'}
          </div>
        </div>
      </CardContent>
      <CardActions className={classes.root}>
        <TextLink onClick={() => handleClickViewDocument(docData)}>
          Ver documentos
        </TextLink>
        <TextLink
          onClick={() => {
            handleClickDownloadDocument(docData);
          }}
        >
          Baixar
        </TextLink>
      </CardActions>
    </Card>
  );
};

export default DocumentCard;
