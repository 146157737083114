import * as React from 'react';

import Radio, { RadioProps } from '@material-ui/core/Radio';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import colors from '../../mobiauto.web-ui-components.components.colors/colors';

function RadioMain(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Radio {...props} />
    </React.Fragment>
  );
}

const RadioDefault = withStyles({
  root: {
    color: colors.secondary.medium,
    '&$checked': {
      color: colors.primary.medium,
    },
  },
  checked: {},
})(RadioMain);

const RadioSuccess = withStyles({
  root: {
    color: colors.secondary.medium,
    '&$checked': {
      color: colors.success.medium,
    },
  },
  checked: {},
})(RadioMain);

const RadioError = withStyles({
  root: {
    color: colors.dangerous.medium,
    '&$checked': {
      color: colors.primary.medium,
    },
  },
  checked: {},
})(RadioMain);

const RadioDisabled = withStyles({
  root: {
    color: colors.secondary.medium,
    '&$checked': {
      color: colors.secondary.medium,
    },
  },
  checked: {},
})(RadioMain);

interface Props extends RadioProps {
  variant?: 'error' | 'success';
}

export default function RadioButton({ variant, disabled, ...props }: Props) {
  if (disabled) {
    return <RadioDisabled disabled checked {...props} />;
  }

  if (variant === 'success') {
    return <RadioSuccess {...props} />;
  }

  if (variant === 'error') {
    return <RadioError {...props} />;
  }

  return <RadioDefault {...props} />;
}
