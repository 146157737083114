import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'campaignServices';

export default class CampaignServices {
  static getAllFinancingCampaign = (
    dealerId,
    dealerPJFinancingInstitutionId
  ) => {
    return request
      .get(
        `${domain}/financing/${dealerId}/campaign/${dealerPJFinancingInstitutionId}/v1.0`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getAllFinancingCampaign()`)
      );
  };

  //CRUD_CAMPAIGN

  static getCampaignById = (
    dealerId,
    dealerPJFinancingInstitutionId,
    campaignId
  ) => {
    return request
      .get(
        `${domain}/financing/${dealerId}/campaign/${dealerPJFinancingInstitutionId}/v1.0/${campaignId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getCampaignById()`));
  };

  static createCampaign = (dealerId, dealerPJFinancingInstitutionId, data) => {
    return request
      .post(
        `${domain}/financing/${dealerId}/campaign/${dealerPJFinancingInstitutionId}/v1.0`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.createCampaign()`));
  };

  static updateCampaign = (
    dealerId,
    dealerPJFinancingInstitutionId,
    campaignId,
    data
  ) => {
    return request
      .put(
        `${domain}/financing/${dealerId}/campaign/${dealerPJFinancingInstitutionId}/v1.0/${campaignId}`,
        data
      )
      .then(buildHTTPResponseHandler(`${serviceName}.updateCampaign()`));
  };

  static deleteCampaign = (
    dealerId,
    dealerPJFinancingInstitutionId,
    campaignId
  ) => {
    return request
      .delete(
        `${domain}/financing/${dealerId}/campaign/${dealerPJFinancingInstitutionId}/v1.0/${campaignId}`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.deleteCampaign()`));
  };
}
