import { Fab } from '@material-ui/core';
import styled from 'styled-components';

export const PageHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
`;

export const BackFab = styled(Fab)`
  background-color: transparent !important;
  color: #757575 !important;
  border: 1px solid #e0e0e0 !important;
  box-shadow: none !important;
  margin-right: 16px !important;
`;

export const ProposalTitle = styled.span`
  font-family: Poppins Bold;
  font-size: 24px;
  color: #424242;
`;

export const OrderTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
