import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  Cancel as CancelIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

import { DebounceInput } from 'react-debounce-input';

import useSnackBar from 'hooks/useSnackBar';
import { useChatContext } from 'pages/chats/ChatsContext';

import { EmptyTemplates } from 'modules/messageTemplate/assets/EmptyTemplates';
import { useGetProposalMessageTemplates } from 'modules/messageTemplate/services/queries';
import {
  MessageTemplateCategory,
  ProposalChannel,
  ProposalMessageTemplate,
} from 'modules/messageTemplate/services/dto';

import * as S from './styles';

interface Props {
  channel: ProposalChannel;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
}

const getTemplateCategories = (
  items: ProposalMessageTemplate[]
): MessageTemplateCategory[] => {
  const uniqueCategoriesMap = new Map<number, MessageTemplateCategory>();

  items.forEach(template => {
    const category = template?.category;

    if (category && !uniqueCategoriesMap.has(category?.id)) {
      return uniqueCategoriesMap.set(category.id, category);
    }
  });

  return Array.from(uniqueCategoriesMap.values());
};

const filterTemplatesByCategoryId = ({
  categoryId,
  templates = [],
}: {
  categoryId: number;
  templates: ProposalMessageTemplate[];
}) => {
  return templates.filter(template => template?.category?.id === categoryId);
};

const filterTemplatesBySearchField = ({
  filterValue,
  templates = [],
}: {
  filterValue: string;
  templates: ProposalMessageTemplate[];
}) => {
  const regex = new RegExp(filterValue, 'i');

  const regexValidate = (value: string) => regex.test(value);

  return templates.filter(item => {
    const byMessage = regexValidate(item.message);
    const byCategoryName = regexValidate(item?.category?.name);
    const byContentMessage = regexValidate(item?.content?.message);

    return byMessage || byCategoryName || byContentMessage;
  });
};

function TransitionElement(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
}

const DialogTransition = React.forwardRef(TransitionElement);

export function TemplatesDialog({ channel, isOpen, onClose, onSubmit }: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = useState<ProposalMessageTemplate | null>(null);

  const [categories, setCategories] = useState<MessageTemplateCategory[]>([]);
  const [templates, setTemplates] = useState<ProposalMessageTemplate[]>([]);
  const [listedTemplates, setListedTemplates] = useState<
    ProposalMessageTemplate[]
  >([]);

  const { error } = useSnackBar();
  const { currentProposal }: any = useChatContext();

  const dealerId = +currentProposal?.dealerId;
  const proposalId = +currentProposal?.id;

  const {
    data: dataMessageTemplates,
    mutate: getMessageTemplates,
    isLoading: loadingMessageTemplates,
  } = useGetProposalMessageTemplates({
    onError: () => {
      return error('Desculpe, ocorreu um erro inesperado!');
    },
  });

  const handleOnSubmit = useCallback(() => {
    return onSubmit(selectedTemplate.message);
  }, [selectedTemplate]);

  const handleGetMessageTemplates = useCallback(() => {
    if (!dealerId || !proposalId) return;

    return getMessageTemplates({ channel, dealerId, proposalId });
  }, [channel, dealerId, proposalId]);

  const handleSelectedTemplate = useCallback(
    (templateId: number) => {
      const selected = templates.find(({ id }) => id === templateId);
      if (selected) setSelectedTemplate(selected);
    },
    [templates]
  );

  const handleSelectCategoryTemplate = useCallback(
    (selectedId: number) => {
      const currentId = selectedId !== selectedCategoryId ? selectedId : null;

      setSelectedCategoryId(currentId);

      let filteredItems = [...templates];

      if (currentId !== null) {
        filteredItems = filterTemplatesByCategoryId({
          categoryId: currentId,
          templates,
        });
      }

      filteredItems = filterTemplatesBySearchField({
        filterValue: searchValue,
        templates: filteredItems,
      });

      return setListedTemplates([...filteredItems]);
    },
    [templates, searchValue, selectedCategoryId]
  );

  const handleSearchTemplates = useCallback(
    (inputValue: string) => {
      setSearchValue(inputValue);

      let filteredItems = [...templates];

      if (selectedCategoryId !== null) {
        filteredItems = filterTemplatesByCategoryId({
          categoryId: selectedCategoryId,
          templates,
        });
      }

      filteredItems = filterTemplatesBySearchField({
        filterValue: inputValue,
        templates: filteredItems,
      });

      return setListedTemplates([...filteredItems]);
    },
    [templates, selectedCategoryId]
  );

  const disableSubmitButton = loadingMessageTemplates || !selectedTemplate;
  const hasMessageTemplates = listedTemplates?.length > 0;
  const hasTemplatesCategories = categories?.length > 0;

  useEffect(() => {
    if (isOpen) return handleGetMessageTemplates();
  }, [isOpen]);

  useEffect(() => {
    if (!dataMessageTemplates) return;

    const initTemplates = [...dataMessageTemplates];

    setTemplates(initTemplates);
    setListedTemplates(initTemplates);
    setCategories([...getTemplateCategories(initTemplates)]);
  }, [dataMessageTemplates]);

  return (
    <Dialog
      keepMounted
      maxWidth="md"
      onClose={onClose}
      open={isOpen}
      scroll="paper"
      TransitionComponent={DialogTransition}
    >
      <S.DialogTitle disableTypography>
        <S.HeaderTitle>Mensagens pré-definidas</S.HeaderTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </S.DialogTitle>
      <S.DialogContent>
        {loadingMessageTemplates && (
          <S.LoadingContainer>
            <CircularProgress color="primary" size="3rem" />
          </S.LoadingContainer>
        )}
        {!loadingMessageTemplates && (
          <Fragment>
            <S.FixedFilters>
              {hasTemplatesCategories && (
                <S.FilterContainer>
                  <S.FilterTitle>Filtros:</S.FilterTitle>
                  <S.FilterChips>
                    {categories.map(category => {
                      const isSelected = selectedCategoryId === category.id;
                      return (
                        <S.Chip
                          key={category.id}
                          label={category.name}
                          onClick={() => {
                            return handleSelectCategoryTemplate(category.id);
                          }}
                          {...(isSelected && {
                            color: 'primary',
                            deleteIcon: <CancelIcon />,
                            onClick: () => null,
                            onDelete: () => {
                              return handleSelectCategoryTemplate(category.id);
                            },
                          })}
                        />
                      );
                    })}
                  </S.FilterChips>
                </S.FilterContainer>
              )}
              <S.FilterContainer>
                <DebounceInput
                  element={S.TextField}
                  variant="outlined"
                  placeholder="Busque por palavra-chave"
                  debounceTimeout={150}
                  value={searchValue}
                  onChange={({ target }) => handleSearchTemplates(target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    ...(!!searchValue?.length && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleSearchTemplates('')}>
                            <CancelIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }),
                  }}
                />
              </S.FilterContainer>
            </S.FixedFilters>
            <S.ItemsContainer>
              {!hasMessageTemplates && (
                <S.EmptyContainerMessage>
                  <EmptyTemplates />
                  <div>
                    <b className="title">Nenhum resultado encontrado</b>
                    <p className="subtitle">
                      Tente usar palavras-chave diferentes.
                    </p>
                  </div>
                </S.EmptyContainerMessage>
              )}
              {hasMessageTemplates && (
                <RadioGroup
                  name="selectedTemplate"
                  value={selectedTemplate?.id ?? ''}
                  onChange={({ target }) => {
                    return handleSelectedTemplate(+target.value);
                  }}
                >
                  {listedTemplates.map(template => (
                    <FormControlLabel
                      key={template.id}
                      value={template.id}
                      control={<Radio color="primary" />}
                      label={
                        <S.Tooltip title={template.message} arrow>
                          <S.ItemValue>{template.message}</S.ItemValue>
                        </S.Tooltip>
                      }
                    />
                  ))}
                </RadioGroup>
              )}
            </S.ItemsContainer>
          </Fragment>
        )}
      </S.DialogContent>
      <S.DialogActions>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleOnSubmit}
          disabled={disableSubmitButton}
        >
          Enviar
        </Button>
      </S.DialogActions>
    </Dialog>
  );
}
