import React from 'react';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ThemeButton from 'components/atoms/ThemeButton';
import GenericDialog from 'components/GenericDialog';
import { LineSpaceBetween } from './styles';

const RemoveVehicleComponent = ({
  open,
  handleClose,
  vehicle,
  handleClickConfirm,
}) => {
  const DialogAction = () => {
    return (
      <LineSpaceBetween>
        <OutlinedButton onClick={handleClose}>Cancelar</OutlinedButton>
        <div style={{ width: 8 }} />
        <ThemeButton onClick={handleClickConfirm}>Confirmar</ThemeButton>
      </LineSpaceBetween>
    );
  };

  return (
    <GenericDialog
      open={open && vehicle.id}
      onClose={handleClose}
      title="Excluir veículo"
      aria-labelledby="Adicionar veículo"
      DialogAction={DialogAction}
      maxWidth={'xs'}
    >
      <div style={{ textAlign: 'center' }}>
        Tem certeza que deseja remover o veiculo da proposta?
      </div>
    </GenericDialog>
  );
};

export default RemoveVehicleComponent;
