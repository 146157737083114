import { defaultSelectObject } from 'constants/defaultSelect';
import { DealRepasse as DealRepasseType } from 'types/DealTypes';
import VEHICLE_TYPE from 'constants/vehicleTypes';
const Deal: DealRepasseType = {
  armorType: null,
  armored: false,
  deal0km: false,
  auction: false,
  casualty: false,
  colorId: defaultSelectObject.value,
  resaleComments: '',
  doors: defaultSelectObject.value,
  featureIds: [],
  fuelId: defaultSelectObject.value,
  images: [],
  km: 0,
  makeId: defaultSelectObject.value,
  makeName: '',
  modelId: defaultSelectObject.value,
  modelName: '',
  modelYear: defaultSelectObject.value,
  plate: '',
  resalePrice: 0,
  minimumBidIncrement: 0,
  minimumPriceProposalResale: 0,
  productionYear: defaultSelectObject.value,
  transmissionId: defaultSelectObject.value,
  trimId: defaultSelectObject.value,
  trimName: '',
  reports: [],
  reportId: 0,
  reportUrl: '',
  reportStatus: null,
  tradein: false,
  ownerEmail: '',
  ownerCPF: '',
  ownerName: '',
  ownerPhone: '',
  errors: {},
  vehicleType: VEHICLE_TYPE[0].label,
  vin: '',
  renavam: '',
  manufacturingPackageCode: '',
  youtubeId: '',
  dealerPJLocality: defaultSelectObject,
};
export default Deal;
