import React from 'react';
import styled from 'styled-components';

const Invisible = styled.div`
  display: none;
`;

export default function InvisibleDivTpUpload({
  inputRef,
  handleOnChange,
  accept = 'image/*',
  multiple = false,
}) {
  return (
    <Invisible>
      <input
        ref={inputRef}
        type="file"
        accept={accept}
        onChange={handleOnChange}
        multiple={multiple}
      />
    </Invisible>
  );
}
