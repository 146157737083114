import React from 'react';
import { Avatar, Button, Chip } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { Storage, Close } from '@material-ui/icons';

// Constant import
import Flag from 'constants/columns.js';

// Component import
import { PreAnalysisChip } from '../PreAnalysisChip';

// Context import
import { sidebarIsOpen } from 'pages/conversations/contexts/atoms';
import { useChatContext } from 'pages/chats/ChatsContext';

// Style import
import { Container } from './styles';

const chipColors = {
  NEW: '#0054CC',
  IN_NEGOTIATION: '#E7C300',
  SCHEDULED: '#CC7BF8',
  WITH_ORDER: '#29CE29',
};

export function ChatHeader() {
  // Hooks
  const { currentProposal }: any = useChatContext();

  // States
  const [sideBarIsOpened, setSideBarIsOpened] = useRecoilState(sidebarIsOpen);

  const nameParts =
    !!currentProposal?.clientUserName &&
    (currentProposal.clientUserName || '').split(' ');
  const firstLetterFirstName = !!nameParts && nameParts[0].charAt(0);
  const firstLetterLastName =
    !!nameParts && nameParts.length >= 2 ? nameParts[1].charAt(0) : '';

  const toggleSidebar = () =>
    setSideBarIsOpened(previousState => !previousState);

  const userStatus = currentProposal.orderStatus || currentProposal.status;
  const chipLabel = Flag[userStatus]?.label;
  const chipBackground = chipColors[currentProposal.status];

  return (
    <Container>
      <div>
        <Avatar>
          {firstLetterFirstName}
          {firstLetterLastName}
        </Avatar>

        <span>{currentProposal?.clientUserName}</span>
        {!!chipLabel && (
          <Chip
            label={chipLabel}
            style={{ color: '#FFF', backgroundColor: chipBackground }}
          />
        )}

        <PreAnalysisChip spacing={chipLabel} />
      </div>

      <Button
        variant="text"
        color="primary"
        startIcon={sideBarIsOpened ? <Close /> : <Storage />}
        onClick={toggleSidebar}
        style={{ textTransform: 'none' }}
      >
        {sideBarIsOpened ? 'Fechar' : 'Dados da proposta'}
      </Button>
    </Container>
  );
}
