import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import isEmpty from 'helpers/isEmpty';
import uploadService from 'services/uploadServices';
import proposalServices, { DocumentDTO } from 'services/proposalServices';
import { getMediaUrl, getMediaRequest } from 'components/getMedia';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { useChatContext } from 'pages/chats/ChatsContext';
import DocumentsHistoryComponents from './DocumentsHistoryComponent';
import schema from './components/schema/schema';

const OPTIONS_TYPES_DOC = [
  { id: 'CRLV', label: 'CRLV' },
  { id: 'CRV', label: 'CRV' },
  { id: 'CNH', label: 'CNH' },
  { id: 'CPF', label: 'CPF' },
  { id: 'RG', label: 'RG' },
  { id: 'RESIDENCE_PROOF', label: 'COMPROVANTE DE RESIDÊNCIA' },
  { id: 'OTHER', label: 'Outros' },
];
const DocumentsHistory = () => {
  const dispatch = useDispatch();

  const inputFileRef = useRef(null);

  const [state, setState] = useState({
    //LOADINGS
    isFetching: false,
    isDeleting: false,
    loadingUpload: false,
    //DATA
    documents: [],
    currentDocumentId: null,
    isOpenConfirmation: false,
    isOpenDialogUpload: false,
  });

  const { currentProposal }: any = useChatContext();

  const formik = useFormik({
    initialValues: {
      typeDoc: '',
      uploadDoc: [],
    },
    validationSchema: schema,
    onSubmit: values => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      uploadFile(values?.uploadDoc, values?.typeDoc);
    },
  });

  const handleCloseDialogUpload = () => {
    formik.resetForm();
    setState(prevState => ({ ...prevState, isOpenDialogUpload: false }));
  };

  const manageConfirmationModal = (isOpen, documentId) => {
    setState(prevState => ({
      ...prevState,
      isOpenConfirmation: isOpen,
      currentDocumentId: documentId,
    }));
  };

  const getUploadProgress = (props: any) => {
    console.log('>>>> props', props);
  };

  const getDocuments = async () => {
    try {
      setState(prevState => ({ ...prevState, isFetching: true }));
      const { success, data }: any = await proposalServices.getDocuments(
        currentProposal.dealerId,
        currentProposal.id
      );
      if (success) {
        setState(prevState => ({ ...prevState, documents: data }));
        return;
      }
      throw new Error('');
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    } finally {
      setState(prevState => ({ ...prevState, isFetching: false }));
    }
    return;
  };

  const deleteDocument = async () => {
    try {
      setState(prevState => ({
        ...prevState,
        isDeleting: true,
        isOpenConfirmation: false,
      }));
      const { success }: any = await proposalServices.deleteDocument(
        currentProposal.dealerId,
        currentProposal.id,
        state.currentDocumentId
      );
      if (success) {
        getDocuments();
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Documento deletado com sucesso.'
          )
        );
        return;
      }
      throw new Error('');
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    } finally {
      setState(prevState => ({ ...prevState, isDeleting: false }));
    }
  };

  const addDocument = async (newDocument: any) => {
    try {
      setState(prevState => ({ ...prevState, loadingUpload: true }));
      const { success, data }: any = await proposalServices.addDocument(
        currentProposal?.dealerId,
        currentProposal?.id,
        newDocument
      );
      if (success) {
        getDocuments();
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Documento adicionado com sucesso.'
          )
        );
        formik.resetForm();
        return;
      }
      throw new Error(data);
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    } finally {
      setState(prevState => ({ ...prevState, loadingUpload: false }));
      handleCloseDialogUpload();
    }
  };

  const uploadFile = async (event, typeDocSelected) => {
    try {
      if (isEmpty(event[0])) return;
      const file = event[0];
      setState(prevState => ({ ...prevState, loadingUpload: true }));
      const { success, data }: any = await uploadService.uploadMedia({
        file,
        mediaOrigin: 'PROPOSAL_INTERACTION',
        callback: getUploadProgress,
      });
      if (success) {
        const newDocument = {
          mediaId: data?.id,
          name: data?.fileName,
          documentType: typeDocSelected || null,
        };

        await addDocument(newDocument);
        return;
      } else {
        setState(prevState => ({ ...prevState, loadingUpload: false }));
      }
    } catch {
      setState(prevState => ({ ...prevState, loadingUpload: false }));
      console.log('>>>e');
    }
  };

  const handleOpenDialogUpload = () => {
    setState(prevState => ({ ...prevState, isOpenDialogUpload: true }));
  };

  const downloadOrViewMedia = async (docData, openInNewTab = false) => {
    if (openInNewTab) {
      const url = getMediaUrl({
        mediaId: docData.mediaId,
        mediaVcode: docData?.mediaVcode,
      });
      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${docData.name}`);
      window.open(link, '_blank');
      return;
    }
    const media = await getMediaRequest({
      mediaId: docData.mediaId,
      mediaVcode: docData?.mediaVcode,
    });
    const blob = new Blob([media.data]);
    const url = window.URL.createObjectURL(blob);
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${docData.name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleClickViewDocument = (docData: DocumentDTO) => {
    downloadOrViewMedia(docData, true);
  };

  const handleClickDownloadDocument = (docData: DocumentDTO) => {
    downloadOrViewMedia(docData);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const viewComponentProps = {
    isFetching: state.isFetching,
    loadingUpload: state.loadingUpload,
    isDeleting: state.isDeleting,
    documents: state.documents,
    //
    deleteDocument,
    uploadFile,
    handleClickViewDocument,
    handleClickDownloadDocument,
    //ref
    inputFileRef,
    //MODAL_CONFIRMATION
    manageConfirmationModal,
    isOpenConfirmation: state.isOpenConfirmation,
    isOpenDialogUpload: state.isOpenDialogUpload,
    handleOpenDialogUpload,
    handleCloseDialogUpload,
    OPTIONS_TYPES_DOC,
    formik,
  };
  return <DocumentsHistoryComponents {...viewComponentProps} />;
};

export default DocumentsHistory;
