import * as React from 'react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Chip from 'components/atoms/Chip';

const statusTypeRef = {
  APPROVED: { label: 'Consentido', color: 'high' },
  REPROVED: { label: 'Não Consentido', color: 'low' },
  CANCELED: { label: 'Cancelado', color: 'default' },
  WAITING: { label: 'Aguardando', color: 'medium' },
};

interface Column {
  id: string;
  label: string;
  minWidth?: string | number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'status', label: 'Status', minWidth: 40 },
  { id: 'sentTo', label: 'Enviado para', minWidth: 40 },
  { id: 'createData', label: 'Enviado em', minWidth: '100%' },
];

const TableDigitalProposal = ({ rows }) => {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  return (
    <Paper style={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer style={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: '14px' }}>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                if (index >= 3) {
                  return;
                }
                return (
                  <TableRow hover role="checkbox" tabIndex={0} key={row.id}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Box component="div" display="flex">
                            {column.id === 'status' && (
                              <>
                                <Chip type={statusTypeRef[row.status].color}>
                                  {statusTypeRef[row.status].label}
                                </Chip>
                              </>
                            )}

                            {column.id === 'sentTo' && (
                              <>
                                <Box
                                  component="div"
                                  display="flex"
                                  width="100%"
                                  justifyContent="space-between"
                                >
                                  {row?.phone || row?.email || ''}
                                </Box>
                              </>
                            )}

                            {column.id === 'createData' && (
                              <Box
                                component="div"
                                display="flex"
                                width="100%"
                                justifyContent="space-between"
                              >
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                                {moment(row.createdAt).format('L')} -{' '}
                                {moment(row.createdAt).format('LT')}
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableDigitalProposal;
