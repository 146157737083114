import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { BankAccount, PaymentMethod } from 'modules/financial/types';
import { ContactCardInfosDTO } from 'modules/financial/pages/components/CreateClient/components/ClientCardOption/types';
import { Select } from 'types/SelectType';

interface State {
  dealer: any;
  paymentMethods: PaymentMethod[];
  bankAccounts: BankAccount[];
  contacts: ContactCardInfosDTO[];
  sellerIdsOptions: Select[];
}

const INTIAL_STATE: State = {
  dealer: {},
  paymentMethods: [],
  bankAccounts: [],
  contacts: [],
  sellerIdsOptions: [],
};

const tempDealerReduccer = (
  state = INTIAL_STATE,
  { type, payload }: Action
) => {
  switch (type) {
    case ActionTypes.SAVE_BANKACCOUNT:
      return { ...state, bankAccounts: payload.bankAccounts };
    case ActionTypes.SAVE_PAYMENT_METHOD:
      return { ...state, paymentMethods: payload.paymentMethodsOptions };
    case ActionTypes.SAVE_DEALER_INFOS:
      return {
        ...state,
        dealer: payload.dealer,
      };
    case ActionTypes.SAVE_CONTACTS:
      return {
        ...state,
        contacts: payload.contacts,
      };
    case ActionTypes.SAVE_SELLERS_IDS:
      return {
        ...state,
        sellerIdsOptions: payload.sellersIds,
      };
    default:
      return state;
  }
};

export default tempDealerReduccer;
