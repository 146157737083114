/* eslint-disable react/display-name */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import searchServices from 'services/searchServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ChooseModel0KmComponent from './ChooseModel0KmComponent';

const buildOptions = array => {
  return array.map(el => ({ value: el.id, label: el.name, ...el }));
};

const ChooseModel0KmContainer = ({ selectedModel, handleClickItem }) => {
  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  const [state, setState] = useState<{
    query: string;
    makesOptions: [{ label: string; value: number | string }] | [];
    modelsOptions: [{ label: string; value: number | string }] | [];
  }>({ query: '', makesOptions: [], modelsOptions: [] });

  const getModelsByMake = useCallback(async () => {
    try {
      if (!state.makesOptions.find(el => el.value === currentDealer.makeId)) {
        return;
      }
      const response: any = await searchServices.getModels(
        'CAR',
        state.makesOptions.find(el => el.value === currentDealer.makeId)?.label,
        true
      );
      if (response.success) {
        setState(prevState => ({
          ...prevState,
          modelsOptions: buildOptions(response.data),
        }));
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Modelos".'
        )
      );
    }
  }, [currentDealer.makeId, dispatch, state.makesOptions]);

  const getMakes = useCallback(async () => {
    try {
      const response: any = await searchServices.getMakes('CAR', true);
      if (response.success) {
        const makeOptions = buildOptions(response.data);
        setState(prevState => ({ ...prevState, makesOptions: makeOptions }));
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro na consulta de "Marcas".'
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getMakes();
  }, []);

  useEffect(() => {
    if (state.makesOptions.length) {
      getModelsByMake();
    }
  }, [state.makesOptions]);

  const headCells = [
    {
      field: 'modelName',
      headerName: 'Modelo',
      flex: 0.9,
      renderHeader: params => <div>{params.colDef.headerName}</div>,
      renderCell: params => (
        <Box component="span" fontSize="16px" color="#212121">
          {params.value}
        </Box>
      ),
      headerClassName: 'table-header',
      cellClassName: 'table-body',
    },
    {
      field: 'checked',
      headerName: ' ',
      flex: 0.1,
      renderHeader: params => <div>{params.colDef.headerName}</div>,
      renderCell: params => (
        <Box
          component="div"
          display="flex"
          width="100%"
          justifyContent="center"
        >
          <RadioButton
            onClick={() => {
              const makeElement = state.makesOptions.find(
                el => el.value === currentDealer?.makeId
              );
              const modelElement = state.modelsOptions.find(
                el => el.value === params.value
              );
              handleClickItem({
                make: { id: currentDealer?.makeId, name: makeElement?.label },
                model: { id: modelElement?.value, name: modelElement?.label },
              });
            }}
            checked={params.value === selectedModel?.model?.id}
          />
        </Box>
      ),
      headerClassName: 'table-header',
      cellClassName: 'table-body',
    },
  ];

  const makeTableRows = (array): [] =>
    array
      .filter(model =>
        model.label.toLowerCase().includes(state.query.toLowerCase())
      )
      .map(model => ({
        id: model.value,
        modelName: model.label,
        checked: model.value,
      }));

  const handleChangeQuery = value => {
    setState(prevState => ({ ...prevState, query: value }));
  };

  const viewComponentProps = {
    modelsOptions: state.modelsOptions,
    query: state.query,
    handleChangeQuery,
    headCells,
    makeTableRows,
  };
  return <ChooseModel0KmComponent {...viewComponentProps} />;
};

export default ChooseModel0KmContainer;
