import React, { useEffect, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import routesPath from 'config/routesPath';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import OrderStepperActions from 'modules/order/components/atoms/OrderStepperActions';
import OrderStepsComponent from './OrderStepsComponent';
import Checkout from './Checkout';
import ProposalStep from './Proposal';
import ChatShowroom from 'pages/chats/ChatShowroom';
import ChatOrderShowroom from 'pages/chats/ChatOrderShowroom';
import { ChatProvider, useChatContext } from 'pages/chats/ChatsContext';
import checkStatusToBlockChangesInOrder from 'modules/order/helpers/checkStatusToBlockChangesInOrder';
import QueueStep from './QueueStep';
import { LoadingContent } from './styles';

const OrderStepsContainer = props => {
  const { currentProposal }: any = useChatContext();

  const dispatch = useDispatch();

  const matchResult: any = useMemo(
    () =>
      matchPath(
        window.location.pathname,
        routesPath.getRecursivePath(routesPath.proposalOrderById)
      ),
    [window.location.pathname]
  );

  const { currentDealer } = useSelector(state => state.dealerStore);
  const proposalStore = useSelector(({ proposalStore }) => proposalStore);
  const orderStore = useSelector(({ order }) => order);

  useEffect(() => {
    if (props?.onModal) {
      dispatch(
        ActionCreators.setDealerIdAndProposalId({
          ...proposalStore.currentProposal,
        })
      );
      return;
    }
    dispatch(
      ActionCreators.setDealerIdAndProposalId({ ...matchResult.params })
    );
    return () => dispatch(ActionCreators.resetOrderStore());
  }, []);

  useEffect(() => {
    if (orderStore.orderStatus) {
      dispatch(
        ActionCreators.setCheckoutOrder(
          checkStatusToBlockChangesInOrder(orderStore?.orderStatus)
        )
      );
    }
  }, [orderStore.orderStatus]);

  useEffect(() => {
    if (
      currentProposal?.generalStep &&
      ['PROPOSAL_ORDER_OEM'].includes(currentProposal?.generalStep)
    ) {
      dispatch(ActionCreators.getQueueById());
      return;
    }
    if (
      currentProposal?.generalStep &&
      !['ATTENDANCE'].includes(currentProposal?.generalStep)
    ) {
      setTimeout(() => {
        dispatch(ActionCreators.getOrderById());
      }, 500);
    }
  }, [currentProposal?.generalStep]);

  function getCurrentStepContent(step) {
    if (
      currentDealer.products.some(
        product => product.type === 'PROPOSAL_ORDER_OEM'
      )
    ) {
      switch (step) {
        case 0:
          return <ChatOrderShowroom />;
        case 1:
          return (
            <QueueStep handleOnClickCloseModalProposal={props.onCloseModal} />
          );
        case 2:
          return <ProposalStep />;
        case 3:
          return (
            <>
              <Checkout />
              <OrderStepperActions />
            </>
          );
        default:
          return <ChatOrderShowroom />;
      }
    }
    switch (step) {
      case 0:
        return <ChatOrderShowroom />;
      case 1:
        return <ProposalStep />;
      case 2:
        return (
          <>
            <Checkout />
            <OrderStepperActions />
          </>
        );
      default:
        return <ChatOrderShowroom />;
    }
  }

  function LoadingComponent() {
    return (
      <LoadingContent>
        <CircularProgress />
        <h4>Carregando dados do pedido...</h4>
      </LoadingContent>
    );
  }

  return (
    <>
      {props.hasProductProposalApplication ? (
        <OrderStepsComponent
          currentStepComponent={
            props.loadingOrder
              ? LoadingComponent()
              : getCurrentStepContent(props.currentStep)
          }
          handleOnClickClose={props.onCloseModal}
          onModal={props?.onModal}
        />
      ) : (
        <ChatShowroom
          onModal={props?.onModal}
          handleOnClickClose={props.onCloseModal}
        />
      )}
    </>
  );
};

const OrderStepsMain = props => {
  return (
    <ChatProvider onModal={props?.onModal}>
      <OrderStepsContainer {...props} />
    </ChatProvider>
  );
};

export default memo(OrderStepsMain);
