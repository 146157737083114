import { CircularProgress } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import styled, { css } from 'styled-components';

export const RatingIcon = styled(StarIcon)`
  height: 20px;
  width: 20px;
  ${props =>
    props.full
      ? css`
          color: #ffb547;
        `
      : css`
          color: #dedede;
        `}
`;

export const RatingContent = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-size: 14px;
    color: #757575;
    height: 27px;
  }
  ${props =>
    props.loading &&
    css`
      opacity: 0.5;
    `}
`;
export const MiniLoading = styled(CircularProgress)`
  margin-left: 8px;
  color: #424242 !important;
`;
