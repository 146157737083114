import { all, put, select, takeLatest } from 'redux-saga/effects';
import { createEvaluation } from 'modules/evaluation/services/evaluationService';
import { Action } from 'types/ActionTypes';
import ActionCreators from './reducers/actionCreators';
import ActionTypes from './reducers/actionTypes';
import InventoryService from 'services/inventoryServices';
import DealFormActions from 'modules/resale/store/reducers/dealForm/actionCreators';
import { Select } from 'types/SelectType';

function* saveEvaluation(action: Action) {
  const deal = yield select(state => state.evaluation);
  try {
    const response = yield createEvaluation(action.payload.dealerId, deal);
    if (response.success) {
      yield put(ActionCreators.cleanDeal());
      action.payload.toEvaluationList();
    } else {
      console.log(response);
    }
  } catch (e) {
    console.log(e);
  }
}
function getFeatures(features: Select[]): (number | undefined)[] {
  return features.map((feature: Select): number | undefined => feature.id);
}

function* getFuel(action: Action) {
  const fuels = yield select(state => state.dealForm.fuels);

  if (action.payload.fuel2Id || action.payload.fuel2Name) {
    const element = fuels.find((fuel: Select) => fuel.name === 'Flex');
    yield put(ActionCreators.setFuel(element?.id || 0));
  } else {
    const element = fuels.find(
      (fuel: Select) => fuel.name === action.payload.fuelId
    );

    yield put(ActionCreators.setFuel(element?.id || 0));
  }
}

function* getVehicleInfoByPlate(action: Action) {
  const vehicleType = yield select(state => state.orderDeal.vehicleType);
  try {
    yield put(ActionCreators.searchingPlate());
    const response = yield InventoryService.getVehicleByPlate(
      action.payload.dealerId,
      action.payload.plate
    );
    if (response.success) {
      const { modelYear, productionYear, trim, deal0km, vin } = response.data;

      const deal = {
        modelYear,
        productionYear,
        deal0km,
        trimId: trim.id,
        trimName: trim.name,
        featureIds: getFeatures(trim?.features || []),
        plate: action.payload.plate,
        vin,
        ...trim,
      };

      yield put(ActionCreators.setVehicleInfosByPlate(deal));
      yield put(DealFormActions.getJustMakes(deal?.vehicleType || vehicleType));
      yield put(
        DealFormActions.getJustModels(
          deal?.vehicleType || vehicleType,
          deal.makeName
        )
      );
      yield put(
        DealFormActions.getJustProdutionYearsAndModelYears(
          deal?.vehicleType || vehicleType,
          deal.modelId,
          deal.productionYear
        )
      );
      yield put(
        DealFormActions.getJustTrims(
          deal?.vehicleType || vehicleType,
          deal.modelId,
          deal.modelYear
        )
      );
      yield put(ActionCreators.searchTransmissions(trim));
      yield put(ActionCreators.searchFuels(trim));
      yield put(
        DealFormActions.getFipePrice(deal.trimId, deal.modelYear, deal.deal0km)
      );
    } else {
      yield put(ActionCreators.searchingPlateError(response.problem));
    }
  } catch (e) {
    yield put(ActionCreators.searchingPlateError(e));
    console.log(e);
  }
  finally{
    yield put(ActionCreators.searchedPlate());
  }
}

export default all([
  takeLatest(ActionTypes.CREATE_EVALUATION, saveEvaluation),
  takeLatest(ActionTypes.GET_VEHICLE_INFO_BY_PLATE, getVehicleInfoByPlate),
  takeLatest(ActionTypes.GET_FUELS, getFuel),
]);
