import extractDomain from './extractDomain';
import extractPathName from './extractPathName';
import requireAuthentication from './requireAuthentication';
export default function shouldEmbedTokenForURL(URL) {
  const domain = extractDomain(URL);
  const pathname = extractPathName(URL);

  return (
    (domain.endsWith('mobiauto.com.br') || domain.endsWith('mobiauto.mobi')) &&
    requireAuthentication(pathname)
  );
}
