import styled from 'styled-components';

export const FlexWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  align-items: center;
`;

export const CardContainer = styled.div`
  border: 1px solid;
  padding: 0px 16px;
  border-radius: 4px;
  background: #fffec8;
  border-color: #e3e3e3;
  margin: 8px;
  flex: 1;
`;

export const Label = styled.span`
  font-family: 'Poppins Medium';
  font-weight: bold;
  margin-right: 8px;
`;
