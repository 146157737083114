import ActionTypes from './actionTypes';
// import { Action } from 'types/ActionTypes'

export default {
  //SET CURRENT GROUP
  setCurrentDealerGroup: function(dealerGroup: object) {
    return { type: ActionTypes.SET_CURRENT_GROUP, payload: dealerGroup };
  },

  //ALL PROPOSALS BY GROUPS
  getAllProposalsbyGroup: function(otherGroupId) {
    return {
      type: ActionTypes.GET_ALL_PROPOSALS_BY_GROUP,
      payload: { otherGroupId },
    };
  },

  setAllProposalsbyGroupLoading: function(isLoading: boolean) {
    return {
      type: ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_LOADING,
      payload: isLoading,
    };
  },

  setAllProposalsbyGroup: function(proposals: any) {
    return {
      type: ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_SUCCESS,
      payload: proposals,
    };
  },

  setAllProposalsbyGroupError: function(error: any) {
    return {
      type: ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_ERROR,
      payload: error,
    };
  },

  updateProposalById: newProposalData => {
    return {
      type: ActionTypes.UPDATE_PROPOSAL_BY_ID,
      payload: newProposalData,
    };
  },

  managementFilters: filters => {
    return { type: ActionTypes.MANAGEMENT_FILTER, payload: filters };
  },

  setCurrentUser: user => {
    return {
      type: ActionTypes.SET_CURRENT_USER,
      payload: user,
    };
  },

  // CLEAN_STORE
  cleanStore: () => {
    return {
      type: ActionTypes.CLEAN_STORE,
    };
  },
};
