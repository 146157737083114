import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import formatAmount from 'helpers/formatAmount';
import validateDealerHasProducts from 'helpers/validateDealerHasProducts';
import OrderServices from 'modules/order/services/OrderServices';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import {
  InstallmentsTypeEnum,
  OrderPayment,
  OrderPaymentType,
  TypeOfSale,
} from 'modules/order/types/OrderPayment';
import { FandiData } from 'modules/order/services/dto';
import { STOCK_KIND_TRANSLATE_ID } from 'modules/store-inventory/helpers/TranslateAggregations';
import TextField from 'modules/financing/components/atoms/TextField';
import CurrencyTextField, {
  ChangeCurrencyTextField,
} from 'modules/financing/components/atoms/CurrencyTextField';
import {
  ActionsContent,
  MiniLoading,
  NextPanelButton,
  PreviousPanelButton,
} from 'modules/order/pages/OrderSteps/Proposal/steps/ProposalData/styles';

import ChangeSaleTypeConfirmDialog from './ChangeSaleTypeConfirmDialog';
import PaymentTotalValues from './PaymentTotalValues';
import OrderProducts from '../OrderProducts';
import PaymentList from './PaymentList';

import {
  PaymentContainer,
  PaymentContent,
  PercentageCommissionContent,
  SaleDataContent,
  SaleTypeRadioGroup,
  SubTitle,
  useStyles,
} from './styles';
import { ClientData } from 'modules/financing/interfaces';

function PaymentDataComponent({ handlePreviousPanel, handleNextPanel }) {
  const {
    proposalId,
    dealerId,
    paymentData: {
      typeOfSale,
      salePrice,
      saleDate,
      manufactorBonusValue,
      manufactorDiscountValue,
      validity,
      payments,
      products,
      totalRemaining,
      totalInserted,
      percentageCommissionVDVI,
    },
    currentVehicle,
    isFinished,
  } = useSelector(state => state.order);

  const { currentDealer } = useSelector(state => state.dealerStore);

  const [installmentsTypes, setinstallmentsTypes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState<OrderPaymentType[]>([]);
  const [
    openChangeSaleTypeConfirmDialog,
    setOpenChangeSaleTypeConfirmDialog,
  ] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const dispatch = useDispatch();

  const today = moment().format('YYYY-MM-DD');

  const enablePercentageCommissionVDVI = [
    STOCK_KIND_TRANSLATE_ID[2].id,
    STOCK_KIND_TRANSLATE_ID[2].value,
    'DIRECT_SALE',
  ].includes(currentVehicle?.stockKind);

  // const parsePayment = (payment: OrderPayment): OrderPayment => {
  //   const usedVehicle: any = payment.usedVehicle ? {id: payment.usedVehicle.id} : undefined
  //   return {...payment, usedVehicle}
  // }

  const savePaymentData = async () => {
    if (isFinished) {
      handleNextPanel();
      return;
    }
    const hasBonusAndDiscountManufactorProps = currentDealer.hasBonusAndDiscountManufactor
      ? { manufactorBonusValue, manufactorDiscountValue }
      : {};
    const paymentData = {
      typeOfSale,
      salePrice,
      saleDate,
      payments,
      products,
      percentageCommissionVDVI,
      ...hasBonusAndDiscountManufactorProps,
    };

    if (!enablePercentageCommissionVDVI) {
      delete paymentData?.percentageCommissionVDVI;
    }

    setLoadingSave(true);
    const { success, data }: any = await OrderServices.savePaymentData(
      dealerId,
      proposalId,
      paymentData
    );
    if (success) handleNextPanel();
    else {
      console.error(data);
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          data || 'Não foi possível salvar o pagamento na proposta!'
        )
      );
    }
    setLoadingSave(false);
  };

  async function handleGetFandiData() {
    try {
      const response: any = await OrderServices.getFinancedFandiData({
        dealerId,
        proposalId,
      });

      if (!response?.success) {
        return;
      }

      const hasData = Object.values(response.data);

      if (hasData) {
        const data = response.data as FandiData;

        const dueDate = data.dataPrimeiroVencimento;
        const orderDate = data.dataContrato;
        const dueDays = moment(dueDate).diff(moment(orderDate), 'days');

        const clientData: Partial<ClientData> = {
          cpf: Number(data?.cpfCNPJ) || null,
          name: data?.nome || '',
          birthDate: data?.dataNascimento ?? '',
          zipCode: String(data?.cep) ?? '',
          complement: data?.complemento ?? '',
          number: String(data?.numero) ?? '',
          neighborhood: data?.bairro ?? '',
          phone: data?.celular ?? '',
          email: data?.email ?? '',
        };

        dispatch(ActionCreators.setCurrentBuyer(clientData));

        const orderPayment: OrderPayment = {
          position: 1,
          paymentType: {
            id: 554,
            name: 'Financiamento interno',
            installmentsType: InstallmentsTypeEnum.FINANCIAMENTO,
            active: true,
            dueDays,
            unique: true,
            startInternalFinancing: true,
          },
          value: Number(data?.valorFinanciado) ?? 0,
          date: moment(orderDate).format('DD/MM/YYYY'),
          dueDate,
          comments: '',
          financingTerm: '',
          financingInstallments: 0,
        };

        dispatch(
          ActionCreators.setPaymentData({
            payments: [...payments, orderPayment],
          })
        );
      }
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Não foi possível consultar os meios de pagamento'
        )
      );
    }
  }

  const handleChangeTypeOfSale = async (_, value: TypeOfSale) => {
    const isFinanced = value === TypeOfSale.FINANCED;
    const hasFandiIntegration = currentDealer.externalDealerIdentification.find(
      externalDealer => externalDealer.externalProvider === 'FANDI'
    );

    if (isFinanced && hasFandiIntegration) {
      await handleGetFandiData();
    }

    if (payments?.length >= 1) return setOpenChangeSaleTypeConfirmDialog(true);

    return dispatch(ActionCreators.setPaymentData({ typeOfSale: value }));
  };

  const handleChangePercentageCommissionVDVI = event => {
    dispatch(
      ActionCreators.setPaymentData({
        percentageCommissionVDVI: event.floatValue,
      })
    );
  };

  const handleOnCloseChangeSaleTypeConfirmDialog = isConfirmed => {
    if (isConfirmed) {
      const newTypeOfSale =
        typeOfSale === TypeOfSale.FINANCED
          ? TypeOfSale.IN_CASH
          : TypeOfSale.FINANCED;
      dispatch(ActionCreators.setPaymentData({ typeOfSale: newTypeOfSale }));
      dispatch(ActionCreators.setPaymentData({ payments: [], products: [] }));
    }

    setOpenChangeSaleTypeConfirmDialog(false);
  };

  const handleChangeSaleValue = (event: ChangeCurrencyTextField) => {
    dispatch(ActionCreators.setPaymentData({ salePrice: event.floatValue }));
  };

  const handleChangeManufactorDiscountValue = (
    event: ChangeCurrencyTextField
  ) => {
    dispatch(
      ActionCreators.setPaymentData({
        manufactorDiscountValue: event.floatValue,
      })
    );
  };

  const handleChangeManufactorBonusValue = (event: ChangeCurrencyTextField) => {
    dispatch(
      ActionCreators.setPaymentData({
        manufactorBonusValue: event.floatValue,
      })
    );
  };

  const handleChangeSaleDate = event => {
    dispatch(ActionCreators.setPaymentData({ saleDate: event.target?.value }));
  };

  const getInstallmentsTypes = async () => {
    try {
      const { data } = await OrderServices.getInstallmentsTypes(
        dealerId,
        typeOfSale
      );
      setinstallmentsTypes(data);
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Não foi possível consultar os meios de pagamento'
        )
      );
    }
  };

  const getPaymentTypes = async () => {
    try {
      const response: any = await OrderServices.getPaymentTypes(
        dealerId,
        typeOfSale
      );
      const paymentTypes: OrderPaymentType[] = response.data;
      setPaymentTypes(paymentTypes);
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Não foi possível consultar os tipos de pagamento'
        )
      );
    }
  };

  const getTooltipError = () => {
    if (!salePrice) {
      return 'Insira o valor de venda';
    }

    if (totalRemaining > 0) {
      return `Defina o meio de pagamento para valor de ${formatAmount(
        totalRemaining,
        'PT-BR',
        'R$'
      )} restante`;
    }

    if (totalRemaining < 0) {
      return `O pagamento excedeu em ${formatAmount(
        Math.abs(totalRemaining),
        'PT-BR',
        'R$'
      )} o preço de venda`;
    }

    return '';
  };

  const isPaymentValid = () => {
    return (
      formatAmount(totalRemaining, 'PT-BR', 'R$') === 'R$ 0,00' && salePrice
    );
  };

  useEffect(() => {
    if (typeOfSale) {
      getInstallmentsTypes();
      getPaymentTypes();
    }
  }, [typeOfSale]);

  useEffect(() => {
    const totalRemaining =
      (salePrice ?? 0) -
      (totalInserted ?? 0) -
      (manufactorDiscountValue ?? 0) -
      (manufactorBonusValue ?? 0);

    dispatch(
      ActionCreators.setPaymentData({
        totalRemaining,
      })
    );
  }, [salePrice, totalInserted, manufactorDiscountValue, manufactorBonusValue]);

  const classes = useStyles();

  return (
    <PaymentContainer>
      <PaymentContent>
        {typeOfSale &&
          validateDealerHasProducts(currentDealer.products, ['FINANCING']) && (
            <>
              <SubTitle>Selecione o tipo de venda</SubTitle>
              <SaleTypeRadioGroup
                value={typeOfSale}
                onChange={handleChangeTypeOfSale}
              >
                <FormControlLabel
                  value={TypeOfSale.IN_CASH}
                  control={<Radio classes={{ checked: classes.checked }} />}
                  label="À vista"
                  disabled={isFinished}
                />
                <FormControlLabel
                  value={TypeOfSale.FINANCED}
                  control={<Radio classes={{ checked: classes.checked }} />}
                  label="Financiamento interno"
                  disabled={isFinished}
                />
              </SaleTypeRadioGroup>
            </>
          )}

        <SubTitle>Informações do pagamento</SubTitle>
        <SaleDataContent>
          <CurrencyTextField
            variant="filled"
            label="Valor de venda"
            value={salePrice}
            onChangeValue={handleChangeSaleValue}
            disabled={isFinished}
            error={!salePrice}
            helperText={!salePrice ? 'Insira o valor de venda' : ''}
          />
          {currentDealer.hasBonusAndDiscountManufactor && (
            <>
              <CurrencyTextField
                variant="filled"
                label="Desconto montadora"
                value={manufactorDiscountValue}
                onChangeValue={handleChangeManufactorDiscountValue}
                disabled={isFinished}
              />
              <CurrencyTextField
                variant="filled"
                label="Bônus montadora"
                value={manufactorBonusValue}
                onChangeValue={handleChangeManufactorBonusValue}
                disabled={isFinished}
              />
            </>
          )}
          <TextField
            value={saleDate}
            onChange={handleChangeSaleDate}
            label="Data da venda"
            type="date"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: today,
            }}
          />
          <TextField
            value={validity}
            label="Data da vencimento"
            type="date"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: today,
            }}
          />
        </SaleDataContent>

        {enablePercentageCommissionVDVI && (
          <>
            <SubTitle>Informações da comissão do vendedor</SubTitle>
            <PercentageCommissionContent>
              <NumberFormat
                value={Number(percentageCommissionVDVI)}
                onValueChange={handleChangePercentageCommissionVDVI}
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                allowLeadingZeros={false}
                placeholder="0"
                suffix={'%'}
                fixedDecimalScale={true}
                customInput={TextField}
                label="Percentual da comissão"
                error={!salePrice}
                disabled={isFinished}
              />
            </PercentageCommissionContent>
          </>
        )}

        {currentDealer.shouldHaveAdditionalProductsOnProposalOrder && (
          <>
            <SubTitle>Serviços adicionais</SubTitle>
            <OrderProducts />
          </>
        )}

        <SubTitle>Informações da venda</SubTitle>
        <PaymentList
          installmentsTypes={installmentsTypes}
          paymentTypes={paymentTypes}
          isFinished={isFinished}
        />
        <PaymentTotalValues />
        {openChangeSaleTypeConfirmDialog && (
          <ChangeSaleTypeConfirmDialog
            open={openChangeSaleTypeConfirmDialog}
            onClose={handleOnCloseChangeSaleTypeConfirmDialog}
          />
        )}
      </PaymentContent>
      {!isFinished && (
        <ActionsContent>
          <PreviousPanelButton onClick={handlePreviousPanel}>
            Voltar
          </PreviousPanelButton>
          <Tooltip
            title={<span style={{ fontSize: 14 }}>{getTooltipError()}</span>}
            disableHoverListener={isPaymentValid()}
          >
            <div>
              <NextPanelButton
                disabled={!isPaymentValid()}
                onClick={savePaymentData}
              >
                Próximo
                {loadingSave && <MiniLoading size={20} />}
              </NextPanelButton>
            </div>
          </Tooltip>
        </ActionsContent>
      )}
    </PaymentContainer>
  );
}

export default PaymentDataComponent;
