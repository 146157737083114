import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import proposalServices from 'services/proposalServices';
import { useChatContext } from 'pages/chats/ChatsContext';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import RemoveVehicleComponent from './RemoveVehicleComponent';

const RemoveVehicleContainer = ({ open, handleClose, vehicle }) => {
  const dispatch = useDispatch();
  const { currentProposal, getInterestProducts } = useChatContext();

  const { currentDealer } = useSelector(state => ({
    ...state.proposalStore,
    ...state.dealerStore,
  }));

  const removeVehicle = async () => {
    try {
      const response = await proposalServices.removeInterestProducts(
        currentProposal.dealerId || currentDealer.id,
        currentProposal.id,
        vehicle.id
      );
      if (response.success) {
        getInterestProducts();
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Veículo removido com sucesso.'
          )
        );
        handleClose();
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao remover o veículo de interesse.'
        )
      );
    }
  };

  const handleClickConfirm = async () => {
    removeVehicle();
  };

  const viewComponentProps = { open, vehicle, handleClose, handleClickConfirm };

  return <RemoveVehicleComponent {...viewComponentProps} />;
};

export default RemoveVehicleContainer;
