import styled from 'styled-components';

import {
  Chip as MUIChip,
  DialogActions as MUIDialogActions,
  DialogContent as MUIDialogContent,
  DialogTitle as MUIDialogTitle,
  TextField as MUITextField,
  Tooltip as MUITooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const DialogTitle = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})(MUIDialogTitle);

export const LoadingContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
`;

export const HeaderTitle = styled('h2')`
  font-family: 'Poppins Medium';
  letter-spacing: 0.5px;
  margin: 0;
`;

export const DialogContent = withStyles({
  root: {
    width: 740,
    minHeight: 240,
    padding: '0',
    position: 'relative',
  },
})(MUIDialogContent);

export const FixedFilters = styled('div')`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
  background-color: #fff;
  z-index: 1;
`;

export const FilterContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0 1.5rem;
`;

export const FilterTitle = styled('p')`
  font-family: 'Poppins Medium';
  font-size: 1rem;
  margin: 0;
  line-height: 1;
`;

export const FilterChips = styled('div')`
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f4f6f6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd !important;
    border: none !important;
    cursor: grab;
  }
`;

export const Chip = withStyles({
  root: {
    fontFamily: "'Poppins Medium'",
  },
})(MUIChip);

export const TextField = styled(MUITextField).attrs({ size: 'small' })`
  width: 100%;

  .MuiInputBase-root {
    padding: 0.25rem 0.875rem;

    input {
      font-family: 'Poppins Regular';
      font-size: 1rem;
      color: #000;
    }
  }
`;

export const DialogActions = withStyles({
  root: {
    padding: '1.25rem 1.5rem',

    '& .MuiButtonBase-root': {
      color: '#fff',
      fontFamily: "'Poppins Medium'",
      textTransform: 'none',
    },
  },
})(MUIDialogActions);

export const ItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1.5rem;

  .MuiFormGroup-root {
    gap: 0.5rem;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #ddd;
  }
`;

export const ItemValue = styled('div')`
  font-family: 'Poppins Regular';
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Tooltip = withStyles({
  tooltip: {
    fontFamily: '"Poppins Medium"',
    fontSize: '0.75rem',
    letterSpacing: '0.5px',
  },
})(MUITooltip);

export const EmptyContainerMessage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.25rem;
  line-height: 1.5;
  text-align: center;
  padding: 1.5rem 0;

  .title {
    font-family: 'Poppins Medium';
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
  }

  .subtitle {
    font-family: 'Poppins Regular';
    font-size: 0.875rem;
    color: #516766;
    margin: 0;
  }
`;
