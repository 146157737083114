import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';

import ThemeButton from 'components/atoms/ThemeButton';

import useSnackBar from 'hooks/useSnackBar';

import { useStartInsuranceQuote } from 'modules/insureQuotes/services/queries';

import { QuoteDialog } from './QuoteDialog';

interface Props {
  buttonLabel?: string;
  iframeParams: string;
}

export function StartQuoteFlow({
  buttonLabel = 'Cotar Seguros',
  iframeParams,
}: Props) {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const { currentDealer, proposalId } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
    proposalId: state.proposalStore.currentProposal.proposalId,
  }));

  const { error } = useSnackBar();

  const handleOpenDialog = () => setDialogIsOpen(true);
  const handleCloseDialog = () => setDialogIsOpen(false);

  const {
    mutate: startInsuranceQuote,
    isLoading: loadingStartInsuranceQuote,
  } = useStartInsuranceQuote({
    onSuccess: () => handleOpenDialog(),
    onError: () => error('Ocorreu um erro ao iniciar uma "Cotação"'),
  });

  const handleStartInsuranceQuote = () => {
    const currentDealerId = currentDealer?.id;

    if (!currentDealerId || !proposalId) return;

    return startInsuranceQuote({ dealerId: currentDealerId, proposalId });
  };

  return (
    <Fragment>
      <ThemeButton onClick={() => handleStartInsuranceQuote()}>
        {loadingStartInsuranceQuote && (
          <CircularProgress color="inherit" size={24} />
        )}
        {!loadingStartInsuranceQuote && buttonLabel}
      </ThemeButton>
      <QuoteDialog
        dialogTitle={buttonLabel}
        iframeParams={iframeParams}
        isOpen={dialogIsOpen}
        onClose={() => handleCloseDialog()}
      />
    </Fragment>
  );
}
