import styled from 'styled-components';
const PageWrapper = styled.div`
  padding: ${props => (props.withoutPadding ? '0' : '15px 30px')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: ${props => props.hasOverflow && 'hidden'};
`;
const PageWrapperMobile = styled(PageWrapper)`
  padding: ${props => (props.withoutPadding ? '0' : '16px')};
  overflow-x: ${props => props.hasOverflow && 'overlay'};
`;

export { PageWrapper, PageWrapperMobile };
