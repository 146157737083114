import Request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'OptionsService';
const domain = EnvVars('API_SEARCH_BASE_URL');
const domainAdmin = EnvVars('API_ADMIN_BASE_URL');
const domainPainelDigital = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

const options = 'options';
const vehicleType = 'vehicle-type';
const apiVersion = 'v1.0';

const baseUrl = `${options}/${apiVersion}/${vehicleType}`;

export const getVehicleTypes = () => {
  return Request.get(`${domain}/${baseUrl}`).then(
    buildHTTPResponseHandler(`${serviceName}.getVehicleTypes()`)
  );
};

export const getApps = () => {
  return Request.get(`${domainAdmin}/${options}/${apiVersion}/apps`).then(
    buildHTTPResponseHandler(`${serviceName}.getApps()`)
  );
};

export const getOrderBy = () => {
  return Request.get(
    `${domainPainelDigital}/${options}/${apiVersion}/filters`
  ).then(buildHTTPResponseHandler(`${serviceName}.getOrderBy()`));
};

export const getScoreFilter = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/scorefilter`).then(
    buildHTTPResponseHandler(`${serviceName}.getScoreFilter()`)
  );
};

export const getPublishesStatus = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/publishstatus`).then(
    buildHTTPResponseHandler(`${serviceName}.getPublishesStatus()`)
  );
};

export const getSchollingTypes = () => {
  return new Promise(resolve => {
    resolve([
      { value: 'SUPERIOR_INCOMPLETO', label: 'Superior Incompleto' },
      { value: 'MEDIO_COMPLETO', label: 'Ensino Médio Completo' },
      { value: 'MEDIO', label: 'Ensino Médio Incompleto' },
      { value: 'SUPERIOR', label: 'Superior' },
      { value: 'MESTRADO', label: 'Mestrado' },
      { value: 'DOUTORADO', label: 'Doutorado' },
      { value: 'POS_GRADUADO', label: 'Pós Graduado' },
      { value: 'FUNDAMENTAL', label: 'Fundamental' },
      { value: 'ANALFABETO', label: 'Analfabeto' },
    ]);
  });
};

export const getAddressType = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/address-type`).then(
    buildHTTPResponseHandler(`${serviceName}.getAddressType()`)
  );
};

export const getCareers = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/career`).then(
    buildHTTPResponseHandler(`${serviceName}.getCareers()`)
  );
};

export const getEmployments = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/employment`).then(
    buildHTTPResponseHandler(`${serviceName}.getEmployments()`)
  );
};

export const getGenders = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/genders`).then(
    buildHTTPResponseHandler(`${serviceName}.getGenders()`)
  );
};

export const getCivilStatus = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/civilstatus`).then(
    buildHTTPResponseHandler(`${serviceName}.getCivilStatus()`)
  );
};

export const getStreetType = () => {
  return Request.get(`${domainPainelDigital}/options/v1.0/street-type`).then(
    buildHTTPResponseHandler(`${serviceName}.getStreetType()`)
  );
};
