import React from 'react';
import Select from 'modules/financing/components/atoms/Select';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CEPMaskCustom } from 'components/MaskTextInput';
import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import AutoComplete from 'components/atoms/AutoComplete'
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput'


const GuarantorAddressInfosForm = props => {
  const {
    statesOptions,
    streetTypeOptions,
    addressTypeOptions,
    citiesOptionsState,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <Select
            id="guarantorAddressType"
            name="guarantorAddressType"
            label="Tipo do endereço"
            value={formData['guarantorAddressType']}
            onChange={onChange}
            options={addressTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="guarantorZipcode"
            name="guarantorZipcode"
            label="CEP"
            value={formData['guarantorZipcode']}
            onKeyPress={handleKeyPress}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: CEPMaskCustom,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton onClick={getAddresInfosByZipCode} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={2}>
          <Select
            id="guarantorStreetType"
            name="guarantorStreetType"
            label="Tipo do logradouro"
            value={formData['guarantorStreetType']}
            onChange={onChange}
            options={streetTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            id="guarantorAddress"
            name="guarantorAddress"
            label="Endereço"
            value={formData['guarantorAddress']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorAddress')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="guarantorNumber"
            name="guarantorNumber"
            label="Número"
            value={formData['guarantorNumber']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorNumber')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={12}>
          <TextInput
            id="guarantorComplement"
            name="guarantorComplement"
            label="Complemento (opcional)"
            value={formData['guarantorComplement']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorComplement')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="guarantorNeighborhood"
            name="guarantorNeighborhood"
            label="Bairro"
            value={formData['guarantorNeighborhood']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorNeighborhood')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="guarantorCityStateId"
            label="Estado*"
            options={statesOptions}
            value={formData?.['guarantorCityStateId'] ? {
              id: formData['guarantorCityStateId'],
              value: formData['guarantorCityStateId'],
              label: statesOptions.find(e => e.value === formData['guarantorCityStateId'])?.label,
              name: statesOptions.find(e => e.value === formData['guarantorCityStateId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e?.value, name: 'guarantorCityStateId' } })}
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="guarantorCityId"
            label="Cidade*"
            options={citiesOptionsState}
            value={formData?.['guarantorCityId'] ? {
              id: formData['guarantorCityId'],
              value: formData['guarantorCityId'],
              label: citiesOptionsState.find(e => e.value === formData['guarantorCityId'])?.label,
              name: citiesOptionsState.find(e => e.value === formData['guarantorCityId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e?.value, name: 'guarantorCityId' } })}
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GuarantorAddressInfosForm;
