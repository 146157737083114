import React from 'react';
import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import getDate from './getDate';
import getInitialsName from 'helpers/getInitialsName';
import formatTime from 'helpers/formatTime';

const OvalBigger = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${props => props.backgroundColor || Colors.gray.nearWhite};
  font-family: Poppins Bold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ContainerIcon = styled.div`
  height: 32px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const ContainerMessage = styled.div`
  display: flex;
  flex-flow: ${props => props.flexDirection || 'wrap-reverse'};
  padding: ${props => props.paddingRight || '0'};
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
`;

const FooterMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SystemicMessageText = styled.div`
  min-width: 80px;
  padding: 8px 16px;
  font-family: Poppins Regular;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  text-align: right;
  max-width: 80%;
  min-width: 80px;
`;

const OvalDividerMessage = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${Colors.gray.light};
  border-radius: 2px;
`;

const DateMessage = styled.div`
  font-family: Poppins Regular;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.gray.dark};
  padding: ${props => props.paddingRight || '0 8px'};
  padding-left: 0px;
`;

const ProviderMessage = styled.div`
  font-family: Poppins Regular;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.gray.light};
  padding: ${props => props.paddingRight || '0 8px'};
`;

class SystemicMessage extends React.PureComponent {
  render() {
    const { message, sellerName } = this.props;
    const dateText = `${getDate(message.date)} ${formatTime(
      new Date(message.date)
    )}`;

    const userCapitilize = getInitialsName(message.ownerName || sellerName);

    return (
      <Container>
        <ContainerMessage>
          <SystemicMessageText>{message.message}</SystemicMessageText>
          <ContainerIcon>
            <OvalBigger style={{ margin: ' 0 16px' }}>
              {userCapitilize}
            </OvalBigger>
          </ContainerIcon>
        </ContainerMessage>
        <ContainerMessage flexDirection="row">
          <FooterMessage>
            <ProviderMessage paddingRight="0 8px">Mobiauto</ProviderMessage>
            <OvalDividerMessage style={{ marginRight: '8px' }} />

            <DateMessage>{dateText}</DateMessage>

            <ContainerIcon>
              <OvalBigger
                backgroundColor="transparent"
                style={{ margin: ' 0 16px' }}
              />
            </ContainerIcon>
          </FooterMessage>
        </ContainerMessage>
      </Container>
    );
  }
}

export default SystemicMessage;
