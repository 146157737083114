import styled from 'styled-components';
import OutlinedButton from 'components/atoms/OutlinedButton';

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const RedOutlinedButton = styled(OutlinedButton)`
  text-transform: inherit !important;
  border-color: #f44336 !important;
  color: #f44336 !important;
`;

export const WarningContainer = styled.div`
  display: flex;
  background: #fff5e5;
  border-radius: 4px;
  color: #874900;
  min-height: 57px;
  align-items: center;
  padding: 0px 18px;
`;

export const Text = styled.div`
  font-size: 14px;
  margin: 16px 0px;
  text-align: center;
`;
