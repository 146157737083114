import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionCreators from 'modules/financing/store/reducers/actionCreators';
import OrderActionCreators from 'modules/order/store/reducers/actionCreators';
import ReferencesForm from './ReferencesForm';

const ReferencesFormContainer = ({
  formData,
  onChange,
  disableAllInputs = false,
  enableCopyButton = false,
}) => {
  //REDUX_HOOKS
  const { bankAccountTypeOptions } = useSelector(
    state => state.financing
  );
  const { financialInstitutionsOptions: orderFinancialInstitutionsOptions } = useSelector(
    state => state.order
  );
  const dispatch = useDispatch();

  //GET_OPTIONS
  useEffect(() => {
    dispatch(OrderActionCreators.getAllFinancialInstitutionOptions());
    // if (dealerId) {
    // } else {
    //   dispatch(ActionCreators.getAllFinancialInstitutionOptions());
    // }
    dispatch(ActionCreators.getBankAccountTypeOptions());
  }, []);

  const viewComponentProps = {
    // financialInstitutionsOptions: dealerId ?orderFinancialInstitutionsOptions  : financingFinancialInstitutionsOptions,
    financialInstitutionsOptions: orderFinancialInstitutionsOptions,
    bankAccountTypeOptions,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  };

  return <ReferencesForm {...viewComponentProps} />;
};

export default ReferencesFormContainer;
