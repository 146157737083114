import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import animationData from 'assets/lottie-loading-write-tree-points.json';
import getInitialsName from 'helpers/getInitialsName';
import { useChatContext } from 'pages/chats/ChatsContext';
import MessageTypes from 'constants/MessageTypes';
import useGetChatMobiSmartConsultant from './hooks/useGetChatMobiSmartConsultant';
import MobiSmartConsultantComponent from './MobiSmartConsultantComponent';

const MobiSmartConsultant = () => {
  const { firstName } = useSelector(state => ({
    ...state.userStore,
  }));

  const { setTextInputState, currentProposal }: any = useChatContext();

  const [messageWriteInput, setMessageWriteInput] = useState(null);
  const [messageEnv, setMessageEnv] = useState(null);
  const [lastMessageUser, setLastMessageUser] = useState('');

  const { data, isFetching, refetch } = useGetChatMobiSmartConsultant(
    messageEnv
  );

  const buildOvalLetter = () => {
    return firstName && getInitialsName(firstName);
  };
  const ovalLetter = buildOvalLetter();

  const handleCopyMessageForWhatsAppInput = message => {
    setTextInputState(prevState => ({
      ...prevState,
      messageType: MessageTypes.TEXT,
      messageText: message,
    }));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    isClickToPauseDisabled: false,
  };

  const sendMessageWriteToMobiSmartConsultant = () => {
    if (isFetching) {
      return;
    }
    setLastMessageUser(messageWriteInput);
    setMessageEnv(messageWriteInput);
    setMessageWriteInput('');
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      const start = event.target.selectionStart;
      const end = event.target.selectionEnd;
      const newText =
        messageWriteInput.substring(0, start) +
        '\n' +
        messageWriteInput.substring(end);
      setMessageWriteInput(newText);
      event.target.selectionStart = start + 1;
      event.target.selectionEnd = start + 1;
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessageWriteToMobiSmartConsultant();
    }
  };

  const handleClickSuggestion = async text => {
    setLastMessageUser(text);
    setMessageEnv(text);
  };

  useEffect(() => {
    setMessageEnv(null);
    setLastMessageUser('');
  }, [data]);

  useEffect(() => {
    if (messageEnv?.trim()?.length) refetch();
  }, [messageEnv]);

  const viewComponentProps = {
    data,
    ovalLetter,
    lastMessageUser,
    handleClickSuggestion,
    isFetching,
    defaultOptions,
    messageWriteInput,
    setMessageWriteInput,
    handleKeyPress,
    sendMessageWriteToMobiSmartConsultant,
    messageEnv,
    handleCopyMessageForWhatsAppInput,
    currentProposal,
  };
  return <MobiSmartConsultantComponent {...viewComponentProps} />;
};

export default MobiSmartConsultant;
