import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ChangeDealer from 'components/ChangeDealer';

import DealerServices from 'services/dealerServices';
import { useChatContext } from 'pages/chats/ChatsContext';
import { highAccessUserTypes } from 'constants/userAccess';

import lazyRetry from 'helpers/lazyRetry';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';
import { usePermissions } from 'hooks/usePermissions';

import ItemHeader from '../atoms/ItemHeader';
import { Split, NameProfile, Button } from './styles';

const ChangeDepartmentDialog = React.lazy(() =>
  lazyRetry(() => import('./ChangeDepartmentDialog'))
);

const DepartmentSelect = props => {
  const [state, setState] = useState({
    departments: [],
    currentProposal: {},
    openChangeDepartmentDialog: false,
  });

  const getDepartments = useCallback(async () => {
    try {
      const { proposalStore } = props;
      const { success, data } = await DealerServices.getAllDepartamentByDealer(
        proposalStore.currentProposal?.dealerId
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          departments: [
            ...state.departments,
            ...data.map(el => ({ value: el.id, label: el.name })),
          ],
        }));
      }
    } catch (e) {
      console.log(e);
    }
  }, [props, state.departments]);

  useEffect(() => {
    if (
      props.proposalStore?.currentProposal?.dealerId &&
      state.departments.length < 2
    ) {
      getDepartments();
    }
  }, []);

  const currentDepartmentLabel = state.departments?.find(
    el => el.value === props.proposalStore?.currentProposal?.department?.id
  )?.label;

  return (
    <div>
      {state.departments.length > 1 && (
        <>
          <ItemHeader>Departamento</ItemHeader>
          <div
            style={{
              margin: 16,
            }}
          >
            <Split style={{ padding: '8px 0px' }}>
              <NameProfile>{`${currentDepartmentLabel ??
                'Sem Departamento'}`}</NameProfile>
            </Split>
            {props.enableChange && (
              <Button
                onClick={() =>
                  setState(prevState => ({
                    ...prevState,
                    openChangeDepartmentDialog: true,
                  }))
                }
              >
                Alterar Departamento
              </Button>
            )}
            {state.openChangeDepartmentDialog && (
              <ChangeDepartmentDialog
                open={state.openChangeDepartmentDialog}
                handleClose={() =>
                  setState(prevState => ({
                    ...prevState,
                    openChangeDepartmentDialog: false,
                  }))
                }
                currentProposal={props.proposalStore.currentProposal}
                departments={state.departments}
                getCurrentProposal={(dealerId, proposalId) => {
                  props.proposalStore.getCurrentProposal(dealerId, proposalId);
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const StoreInfos = props => {
  const { userType } = useSelector(state => state.userStore);
  const { currentProposal, getCurrentProposal } = useChatContext();

  const [state, setState] = useState({
    openModalChangeDealer: false,
    thisTypeUserCanChange: false,
  });

  const checkUserCanChangeSeller = () => {
    let userTypeStore = null;

    if (!checkCallCenterOsSearchAndRescueRoute()) {
      userTypeStore = userType;
    } else {
      userTypeStore = 'SALES_ASSISTENT';
    }

    setState(prevState => ({
      ...prevState,
      thisTypeUserCanChange: [
        ...highAccessUserTypes,
        'SALES_ASSISTENT',
      ].includes(userTypeStore),
    }));
  };

  const handleClickOpenModalChangeDealer = () => {
    setState(prevState => ({
      ...prevState,
      openModalChangeDealer: !state.openModalChangeDealer,
    }));
  };

  const handleClickCloseModalChangeDealer = newDealerId => {
    setState(prevState => ({
      ...prevState,
      openModalChangeDealer: false,
    }));
    getCurrentProposal(
      newDealerId || currentProposal.dealerId,
      currentProposal.id
    );
  };

  const { closedProposal } = props;
  const { hasPermission } = usePermissions();

  const canChangeStore = hasPermission('PROPOSAL_CHANGE_STORE');
  const canChangeDepartment = hasPermission('SWITCH_DEPARTMENT_LEAD');

  const enableChangeStore = checkCallCenterOsSearchAndRescueRoute()
    ? state.thisTypeUserCanChange
    : !closedProposal && canChangeStore;

  const enableChangeDepartment = checkCallCenterOsSearchAndRescueRoute()
    ? state.thisTypeUserCanChange
    : !closedProposal && canChangeDepartment;

  useEffect(() => {
    checkUserCanChangeSeller();
  }, []);

  return (
    <>
      <ItemHeader>Loja</ItemHeader>
      <div style={{ padding: 16 }}>
        {currentProposal.dealerName && (
          <Split style={{ padding: '8px 0px' }}>
            <NameProfile>{currentProposal.dealerName}</NameProfile>
          </Split>
        )}
        {enableChangeStore && (
          <Button onClick={handleClickOpenModalChangeDealer}>
            Alterar Loja
          </Button>
        )}
      </div>
      <DepartmentSelect
        enableChange={enableChangeDepartment}
        proposalStore={{
          currentProposal,
          getCurrentProposal,
        }}
      />
      {state.openModalChangeDealer && (
        <ChangeDealer
          open={state.openModalChangeDealer}
          handleClose={handleClickCloseModalChangeDealer}
        />
      )}
    </>
  );
};

export default StoreInfos;
