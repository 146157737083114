import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';

import Chip from 'components/atoms/Chip';

import { useChatContext } from 'pages/chats/ChatsContext';
import { highAccessUserTypes } from 'constants/userAccess';
import lazyRetry from 'helpers/lazyRetry';
import isEmpty from 'helpers/isEmpty';
import { returnHoursOrMin } from 'helpers/returnHoursOrMin';
import { returnDateHourFormatedString } from 'helpers/returnDateHourFormatedString';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';
import { usePermissions } from 'hooks/usePermissions';

import CardSellerProfileAndLeadInfosComponent from '../../CardSellerProfileAndLeadInfos';

import { Split, Button } from './styles';

const ChangeSellerByDialog = React.lazy(() =>
  lazyRetry(() => import('./ChangeSellerByDialog'))
);

const SellerProfile = ({ closedProposal }) => {
  const { userType } = useSelector(state => state.userStore);
  const { currentProposal, getCurrentProposal } = useChatContext();

  const [state, setState] = useState({
    openModalChangeSeller: false,
    thisTypeUserCanChange: false,
  });

  const checkUserCanChangeSeller = () => {
    let userTypeStore = null;

    if (!checkCallCenterOsSearchAndRescueRoute()) {
      userTypeStore = userType;
    } else {
      userTypeStore = 'SALES_ASSISTENT';
    }

    setState(prevState => ({
      ...prevState,
      thisTypeUserCanChange: [
        ...highAccessUserTypes,
        'SALES_ASSISTENT',
      ].includes(userTypeStore),
    }));
  };

  const handleClickOpenModalChangeSeller = () => {
    setState(prevState => ({
      ...prevState,
      openModalChangeSeller: !state.openModalChangeSeller,
    }));
  };

  const handleClickCloseModalChangeSeller = () => {
    setState(prevState => ({ ...prevState, openModalChangeSeller: false }));
    getCurrentProposal(currentProposal.dealerId, currentProposal.id);
  };

  useEffect(() => {
    checkUserCanChangeSeller();
  }, []);

  const { hasPermission } = usePermissions();
  const canChangeAgent = hasPermission('PROPOSAL_CHANGE_AGENT');

  if (isEmpty(currentProposal)) return null;

  const hasNoSeller = isEmpty(currentProposal.sellerName);
  const buttonLabel = hasNoSeller ? 'Adicionar Vendedor' : 'Alterar Vendedor';

  const enableChange = checkCallCenterOsSearchAndRescueRoute()
    ? state.thisTypeUserCanChange
    : !closedProposal && (currentProposal?.sellerName ? canChangeAgent : true);

  return (
    <>
      <div style={{ padding: '8px' }}>
        {currentProposal.status === 'FINISHED_BUT_NOT_CONCLUDED' && (
          <Grid container direction="column">
            <Box
              component="div"
              border="1px solid #e5e5e5"
              padding="8px"
              borderRadius="4px"
            >
              <Chip type="low">Venda não Realizada</Chip>
              <Box component="span" fontWeight="bold" fontSize="14px" ml="16px">
                Motivo:
              </Box>{' '}
              {currentProposal.statusDetailName}
            </Box>
          </Grid>
        )}
        <Grid container direction="column">
          {hasNoSeller ? (
            <span> Nenhum vendedor vinculado</span>
          ) : (
            <Grid container direction="column">
              <Split>
                <CardSellerProfileAndLeadInfosComponent
                  salesName={currentProposal.sellerName}
                  occupation="Vendedor"
                  leadCreatedAt={returnDateHourFormatedString(
                    currentProposal.sellerAssignedAt
                  )}
                  interactionDate={
                    currentProposal?.lastMessage?.ownerName ===
                    currentProposal?.sellerName
                      ? returnDateHourFormatedString(
                          currentProposal?.lastMessage?.date
                        )
                      : ''
                  }
                  enableChangeSeller={enableChange}
                  serviceTime={
                    currentProposal?.sellerTma
                      ? returnHoursOrMin(currentProposal?.sellerTma)
                      : ''
                  }
                  onClick={handleClickOpenModalChangeSeller}
                />
              </Split>
            </Grid>
          )}

          {!currentProposal.sellerName && enableChange && (
            <Button onClick={handleClickOpenModalChangeSeller}>
              {buttonLabel}
            </Button>
          )}
        </Grid>
      </div>
      {state.openModalChangeSeller && (
        <ChangeSellerByDialog
          open={state.openModalChangeSeller}
          handleClose={handleClickCloseModalChangeSeller}
        />
      )}
    </>
  );
};

export default SellerProfile;
