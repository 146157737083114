import request from 'helpers/request';

/* 
    defines a custom request for each module
    import at @MODULE/services/index
*/

const getRestActions = (moduleBaseUrl: string) => {
  const moduleBaseUrl_ = moduleBaseUrl;
  return {
    moduleBaseUrl: moduleBaseUrl_,
    get: (endpoint: string, query?: any, totalUrl?: string, isBlob?: boolean) => (
      request.get(
        totalUrl || `${moduleBaseUrl_}/${endpoint}`,
        query, isBlob ? { responseType: 'blob' } : null
      )
    ),
    post: (endpoint, data: any, totalUrl?: string) => (
      request.post(
        totalUrl || `${moduleBaseUrl_}/${endpoint}`,
        data
      )
    ),
    put: (endpoint, data: any, totalUrl?: string) => (
      request.put(
        totalUrl || `${moduleBaseUrl_}/${endpoint}`,
        data
      )
    ),
    patch: (endpoint, data: any, totalUrl?: string) => (
      request.put(
        totalUrl || `${moduleBaseUrl_}/${endpoint}`,
        data
      )
    ),
    delete: (endpoint, params?: any, totalUrl?: string) => (
      request.delete(
        totalUrl || `${moduleBaseUrl_}/${endpoint}`,
        params
      )
    ),
  }
};

export default getRestActions;