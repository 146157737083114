import React, { useEffect } from 'react';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import { ReactComponent as EmptyTemplateMessages } from 'assets/empty-messages-template.svg';
import proposalIsFinished from 'pages/chats/helpers/proposalIsFinished';
import isEmpty from 'helpers/isEmpty';
import { useChatContext } from 'pages/chats/ChatsContext';
import MessageTypes from 'constants/MessageTypes';
import useGetSmartChatSuggestions from './hooks/useGetSmartChatSuggestions';
import usePostSuggestion from './hooks/usePostSuggestion';
import ViewTextComponent from './ViewTextComponent';
import {
  Container,
  Wrapper,
  WrapperBg,
  Bg,
  EmptyContainer,
  EmptyIcon,
} from './styles';
import useMobiIAProducts from 'hooks/useMobiIAProducts';

const ViewTextContainer = ({
  currentChannel,
  canSendWhatsAppBusinessMessage = true,
}) => {
  const {
    currentProposal,
    interactions,
    otherStates: { chatIsLoading },
    addInteraction,
  } = useChatContext();

  const { hasMobiGpt } = useMobiIAProducts();
  const { mutateAsync: postSuggestion } = usePostSuggestion();
  const {
    data: messagesMobiSmartChat,
    refetch: getSuggestions,
    isRefetching: isRefetchingSuggestions,
    isLoading: isLoadingSuggestions,
  } = useGetSmartChatSuggestions();

  const loadingSuggestions = isRefetchingSuggestions || isLoadingSuggestions;

  const proposalIsOpen = !proposalIsFinished(currentProposal);
  const customerSentLastMessage = !interactions[interactions.length - 1]
    ?.fromPJ;

  const handleGetSuggestions = () => {
    const hasProposal = !isEmpty(currentProposal);
    if (
      hasMobiGpt &&
      hasProposal &&
      proposalIsOpen &&
      customerSentLastMessage
    ) {
      getSuggestions();
    }
  };

  const displaySuggestions = () => {
    const hasSuggestions = messagesMobiSmartChat?.suggestion;
    const proposalIsNotArchived = !currentProposal?.archived;
    return (
      hasSuggestions &&
      proposalIsOpen &&
      proposalIsNotArchived &&
      customerSentLastMessage &&
      !loadingSuggestions
    );
  };

  const handleClickSuggestion = async (text, callback) => {
    const params = {
      id: messagesMobiSmartChat?.id,
      textSuggestion: text,
    };
    const success = await addInteraction(MessageTypes.TEXT, {
      channel: currentChannel,
      message: text,
      premessage: true,
    });
    if (success) {
      postSuggestion(params);
      callback();
    }
  };

  useEffect(() => {
    if (interactions?.length) handleGetSuggestions();
  }, [interactions]);

  const viewComponentProps = {
    currentChannel,
    currentProposal,
    interactions,
    messagesMobiSmartChat,
    handleClickSuggestion,
    displaySuggestions,
    loadingSuggestions,
    canSendWhatsAppBusinessMessage,
  };

  if (isEmpty(currentProposal))
    return (
      <WrapperBg isWhatsApp={currentChannel === 'WHATSAPP'}>
        {currentChannel === 'WHATSAPP' && <Bg />}
        <Container>
          <EmptyContainer>
            <EmptyIcon>
              <EmptyTemplateMessages />
            </EmptyIcon>
            <span>Sem histórico de mensagens.</span>
          </EmptyContainer>
        </Container>
      </WrapperBg>
    );

  if (chatIsLoading) {
    return (
      <WrapperBg isWhatsApp={currentChannel === 'WHATSAPP'}>
        {currentChannel === 'WHATSAPP' && <Bg />}
        <Container>
          <Wrapper>
            <Progress />
          </Wrapper>
        </Container>
      </WrapperBg>
    );
  }

  return <ViewTextComponent {...viewComponentProps} />;
};

export default ViewTextContainer;
