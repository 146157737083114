import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const NameProfile = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #757575;
  font-family: Poppins Regular;
  margin-left: 16px;
`;

export const Split = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.div`
  font-family: Poppins Bold;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-right: 10px;
`;

export const Button = styled.div`
  font-family: Poppins Medium;
  text-transform: initial;
  background-color: transparent;
  border: none;
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
`;
