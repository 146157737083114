import * as yup from 'yup';

const proposalSchema = yup.object().shape(
  {
    name: yup
      .string()
      .required('Nome obrigatório')
      .default(''),
    email: yup
      .string()
      .email('E-mail inválido')
      .when('mobile', {
        is: value => value && value.length,
        then: yup.string(),
        otherwise: yup.string().required('Telefone ou e-mail obrigatório'),
      })
      .default(''),
    mobile: yup
      .string()
      .when('email', {
        is: value => value && value.length,
        then: yup.string(),
        otherwise: yup.string().required('Telefone ou e-mail obrigatório'),
      })
      .default(''),
    cpfCnpj: yup.string().optional(),
    departmentCode: yup
      .string()
      .nullable(true)
      .default(null)
      .optional(),
    currentDealerSelected: yup
      .number()
      .nullable(null)
      .default(null)
      .required('Loja obrigatório'),
    originSelected: yup
      .string()
      .nullable(true)
      .default(null)
      .optional(),
    channelSelected: yup
      .string()
      .nullable(true)
      .default(null)
      .optional(),
  },
  [['email', 'mobile']]
);

export default proposalSchema;
