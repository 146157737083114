import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import * as API from './api';

interface HandleReturn {
  onSuccess?: (status?: number, message?: string) => void;
  onError?: (status?: number, message?: string) => void;
}

interface CustomMutation<Query, Response> {
  key: string;
  request: (params: Query) => Promise<Response>;
  handleReturn?: HandleReturn;
  queriesToInvalidate?: string[];
}

function useCustomMutation<Query, Response>({
  handleReturn,
  key,
  request,
  queriesToInvalidate,
}: CustomMutation<Query, Response>) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleOnError = message => {
    return dispatch(
      UIActionCreators.snackbarManagement('error', String(message))
    );
  };

  return useMutation([key], (params: Query) => request(params), {
    onSuccess: (response: any) => {
      if (response?.success) {
        if (queriesToInvalidate) {
          queriesToInvalidate.map(query =>
            queryClient.invalidateQueries(query)
          );
        }
        if (handleReturn?.onSuccess) handleReturn?.onSuccess();
      } else {
        handleOnError(response?.data);
        if (handleReturn?.onError) handleReturn?.onError();
      }
    },
  });
}

function useGetEvaluations(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'getEvaluations',
    request: API.getEvaluations,
    handleReturn,
  });
}

function useCreateEvaluation(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'createEvaluation',
    request: API.createEvaluation,
    handleReturn,
  });
}

function useStartPreviousCreatedEvaluation(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'startPreviousCreatedEvaluation',
    request: API.startPreviousCreatedEvaluation,
    handleReturn,
  });
}

function useGetReferencePrices(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'getReferencePrices',
    request: API.getReferencePrices,
    handleReturn,
  });
}

function useCheckDealerBelongsToGroup(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'checkDealerBelongsToGroup',
    request: API.checkDealerBelongsToGroup,
    handleReturn,
  });
}

export {
  useCheckDealerBelongsToGroup,
  useCreateEvaluation,
  useGetEvaluations,
  useGetReferencePrices,
  useStartPreviousCreatedEvaluation,
};
