import React from 'react';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import Checkbox from 'bit/mobiauto.web-ui-components.components.checkbox/Checkbox';
import TextInput from 'modules/financing/components/atoms/TextField';
import AutoComplete from 'components/atoms/AutoComplete';
// import ThemeButton from 'components/atoms/ThemeButton';
// import Chip from '@material-ui/core/Chip';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import ExpansionPanel from 'components/ExpansionPanel';
// import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
// import { ReactComponent as Trash } from 'assets/trash-gray.svg';

import SelectComponent from 'modules/financing/components/atoms/Select';

const VehicleOfInterest = ({
  makesOptions,
  modelsOptions,
  initialYearsOptions,
  finalYearsOptions,
  trimOptions,
  colorsOptions,
  transmissionsOptions,
  fuelOptions,
  bodystylesOptions,
  handleCurrentVehicleOfInterestValues,
  currentVehicleOfInterest,
  vehicleTypeSelected,
  handleSelectVehicleType,
  vehicleTypeOptions,
}) => {
  return (
    <div>
      <section>
        <Grid container spacing={1}>
          {/* //ROW */}
          <Grid item xs={2}>
            <SelectComponent
              label="Tipo do veículo"
              value={vehicleTypeSelected.value}
              onChange={event => handleSelectVehicleType(event.target)}
              options={vehicleTypeOptions}
              mini
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="make"
              label="Marca"
              value={
                currentVehicleOfInterest?.make?.id
                  ? {
                      id: currentVehicleOfInterest?.make?.id,
                      label: currentVehicleOfInterest?.make?.name,
                    }
                  : undefined
              }
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'make',
                  event?.value || null,
                  makesOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={makesOptions}
              disabled={!makesOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="model"
              label="Modelo"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'model',
                  event?.value || null,
                  modelsOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={modelsOptions}
              disabled={!modelsOptions.length}
            />
          </Grid>
          <Grid item xs={2}>
            <AutoComplete
              name="initialYear"
              label="Ano inicial"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'initialYear',
                  event?.value || null
                )
              }
              options={initialYearsOptions}
              disabled={!initialYearsOptions.length}
            />
          </Grid>
          <Grid item xs={2}>
            <AutoComplete
              name="finalYear"
              label="Ano final"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'finalYear',
                  event?.value || null
                )
              }
              options={finalYearsOptions}
              disabled={!finalYearsOptions.length}
            />
          </Grid>
          {/* //ROW */}
          <Grid item xs={3}>
            <AutoComplete
              name="trim"
              label="Versão"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'trim',
                  event?.value || null,
                  trimOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={trimOptions}
              disabled={!trimOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="color"
              label="Cor"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'color',
                  event?.value || null,
                  colorsOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={colorsOptions}
              disabled={!colorsOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="transmission"
              label="Transmissão"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'transmission',
                  event?.value || null,
                  transmissionsOptions.find(el => el.value === event?.value)
                    ?.label
                )
              }
              options={transmissionsOptions}
              disabled={!transmissionsOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="bodystyle"
              label="Carroceria"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'bodystyle',
                  event?.value || null,
                  bodystylesOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={bodystylesOptions}
              disabled={!bodystylesOptions.length}
            />
          </Grid>
          {/* //ROW */}
          <Grid item xs={3}>
            <NumberFormat
              label="KM Máxima"
              value={currentVehicleOfInterest.km}
              onValueChange={values => {
                handleCurrentVehicleOfInterestValues('km', values.floatValue);
              }}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              allowLeadingZeros={false}
              customInput={TextInput}
              mini
            />
          </Grid>
          <Grid item xs={3}>
            <AutoComplete
              name="fuel"
              label="Combustível"
              onChange={event =>
                handleCurrentVehicleOfInterestValues(
                  'fuel',
                  event?.value || null,
                  fuelOptions.find(el => el.value === event?.value)?.label
                )
              }
              options={fuelOptions}
              disabled={!fuelOptions.length}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberFormat
              label="Preço mínimo"
              value={currentVehicleOfInterest.minPrice}
              onValueChange={values => {
                handleCurrentVehicleOfInterestValues(
                  'minPrice',
                  values.floatValue
                );
              }}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              allowLeadingZeros={false}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="R$ "
              customInput={TextInput}
              mini
            />
          </Grid>
          <Grid item xs={3}>
            <NumberFormat
              label="Preço máximo"
              value={currentVehicleOfInterest.maxPrice}
              onValueChange={values => {
                handleCurrentVehicleOfInterestValues(
                  'maxPrice',
                  values.floatValue
                );
              }}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              allowLeadingZeros={false}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="R$ "
              customInput={TextInput}
              mini
            />
          </Grid>
          {/* //ROW */}
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  label={'Blindado'}
                  checked={currentVehicleOfInterest.armored}
                  onChange={event =>
                    handleCurrentVehicleOfInterestValues(
                      'armored',
                      event.target.checked
                    )
                  }
                />
              </div>
              {/* <ThemeButton onClick={addInterestProduct} disabled={!Object.values(currentVehicleOfInterest).filter(el => el).length}>
                + Adicionar veículo de interesse
              </ThemeButton> */}
            </div>
          </Grid>
        </Grid>
      </section>
      {/* <section>
        <Grid container>
          <Grid item xs={12}>
            <h3>Veículo adicionado</h3>
          </Grid>
          <Grid item xs={12}>
            <ExpansionPanel
              // startExpanded
              panel={{
                title: (
                  <ExpansionPanelTitle
                    title={'Perfil 1'}
                    extraButton={
                      <ButtonBase
                        onClick={e => {
                          e.preventDefault();
                        }}
                      >
                        <Trash />
                      </ButtonBase>
                    }
                  />
                ),
              }}
            >
              <Grid container spacing={1}>
                <Grid xs={6} item>
                  Título:{' '}
                  <Chip
                    label={'Audi Q3 TFSI 2.0 Ambition S Tronic Quattro'}
                  // onDelete={() => null}
                  />
                </Grid>
                <Grid xs={6} item>
                  Preço:{' '}
                  <Chip label={'$50.000 - R$100.000'}
                  // onDelete={() => null} 
                  />
                </Grid>
              </Grid>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </section> */}
    </div>
  );
};

export default VehicleOfInterest;
