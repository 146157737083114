import React from 'react';
import ChatHeader from 'pages/chats/components/Header';
import proposalIsFinished from 'pages/chats/helpers/proposalIsFinished';
import Page from 'components/Page';
import Chat from '../components/Chat';
import SideBar from './components/SideBar';
import SubHeader from '../components/SubHeader';
import { Container, Content } from './styles';

const ChatShowroomComponent = ({
  currentProposal,
  handleOnClickBack,
  handleOnClickClose,
  onModal,
  proposalError,
}) => {
  return (
    <Page withoutPadding>
      <ChatHeader
        error={proposalError}
        currentProposal={currentProposal}
        handleOnClickBack={handleOnClickBack}
        onModal={onModal}
        handleOnClickClose={handleOnClickClose}
      />
      <Container isFinished={proposalIsFinished(currentProposal)}>
        <SubHeader currentProposalStatus={currentProposal.status} />
        <Content>
          <Chat />
          <SideBar />
        </Content>
      </Container>
    </Page>
  );
};

export default ChatShowroomComponent;
