import React from 'react';
import Grid from '@material-ui/core/Grid';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import NewCardCallCenter from './components/NewCardCallCenter';

const NewCardComponent = props => {
  const { isOpen, handleOnClose } = props;

  const cardProps = {
    //OPTIONS
    originOptions: props.originOptions,
    channelOptions: props.channelOptions,
    departmentOptions: props.departmentOptions,
    dealersList: props.dealersList,
    handleChange: props.handleChange,
    //FORMDATA
    formData: props.formData,
    searchDeals: props.searchDeals,
    dealsCheckeds: props.dealsCheckeds,
    errors: props.formikErrors,
    touched: props.formikTouched,
    handleBlur: props.handleBlur,

    query: props.query,
    handleOnChangeSearchDeal: props.handleOnChangeSearchDeal,
    deals: props.deals,
    handleClickCheckDeal: props.handleClickCheckDeal,

    handleKeyPressToSearchDeals: props.handleKeyPressToSearchDeals,
    //LOADINGS
    isLoadingDeals: props.isLoadingDeals,
    isLoadingDealers: props.isLoadingDealers,
  };

  return (
    <GenericDialog
      title="Criando proposta"
      onClose={handleOnClose}
      open={isOpen}
      DialogAction={() => {
        return (
          <Grid container justify="space-between">
            <Grid item>
              <OutlinedButton onClick={props.handleOnClose}>
                Cancelar
              </OutlinedButton>
            </Grid>
            <Grid item>
              {props.isLoadingCreateProposal ? (
                <Progress small />
              ) : (
                <ThemeButton onClick={props.handleClickCreateProposal}>
                  Confirmar
                </ThemeButton>
              )}
            </Grid>
          </Grid>
        );
      }}
    >
      <NewCardCallCenter {...cardProps} />
    </GenericDialog>
  );
};
export default NewCardComponent;
