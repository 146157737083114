import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';

import Chip from 'components/atoms/Chip';

import { highAccessUserTypes } from 'constants/userAccess';
import { useChatContext } from 'pages/chats/ChatsContext';
import lazyRetry from 'helpers/lazyRetry';
import { returnDateHourFormatedString } from 'helpers/returnDateHourFormatedString';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';
import { usePermissions } from 'hooks/usePermissions';

import CardSellerProfileAndLeadInfosComponent from '../../CardSellerProfileAndLeadInfos';

import { Split, Button } from './styles';

const ChangeSalesAssistantByDialog = React.lazy(() =>
  lazyRetry(() => import('../ChangeSalesAssistantByDialog'))
);

const CallCenter = ({ closedProposal }) => {
  const { userType } = useSelector(state => state.userStore);
  const { currentProposal, getCurrentProposal } = useChatContext();

  const [state, setState] = useState({
    openModalChangeSeller: false,
    thisTypeUserCanChange: false,
  });

  const handleClickOpenModalChangeSalesAssistant = () => {
    setState(prevState => ({
      ...prevState,
      openModalChangeSeller: !state.openModalChangeSeller,
    }));
  };

  const handleClickCloseModalChangeSalesAssistant = () => {
    setState(prevState => ({ ...prevState, openModalChangeSeller: false }));
    getCurrentProposal(currentProposal.dealerId, currentProposal.id);
  };

  const checkUserCanChangeSeller = () => {
    let userTypeStore = null;

    if (!checkCallCenterOsSearchAndRescueRoute()) {
      userTypeStore = userType;
    } else {
      userTypeStore = 'SALES_ASSISTENT';
    }

    setState(prevState => ({
      ...prevState,
      thisTypeUserCanChange: [
        ...highAccessUserTypes,
        'SALES_ASSISTENT',
      ].includes(userTypeStore),
    }));
  };

  useEffect(() => {
    checkUserCanChangeSeller();
  }, []);

  const text = currentProposal?.salesAssistantName
    ? 'Alterar Assistente de Vendas'
    : 'Adicionar Assistente de Vendas';

  const { hasPermission } = usePermissions();
  const canChangeAgent = hasPermission('PROPOSAL_CHANGE_AGENT');

  const enableChange = checkCallCenterOsSearchAndRescueRoute()
    ? state.thisTypeUserCanChange
    : !closedProposal && canChangeAgent;

  return (
    <>
      <div style={{ padding: '8px', width: '100%' }}>
        {currentProposal.salesAssistantStatus === 'DISQUALIFIED' && (
          <Grid container direction="column">
            <Box
              component="div"
              border="1px solid #e5e5e5"
              padding="8px"
              borderRadius="4px"
            >
              <Chip type="low">Desqualificado</Chip>
              <Box component="span" fontWeight="bold" fontSize="14px" ml="16px">
                Motivo:
              </Box>{' '}
              {currentProposal.salesAssistantStatusDetailName}
            </Box>
          </Grid>
        )}
        {currentProposal.salesAssistantName ? (
          <Split>
            <Box component="div" justifyContent="center">
              <CardSellerProfileAndLeadInfosComponent
                salesName={currentProposal?.salesAssistantName}
                occupation="Assistente de Vendas"
                leadCreatedAt={returnDateHourFormatedString(
                  currentProposal?.createdAt
                )}
                interactionDate={
                  currentProposal?.lastMessage?.ownerName ===
                  currentProposal?.salesAssistantName
                    ? returnDateHourFormatedString(
                        currentProposal?.lastMessage?.date
                      )
                    : ''
                }
                enableChangeSeller={enableChange}
                serviceTime={currentProposal?.salesAssistantTma}
                buttonText="Alterar assistente de vendas"
                onClick={handleClickOpenModalChangeSalesAssistant}
              />
            </Box>
          </Split>
        ) : (
          <div>Nenhum assistente de vendas vinculado.</div>
        )}

        {!currentProposal.salesAssistantName && enableChange && (
          <Button onClick={handleClickOpenModalChangeSalesAssistant}>
            {text}
          </Button>
        )}
      </div>
      {state.openModalChangeSeller && (
        <ChangeSalesAssistantByDialog
          open={state.openModalChangeSeller}
          handleClose={handleClickCloseModalChangeSalesAssistant}
        />
      )}
    </>
  );
};

export default CallCenter;
