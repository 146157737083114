import { localStore } from 'components/Storage';

//TEMPORARY TILL THE FINAL RESALE STORE HAS THE DEALER INFOS
// import ActionCreatorsTempDealerStore from 'modules/financial/store/tempDealer/actionCreators';
// import store from 'modules/resale/store/index';

class DealerFromStore {
  static getDealerFromLocalStorage = () => {
    const dealer = localStore.getItem('dealerCurrent');
    let dealerCurrent = null;
    if (dealer) {
      dealerCurrent = JSON.parse(dealer);
      /*
        TEMPORARY TILL THE FINAL RESALE STORE HAS THE DEALER INFOS
        SETS ALL DEALER INFOS
      */

      // store.dispatch(ActionCreatorsTempDealerStore.setDealer(dealerCurrent));
    }

    return dealerCurrent;
  };

  static setDealerToLocalStorage = dealer => {
    this.clearDealerFromLocalStorage();
    localStore.setItem('dealerCurrent', JSON.stringify(dealer || {}));
  };

  static clearDealerFromLocalStorage = () => {
    localStorage.removeItem('dealerCurrent');
  };

  //DEALER_GROUP
  static setDealerGroupToLocalStorage = dealerGroupId => {
    localStorage.removeItem('dealerGroupCurrent');
    localStore.setItem(
      'dealerGroupCurrent',
      JSON.stringify(dealerGroupId || {})
    );
  };

  static getDealerGroupFromLocalStorage = () => {
    const dealerGroup = localStore.getItem('dealerGroupCurrent');
    let dealerGroupCurrent = null;
    if (dealerGroup) {
      dealerGroupCurrent = JSON.parse(dealerGroup);
    }
    return dealerGroupCurrent;
  };

  static clearDealerGroupFromLocalStorage = () => {
    localStorage.removeItem('dealerGroupCurrent');
  };

  //RESALE_PROPOSAL
  static setResaleProposalToLocalStorage = currentProposal => {
    localStorage.removeItem('resaleProposalCurrent');
    localStore.setItem(
      'resaleProposalCurrent',
      JSON.stringify(currentProposal || {})
    );
  };

  static getResaleProposalFromLocalStorage = () => {
    const resaleProposal = localStore.getItem('resaleProposalCurrent');
    let resaleProposalCurrent = null;
    if (resaleProposal) {
      resaleProposalCurrent = JSON.parse(resaleProposal);
    }
    return resaleProposalCurrent;
  };

  static clearResaleProposalFromLocalStorage = () => {
    localStorage.removeItem('resaleProposalCurrent');
  };

  //DEAL
  static setDealToLocalStorage = (currentDeal, proposalId) => {
    localStorage.removeItem('currentDeal');
    localStore.setItem(
      'currentDeal',
      JSON.stringify({ currentDeal, proposalId } || {})
    );
  };

  static getDealFromLocalStorage = () => {
    const currentDeal = localStore.getItem('currentDeal');
    if (currentDeal) {
      return JSON.parse(currentDeal);
    }
  };

  static clearDealFromLocalStorage = () => {
    localStorage.removeItem('currentDeal');
  };
}

export default DealerFromStore;
