import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import { ReactComponent as IconEmail } from 'assets/email.svg';
import { ReactComponent as IconMobile } from 'assets/baseline-phone-android-24-px.svg';

export const IconMobileStyled = styled(IconMobile)`
  cursor: pointer;
  fill: ${Colors.gray.dark};
  width: 24px;
  height: 24px;
  margin-right: 24px;
`;

export const IconEmailStyled = styled(IconEmail)`
  cursor: pointer;
  fill: #000;
  width: 24px;
  height: 24px;
  margin-right: 24px;
`;

export const LabelInfo = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 24px;
  font-family: Poppins Bold;
  display: flex;
`;

export const NameProfile = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #333333;
  font-family: Poppins Bold;
`;

export const BlueTag = styled.div`
  font-size: 12px;
  color: #4bada9;
  width: 100%;
`;

export const ContactSource = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Poppins Regular;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
