import { useSelector } from 'react-redux';
import { PricingVehicleState } from '../PricingVehicleTypes';

const usePricingVehicleStore = (): PricingVehicleState => {
  const state = useSelector(state => ({
    ...state?.dealerStore,
    vehicleType: state?.orderDeal.vehicleType,
    ...{
      ...state?.dealForm,
      deal: state?.evaluation,
      vehicleTypes: state?.dealForm?.vehicleTypes,
    },
  }));

  return {
    ...state,
  };
};

export { usePricingVehicleStore as default };
