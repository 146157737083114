import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border: ${props => (props.border ? props.border : '1px solid #e0e0e0')};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  height: 47px;
`;

export const ColorView = styled.input`
  border: none;
  background: none;
  height: 32px;
  width: 32px;
  overflow: hidden;
  padding: 0px;
  border-radius: 22px;
`;

export const styles = {
  input: {
    marginLeft: 8,
    flex: 1,
  },
};
