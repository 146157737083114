import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import { Tooltip } from '@material-ui/core';
import { formatDurationFromNow } from './FormatDuration';

const Higlight = styled.span`
  font-size: 14px;
  color: ${Colors.primary.medium};
`;

export default function DateTimeHightLight({ date }) {
  const dateFromNowFormatted = formatDurationFromNow(date);
  const dateFormatted = moment(date).format('DD/MM/YY [às] HH:mm');

  return (
    <Tooltip title={dateFromNowFormatted}>
      <Higlight>{dateFormatted}</Higlight>
    </Tooltip>
  );
}
