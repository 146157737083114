const aggregations = {
  TRANSMISSION: {
    label: 'Transmissão',
    attribute: 'transmissions',
  },
  CITY: {
    label: 'Cidade',
    attribute: 'citiesI',
  },
  MAKE: {
    label: 'Marca',
    attribute: 'makesI',
  },
  ARMORED: {
    label: 'Blindado',
    attribute: 'armored',
  },
  BODYSTYLE: {
    label: 'Carroceria',
    attribute: 'bodyStyles',
  },
  EXTERIOR_COLOR: {
    label: 'Cor',
    attribute: 'colors',
  },
  COLOR: {
    label: 'Cor',
    attribute: 'colors',
  },
  DOORS: {
    label: 'Portas',
    attribute: 'doors',
  },
  DEALER_TYPE: {
    label: 'Tipo de loja',
    attribute: 'dealerType',
  },
  EXTERNAL_STOCK_KIND: {
    label: 'Tipo de estoque',
    attribute: 'externalStockKind',
  },
  YEAR: {
    label: 'Ano Modelo',
    //TODO add specific rule for range values
    attribute: '',
  },
  FUEL: {
    label: 'Combustível',
    attribute: 'fuels',
  },
  STATE: {
    label: 'Estado',
    attribute: 'state',
  },
  MODEL: {
    label: 'Modelo',
    attribute: 'modelsI',
  },
  DEALER: {
    label: 'Loja',
    attribute: 'dealers',
  },
  FINALPLATE: {
    label: 'Fim da placa',
    attribute: 'lastNumberOfPlate',
  },
  YEAR_BY_PRODUCTION: {
    label: 'Ano de Produção',
    attribute: '',
  },
  STOCK_KIND: {
    label: 'Perfil de estoque',
    attribute: 'skd_',
  },
  DEAL_TYPE: {
    label: 'Tipo',
    attribute: 'dealType',
  },
  STOCK_TYPE: {
    label: 'Tipo de estoque',
    attribute: 'stc_',
  },
};

export function translate(aggregation?: string) {
  return aggregation ? aggregations[aggregation]?.label || aggregation : '';
}

export function getAttribute(aggregation: string) {
  return aggregation ? aggregations[aggregation]?.attribute || aggregation : '';
}

export const STOCK_KIND_TRANSLATE_ID = {
  0: { id: 0, label: 'Seminovos', value: 'USED' },
  1: { id: 1, label: '0km', value: 'NEW' },
  2: { id: 2, label: 'Venda Direta', value: 'VD' },
  3: { id: 3, label: 'Imobilizado', value: 'IMMOBILIZED' },
};

export const getLabel = (filter, aggregation = null) => {
  if ((aggregation || filter.aggregation) === 'STOCK_KIND') {
    return STOCK_KIND_TRANSLATE_ID[filter.id]?.label;
  }
  if (filter?.name) {
    return filter?.name;
  }
  if (filter?.id === 0) {
    return 'Não';
  }
  if (filter?.id === 1) {
    return 'Sim';
  }
  return filter?.id;
};
