const paineldigital = '/mobigestor/api';
const images = '/images/api';
const admin = '/admin/api';
const mobifi = '/mobifi/api';
const dealersite = '/api/adm';
const mobiSmartConsultant = '/mobi-consultor-inteligente';
const mobiSmartChat = '/mobi-chat-inteligente';
const mobiSmartAdvertising = '/mobi-anuncio-inteligente';
const superset = '/mobigestor-analytics';
const mediaKit = '/media-kit';
const campaign = '/campaign/api';

const pathsRequireAuthentication = [
  paineldigital,
  images,
  admin,
  mobifi,
  dealersite,
  mobiSmartConsultant,
  mobiSmartChat,
  mobiSmartAdvertising,
  superset,
  mediaKit,
  campaign,
];

export default function requireAuthentication(path) {
  return pathsRequireAuthentication.some(item => path.startsWith(item));
}
