import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectRoot: {
    border: '1px solid #e0e0e0',
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
  selectMini: {
    padding: '21px 12px 5px',
  },
  selectMiniLabel: {
    transform: `translate(12px, 6px) scale(1)`,
  },
  selectMiniShrink: {
    transform: `translate(12px, 8px) scale(0.75) !important`,
  },
}));

const SelectComponent = ({
  value,
  label = '',
  onChange,
  options,
  disabled = false,
  mini = false,
  id = 'demo-simple-select',
  name = '',
  inputProps = {},
  required = false,
  error = false,
  helperText = null,
}) => {
  const classes = useStyles();

  return (
    <FormControl variant="filled" className={classes.formControl} error={error}>
      <InputLabel
        shrink={value}
        classes={{
          filled: mini ? classes.selectMiniLabel : '',
          shrink: mini ? classes.selectMiniShrink : '',
        }}
        id="demo-simple-select-label"
        required={required}
      >
        {label}
      </InputLabel>
      <Select
        className={classes.selectRoot}
        name={name}
        id={id}
        classes={{ select: mini ? classes.selectMini : '' }}
        value={value}
        onChange={onChange}
        disabled={disabled}
        inputProps={inputProps}
      >
        {options.length > 0 ? (
          options.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={''}></MenuItem>
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectComponent;
