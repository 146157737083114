import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  CREATE_DEAL: `@${MODULE}/CREATE_DEAL`,
  SET_MAKE: `@${MODULE}/${getters.SET}_MAKE`,
  SET_MODEL: `@${MODULE}/${getters.SET}_MODEL`,
  SET_PRODUCTION_YEAR: `@${MODULE}/${getters.SET}_PRODUCTION_YEAR`,
  SET_MODEL_YEAR: `@${MODULE}/${getters.SET}_MODEL_YEAR`,
  SET_TRIM: `@${MODULE}/${getters.SET}_TRIM`,
  SET_COLOR: `@${MODULE}/${getters.SET}_COLOR`,
  SET_DOOR: `@${MODULE}/${getters.SET}_DOOR`,
  SET_TRANSMISSION: `@${MODULE}/${getters.SET}_TRANSMISSION`,
  SET_FUEL: `@${MODULE}/${getters.SET}_FUEL`,
  SET_KILOMETER: `@${MODULE}/${getters.SET}_KILOMETER`,
  SET_PLATE: `@${MODULE}/${getters.SET}_PLATE`,
  SET_DEALER_PJ_LOCALITY: `@${MODULE}/${getters.SET}_DEALER_PJ_LOCALITY`,
  SET_AUCTION: `@${MODULE}/${getters.SET}_AUCTION`,
  SET_CASUALTY: `@${MODULE}/${getters.SET}_CASUALTY`,
  SET_ARMORED: `@${MODULE}/${getters.SET}_ARMORED`,
  SET_ARMOR_TYPE: `@${MODULE}/${getters.SET}_ARMOR_TYPE`,
  SET_YOUTUBE_ID: `@${MODULE}/${getters.SET}_YOUTUBE_ID`,
  GET_VEHICLE_INFO_BY_PLATE: `@${MODULE}/${getters.GET}_VEHICLE_INFO_BY_PLATE_${actionOptions.REQUEST}`,
  GET_VEHICLE_INFO_BY_PLATE_SUCCEDED: `@${MODULE}/${getters.GET}_VEHICLE_INFO_BY_PLATE_${actionOptions.SUCCESS}`,
  GET_VEHICLE_INFO_BY_PLATE_ERROR: `@${MODULE}/${getters.GET}_VEHICLE_INFO_BY_PLATE_${actionOptions.ERROR}`,
  SEARCH_TRANSMISSIONS: `@${MODULE}/SEARCH_TRANSMISSIONS`,
  SEARCH_FUELS: `@${MODULE}/SEARCH_FUELS`,
  SET_FEATURES: `@${MODULE}/${getters.SET}_FEATURES`,
  SET_COMMENTS: `@${MODULE}/${getters.SET}_COMMENTS`,
  SET_CONDITIONALS_IDS: `@${MODULE}/${getters.SET}_CONDITIONALS_IDS`,
  SET_PRICE: `@${MODULE}/${getters.SET}_MINIMUM_PRICE`,
  SET_PURCHASE_PRICE: `@${MODULE}/${getters.SET}_PURCHASE_PRICE`,
  SET_MINIMUM_BID_INCREMENT: `@${MODULE}/${getters.SET}_SET_MINIMUM_BID_INCREMENT`,
  SET_MINIMUM_PRICE: `@${MODULE}/${getters.SET}_PRICE`,
  SET_BUY_NOW_PRICE: `@${MODULE}/${getters.SET}_BUY_NOW_PRICE`,
  SET_REPORT_URL: `@${MODULE}/${getters.SET}_REPORT_URL`,
  SET_REPORT_ID: `@${MODULE}/${getters.SET}_REPORT_ID`,
  SET_REPORT_UPLOAD_SUCCESS: `@${MODULE}/${getters.SET}_REPORT_UPLOAD_SUCCESS`,
  SET_IMAGE_UPLOAD: `@${MODULE}/${getters.SET}_IMAGE_UPLOAD`,
  SET_IMAGE_UPLOADING: `@${MODULE}/${getters.SET}_IMAGE_UPLOADING`,
  SET_IMAGE_UPLOAD_ERROR: `@${MODULE}/${getters.SET}_IMAGE_UPLOAD_ERROR`,
  SET_IMAGE_UPLOAD_SUCCESS: `@${MODULE}/${getters.SET}_IMAGE_UPLOAD_SUCCESS`,
  SET_REPORT_UPLOAD: `@${MODULE}/${getters.SET}_REPORT_UPLOAD`,
  SET_FEATURES_CONDITIONAL_VALUE: `@${MODULE}/${getters.SET}_FEATURES_CONDITIONAL_VALUE`,
  SET_FEATURES_CONDITIONAL_REPAIR_PRICE: `@${MODULE}/${getters.SET}_FEATURES_CONDITIONAL_REPAIR_PRICE`,
  SET_IMAGE_POSITION: `@${MODULE}/${getters.SET}_IMAGE_POSITION`,
  SET_IMAGE_UPLOADED: `@${MODULE}/${getters.SET}_IMAGE_UPLOADED`,
  DELETE_IMAGE: `@${MODULE}/${getters.DELETE}_IMAGE`,

  SAVE_DEAL: `@${MODULE}/FINALIZE_DEAL`,
  SAVE_DEAL_SUCCESS: `L@${MODULE}/FINALIZE_DEAL_SUCCESS`,
  SAVE_DEAL_ERROR: `@${MODULE}/FINALIZE_DEAL_ERROR`,
  SET_IMAGE_UPLOADING_RATE: `@${MODULE}/SET_IMAGE_UPLOADING_RATE`,
  SET_REPORT_UPLOADING: `@${MODULE}/SET_REPORT_UPLOADING`,
  CLEAR_DEAL_REPORT: `@${MODULE}/CLEAR_REPORT`,

  GET_DEAL: `@${MODULE}/GET_DEAL`,
  DEAL_LOADING: `@${MODULE}/DEAL_LOADING`,
  DEAL_LOADED: `@${MODULE}/DEAL_LOADED`,
  DEAL_UPDATING: `@${MODULE}/DEAL_UPDATING`,
  DEAL_UPDATING_ERROR: `@${MODULE}/DEAL_UPDATING_ERROR`,
  SET_DEAL: `@${MODULE}/SET_DEAL`,
  UPDATED_DEAL_SUCCESS: `@${MODULE}/UPDATED_DEAL_SUCCESS`,
  UPDATED_DEAL_ERROR: `@${MODULE}/UPDATED_DEAL_ERROR`,
  VALIDATE_BASE_DEAL: `@${MODULE}/VALIDATE_BASE_DEAL`,
  SET_DEAL_ERROR_LOADING: `@${MODULE}/SET_DEAL_ERROR_LOADING`,
  SET_DEAL_ERROR_UPLOADING: `@${MODULE}/SET_DEAL_ERROR_UPLOADING`,
  CLEAN_DEAL_ERRORS: `@${MODULE}/CLEAN_DEAL_ERRORS`,
  SET_DEAL_REPORT_STATUS: `@${MODULE}/SET_DEAL_REPORT_STATUS`,

  SET_JUST_MAKE: `@${MODULE}/${getters.SET}_JUST_MAKE`,
  SET_JUST_MODEL: `@${MODULE}/${getters.SET}_JUST_MODEL`,
  SET_JUST_PRODUCTION_YEAR: `@${MODULE}/${getters.SET}_JUST_PRODUCTION_YEAR`,
  SET_JUST_MODEL_YEAR: `@${MODULE}/${getters.SET}_JUST_MODEL_YEAR`,
  SET_JUST_TRIM: `@${MODULE}/${getters.SET}_JUST_TRIM`,
  SET_JUST_COLOR: `@${MODULE}/${getters.SET}_JUST_COLOR`,
  SET_JUST_DOOR: `@${MODULE}/${getters.SET}_JUST_DOOR`,
  SET_JUST_TRANSMISSION: `@${MODULE}/${getters.SET}_JUST_TRANSMISSION`,
  SET_JUST_FUEL: `@${MODULE}/${getters.SET}_JUST_FUEL`,
  SET_JUST_KILOMETER: `@${MODULE}/${getters.SET}_JUST_KILOMETER`,
  SET_JUST_PLATE: `@${MODULE}/${getters.SET}_JUST_PLATE`,
  SET_JUST_ARMORED: `@${MODULE}/${getters.SET}_JUST_ARMORED`,
  SET_JUST_ARMOR_TYPE: `@${MODULE}/${getters.SET}_JUST_ARMOR_TYPE`,
  SEARCHING_DEAL_BY_PLATE: `@${MODULE}/SEARCHING_DEAL_BY_PLATE`,
  SEARCHED_DEAL_BY_PLATE: `@${MODULE}/SEARCHED_DEAL_BY_PLATE`,
  ERROR_SEARCHING_DEAL_BY_PLATE: `@${MODULE}/ERROR_SEARCHING_DEAL_BY_PLATE`,
  SET_VALIDATE_BASE_VEHICLE_DATA: `@${MODULE}/SET_VALIDATE_BASE_VEHICLE_DATA`,
  SET_VEHICLE_VALUES_VALIDATE_DATA: `@${MODULE}/SET_VEHICLE_VALUES_VALIDATE_DATA`,
  SET_DEAL_IMAGES_UPLOADING: `@${MODULE}/SET_DEAL_IMAGES_UPLOADING`,

  UPLOAD_REPORT_IMAGE: `@${MODULE}/UPLOAD_REPORT_IMAGE`,
  UPLOADING_REPORT_IMAGE: `@${MODULE}/UPLOADING_REPORT_IMAGE`,
  UPLOADED_REPORT_IMAGE: `@${MODULE}/UPLOADED_REPORT_IMAGE`,
  ERROR_UPLOAD_REPORT_IMAGE: `@${MODULE}/ERROR_UPLOAD_REPORT_IMAGE`,
  REMOVE_REPORT_IMAGE: `@${MODULE}/REMOVE_REPORT_IMAGE`,

  SET_TRADE_IN: `@${MODULE}/SET_TRADE_IN`,
  SET_TRADE_IN_OWNER_NAME: `@${MODULE}/SET_TRADE_IN_OWNER_NAME`,
  SET_TRADE_IN_OWNER_EMAIL: `@${MODULE}/SET_TRADE_IN_OWNER_EMAIL`,
  SET_TRADE_IN_OWNER_PHONE: `@${MODULE}/SET_TRADE_IN_OWNER_PHONE`,
  SET_TRADE_IN_OWNER_CPF: `@${MODULE}/SET_TRADE_IN_OWNER_CPF`,
  VALIDATE_TRADE_IN_INFO: `@${MODULE}/VALIDATE_TRADE_IN_INFO`,
  SET_REPORTS: `@${MODULE}/SET_REPORTS`,
  SET_VEHICLE_TYPE: `@${MODULE}/SET_VEHICLE_TYPE`,
  SET_VIN: `@${MODULE}/SET_VIN`,
  SET_RENAVAM: `@${MODULE}/SET_RENAVAM`,
  SET_MANUFACTURING_PACKAGE_CODE: `@${MODULE}/SET_MANUFACTURING_PACKAGE_CODE`,
  SET_JUST_VEHICLE_TYPE: `@${MODULE}/SET_JUST_VEHICLE_TYPE`,
  SET_DATE_PUBLISH: `@${MODULE}/SET_DATE_PUBLISH`,
  SET_DATE_COUNTDOWN: `@${MODULE}/SET_DATE_COUNTDOWN`,
};
