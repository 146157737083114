/* eslint-disable @typescript-eslint/no-explicit-any */
import SearchService from 'services/searchServices';
import { useQueries } from 'react-query';
import { usePricingVehicleContext } from '../PricingVehicleContext';
import InventoryService from 'services/inventoryServices';
import useSnackBar from 'hooks/useSnackBar';
import { useState } from 'react';
import useDealerId from 'hooks/useDealerId';
import { PlateResponse } from '../PricingVehicleTypes';

export const useGetPricingVehicleDynamicOptions = () => {
  const { pricingVehicleFormData, vehicleType } = usePricingVehicleContext();
  const [plateData, setPlateData] = useState<PlateResponse>(
    {} as PlateResponse
  );
  const { setValues, values } = pricingVehicleFormData;
  const { handleSnackBarError } = useSnackBar();
  const dealerId = useDealerId();

  return useQueries([
    {
      queryKey: ['get_makes', vehicleType],
      queryFn: () => SearchService.getMakes(vehicleType),
      onSettled: () => {
        setValues({
          ...values,
          model: null,
          productionYear: null,
          modelYear: null,
          trim: null,
        });
      },
      select: (response: any) => {
        return response?.data?.map(item => ({
          value: item?.id,
          label: item?.name,
        }));
      },
      enabled: Boolean(vehicleType),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['get_vehicle_by_plate', values?.plate],
      queryFn: () => InventoryService.getVehicleByPlate(dealerId, values.plate),
      onSettled: (response: any) => {
        if (response.success) {
          const { trim, vin } = response.data;

          setPlateData(response.data);

          setValues({
            ...values,
            make: {
              value: trim.makeId,
              label: trim.makeName,
            },
            vin: vin,
            fuel: null,
          });

          return;
        }

        setValues({
          ...values,
          make: null,
          model: null,
          productionYear: null,
          modelYear: null,
          trim: null,
        });

        handleSnackBarError('Erro ao buscar dados');
      },
      enabled: values?.plate?.length > 6,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['get_models', values?.make?.value, plateData?.modelYear],
      queryFn: () => SearchService.getModels(vehicleType, values?.make?.label),
      onSettled: (data: any) => {
        const modelSelected = data?.find(
          item => item?.value === plateData?.trim?.modelId
        );

        setValues({
          ...values,
          model: modelSelected || null,
          productionYear: null,
          modelYear: null,
          trim: null,
        });
      },
      select: (response: any) => {
        return response?.data?.map(item => ({
          value: item.id,
          label: item.name,
        }));
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(values?.make?.value),
    },
    {
      queryKey: [
        'get_production_years',
        values?.model?.value,
        plateData?.productionYear,
      ],
      queryFn: () =>
        SearchService.getProductionYears(vehicleType, values?.model.value),
      onSettled: (data: any) => {
        const selectedProductionYear = data?.find(
          productionYear => productionYear.value === plateData?.productionYear
        );

        setValues({
          ...values,
          productionYear: selectedProductionYear || null,
          modelYear: null,
          trim: null,
        });
      },
      select: (response: any) => {
        return response?.data?.years?.map(year => ({
          value: year,
          label: String(year),
        }));
      },
      enabled: Boolean(values?.model?.value),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['get_trims', values?.modelYear, plateData?.modelYear],
      queryFn: () =>
        SearchService.getTrims(
          vehicleType,
          values?.model?.value,
          values?.modelYear?.value
        ),
      onSettled: (data: any) => {
        const selectedTrim = data?.find(
          trim => trim.value === plateData?.trim?.id
        );

        setValues({
          ...values,
          trim: selectedTrim || null,
        });
      },
      select: (response: any) => {
        return response?.data?.map(trim => ({
          value: trim.id,
          label: trim.name,
        }));
      },
      enabled: Boolean(values?.modelYear?.value),
      refetchOnWindowFocus: false,
    },
  ]);
};
