import React from 'react';
import NumberFormat from 'react-number-format';
import ErrorIcon from '@material-ui/icons/Error';
import TextField from 'modules/financing/components/atoms/TextField';
import Select from 'bit/mobiauto.web-ui-components.components.select/Select';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import formatAmount from 'helpers/formatAmount';
import ThemeButton from 'components/atoms/ThemeButton';
import { termOptions } from 'modules/financing/helpers/defaultOptions';
import {
  Container,
  Space,
  ActionsContainer,
  ResultContainer,
  ShinyText,
} from './styles';

const FormSimulation = ({
  vehiclePrice,
  inputValue,
  isLoading,
  installments,
  modelYear,
  yearsOptions,
  financingSimulationResult,
  sendFinancingSimulation,
  onChange,
}) => {
  return (
    <>
      <Container>
        <NumberFormat
          value={vehiclePrice}
          onValueChange={event => onChange('vehiclePrice', event.floatValue)}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          allowLeadingZeros={false}
          placeholder="0"
          prefix="R$ "
          decimalScale={2}
          fixedDecimalScale={true}
          customInput={TextField}
          label={'Valor do veículo'}
        />
        <Space />
        <NumberFormat
          value={inputValue}
          onValueChange={event => onChange('inputValue', event.floatValue)}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          allowLeadingZeros={false}
          placeholder="0"
          prefix="R$ "
          decimalScale={2}
          fixedDecimalScale={true}
          customInput={TextField}
          label={'Valor de entrada'}
        />
        <Space />
        <div style={{ display: 'flex' }}>
          <Select
            id="installments"
            name="installments"
            label="Prazo"
            value={installments}
            onChange={event => onChange(event.target.name, event.target.value)}
            options={termOptions}
          />
          <Space />
          <Select
            id="modelYear"
            name="modelYear"
            label="Ano Modelo"
            value={modelYear}
            onChange={event => onChange(event.target.name, event.target.value)}
            options={yearsOptions}
          />
        </div>
        {financingSimulationResult &&
          financingSimulationResult.map(simulation => {
            return (
              <>
                <Space />
                <ResultContainer>
                  <ShinyText>
                    {formatAmount(vehiclePrice - inputValue, 'PT-BR', 'R$')}
                  </ShinyText>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 8 }}>
                      <ShinyText>{`${simulation?.installments}x `}</ShinyText>
                      parcelas de
                      <ShinyText>{` ${formatAmount(
                        simulation?.minInstallmentValue,
                        'PT-BR',
                        'R$'
                      )} `}</ShinyText>
                      a
                      <ShinyText>{` ${formatAmount(
                        simulation?.maxInstallmentValue,
                        'PT-BR',
                        'R$'
                      )} `}</ShinyText>
                    </div>
                    <Tooltip title="Este valor de parcela não está atrelado a nenhum banco.">
                      <ErrorIcon />
                    </Tooltip>
                  </div>
                </ResultContainer>
              </>
            );
          })}
      </Container>
      <Space size="24px" />
      <ActionsContainer>
        {isLoading ? (
          <Progress medium />
        ) : (
          <ThemeButton onClick={sendFinancingSimulation}>
            Simular financiamento
          </ThemeButton>
        )}
      </ActionsContainer>
    </>
  );
};

export default FormSimulation;
