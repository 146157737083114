import { getReportPDFUri } from 'modules/evaluation/services/evaluationService';
import handlePDFBlobFile from 'helpers/handlePDFBlobFile';

export const generateReport = async ({
  dealerId,
  evaluationId,
  enabledReport,
}: {
  dealerId: number;
  evaluationId: number;
  enabledReport: boolean;
}) => {
  if (!enabledReport) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = await getReportPDFUri(dealerId, evaluationId);

  if (result?.success) {
    return handlePDFBlobFile(result.data, `evaluation-${evaluationId}`, true);
  }

  return result;
};
