import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import OrderDocumentsServices from 'modules/order/services/OrderDocumentsServices';

interface Props {
  callbackOnSuccess?: () => void;
  dealerId?: number;
  proposalId?: number;
}

const useGetMatrixDocuments = ({
  callbackOnSuccess,
  dealerId: paramDealerId,
  proposalId: paramProposalId,
}: Props) => {
  const dispatch = useDispatch();
  const {
    dealerId: loggedDealerId,
    proposalId: loggedProposalId,
  } = useSelector(state => state.order);

  const dealerId = paramDealerId || loggedDealerId;
  const proposalId = paramProposalId || loggedProposalId;

  return useQuery({
    queryKey: 'matrix_documents',
    queryFn: () =>
      OrderDocumentsServices.getMatrixDocumentsCheckout(dealerId, proposalId),
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess(props: { success: boolean; data: any }) {
      if (!props?.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            props?.data ??
              'Ocorreu um erro ao consultar lista de documentos pendentes.'
          )
        );
        return false;
      }
      typeof callbackOnSuccess === 'function' && callbackOnSuccess();
      return true;
    },
    onError() {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao consultar lista de documentos pendentes.'
        )
      );
    },
  });
};

export default useGetMatrixDocuments;
