export function useClipboardArea() {
  const onPaste = (fn: () => void) => {
    document.addEventListener('paste', fn);

    return () => {
      document.removeEventListener('paste', fn);
    };
  };

  return {
    onPaste,
  };
}
