import ActionTypes from './actionTypes';

export const INITIAL_STATE: any = {
  ownerDesiredPrice: null,
  ownerInterestVehicles: null,
  evaluateComments: null,
  evaluationScore: null,
  evaluationPrice: null,
  tradein: false,
  renavam: null,
  manufacturingPackageCode: null,
  vin: '',
  vehicleType: 'CAR',
  ownerCPF: null,
  ownerPhone: null,
  ownerEmail: null,
  ownerName: null,
  reports: [],
  images: [],
  armorType: null,
  armored: false,
  featureIds: [],
  km: 0,
  fuelId: null,
  transmissionId: null,
  colorId: null,
  doors: null,
  productionYear: null,
  modelYear: null,
  trimName: '',
  trimId: '',
  modelName: '',
  modelId: null,
  makeName: '',
  makeId: null,
  plate: '',
  deal0km: false,
  searchingPlate: false,
};

const evaluation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CLEAN_DEAL:
      return INITIAL_STATE;
    case ActionTypes.GET_EVALUATION_VEHICLE_INFOS_BY_PLATE: {
      const { trim, ...deal } = action.payload;
      return {
        ...state,
        ...deal,
      };
    }
    case ActionTypes.SET_EVALUATION_PLATE:
      return {
        ...state,
        plate: action.payload,
      };
    case ActionTypes.SET_EVALUATION_ARMORED:
      return {
        ...state,
        armored: action.payload,
      };
    case ActionTypes.SET_EVALUATION_ARMOR_TYPE:
      return {
        ...state,
        armorType: action.payload,
      };
    case ActionTypes.SET_EVALUATION_VEHICLE_TYPE:
      return {
        ...state,
        ownerDesiredPrice: INITIAL_STATE.ownerDesiredPrice,
        ownerInterestVehicles: INITIAL_STATE.ownerInterestVehicles,
        evaluateComments: INITIAL_STATE.evaluateComments,
        evaluationScore: INITIAL_STATE.evaluationScore,
        evaluationPrice: INITIAL_STATE.evaluationPrice,
        tradein: INITIAL_STATE.tradein,
        renavam: INITIAL_STATE.renavam,
        manufacturingPackageCode: INITIAL_STATE.manufacturingPackageCode,
        vin: INITIAL_STATE.vin,
        reports: INITIAL_STATE.reports,
        images: INITIAL_STATE.images,
        armorType: INITIAL_STATE.armorType,
        armored: INITIAL_STATE.armored,
        featureIds: INITIAL_STATE.featureIds,
        km: INITIAL_STATE.km,
        fuelId: INITIAL_STATE.fuelId,
        transmissionId: INITIAL_STATE.transmissionId,
        colorId: INITIAL_STATE.colorId,
        doors: INITIAL_STATE.doors,
        productionYear: INITIAL_STATE.productionYear,
        modelYear: INITIAL_STATE.modelYear,
        trimName: INITIAL_STATE.trimName,
        trimId: INITIAL_STATE.trimId,
        modelName: INITIAL_STATE.modelName,
        modelId: INITIAL_STATE.modelId,
        makeName: INITIAL_STATE.makeName,
        makeId: INITIAL_STATE.makeId,
        plate: INITIAL_STATE.plate,
        deal0km: INITIAL_STATE.deal0km,
        searchingPlate: INITIAL_STATE.searchingPlate,
        vehicleType: action.payload.vehicleType,
      };
    case ActionTypes.SET_EVALUATION_MAKE:
      return {
        ...state,
        makeId: action.payload.makeId,
        makeName: action.payload.makeName,
        modelId: INITIAL_STATE.modelId,
        modelName: INITIAL_STATE.modelName,
        productionYear: INITIAL_STATE.productionYear,
        modelYear: INITIAL_STATE.modelYear,
        trimId: INITIAL_STATE.trimId,
        fuelId: INITIAL_STATE.fuelId,
        trimName: INITIAL_STATE.trimName,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SET_EVALUATION_MODEL:
      return {
        ...state,
        modelId: action.payload.modelId,
        modelName: action.payload.modelName,
        productionYear: INITIAL_STATE.productionYear,
        modelYear: INITIAL_STATE.modelYear,
        trimId: INITIAL_STATE.trimId,
        trimName: INITIAL_STATE.trimName,
        fuelId: INITIAL_STATE.fuelId,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SEARCHING_BY_PLATE:
      return {
        ...state,
        searchingPlate: true,
      };
    case ActionTypes.ERROR_SEARCHING_BY_PLATE:
      return {
        ...state,
        searchingPlate: false,
        searchingPlateDetail: action.payload,
      };
    case ActionTypes.SEARCHED_BY_PLATE:
      return {
        ...state,
        searchingPlate: false,
        searchingPlateDetail: action.payload,
      };
    case ActionTypes.SET_EVALUATION_PRODUCTION_YEAR:
      return {
        ...state,
        productionYear: action.payload,
        modelYear: INITIAL_STATE.modelYear,
        trimId: INITIAL_STATE.trimId,
        trimName: INITIAL_STATE.trimName,
        fuelId: INITIAL_STATE.fuelId,
      };
    case ActionTypes.SET_EVALUATION_MODEL_YEAR:
      return {
        ...state,
        modelYear: action.payload,
        trimId: INITIAL_STATE.trimId,
        trimName: INITIAL_STATE.trimName,
        fuelId: INITIAL_STATE.fuelId,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.SET_EVALUATION_TRIM:
      return {
        ...state,
        trimId: action.payload.trimId,
        trimName: action.payload.trimName,
      };
    case ActionTypes.SET_EVALUATION_COLOR_ID:
      return {
        ...state,
        colorId: action.payload,
      };
    case ActionTypes.SET_EVALUATION_DOORS:
      return {
        ...state,
        doors: action.payload,
      };
    case ActionTypes.SET_EVALUATION_TRANSMISSION:
      return {
        ...state,
        transmissionId: action.payload,
      };
    case ActionTypes.SET_EVALUATION_TRADEIN:
      return {
        ...state,
        tradein: action.payload,
      };
    case ActionTypes.SET_EVALUATION_FUEL_ID:
      return {
        ...state,
        fuelId: action.payload,
      };
    case ActionTypes.SET_EVALUATION_KM:
      return {
        ...state,
        km: action.payload,
      };
    case ActionTypes.SET_EVALUATION_VIN:
      return {
        ...state,
        vin: action.payload,
      };
    case ActionTypes.SET_EVALUATION_RENAVAM:
      return {
        ...state,
        renavam: action.payload,
      };
    case ActionTypes.SET_EVALUATION_MANUFACTURING_PACKAGE_CODE:
      return {
        ...state,
        manufacturingPackageCode: action.payload,
      };
    case ActionTypes.SET_EVALUATION_OWNER_NAME:
      return {
        ...state,
        ownerName: action.payload,
      };
    case ActionTypes.SET_EVALUATION_OWNER_CPF:
      return {
        ...state,
        ownerCPF: action.payload,
      };
    case ActionTypes.SET_EVALUATION_OWNER_EMAIL:
      return {
        ...state,
        ownerEmail: action.payload,
      };
    case ActionTypes.SET_EVALUATION_OWNER_PHONE:
      return {
        ...state,
        ownerPhone: action.payload,
      };
    case ActionTypes.GET_EVALUATION: {
      const { ...evaluations } = action.payload;
      return {
        ...state,
        ...evaluations,
      };
    }
    default: {
      return state;
    }
  }
};

export default evaluation;
