import EnvVars from 'helpers/envVars';
import getRestActions from 'helpers/requestModules';
import DealServices from './DealServices';
import DealDocumentServices from './DealDocumentServices';
import TransactionalServices from './TransactionalServices';

const envBaseUrl = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

//store middleware refresh it
let requestModule = getRestActions(``);

export const setManageDealsServicesUrl = (dealerId: string | number) => {
  const baseUrl = `${envBaseUrl}/financial/${dealerId}/inventory`;
  requestModule = getRestActions(baseUrl);
};

export { requestModule };

export { DealServices, DealDocumentServices, TransactionalServices };
