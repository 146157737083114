import React from 'react';
import Grid from '@material-ui/core/Grid';
import Select from 'modules/financing/components/atoms/Select';
import {
  TextMaskCustom,
  CPFCNPJMaskCustom,
} from 'components/MaskTextInput';
import getDateByDatePickerComponent from 'modules/financing/helpers/getDateByDatePickerComponent';
import TextInput from 'modules/financing/components/atoms/TextField';
import { escolarityTypeOptions } from 'modules/financing/helpers/defaultOptions'
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput'

const GuarantorInfosForm = props => {
  const {
    genderOptions,
    civilStatusOptions,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton,
    disableCpfCnpj,
    statesOptions
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="guarantorName"
            name="guarantorName"
            label="Nome Completo"
            value={formData['guarantorName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorName')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="guarantorBirthDate"
            name="guarantorBirthDate"
            type="date"
            label="Data de nascimento"
            value={getDateByDatePickerComponent(formData['guarantorBirthDate'])}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorBirthDate')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="guarantorCpf"
            name="guarantorCpf"
            label="CPF"
            value={formData['guarantorCpf']}
            disabled={disableAllInputs || disableCpfCnpj}
            InputProps={{
              inputComponent: CPFCNPJMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorCpf')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="guarantorMotherName"
            name="guarantorMotherName"
            label="Nome da mãe"
            value={formData['guarantorMotherName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorMotherName')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="guarantorFatherName"
            name="guarantorFatherName"
            label="Nome do pai"
            value={formData['guarantorFatherName']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorFatherName')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <TextInput
            id="guarantorEmail"
            name="guarantorEmail"
            label="E-mail"
            value={formData['guarantorEmail']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorEmail')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="guarantorPhone"
            name="guarantorPhone"
            label="Telefone/Celular"
            value={formData['guarantorPhone']}
            disabled={disableAllInputs}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorPhone')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="guarantorNationality"
            name="guarantorNationality"
            label="Nacionalidade"
            value={formData['guarantorNationality']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorNationality')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="guarantorGender"
            name="guarantorGender"
            label="Sexo"
            value={formData['guarantorGender']}
            onChange={onChange}
            options={genderOptions}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            id="guarantorCivilStatus"
            name="guarantorCivilStatus"
            label="Estado Civil"
            value={formData['guarantorCivilStatus']}
            onChange={onChange}
            options={civilStatusOptions}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <Select
            id="guarantorEscolarityType"
            name="guarantorEscolarityType"
            label="Escolaridade"
            value={formData['guarantorEscolarityType']}
            onChange={onChange}
            options={escolarityTypeOptions}
            disabled={disableAllInputs}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="guarantorRgNumber"
            name="guarantorRgNumber"
            label="RG"
            value={formData['guarantorRgNumber']}
            disabled={disableAllInputs}
            onChange={onChange}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorRgNumber')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="guarantorRgIssuer"
            name="guarantorRgIssuer"
            label="Orgão emissor"
            value={formData['guarantorRgIssuer']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('guarantorRgIssuer')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={6}>
          <Select
            id="guarantorRgIssuedState"
            name="guarantorRgIssuedState"
            label="UF do orgão emissor"
            value={formData['guarantorRgIssuedState']}
            onChange={onChange}
            options={statesOptions}
            disabled={disableAllInputs}
            inputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('schoolingType')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="guarantorRgIssuedAt"
            type="date"
            name="guarantorRgIssuedAt"
            label="Data de emissão do RG"
            value={getDateByDatePickerComponent(formData['guarantorRgIssuedAt'])}
            onChange={onChange}
            disabled={disableAllInputs}
            required
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GuarantorInfosForm;
