import BookedVehicleInfos from './BookedVehicleInfo';
import ToBookVehicle from './ToBookVehicle';
import React, { useState } from 'react';
import { LoadingContainer } from './styles';
import { CircularProgress } from '@material-ui/core';
import ItemHeader from '../atoms/ItemHeader';
import { useChatContext } from 'pages/chats/ChatsContext';

export default function BookVehicleInfos(props) {
  const { currentProposal, getCurrentProposal }: any = useChatContext();

  const [updating, setUpdating] = useState(false);

  const updateProposal = async () => {
    setUpdating(true);
    await getCurrentProposal(currentProposal.dealerId, currentProposal.id);
    setUpdating(false);
  };

  return (
    <>
      <ItemHeader>Veiculo reservado</ItemHeader>
      <div style={{ padding: 16 }}>
        {updating ? (
          <LoadingContainer>
            <CircularProgress />
            <span>Atualizando...</span>
          </LoadingContainer>
        ) : currentProposal.booked ? (
          <BookedVehicleInfos
            updateProposal={updateProposal}
            closedProposal={props.closedProposal}
          />
        ) : (
          <ToBookVehicle
            updateProposal={updateProposal}
            proposalStatus={currentProposal.status}
            closedProposal={props.closedProposal}
          />
        )}
      </div>
    </>
  );
}
