import ActionTypes from './actionTypes'
import { Action } from 'types/ActionTypes'
import { DealerFromStore } from 'components/withDealer'

export const defaultNonReceivedValue = null

export const INITIAL_STATE: any = {
  currentGroup: { id: null, name: null },
  proposals: {
    content: [],
  },
  proposalsIsLoading: false,
  proposalsIsError: false,
}

export default function lead(state: any = INITIAL_STATE, action: Action): any {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_GROUP: {
      let currentGroup = action.payload
      if (!currentGroup) {
        currentGroup = DealerFromStore.getDealerGroupFromLocalStorage()
      }
      return { ...state, currentGroup: currentGroup }
    }
    case ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_SUCCESS:
      return { ...state, proposals: action.payload }
    case ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_ERROR:
      return { ...state, proposalsIsError: action.payload }
    case ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_LOADING:
      return { ...state, proposalsIsLoading: action.payload }
    default:
      return state
  }
}
