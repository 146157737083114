import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { DealRepasse, PageResaleOptions } from 'types/DealTypes';

export const PageDefaultOptions: PageResaleOptions = {
  page: 0,
  size: 20,
  order: 0,
};

export default {
  get: (options: PageResaleOptions): Action => ({
    type: ActionTypes.GET_DEALS,
    payload: { options: { ...options } },
  }),
  set: (deals: any): Action => ({
    type: ActionTypes.GET_DEALS_SUCCEDED,
    payload: deals,
  }),
  setIsLoading: (): Action => ({
    type: ActionTypes.GET_DEALS_LOADING,
  }),
  setErrorLoading: (error: any): Action => ({
    type: ActionTypes.GET_DEALS_ERROR,
    payload: error,
  }),
  add: (deal: DealRepasse): Action => ({
    type: ActionTypes.ADD_DEAL,
    payload: deal,
  }),
  deleteDeal: (dealerId: number, dealId: number): Action => ({
    type: ActionTypes.DELETE_DEAL,
    payload: { dealerId, dealId },
  }),
  activateDeal: (dealerId: number, dealId: number): Action => ({
    type: ActionTypes.ACTIVATE_DEAL,
    payload: { dealerId, dealId },
  }),
  inactivateDeal: (dealerId: number, dealId: number): Action => ({
    type: ActionTypes.INACTIVATE_DEAL,
    payload: { dealerId, dealId },
  }),
  setIsInactivate: (): Action => ({
    type: ActionTypes.INACTIVING_DEAL,
  }),
  setIsActivate: (): Action => ({
    type: ActionTypes.ACTIVING_DEAL,
  }),
  activateDealSuccess: (dealId: number): Action => ({
    type: ActionTypes.ACTIVATE_DEAL_SUCCEDED,
    payload: dealId,
  }),
  inactivateDealSuccess: (dealId: number): Action => ({
    type: ActionTypes.INACTIVATE_DEAL_SUCCEDED,
    payload: dealId,
  }),
  setErrorActivate: (error: any): Action => ({
    type: ActionTypes.ACTIVATE_DEAL_ERROR,
    payload: error,
  }),
  setErrorInactivate: (error: any): Action => ({
    type: ActionTypes.INACTIVATE_DEAL_ERROR,
    payload: error,
  }),
  setIsDeleting: (): Action => ({
    type: ActionTypes.DELETING_DEAL,
  }),
  deleteDealSuccess: (dealId: number): Action => ({
    type: ActionTypes.DELETE_DEAL_SUCCEDED,
    payload: dealId,
  }),
  setErrorDeleting: (error: any): Action => ({
    type: ActionTypes.DELETE_DEAL_ERROR,
    payload: error,
  }),
  resetDeleteDeal: (): Action => ({
    type: ActionTypes.RESET_DELETE_DEAL,
  }),
  update: (deal: DealRepasse): Action => ({
    type: ActionTypes.UPDATE_DEAL,
    payload: deal,
  }),
  recalculateInfos: (): Action => ({
    type: ActionTypes.RECALCULATE_DEALS_INFOS,
  }),
  setInfos: (data: any): Action => ({
    type: ActionTypes.SET_DEALS_INFOS,
    payload: data,
  }),
  setTxtSearch: (data: string): Action => ({
    type: ActionTypes.SET_TXT_SEARCH,
    payload: data,
  }),
  setByOrder: (data: number): Action => ({
    type: ActionTypes.SET_BY_ORDER,
    payload: data,
  }),
  getInfoCards: (dealerId: number): Action => ({
    type: ActionTypes.GET_INFO_CARD,
    payload: dealerId,
  }),
  setErrorInfoCards: (error: any): Action => ({
    type: ActionTypes.SET_INFO_CARD_ERROR,
    payload: error,
  }),
  setInfoCards: (data: any): Action => ({
    type: ActionTypes.SET_INFO_CARD,
    payload: data,
  }),
  getContactData: (
    dealerId: number,
    contactId: number,
    isSupplier: boolean
  ): Action => ({
    type: ActionTypes.GET_CONTACT_INFO,
    payload: { dealerId, contactId, isSupplier },
  }),
  setContactInfo: (data: any): Action => ({
    type: ActionTypes.SET_CONTACT_INFO,
    payload: data,
  }),
  updateContactData: (
    dealerId: number,
    isSupplier: boolean,
    contactId: number,
    data: any
  ) => ({
    type: ActionTypes.SET_UPDATE_CONTACT_INFO,
    payload: { dealerId, isSupplier, contactId, data },
  }),
  setErrorContactInfo: (error: any): Action => ({
    type: ActionTypes.SET_CONTACT_INFO_ERROR,
    payload: error,
  }),
  setLoadingUpdate: (data: any): Action => ({
    type: ActionTypes.SET_CONTACT_INFO_LOADING,
    payload: data,
  }),
  createContact: (
    dealerId: number,
    isSupplier: boolean,
    data: any,
    callback: any
  ): Action => ({
    type: ActionTypes.CREATE_CONTACT,
    payload: { dealerId, isSupplier, data, callback },
  }),
};
