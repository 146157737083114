import React from 'react';

// Context import
import { useChatContext } from 'pages/chats/ChatsContext';

// Component import
import { 
  StatusSteps, 
  // NegotiationProcess, 
  LeadTemperature,
  SourceAndChannel,
  SalesOrder
} from 'pages/chats/components/SideBarComponents/Overview/components';

import ItemHeader from '../atoms/ItemHeader';

// Style import
import { CreatedAt } from './styles';
import moment from 'moment';

export function Overview() {
  const { currentProposal }: any = useChatContext();

  return (
    <>
      <ItemHeader>Visão geral</ItemHeader>

      <CreatedAt>
        <p>Criado em:</p>
        <span>{moment(currentProposal?.createdAt).format("DD/MM/YYYY [às] HH:mm")}</span>
      </CreatedAt>

      <StatusSteps />
      <SalesOrder />
      {/* <NegotiationProcess /> */}
      <LeadTemperature />
      <SourceAndChannel />
    </>
  );
}
