import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import CompanyFullPayForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/CompanyFullPayForm';

const Employment = <ExpansionPanelTitle title={'Dados da empresa'} />;

const ClientDataPJ = ({ buyerCurrentData }) => {
  const dispatch = useDispatch();
  const { isFinished, queueData } = useSelector(state => state.order);

  const convertClientDataToForm = {
    ...buyerCurrentData,
    companyName: buyerCurrentData?.name,
    companySocialName: buyerCurrentData?.socialName,
    cnpj: buyerCurrentData?.cpfCnpj,
    companyEmail: buyerCurrentData?.email,
    companyInscricaoEstadual: buyerCurrentData?.companyStateEnrollment,
    companyPhone: buyerCurrentData?.phone,
    companyPhone2: buyerCurrentData?.phone2,
    companyZipcode: buyerCurrentData?.zipcode,
    companyStreetType: buyerCurrentData?.streetType,
    companyAddress: buyerCurrentData?.address,
    companyNumber: buyerCurrentData?.number,
    companyComplement: buyerCurrentData?.complement,
    companyNeighborhood: buyerCurrentData?.neighborhood,
    companyCityStateId: buyerCurrentData?.cityStateId,
    companyCityId: buyerCurrentData?.cityId,
  };

  const deConvertClientDataToForm = (name, value) => {
    switch (name) {
      case 'companyName':
        return { name: value };
      case 'companySocialName':
        return { socialName: value };
      case 'cnpj':
        return { cpfCnpj: value };
      case 'companyEmail':
        return { email: value };
      case 'companyInscricaoEstadual':
        return { companyStateEnrollment: value };
      case 'companyPhone':
        return { phone: value };
      case 'companyPhone2':
        return { phone2: value };
      case 'companyZipcode':
        return { zipcode: value };
      case 'companyStreetType':
        return { streetType: value };
      case 'companyAddress':
        return { address: value };
      case 'companyNumber':
        return { number: value };
      case 'companyComplement':
        return { complement: value };
      case 'companyNeighborhood':
        return { neighborhood: value };
      case 'companyCityStateId':
        return { cityStateId: value };
      case 'companyCityId':
        return { cityId: value };
      default:
        return { [name]: value };
    }
  };

  const handleChangeFormValues = event => {
    const { value, name } = event.target;

    dispatch(
      ActionCreators.setQueueData({
        client: {
          ...queueData.client,
          ...deConvertClientDataToForm(name, value),
        },
      })
    );
  };

  return (
    <ExpansionPanel panel={{ title: Employment }} startExpanded>
      <CompanyFullPayForm
        formData={convertClientDataToForm}
        onChange={handleChangeFormValues}
        disableAllInputs={isFinished}
      />
    </ExpansionPanel>
  );
};

export default memo(ClientDataPJ);
