import QueueServices from 'services/queueServices';
import moment from 'moment';
import { createUUID } from 'modules/order/helpers/CreateUuid';
import OrderServices from 'modules/order/services/OrderServices';
import { ProposalVehicleDto } from 'modules/order/types/ProposalVehicleDto';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { TypeOfSale } from 'modules/order/types/OrderPayment';
import { Action } from 'types/ActionTypes';
import { OrderPayment } from './../types/OrderPayment';
import {
  getGeneralOrderStep,
  ProposalOrder,
  ProposalOrderStep,
} from './../types/ProposalOrder';
import ActionCreators from './reducers/actionCreators';
import ActionTypes from './reducers/actionTypes';
import { trimStringObject } from 'helpers/trimStringObject';
import isEmpty from 'helpers/isEmpty';
// import { clearValuesObjectWithMask } from 'helpers/clearValuesObjectWithMask';

const buildBankOptions = arr => {
  const options: Array<{ label: string; value: string }> = [];
  arr?.map(element => options.push({ label: element.name, value: element.id }));
  return options;
};

function* setCurrentVehicle(vehicles: ProposalVehicleDto[]) {
  if (vehicles && vehicles.length > 0) {
    const vehicle = {
      ...vehicles[0],
      id: vehicles[0]?.dealId ?? null,
    };
    yield put(ActionCreators.setCurrentVehicle(vehicle));
  }
}

function range(lenght: number): number[] {
  return Array.from(Array(lenght).keys());
}

function parsePaymentItems(payments: OrderPayment[]): OrderPayment[] {
  const paymentsSorted = payments
    .map(payment => ({
      ...payment,
      date: moment(payment.date).format('YYYY-MM-DD'),
    }))
    .sort((a, b) => a.position - b.position);
  return paymentsSorted;
}

function* setPaymentData(order: ProposalOrder) {
  const { currentVehicle } = yield select(state => state.order);
  const { currentDealer } = yield select(state => state.dealerStore);

  yield put(
    ActionCreators.setPaymentData({
      payments: parsePaymentItems(order.payments) || [],
      salePrice:
        order.salePrice || currentVehicle?.price || currentVehicle?.price0km,
      saleDate: moment(order.saleDate).format('YYYY-MM-DD'),
      typeOfSale: order.typeOfSale || TypeOfSale.IN_CASH,
      validity: order.validity
        ? moment(order.validity).format('YYYY-MM-DD')
        : undefined,
      products: order.products.map(product => ({
        ...product,
        uuid: createUUID(),
      })),
      percentageCommissionVDVI: String(order?.percentageCommissionVDVI) || '0',
      manufactorDiscountValue: currentDealer.hasBonusAndDiscountManufactor
        ? String(order?.manufactorDiscountValue) || '0'
        : null,
      manufactorBonusValue: currentDealer.hasBonusAndDiscountManufactor
        ? String(order?.manufactorBonusValue) || '0'
        : null,
    })
  );
}

function* setSaleComments(saleComments: string) {
  if (saleComments) {
    yield put(ActionCreators.setSaleComments(saleComments));
  }
}

function* setCompleteSteps({ payload: newCurrentStep }: Action) {
  const { completedSteps } = yield select(state => state.order);

  const newCompletedSteps = range(newCurrentStep);

  if (newCompletedSteps.length > completedSteps.length) {
    yield put(ActionCreators.setCompletedSteps(newCompletedSteps));
  }
}

function* setCurrentStep(orderLevel: string) {
  const { currentDealer } = yield select(state => state.dealerStore);
  const { currentStep } = yield select(state => state.order);

  const newCurrentStep = getGeneralOrderStep(
    !!currentDealer.products.find(
      product => product.type === 'PROPOSAL_ORDER_OEM'
    )
  )[orderLevel];

  if (newCurrentStep != currentStep)
    yield put(ActionCreators.setActiveStep(newCurrentStep));
}

function getProposalCurrrentStep(
  finishedStep: ProposalOrderStep
): ProposalOrderStep {
  const stepsSummary = Object.values(ProposalOrderStep);
  const finishedStepIndex = stepsSummary.indexOf(finishedStep);
  const currentStepIndex = finishedStepIndex + 1;

  //if not last step
  if (currentStepIndex <= stepsSummary.length)
    return stepsSummary[currentStepIndex] as ProposalOrderStep;
  else return stepsSummary[finishedStepIndex] as ProposalOrderStep;
}

function* setProposalData(order: ProposalOrder) {
  const currentStep = getProposalCurrrentStep(order.step);
  yield put(ActionCreators.setProposalStep(currentStep));

  yield setCurrentVehicle(order.vehiclesOrder);
  yield setPaymentData(order);
  yield setSaleComments(order.saleComments);
}

function* getQueueById() {
  try {
    yield put(ActionCreators.setLoadingOrder(true));

    const { currentDealer, proposalId } = yield select(state => ({
      ...state.dealerStore,
      ...state.proposalStore.currentProposal,
    }));

    if (!currentDealer.id || !proposalId) {
      return;
    }
    const response = yield call(
      QueueServices.getProposalQueueForId,
      currentDealer.id,
      proposalId
    );

    if (response.success) {
      if (!isEmpty(response.data.trim)) {
        yield put(
          ActionCreators.setCurrentVehicle({
            ...response.data.trim,
            exteriorColor: response.data.exteriorColor,
            interiorColor: response.data.interiorColor,
          })
        );
      }
      yield put(
        ActionCreators.setQueueData({
          client: response.data.client,
          createdAt: response.data.createdAt,
          delivery: response.data.delivery,
          comments: response.data.comments,
          payment: response.data.payment,
          proposal: response.data.proposal,
          seller: response.data.seller,
          status: response.data.status,
          trim: response.data.trim,
          updatedAt: response.data.updatedAt,
        })
      );

      yield setCurrentStep('PROPOSAL_ORDER_OEM');
      yield put(
        ActionCreators.setProposalStep(ProposalOrderStep.VEHICLE_QUEUE)
      );

      yield put(ActionCreators.setLoadingOrder(false));
      return;
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro ao carregar as informações do pedido.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
      throw response.data;
    }
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro ao carregar as informações do pedido.',
        true
      )
    );
    console.error(e);
    yield put(ActionCreators.setLoadingOrder(false));
  }
}

function* getOrderById() {
  try {
    yield put(ActionCreators.setLoadingOrder(true));

    const { dealerId, proposalId } = yield select(state => state.order);

    if (!dealerId || !proposalId) {
      return;
    }

    const response = yield call(
      OrderServices.getProposalById,
      dealerId,
      proposalId
    );
    if (response.success) {
      yield put(ActionCreators.setCurrentOrder(response.data));
      yield setCurrentStep(response.data.generalStep);
      yield setProposalData(response.data);

      yield put(ActionCreators.setLoadingOrder(false));
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro ao carregar as informações do pedido.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
      throw response.data;
    }
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro ao carregar as informações do pedido.',
        true
      )
    );
    console.error(e);
    yield put(ActionCreators.setLoadingOrder(false));
  }
}

function* getOrderStatus() {
  try {
    yield put(ActionCreators.setLoadingOrderStatus(true));
    const { dealerId, proposalId } = yield select(state => state.order);
    const response = yield call(
      OrderServices.getOrderStatus,
      dealerId,
      proposalId
    );
    if (response.success) {
      yield put(ActionCreators.setOrderStatus(response.data));
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
    yield put(ActionCreators.setLoadingOrderStatus(false));
  } catch (e) {
    console.error(e);
    yield put(ActionCreators.setLoadingOrderStatus(false));
  }
}

//FINSH_ORDER
function* finishOrder() {
  try {
    // yield put(ActionCreators.setLoadingOrder(true));
    const { dealerId, proposalId, currentStep } = yield select(
      state => state.order
    );

    const response = yield call(
      OrderServices.finishOrder,
      dealerId,
      proposalId
    );
    if (response.success) {
      yield put(ActionCreators.setConfettiRun(true));
      yield put(ActionCreators.setActiveStep(currentStep + 1));
      yield put(ActionCreators.setCheckoutOrder(true));
      return;
      // yield put(ActionCreators.setLoadingOrder(false));
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado',
        true
      )
    );
    // yield put(ActionCreators.setLoadingOrder(false));
  }
}

function* getProposalDigital() {
  try {
    const { dealerId, proposalId } = yield select(state => state.order);
    yield put(ActionCreators.setProposalDigital({ isLoading: true }));
    const response = yield call(
      OrderServices.getDigitalProposal,
      dealerId,
      proposalId
    );
    if (response.success) {
      yield put(ActionCreators.setProposalDigital({ data: response.data }));
      return;
    }

    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado',
        true
      )
    );
  } finally {
    yield put(ActionCreators.setProposalDigital({ isLoading: false }));
    yield put(ActionCreators.setLoadingOrder(false));
  }
}

function* sendCancelInvoice() {
  try {
    const { dealerId, proposalId } = yield select(state => state?.order);
    yield put(ActionCreators.setCancelInvoice({ isLoading: true }));
    const response = yield call(
      OrderServices.sendCancelInvoice,
      dealerId,
      proposalId
    );

    if (response?.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Nota fiscal cancelada',
          true
        )
      );
      return;
    }

    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado',
        true
      )
    );
  } finally {
    yield put(ActionCreators.setCancelInvoice({ isLoading: false }));
  }
}

function* getCancelInvoice() {
  try {
    const { dealerId, proposalId } = yield select(state => state.order);
    yield put(ActionCreators.setCancelInvoice({ isLoading: true }));
    const response = yield call(
      OrderServices.getCancelInvoice,
      dealerId,
      proposalId
    );
    if (response.success) {
      yield put(ActionCreators.setCancelInvoice({ data: response?.data }));
      return;
    }

    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado',
        true
      )
    );
    // yield put(ActionCreators.setLoadingOrder(false));
  } finally {
    yield put(ActionCreators.setCancelInvoice({ isLoading: false }));
  }
}

function* sendProposalDigital() {
  try {
    const { dealerId, proposalId } = yield select(state => state?.order);
    const response = yield call(
      OrderServices.sendDigitalProposal,
      dealerId,
      proposalId
    );
    if (response?.success) {
      yield put(ActionCreators.getProposalDigital());
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    console.log('e');
  }
}

//BUYER
function* sendBuyerForm(action: Action) {
  try {
    const { dealerId, proposalId, buyerCurrentData } = yield select(
      state => state.order
    );

    const { currentDealer } = yield select(state => state.dealerStore);

    const buyerCurrentDataFormated = {
      ...buyerCurrentData,
    };
    if (typeof buyerCurrentData?.rgNumber === 'string') {
      buyerCurrentDataFormated.rgNumber = buyerCurrentData?.rgNumber.replace(
        /_|-|\/|\./g,
        ''
      );
    }
    delete buyerCurrentDataFormated.cpfCnpj;

    const response = yield call(
      OrderServices.sendBuyerForm,
      dealerId,
      proposalId,
      action.payload.currentStep,
      trimStringObject(buyerCurrentDataFormated)
    );
    if (response.success) {
      if (
        [
          'BUYER_PERSON_MARITAL_PARTNER',
          'BUYER_FINANCING_OCCUPATION',
          'BUYER_INCASH_PERSON',
          'BUYER_INCASH_COMPANY',
        ].includes(action.payload.currentStep)
      ) {
        const hasProposalOrderOEM = currentDealer.products.find(
          product => product.type === 'PROPOSAL_ORDER_OEM'
        );
        yield put(
          ActionCreators.setCompletedSteps(
            hasProposalOrderOEM ? [0, 1, 2] : [0, 1]
          )
        );
        yield put(
          UIActionCreators.snackbarManagement(
            'success',
            'Dados do comprador salvo com sucesso.',
            true
          )
        );
        action.payload.callback();
      } else {
        action.payload.callback();
      }
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* approveProposal(action: Action) {
  try {
    const { dealerId, proposalId } = yield select(state => state.order);
    const { approvalStatus, refuseReason } = action.payload;
    yield put(ActionCreators.setApproveIsLoading(true));
    const response = yield call(
      OrderServices.approveProposal,
      dealerId,
      proposalId,
      approvalStatus,
      refuseReason
    );
    if (response.success) {
      yield put(ActionCreators.setApproveIsLoading(false));
      yield put(ActionCreators.getOrderById());
      yield put(ActionCreators.getOrderStatus());
      action.payload.callback();
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Aprovação envianda com successo.',
          true
        )
      );
    } else {
      yield put(ActionCreators.setApproveIsLoading(false));
      yield put(
        UIActionCreators.snackbarManagement(
          'error',
          response.data || 'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
  } catch (e) {
    yield put(ActionCreators.setApproveIsLoading(false));
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* getAllFinancialInstitutionOptions() {
  try {
    const {
      currentDealer,
      financialInstitutionsOptions,
    } = yield select(state => ({ ...state.order, ...state.dealerStore }));
    if (financialInstitutionsOptions.length <= 1) {
      const response = yield call(
        OrderServices.getAllFinancialInstitution,
        currentDealer.id
      );
      if (response.success) {
        yield put(
          ActionCreators.setAllFinancialInstitutionOptions(
            buildBankOptions(response.data)
          )
        );
      } else {
        yield put(
          ActionCreators.setAllFinancialInstitutionOptions(
            financialInstitutionsOptions
          )
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export default all([
  takeLatest(ActionTypes.GET_ORDER_STATUS, getOrderStatus),
  takeLatest(ActionTypes.SEND_BUYER_FORM, sendBuyerForm),
  takeLatest(ActionTypes.APPROVE_PROPOSAL, approveProposal),
  takeLatest(ActionTypes.GET_ORDER_BY_ID, getOrderById),
  takeLatest(ActionTypes.FINISH_ORDER, finishOrder),
  takeLatest(ActionTypes.SET_ACTIVE_STEP, setCompleteSteps),
  takeLatest(
    ActionTypes.GET_ALL_FINANCIAL_INSTITUTION_OPTIONS,
    getAllFinancialInstitutionOptions
  ),
  takeLatest(ActionTypes.GET_PROPOSAL_DIGITAL, getProposalDigital),
  takeLatest(ActionTypes.SEND_PROPOSAL_DIGITAL, sendProposalDigital),
  takeLatest(ActionTypes.GET_CANCEL_INVOICE, getCancelInvoice),
  takeLatest(ActionTypes.SEND_CANCEL_INVOICE, sendCancelInvoice),
  takeLatest(ActionTypes.GET_QUEUE_BUY_ID, getQueueById),
]);
