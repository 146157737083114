import styled from 'styled-components';
import { ReactComponent as Alert } from 'assets/alert.svg';

export const AlertStyled = styled(Alert)`
  margin-right: 8px;
`;

export const LabelInfo = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 24px;
  font-family: Poppins Bold;
  display: flex;
`;
export const Item = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Poppins Regular;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LabelBold = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 14px;
  font-family: Poppins Bold;
  display: flex;
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 14px;
  display: flex;
`;
