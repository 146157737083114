import { putCategoryPayloadDTO, Category } from 'modules/financial/types';
import { Action } from 'types/ActionTypes';
import { Select } from 'types/SelectType';
import actionTypes from './actionTypes';

export default {
  //SAGAS
  put: (id: number, attrs: putCategoryPayloadDTO): Action => ({
    type: actionTypes.PUT_CATEGORIES_REQUEST,
    payload: { id, attrs },
  }),

  get: (): Action => ({
    type: actionTypes.GET_CATEGORIES_REQUEST,
    payload: {},
  }),

  delete: (id: number): Action => ({
    type: actionTypes.DELETE_CATEGORIE_REQUEST,
    payload: { id },
  }),

  //REDUCERS
  //used after GET(all) method
  reloadAll: (categories: Select[]): Action => ({
    type: actionTypes.RELOAD_ALL_CATEGORIES,
    payload: { categories },
  }),

  //used after POST and GET(by id) methods
  save: (categories: Category | Category[]): Action => ({
    type: actionTypes.SAVE_CATEGORIE,
    payload: { categories },
  }),

  //used after PUT and PATCH methods
  upateExisting: (updatedCategory: Category): Action => ({
    type: actionTypes.UPDATE_CATEGORY,
    payload: { updatedCategory },
  }),

  //used after DELETE method
  remove: (id: number): Action => ({
    type: actionTypes.REMOVE_CATEGORY,
    payload: { id },
  }),
};
