import getRestActions from 'helpers/requestModules';
import EnvVars from 'helpers/envVars';

const envBaseUrl = EnvVars("API_PAINEL_DIGITAL_BASE_URL");

//store middleware refresh it
let requestModule = getRestActions('');

export function setFinancialServicesUrl(dealerId: string | number) {
    const baseUrl = `${envBaseUrl}/financial/${dealerId}`
    requestModule = getRestActions(baseUrl);
}

export {requestModule}; 


