import styled from 'styled-components';

export const Container = styled.div`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
`;
