import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  margin-right: 16px;
`;

export const BoxStyle = styled(Box)`
  border: '1px solid black';
  box-shadow: 0px 0px 4px 0px #0000001a;

  box-shadow: 0px 0px 4px 0px #00000029;
  min-height: 400px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 5% 1fr;

  height: 10px;
`;

export const ColumnUserFlex = styled.div`
  display: flex;
  height: 72px;
  padding: 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  width: 80px;
`;

export const TextName = styled.span`
  overflow: hidden;
  color: var(--text-primary, #424242);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const TextRole = styled.span`
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: var(--text-secondary, #757575);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const BoxFlexHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 80px;
  margin-top: 2%;
  margin-bottom: 2%;
`;

export const BoxFlexColumn = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e0e0e0;

  :last-child {
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderTimeBox = styled.div`
  color: var(--text-secondary, #757575);
  text-align: left;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
  width: 100px;
  margin: 2% 0;
`;

export const CardUnavailableStyle = styled.div`
  display: flex;
  padding: 0px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-top: 3px solid var(--text-secondary, #757575);
  background: var(--action-hover-gray-100, #f5f5f5);
`;

export const CardScheduleStyle = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 100px;
  background: #00b4aa;
  width: 224px;
  height: 56px;
  padding: 0px 8px 0px 8px;
  span {
    color: var(--text-contrast-text, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
  }
`;

export const AddIconStyle = styled(AddIcon)`
  color: #fff;
`;
