import * as React from 'react';
import * as Icon from '@material-ui/icons';

export default function IconComponent({
  name,
  type = '',
  size = 22,
  color = '#333',
  style = {},
  ...props
}) {
  const IconReceived = Icon[name + type];
  return (
    <IconReceived
      style={Object.assign({ color, fontSize: size }, style)}
      {...props}
    />
  );
}
