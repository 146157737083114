import React, { useEffect, useState } from 'react';
import { ChipProps } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { MonetizationOnRounded } from '@material-ui/icons';
import { sidebarIsOpen } from 'pages/conversations/contexts/atoms';
import { useChatContext } from 'pages/chats/ChatsContext';
import * as S from './styles';
interface PreAnalysisChipProps extends ChipProps {
  spacing: boolean;
}

export function PreAnalysisChip({ spacing }: PreAnalysisChipProps) {
  // Hooks
  const { currentProposal }: any = useChatContext();

  const sideBarIsOpened = useRecoilValue(sidebarIsOpen);
  const [showChip, setShowChip] = useState(false);
  const label = 'Crédito pré-analisado';

  useEffect(() => {
    setShowChip(
      !sideBarIsOpened && currentProposal?.preAnalysisStatus === 'Elegível'
    );
  }, [currentProposal, sideBarIsOpened]);

  if (showChip) {
    return (
      <S.ChipContainer spacing={spacing}>
        <MonetizationOnRounded />
        <S.LabelContainer className="label">{label}</S.LabelContainer>
      </S.ChipContainer>
    );
  }

  return <></>;
}
