import styled from 'styled-components';

interface StyleProps {
  type: string;
  borderRadius?: string | number;
}

const colors = {
  success: { background: '#E0F3F1', color: '#006B5D' },
  error: { background: '#FEECEB', color: '#F44336' },
  waiting: { background: '#FCECBA', color: '#874900' },
  warning: { background: '#FFF5E5', color: '#C77700' },
  info: { background: '#E9EAF9', color: '#4443BC' },
  gray: { background: '#E0E0E0', color: '#757575' },
  orange: { background: '#ff4500', color: '#F5F5F5' },
};

export const Container = styled.div`
  padding: 6px 8px;
  border-radius: 20px;
  font-weight: 500;
  font-size: ${({ fontSize }): string => fontSize};
  line-height: 12px;
  background: ${({ type }: StyleProps): string => colors[type].background};
  border-radius: ${({ borderRadius }: StyleProps) =>
    typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius};
  color: ${({ type }: StyleProps): string => colors[type].color};
  text-transform: capitalize;
  white-space: nowrap;
  text-align: center;
`;
