import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  GET_VEHICLE_TYPES: `@${MODULE}/${getters.GET}_VEHICLE_TYPES_${actionOptions.REQUEST}`,
  GET_VEHICLE_TYPES_SUCCEDED: `@${MODULE}/${getters.GET}_VEHICLE_TYPES_${actionOptions.SUCCESS}`,
  GET_VEHICLE_TYPES_ERROR: `@${MODULE}/${getters.GET}_VEHICLE_TYPES_${actionOptions.ERROR}`,

  GET_JUST_VEHICLE_TYPES: `@${MODULE}/${getters.GET}_JUST_VEHICLE_TYPES_${actionOptions.REQUEST}`,
  GET_JUST_VEHICLE_TYPES_SUCCEDED: `@${MODULE}/${getters.GET}_JUST_VEHICLE_TYPES_${actionOptions.SUCCESS}`,
  GET_JUST_VEHICLE_TYPES_ERROR: `@${MODULE}/${getters.GET}_JUST_VEHICLE_TYPES_${actionOptions.ERROR}`,

  GET_MAKES: `@${MODULE}/${getters.GET}_MAKES_${actionOptions.REQUEST}`,
  GET_MAKES_SUCCEDED: `@${MODULE}/${getters.GET}_MAKES_${actionOptions.SUCCESS}`,
  GET_MAKES_ERROR: `@${MODULE}/${getters.GET}_MAKES_${actionOptions.ERROR}`,

  GET_MODELS: `@${MODULE}/${getters.GET}_MODELS_${actionOptions.REQUEST}`,
  GET_MODELS_SUCCEDED: `@${MODULE}/${getters.GET}_MODELS_${actionOptions.SUCCESS}`,
  GET_MODELS_ERROR: `@${MODULE}/${getters.GET}_MODELS_${actionOptions.ERROR}`,

  GET_PRODUCTION_YEARS: `@${MODULE}/${getters.GET}_PRODUCTION_YEARS_${actionOptions.REQUEST}`,
  GET_PRODUCTION_YEARS_SUCCEDED: `@${MODULE}/${getters.GET}_PRODUCTION_YEARS_${actionOptions.SUCCESS}`,
  GET_PRODUCTION_YEARS_ERROR: `@${MODULE}/${getters.GET}_PRODUCTION_YEARS_${actionOptions.ERROR}`,

  GET_MODEL_YEARS: `@${MODULE}/${getters.GET}_MODEL_YEARS_${actionOptions.REQUEST}`,
  GET_MODEL_YEARS_SUCCEDED: `@${MODULE}/${getters.GET}_MODEL_YEARS_${actionOptions.SUCCESS}`,
  GET_MODEL_YEARS_ERROR: `@${MODULE}/${getters.GET}_MODEL_YEARS_${actionOptions.ERROR}`,

  GET_TRIMS: `@${MODULE}/${getters.GET}_TRIMS_${actionOptions.REQUEST}`,
  GET_TRIMS_SUCCEDED: `@${MODULE}/${getters.GET}_TRIMS_${actionOptions.SUCCESS}`,
  GET_TRIMS_ERROR: `@${MODULE}/${getters.GET}_TRIMS_${actionOptions.ERROR}`,

  GET_DOORS: `@${MODULE}/${getters.GET}_DOORS_${actionOptions.REQUEST}`,
  GET_DOORS_SUCCEDED: `@${MODULE}/${getters.GET}_DOORS_${actionOptions.SUCCESS}`,
  GET_DOORS_ERROR: `@${MODULE}/${getters.GET}_DOORS_${actionOptions.ERROR}`,

  GET_COLORS: `@${MODULE}/${getters.GET}_COLORS_${actionOptions.REQUEST}`,
  GET_COLORS_SUCCEDED: `@${MODULE}/${getters.GET}_COLORS_${actionOptions.SUCCESS}`,
  GET_COLORS_ERROR: `@${MODULE}/${getters.GET}_COLORS_${actionOptions.ERROR}`,

  GET_TRANSMISSIONS: `@${MODULE}/${getters.GET}_TRANSMISSIONS_${actionOptions.REQUEST}`,
  GET_TRANSMISSIONS_SUCCEDED: `@${MODULE}/${getters.GET}_TRANSMISSIONS_${actionOptions.SUCCESS}`,
  GET_TRANSMISSIONS_ERROR: `@${MODULE}/${getters.GET}_TRANSMISSIONS_${actionOptions.ERROR}`,

  GET_FUELS: `@${MODULE}/${getters.GET}_FUELS_${actionOptions.REQUEST}`,
  GET_FUELS_SUCCEDED: `@${MODULE}/${getters.GET}_FUELS_${actionOptions.SUCCESS}`,
  GET_FUELS_ERROR: `@${MODULE}/${getters.GET}_FUELS_${actionOptions.ERROR}`,

  GET_ARMOR_TYPE: `@${MODULE}/${getters.GET}_ARMOR_TYPE_${actionOptions.REQUEST}`,
  GET_ARMOR_TYPE_SUCCEDED: `@${MODULE}/${getters.GET}_ARMOR_TYPE_${actionOptions.SUCCESS}`,
  GET_ARMOR_TYPE_ERROR: `@${MODULE}/${getters.GET}_ARMOR_TYPE_${actionOptions.ERROR}`,
  GET_FEATURES: `@${MODULE}/${getters.GET}_FEATURES_${actionOptions.REQUEST}`,
  GET_FEATURES_SUCCEDED: `@${MODULE}/${getters.GET}_FEATURES_${actionOptions.SUCCESS}`,
  GET_FEATURES_ERROR: `@${MODULE}/${getters.GET}_FEATURES_${actionOptions.ERROR}`,

  GET_CONDITIONALS: `@${MODULE}/${getters.GET}_CONDITIONALS_${actionOptions.REQUEST}`,
  GET_CONDITIONALS_SUCCEDED: `@${MODULE}/${getters.GET}_CONDITIONALS_${actionOptions.SUCCESS}`,
  GET_CONDITIONALS_ERROR: `@${MODULE}/${getters.GET}_CONDITIONALS_${actionOptions.ERROR}`,
  GET_PRODUCTION_YEARS_AND_MODEL_YEARS: `@${MODULE}/${getters.GET}_PRODUCTION_YEARS_AND_MODEL_YEARS_${actionOptions.REQUEST}`,

  GET_FIPE_PRICE: `@${MODULE}/${getters.GET}_FIPE_PRICE_${actionOptions.REQUEST}`,
  SET_FIPE_PRICE: `@${MODULE}/${getters.SET}_FIPE_PRICE_${actionOptions.SUCCESS}`,

  GET_REPORT_STATUS: `@${MODULE}/${getters.GET}_REPORT_STATUS_${actionOptions.REQUEST}`,
  GET_REPORT_STATUS_SUCCEDED: `@${MODULE}/${getters.GET}_REPORT_STATUS_${actionOptions.SUCCESS}`,
  GET_REPORT_STATUS_ERROR: `@${MODULE}/${getters.GET}_REPORT_STATUS_${actionOptions.ERROR}`,

  GET_JUST_MAKES: `@${MODULE}/${getters.GET}_JUST_MAKES_${actionOptions.REQUEST}`,
  GET_JUST_MAKES_SUCCEDED: `@${MODULE}/${getters.GET}_JUST_MAKES_${actionOptions.SUCCESS}`,
  GET_JUST_MAKES_ERROR: `@${MODULE}/${getters.GET}_JUST_MAKES_${actionOptions.ERROR}`,

  GET_JUST_MODELS: `@${MODULE}/${getters.GET}_JUST_MODELS_${actionOptions.REQUEST}`,
  GET_JUST_MODELS_SUCCEDED: `@${MODULE}/${getters.GET}_JUST_MODELS_${actionOptions.SUCCESS}`,
  GET_JUST_MODELS_ERROR: `@${MODULE}/${getters.GET}_JUST_MODELS_${actionOptions.ERROR}`,

  GET_JUST_PRODUCTION_YEARS: `@${MODULE}/${getters.GET}_JUST_PRODUCTION_YEARS_${actionOptions.REQUEST}`,
  GET_JUST_PRODUCTION_YEARS_SUCCEDED: `@${MODULE}/${getters.GET}_JUST_PRODUCTION_YEARS_${actionOptions.SUCCESS}`,
  GET_JUST_PRODUCTION_YEARS_ERROR: `@${MODULE}/${getters.GET}_JUST_PRODUCTION_YEARS_${actionOptions.ERROR}`,

  GET_JUST_MODEL_YEARS: `@${MODULE}/${getters.GET}_JUST_MODEL_YEARS_${actionOptions.REQUEST}`,
  GET_JUST_MODEL_YEARS_SUCCEDED: `@${MODULE}/${getters.GET}_JUST_MODEL_YEARS_${actionOptions.SUCCESS}`,
  GET_JUST_MODEL_YEARS_ERROR: `@${MODULE}/${getters.GET}_JUST_MODEL_YEARS_${actionOptions.ERROR}`,

  GET_DEALER_LOCALITIES: `@${MODULE}/${getters.GET}_DEALER_LOCALITIES_${actionOptions.REQUEST}`,
  GET_DEALER_LOCALITIES_SUCCEDED: `@${MODULE}/${getters.GET}_DEALER_LOCALITIES_${actionOptions.SUCCESS}`,
  GET_DEALER_LOCALITIES_ERROR: `@${MODULE}/${getters.GET}_DEALER_LOCALITIES_${actionOptions.ERROR}`,

  GET_JUST_TRIMS: `@${MODULE}/${getters.GET}_JUST_TRIMS_${actionOptions.REQUEST}`,
  GET_JUST_TRIMS_SUCCEDED: `@${MODULE}/${getters.GET}_JUST_TRIMS_${actionOptions.SUCCESS}`,
  GET_JUST_TRIMS_ERROR: `@${MODULE}/${getters.GET}_JUST_TRIMS_${actionOptions.ERROR}`,
  GET_JUST_PRODUCTION_YEARS_AND_MODEL_YEARS: `@${MODULE}/${getters.GET}_JUST_PRODUCTION_YEARS_AND_MODEL_YEARS_${actionOptions.REQUEST}`,
  CLEAN_DEAL: `@${MODULE}/CLEAN_DEAL`,
};
