import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export default {
  //GET_DEALER_BY_ID
  getDealerById: function(dealerId): Action {
    return {
      type: ActionTypes.GET_DEALER_BY_ID,
      payload: { dealerId },
    };
  },
  //SET_DEALER
  setDealer: function(dealer): Action {
    return {
      type: ActionTypes.SET_DEALER,
      payload: { dealer },
    };
  },
};
