import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'searchAndRescueCenter';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class SearchAndRescueServices {

  static getAllProposalsByGroup = (groupId, obj) => {
    return request
      .get(`${domain}/searchandrescuecenter/${groupId}/v1.0/proposals`, {
        ...obj,
      })
      .then(
        buildHTTPResponseHandler(`${serviceName}.getAllProposalsByGroup()`)
      );
  };

  static alterStatusProposalSalesAssistant = (
    groupId,
    proposalId,
    newStatus,
    statusDetailId
  ) => {
    const url = `${domain}/searchandrescuecenter/${groupId}/v1.1/${proposalId}/sales-assistant/status/${newStatus}`;
    const axiosConfig = statusDetailId ? { params: { statusDetailId } } : null
    return request
      .put(url, {}, axiosConfig)
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.alterStatusProposalSalesAssistant()`
        )
      );
  };

  static setProposalSalesAssistant = (
    groupId,
    proposalId,
    salesAssistantId
  ) => {
    const url = `${domain}/searchandrescuecenter/${groupId}/v1.0/${proposalId}/sales-assistant/${salesAssistantId}`;
    return request
      .put(url, {})
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.setProposalSalesAssistant()`
        )
      );
  };

  static exportProposalsByCsv = (groupId, queryParams = {}) => {
    return request
      .get(`${domain}/searchandrescuecenter/${groupId}/v1.0/proposals/file`, queryParams)
      .then(buildHTTPResponseHandler(`${serviceName}.exportProposalsByCsv()`));
  };

}
