import { createActions, createReducer } from 'reduxsauce';

// enum TYPE {
//   CALL_CENTER,
//   STORE,
//   SEARCH_AND_RESCUE
// }

export const { Types, Creators } = createActions({
  setProposalModalOpen: ['isOpen', 'currentProposal'],
});

export const INITIAL_STATE = {
  openProposalModal: false,
  currentProposal: null,
};

export const setProposalModalOpen = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    openProposalModal: action.isOpen,
    currentProposal: action?.currentProposal || null,
  };
};

export const HANDLERS = {
  [Types.SET_PROPOSAL_MODAL_OPEN]: setProposalModalOpen,
};

export default createReducer(INITIAL_STATE, HANDLERS);
