import React, { useEffect, useState } from 'react';

import { Pricing } from '../../../services/dto';
import ReferencePrices from '../ReferencePrices';

import * as S from './styles';

const Vehicle = ({
  deal,
  referencePrices,
  dealerBelongsToSagaGroup = false,
  isLoading = false,
  evaluationInProgressReferencePrices = [],
}) => {
  const [currentPrices, setCurrentPrices] = useState<Pricing[]>([]);

  const header = {
    fullName: `${deal.makeName} ${deal.modelName}`,
    ...(deal?.name && { name: `${deal.name}` }),
    ...(deal?.plate && { plate: `${deal.plate}` }),
  };

  useEffect(() => {
    if (referencePrices?.success) {
      if (dealerBelongsToSagaGroup && referencePrices?.data[0]?.value === 0) {
        return setCurrentPrices([
          {
            ...referencePrices?.data[0],
            alertMessage:
              'Conforme critérios da Primeira Mão, precisamos realizar uma avaliação presencial para esse veículo. Siga com a solicitação para obter o preço.',
          },
        ]);
      }

      if (referencePrices?.data) {
        return setCurrentPrices(referencePrices?.data);
      }
    }
  }, [referencePrices?.success]);

  React.useEffect(() => {
    if (!referencePrices) {
      setCurrentPrices(evaluationInProgressReferencePrices);
    }
  }, [evaluationInProgressReferencePrices]);

  return (
    <S.Container>
      <S.Header>
        <S.VehicleMainInfo>{header.fullName}</S.VehicleMainInfo>
        {header?.name && <S.VehicleExtraInfo>{header.name}</S.VehicleExtraInfo>}
        {header?.plate && <S.Plate>{header.plate}</S.Plate>}
      </S.Header>
      {currentPrices?.length > 0 && (
        <S.ReferenceBox>
          <ReferencePrices
            references={currentPrices}
            isLoading={isLoading}
            dealerBelongsToSagaGroup={dealerBelongsToSagaGroup}
          />
        </S.ReferenceBox>
      )}
    </S.Container>
  );
};

export default Vehicle;
