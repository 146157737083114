import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionCreators from 'modules/order/store/reducers/actionCreators';
import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import PersonalInfoFullPayForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/PersonalInfosFullPayForm';

//EXPANSIONS_TITLES
const Client = <ExpansionPanelTitle title={'Dados do Cliente'} />;

const ClientDataPF = ({ buyerCurrentData }) => {
  const dispatch = useDispatch();
  const { isFinished, queueData } = useSelector(state => state.order);

  const convertClientDataToForm = {
    ...buyerCurrentData,
    cpf: buyerCurrentData?.cpfCnpj ?? null,
    careerId: buyerCurrentData?.jobTitle ?? null,
  };

  const deConvertClientDataToForm = (name, value) => {
    switch (name) {
      case 'cpf':
        return { cpfCnpj: value };
      case 'careerId':
        return { jobTitle: value };

      default:
        return { [name]: value };
    }
  };

  const handleChangeFormValues = event => {
    const { value, name } = event.target;

    dispatch(
      ActionCreators.setQueueData({
        client: {
          ...queueData.client,
          ...deConvertClientDataToForm(name, value),
        },
      })
    );
  };

  return (
    <div>
      <ExpansionPanel panel={{ title: Client }} startExpanded>
        <PersonalInfoFullPayForm
          formData={convertClientDataToForm}
          onChange={handleChangeFormValues}
          disableAllInputs={isFinished}
        />
      </ExpansionPanel>
    </div>
  );
};

export default memo(ClientDataPF);
