import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 16px 24px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      display: block;

      color: #424242;
      font-family: 'Poppins Regular';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;

      margin: 0 16px;
    }
  }
`;
