/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueries, QueryObserverResult } from 'react-query';
import SearchService from 'services/searchServices';

export const useGetPricingVehicleStaticOptions = (): QueryObserverResult<
  any
>[] => {
  return useQueries([
    {
      queryKey: 'get_colors',
      queryFn: () => SearchService.getColors(),
      select: (response: any) => {
        return response?.data?.map(color => ({
          value: color.id,
          label: color.name,
        }));
      },
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'get_fuels',
      queryFn: () => SearchService.getFuels(),
      select: (response: any) => {
        return response?.data.map(fuel => ({
          value: fuel.id,
          label: fuel.name,
        }));
      },
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'get_transmissions',
      queryFn: () => SearchService.getTransmissions(),
      select: (response: any) => {
        return response?.data.map(transmission => ({
          value: transmission.id,
          label: transmission.name,
        }));
      },
      refetchOnWindowFocus: false,
    },
  ]);
};
