export const MODULE = 'evaluation';

export default {
  CREATE_EVALUATION: `@${MODULE}/CREATE_EVALUATION`,
  GET_EVALUATION: `@${MODULE}/GET_EVALUATIONS`,
  CREATE_EVALUATION_SUCCESS: `@${MODULE}/CREATE_EVALUATION_SUCCESS`,
  CLEAN_DEAL: `@${MODULE}/CLEAN_DEAL`,
  GET_VEHICLE_INFO_BY_PLATE: `@${MODULE}/GET_VEHICLE_INFO_BY_PLATE`,
  GET_FUELS: `@${MODULE}/GET_FUELS`,
  SET_EVALUATION_TYPES: `@${MODULE}/SET_EVALUATION_TYPES`,
  SET_EVALUATION_VEHICLE_TYPE: `@${MODULE}/SET_EVALUATION_VEHICLE_TYPE`,
  SET_EVALUATION_OWNER_CPF: `@${MODULE}/SET_EVALUATION_OWNER_CPF`,
  SET_EVALUATION_OWNER_PHONE: `@${MODULE}/SET_EVALUATION_OWNER_PHONE`,
  SET_EVALUATION_OWNER_EMAIL: `@${MODULE}/SET_EVALUATION_OWNER_EMAIL`,
  SET_EVALUATION_OWNER_NAME: `@${MODULE}/SET_EVALUATION_OWNER_NAME`,
  SET_EVALUATION_FUEL_ID: `@${MODULE}/SET_EVALUATION_FUEL_ID`,
  SET_EVALUATION_VIN: `@${MODULE}/SET_EVALUATION_VIN`,
  SET_EVALUATION_TRIM: `@${MODULE}/SET_EVALUATION_TRIM`,
  SET_EVALUATION_TRADEIN: `@${MODULE}/SET_EVALUATION_TRADEIN`,
  SET_EVALUATION_MODEL: `@${MODULE}/SET_EVALUATION_MODEL`,
  SET_EVALUATION_RENAVAM: `@${MODULE}/SET_EVALUATION_RENAVAM`,
  SET_EVALUATION_COLOR_ID: `@${MODULE}/SET_EVALUATION_COLOR_ID`,
  SET_EVALUATION_DEALER_ID: `@${MODULE}/SET_EVALUATION_DEALER_ID`,
  SET_EVALUATION_ARMOR_TYPE: `@${MODULE}/SET_EVALUATION_ARMOR_TYPE`,
  SET_EVALUATION_DOORS: `@${MODULE}/SET_EVALUATION_DOORS`,
  SET_EVALUATION_EVALUATION_SCORE: `@${MODULE}/SET_EVALUATION_EVALUATION_SCORE`,
  SET_EVALUATION_EVALUATOR: `@${MODULE}/SET_EVALUATION_EVALUATOR`,
  SET_EVALUATION_0KM: `@${MODULE}/SET_EVALUATION_0KM`,
  SET_EVALUATION_KM: `@${MODULE}/SET_EVALUATION_KM`,
  SET_EVALUATION_PLATE: `@${MODULE}/SET_EVALUATION_PLATE`,
  SET_EVALUATION_ARMORED: `@${MODULE}/SET_EVALUATION_ARMORED`,
  SET_EVALUATION_TRANSMISSION: `@${MODULE}/SET_EVALUATION_TRANSMISSION`,
  SET_EVALUATION_MAKE: `@${MODULE}/SET_EVALUATION_MAKE`,
  SET_EVALUATION_MODEL_YEAR: `@${MODULE}/SET_EVALUATION_MODEL_YEAR`,
  SET_EVALUATION_PRODUCTION_YEAR: `@${MODULE}/SET_EVALUATION_PRODUCTION_YEAR`,
  SET_EVALUATION_MANUFACTURING_PACKAGE_CODE: `@${MODULE}/SET_EVALUATION_MANUFACTURING_PACKAGE_CODE`,
  GET_EVALUATION_VEHICLE_INFOS_BY_PLATE: `@${MODULE}/GET_EVALUATION_VEHICLE_INFOS_BY_PLATE`,
  SEARCHING_BY_PLATE: `@${MODULE}/SEARCHING_BY_PLATE`,
  SEARCH_TRANSMISSIONS: `@${MODULE}/SEARCH_TRANSMISSIONS`,
  SEARCH_FUELS: `@${MODULE}/SEARCH_FUELS`,
  SEARCHED_BY_PLATE: `@${MODULE}/SEARCHED_BY_PLATE`,
  ERROR_SEARCHING_BY_PLATE: `@${MODULE}/ERROR_SEARCHING_BY_PLATE`,
};
