import React, { memo } from 'react';
import getImage from 'components/getImage';
import getDate from './getDate';
// import { LazyImg } from 'components/LazyImg';
import formatTime from 'helpers/formatTime';
import lazyRetry from 'helpers/lazyRetry';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import styled from 'styled-components';
import { addLinkOnMessage } from './components/utils/addLinkOnMessage';

const WrapperImage = styled.div`
  position: relative;
  width: 250px;
  height: 188px;
  cursor: pointer;
`;

const ZoomButtom = styled.div`
  width: 42px;
  height: 42px;
  background: #0000006b;
  right: 8px;
  top: 8px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreViewMessage = React.lazy(() =>
  lazyRetry(() => import('components/PreViewMessage'))
);

const width = 250;
class MessageImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openView: false,
      isLoading: false,
    };
  }

  getLinkImage = ({ imageId, imageVcode }) => {
    return getImage({ imageId, imageVcode }, 'auto', width, 0);
  };

  handleOnClickToOpenView = () => {
    this.setState({ openView: true });
  };

  handleOnClickToCloseView = () => {
    this.setState({ openView: false });
  };

  onError = () => {
    this.setState({ isLoading: false });
  };

  onLoad = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const { message, sellerName } = this.props;
    const linkLogo = this.getLinkImage({
      imageId: message.media.id,
      imageVcode: message.media.vcode,
    });
    const dateText = `${getDate(message.date)} ${formatTime(
      new Date(message.date)
    )}`;

    return (
      <>
        {/* {this.state.isLoading && <LoadingInnerPages />} */}
        <WrapperImage>
          <img
            style={{ width: 250, height: 188, objectFit: 'cover' }}
            src={linkLogo}
            onClick={this.handleOnClickToOpenView}
            // className={`${className} lazyload`}
            // alt={alt}
            // onLoad={onLoad}
            // {...props}
          />
          <ZoomButtom>
            <ZoomInIcon style={{ width: 32, height: 32 }} />
          </ZoomButtom>
        </WrapperImage>
        <div style={{ display: 'flex', width: '250px' }}>
          {addLinkOnMessage(message?.message)}
        </div>
        {/* <LazyImg
          style={{ borderRadius: '10px', cursor: 'pointer' }}
          src={linkLogo}
          onLoad={this.onLoad}
          onError={this.onError}
          onClick={this.handleOnClickToOpenView}
        /> */}
        {this.state.openView && (
          <PreViewMessage
            open={this.state.openView}
            handleOnClose={this.handleOnClickToCloseView}
            message={{ ...message }}
            date={dateText}
            ownerName={message.ownerName || sellerName}
          />
        )}
      </>
    );
  }
}

export default memo(MessageImage);
