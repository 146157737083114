import envVars from 'helpers/envVars';
import request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

import * as DTO from './dto';

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'ProposalService';

export async function checkDealerBelongsToGroup({
  dealerId,
  groupId,
}: DTO.CheckDealerBelongsToGroupQ) {
  const response = await request
    .get<any>(`${domain}/dealer-group/${groupId}/v1.0/${dealerId}`)
    .then(
      buildHTTPResponseHandler(
        `${serviceName}.checkDealerBelongsToGroup()`
      ) as any
    );
  return response;
}

export async function getEvaluations({
  dealerId,
  proposalId,
}: DTO.GetEvaluationsQ) {
  const response = await request
    .get(`${domain}/dealer/${dealerId}/proposal/${proposalId}/evaluation/v1.1`)
    .then(buildHTTPResponseHandler(`${serviceName}.getEvaluations()`) as any);
  return response;
}

export async function createEvaluation({
  dealerId,
  proposalId,
  evaluationDto,
  evaluate = false,
}: DTO.CreateEvaluationQ) {
  const response = await request
    .post<any>(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/evaluation/v2.0?evaluate=${evaluate}`,
      evaluationDto
    )
    .then(buildHTTPResponseHandler(`${serviceName}.createEvaluation()`) as any);
  return response;
}

export async function startPreviousCreatedEvaluation({
  dealerId,
  dealId,
  proposalId,
}: DTO.StartPreviousCreatedEvaluationQ) {
  const response = await request
    .put(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/evaluation/v1.0/${dealId}/start`,
      {}
    )
    .then(
      buildHTTPResponseHandler(
        `${serviceName}.startPreviousCreatedEvaluation()`
      ) as any
    );
  return response;
}

export async function getReferencePrices({
  colorId,
  dealerId,
  fuelId,
  km,
  modelYear,
  plate,
  proposalId,
  provider,
  trimId,
}: DTO.GetReferencePricesQ) {
  const propsToConvert = {
    colorId,
    ...(fuelId ? { fuelId } : {}),
    km,
    modelYear,
    plate,
    trimId,
  };

  const formatted = Object.entries(propsToConvert)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const response = await request
    .get(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/evaluation/v1.0/price/${provider}?${formatted}`
    )
    .then(
      buildHTTPResponseHandler(`${serviceName}.getReferencePrices()`) as any
    );

  return response;
}

export const checkIfThereIsEvaluationByPlate = ({ dealerId, plate }) => {
  return request
    .get(
      `${domain}/dealer/${dealerId}/inventory/evaluation/v2.0/plate/${plate}`
    )
    .then(
      buildHTTPResponseHandler(
        `${serviceName}.checkIfExistsEvaluationByPlate()`
      ) as any
    );
};

export const followEvaluation = ({ dealerId, proposalId, evaluationId }) => {
  return request
    .post(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/evaluation/v1.0/${evaluationId}/follow`,
      {}
    )
    .then(
      buildHTTPResponseHandler(
        `${serviceName}.insertEvaluationInProgressOnLead()`
      ) as any
    );
};
