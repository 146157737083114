import React, { useState } from 'react'
import { Tooltip } from '@material-ui/core'
import moment from 'moment'
import BookDetailDialog from 'pages/chats/components/SideBarComponents/BookVehicleInfos/BookedVehicleInfo/BookDetailDialog'
import { TootipTitle, Tag, ConeIcon } from './styles'


export default function BookedTag(props) {

  const onBookCancelled: () => void = props.onBookCancelled
  const bookedAtFormatted: string = moment(props.bookedAt).format('DD/MM/YY [às] HH:mm')
  const vehicleName: string = props.vehicleName
  const bookedId: string = props.bookedId
  const variant: 'mini' | 'standart' | 'withVehicleName' | 'fab' = props.variant || 'standart'

  const isMini = variant === 'mini'
  const isFab = variant === 'fab'
  const isStandart = variant === 'standart'
  const isWithVehicleName = variant === 'withVehicleName'

  const [openBookDetail, setOpenBookDetail] = useState(false)

  const handleOpenBookDetail = (event) => {
    event.stopPropagation()
    if (isWithVehicleName || isFab)
      setOpenBookDetail(true)
  }

  return (
    <>
      <Tooltip
        disableFocusListener={isStandart}
        disableHoverListener={isStandart}
        disableTouchListener={isStandart}
        title={
          <TootipTitle>
            Veículo reservado até o dia {bookedAtFormatted}
          </TootipTitle>
        }
      >
        <Tag clickable={isWithVehicleName} fab={isFab} onClick={handleOpenBookDetail}>
          <ConeIcon fab={isFab} />
          {!props?.someIcon && !isFab &&
            <span>
              &nbsp;
              {isMini && `Reservado`}
              {isStandart && `Reservado até o dia ${bookedAtFormatted}`}
              {isWithVehicleName && vehicleName}
            </span>
          }
        </Tag>
      </Tooltip>
      {(isWithVehicleName || isFab) &&
        <BookDetailDialog
          open={openBookDetail}
          onClose={() => setOpenBookDetail(false)}
          bookedId={bookedId}
          onBookCancelled={onBookCancelled}
          onClick={event => event.stopPropagation()}
        />
      }
    </>
  )
}