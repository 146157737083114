import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/upload-file-white.svg';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 18px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
`;

export const UploadIcon = styled(Icon)`
  margin-right: 8px;
  margin-top: 3px;
`;
