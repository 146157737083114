import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { Select } from 'types/SelectType';
import formatProductionYearsToSelect from 'helpers/formatProductionYearsToSelect';
import formatModelYearsToSelect from 'helpers/formatModelYearsToSelect';
import armorTypes from './ArmorTypes';
import doors from './DoorsTypes';

interface State {
  makes: Select[];
  models: Select[];
  productionYears: Select[];
  modelYears: Select[];
  trims: Select[];
  doors: Select[];
  colors: Select[];
  transmissions: Select[];
  fuels: Select[];
  armorTypes: Select[];
  features: Select[];
  conditionals: Select[];
  fipe: any;
  reportStatus: Select[];
  vehicleTypes: Select[];
  vin: string;
  dealerLocality: Select[];
}
export const INITIAL_STATE: State = {
  makes: [],
  models: [],
  productionYears: [],
  modelYears: [],
  trims: [],
  doors: [],
  colors: [],
  transmissions: [],
  fuels: [],
  armorTypes: [],
  features: [],
  conditionals: [],
  fipe: {},
  reportStatus: [],
  vehicleTypes: [],
  vin: '',
  dealerLocality: [],
};
export default function dealForm(
  state: State = INITIAL_STATE,
  action: Action
): State {
  switch (action.type) {
    case ActionTypes.GET_VEHICLE_TYPES_SUCCEDED:
      return {
        ...state,
        vehicleTypes: action.payload,
        makes: INITIAL_STATE.makes,
        models: INITIAL_STATE.models,
        productionYears: INITIAL_STATE.productionYears,
        modelYears: INITIAL_STATE.models,
        trims: INITIAL_STATE.trims,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.GET_MAKES_SUCCEDED:
      return {
        ...state,
        makes: action.payload,
        models: INITIAL_STATE.models,
        productionYears: INITIAL_STATE.productionYears,
        modelYears: INITIAL_STATE.models,
        trims: INITIAL_STATE.trims,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.GET_MODELS_SUCCEDED:
      return {
        ...state,
        models: action.payload,
        productionYears: INITIAL_STATE.productionYears,
        modelYears: INITIAL_STATE.models,
        trims: INITIAL_STATE.trims,
        vin: INITIAL_STATE.vin,
      };
    case ActionTypes.GET_PRODUCTION_YEARS_SUCCEDED:
      return {
        ...state,
        productionYears: formatProductionYearsToSelect(action.payload),
        modelYears: INITIAL_STATE.models,
        trims: INITIAL_STATE.trims,
      };
    case ActionTypes.GET_MODEL_YEARS:
      return {
        ...state,
        modelYears: formatModelYearsToSelect(
          state.productionYears,
          action.payload
        ),
        trims: INITIAL_STATE.trims,
      };
    case ActionTypes.GET_TRIMS_SUCCEDED:
      return { ...state, trims: action.payload };
      case ActionTypes.GET_DEALER_LOCALITIES_SUCCEDED:
        return { ...state, dealerLocality: action.payload };
    case ActionTypes.GET_DOORS:
      return { ...state, doors: doors };
    case ActionTypes.GET_COLORS_SUCCEDED:
      return { ...state, colors: action.payload };
    case ActionTypes.GET_TRANSMISSIONS_SUCCEDED:
      return { ...state, transmissions: action.payload };
    case ActionTypes.GET_FUELS_SUCCEDED:
      return { ...state, fuels: action.payload };
    case ActionTypes.GET_ARMOR_TYPE:
      return { ...state, armorTypes: armorTypes };
    case ActionTypes.GET_FEATURES_SUCCEDED:
      return { ...state, features: action.payload };
    case ActionTypes.GET_CONDITIONALS_SUCCEDED:
      return { ...state, conditionals: action.payload };
    case ActionTypes.SET_FIPE_PRICE:
      return { ...state, fipe: action.payload };
    case ActionTypes.GET_REPORT_STATUS_SUCCEDED:
      return { ...state, reportStatus: action.payload };
    case ActionTypes.GET_JUST_MAKES_SUCCEDED:
      return {
        ...state,
        makes: action.payload,
      };
    case ActionTypes.GET_JUST_MODELS_SUCCEDED:
      return {
        ...state,
        models: action.payload,
      };
    case ActionTypes.GET_JUST_PRODUCTION_YEARS_SUCCEDED:
      return {
        ...state,
        productionYears: formatProductionYearsToSelect(action.payload),
      };
    case ActionTypes.GET_JUST_MODEL_YEARS_SUCCEDED:
      return {
        ...state,
        modelYears: formatModelYearsToSelect(
          state.productionYears,
          action.payload
        ),
      };
    case ActionTypes.GET_JUST_TRIMS_SUCCEDED:
      return { ...state, trims: action.payload };
    case ActionTypes.GET_JUST_VEHICLE_TYPES_SUCCEDED:
      return { ...state, vehicleTypes: action.payload };
    default:
      return state;
  }
}
