import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import TextField from 'modules/financing/components/atoms/TextField';
import ActionCreator from 'modules/order/store/reducers/actionCreators';
import { Label } from './styles';

const VinForm = ({ pendency }) => {
  // const { checkoutData } = useSelector(state => state.order)
  const dispatch = useDispatch()

  const [vinValue, setVinValue] = useState('')

  const handleChangeVin = (value) => {
    setVinValue(value)
    dispatch(ActionCreator.changePendenciesInfoById(pendency.id, 'value', value))
  }

  return <>
    <Label>Preencha os dados abaixo:</Label>
    <div style={{ minHeight: 9 }} />
    <div style={{ width: 300 }}>
      <TextField
        placeholder="Chassi"
        label="Chassi"
        name="Chassi"
        value={vinValue}
        disabled={false}
        onChange={(e) => handleChangeVin(e.target.value)}
      />
    </div>
    <div style={{ minHeight: 16 }} />
  </>
}

export default VinForm