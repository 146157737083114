import React from 'react';
import { MobiSwitch } from './styles';

const Switch = ({ checked, onChange, disabled = false }) => {
  return (
    <MobiSwitch checked={checked} onChange={onChange} disabled={disabled} />
  );
};

export default Switch;
