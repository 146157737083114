import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import DealCard from 'modules/financing/components/atoms/DealListCard';
import SearchInput from 'modules/financing/components/atoms/SearchInput';
import OrderServices from 'modules/order/services/OrderServices';
import isEmpty from 'helpers/isEmpty';
import { ReactComponent as EmptyVehicleListIcon } from 'assets/empty-vehicle-list-icon.svg';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { Label, FlexCenterContainer, EmptyListIconContainer } from './styles';

async function getDealbyPlate(
  dealerId,
  vehiclePlate,
  callback,
  setLoading,
  dispatch
) {
  try {
    setLoading(true);
    const response: {
      success?: boolean;
      data?: any;
    } = await OrderServices.getValuationsOnDmsApiByVehiclePlate(
      dealerId,
      vehiclePlate
    );
    if (response.success) {
      callback(response.data);
    } else {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          response?.data || 'Desculpe, Ocorreu um erro inesperado.',
          true
        )
      );
    }
    setLoading(false);
  } catch (e) {
    setLoading(false);
    dispatch(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

type Vehicle = {
  date: string;
  id: number;
  plate: string;
  price: number;
  status: string;
  evaluationRecommendation: string;
  evaluationFinalPrice: string | number;
};

const SearchVehicleDms = ({ currentVehicle, onVehicleChoosen }) => {
  const dispatch = useDispatch();
  const [vehiclePlate, setVehiclePlate] = useState(null);
  const { dealerId } = useSelector(state => state.order);
  const [vehicleFinded, setVehicleFinded] = useState<Array<Vehicle> | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const search = () => {
    getDealbyPlate(
      dealerId,
      vehiclePlate,
      setVehicleFinded,
      setIsLoading,
      dispatch
    );
  };

  const [radioIndex, setIndex] = useState();

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const handleChooseVehicle = (vehicle, index) => {
    onVehicleChoosen(vehicle);
    setIndex(index);
  };

  const checkVehicleDisabled = vehicle => {
    if (vehicle.evaluationFinalPrice <= 0) {
      return true;
    }
    if (
      vehicle.evaluationRecommendation &&
      vehicle.evaluationRecommendation === 'NOT_BUY'
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Label>Veículo usado</Label>
      <SearchInput
        value={vehiclePlate}
        onChange={event => setVehiclePlate(event.target.value)}
        placeholder="Busque por placa, nome, etc..."
        searchFunction={search}
        onKeyPress={handleKeyPress}
      />
      <div style={{ height: 8 }} />
      {isLoading ? (
        <FlexCenterContainer column padding>
          <Progress large />
        </FlexCenterContainer>
      ) : vehicleFinded === null ? null : isEmpty(vehicleFinded) ? (
        <FlexCenterContainer column padding>
          <EmptyListIconContainer>
            <EmptyVehicleListIcon />
          </EmptyListIconContainer>
          Nenhum resultado encontrado.
        </FlexCenterContainer>
      ) : (
        <div>
          {vehicleFinded?.map((vehicle, index) => {
            return (
              <DealCard
                key={vehicle.id}
                deal={vehicle}
                canceled={checkVehicleDisabled(vehicle)}
                selectComponent={
                  <RadioButton
                    checked={
                      vehicle?.id === currentVehicle?.id && radioIndex === index
                    }
                    onChange={() => handleChooseVehicle(vehicle, index)}
                    name="vehicleRadio"
                    disabled={checkVehicleDisabled(vehicle)}
                  />
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchVehicleDms;
