import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';

export default {
  //SELL
  getSellerProposals: function(
    dealerId: number,
    options: {
      page: number;
      size: number;
      combo: boolean;
      isSeller: boolean;
      query: string | null;
      dateInitial: string | null;
      dateFinal: string | null;
      sellerId: number | null;
      status: string;
    }
  ): Action {
    return {
      type: ActionTypes.GET_SELLER_PROPOSALS,
      payload: {
        dealerId,
        options,
      },
    };
  },
  //BUY
  getBuyerProposals: function(
    dealerId: number,
    options: {
      page: number;
      size: number;
      combo: boolean;
      isSeller: boolean;
      query: string | null;
      dateInitial: string | null;
      dateFinal: string | null;
      sellerId: number | null;
      status: string;
    }
  ): Action {
    return {
      type: ActionTypes.GET_BUYER_PROPOSALS,
      payload: {
        dealerId,
        options,
      },
    };
  },
  //INDIVIDUAL
  setIndividualProposalsError: function(error: any): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_INDIVIDUAL_ERROR,
      payload: { error },
    };
  },
  setIndividualProposalsSuccess: function(proposals: any): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_INDIVIDUAL_SUCCEDED,
      payload: { proposals },
    };
  },
  //COMBO
  setComboProposalsError: function(error: any): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_COMBO_ERROR,
      payload: { error },
    };
  },
  setComboProposalsSuccess: function(proposals: any): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_COMBO_SUCCEDED,
      payload: { proposals },
    };
  },
  setSellerProposalsOptions: function(options: any): Action {
    return {
      type: ActionTypes.SET_SELLER_PROPOSALS_OPTIONS,
      payload: { options },
    };
  },
  setBuyerProposalsOptions: function(options: any): Action {
    return {
      type: ActionTypes.SET_BUYER_PROPOSALS_OPTIONS,
      payload: { options },
    };
  },
  setIsLoading: function(): Action {
    return {
      type: ActionTypes.SET_PROPOSALS_LOADING,
    };
  },
  //TAB
  setSellerProposalsCurrentTab: function(currentTab: number) {
    return {
      type: ActionTypes.SET_SELLER_PROPOSALS_CURRENT_TAB,
      payload: { currentTab },
    };
  },
  setBuyerProposalsCurrentTab: function(currentTab: number) {
    return {
      type: ActionTypes.SET_BUYER_PROPOSALS_CURRENT_TAB,
      payload: { currentTab },
    };
  },
};
