import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import { PricingProvider, Pricing } from '../../../services/dto';
import { FipeLogo, MobiautoLogo, PrimeiraMaoLogo } from './assets';

import * as S from './styles';
import styled from 'styled-components';
import TextComponent from 'modules/mobiOne/components/Text/TextComponent';
import Wrapper from 'components/Wrapper';
import { useCheckDealerBelongsToGroup } from '../../../services/queries';

const IconWrapper = styled.div`
  line-height: 0;

  & svg {
    width: 42px;
    height: 24px;
  }
`;

export interface PriceReference {
  id: PricingProvider;
  price: number;
  alertMessage?: string;
}

interface Props {
  references: Pricing[];
  isLoading?: boolean;
  listMode?: boolean;
  dealerBelongsToSagaGroup?: boolean;
}

const ReferencePrices = ({
  references,
  isLoading = false,
  listMode = false,
  dealerBelongsToSagaGroup = false,
}: Props) => {
  const formatCurrency = (value, currency = 'BRL', localeString = 'pt-BR') => {
    const options = { style: 'currency', currency, minimumFractionDigits: 0 };
    return value?.toLocaleString(localeString, options);
  };

  const {
    isLoading: isLoadingCheckDealerBelongsToGroup,
  } = useCheckDealerBelongsToGroup({});

  const getPriceReference = (provider: PricingProvider) => {
    switch (provider) {
      case 'MOBIAUTO':
        return {
          name: 'Mobiauto',
          icon: <MobiautoLogo />,
        };
      case 'FIPE':
        return {
          name: 'Fipe',
          icon: (
            <IconWrapper>
              <FipeLogo />
            </IconWrapper>
          ),
        };
      case 'PRIMEIRA_MAO':
        return {
          name: 'Primeira Mão',
          icon: <PrimeiraMaoLogo />,
        };
      default:
        return {
          name: '-',
          icon: <MobiautoLogo />,
        };
    }
  };

  return (
    <S.Container>
      <S.ContainerTitle className="title">
        Referências de mercado
      </S.ContainerTitle>
      <S.List listMode={listMode}>
        {isLoading && (
          <S.Item className="item">
            <div className="content">
              <S.ItemTitle>
                <Skeleton animation="wave" width="40px" height="40px" />
                <Skeleton
                  animation="wave"
                  width="60px"
                  height="24px"
                  variant="text"
                />
              </S.ItemTitle>
              <Skeleton
                animation="wave"
                width="60px"
                height="24px"
                variant="text"
              />
            </div>
          </S.Item>
        )}
        {isLoading ? (
          <Skeleton width="100%" height={60} />
        ) : (
          <>
            {references?.map(reference => {
              const { icon, name } = getPriceReference(reference?.provider);
              const hasAlertMessage = reference?.alertMessage?.length > 0;
              return (
                <S.Item
                  key={reference?.provider}
                  referencesLength={references.length}
                  listMode={listMode}
                  className="item"
                >
                  <div className="content">
                    <S.ItemTitle>
                      <span className="icon">{icon}</span>
                      <span className="name">{name}</span>
                    </S.ItemTitle>
                    {!hasAlertMessage &&
                      (reference.value === 0 ? (
                        <span>-</span>
                      ) : (
                        <S.ItemPrice>
                          <span>{formatCurrency(reference?.value)}</span>
                        </S.ItemPrice>
                      ))}
                  </div>
                  {hasAlertMessage && (
                    <p className="alert">{reference?.alertMessage}</p>
                  )}
                  {isLoadingCheckDealerBelongsToGroup ? (
                    <Skeleton width="100%" height={listMode ? 150 : 56} />
                  ) : (
                    dealerBelongsToSagaGroup && (
                      <Wrapper>
                        {listMode && <S.Divider />}
                        <TextComponent
                          fontSize={listMode ? '14px' : '12px'}
                          fontWeight={500}
                          fontFamily="Poppins Medium"
                          color={listMode ? '#424242' : '#0000008C'}
                          display="block"
                          extraStyle={{
                            lineHeight: listMode ? '28px' : '20px',
                            margin: '8px 0',
                          }}
                        >
                          Informações adicionais:
                        </TextComponent>
                        <TextComponent
                          fontSize={listMode ? '14px' : '12px'}
                          color={listMode ? '#424242' : '#0000008C'}
                          extraStyle={{
                            lineHeight: listMode ? '24px' : '20px',
                          }}
                        >
                          {reference?.description}
                        </TextComponent>
                      </Wrapper>
                    )
                  )}
                </S.Item>
              );
            })}
          </>
        )}
      </S.List>
    </S.Container>
  );
};

export default ReferencePrices;
