import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Snackbar from 'bit/mobiauto.web-ui-components.components.snackbars/Snackbars';
import DateTimeHightLight from 'components/atoms/DateTimeHighLight/DateTimeHighLight';
import { useChatContext } from 'pages/chats/ChatsContext';
import { CancellationReason } from 'types/BookedVehicleTypes';
import BookVehicleServices from 'services/bookVehicleServices';
import {
  BookDivider,
  BookInfo,
  CancelButton,
  DialogHeader,
  SubTitle,
  TitleText,
  DialogActionsContainer,
  MiniLoading,
} from './styles';

export default function CancelBookDialog(props) {
  const { bookedId, bookedAt, open } = props;

  const { currentDealer } = useSelector(state => state.dealerStore);

  const { currentProposal }: any = useChatContext();

  const dealerId = currentDealer?.id || currentProposal?.dealerId;

  const handleClose: (isCanceled) => void = props.onClose;

  const [reasonOptions, setReasonOptions] = useState<CancellationReason[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: '',
    variant: '',
  });

  const [reasonId, setJustifyDescription] = useState<string>('');

  const openSnackBar = (message: string, variant: 'success' | 'error') => {
    setSnackbar({
      isOpen: true,
      message: message,
      variant: variant,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      isOpen: false,
      message: '',
      variant: '',
    });
  };

  const cancelBook = () => {
    setLoading(true);
    BookVehicleServices.cancelBookedVehicle(dealerId, bookedId, reasonId)
      .then(() => {
        handleClose(true);
        setLoading(false);
        openSnackBar('Reserva cancelada com sucesso', 'success');
      })
      .catch(() => {
        setLoading(false);
        openSnackBar('Não foi possível cancelar a reserva do veículo', 'error');
      });
  };

  const handleJustifyChange = event => {
    setJustifyDescription(event.target.value);
  };

  const getReasonOptions = async () => {
    const response: any = await BookVehicleServices.getCancellationReasons(
      dealerId
    );

    setReasonOptions(response?.data);
  };

  useEffect(() => {
    if (open) getReasonOptions();
  }, [open]);

  return (
    <>
      <Dialog
        onClose={() => handleClose(false)}
        {...props}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <DialogHeader>
            <TitleText>Cancelar reserva</TitleText>
            <IconButton aria-label="Close" onClick={() => handleClose(false)}>
              <Close />
            </IconButton>
          </DialogHeader>
        </DialogTitle>
        <DialogContent>
          <SubTitle>Reserva vigente</SubTitle>
          <BookInfo>
            Veículo reservado até o dia &nbsp;
            <DateTimeHightLight date={bookedAt} />
          </BookInfo>
          <BookDivider />
          <SubTitle>Justificativa do cancelamento</SubTitle>
          <div style={{ marginTop: 12 }}>
            <FormControl variant="outlined" style={{ minWidth: '100%' }}>
              <InputLabel>Selecione a justificativa</InputLabel>
              <Select
                value={reasonId}
                variant="outlined"
                onChange={handleJustifyChange}
                input={<OutlinedInput labelWidth={200} />}
              >
                {reasonOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActionsContainer>
          <CancelButton
            color="secondary"
            variant="contained"
            disabled={!reasonId || loading}
            onClick={cancelBook}
          >
            Cancelar reserva
            {loading && <MiniLoading size={20} />}
          </CancelButton>
        </DialogActionsContainer>
      </Dialog>
      {snackbar.isOpen && (
        <Snackbar
          variant={snackbar.variant}
          message={snackbar.message}
          open={snackbar.isOpen}
          onClose={closeSnackbar}
          autoHideDuration={3000}
          vertical="bottom"
          horizontal="right"
        />
      )}
    </>
  );
}
