import styled from 'styled-components';

export const FormLine = styled.div`
  display: flex;
  align-items: start;
`;

export const HyperlinkButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
  margin-left: 4px;
`;
