import React from 'react';
import Grid from '@material-ui/core/Grid';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import VehicleByMakeListCard from 'modules/financing/components/atoms/VehicleByMakeListCard';
import SearchInput from 'modules/financing/components/atoms/SearchInput';
import isEmpty from 'helpers/isEmpty';
import SelectComponent from '../../atoms/Select/Select';
import {
  Container,
  FlexCenterContainer,
  FlexItem,
  FilterContainer,
} from './styles';

function ListVehicleByMakeIdComponent(props) {
  return (
    <div>
      <FilterContainer>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SelectComponent
              label="Tipo do veículo"
              value={props.vehicleTypeSelected.value}
              onChange={event => props.handleSelectVehicleType(event.target)}
              options={props.vehicleTypeOptions}
              mini
            />
          </Grid>
          <Grid item xs={3}>
            <SelectComponent
              label="Escolha a Marca"
              value={props.makesIdSelected}
              onChange={event => props.setMakesIdSelected(event.target.value)}
              options={props.makesOptions}
              mini
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', height: '100%' }}>
              <SearchInput
                value={props.query}
                onChange={event => props.handleChangeQuery(event.target.value)}
                placeholder="Busque por nome, marca, modelo e etc..."
                searchFunction={props.handleClickSearch}
                onKeyPress={props.handleKeyPress}
              />
            </div>
          </Grid>
        </Grid>
      </FilterContainer>
      {props.isLoading ? (
        <FlexCenterContainer>
          <Progress large />
        </FlexCenterContainer>
      ) : isEmpty(props.vehicleList) ? (
        <FlexCenterContainer>Nenhum resultado encontrado.</FlexCenterContainer>
      ) : (
        <Container>
          {props.vehicleList &&
            props.vehicleList.map((vehicle, index) => {
              return (
                <Grid
                  key={`${vehicle.makeName}_${index}_${vehicle.name}`}
                  item
                  xs={12}
                >
                  <FlexItem>
                    <VehicleByMakeListCard
                      key={vehicle.id}
                      deal={vehicle}
                      handleClick={() => props.handleClickItem(vehicle)}
                      currentSelected={props.selectedItemId}
                      selectComponent={
                        <RadioButton
                          checked={vehicle.id === props.selectedItemId}
                          onChange={props.handleClick}
                          name="vehicleRadio"
                          aria-label={vehicle.id}
                        />
                      }
                    />
                  </FlexItem>
                </Grid>
              );
            })}
        </Container>
      )}
    </div>
  );
}

export default ListVehicleByMakeIdComponent;
