import React, { useState } from 'react';
import GenericDialog from 'components/GenericDialog';
import ChooseVehicleTabs from 'components/molecules/ChooseVehicleTabs';
import { ActionsContainer, CancelButton, AddCarButton } from './styles';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';

type ChooseVehicleDialogProps = {
  open: boolean;
  onClose: (choosedVehicle?) => void;
  listType?: 'SHOWROOM' | 'EVALUATION';
  allAvailable?: boolean;
  isStock?: boolean;
  isCatalog?: boolean;
  isProfile?: boolean;
  isModels0Km?: boolean;
  extraDefaultSopValues?: object;
  multipleSelection?: boolean;
  isLoading?: boolean;
};

const ChooseVehicleDialog = ({
  open,
  onClose,
  listType = 'SHOWROOM',
  allAvailable = false,
  isStock = true,
  isCatalog = true,
  isProfile = true,
  isModels0Km = true,
  extraDefaultSopValues,
  multipleSelection = false,
  isLoading = false,
}: ChooseVehicleDialogProps) => {
  const [selectedVehicles, setSelectedVehicles] = useState<any[]>([]);
  // const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleSelectVehicle = deal => {
    if (multipleSelection) {
      let currentVehicles = [...selectedVehicles];
      if (
        currentVehicles.find(selectedVehicle => selectedVehicle.id === deal.id)
      ) {
        currentVehicles = currentVehicles.filter(
          vehicle => vehicle.id !== deal.id
        );
      } else {
        currentVehicles.push(deal);
      }
      return setSelectedVehicles(currentVehicles);
    }
    setSelectedVehicles([...[deal]]);
  };

  //DIALOG_ACTIONS_COMPONENT
  const DialogActions = () => {
    const handleOnVehicleChoosen = () => {
      onClose(selectedVehicles);
      // setConfirmationDialogOpen(true);
    };

    return (
      <ActionsContainer>
        <CancelButton disabled={isLoading} onClick={() => onClose()}>
          Cancelar
        </CancelButton>
        <AddCarButton
          disabled={isLoading || !selectedVehicles.length}
          onClick={handleOnVehicleChoosen}
        >
          {isLoading ? <Progress small /> : 'Adicionar veículo'}
        </AddCarButton>
      </ActionsContainer>
    );
  };

  //DIALOG
  return (
    <GenericDialog
      open={open}
      onClose={() => onClose()}
      OnCloseButton
      DialogAction={DialogActions}
      title="Selecionar veículo"
      maxWidth={'xl'}
      fullWidth
    >
      <ChooseVehicleTabs
        selectedVehicles={selectedVehicles}
        handleSelectVehicle={handleSelectVehicle}
        allAvailable={allAvailable}
        isStock={isStock}
        isCatalog={isCatalog}
        isProfile={isProfile}
        isModels0Km={isModels0Km}
        listType={listType}
        extraDefaultSopValues={extraDefaultSopValues}
        multipleSelection={multipleSelection}
      />

      {/* {confirmationDialogOpen && (
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
          onClickConfirmation={() => onClose(currentVehicle)}
          maxWidth={'md'}
        >
          <DealCard
            deal={buildVehicleObject(currentVehicle)}
          />
        </ConfirmationDialog>
      )} */}
    </GenericDialog>
  );
};

export default ChooseVehicleDialog;
