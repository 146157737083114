export const MODULE = 'financing';

export default {
  //SYSTEM_ACTIONS
  SET_FINANCING_SYSTEM_STATUS: `@${MODULE}/SET_FINANCING_SYSTEM_STATUS`,
  RESET_FINANCING_STORE: `@${MODULE}/RESET_FINANCING_STORE`,
  SET_CURRENT_DEALER_ID: `@${MODULE}/SET_CURRENT_DEALER_ID`,
  //LISTING
  GET_FINANCING_LISTING: `@${MODULE}/GET_FINANCING_LISTING`,
  SET_FINANCING_LISTING: `@${MODULE}/SET_FINANCING_LISTING`,
  SET_FINANCING_LISTING_IS_LOADING: `@${MODULE}/SET_FINANCING_LISTING_IS_LOADING`,
  //MANAGEMENT_STEPS
  SET_ACTIVE_STEP: `@${MODULE}/SET_ACTIVE_STEP`,
  SET_COMPLETE_STEP: `@${MODULE}/SET_COMPLETE_STEP`,
  //CLIENT_DATA
  CREATE_CONTACT: `@${MODULE}/CREATE_CONTACT`,
  SET_CREATE_CONTACT_IS_LOADING: `@${MODULE}/SET_CREATE_CONTACT_IS_LOADING`,
  GET_CONTACT_DATA_BY_ID: `@${MODULE}/GET_CONTACT_DATA_BY_ID`,
  SET_CLIENT_CURRENT: `@${MODULE}/SET_CLIENT_CURRENT`,
  GET_STATES: `@${MODULE}/GET_STATES`,
  SET_STATES: `@${MODULE}/SET_STATES`,
  GET_CITIES_BY_STATE: `@${MODULE}/GET_CITIES_BY_STATE`,
  SET_CITIES_BY_STATE: `@${MODULE}/SET_CITIES_BY_STATE`,
  //CURRENT_VEHICLE_DATA
  SET_VEHICLE_CURRENT: `@${MODULE}/SET_VEHICLE_CURRENT`,
  SET_CURRENT_VEHICLE_INFO: `@${MODULE}/SET_CURRENT_VEHICLE_INFO`,
  //CREATE_FINANCING
  CREATE_FINANCING: `@${MODULE}/CREATE_FINANCING`,
  SET_FINANCING: `@${MODULE}/SET_FINANCING`,
  GET_FINANCING_ORDER_BY_ID: `@${MODULE}/GET_FINANCING_ORDER_BY_ID`,
  SET_FINANCING_ORDER_BY_ID: `@${MODULE}/SET_FINANCING_ORDER_BY_ID`,
  SET_FINANCING_ORDER_BY_ID_LOADING: `@${MODULE}/SET_FINANCING_ORDER_BY_ID_LOADING`,
  //SIMULATION
  SET_CURRENT_VEHICLE_PRICE: `@${MODULE}/SET_CURRENT_VEHICLE_PRICE`,
  SET_DOWN_PAYMENT: `@${MODULE}/SET_DOWN_PAYMENT`,
  SET_INSTALLMENT: `@${MODULE}/SET_INSTALLMENT`,
  SET_COMISSION_RETURN: `@${MODULE}/SET_COMISSION_RETURN`,
  SET_FINANCING_VALUE: `@${MODULE}/SET_FINANCING_VALUE`,
  SIMULATE: `@${MODULE}/SIMULATE`,
  SIMULATE_BY_ID: `@${MODULE}/SIMULATE_BY_ID`,
  SET_SIMULATE_BANK_DATA_BY_ID: `@${MODULE}/SET_SIMULATE_BANK_DATA_BY_ID`,
  SET_BANKS_TO_SIMULATE: `@${MODULE}/SET_BANKS_TO_SIMULATE`,
  SET_SIMULATE_LOADING: `@${MODULE}/SET_SIMULATE_LOADING`,
  CHANGE_DOWN_PAYMENT_BY_BANK: `@${MODULE}/CHANGE_DOWN_PAYMENT_BY_BANK`,
  CHANGE_COMMISSIONRETURN_BY_BANK: `@${MODULE}/CHANGE_COMMISSIONRETURN_BY_BANK`,
  CHANGE_TERMS_BY_BANK: `@${MODULE}/CHANGE_TERMS_BY_BANK`,
  CHANGE_SIMULATE_TERM_BY_BANK: `@${MODULE}/CHANGE_SIMULATE_TERM_BY_BANK`,
  ADD_RATES_CONFIG_BY_BANK: `@${MODULE}/ADD_RATES_CONFIG_BY_BANK`,
  //SELECT_BANK_TO_FINANCING
  SELECT_BANK_TO_FINANCING: `@${MODULE}/SELECT_BANK_TO_FINANCING`,
  APPLY_SIMULATION: `@${MODULE}/APPLY_SIMULATION`,
  SET_APPLY_SIMULATION_LOADING: `@${MODULE}/SET_APPLY_SIMULATION_LOADING`,
  //CHANGE_CLIENT_DATA_INFOS
  CHANGE_CLIENT_CURRENT_DATA: `@${MODULE}/CHANGE_CLIENT_CURRENT_DATA`,
  //BANKS_RESULT_ANALYSIS
  SET_ENABLE_STEP_RESULT_ANALYSIS: `@${MODULE}/SET_ENABLE_STEP_RESULT_ANALYSIS`,
  SET_APPLICATIONS: `@${MODULE}/SET_APPLICATIONS`,
  SEND_CLIENT_FORM: `@${MODULE}/SEND_CLIENT_FORM`,
  APPLY_CREDIT_ANALYSIS: `@${MODULE}/APPLY_CREDIT_ANALYSIS`,
  SET_BANKS_RESULT_ANALYSIS: `@${MODULE}/SET_BANKS_RESULT_ANALYSIS`,
  GET_CREDIT_ANALYSIS: `@${MODULE}/GET_CREDIT_ANALYSIS`,
  SET_RESULT_ANALYSIS_LOADING: `@${MODULE}/SET_RESULT_ANALYSIS_LOADING`,
  CONTRACT_FINANCING: `@${MODULE}/CONTRACT_FINANCING`,
  //OPTIONS
  GET_GENDER_OPTIONS: `@${MODULE}/GET_GENDER_OPTIONS`,
  SET_GENDER_OPTIONS: `@${MODULE}/SET_GENDER_OPTIONS`,

  GET_SERVICE_TYPES_OPTIONS: `@${MODULE}/GET_SERVICE_TYPES_OPTIONS`,
  SET_SERVICE_TYPES_OPTIONS: `@${MODULE}/SET_SERVICE_TYPES_OPTIONS`,

  GET_CIVIL_STATUS_OPTIONS: `@${MODULE}/GET_CIVIL_STATUS_OPTIONS`,
  SET_CIVIL_STATUS_OPTIONS: `@${MODULE}/SET_CIVIL_STATUS_OPTIONS`,

  GET_STREET_TYPE_OPTIONS: `@${MODULE}/GET_STREET_TYPE_OPTIONS`,
  SET_STREET_TYPE_OPTIONS: `@${MODULE}/SET_STREET_TYPE_OPTIONS`,

  GET_ADDRESS_TYPE_OPTIONS: `@${MODULE}/GET_ADDRESS_TYPE_OPTIONS`,
  SET_ADDRESS_TYPE_OPTIONS: `@${MODULE}/SET_ADDRESS_TYPE_OPTIONS`,

  GET_CAREERS_OPTIONS: `@${MODULE}/GET_CAREERS_OPTIONS`,
  SET_CAREERS_OPTIONS: `@${MODULE}/SET_CAREERS_OPTIONS`,

  GET_EMPLOYMENTS_OPTIONS: `@${MODULE}/GET_EMPLOYMENTS_OPTIONS`,
  SET_EMPLOYMENTS_OPTIONS: `@${MODULE}/SET_EMPLOYMENTS_OPTIONS`,

  GET_ALL_FINANCIAL_INSTITUTION_OPTIONS: `@${MODULE}/GET_ALL_FINANCIAL_INSTITUTION_OPTIONS`,
  SET_ALL_FINANCIAL_INSTITUTION_OPTIONS: `@${MODULE}/SET_ALL_FINANCIAL_INSTITUTION_OPTIONS`,

  GET_COMISSION_RETURN_OPTIONS: `@${MODULE}/GET_COMISSION_RETURN_OPTIONS`,
  SET_COMISSION_RETURN_OPTIONS: `@${MODULE}/SET_COMISSION_RETURN_OPTIONS`,

  GET_BANK_ACCOUNT_TYPE_OPTIONS: `@${MODULE}/GET_BANK_ACCOUNT_TYPE_OPTIONS`,
  SET_BANK_ACCOUNT_TYPE_OPTIONS: `@${MODULE}/SET_BANK_ACCOUNT_TYPE_OPTIONS`,

  //FINANCING CAMPAIGN
  GET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION: `@${MODULE}/GET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION`,
  SET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION: `@${MODULE}/SET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION`,
  SET_CURRENT_FINANCIAL_INSTITUTION: `@${MODULE}/SET_CURRENT_FINANCIAL_INSTITUTION`,
  GET_CAMPAIGN_BY_ID: `@${MODULE}/GET_CAMPAIGN_BY_ID`,
  SET_CAMPAIGNS: `@${MODULE}/SET_CAMPAIGNS`,
  SET_CAMPAIGN: `@${MODULE}/SET_CAMPAIGN`,
  CREATE_CAMPAIGN: `@${MODULE}/CREATE_CAMPAIGNS`,
  UPDATE_CAMPAIGN: `@${MODULE}/UPDATE_CAMPAIGNS`,
  DELETE_CAMPAIGN: `@${MODULE}/DELETE_CAMPAIGNS`,
  //FINANCING INSTITUTION
  SET_FINANCIAL_INSTITUTION_CURRENT_TAB: `@${MODULE}/SET_FINANCIAL_INSTITUTION_CURRENT_TAB`,
  POST_NEW_INSTITUTION: `@${MODULE}/POST_NEW_INSTITUTION`,
  GET_BY_INSTITUTION: `@${MODULE}/GET_BY_INSTITUTION`,
  UPDATE_INSTITUTION: `@${MODULE}/UPDATE_INSTITUTION`,
  SET_INSTITUTION: `@${MODULE}/SET_INSTITUTION`,
  //PRINT_OUT_ORDER
  PRINT_OUT_ORDER: `@${MODULE}/PRINT_OUT_ORDER`,
};
