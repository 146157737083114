import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DialogActions } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from 'bit/mobiauto.web-ui-components.components.icon'

import { CloseButton, TitleContainer, styles } from './styles'

const DialogComponent = ({
  open = false,
  title = null,
  content = null,
  actions = null,
  classes,
  handleClose,
}: any) => {
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle className={classes.title}>
          <TitleContainer>
            <div>{title}</div>
            <CloseButton onClick={handleClose}>
              <Icon name="Close" />
            </CloseButton>
          </TitleContainer>
        </DialogTitle>
        <DialogContent className={classes.root}>{content}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </div>
  )
}

export default React.memo(withStyles(styles)(DialogComponent))
