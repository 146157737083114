import { getters, httpCallStatus } from '../acctionOptions';

const MODULE = 'modules/financial/posting';

export default {
  //REDUCERS
  SAVE_POSTING: `@${MODULE}/CREATE_POSTING`,

  UPDATE_POSTING: `@${MODULE}/UPDATE_POSTING`,
  UPDATE_SELECTED_POSTING: `@${MODULE}/UPDATE_SELECTED_POSTING`,
  SELECT_POSTING: `@${MODULE}/SELECT_POSTING`,
  FILTER_POSTINGS: `@${MODULE}/FILTER_POSTINGS`,
  RELOAD_ALL_POSTINGS: `@${MODULE}/RELOAD_ALL_POSTINGS`,
  UPDATE_NEW_POSTING: `@${MODULE}/UPDATE_NEW_POSTING`,

  DEAL_SELECTED: `@{MODULE}/DEAL_SELECTED`,
  CONTACT_SELECTED: `@{MODULE}/CONTACT_SELECTED`,
  NEW_CONTACT_ZIPCODE_INFO: `@{MODULE}/NEW_CONTACT_ZIPCODE_INFO`,

  //SAGAS
  SHOW_POSTING_REQUEST: `@${MODULE}/SHOW_POSTING_${httpCallStatus.REQUEST}`,
  GET_POSTING_REQUEST: `@${MODULE}/GET_POSTING_${httpCallStatus.REQUEST}`,
  GET_POSTING_SUCCESS: `@${MODULE}/GET_POSTING_${httpCallStatus.SUCCESS}`,
  GET_POSTING_ERROR: `@${MODULE}/GET_POSTING_${httpCallStatus.ERROR}`,

  GET_DEALER_CONTACTS_REQUEST: `@${MODULE}/GET_DEALER_CONTACTS${httpCallStatus.ERROR}`,

  POST_POSTING_REQUEST: `@${MODULE}/POST_POSTING_${httpCallStatus.REQUEST}`,
  POST_POSTING_SUCCESS: `@${MODULE}/POST_POSTING_${httpCallStatus.SUCCESS}`,
  POST_POSTING_ERROR: `@${MODULE}/POST_POSTING_${httpCallStatus.ERROR}`,

  PUT_POSTING_REQUEST: `@${MODULE}/PUT_POSTING_${httpCallStatus.REQUEST}`,
  PUT_POSTING_SUCCESS: `@${MODULE}/PUT_POSTING_${httpCallStatus.SUCCESS}`,
  PUT_POSTING_ERROR: `@${MODULE}/PUT_POSTING_${httpCallStatus.ERROR}`,

  SET_IS_LOADING: `@${MODULE}/SET_IS_LOADING`,
};
