import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import lazyRetry from 'helpers/lazyRetry';
import AnnotationDetailDialog from './AnnotationDetailDialog';
import * as moment from 'moment';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
// import RemoveIcon from '@material-ui/icons/DeleteOutline';
import { Tooltip, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ProposalServices from 'services/proposalServices';
import useDealerId from 'hooks/useDealerId';
import { useChatContext } from 'pages/chats/ChatsContext';
const RemoveAnnotation = React.lazy(() =>
  lazyRetry(() => import('components/RemoveAnnotation'))
);

export const MobiSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#00B0AA',
    },
    '&$checked + $track': {
      backgroundColor: '#00B0AA',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ButtonLink = styled.div`
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-align: start;
`;

const AnnotationContent = styled.div`
  border: solid 1px #e0e0e0;
  border-radius: 6px;
  border-left: 4px solid #4300d2;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  padding: 16px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ last }) =>
    !last &&
    css`
      margin-bottom: 16px;
    `}
`;

const AnnotationFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AnnotationTag = styled.div`
  background: #eeeeee;
  border-radius: 90px;
  color: #333333;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  padding: 0 12px;
  margin-right: 5px;
`;

const AuthorText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 125px;
`;

const ReminderIcon = styled(NotificationsNoneIcon)`
  color: #757575;
`;

// const IconButton = styled.div`
//   cursor: pointer;
//   border-radius: 50%;
//   color: #757575;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 32px;
//   height: 32px;
//   :hover {
//     background-color: rgba(0, 0, 0, 0.1);
//   }
// `;

const Annotations = ({ item, last }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    openModalRemoveAnnotation: false,
  });
  const { handleChangeDoneStatusAnnotation } = useChatContext();
  const dealerId = useDealerId();

  // const handleClickOpenModalRemoveAnnotation = event => {
  //   event.stopPropagation();
  //   setState({
  //     itemSelectedRemoveAnnotation: item.id,
  //     openModalRemoveAnnotation: !state.openModalRemoveAnnotation,
  //   });
  // };

  const handleClickCloseModalRemoveAnnotation = () => {
    setState({
      itemSelectedRemoveAnnotation: null,
      openModalRemoveAnnotation: false,
    });
  };

  const handleClickOpenDetailAnnotation = open => {
    setState({
      detailAnnotationOpened: open,
    });
  };
  let reminderDate = null;

  if (item.useReminder) {
    reminderDate = moment(item.reminderDate).format('DD/MM/YYYY HH:mm');
  }

  const putDoneAnnotation = async (annotationId, isDone) => {
    try {
      const { success } = await ProposalServices.changeDoneAnnotation(
        item?.dealerId ?? dealerId,
        item?.proposalId,
        annotationId,
        isDone
      );
      if (success) {
        handleChangeDoneStatusAnnotation(annotationId, isDone);
        return;
      }
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  };

  return (
    <>
      <AnnotationContent last={last}>
        <Tooltip title={item.message}>
          <Text>{item.message}</Text>
        </Tooltip>
        <ButtonLink onClick={() => handleClickOpenDetailAnnotation(true)}>
          Ler tudo
        </ButtonLink>
        <AnnotationFooter>
          <div style={{ display: 'flex' }}>
            {reminderDate && (
              <Tooltip title="Data do lembrete">
                <AnnotationTag>
                  <ReminderIcon />
                  <span style={{ whiteSpace: 'nowrap' }}>{reminderDate}</span>
                </AnnotationTag>
              </Tooltip>
            )}
            {item.userName && (
              <Tooltip title={'Autor da anotação: ' + item.userName}>
                <AnnotationTag>
                  <AuthorText>{item.userName}</AuthorText>
                </AnnotationTag>
              </Tooltip>
            )}
          </div>
          <Tooltip title="Realizado?">
            <div>
              <MobiSwitch
                checked={item?.done}
                onChange={el => {
                  putDoneAnnotation(item?.id, el.target.checked);
                }}
              />
            </div>
          </Tooltip>
          {/* <Tooltip title="Remover anotação">
            <IconButton onClick={handleClickOpenModalRemoveAnnotation}>
              <RemoveIcon />
            </IconButton>
          </Tooltip> */}
        </AnnotationFooter>
      </AnnotationContent>
      <AnnotationDetailDialog
        annotation={item}
        open={state.detailAnnotationOpened}
        handleClose={() => handleClickOpenDetailAnnotation(false)}
      />
      {state.openModalRemoveAnnotation && (
        <RemoveAnnotation
          open={state.openModalRemoveAnnotation}
          item={item}
          itemSelected={state.itemSelectedRemoveAnnotation}
          handleClose={handleClickCloseModalRemoveAnnotation}
        />
      )}
    </>
  );
};

export default Annotations;
