import React from 'react';
import { Box } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import TextField from 'modules/financing/components/atoms/TextField';

const DeliveryInfosComponent = ({
  renderCheckBoxOrderMadeAtTheAssembler,
  deliveryForecast,
  handleChangeDeliveryForecast,
  handleChangeExternalId,
  externalId,
  queueData,
}) => {
  const queueFinished = ['FINISHED', ''].includes(queueData?.status?.value);

  return (
    <Box
      component="div"
      display="grid"
      gridTemplateColumns={
        renderCheckBoxOrderMadeAtTheAssembler ? '0.9fr 0.6fr 1fr' : '1fr 1fr'
      }
    >
      <Box display="flex" marginRight="8px" paddingTop="8px">
        <TextField
          label="ID do pedido (opcional)"
          value={externalId}
          onChange={event => handleChangeExternalId(event.target.value)}
        />
      </Box>

      <Box display="flex" marginLeft="8px" paddingTop="8px">
        <DatePicker
          autoOk
          disablePast
          label="Previsão de entrega"
          inputVariant="outlined"
          value={deliveryForecast}
          invalidDateMessage="Data inválida"
          minDateMessage="Previsão de entrega não pode estar no passado"
          format="DD/MM/YYYY"
          onChange={handleChangeDeliveryForecast}
          disabled={renderCheckBoxOrderMadeAtTheAssembler && queueFinished}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default DeliveryInfosComponent;
