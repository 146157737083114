import { action, observable, decorate } from 'mobx';
import remotedev from 'mobx-remotedev';
import * as MobiFiServices from 'services/mobiFiServices';

const _defaultInitialState = {
  visitsNow: 0,
  mediumTime: 0,
  visits: null,
  allVisitsChart: null,
  newVisits: null,
  recurrentVisits: null,
  totalVisits: 0,
  permanenceDataChat: null,
  dateAllVisitsChart: null,
  frequencyHelper: null,
  heatChartVisits: null,
  heatChartRecurrent: null,
};

class MobiFiStore {
  constructor(initialState) {
    this.setInitialState(initialState || _defaultInitialState);
  }

  setInitialState = ({
    visitsNow,
    mediumTime,
    allVisits,
    newVisits,
    recurrentVisits,
    dateAllVisitsChart,
    totalVisits,
    permanenceDataChat,
    frequencyHelper,
    heatChartVisits,
    heatChartRecurrent,
  }) => {
    this.visitsNow = visitsNow;
    this.mediumTime = mediumTime;
    this.allVisits = allVisits;
    this.newVisits = newVisits;
    this.recurrentVisits = recurrentVisits;
    this.dateAllVisitsChart = dateAllVisitsChart;
    this.totalVisits = totalVisits;
    this.permanenceDataChat = permanenceDataChat;
    this.frequencyHelper = frequencyHelper;
    this.heatChartVisits = heatChartVisits;
    this.heatChartRecurrent = heatChartRecurrent;
  };

  getVisitsNow = async id => {
    const result = await MobiFiServices.getVisitNow(id);
    const { data, success } = result;

    if (success) {
      this.setVisitsNow(data);
    }
  };

  setVisitsNow = data => {
    this.visitsNow = data != null ? data : 0;
  };

  setAllVisits = data => {
    this.allVisitsChart = data.visitHelperList.visits;
    this.dateAllVisitsChart = data.visitHelperList.days;
    this.mediumTime = data.average;
    this.totalVisits = data.visitHelperList.totalVisits;
    this.permanenceDataChat = data.lenghtOfStayHelperList;
    this.frequencyHelper = data.frequencyHelper;
    this.heatChartVisits = data.heatMapChartDTOVisits.options.series;
    this.heatChartRecurrent = data.heatMapChartDTOPassants.options.series;
  };

  setNewVisits = data => {
    this.newVisits = data;
  };

  setRecurrentVisits = data => {
    this.recurrentVisits = data;
  };

  getAllVisits = async (id, dates) => {
    const result = await MobiFiServices.getAllVisits(id, dates);
    const { data, success } = result;
    if (success) {
      this.setAllVisits(data);
    }
  };

  getNewVisits = async id => {
    const result = await MobiFiServices.getNewVisits(id);

    const { data, success } = result;
    if (success) {
      this.setNewVisits(data);
    }
  };

  getReturnVisits = async id => {
    const result = await MobiFiServices.getRecurrentVisits(id);
    const { data, success } = result;
    if (success) {
      this.setRecurrentVisits(data);
    }
  };
}

export default remotedev(
  decorate(MobiFiStore, {
    visitsNow: observable,
    mediumTime: observable,
    newVisits: observable,
    allVisits: observable,
    recurrentVisits: observable,
    totalVisits: observable,
    dateAllVisitsChart: observable,
    allVisitsChart: observable,
    frequencyHelper: observable,
    setAllVisits: action,
    setNewVisits: action,
    setRecurrentVisits: action,
    setInitialState: action,
    getVisitsNow: action,
    getAllVisits: action,
    getNewVisits: action,
    setVisitsNow: action,
    getReturnVisits: action,
  })
);
