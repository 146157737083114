import React from 'react';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import InvisibleDivTpUpload from 'components/InvisibleDivToUpload';
import DocumentCard from './components/DocumentCard';
import DialogUpload from './components/DialogUpload/DialogUpload';
import ThemeButton from 'components/atoms/ThemeButton';
import DeleteDocDialog from './components/DeleteDocDialog/index';
import Wrapper from 'components/Wrapper';
import ItemHeader from '../atoms/ItemHeader';
import { UploadIcon, List, EmptyContainer } from './styles';

const DocumentsHistory = ({
  isFetching,
  isDeleting,
  documents,
  deleteDocument,
  inputFileRef,
  uploadFile,
  isOpenConfirmation,
  manageConfirmationModal,
  handleClickViewDocument,
  handleClickDownloadDocument,
  isOpenDialogUpload,
  handleOpenDialogUpload,
  handleCloseDialogUpload,
  OPTIONS_TYPES_DOC,
  loadingUpload,
  formik,
}) => {
  return (
    <>
      <ItemHeader>Documentos</ItemHeader>
      <div>
        {isFetching ? (
          <List>
            <Progress />
          </List>
        ) : !documents.length ? (
          <EmptyContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {loadingUpload || isDeleting ? (
                <Progress small />
              ) : (
                <Wrapper
                  flex
                  align="center"
                  justify="center"
                  direction="column"
                  gap={24}
                >
                  <Wrapper fontSize={16}>Nenhum documento adicionado.</Wrapper>
                  <ThemeButton onClick={handleOpenDialogUpload}>
                    <UploadIcon />
                    Upload
                  </ThemeButton>
                </Wrapper>
              )}
            </div>
          </EmptyContainer>
        ) : (
          <div
            style={{
              maxHeight: 'calc(100vh - 250px)',
              overflow: 'auto',
            }}
          >
            <List>
              {documents.map(docData => {
                return (
                  <DocumentCard
                    key={docData.id}
                    docData={docData}
                    handleClickDelete={() =>
                      manageConfirmationModal(true, docData.id)
                    }
                    isDeleting={isDeleting}
                    handleClickViewDocument={handleClickViewDocument}
                    handleClickDownloadDocument={handleClickDownloadDocument}
                    optionsTypesDoc={OPTIONS_TYPES_DOC}
                  />
                );
              })}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {loadingUpload || isDeleting ? (
                  <Progress small />
                ) : (
                  <ThemeButton onClick={handleOpenDialogUpload}>
                    <UploadIcon color="white" name="Publish" />
                    Upload
                  </ThemeButton>
                )}
              </div>
            </List>
          </div>
        )}

        <InvisibleDivTpUpload
          inputRef={inputFileRef}
          handleOnChange={uploadFile}
          accept={'image/*,audio/*,.pdf'}
        />

        <DeleteDocDialog
          isOpen={isOpenConfirmation}
          setIsOpen={() => manageConfirmationModal(false, null)}
          deleteDoc={deleteDocument}
        />
      </div>
      <DialogUpload
        openModal={isOpenDialogUpload}
        handleCloseDialogUpload={handleCloseDialogUpload}
        optionsTypesDoc={OPTIONS_TYPES_DOC}
        loadingUpload={loadingUpload}
        formik={formik}
      />
    </>
  );
};

export default DocumentsHistory;
