import { IconButton as IconButtonMaterial } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const BookedVehicleContainer = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const BookedVehicleName = styled.h3`
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  margin: 4px 0 8px 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const IconButton = styled(IconButtonMaterial)`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;
