/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import inventoryServices from 'services/inventoryServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

const useGetOrderDetail = (
  dealerId,
  orderId,
  callback = () => {
    return;
  }
) => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: 'get_me_infos',
    queryFn: () => inventoryServices.getOrderDetail(dealerId, orderId),
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess(props: { success: boolean; data: any }) {
      if (!props?.success) {
        callback();
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            props?.data ?? 'Ocorreu um erro ao consultar informações do pedido.'
          )
        );
        return [];
      }
      return props.data;
    },
    onError() {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao consultar informações do pedido.'
        )
      );
    },
  });
};

export default useGetOrderDetail;
