import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Wrapper from 'components/Wrapper';
import { ConfirmButton } from './styles';

const ConfirmButtonComponent = ({ children, ...props }): JSX.Element => {
  const { loading } = props;

  return (
    <ConfirmButton {...props}>
      {loading ? (
        <CircularProgress style={{ color: 'white' }} />
      ) : (
        <Wrapper flex>{children}</Wrapper>
      )}
    </ConfirmButton>
  );
};

export default ConfirmButtonComponent;
