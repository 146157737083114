import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import Wrapper from 'components/Wrapper';
import DealerServices from 'services/dealerServices';
import SearchServices from 'services/searchServices';
import ProposalServices from 'services/proposalServices';
import { useChatContext } from 'pages/chats/ChatsContext';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ConfirmButton from 'components/atoms/ConfirmButton';
import ChangeDealerComponent from './ChangeDealerComponent';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

const ChangeDealerContainer = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { currentProposal, getCurrentProposal }: any = useChatContext();

  const [state, setState] = useState({
    dataTable: [],
    dataTableAux: [],
    states: [],
    search: '',
    selectedStore: null,
    isLoading: false,
    stateSelected: null,
    citySelected: null,
    brandSelected: null,
  });

  const handleFilter = () => {
    let datafiltered: any = state?.dataTableAux;
    if (state?.stateSelected?.value?.length) {
      datafiltered = datafiltered?.filter(
        (el: any) => el?.state === state?.stateSelected?.value
      );
    }
    if (state?.citySelected?.label?.length) {
      datafiltered = datafiltered?.filter(
        (el: any) => el?.city === state?.citySelected?.label
      );
    }
    if (state?.brandSelected?.label?.length) {
      datafiltered = datafiltered?.filter(
        (el: any) => el?.makeName === state?.brandSelected?.label
      );
    }
    if (state?.search?.length) {
      datafiltered = datafiltered?.filter((el: any) =>
        el?.name?.toLowerCase()?.includes(state?.search?.toLowerCase())
      );
    }
    setState(prevState => ({
      ...prevState,
      dataTable: datafiltered,
    }));
  };

  const onChangeSearch = event => {
    setState(prevState => ({
      ...prevState,
      search: event?.target?.value,
    }));
  };

  const getDealersSameGroup = async () => {
    try {
      const {
        data,
        success,
      }: any = await DealerServices.getDealersSameMainGroup(
        currentProposal.dealerId
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          dataTable: data?.content,
          dataTableAux: data?.content,
          selectedStore: currentProposal.dealerId,
        }));
        return;
      }
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    }
  };

  const getStates = async () => {
    try {
      const { data, success }: any = await SearchServices.getStates();
      if (success) {
        setState(prevState => ({
          ...prevState,
          states: data,
        }));
        return;
      }
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    }
  };

  const changeDealerInProposal = async () => {
    try {
      setState(prevState => ({
        ...prevState,
        isLoading: true,
      }));

      const { success }: any = await ProposalServices.setDealer(
        currentProposal.dealerId,
        currentProposal.id,
        state?.selectedStore
      );

      if (success) {
        handleClose(state?.selectedStore);
        getCurrentProposal();
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Loja alterada com sucesso!'
          )
        );
        return;
      }
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    } catch (e) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.'
        )
      );
    } finally {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
      }));

      return;
    }
  };

  const ActionsButtons = () => {
    return (
      <Wrapper flex width={'100%'} justify={'end'} gap={10}>
        <Button
          size="medium"
          variant="text"
          onClick={handleClose}
          style={{ textTransform: 'none', padding: '8px 30px' }}
        >
          Cancelar
        </Button>

        <ConfirmButton
          onClick={changeDealerInProposal}
          disabled={state?.isLoading}
          style={{ width: '130px' }}
        >
          {state?.isLoading ? (
            <CircularProgress color="inherit" size="30px" />
          ) : (
            'Concluir'
          )}
        </ConfirmButton>
      </Wrapper>
    );
  };

  const handleChangeStore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({
      ...prevState,
      selectedStore: event?.target?.value,
    }));
  };

  useEffect(() => {
    getDealersSameGroup();
    getStates();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [
    state?.search,
    state?.stateSelected,
    state?.citySelected,
    state?.brandSelected,
  ]);

  const viewComponentProps = {
    onChangeSearch,
    handleClose,
    open,
    ActionsButtons,
    handleChangeStore,
    state,
    setState,
  };

  return <ChangeDealerComponent {...viewComponentProps} />;
};

export default ChangeDealerContainer;
