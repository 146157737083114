import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import OrderServices from 'modules/order/services/OrderServices';
import ActionCreators from 'modules/order/store/reducers/actionCreators';

const useCreateFinancialAdvanced = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentProposal } = useSelector(state => state.proposalStore);

  return useMutation<any, any, any>(
    'create_financial_advanced',
    () => {
      return OrderServices.createFinancialAdvanced(
        currentProposal.dealerId,
        currentProposal.proposalId
      );
    },
    {
      onError: () => {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro ao tentar emitir o adiantamento.'
          )
        );
      },
      onSuccess(data) {
        if (data?.success) {
          queryClient.invalidateQueries('get_financial_advanced');
          dispatch(ActionCreators.getOrderById());
          dispatch(
            UIActionCreators.snackbarManagement(
              'success',
              'Adiantamento emitido com sucesso'
            )
          );
          return data;
        }
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            data?.data ?? 'Ocorreu um erro ao tentar emitir o adiantamento.'
          )
        );
        return null;
      },
    }
  );
};

export default useCreateFinancialAdvanced;
