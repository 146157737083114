import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import AddBlockedComponent from './AddBlockedComponent';
import bookVehicleService from 'modules/store-inventory/services/InventorySearchServices';

const AddBlockedContainer = ({
  dealerId,
  dealId,
  open,
  onClose,
  callbackOnSuccess,
}) => {
  const dispatch = useDispatch();
  const [blockedState, setBlockedState] = useState({
    blockedAt: null,
    reason: null,
  });
  const [isLoading, setLoading] = useState(false);

  const addBlockedVehicle = async () => {
    try {
      setLoading(true);
      const response: any = await bookVehicleService.addBlockVehicle(
        dealerId,
        dealId,
        blockedState.reason,
        blockedState.blockedAt
      );
      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Veículo bloqueado com sucesso.'
          )
        );
        setLoading(false);
        callbackOnSuccess();
        onClose();
        return;
      }
      setLoading(false);
      dispatch(UIActionCreators.snackbarManagement('error', response.data));
    } catch {
      setLoading(false);
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  };

  const handleChangeValue = event => {
    const { name, value } = event.target;
    setBlockedState(prevState => ({ ...prevState, [name]: value }));
  };

  const componentProps = {
    open,
    onClose,
    blockedAt: blockedState.blockedAt,
    reason: blockedState.reason,
    handleChangeValue,
    addBlockedVehicle,
    isLoading: isLoading,
  };

  return <AddBlockedComponent {...componentProps} />;
};

export default memo(AddBlockedContainer);
