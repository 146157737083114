import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import Close from '@material-ui/icons/Close';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';
import DateTimeHightLight from 'components/atoms/DateTimeHighLight/DateTimeHighLight';
import getInitialsName from 'helpers/getInitialsName';
import ThemeButton from 'components/atoms/ThemeButton';
import VehicleItem from 'components/VehicleItem/VehicleItem';
import { userTypes } from 'types/LeadsTypes';
import PROFILES from 'constants/profiles';
import useGetOrderDetail from './hooks/useGetOrderDetail';
import {
  Divider,
  BookInfo,
  SubTitle,
  ListLoading,
  Avatar,
  DialogActionsContainer,
  DialogHeader,
  VericalDivider,
  // DefaultButton,
} from './styles';

export default function OrderDetailDialog(props) {
  const { onClose, open, currentDeal } = props;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { currentDealer, userStore } = useSelector(state => ({
    userStore: state.userStore,
    ...state.dealerStore,
  }));

  const { data: response, isLoading, isFetching }: any = useGetOrderDetail(
    currentDealer.id,
    currentDeal.orderId,
    onClose
  );

  const isManagerOrOwner = () => {
    if (userStore?.userProfile === PROFILES.CONSULTOR) {
      return true;
    }
    const currentUser = currentDealer.users.find(
      item => item.userSite.email === userStore.userSite.email
    );
    return [userTypes.MANAGER, userTypes.OWNER].includes(currentUser.userType);
  };

  const goToDetails = () => {
    dispatch(
      proposalCreators.setProposalModalOpen(true, {
        dealerId: response?.data.dealer.id || currentDealer.id,
        proposalId: response?.data.order.proposalId || currentDeal.proposalId,
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    return () => queryClient.removeQueries('get_me_infos');
  }, []);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <DialogHeader>
          <div>Informações do pedido</div>
          <IconButton aria-label="Close" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogHeader>
      </DialogTitle>

      <DialogContent>
        {isLoading || isFetching ? (
          <ListLoading>
            <CircularProgress />
            <span>Carregando dados do pedido...</span>
          </ListLoading>
        ) : (
          <>
            <SubTitle>Veículo</SubTitle>
            {response?.data?.vehicle && (
              <VehicleItem
                vehicle={{
                  ...response?.data?.vehicle,
                  ...currentDeal,
                  color: currentDeal?.exteriorColor || currentDeal?.colorName,
                }}
                isSelectable={false}
                showBooked={false}
              />
            )}
            <Divider />
            <SubTitle>Pedido</SubTitle>
            <BookInfo>
              Validade do pedido até o dia &nbsp;
              <DateTimeHightLight date={response?.data?.order?.validity} />
            </BookInfo>
            <Divider />
            <SubTitle>Loja</SubTitle>
            <BookInfo>{response?.data?.dealer?.name}</BookInfo>
            <Divider />
            <SubTitle>Cliente</SubTitle>
            <BookInfo>
              <Avatar>{getInitialsName(response?.data?.client?.name)}</Avatar>
              <span>{response?.data?.client?.name}</span>
            </BookInfo>
            <Divider />
            <Grid container>
              <Grid item xs={5}>
                <SubTitle>Vendedor</SubTitle>
                <BookInfo>
                  <Avatar>
                    {getInitialsName(response?.data?.seller?.name)}
                  </Avatar>
                  <span>{response?.data?.seller?.name}</span>
                </BookInfo>
              </Grid>
              {response?.data?.seller?.managerName && (
                <>
                  <Grid item xs={1}>
                    <VericalDivider />
                  </Grid>
                  <Grid item xs={6}>
                    <SubTitle>Gestor responsável</SubTitle>
                    <BookInfo>
                      <Avatar>
                        {getInitialsName(response?.data?.seller?.managerName)}
                      </Avatar>
                      <span>{response?.data?.seller?.managerName}</span>
                    </BookInfo>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider end />
          </>
        )}
      </DialogContent>
      {Number(response?.data?.dealer?.id) === Number(currentDealer?.id) &&
        isManagerOrOwner() && (
          <DialogActionsContainer>
            <ThemeButton onClick={goToDetails}>Abrir proposta</ThemeButton>
          </DialogActionsContainer>
        )}
    </Dialog>
  );
}
