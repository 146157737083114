import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'DealerGroupServices';

export default class DealerGroupServices {
  static getSalesAssistant = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer-group/${dealerId}/v1.0/sales-assistant${
          proposalId ? `?proposalId=${proposalId}` : ''
        }`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getSalesAssistant()`));
  };

  static getSalesAssistantOnShowroom = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer/${dealerId}/proposals/${proposalId}/v1.0/sales-assistant`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.getSalesAssistantOnShowroom()`)
      );
  };

  static getSellers = (dealerId, proposalId) => {
    return request
      .get(
        `${domain}/dealer-group/${dealerId}/v1.0/sellers${
          proposalId ? `?proposalId=${proposalId}` : ''
        }`
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getSellers()`));
  };

  static getDepartmentsByGrupId = groupId => {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/departments`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getDepartments()`));
  };

  static getUsers = groupId => {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/users`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getUsers()`));
  };

  static getStoresActionPlan = groupId => {
    return request
      .get(`${domain}/dealer-group/${groupId}/v1.0/action-plans`, {})
      .then(buildHTTPResponseHandler(`${serviceName}.getStoresActionPlan()`));
  };
}
