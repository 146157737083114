import styled from 'styled-components';

export const ContainerTags = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding-bottom: 20px;
`;

export const ContainerFilters = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 8px;
  margin-bottom: 21px;
`;

export const ButtonAddNewTag = styled.div`
  display: flex;
  justify-content: end;
  color: #4443bc;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;
  :hover {
    cursor: pointer;
  }
`;

export const ButtonViewMore = styled.div`
  color: #4443bc;
  font-weight: 400;
  font-size: 14px;

  :hover {
    cursor: pointer;
  }
`;
