import request from 'helpers/request';
import EnvVar from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVar('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'cloudMessageServices';

export const postCloudMessageToken = token => {
  return request
    .post(`${domain}/dealer/v1.0/registerpushdevice`, {
      app: 'PAINEL_DIGITAL',
      platform: 'WEB',
      registration: token,
    })
    .then(buildHTTPResponseHandler(`${serviceName}.postCloudMessageToken()`));
};
