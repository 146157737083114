import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import useSnackBar from 'hooks/useSnackBar';

import {
  GetAllInsuranceQuoteByProposalIdQ,
  GetAllInsuranceQuoteByProposalIdR,
  InsuranceQuoteData,
  InsuranceQuoteStatus,
} from 'modules/insureQuotes/services/dto';
import {
  useGetAllInsuranceQuoteByProposalId,
  useMarkSelectedInsuranceQuote,
} from 'modules/insureQuotes/services/queries';

import { AlertSync } from './AlertSync';
import { QuoteItem } from './QuoteItem';

import * as S from './styles';

interface QuotesGroups {
  quotes: InsuranceQuoteData[];
  vehicleName: string;
}

interface Props {
  orientation?: 'horizontal' | 'vertical';
}

export function QuotesList({ orientation = 'vertical' }: Props) {
  const [quotesGroups, setQuotesGroups] = useState<QuotesGroups[]>([]);
  const [selectedQuoteId, setSelectedQuoteId] = useState<number | null>(null);

  const { currentDealer, proposalId } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
    proposalId: state.proposalStore.currentProposal.proposalId,
  }));

  const { success } = useSnackBar();

  const queryParams: GetAllInsuranceQuoteByProposalIdQ = useMemo(
    () => ({ dealerId: currentDealer.id, proposalId }),
    [currentDealer, proposalId]
  );

  const {
    data: dataAllInsuranceQuotes,
    isLoading: loadingAllInsuranceQuotes,
    isFetching: fetchingAllInsuranceQuotes,
    isRefetching: refetchingAllInsuranceQuotes,
  } = useGetAllInsuranceQuoteByProposalId(queryParams);

  const loadingInsuranceQuotes =
    loadingAllInsuranceQuotes ||
    fetchingAllInsuranceQuotes ||
    refetchingAllInsuranceQuotes;

  const {
    mutateAsync: selectInsurance,
    isLoading: loadingSelectInsurance,
  } = useMarkSelectedInsuranceQuote({
    onSuccess: selectedId => {
      setSelectedQuoteId((selectedId as unknown) as number);
      return success('Cotação selecionada com sucesso.');
    },
  });

  const groupArrayByCustomKey = <T, K extends keyof T>({
    array,
    key,
  }: {
    array: T[];
    key: K;
  }) => {
    return array.reduce((result, currentItem) => {
      const groupKey = currentItem[key] ? String(currentItem[key]) : '-';

      if (!result[groupKey]) result[groupKey] = [];
      result[groupKey].push(currentItem);

      return result;
    }, {} as { [key: string]: T[] });
  };

  const handleOnSelectInsurance = useCallback(
    async (insuranceId: number) => {
      await selectInsurance({
        dealerId: currentDealer.id,
        insuranceId,
        proposalId,
      });
    },
    [currentDealer, proposalId]
  );

  const hasDesynchronizedQuotesOnGroup = (group: QuotesGroups) => {
    return group.quotes?.some(
      ({ status }) => status === InsuranceQuoteStatus.New
    );
  };

  const allQuotesAreNew = (groups: QuotesGroups[]) => {
    return groups?.every(group => {
      return group.quotes?.every(
        ({ status }) => status === InsuranceQuoteStatus.New
      );
    });
  };

  const getInitSelectedQuote = (values: GetAllInsuranceQuoteByProposalIdR) => {
    const initSelectedQuote = values.find(value => value.isSelected);
    return initSelectedQuote ? initSelectedQuote.id : null;
  };

  const formatInsuranceQuotes = (
    values: GetAllInsuranceQuoteByProposalIdR
  ): QuotesGroups[] => {
    const groupedQuotesByVehicle = groupArrayByCustomKey({
      array: values,
      key: 'vehicle',
    });

    const formattedGroups: QuotesGroups[] = Object.entries(
      groupedQuotesByVehicle
    ).map(([groupKey, values]) => {
      return { vehicleName: groupKey, quotes: values };
    });

    return formattedGroups;
  };

  const hasDesynchronizedQuotes = quotesGroups?.some(
    hasDesynchronizedQuotesOnGroup
  );

  const hasQuoteGroups = quotesGroups?.length > 0;
  const hasValidQuotes = hasQuoteGroups && !allQuotesAreNew(quotesGroups);

  const showSyncAlert = hasQuoteGroups && hasDesynchronizedQuotes;

  useEffect(() => {
    if (!dataAllInsuranceQuotes) return;

    const formattedGroups = formatInsuranceQuotes(dataAllInsuranceQuotes);
    setQuotesGroups(formattedGroups);

    const initSelected = getInitSelectedQuote(dataAllInsuranceQuotes);
    setSelectedQuoteId(initSelected);
  }, [dataAllInsuranceQuotes]);

  return (
    <Fragment>
      <S.Container>
        {loadingInsuranceQuotes &&
          [0, 1, 2].map(skeleton => (
            <Skeleton key={skeleton} variant="rect" height={120} width="100%" />
          ))}
        {!loadingInsuranceQuotes && (
          <Fragment>
            {showSyncAlert && <AlertSync />}
            {!showSyncAlert && !hasValidQuotes && (
              <S.EmptyInfo>Não há histórico de cotações.</S.EmptyInfo>
            )}
            {hasValidQuotes && (
              <S.Items data-orientation={orientation}>
                {quotesGroups.map(group => {
                  const groupHasQuotes = group?.quotes?.length > 0;
                  const loadingResults = hasDesynchronizedQuotesOnGroup(group);
                  return (
                    <S.Item
                      key={group.vehicleName}
                      data-orientation={orientation}
                    >
                      <S.ItemTitle>{group.vehicleName}</S.ItemTitle>
                      <S.Accordion>
                        <S.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <p>Cotações realizadas</p>
                          {loadingResults && (
                            <S.StatusChip
                              size="small"
                              label="Carregando resultados"
                            />
                          )}
                        </S.AccordionSummary>
                        <S.AccordionDetails>
                          {!groupHasQuotes && (
                            <S.EmptyInfo>
                              Nenhuma cotação disponível para esse veículo.
                            </S.EmptyInfo>
                          )}
                          {groupHasQuotes && (
                            <S.ItemContent>
                              <S.ItemSubtitle>
                                Selecione uma cotação caso deseje priorizá-la:
                              </S.ItemSubtitle>
                              <S.ItemContent>
                                {group.quotes.map((quote, quoteIndex) => (
                                  <QuoteItem
                                    key={quote.id}
                                    quote={quote}
                                    quoteIndex={quoteIndex}
                                    isLoading={loadingSelectInsurance}
                                    onSelectQuote={selectedId => {
                                      return handleOnSelectInsurance(
                                        selectedId
                                      );
                                    }}
                                    {...(selectedQuoteId !== null && {
                                      selectedQuoteId,
                                    })}
                                  />
                                ))}
                              </S.ItemContent>
                            </S.ItemContent>
                          )}
                        </S.AccordionDetails>
                      </S.Accordion>
                    </S.Item>
                  );
                })}
              </S.Items>
            )}
          </Fragment>
        )}
      </S.Container>
    </Fragment>
  );
}
