/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { GridOverlay } from '@material-ui/data-grid';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';

import ThemeButton from 'components/atoms/ThemeButton';
import TableDataGrid from 'components/atoms/TableDataGrid';
import Wrapper from 'components/Wrapper';
import formatAmount from 'helpers/formatAmount';

import useGetFinancialAdvanced from './hooks/useGetFinancialAdvanced';
import useCreateFinancialAdvanced from './hooks/useCreateFinancialAdvanced';

const PAYMENT_STATUS = {
  EMITTED: { label: 'Emitido', bg: '#07B2FD', color: '#FFF' },
  NOT_EMITTED: { label: 'Não emitido', bg: '#BDBDBD', color: '#FFF' },
  RECEIVED: { label: 'Recebido', bg: '#00B4AA', color: '#FFF' },
};

const ChipCustom = styled.div`
  color: ${({ color }) => color};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  background-color: ${({ bg }) => bg};
  padding: 0px 16px;
  border-radius: 100px;
  height: 32px;
  display: flex;
  align-items: center;
`;

const headCells = [
  {
    field: 'status',
    headerName: 'Status',
    width: 170,
    headerClassName: 'table-header',
    cellClassName: 'table-body',
    renderCell: params => {
      return (
        <ChipCustom
          color={PAYMENT_STATUS[params.value].color}
          bg={PAYMENT_STATUS[params.value].bg}
        >
          {PAYMENT_STATUS[params.value].label}
        </ChipCustom>
      );
    },
  },
  {
    field: 'date',
    headerName: 'Data',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'table-body',
    valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'dueDate',
    headerName: 'Vencimento',
    width: 140,
    headerClassName: 'table-header',
    cellClassName: 'table-body',
    valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'value',
    headerName: 'Valor',
    width: 160,
    headerClassName: 'table-header',
    cellClassName: 'table-body',
    valueFormatter: ({ value }) =>
      `${formatAmount(Number(value), 'PT-BR', 'R$', true)}`,
  },
  {
    field: 'paymentMethod',
    headerName: 'Meio de pagamento',
    flex: 1,
    headerClassName: 'table-header',
    cellClassName: 'table-body',
  },
  {
    field: 'installmentsType',
    headerName: 'Tipo de pagamento',
    flex: 1,
    headerClassName: 'table-header',
    cellClassName: 'table-body',
  },
];

export default function FinancialAdvance() {
  const {
    refetch: getFinancialAdvanced,
    data: dataFinancialAdvanced,
    isFetching: fetchingFinancialAdvanced,
    isLoading: loadingFinancialAdvanced,
  } = useGetFinancialAdvanced();

  const {
    mutate: createFinancialAdvanced,
    isLoading: isLoadingCreateFinancialAdvanced,
  } = useCreateFinancialAdvanced();

  const isLoadingFinancialAdvanced =
    fetchingFinancialAdvanced || loadingFinancialAdvanced;

  const buildRows = (paymentAdvancedData = []) => {
    return paymentAdvancedData?.map(row => ({
      id: row.id,
      status: row.paymentDmsStatus,
      dueDate: row.dueDate,
      value: row.value,
      paymentMethod: row.paymentType.name,
      installmentsType: row.paymentType.installmentsType,
    }));
  };

  useEffect(() => {
    getFinancialAdvanced();
  }, []);

  return (
    <Wrapper
      flex
      backgroundColor="#fff"
      direction="column"
      width="100%"
      style={{ flex: 1 }}
    >
      <TableDataGrid
        rows={buildRows(dataFinancialAdvanced?.data)}
        columns={headCells}
        pageSize={10}
        loading={isLoadingFinancialAdvanced}
        components={{
          NoRowsOverlay: () => (
            <GridOverlay>
              <div
                style={{
                  position: 'absolute',
                  top: 55,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Wrapper color="#424242" align="center">
                  Nenhum adiantamento encontrado.
                </Wrapper>
              </div>
            </GridOverlay>
          ),
          LoadingOverlay: () => (
            <div
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: 200,
              }}
            >
              <Progress large />
            </div>
          ),
        }}
      />
      <Wrapper flex p={16} style={{ flex: 1, alignSelf: 'center' }}>
        <ThemeButton
          loading={isLoadingCreateFinancialAdvanced}
          disabled={isLoadingCreateFinancialAdvanced}
          onClick={createFinancialAdvanced}
        >
          Emitir adiantamento
        </ThemeButton>
      </Wrapper>
    </Wrapper>
  );
}
