import React from 'react';
import Page from 'components/Page';
import SubHeader from '../components/SubHeaderOrder';
import Chat from '../components/Chat';
import SideBar from 'pages/chats/ChatShowroom/components/SideBar';
import proposalIsFinished from 'pages/chats/helpers/proposalIsFinished';
import { Container, Content } from './styles';

const ChatShowroomComponent = ({ currentProposal }) => (
  <Page withoutPadding>
    <Container isFinished={proposalIsFinished(currentProposal)}>
      <SubHeader currentProposalStatus={currentProposal.status} />
      <Content>
        <Chat />
        <SideBar />
      </Content>
    </Container>
  </Page>
);

export default ChatShowroomComponent;
