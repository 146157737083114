import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

const Line = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
`;

export const LineSpaceBetween = styled(Line)`
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.div`
  font-family: Poppins Bold;
  font-weight: bold;
  line-height: 48px;
  color: ${Colors.gray.dark};
`;

export const styles = {
  root: { padding: '0px 16px' },
  zeroPadding: { padding: '0px 0px' },
  rootActions: {
    overflow: 'hidden',
    justifyContent: 'center',
    margin: 16,
    padding: 0,
  },
};
