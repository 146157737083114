import styled from 'styled-components';

import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const BookButton = styled.div`
  font-family: Poppins Medium;
  text-transform: initial;
  background-color: transparent;
  border: none;
  color: ${Colors.primary.medium};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 8px;
`;
