import ActionTypes from './actionTypes';

export default {
  //GET_TABS
  getTabs: function() {
    return { type: ActionTypes.GET_TABS, payload: {} };
  },
  setTabs: function(tabs: any) {
    return {
      type: ActionTypes.SET_TABS,
      payload: { tabs },
    };
  },
  setCurrentTab: function(currentTab: string) {
    return {
      type: ActionTypes.SET_CURRENT_TAB,
      payload: { currentTab },
    };
  },
  getProviderByType: function(type: string) {
    return { type: ActionTypes.GET_PROVIDER_BY_TYPE, payload: { type } };
  },
  setProviderDataSuccess: function(providers: any) {
    return {
      type: ActionTypes.SET_PROVIDER_DATA_SUCCEDED,
      payload: { providers },
    };
  },
  setProviderStockError: function(error: any) {
    return { type: ActionTypes.SET_PROVIDER_STOCK_ERROR, payload: { error } };
  },
  setProviderStockLoading: function() {
    return { type: ActionTypes.SET_PROVIDER_STOCK_LOADING };
  },
  //CONNECT
  connectProvider: function(providerId: number | string, credentialsDto: any) {
    return {
      type: ActionTypes.CONNECT_PROVIDER,
      payload: { providerId, credentialsDto },
    };
  },
  setConnectionStatus: function(
    connectHasSuccess = false,
    connectHasFailed = false,
    connectIsLoading = false,
    data: any = null,
    connectUrl: string | null = null
  ) {
    return {
      type: ActionTypes.CONNECT_SET_STATUS,
      payload: {
        connectHasSuccess,
        connectHasFailed,
        connectIsLoading,
        data,
        connectUrl,
      },
    };
  },
  //RECONNECT
  reconnectProvider: function(
    providerId: string | number,
    credentialsDto: any
  ) {
    return {
      type: ActionTypes.RECONNECT_PROVIDER,
      payload: { providerId, credentialsDto },
    };
  },
  //DISCONNECT
  disconnectProvider: function(providerId: number | string) {
    return {
      type: ActionTypes.DISCONNECT_PROVIDER,
      payload: { providerId },
    };
  },
  //SYNC
  syncPlan: function(providerId: number) {
    return {
      type: ActionTypes.SYNC_PLAN,
      payload: { providerId },
    };
  },
  syncPlanLoading: function(isLoading: boolean) {
    return {
      type: ActionTypes.SYNC_PLAN_LOADING,
      payload: { isLoading },
    };
  },
  //CHANGE_ATIVATION
  changeActivationStatus: function(providerId: number, status) {
    return {
      type: ActionTypes.CHANGE_ACTIVATION_STATUS,
      payload: { providerId, status },
    };
  },

  changeProviderSwitchStock: function(providerId: number) {
    return {
      type: ActionTypes.CHANGE_SWITCH_STOCK,
      payload: { providerId },
    };
  },
};
