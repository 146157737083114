import styled from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';
export const Container = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TitleForm = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #757575;
  margin-bottom: 8px;
`;

export const BackButton = styled(Button)`
  background: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  color: #424242 !important;
  text-transform: inherit !important;
  margin-right: 8px !important;
`;

export const NextButton = styled(Button)`
  background: ${({ theme }) => `${theme.primary}`} !important;
  color: #fff !important;
  text-transform: inherit !important;
`;
