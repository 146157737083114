import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ProposalIdContainer from 'pages/chats/components/ProposalIdContainer';
import Chip from 'components/atoms/Chip';
import getImage from 'components/getImage';
import {
  PageHeader,
  BackFab,
  ProposalTitle,
  OrderTitleContainer,
} from './styles';
import { useChatContext } from 'pages/chats/ChatsContext';
import ProposalsTags from 'pages/proposals/tabs/Kanban/components/ProposalsTags';
import { PreAnalysisChip } from 'pages/conversations/components';

const Header = ({
  error,
  onModal,
  handleOnClickClose,
  handleOnClickBack,
  currentProposal,
  extraComponent = null,
}) => {
  const { updateCurrentProposal, removeProposalTag }: any = useChatContext();
  const { originOptions }: any = useSelector(state => state.leads);

  const currentOriginLabel = originOptions?.find(
    item => item?.value === currentProposal?.origin
  )?.label;

  if (error) {
    return (
      <PageHeader>
        <OrderTitleContainer>
          <Tooltip title="Voltar para lista de propostas">
            <BackFab onClick={onModal ? handleOnClickClose : handleOnClickBack}>
              {onModal ? <CloseIcon /> : <ArrowBackIcon />}
            </BackFab>
          </Tooltip>
        </OrderTitleContainer>
      </PageHeader>
    );
  }

  return (
    <>
      <PageHeader>
        <OrderTitleContainer>
          <Tooltip title="Voltar para lista de propostas">
            <BackFab onClick={onModal ? handleOnClickClose : handleOnClickBack}>
              {onModal ? <CloseIcon /> : <ArrowBackIcon />}
            </BackFab>
          </Tooltip>
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            gridGap="8px"
          >
            <Box
              component="div"
              display="flex"
              gridGap="16px"
              alignItems="center"
            >
              <ProposalTitle>
                {currentProposal.clientUserName ?? 'Cliente'}
              </ProposalTitle>
              {!!currentOriginLabel && (
                <Chip>
                  <Tooltip title={`Origem: ${currentOriginLabel}`}>
                    <Box component="span" color="#1C1B1F">
                      {currentOriginLabel}
                    </Box>
                  </Tooltip>
                </Chip>
              )}

              {currentProposal?.providers?.[0] && (
                <Tooltip
                  title={`Canal: ${currentProposal?.providers?.[0]?.name}`}
                >
                  <Box component="div" display="flex" alignItems="center">
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={
                        getImage(
                          {
                            imageId:
                              currentProposal?.providers?.[0].image?.imageId,
                            imageVcode:
                              currentProposal?.providers?.[0].image?.imageVcode,
                          },
                          24,
                          24
                        ) || require('assets/default-image.svg')
                      }
                    />

                    <PreAnalysisChip spacing />
                  </Box>
                </Tooltip>
              )}
            </Box>
            <ProposalIdContainer proposalId={currentProposal.id} />
            <ProposalsTags
              tags={currentProposal?.tags}
              proposalId={currentProposal.id}
              proposalDealerId={currentProposal.dealerId}
              callbackOnSuccess={newTags =>
                updateCurrentProposal('tags', [...newTags])
              }
              handleClickDeleteTag={currentTag => removeProposalTag(currentTag)}
            />
            {/* <Box
              component="div"
              display="flex"
              gridGap="16px"
              alignItems="center"
            >
              <Tooltip title="Tempo desde que o lead foi recebido">
                <Box
                  component="div"
                  display="flex"
                  border="1px solid #757575"
                  borderRadius="100px"
                  gridGap="4px"
                  alignItems="center"
                  p={'0px 16px'}
                  style={{ fontFamily: 'Poppins Medium' }}
                >
                  <Box component="div" display="flex" color="#4443BC">
                    <TimerIcon style={{ fontSize: '1.2rem' }} />
                  </Box>
                  Tempo de atendimento:{' '}
                  {moment(currentProposal?.createdAt)
                    .startOf('hour')
                    .fromNow()}
                </Box>
              </Tooltip>
              <Box
                component="span"
                fontSize="12px"
                fontWeight="400"
                color="#757575"
              >
                Criado em:{' '}
                {returnDateHourFormatedString(currentProposal?.createdAt)}
              </Box>
            </Box> */}
          </Box>
        </OrderTitleContainer>
        {extraComponent}
      </PageHeader>
    </>
  );
};

export default Header;
