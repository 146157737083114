import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export const InfosContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 50%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const CardContainer = styled(Card)`
  flex-grow: 1;
  display: flex;
  background: ${({ canceled }) =>
    canceled === null
      ? '#fff'
      : canceled
      ? `${Colors.dangerous.light}2F`
      : `${Colors.success.light}2F`} !important;
  border: 1px solid;
  border-color: ${({ canceled }) =>
    canceled === null
      ? '#b6b6b6'
      : canceled
      ? `${Colors.dangerous.light}`
      : `${Colors.success.light}`} !important;
  border-radius: 4px;
  justify-content: space-between;
  align-items: space-between;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const styles = () => ({
  cover: {
    minWidth: '122px',
    height: '85px',
    display: 'flex',
    justifyContent: 'flex-end',
    // marginLeft: '10px',
    alignItems: 'flex-end',
    // objectFit: 'contain',
    backgroundSize: 'contain',
  },

  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '12px',
    padding: '8px',
  },
  modelName: {
    fontFamily: 'Poppins Regular',
    fontSsize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'start',
  },
  spec: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '12px',
    height: '20px',
  },
  types: {
    fontSize: '12px',
    fontFamily: 'Poppins Regular',
    color: `${Colors.gray.medium}`,
    background: '#F5F5F5',
    borderRadius: 20,
    padding: '4px 8px',
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  typeError: {
    fontSize: '8px',
    fontFamily: 'Poppins Regular',
    color: '#FFF',
    background: `${Colors.dangerous.light}`,
    borderRadius: 20,
    padding: '2px 4px',
  },
  typeSuccess: {
    fontSize: '8px',
    fontFamily: 'Poppins Regular',
    color: '#FFF',
    background: `${Colors.success.light}`,
    borderRadius: 20,
    padding: '2px 4px',
  },
  bookedAt: {
    fontSize: '12px',
    fontFamily: 'Poppins Regular',
    color: `#4443BC`,
    background: '#E9EAF9',
    borderRadius: 20,
    padding: '4px 8px',
    whiteSpace: 'nowrap',
  },
});
