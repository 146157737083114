import React, { Fragment } from 'react';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import * as S from '../../styles';
import { FilterOption } from '../../interfaces';

const ActiveFilters = ({
  hasSelectedFilters,
  selectedFilters,
  handleDeleteOneFilter,
  handleDeleteAllFilters,
  hiddenTitle = false,
}: {
  hasSelectedFilters: boolean;
  selectedFilters: FilterOption[];
  handleDeleteOneFilter: (filter: FilterOption) => void;
  handleDeleteAllFilters: () => void;
  hiddenTitle?: boolean;
}) => {
  return (
    <Fragment>
      {hasSelectedFilters && (
        <S.SelectedContainer className="active-filters">
          {!hiddenTitle && <S.SelectedTitle>Filtros ativos</S.SelectedTitle>}
          <S.CurrentFilters>
            {selectedFilters.map(filter => (
              <S.SelectedChip
                key={`${filter.id}-${filter.value}`}
                label={filter.label}
                onDelete={
                  filter.disabled ? null : () => handleDeleteOneFilter(filter)
                }
                deleteIcon={<HighlightOffOutlinedIcon />}
              />
            ))}
            <S.ClearFiltersButton onClick={handleDeleteAllFilters}>
              Limpar filtros
            </S.ClearFiltersButton>
          </S.CurrentFilters>
        </S.SelectedContainer>
      )}
    </Fragment>
  );
};

export default ActiveFilters;
