import React from 'react';
import moment from 'moment';
import { FormikProps } from 'formik';
import NumberFormat from 'react-number-format';

import { TextField } from '@material-ui/core';

import DatePicker from 'components/atoms/DatePicker';
import AutoComplete from 'components/atoms/AutoComplete';
import { CPFCNPJMaskCustom } from 'components/MaskTextInput';

import { ActionFieldType, FormField, Option } from './utils/interfaces';
import * as S from './styles';

interface DynamicFieldsFormProps<T> {
  formik: FormikProps<T>;
  formFields: FormField[];
}

export function DynamicFieldsForm<T>({
  formik,
  formFields,
}: DynamicFieldsFormProps<T>) {
  const renderField = (field: FormField) => {
    const isError = !!(formik.touched[field.name] && formik.errors[field.name]);
    const helperText = isError ? (formik.errors[field.name] as string) : '';

    switch (field.type) {
      case ActionFieldType.Alphanumeric:
      case ActionFieldType.Numeric:
        return (
          <TextField
            fullWidth
            variant="outlined"
            name={field.name}
            label={field.label}
            placeholder={field.placeholder}
            value={formik.values[field.name]}
            onChange={({ target }) => {
              const currentValue = target.value;

              if (
                field.type === ActionFieldType.Numeric &&
                currentValue?.length
              ) {
                const onlyNumbers = currentValue.replace(/\D/g, '');
                formik.setFieldValue(field.name, onlyNumbers);
                return;
              }

              formik.setFieldValue(field.name, currentValue);
            }}
            error={isError}
            helperText={helperText}
          />
        );

      case ActionFieldType.CPFCNPJ:
        return (
          <TextField
            fullWidth
            variant="outlined"
            name={field.name}
            label={field.label}
            value={formik.values[field.name]}
            InputProps={{
              inputComponent: CPFCNPJMaskCustom,
              value: formik.values[field.name],
              onChange: formik.handleChange,
            }}
            InputLabelProps={{ shrink: formik.values[field.name] }}
            error={isError}
            helperText={helperText}
            placeholder={field.placeholder}
          />
        );

      case ActionFieldType.Select:
        return (
          <AutoComplete
            name={field.name}
            label={field.label}
            value={formik.values[field.name]}
            options={field.options || []}
            onChange={(e: Option) => formik.setFieldValue(field.name, e)}
            error={isError}
            helperText={helperText}
            isClearable={false}
          />
        );

      case ActionFieldType.Date:
        return (
          <DatePicker
            name={field.name}
            label={field.label}
            onChange={e =>
              formik.setFieldValue(field.name, moment(e).valueOf())
            }
            value={formik.values[field.name]}
            error={isError}
            helperText={helperText}
          />
        );

      case ActionFieldType.Price:
        return (
          <NumberFormat
            name={field.name}
            label={field.label}
            value={formik.values[field.name]}
            onValueChange={e => formik.setFieldValue(field.name, e.floatValue)}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="R$ "
            customInput={TextField}
            variant="outlined"
            fullWidth
          />
        );

      default:
        return null;
    }
  };

  return (
    <S.DynamicFieldsFormContainer>
      {formFields.map(field => (
        <S.FieldsWrapper key={String(field.name)}>
          {renderField(field)}
        </S.FieldsWrapper>
      ))}
      {formik?.values?.['statusDetailId']?.label?.toLowerCase() ===
        'outros' && (
        <TextField
          placeholder="Descreva o motivo..."
          label="Motivo"
          value={formik.values?.['reasonNotToSell']}
          onChange={event =>
            formik.setFieldValue('reasonNotToSell', event.target.value, true)
          }
          multiline
          rows="3"
          variant="outlined"
          error={
            !!(
              formik.touched?.['reasonNotToSell'] &&
              formik.errors?.['reasonNotToSell']
            )
          }
          helperText={
            formik?.errors?.['reasonNotToSell']
              ? formik?.errors?.['reasonNotToSell']
              : `${formik.values?.['reasonNotToSell']?.length || 0}/200`
          }
          style={{ marginTop: '10px' }}
          fullWidth
        />
      )}
    </S.DynamicFieldsFormContainer>
  );
}
