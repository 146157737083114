import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ArrowBack,
  AddCommentOutlined,
  SpeakerNotesOffOutlined,
} from '@material-ui/icons';

import CreateNewProposalModalCallCenter from 'components/NewCardCallCenter';
import { Search } from 'pages/conversations/components/ChatList/Search';
import CreateNewProposalModalShowroom from 'pages/proposals/tabs/Kanban/components/CreateNewProposalModal';

import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';
import { usePermissions } from 'hooks/usePermissions';
import { ChatListShow } from 'pages/conversations/interfaces';

import {
  chatListOptions,
  inactiveLeadsList,
  selectedLead,
  leadsList,
  filterByUnread as filterByUnreadState,
} from 'pages/conversations/contexts/atoms';

import {
  Container,
  ConversationWrapper,
  LeadStatusDisplay,
  Title,
} from './styles';

interface ChatListProps {
  show: ChatListShow;
}

export function Header({ show }: ChatListProps) {
  const setChatListOption = useSetRecoilState(chatListOptions);
  const setSelectedLead = useSetRecoilState(selectedLead);
  const [
    createNewProposalModalIsOpen,
    setCreateNewProposalModalIsOpen,
  ] = useState(false);
  const inactiveLeads = useRecoilValue(inactiveLeadsList);
  const activeLeads = useRecoilValue(leadsList);
  const [filterByUnread, setFilterByUnread] = useRecoilState(
    filterByUnreadState
  );

  const { currentDealer } = useSelector(state => state.dealerStore);
  const { currentGroup } = useSelector(state => state.callCenter);

  const { hasPermission } = usePermissions();
  const canCreateProposals = hasPermission('PROPOSAL_CREATE');

  const handleTabChange = (tab: ChatListShow) => {
    setChatListOption(tab);
    setFilterByUnread(false);
    setSelectedLead(null);
  };

  function UnReadMessagesCount() {
    if (!filterByUnread) return;

    const count =
      show === 'CONVERSATIONS'
        ? activeLeads.filter(lead => lead.unseenMessages >= 1).length
        : inactiveLeads.filter(lead => lead.unseenMessages >= 1).length;

    return (
      <p>
        Filtro: não lidas <span>{count}</span>
      </p>
    );
  }

  return (
    <Container display={show} unreadView={filterByUnread}>
      {show === 'CONVERSATIONS' ? (
        <>
          <ConversationWrapper display={show}>
            <Title>
              <h1>Conversas</h1>
              <p>{activeLeads && activeLeads.length}</p>
            </Title>

            {(checkCallCenterOsSearchAndRescueRoute()
              ? true
              : !currentDealer.closeLead && canCreateProposals) && (
              <IconButton
                onClick={() => setCreateNewProposalModalIsOpen(true)}
                size="small"
              >
                <AddCommentOutlined />
              </IconButton>
            )}
          </ConversationWrapper>

          <Search />

          <LeadStatusDisplay>
            {filterByUnread ? (
              <UnReadMessagesCount />
            ) : (
              <>
                <div onClick={() => handleTabChange('INACTIVE_LEADS')}>
                  <SpeakerNotesOffOutlined />
                  <p>Leads finalizados</p>
                </div>

                <span>{inactiveLeads.length}</span>
              </>
            )}
          </LeadStatusDisplay>
        </>
      ) : (
        <>
          <ConversationWrapper display={show}>
            <IconButton
              color="secondary"
              onClick={() => handleTabChange('CONVERSATIONS')}
              size="small"
            >
              <ArrowBack />
            </IconButton>

            <Title>
              <h1>Leads finalizados</h1>
              <p>{inactiveLeads && inactiveLeads.length}</p>
            </Title>
          </ConversationWrapper>

          <Search />

          {filterByUnread && (
            <LeadStatusDisplay>
              <UnReadMessagesCount />
            </LeadStatusDisplay>
          )}
        </>
      )}
      {createNewProposalModalIsOpen &&
        !checkCallCenterOsSearchAndRescueRoute() && (
          <CreateNewProposalModalShowroom
            isOpen={createNewProposalModalIsOpen}
            handleOnClose={() => setCreateNewProposalModalIsOpen(false)}
          />
        )}
      {createNewProposalModalIsOpen &&
        checkCallCenterOsSearchAndRescueRoute() && (
          <CreateNewProposalModalCallCenter
            isOpen={createNewProposalModalIsOpen}
            handleOnOpen={() => null}
            handleOnClose={() => setCreateNewProposalModalIsOpen(false)}
            currentGroupId={currentGroup?.id}
          />
        )}
    </Container>
  );
}
