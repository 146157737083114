import React from 'react';
import Wrapper from 'components/Wrapper';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import AutoComplete from 'components/atoms/AutoComplete';
import OutlinedButton from 'components/atoms/OutlinedButton';
import DragzoneBoxComponent from 'components/atoms/DragzoneBox/DragzoneBoxComponent';
import { uploadDocumentTypesComponent } from './UploadDocumentTypes';

const UploadDocumentModalComponent = ({
  open,
  onClose,
  file,
  setFile,
  addDocument,
  documentTypesOptions,
  isLoadingOptions,
  typeDocument,
  setTypeDocument,
  isLoading,
}: uploadDocumentTypesComponent) => {
  return (
    <GenericDialog
      open={open}
      title="Upload de documento"
      onClose={onClose}
      maxWidth="sm"
      DialogAction={() => (
        <Wrapper flex justify="end" width="100%" gap="md">
          <OutlinedButton onClick={onClose} disabled={isLoading}>
            Cancelar
          </OutlinedButton>
          <ThemeButton
            onClick={addDocument}
            disabled={isLoading || !typeDocument || !file.length}
            loading={isLoading}
          >
            Adicionar
          </ThemeButton>
        </Wrapper>
      )}
    >
      <Wrapper width="100%" gap="lg" flex direction="column">
        <Wrapper width="100%">
          <AutoComplete
            minHeight="58px"
            label="Tipo de documento*"
            name="typeDocument"
            disabled
            value={
              typeDocument
                ? {
                    value: typeDocument,
                    label: documentTypesOptions?.find(
                      e => e.value === Number(typeDocument)
                    )?.label,
                    name: documentTypesOptions?.find(
                      e => e.value === Number(typeDocument)
                    )?.label,
                  }
                : null
            }
            options={documentTypesOptions}
            onChange={e => {
              setTypeDocument(e?.value);
            }}
            isLoading={isLoadingOptions}
          />
        </Wrapper>

        {!!typeDocument && (
          <Wrapper width="100%">
            <DragzoneBoxComponent fileState={file} setFileState={setFile} />
          </Wrapper>
        )}
      </Wrapper>
    </GenericDialog>
  );
};

export default UploadDocumentModalComponent;
