import { useQuery } from 'react-query';
import MobiIAServices from 'services/mobiIAServices';
import useMobiIAProducts from 'hooks/useMobiIAProducts';
import useSnackBar from 'hooks/useSnackBar';
import useProposal from 'hooks/useProposal';

const useGetChatMobiSmartConsultant = message => {
  const { hasMobiGpt } = useMobiIAProducts();
  const { handleSnackBarError } = useSnackBar();
  const { proposalId, dealerId } = useProposal();

  return useQuery<any>(
    `get_Chat_Mobi_Smart_Consultant_${proposalId}`,
    () =>
      MobiIAServices.getChatMobiSmartConsultant(proposalId, dealerId, message),
    {
      select: (data: any) => {
        return data?.data ? data?.data : [];
      },
      refetchOnWindowFocus: false,
      initialData: [],
      refetchOnMount: 'always',
      staleTime: 0,
      enabled: proposalId && hasMobiGpt,
      onError: () => {
        handleSnackBarError('Ocorreu um erro Inesperado".');
      },
    }
  );
};

export default useGetChatMobiSmartConsultant;
