export const statusType = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  REPROVED: 'REPROVED',
  CANCELED: 'CANCELED',
};

export type IBoxConsent = {
  status?: string;
  imageId?: number;
  imageVcode?: string;
  idValue?: string | number;
  ipValue?: string | number;
  createData?: string | number;
  createHours?: string | number;
  expirationData?: string | number;
  expirationHours?: string | number;
  userAgentContent?: string | number;
};
