import React, { memo } from 'react';
import getVideo from 'components/getVideo';
import { addLinkOnMessage } from './components/utils/addLinkOnMessage';

const MessageVideo = ({ message }) => {
  const getLinkVideo = video => getVideo(video);

  const linkAudio = getLinkVideo({
    id: message.media.id,
    vcode: message.media?.vcode,
  });
  const textMessage = message?.message ?? '';

  return (
    <>
      <video width="300" height="400" controls>
        <source src={linkAudio} type="video/mp4" />;
      </video>

      <p>{addLinkOnMessage(textMessage)}</p>
    </>
  );
};

export default memo(MessageVideo);
