import React from 'react';
import Paper from '@material-ui/core/Paper';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

class PaperBorder extends React.Component {
  render() {
    const { style, children, withMargin } = this.props;
    return (
      <Paper
        {...this.props}
        style={{
          ...style,
          borderRadius: '4px',
          boxShadow: `0 0 20px 0 ${Colors.gray.dark}19`,
          boxSizing: 'border-box',
          margin: withMargin ? '30px' : 0,
          transition: '.15s',
        }}
      >
        {children}
      </Paper>
    );
  }
}

export default PaperBorder;
