import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import SchedurServices from 'services/schedurServices'
import ActionTypes from './actionTypes'
import ActionCreators from './actionCreators'
import { Action } from 'types/ActionTypes'

function* createEvent(action: Action){
  try {
    const {dealId, data} = action.payload
    yield put(ActionCreators.callIsLoad())
    const response = yield SchedurServices.createEvent(dealId, data)
    if(response.success){
      yield put(ActionCreators.setSuccess())
      //chamar o get all
    }else{
      yield put(ActionCreators.setError())
    }
  } catch (e) {
    yield put(ActionCreators.setError())
  }
}

function* getEventById(action: Action){
  try {
    const {dealId, eventId} = action.payload

    yield put(ActionCreators.callIsLoad())
    const response = yield SchedurServices.getEventsById(dealId, eventId)
    if(response.success){
      yield put(ActionCreators.getByIdSuccess(response.data))
    }else{
      yield put(ActionCreators.getByIdError())
    }
  } catch (e) {
    yield put(ActionCreators.getByIdError())
  }
}

function* getEvents(action: Action){
  try {
    const { dealId, start, end } = action.payload
    yield put(ActionCreators.callIsLoad())
    const response = yield SchedurServices.getItervalEvents(dealId, start, end)
    if(response.success){
      let data = response.data
      data.map(item => {
        item['startTime'] = new Date(item.startTime)
        item['endTime'] = new Date(item.endTime)
      })
      yield put(ActionCreators.getAllSuccess(response.data))
    }else{
      yield put(ActionCreators.getAllError())
    }
  } catch (e) {
    yield put(ActionCreators.getAllError())
    
  }
}

function* updateEvent(action: Action){
  try {
    const { dealId, eventId } = action.payload
    yield put(ActionCreators.callIsLoad())
    const response = yield SchedurServices.updateEvent(dealId, eventId)
    if(response.success){
      yield put(ActionCreators.updateSuccess())
      //chamar o get all
    }else{
      yield put(ActionCreators.updateError())
    }
  } catch (e) {
    yield put(ActionCreators.updateError())
    
  }
}

function* deleteEvent(action: Action){
  try {
    const { dealId, eventId } = action.payload
    yield put(ActionCreators.callIsLoad())
    const response = yield SchedurServices.deleteEvent(dealId, eventId)
    if(response.success){
      yield put(ActionCreators.deleteSuccess())
      //chamar o get all
    }else{
      yield put(ActionCreators.deleteError())
    }

  } catch (e) {
    yield put(ActionCreators.deleteError())
  }
}

export default all([
  takeLatest(ActionTypes.GET_SCHEDULER_ALL_EVENT, getEvents),
  takeLatest(ActionTypes.CREATE_SCHEDULER_EVENT, createEvent),
  takeLatest(ActionTypes.GET_SCHEDULER_EVENT_BY_ID, getEventById),
  takeLatest(ActionTypes.UPDATE_SCHEDULER_EVENT, updateEvent),
  takeLatest(ActionTypes.DELETE_SCHEDULER_EVENT, deleteEvent),
])