import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import useEvaluation from '../../hooks/useEvaluation';

import * as S from './styles';

interface Props {
  dealerId: number;
  dealId: number;
  isOpen: boolean;
  onClose: () => void;
  proposalId: number;
}

const ModalConfirmation = ({
  dealerId,
  dealId,
  isOpen,
  onClose,
  proposalId,
}: Props) => {
  const { startPreviousCreatedEvaluation, isLoading } = useEvaluation({
    dealerId,
    proposalId,
    onSuccessUpdateStatus: () => {
      onClose();
    },
  });

  const handleOnClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleOnConfirm = () => {
    startPreviousCreatedEvaluation({
      dealerId,
      dealId,
      proposalId,
    });
  };

  return (
    <S.Dialog onClose={handleOnClose} open={isOpen}>
      <S.Header>
        <b className="title">Solicitar avaliação</b>
        <IconButton size="small" onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </S.Header>
      <S.Content>
        <p>Deseja solicitar uma avaliação a partir do veículo precificado?</p>
      </S.Content>
      <S.ButtonsBox>
        <S.CancelButton
          variant="text"
          size="medium"
          onClick={handleOnClose}
          disabled={isLoading}
        >
          Cancelar
        </S.CancelButton>
        <S.ConfirmButton
          variant="contained"
          size="medium"
          onClick={handleOnConfirm}
        >
          {isLoading ? (
            <CircularProgress
              style={{ width: 26, height: 26, color: '#FFF' }}
            />
          ) : (
            'Confirmar'
          )}
        </S.ConfirmButton>
      </S.ButtonsBox>
    </S.Dialog>
  );
};

export default ModalConfirmation;
