import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Close from '@material-ui/icons/Close';
import VehicleItem from 'components/VehicleItem/VehicleItem';
import CancelBookDialog from '../CancelBookDialog';
import React, { useEffect, useState } from 'react';
import BookVehicleServices from 'services/bookVehicleServices';
import { BookedVehicle } from 'types/BookedVehicleTypes';
import DateTimeHightLight from 'components/atoms/DateTimeHighLight/DateTimeHighLight';
import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';
import {
  BookDivider,
  BookInfo,
  SubTitle,
  VericalDivider,
  ListLoading,
  Avatar,
  DefaultButton,
  DialogActionsContainer,
  DialogHeader,
} from './styles';
import getInitialsName from 'helpers/getInitialsName';
import { userTypes } from 'types/LeadsTypes';
import ThemeButton from 'components/atoms/ThemeButton';
import PROFILES from 'constants/profiles';
import { useChatContext } from 'pages/chats/ChatsContext';

export default function BookDetailDialog(props) {
  const {
    onClose,
    currentDeal,
    bookedId,
    open,
    onBookCancelled,
    ...other
  } = props;

  const { currentProposal }: any = useChatContext();
  const dispatch = useDispatch();
  const { currentDealer, userStore } = useSelector(state => ({
    userStore: state.userStore,
    ...state.dealerStore,
  }));

  const dealerId = currentDealer?.id || currentProposal?.dealerId;

  const [loading, setLoading] = useState(false);
  const [bookedVehicle, setBookedVehicle] = useState<BookedVehicle>({});
  const [hasPermissionToCancel, setHasPermissionToCancel] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const goToDetails = () => {
    dispatch(
      proposalCreators.setProposalModalOpen(true, {
        dealerId: currentDeal?.dealerId || dealerId,
        proposalId: bookedVehicle.proposal?.id,
      })
    );
  };

  const getBookedVehicle = async () => {
    setLoading(true);
    const response: any = await BookVehicleServices.getBookedVehicleById(
      dealerId,
      currentDeal?.bookedId || bookedId
    );
    setBookedVehicle(response.data);
    setLoading(false);
  };

  const handleOpenCancelDialog = () => {
    // handleClose()
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = async isCancelled => {
    setOpenCancelDialog(false);

    if (isCancelled) {
      if (onBookCancelled) onBookCancelled();
    }
  };

  useEffect(() => {
    if (open) getBookedVehicle();
  }, [open]);

  const verifyCancelPermission = async () => {
    const currentUserId: number = parseInt(userStore?.userSite?.id);
    const currentUserType: userTypes = userStore?.userType;

    if ([userTypes.MANAGER, userTypes.OWNER].includes(currentUserType))
      setHasPermissionToCancel(true);
    else if (currentUserType === userTypes.SELLER)
      setHasPermissionToCancel(currentUserId === bookedVehicle.seller?.id);
    else setHasPermissionToCancel(false);
  };

  const isManagerOrOwner = () => {
    if (userStore?.userProfile === PROFILES.CONSULTOR) {
      return true;
    }
    const currentUser = (currentDealer.users || []).find(
      item => item.userSite.email === userStore.userSite.email
    );
    return [userTypes.MANAGER, userTypes.OWNER].includes(currentUser?.userType);
  };

  useEffect(() => {
    if (bookedVehicle) verifyCancelPermission();
  }, [bookedVehicle]);

  const isEmpty = object => {
    return !object || !Object.keys(object).length;
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        {...other}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <DialogHeader>
            <div>Informações de reserva</div>
            <IconButton aria-label="Close" onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogHeader>
        </DialogTitle>

        <DialogContent>
          {loading || isEmpty(bookedVehicle) ? (
            <ListLoading>
              <CircularProgress />
              <span>Carregando dados da reserva...</span>
            </ListLoading>
          ) : (
            <>
              <SubTitle>Veículo</SubTitle>
              {bookedVehicle.vehicle && (
                <VehicleItem
                  vehicle={{
                    ...bookedVehicle?.vehicle,
                    ...currentDeal,
                    color: currentDeal?.exteriorColor || currentDeal?.colorName,
                  }}
                  isSelectable={false}
                  showBooked={false}
                />
              )}
              <BookDivider />
              <SubTitle>Reserva</SubTitle>
              <BookInfo>
                Veículo reservado até o dia &nbsp;
                <DateTimeHightLight date={bookedVehicle.bookedAt} />
              </BookInfo>
              <BookDivider />
              <SubTitle>Loja</SubTitle>
              <BookInfo>{bookedVehicle.dealer?.name}</BookInfo>
              <BookDivider />
              <SubTitle>Cliente</SubTitle>
              <BookInfo>{bookedVehicle.proposal?.clientUserName}</BookInfo>
              <BookDivider />
              <Grid container>
                <Grid item xs={5}>
                  <SubTitle>Vendedor</SubTitle>
                  <BookInfo>
                    <Avatar>
                      {getInitialsName(bookedVehicle.seller?.name)}
                    </Avatar>
                    <span>{bookedVehicle.seller?.name}</span>
                  </BookInfo>
                </Grid>
                {bookedVehicle.salesManager && (
                  <>
                    <Grid item xs={1}>
                      <VericalDivider />
                    </Grid>
                    <Grid item xs={6}>
                      <SubTitle>Gestor responsável</SubTitle>
                      <BookInfo>
                        <Avatar>
                          {getInitialsName(bookedVehicle.salesManager?.name)}
                        </Avatar>
                        <span>{bookedVehicle.salesManager?.name}</span>
                      </BookInfo>
                      {bookedVehicle.salesManager?.phone && (
                        <BookInfo>{bookedVehicle.salesManager?.phone}</BookInfo>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              <BookDivider end />
            </>
          )}
        </DialogContent>
        <DialogActionsContainer>
          {currentDeal?.dealerId === dealerId && isManagerOrOwner() && (
            <ThemeButton onClick={goToDetails}>Abrir proposta</ThemeButton>
          )}
          <Tooltip
            title="Para cancelar, você precisa ser o vendedor da reserva"
            disableHoverListener={hasPermissionToCancel}
            style={{ fontSize: 12 }}
          >
            <span>
              <DefaultButton
                color="default"
                variant="outlined"
                onClick={handleOpenCancelDialog}
                disabled={!hasPermissionToCancel}
              >
                Cancelar reserva
              </DefaultButton>
            </span>
          </Tooltip>
        </DialogActionsContainer>
      </Dialog>
      <CancelBookDialog
        open={openCancelDialog}
        onClose={handleCloseCancelDialog}
        bookedAt={bookedVehicle.bookedAt}
        bookedId={currentDeal?.bookedId || bookedId}
        onClick={event => event.stopPropagation()}
      />
    </>
  );
}
