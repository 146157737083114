import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 19px;
`;

export const styles = theme => ({
  root: {
    margin: '0px !important',
  },
  inputRoot: {
    color: Colors.gray.dark,
    width: '100%',
    fontSize: '14px',
    fontFamily: 'Poppins Regular',
  },
  inputInput: {
    transition: theme.transitions.create('width'),
    fontFamily: 'Poppins Regular',
    width: '100%',
    // '&:focus': {
    //   width: '100%',
    //   maxWidth: 1200,
    // },
  },
});
