import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import { ReactComponent as LegsRightChatBubble } from 'assets/legs-right-chat-bubble.svg';
import formatTime from 'helpers/formatTime';
import getInitialsName from 'helpers/getInitialsName';
import MessageImage from './MessageImage';
import MessageAudio from './MessageAudio';
import MessageVideo from './MessageVideo';
import MessageMedia from './MessageMedia';
import MessageResponse from './components/RepliedMessage';
import { addLinkOnMessage } from './components/utils/addLinkOnMessage';
import MessageTypes from 'constants/MessageTypes';
import getImage from 'components/getImage';

const OvalBigger = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${props => props.backgroundColor || Colors.gray.nearWhite};
  font-family: Poppins Bold;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  flex: 0 0 auto;
`;

const ContainerMessage = styled.div`
  display: flex;
  flex-flow: ${props => props.flexDirection || 'wrap-reverse'};
  padding: ${props => props.paddingRight || '0'};
  flex-wrap: nowrap;
  align-items: flex-end;
`;

const FooterMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const BoxBlue = styled.div`
  max-width: 80%;
  padding: 8px 16px;
  background-color: #4300d2;
  color: #ffffff;
  border-radius: 8px 8px 8px 0px;
  font-family: Poppins Regular;
  font-size: 12px;
`;

const DateMessage = styled.div`
  font-family: Poppins Regular;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
`;

export const ProviderIcon = styled.img`
  width: 25px;
  height: 25px;
  margin: 0 auto;
  align-self: center;
  margin-bottom: 6px;
  background: #fff;
  padding: 2px;
  border-radius: 50%;
`;

const MessagePF = props => {
  const {
    message,
    clientName,
    currentRef,
    currentAudioPlaying,
    setCurrentAudioPlaying,
    audiosRef,
    focusOnTheMessage,
    sellerName,
  } = props;

  const dateText = `${moment(message.date).format('DD/MM')} - ${formatTime(
    new Date(message.date)
  )}`;

  const userCapitilize = getInitialsName(clientName);

  const MessageType = messageType => {
    switch (messageType) {
      case MessageTypes.AUDIO:
        return (
          <MessageAudio
            message={message}
            currentAudioPlaying={currentAudioPlaying}
            setCurrentAudioPlaying={setCurrentAudioPlaying}
            audiosRef={audiosRef}
          />
        );
      case MessageTypes.IMAGE:
        return <MessageImage message={message} />;
      case MessageTypes.VIDEO:
        return <MessageVideo message={message} />;
      case MessageTypes.MEDIA:
        return <MessageMedia message={message} isPF />;
      case MessageTypes.RESPONSE:
        return (
          <MessageResponse
            message={message}
            currentAudioPlaying={currentAudioPlaying}
            setCurrentAudioPlaying={setCurrentAudioPlaying}
            audiosRef={audiosRef}
            focusOnTheMessage={focusOnTheMessage}
            sellerName={sellerName}
          />
        );

      default:
        return addLinkOnMessage(message.message);
    }
  };

  return (
    <Container ref={currentRef}>
      <ContainerMessage>
        <ContainerIcon>
          {message?.provider && (
            <Tooltip title={message.provider?.name}>
              <ProviderIcon
                src={getImage({
                  imageId: message.provider?.imageId,
                  imageVcode: message.provider?.imageVcode,
                })}
              />
            </Tooltip>
          )}
          <Tooltip title={clientName}>
            <OvalBigger style={{ margin: '0 16px' }}>
              {userCapitilize}
            </OvalBigger>
          </Tooltip>
        </ContainerIcon>
        <LegsRightChatBubble />
        <BoxBlue>
          <div
            style={{ fontSize: 14, marginBottom: 8, whiteSpace: 'pre-wrap' }}
          >
            {MessageType(props.type)}
          </div>
          <FooterMessage>
            <DateMessage>{dateText}</DateMessage>
          </FooterMessage>
        </BoxBlue>
      </ContainerMessage>
    </Container>
  );
};

export default React.memo(MessagePF);
