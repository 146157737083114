import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'alsListServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default {
  getSellerProposals: function(dealerId, options = {}) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/corporativesale/proposal/v1.0`,
        options
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getProposals()`) as any);
  },
  getBuyerProposals: function(dealerId, options = {}) {
    return request
      .get(`${domain}/buyer/${dealerId}/corporativesale/proposal/v1.1`, options)
      .then(buildHTTPResponseHandler(`${serviceName}.getProposals()`) as any);
  },
};
