import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'types/ActionTypes';
import ActionCreators from './reducers/actionCreators';
import ActionTypes from './reducers/actionTypes';
import SearchServices from 'services/searchServices';
import handlePDFBlobFile from 'helpers/handlePDFBlobFile';
import OrderServices from 'modules/order/services/OrderServices';
import ContactServices from 'modules/resale/services/ContactServices';
import FinancingService from 'modules/financing/services/financingServices';
import CampaignServices from 'modules/financing/services/campaignServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
// import { clearValuesObjectWithMask } from 'helpers/clearValuesObjectWithMask';
import {
  getAddressType,
  getCareers,
  getGenders,
  getCivilStatus,
  getStreetType,
  getEmployments,
} from 'services/optionsServices';

import { trimStringObject } from 'helpers/trimStringObject';

const buildOptions = arr => {
  const options: Array<{ label: string; value: string }> = [];
  arr?.map(element =>
    options.push({ label: element.label, value: element.name })
  );
  return options;
};

const buildBankOptions = arr => {
  const options: Array<{ label: string; value: string }> = [];
  arr?.map(element => options.push({ label: element.name, value: element.id }));
  return options;
};

const buildCareersOptions = arr => {
  const options: Array<{ label: string; value: string }> = [];
  arr?.map(element =>
    options.push({ label: element.label, value: element.id })
  );
  return options;
};

function* getFinancingListing() {
  try {
    yield put(ActionCreators.setFinancingListingIsLoading(true));
    const { currentDealerId } = yield select(state => state.financing);
    const response = yield call(
      FinancingService.financingListing,
      currentDealerId
    );
    if (response.success) {
      yield put(ActionCreators.setFinancingListing(response.data || []));
    }
    yield put(ActionCreators.setFinancingListingIsLoading(false));
  } catch (e) {
    console.log(e);
  }
}
interface FinancingData {
  contactId: number | string;
  molicar: string;
  dealId?: number | string;
  trimId?: number | string;
  fipe?: string;
  proposalId?: number;
}

function* createFinancing(action: Action) {
  try {
    const {
      currentDealerId,
      clientCurrent,
      vehicleCurrent,
      isFinished,
      financing,
    } = yield select(state => state.financing);

    //IF_REVIEW_MODE_OR_HAVE_FINANCING_ID_SKIP_CREATE_FINANCING
    if (isFinished) {
      yield put(ActionCreators.simulate());
      action.payload.callback();
      return;
    }

    const data: FinancingData = {
      contactId: clientCurrent.id,
      molicar: vehicleCurrent.vehicleMolicar,
      fipe: vehicleCurrent.vehicleFipe,
      trimId: vehicleCurrent.vehicleTrimId,
    };

    const response = yield call(
      FinancingService.financing,
      currentDealerId,
      financing?.financingId,
      data
    );
    if (response.success) {
      yield put(ActionCreators.setFinancing(response.data));
      yield put(ActionCreators.simulate());
      action.payload.callback();
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* getFinancingById(action: Action) {
  try {
    yield put(ActionCreators.setFinancingOrderbyIdLoading(true));
    const response = yield call(
      FinancingService.getFinancingById,
      action.payload.dealerId,
      action.payload.financingId
    );
    if (response.success) {
      yield put(
        ActionCreators.setFinancingOrderById({
          ...response.data,
          financing: { financingId: action.payload.financingId },
        })
      );
      yield put(ActionCreators.setFinancingOrderbyIdLoading(false));
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(ActionCreators.setFinancingOrderbyIdLoading(false));
    action.payload?.callback();
  } catch (e) {
    yield put(ActionCreators.setFinancingOrderbyIdLoading(false));
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
    action.payload?.callback();
  }
}

function* simulate() {
  try {
    const {
      currentDealerId,
      vehicleCurrent,
      downPayment,
      term,
      financingValue,
      commissionReturn,
      financing: { financingId },
    } = yield select(state => state.financing);
    const data = {
      commissionReturn,
      downPayment,
      term,
      financingValue,
      vehiclePrice: vehicleCurrent.price,
    };
    yield put(ActionCreators.setSimulateLoading(true));
    const response = yield call(
      FinancingService.simulate,
      currentDealerId,
      financingId,
      data
    );
    if (response.success) {
      yield put(ActionCreators.setBanksToSimulate(response.data));
      yield put(ActionCreators.setSimulateLoading(false));
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(ActionCreators.setSimulateLoading(false));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
    yield put(ActionCreators.setSimulateLoading(false));
  }
}

function* simulateById(action: Action) {
  try {
    const {
      currentDealerId,
      vehicleCurrent,
      commissionReturn,
      financing: { financingId },
    } = yield select(state => state.financing);

    const data = {
      commissionReturn,
      vehiclePrice: vehicleCurrent.price,
      ...action.payload.currentBank,
    };

    yield put(ActionCreators.setSimulateLoading(true));
    const response = yield call(
      FinancingService.simulateById,
      currentDealerId,
      financingId,
      action.payload.institutionId,
      data
    );
    if (response.success) {
      yield put(
        ActionCreators.setSimulateBankDataById(
          action.payload.institutionId,
          response.data
        )
      );
      yield put(ActionCreators.setSimulateLoading(false));
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(ActionCreators.setSimulateLoading(false));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
    yield put(ActionCreators.setSimulateLoading(false));
  }
}

function* sendClientForm(action: Action) {
  try {
    const {
      currentDealerId,
      financing: { financingId },
      clientCurrentData,
    } = yield select(state => state.financing);

    yield put(ActionCreators.setCreditAnalysesLoading(true));
    const clientCurrentDataFormated = {
      ...clientCurrentData,
    };

    if (typeof clientCurrentData?.rgNumber === 'string') {
      clientCurrentDataFormated.rgNumber = clientCurrentData?.rgNumber.replace(
        /_|-|\/|\./g,
        ''
      );
    }
    delete clientCurrentDataFormated.cpfCnpj;
    const response = yield call(
      FinancingService.sendClientForm,
      currentDealerId,
      financingId,
      action.payload.currentStep,
      trimStringObject(clientCurrentDataFormated)
    );

    if (response.success) {
      if (
        [
          'BUYER_FINANCING_PERSON_MARITAL_PARTNER',
          'BUYER_FINANCING_OCCUPATION',
        ].includes(action.payload.currentStep)
      ) {
        yield put(ActionCreators.setEnableStepResultAnalysis(true));
        action.payload.callback();
      } else {
        action.payload.callback();
      }
      return;
    }

    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* applySimulation(action: Action) {
  try {
    const {
      currentDealerId,
      banksToFinancing,
      financing: { financingId },
    } = yield select(state => state.financing);
    yield put(ActionCreators.setApplySimulationLoading(true));
    const banksToFinancingClean = banksToFinancing.map(bank => {
      return {
        commissionReturn: bank.commissionReturn,
        dealerPJFinancingInstitutionId: bank.dealerPJFinancingInstitution.id,
        downPayment: bank.downPayment,
        term: bank.simulateTerm ?? bank.term,
      };
    });

    const response = yield call(
      FinancingService.applyToSimulate,
      currentDealerId,
      financingId,
      banksToFinancingClean
    );

    if (response.success) {
      action.payload.callback();
      yield put(ActionCreators.setApplySimulationLoading(false));
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(ActionCreators.setApplySimulationLoading(false));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
    yield put(ActionCreators.setApplySimulationLoading(false));
  }
}

function* applyCreditAnalysis(action: Action) {
  try {
    const {
      currentDealerId,
      banksToFinancing,
      financing: { financingId },
    } = yield select(state => state.financing);

    const response = yield call(
      FinancingService.applyToCreditAnalysis,
      currentDealerId,
      financingId,
      banksToFinancing
    );
    if (response.success) {
      action.payload.callback();
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(ActionCreators.setCreditAnalysesLoading(false));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
    yield put(ActionCreators.setCreditAnalysesLoading(false));
  }
}

function* contractFinancing(action: Action) {
  try {
    const {
      currentDealerId,
      applications,
      financing: { financingId },
    } = yield select(state => state.financing);

    const response = yield call(
      FinancingService.contractFinancing,
      currentDealerId,
      financingId,
      {
        ...applications,
        proposalCode: Number(applications.proposalCode),
        status: action.payload.status,
      }
    );
    if (response.success) {
      action.payload.callback();
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(ActionCreators.setCreditAnalysesLoading(false));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
    yield put(ActionCreators.setCreditAnalysesLoading(false));
  }
}

function* getCreditAnalysis() {
  try {
    const {
      currentDealerId,
      financing: { financingId },
    } = yield select(state => state.financing);
    yield put(ActionCreators.setCreditAnalysesLoading(true));
    const response = yield call(
      FinancingService.getCreditAnalysis,
      currentDealerId,
      financingId
    );
    if (response.success) {
      yield put(ActionCreators.setBanksResultAnalysis(response.data));
      yield put(ActionCreators.setCreditAnalysesLoading(false));
      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));
    yield put(ActionCreators.setCreditAnalysesLoading(false));
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
    yield put(ActionCreators.setCreditAnalysesLoading(false));
  }
}

function* getStates() {
  try {
    const { success, data } = yield call(SearchServices.getStates);
    if (success) {
      const arr: any = [{ value: 0, label: 'Selecione...' }];
      data.map(el => {
        arr.push({ label: el.name, value: el.id });
      });
      yield put(ActionCreators.setStates(arr));
    } else {
      const error: any =
        Object.values(data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getCitiesByState(action: Action) {
  try {
    const ufData = action.payload;
    if (!ufData) {
      return;
    }
    const { success, data }: any = yield call(
      SearchServices.getCitiesByState,
      ufData
    );
    if (success) {
      const arr: any = [{ value: 0, label: 'Selecione...' }];
      data.map(el => {
        arr.push({ label: el.name, value: el.id });
      });
      yield put(ActionCreators.setCitiesByState(arr));
    } else {
      const error: any =
        Object.values(data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (e) {
    console.log(e);
  }
}

//TODO ADICONAR LOADINGS
function* createContact(action: Action) {
  try {
    const { dealerId, clientData } = action.payload;
    yield put(ActionCreators.setCreateContactIsLoading(true));
    const response = yield call(
      ContactServices.createContact,
      dealerId,
      clientData
    );
    if (response.success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Cliente criado com sucesso.',
          true
        )
      );

      if (action.payload.selectContact) {
        action.payload.selectContact(clientData);
      }
      yield put(ActionCreators.setCreateContactIsLoading(false));
      action.payload.callback();

      return;
    }
    const error: any =
      Object.values(response?.data?.errors || {})?.[0] ??
      'Desculpe, Ocorreu um erro inesperado.';
    yield put(UIActionCreators.snackbarManagement('error', error, true));

    yield put(ActionCreators.setCreateContactIsLoading(false));
  } catch (e) {
    yield put(ActionCreators.setCreateContactIsLoading(false));
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

//FORM OPTIONS
function* getGendersOptions() {
  try {
    const { genderOptions } = yield select(state => state.financing);
    if (genderOptions.length <= 1) {
      const response = yield call(getGenders);
      if (response.success) {
        yield put(
          ActionCreators.setGendersOptions(buildOptions(response.data))
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(ActionCreators.setGendersOptions(genderOptions));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getCivilStatusOptions() {
  try {
    const { civilStatusOptions } = yield select(state => state.financing);
    if (civilStatusOptions.length <= 1) {
      const response = yield call(getCivilStatus);
      if (response.success) {
        yield put(
          ActionCreators.setCivilStatusOptions(buildOptions(response.data))
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(ActionCreators.setCivilStatusOptions(civilStatusOptions));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getStreetTypeOptions() {
  try {
    const { streetTypeOptions } = yield select(state => state.financing);
    if (streetTypeOptions.length <= 1) {
      const response = yield call(getStreetType);
      if (response.success) {
        yield put(
          ActionCreators.setStreetTypeOptions(buildOptions(response.data))
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(ActionCreators.setStreetTypeOptions(streetTypeOptions));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getAddressTypeOptions() {
  try {
    const { addressTypeOptions } = yield select(state => state.financing);
    if (addressTypeOptions.length <= 1) {
      const response = yield call(getAddressType);
      if (response.success) {
        yield put(
          ActionCreators.setAddressTypeOptions(buildOptions(response.data))
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(ActionCreators.setAddressTypeOptions(addressTypeOptions));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getCareersOptions() {
  try {
    const { careersOptions } = yield select(state => state.financing);
    if (careersOptions.length <= 1) {
      const response = yield call(getCareers);
      if (response.success) {
        yield put(
          ActionCreators.setCareersOptions(buildCareersOptions(response.data))
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(ActionCreators.setCareersOptions(careersOptions));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getEmploymentsOptions() {
  try {
    const { careersOptions } = yield select(state => state.financing);
    if (careersOptions.length <= 1) {
      const response = yield call(getEmployments);
      if (response.success) {
        yield put(
          ActionCreators.setEmploymentsOptions(
            buildCareersOptions(response.data)
          )
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(ActionCreators.setEmploymentsOptions(careersOptions));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getAllFinancialInstitutionOptions() {
  try {
    const { financialInstitutionsOptions } = yield select(
      state => state.financing
    );
    if (financialInstitutionsOptions.length <= 1) {
      const response = yield call(FinancingService.getAllFinancialInstitution);
      if (response.success) {
        yield put(
          ActionCreators.setAllFinancialInstitutionOptions(
            buildBankOptions(response.data)
          )
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(
          ActionCreators.setAllFinancialInstitutionOptions(
            financialInstitutionsOptions
          )
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getcommissionReturnOptions() {
  try {
    const { currentDealerId, commissionReturnOptions } = yield select(
      state => state.financing
    );
    if (commissionReturnOptions.length <= 1) {
      const response = yield call(
        FinancingService.getcommissionReturnOptions,
        currentDealerId
      );
      if (response.success) {
        yield put(
          ActionCreators.setcommissionReturnOptions(buildOptions(response.data))
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(
          ActionCreators.setcommissionReturnOptions(commissionReturnOptions)
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}
function* getBankAccountTypeOptions() {
  try {
    const { bankAccountTypeOptions } = yield select(state => state.financing);
    if (bankAccountTypeOptions.length <= 1) {
      const response = yield call(FinancingService.getBankAccountType);
      if (response.success) {
        yield put(
          ActionCreators.setBankAccountTypeOptions(buildOptions(response.data))
        );
      } else {
        const error: any =
          Object.values(response?.data?.errors || {})?.[0] ??
          'Desculpe, Ocorreu um erro inesperado.';
        yield put(UIActionCreators.snackbarManagement('error', error, true));
        yield put(
          ActionCreators.setBankAccountTypeOptions(bankAccountTypeOptions)
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}
function* getAllDealerPJFinancialInstitution(action: Action) {
  try {
    const response = yield call(
      FinancingService.getAllDealerPJFinancialInstitution,
      action.payload
    );
    const { data, success } = response;
    if (success) {
      yield put(ActionCreators.setAllDealerPJFinancialInstitution(data));
      yield put(ActionCreators.getAllFinancialInstitutionOptions());
    } else {
      const error: any =
        Object.values(data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
      yield put(ActionCreators.setAllDealerPJFinancialInstitution([]));
    }
  } catch (error) {
    console.log(error);
  }
}
function* createCampaign(action: Action) {
  try {
    const { dealerId, data, callback } = action.payload;
    const { currentFinancialInstitution } = yield select(
      state => state.financing
    );
    const response = yield call(
      CampaignServices.createCampaign,
      dealerId,
      currentFinancialInstitution?.institution?.id,
      data
    );
    const { success } = response;
    if (success) {
      // yield put(ActionCreators.getAllCampaign(dealerId));
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Campanha criada com sucesso.',
          true
        )
      );
      callback();
      // yield put(ActionCreators.successData(true));
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (error) {
    console.log('error', error);
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}
function* updateCampaign(action: Action) {
  try {
    const { currentFinancialInstitution } = yield select(
      state => state.financing
    );
    const { dealerId, campaignId, dataUpdate, callback } = action.payload;
    const response = yield call(
      CampaignServices.updateCampaign,
      dealerId,
      currentFinancialInstitution?.institution?.id,
      campaignId,
      dataUpdate
    );
    const { success } = response;
    if (success) {
      callback();
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Campanha edidata.',
          true
        )
      );
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (error) {
    console.log('error', error);
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* getCampaignById(action: Action) {
  try {
    const { currentFinancialInstitution } = yield select(
      state => state.financing
    );
    const { dealerId, campaignId } = action.payload;
    const response = yield call(
      CampaignServices.getCampaignById,
      dealerId,
      currentFinancialInstitution.institution.id,
      campaignId
    );
    const { success, data } = response;
    if (success) {
      if (data) {
        yield put(ActionCreators.setCampaigns([]));
      }
      yield put(ActionCreators.setCampaign(data));
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (error) {
    console.log(error);
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* saveDataInstitution(action: Action) {
  try {
    const { dealerId, data, callback } = action.payload;
    const response = yield call(
      FinancingService.saveDataInstitution,
      dealerId,
      data
    );
    const { success } = response;
    if (success) {
      //TODO: VERIFICAR
      // yield put(ActionCreators.getAllInstitution(dealerId));
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Instituição criada.',
          true
        )
      );
      callback();
      // yield put(ActionCreators.successInstData(true));
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (error) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* getInstitutionById(action: Action) {
  try {
    const { dealerId, id } = action.payload;
    const response = yield call(
      FinancingService.getByIdInstitution,
      dealerId,
      id
    );
    const { success, data } = response;
    if (success) {
      yield put(ActionCreators.setInstitution(data));
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (error) {
    console.log(error);
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* updateInstitution(action: Action) {
  try {
    const { dealerId, id, dataUpdate } = action.payload;
    const response = yield call(
      FinancingService.updateInstitution,
      dealerId,
      id,
      dataUpdate
    );
    const { success } = response;
    if (success) {
      yield put(
        UIActionCreators.snackbarManagement(
          'success',
          'Instituição edidata.',
          true
        )
      );
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (error) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

function* printOutOrder(action: Action) {
  try {
    const { dealerId, proposalId } = action.payload;
    const response: any = yield call(
      OrderServices.downloadContract,
      dealerId,
      proposalId
    );
    if (response?.success) {
      handlePDFBlobFile(
        response.data,
        `pedido-${dealerId}-${proposalId}`,
        true
      );
    } else {
      const error: any =
        Object.values(response?.data?.errors || {})?.[0] ??
        'Desculpe, Ocorreu um erro inesperado.';
      yield put(UIActionCreators.snackbarManagement('error', error, true));
    }
  } catch (e) {
    yield put(
      UIActionCreators.snackbarManagement(
        'error',
        'Desculpe, Ocorreu um erro inesperado.',
        true
      )
    );
  }
}

export default all([
  takeLatest(ActionTypes.GET_FINANCING_LISTING, getFinancingListing),
  takeLatest(ActionTypes.CREATE_FINANCING, createFinancing),
  takeLatest(ActionTypes.SIMULATE, simulate),
  takeLatest(ActionTypes.SIMULATE_BY_ID, simulateById),
  takeLatest(ActionTypes.SEND_CLIENT_FORM, sendClientForm),
  takeLatest(ActionTypes.APPLY_CREDIT_ANALYSIS, applyCreditAnalysis),
  takeLatest(ActionTypes.GET_CREDIT_ANALYSIS, getCreditAnalysis),
  takeLatest(ActionTypes.GET_STATES, getStates),
  takeLatest(ActionTypes.GET_CITIES_BY_STATE, getCitiesByState),
  takeLatest(ActionTypes.GET_FINANCING_ORDER_BY_ID, getFinancingById),
  takeLatest(ActionTypes.CREATE_CONTACT, createContact),
  takeLatest(ActionTypes.CONTRACT_FINANCING, contractFinancing),
  takeLatest(ActionTypes.PRINT_OUT_ORDER, printOutOrder),
  //OPTIONS_FORM
  takeLatest(ActionTypes.GET_GENDER_OPTIONS, getGendersOptions),
  takeLatest(ActionTypes.GET_CIVIL_STATUS_OPTIONS, getCivilStatusOptions),
  takeLatest(ActionTypes.GET_STREET_TYPE_OPTIONS, getStreetTypeOptions),
  takeLatest(ActionTypes.GET_ADDRESS_TYPE_OPTIONS, getAddressTypeOptions),
  takeLatest(ActionTypes.GET_CAREERS_OPTIONS, getCareersOptions),
  takeLatest(ActionTypes.GET_EMPLOYMENTS_OPTIONS, getEmploymentsOptions),
  takeLatest(ActionTypes.APPLY_SIMULATION, applySimulation),
  takeLatest(
    ActionTypes.GET_BANK_ACCOUNT_TYPE_OPTIONS,
    getBankAccountTypeOptions
  ),
  takeLatest(
    ActionTypes.GET_ALL_FINANCIAL_INSTITUTION_OPTIONS,
    getAllFinancialInstitutionOptions
  ),
  takeLatest(
    ActionTypes.GET_COMISSION_RETURN_OPTIONS,
    getcommissionReturnOptions
  ),
  takeLatest(
    ActionTypes.GET_ALL_DEALER_PJ_FINANCIAL_INSTITUTION,
    getAllDealerPJFinancialInstitution
  ),
  takeLatest(ActionTypes.CREATE_CAMPAIGN, createCampaign),
  takeLatest(ActionTypes.UPDATE_CAMPAIGN, updateCampaign),
  takeLatest(ActionTypes.GET_CAMPAIGN_BY_ID, getCampaignById),
  takeLatest(ActionTypes.POST_NEW_INSTITUTION, saveDataInstitution),
  takeLatest(ActionTypes.GET_BY_INSTITUTION, getInstitutionById),
  takeLatest(ActionTypes.UPDATE_INSTITUTION, updateInstitution),
]);
