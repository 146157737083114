import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background: #e8e8e8;
  }
`;
