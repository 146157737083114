import React, { useState, useEffect } from 'react';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import ProposalServices from 'services/proposalServices';
import isEmpty from 'helpers/isEmpty';
import List from './components/List';
import { ReactComponent as EmptyHistoryList } from 'assets/phone-actions-remove.svg';
import {
  LoadingContainer,
  EmptyContainer,
  EmptyHistoryListIcon,
} from './styles';

const ProposalHistoryList = ({ dealerId, contactId }) => {
  const [history, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getHistoryProposalsByContactId = async (dealerId, contactId) => {
    try {
      setIsLoading(true);
      const response: any = await ProposalServices.getProposalsByContactId(
        dealerId,
        contactId
      );
      if (response.success) {
        setHistoryData(response.data);
      } else {
        setHistoryData([]);
      }
      setIsLoading(false);
    } catch (e) {
      setHistoryData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistoryProposalsByContactId(dealerId, contactId);
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoadingContainer>
          <Progress large />
          Carregando informações de atendimento...
        </LoadingContainer>
      ) : isEmpty(history) ? (
        <LoadingContainer>
          <EmptyContainer>
            <EmptyHistoryListIcon>
              <EmptyHistoryList />
            </EmptyHistoryListIcon>
            Cliente não possui atendimento nos últimos 90 dias.
          </EmptyContainer>
        </LoadingContainer>
      ) : (
        <div>
          <List
            itensList={history}
            isLoading={isLoading}
            handleClickListItem={() => {
              return;
            }}
            handleClickEditListItem={() => {
              return;
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProposalHistoryList;
