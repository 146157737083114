import { all, takeLatest, put, select } from 'redux-saga/effects';
import LeadsManagerService from 'services/leadsManagerService';
import DealerServices from 'services/dealerServices';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import providerServices from 'services/providerServices';
import groupServices from 'services/groupServices';
import { Action } from 'types/ActionTypes';

function* getManagerProposalsInfos(action: Action) {
  try {
    const { filterByDates } = yield select(state => state.leads);
    yield put(ActionCreators.setProposalsInfosLoading(true));
    const { dealerId } = action.payload;
    const response = yield LeadsManagerService.getProposalsInfos(
      dealerId,
      null,
      filterByDates.initialDate,
      filterByDates.finalDate
    );
    if (response.success) {
      yield put(ActionCreators.setProposalsInfosSuccess(response.data));
      yield put(ActionCreators.setProposalsInfosLoading(false));
      return;
    } else {
      yield put(ActionCreators.setProposalsInfosError(true));
      yield put(ActionCreators.setProposalsInfosLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setProposalsInfosError(true));
    yield put(ActionCreators.setProposalsInfosLoading(false));
    console.log(e);
  }
}

function* getSellerProposalsInfos(action: Action) {
  try {
    const { filterByDates } = yield select(state => state.leads);
    const { dealerID, sellerID } = action.payload;
    yield put(ActionCreators.setProposalsInfosLoading(true));
    const response = yield LeadsManagerService.getProposalsInfos(
      dealerID,
      sellerID,
      filterByDates.initialDate,
      filterByDates.finalDate
    );
    if (response.success) {
      yield put(ActionCreators.setProposalsInfosSuccess(response.data));
      yield put(ActionCreators.setProposalsInfosLoading(false));
      return;
    } else {
      yield put(ActionCreators.setProposalsInfosLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setProposalsInfosLoading(false));
    console.log(e);
  }
}

function* getManagerConvertion(action: Action) {
  try {
    const { filterByDates } = yield select(state => state.leads);
    const { dealerId } = action.payload;
    yield put(ActionCreators.setConvertionLoading(true));
    const response = yield LeadsManagerService.getManagerConvertion(
      dealerId,
      null,
      filterByDates.initialDate,
      filterByDates.finalDate
    );
    if (response.success) {
      yield put(ActionCreators.setConvertionSuccess(response.data));
      yield put(ActionCreators.setConvertionLoading(false));
      return;
    } else {
      yield put(ActionCreators.setConvertionError(true));
      yield put(ActionCreators.setConvertionLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setConvertionError(true));
    yield put(ActionCreators.setConvertionLoading(false));
    console.log(e);
  }
}

function* getSellerConvertion(action: Action) {
  try {
    const { filterByDates } = yield select(state => state.leads);
    const { dealerId, sellerId } = action.payload;
    yield put(ActionCreators.setConvertionLoading(true));
    const response = yield LeadsManagerService.getSellerConvertion(
      dealerId,
      sellerId,
      filterByDates.initialDate,
      filterByDates.finalDate
    );
    if (response.success) {
      yield put(ActionCreators.setConvertionSuccess(response.data));
      yield put(ActionCreators.setConvertionLoading(false));
      return;
    } else {
      yield put(ActionCreators.setConvertionError(true));
      yield put(ActionCreators.setConvertionLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setConvertionError(true));
    yield put(ActionCreators.setConvertionLoading(false));
    console.log(e);
  }
}

function* getSellersProposals(action: Action) {
  try {
    const { filterByDates } = yield select(state => state.leads);
    const { dealerId } = action.payload;
    yield put(ActionCreators.setSellersProposalsLoading(true));
    const response = yield LeadsManagerService.getSellersProposals(
      dealerId,
      filterByDates.initialDate,
      filterByDates.finalDate
    );
    if (response.success) {
      yield put(ActionCreators.setSellersProposalsSuccess(response.data));
      yield put(ActionCreators.setSellersProposalsLoading(false));
      return;
    } else {
      yield put(ActionCreators.setSellersProposalsError(true));
      yield put(ActionCreators.setSellersProposalsLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setSellersProposalsError(true));
    yield put(ActionCreators.setSellersProposalsLoading(false));
    console.log(e);
  }
}

function* getProvidersProposals(action: Action) {
  try {
    const { dealerId, start, end } = action.payload;
    yield put(ActionCreators.setProvidersProposalsLoading(true));
    const response = yield LeadsManagerService.getProvidersProposals(
      dealerId,
      start,
      end
    );
    if (response.success) {
      yield put(ActionCreators.setProvidersProposalsSuccess(response.data));
      yield put(ActionCreators.setProvidersProposalsLoading(false));
      return;
    } else {
      yield put(ActionCreators.setProvidersProposalsError(true));
      yield put(ActionCreators.setProvidersProposalsLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setProvidersProposalsError(true));
    yield put(ActionCreators.setProvidersProposalsLoading(false));
    console.log(e);
  }
}

function* getSellerSchedule(action: Action) {
  try {
    const { dealerId, sellerId, start, end } = action.payload;
    yield put(ActionCreators.setSellerScheduleLoading(true));
    const response = yield LeadsManagerService.getSellerSchedulle(
      dealerId,
      sellerId,
      start,
      end
    );

    if (response.success) {
      yield put(ActionCreators.setSellerScheduleSuccess(response.data));
      yield put(ActionCreators.setSellerScheduleLoading(false));
      return;
    } else {
      yield put(ActionCreators.setSellerScheduleError(true));
      yield put(ActionCreators.setSellerScheduleLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setSellerScheduleError(true));
    yield put(ActionCreators.setSellerScheduleLoading(false));
    console.log(e);
  }
}

function* getSellerScheduleInfos(action: Action) {
  try {
    const { dealerId, sellerId, start, end } = action.payload;
    yield put(ActionCreators.setSellerScheduleInfosLoading(true));
    const response = yield LeadsManagerService.getSellerSchedulleInfos(
      dealerId,
      sellerId,
      start,
      end
    );
    if (response.success) {
      yield put(ActionCreators.setSellerScheduleInfosLoading(false));
      yield put(ActionCreators.getSellerScheduleInfosSuccess(response.data));
    } else {
      yield put(ActionCreators.setSellerScheduleInfosLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setSellerScheduleInfosLoading(false));
    console.log(e);
  }
}

function* getSellerActivities(action: Action) {
  try {
    const { dealerID, sellerID, start, end } = action.payload;
    yield put(ActionCreators.setSellerActivitiesLoading(true));
    const response = yield LeadsManagerService.getSellerActivities(
      dealerID,
      sellerID,
      start,
      end
    );
    if (response.success) {
      yield put(ActionCreators.setSellerActivitiesSuccess(response.data));
      yield put(ActionCreators.setSellerActivitiesLoading(false));
      return;
    } else {
      yield put(ActionCreators.setSellerActivitiesError(true));
      yield put(ActionCreators.setSellerActivitiesLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setSellerActivitiesError(true));
    yield put(ActionCreators.setSellerActivitiesLoading(false));
    console.log(e);
  }
}

function* setInitialAnnotationsIndex(action: Action) {
  try {
    const { initialAnnotationsIndex } = action.payload;

    yield put(
      ActionCreators.setInitialAnnotationsIndexSuccess(initialAnnotationsIndex)
    );

    return;
  } catch (e) {
    console.log(e);
  }
}

function* getDepartmentOptions() {
  try {
    const { dealerStore } = yield select(state => ({
      dealerStore: state.dealerStore,
    }));
    const { success, data } = yield DealerServices.getAllDepartamentByDealer(
      dealerStore.currentDealer.id
    );
    if (success) {
      yield put(
        ActionCreators.setDepartmentOptions([
          ...data.map(el => ({ value: el.code, label: el.name, id: el.id })),
        ])
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* getOriginsOptions(action: Action) {
  try {
    const { dealerStore } = yield select(state => ({
      dealerStore: state.dealerStore,
    }));
    const { isCallCenterOrGroupView, groupId, leadCreation } = action.payload;
    const { data, success } = yield (isCallCenterOrGroupView
      ? groupServices.getOriginsOptionsByGroupId
      : providerServices.getOriginsOptions)(
      isCallCenterOrGroupView ? groupId : dealerStore.currentDealer.id,
      leadCreation
    );
    if (success) {
      yield put(
        ActionCreators.setOriginOptions([
          ...data.map(el => ({ value: el.name, label: el.label })),
        ])
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* getChannelOptions(action: Action) {
  try {
    const { dealerStore } = yield select(state => ({
      dealerStore: state.dealerStore,
    }));
    const { isCallCenterOrGroupView, groupId } = action.payload;
    const { success, data }: any = yield (isCallCenterOrGroupView
      ? groupServices.getChannelsOptionsByGroupId
      : providerServices.getProviderLeadByDealer)(
      isCallCenterOrGroupView ? groupId : dealerStore.currentDealer.id
    );
    if (success) {
      yield put(ActionCreators.setChannelOptions([...data]));
    }
  } catch (e) {
    console.log('Error', e);
  }
}

export default all([
  takeLatest(ActionTypes.GET_SELLER_PROPOSALS_INFOS, getSellerProposalsInfos),
  takeLatest(ActionTypes.GET_MANAGER_PROPOSALS_INFOS, getManagerProposalsInfos),
  takeLatest(ActionTypes.GET_SELLER_ACTIVITIES, getSellerActivities),
  takeLatest(ActionTypes.GET_SELLER_CONVERTION, getSellerConvertion),
  takeLatest(ActionTypes.GET_MANAGER_CONVERTION, getManagerConvertion),
  takeLatest(ActionTypes.GET_SELLER_SCHEDULE, getSellerSchedule),
  takeLatest(ActionTypes.GET_SELLER_SCHEDULE_INFOS, getSellerScheduleInfos),
  takeLatest(
    ActionTypes.SET_INITIAL_ANNOTATIONS_INDEX,
    setInitialAnnotationsIndex
  ),
  takeLatest(ActionTypes.GET_PROVIDERS_PROPOSALS, getProvidersProposals),
  takeLatest(ActionTypes.GET_SELLERS_PROPOSALS, getSellersProposals),
  takeLatest(ActionTypes.GET_DEPARTMENT_OPTIONS, getDepartmentOptions),
  takeLatest(ActionTypes.GET_ORIGIN_OPTIONS, getOriginsOptions),
  takeLatest(ActionTypes.GET_CHANNEL_OPTIONS, getChannelOptions),
]);
