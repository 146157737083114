import ActionTypes from './actionTypes';
// import { Action } from 'types/ActionTypes'

export default {
  //SET CURRENT GROUP
  setCurrentDealerGroup: function(dealerGroup: object) {
    return { type: ActionTypes.SET_CURRENT_GROUP, payload: dealerGroup };
  },

  //ALL PROPOSALS BY GROUPS
  getAllProposalsbyGroup: function() {
    return {
      type: ActionTypes.GET_ALL_PROPOSALS_BY_GROUP,
    };
  },

  setAllProposalsbyGroupLoading: function(isLoading: boolean) {
    return {
      type: ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_LOADING,
      payload: isLoading,
    };
  },

  setAllProposalsbyGroup: function(proposals: any) {
    return {
      type: ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_SUCCESS,
      payload: proposals,
    };
  },

  setAllProposalsbyGroupError: function(error: any) {
    return {
      type: ActionTypes.SET_ALL_PROPOSALS_BY_GROUP_ERROR,
      payload: error,
    };
  },
};
