import React from 'react';

// Asset import
import { ReactComponent as MobiautoIcon } from 'assets/mobiauto-green.svg';

// Style import
import { Container } from './styles';

export function NoMessageSelected () {
  return (
    <Container>
      <MobiautoIcon />
      <h1>Suas conversas</h1>
      <span>Abra uma conversa ou inicie uma nova para ver mensagens e informações dos seus contatos.</span>
    </Container>
  );
}
