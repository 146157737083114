import ActionTypes from './actionTypes';
import { Action } from 'types/ActionTypes';
import { Proposal } from 'types/ProposalsTypes';
import { DATES_BY_PERIOD } from 'helpers/predefinedDates';

interface State {
  individualProposals: Proposal[];
  comboProposals: Proposal[];
  totalProposalsIndividual: number;
  totalProposalsCombo: number;
  isLoading: boolean;
  sellerCurrentTab: number;
  buyerCurrentTab: number;
  totalElements: number;
  totalPages: number;
  number: number;
  sellerOptions: {
    page: number;
    size: number;
    combo: boolean;
    isSeller: boolean;
    query: string;
    dateInitial: number;
    dateFinal: number;
    sellerId: number | null;
    status: string;
  };
  buyerOptions: {
    page: number;
    size: number;
    combo: boolean;
    isSeller: boolean;
    query: string;
    dateInitial: number;
    dateFinal: number;
    sellerId: number | null;
    status: string;
  };
}

const TODAY_DATE = DATES_BY_PERIOD.today.startDate.valueOf();
const FINAL_DATE = DATES_BY_PERIOD.thirtyDays.endDate.valueOf();

export const INITIAL_STATE: State = {
  individualProposals: [],
  comboProposals: [],
  totalProposalsIndividual: 0,
  totalProposalsCombo: 0,
  isLoading: false,
  sellerCurrentTab: 0,
  buyerCurrentTab: 0,
  totalElements: 0,
  totalPages: 0,
  number: 0,
  sellerOptions: {
    page: 0,
    size: 20,
    combo: false,
    isSeller: true,
    query: '',
    dateInitial: TODAY_DATE,
    dateFinal: FINAL_DATE,
    sellerId: null,
    status: 'NEW',
  },
  buyerOptions: {
    page: 0,
    size: 20,
    combo: false,
    isSeller: true,
    query: '',
    dateInitial: TODAY_DATE,
    dateFinal: FINAL_DATE,
    sellerId: null,
    status: 'ALL',
  },
};

export default function dealForm(
  state: State = INITIAL_STATE,
  action: Action
): State {
  switch (action.type) {
    case ActionTypes.SET_SELLER_PROPOSALS_OPTIONS: {
      return {
        ...state,
        sellerOptions: { ...state.sellerOptions, ...action.payload.options },
      };
    }
    case ActionTypes.SET_BUYER_PROPOSALS_OPTIONS: {
      return {
        ...state,
        buyerOptions: { ...state.buyerOptions, ...action.payload.options },
      };
    }
    case ActionTypes.SET_SELLER_PROPOSALS_CURRENT_TAB: {
      return {
        ...state,
        sellerCurrentTab: action.payload.currentTab,
      };
    }
    case ActionTypes.SET_BUYER_PROPOSALS_CURRENT_TAB: {
      return {
        ...state,
        buyerCurrentTab: action.payload.currentTab,
      };
    }
    case ActionTypes.SET_PROPOSALS_LOADING: {
      return { ...state, isLoading: true };
    }
    case ActionTypes.SET_PROPOSALS_INDIVIDUAL_SUCCEDED: {
      return {
        ...state,
        individualProposals:
          action.payload.proposals.content || INITIAL_STATE.individualProposals,
        totalProposalsIndividual: action.payload.proposals.totalElements,
        isLoading: false,
        totalElements: action.payload.proposals.totalElements,
        totalPages: action.payload.proposals.totalPages,
        number: action.payload.proposals.number,
      };
    }
    case ActionTypes.SET_PROPOSALS_COMBO_SUCCEDED: {
      return {
        ...state,
        comboProposals:
          action.payload.proposals.content || INITIAL_STATE.comboProposals,
        totalProposalsCombo: action.payload.proposals.totalElements,
        isLoading: false,
        totalElements: action.payload.proposals.totalElements,
        totalPages: action.payload.proposals.totalPages,
        number: action.payload.proposals.number,
      };
    }
    case ActionTypes.SET_PROPOSALS_INDIVIDUAL_ERROR: {
      return {
        ...state,
        individualProposals: INITIAL_STATE.individualProposals,
        isLoading: false,
      };
    }
    case ActionTypes.SET_PROPOSALS_COMBO_ERROR: {
      return {
        ...state,
        comboProposals: INITIAL_STATE.comboProposals,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
