import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'ResaleDealListService';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const defaultParams = {
  resale: true,
  page: 0,
  size: 20,
};
export default {
  getDeals: function(dealerId, options = {}) {
    const axiosConfig = {
      params: { ...defaultParams, ...options },
    };
    const body = {
      ...options,
      dealStatus: ['ACTIVE', 'PRE_INACTIVE'],
    };
    return request
      .post(
        `${domain}/dealer/${dealerId}/inventory/resale/v1.0/listing`,
        body,
        axiosConfig
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getDeals()`));
  },

  deleteDeal: function(dealerId, dealId) {
    return request
      .delete(`${domain}/dealer/${dealerId}/inventory/resale/v1.0/${dealId}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteInventory()`));
  },

  activateDeal: function(dealerId, dealId) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/inventory/resale/v1.0/activate/${dealId}`,
        { dealId }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.activateDeal()`));
  },

  inactivateDeal: function(dealerId, dealId) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/inventory/resale/v1.0/inactivate/${dealId}`,
        {
          dealId,
        }
      )
      .then(buildHTTPResponseHandler(`${serviceName}.inactivateDeal()`));
  },

  getInfoCardsById: function(dealerId) {
    return request
      .get(`${domain}/dealer/${dealerId}/resale/proposal/v1.0/cards`)
      .then(buildHTTPResponseHandler(`${serviceName}.getInfoCardsById()`));
  },
};
