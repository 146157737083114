import { Button, Table, TableCell } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const EmptyListText = styled.span`
  color: #757575;
  font-weight: 500;
  font-size: 14px;
  line-height: 4em;
`;

export const AddPaymentButton = styled(Button)`
  text-transform: none !important;
  color: white !important;
  background-color: #4c5fae !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
`;

export const PaymentTable = styled(Table)`
  background: #fff;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
`;

export const HeaderCell = styled(TableCell)`
  font-weight: bold !important;
  color: #424242 !important;
  padding: 12px !important;
  font-size: 12px !important;
`;

export const BodyCell = styled(TableCell)`
  padding: 0px 12px !important;
  max-width: 230px !important;
`;

export const PaymentValueColors = {
  add: '#4caf50',
  subtract: '#f44336',
  default: '#757575',
};

export const PaymentValue = styled.span`
  ${props =>
    css`
      color: ${PaymentValueColors[props.variant] || PaymentValueColors.default};
    `}
`;

export const PaymentValueContent = styled.div`
  display: flex;
  align-items: center;
`;
