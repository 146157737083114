import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const PaidChip = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 12px;
  width: fit-content;
  /* margin-left: 8px; */
  padding: 4px;
  font-family: Poppins Regular;
  border-radius: 50%;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  &.paid {
    background: #f0f6f6;
    color: #006b5d;
  }
  &.not-paid {
    background: #feeceb;
    color: #621b16;
  }
  > svg {
    font-size: 16px;
  }
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  width: fit-content;
  width: 24px;
  align-items: center;
  justify-content: center;
  font-family: Poppins Regular;
  border-radius: 50%;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  &.inTransit {
    background: #d4e6fd;
    color: #217ef3;
  }
  &.warning {
    background: #fff5e5;
    color: #ffb547;
  }
  &.active {
    background: #f0f6f6;
    color: #006b5d;
  }
  &.disabled {
    background: ${Colors.gray.nearWhite};
    > svg {
      color: ${Colors.gray.light};
    }
  }
  &.disabled-cone {
    background: ${Colors.gray.nearWhite};
    > svg {
      color: ${Colors.gray.light};
      > path {
        fill: ${Colors.gray.light};
      }
    }
  }
  > svg {
    font-size: 16px;
  }
`;

export const PlateContainer = styled.div`
  display: flex;
  border: 1px solid #757575;
  background-color: #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 22px;
  padding: 0px 4px;
`;

export const IconsLine = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
`;

export const IconComponent = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  > svg {
    color: #da2e22;
  }
`;
