import React from 'react';
import styled from 'styled-components';

const TabPanelComponent = styled.div`
  width: 100%;
  display: ${props => (props.value === props.index ? 'block' : 'none')};
`;

function TabPanel(props) {
  const { children, value, index, ...acc } = props;

  return (
    <TabPanelComponent value={value} index={index} id={`tab-${index}`} {...acc}>
      {children}
    </TabPanelComponent>
  );
}

export default TabPanel;
