import styled from 'styled-components';
import { Checkbox, withStyles } from '@material-ui/core';

const checkBoxStyles = () => ({
  root: {
    width: '0px',
    height: '0px',
    color: 'white',
    borderRadius: '4px',

    '&$checked': {
      background: 'white',
      borderRadius: '4px',
      width: '0px',
      height: '0px',
    },
  },
  checked: {},
});

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

export const ContainerIcon = styled.button`
  display: none;
  align-self: center;
  background: transparent;
  border: transparent;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #111;
  padding: 3px;
  padding-left: 5px;
  border-radius: 4px;
  position: relative;
  height: 23px;
  justify-content: space-between;
  border: ${({ hasChecked, hasInteraction }) =>
    hasInteraction
      ? hasChecked
        ? '2px solid #b5b5b5'
        : '2px solid #e5e5e5'
      : 'none'};
  cursor: pointer;
  &:hover ${ContainerIcon} {
    display: flex;
  }
`;

export const TagBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => (props.bgColor ? `${props.bgColor}` : null)};
  opacity: 0.25;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transform: ${({ hasChecked, hasInteraction }) =>
    hasInteraction ? (hasChecked ? 'scale(1)' : 'scale(0)') : 'scale(1)'};
  transition: 0.2s;
`;

export const TagColor = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#ddd')};
  border-radius: 4px;
`;
