import React from 'react';
import { Chip } from '@material-ui/core';

// Context import
import { useChatContext } from 'pages/chats/ChatsContext';

// Style import
import { Wrapper } from '../styles';

const originOptions = {
  INDICATION: 'Indicação',
  INTERNET: 'Internet',
  RECEPTIVE_CONNECTION: 'Ligação Receptiva',
  PASSING: 'Passante',
  PROSPECTION: 'Prospecção',
  OLX_PF: 'OLX Pessoa Física',
  CRM: 'CRM',
};

export function SourceAndChannel() {
  const { currentProposal }: any = useChatContext();

  const chipLabel = originOptions?.[currentProposal.origin] || "Não informado"

  return (
    <Wrapper>
      <span>Origem e canal:</span>
      <Chip label={chipLabel} style={{ marginTop: 16 }} />
    </Wrapper>
  );
}
