import React, { useEffect, useState } from 'react';
import Wrapper from 'components/Wrapper';
import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import TableDocuments from './components/TableDocuments';
import useGetMatrixDocuments from './hooks/useGetMatrixDocuments';
import { ReactComponent as ApprovationWhiteIcon } from 'assets/approvation-icon-white.svg';
import { IconContainer, Space } from '../../../styles';

const DocumentsStep = () => {
  const [data, setData] = useState([]);
  const { data: response, refetch } = useGetMatrixDocuments({});

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (response && response?.data?.length) {
      setData(typeof response?.data === 'object' ? response?.data : []);
    }
  }, [response]);

  if (!data.length) {
    return null;
  }

  return (
    <>
      <ExpansionPanel
        startExpanded
        panel={{
          title: (
            <ExpansionPanelTitle
              title={'Documentos'}
              IconComponent={
                <IconContainer>
                  <ApprovationWhiteIcon />
                </IconContainer>
              }
            />
          ),
        }}
      >
        <Wrapper flex direction="column" width="100%">
          <TableDocuments
            documents={data}
            updateDocumentsFunction={refetch}
            showActionButtons={false}
          />
        </Wrapper>
      </ExpansionPanel>
      <Space />
    </>
  );
};

export default DocumentsStep;
