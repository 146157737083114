import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Container = styled.div`
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 16px 16px 0px;
`;

export const Title = styled.div`
  font-family: Poppins bold;
  font-size: 16px;
  line-height: 24px;
  width: 222px;
  text-align: center;
`;

export const TopContainer = styled.div`
  display: flex;
  height: 50%;
  align-items: center;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e0f2f1;
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  justify-content: space-evenly;
`;

export const MobigestorText = styled.div`
  font-family: Poppins bold;
  color: ${Colors.primary.medium};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  width: 250px;
  text-align: center;
  color: #757575;
  line-height: 20px;
`;

export const MobiLogo = styled.div`
  position: absolute;
  top: -30px;
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary.medium};
`;
