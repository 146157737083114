import React from 'react';
import { Button, Grid } from '@material-ui/core';
import GenericDialog from 'components/GenericDialog';
import Wrapper from 'components/Wrapper';
import {
  AssociatedInvoiceText,
  ButtonStyled,
  NoteDescription,
  NoteTypeText,
  WarningContainer,
} from './style';
import ErrorIcon from '@material-ui/icons/Error';
import { useSelector } from 'react-redux';
import formatDate from 'helpers/formatDate';

export interface CancelOrderModalProps {
  open: boolean;
  onClose: () => void;
}

const CancelOrderModal = ({
  open,

  onClose,
}: CancelOrderModalProps) => {
  const { cancelInvoice }: any = useSelector(state => state?.order);

  return (
    <>
      <GenericDialog
        title={'Cancelar pedido'}
        open={open}
        onClose={onClose}
        DialogAction={() => (
          <>
            <Wrapper
              flex
              justify="end"
              direction={'row'}
              gap={16}
              style={{ width: '100%' }}
            >
              <ButtonStyled onClick={onClose}>Voltar</ButtonStyled>
            </Wrapper>
          </>
        )}
      >
        <WarningContainer color={'#663D00'} background={'#FFF5E5'}>
          <ErrorIcon style={{ color: '#663D00' }} />
          <span>
          Antes de seguir com o cancelamento do pedido, é preciso cancelar a nota fiscal associada no menu “Fiscal”.
          </span>
        </WarningContainer>
        <Wrapper my={32}>
          <AssociatedInvoiceText>Nota fiscal associada:</AssociatedInvoiceText>
        </Wrapper>

        <Wrapper
          style={{ border: '1px solid #E0E0E0', borderRadius: '4px' }}
          p={16}
        >
          <NoteTypeText>Nota de saída</NoteTypeText>
          <Wrapper flex direction={'row'} gap={16}>
            <Grid item xs={12} md={3}>
              <Wrapper flex direction={'column'}>
                <NoteDescription>Data</NoteDescription>
                <NoteDescription color={'#424242'}>{formatDate(cancelInvoice?.data?.notaFiscalDeSaida?.dataEmissao)}</NoteDescription>
              </Wrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Wrapper flex direction={'column'}>
                <NoteDescription>Chave:</NoteDescription>
                <NoteDescription color={'#424242'}>{cancelInvoice?.data?.notaFiscalDeSaida?.chaveNfe}</NoteDescription>
              </Wrapper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Wrapper flex direction={'column'}>
                <NoteDescription>Número:</NoteDescription>
                <NoteDescription color={'#424242'}>{cancelInvoice?.data?.notaFiscalDeSaida?.numero}</NoteDescription>
              </Wrapper>
            </Grid>
          </Wrapper>
        </Wrapper>
      </GenericDialog>
    </>
  );
};

export default CancelOrderModal;
