import React from 'react';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import { GridColDef } from '@material-ui/data-grid';
import { Radio } from '@material-ui/core';
import Wrapper from 'components/Wrapper';
import ListStoresComponent from './ListStoresComponent';

const ListStoresContainer = ({ handleChangeStore, state }) => {
  const customNoRowsOverlay = () => {
    return (
      <Wrapper flex align={'center'} justify={'center'} mt={100} width={'100%'}>
        Nenhum resultado encontrado.
      </Wrapper>
    );
  };

  const loadingOverlay = () => (
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: 200,
      }}
    >
      <Progress large />
    </div>
  );

  const columns: GridColDef[] = [
    {
      field: 'store',
      headerName: 'Loja',
      flex: 6,
      headerClassName: 'table-header',
      cellClassName: 'table-body',
    },
    {
      field: 'state',
      headerName: 'Estado',
      flex: 3,
      headerClassName: 'table-header',
      cellClassName: 'table-body',
      renderCell: params => {
        return (
          <Wrapper>
            {state?.states?.find(el => el?.id === params.value)?.name}
          </Wrapper>
        );
      },
    },
    {
      field: 'city',
      headerName: `Cidade`,
      flex: 3,
      headerClassName: 'table-header',
      cellClassName: 'table-body',
    },
    {
      field: 'brand',
      headerName: 'Marca',
      flex: 3,
      headerClassName: 'table-header',
      cellClassName: 'table-body',
    },
    {
      field: 'action',
      headerName: ' ',
      flex: 1,
      headerClassName: 'table-header',
      cellClassName: 'table-body',
      align: 'right',
      renderCell: params => {
        return (
          <Wrapper flex justify="end">
            <Radio
              checked={state?.selectedStore == params.value}
              onChange={handleChangeStore}
              value={params.value}
            />
          </Wrapper>
        );
      },
    },
  ];

  const buildDataGridDate = array => {
    return array?.map(element => {
      const rowData = {
        id: element?.id,
        store: element?.name,
        state: element?.state,
        city: element?.city,
        brand: element?.makeName,
        action: element?.id,
      };
      return rowData;
    });
  };

  const viewComponentProps = {
    state,
    columns,
    buildDataGridDate,
    customNoRowsOverlay,
    loadingOverlay,
  };

  return <ListStoresComponent {...viewComponentProps} />;
};

export default ListStoresContainer;
