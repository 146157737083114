import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import envVars from 'helpers/envVars';
import request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import { FilterOption } from '../interfaces';
import useCustomMutation from './useCustomMutation';

interface Props {
  apiProperty: string;
  selectedValues?: FilterOption[];
}

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'DealerServices';

export default function useTags({ apiProperty, selectedValues }: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[] | null>(
    null
  );

  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  const dealerId = currentDealer.id;

  function useGetAllTags() {
    return useCustomMutation({
      key: 'getAllTags',
      handleReturn: {
        onError: () => {
          dispatch(
            UIActionCreators.snackbarManagement(
              'error',
              'Ocorreu um erro ao consultar "Tags".'
            )
          );
        },
      },
      request: async () => {
        const response = await request
          .get(`${domain}/dealer/${dealerId}/tag/v1.0`, {})
          .then(response => {
            buildHTTPResponseHandler(`${serviceName}.getAllTags()`);
            return response?.data as any;
          });
        return response;
      },
    });
  }

  const { data, isLoading, mutate: getAllTags } = useGetAllTags();

  useEffect(() => {
    if (options.length) {
      if (!selectedValues.length && selectedOptions) {
        setSelectedOptions(null);
      }
      if (selectedValues && selectedValues.length) {
        const filtered = options.filter(option => {
          return selectedValues.some(selected => {
            return selected?.value === option.value;
          });
        });
        setSelectedOptions(filtered);
      }
    }
  }, [options, selectedValues]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(el => ({
        filterGroupId: apiProperty,
        id: `${apiProperty}-${el?.id}`,
        label: el?.name,
        value: String(el?.id),
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOptions,
    getAllTags,
    isLoading,
  };
}
