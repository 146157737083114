/* eslint-disable react/jsx-no-target-blank */
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useChatContext } from 'pages/chats/ChatsContext';
import { ReactComponent as TrashIcon } from 'assets/trash-gray.svg';
import BookedTag from 'components/BookedTag/BookedTag';
import React, { useEffect, useState } from 'react';
import { userTypes } from 'types/LeadsTypes';
import BookDetailDialog from './BookDetailDialog';
import CancelBookDialog from './CancelBookDialog';
import Box from '@material-ui/core/Box';
import LinkIcon from 'assets/link-icon.svg';
import {
  BookedVehicleContainer,
  BookedVehicleName,
  IconButton,
} from './styles';

export default function BookedVehicleInfos(props) {
  const { currentProposal }: any = useChatContext();
  const { userStore } = useSelector(state => ({
    userStore: state.userStore,
  }));
  const { updateProposal } = props;

  const [openCancelBook, setOpenCancelBook] = useState(false);
  const [openBookDetail, setOpenBookDetail] = useState(false);

  const [hasPermissionToCancel, setHasPermissionToCancel] = useState<
    boolean | null
  >(null);

  const handleOnCloseCancelBook = (isCancelled: boolean) => {
    setOpenCancelBook(false);
    if (isCancelled) {
      updateProposal();
    }
  };

  const verifyCancelPermission = async () => {
    const currentUserId: number = parseInt(userStore?.userSite?.id);
    const currentUserType: userTypes = userStore?.userType;

    if ([userTypes.MANAGER, userTypes.OWNER].includes(currentUserType))
      setHasPermissionToCancel(true);
    else if (currentUserType === userTypes.SELLER)
      setHasPermissionToCancel(
        currentUserId === currentProposal.bookedSellerId
      );
    else setHasPermissionToCancel(false);
  };

  useEffect(() => {
    verifyCancelPermission();
  }, []);

  const getCancelButtonTitle = () => {
    return hasPermissionToCancel === null
      ? 'Carregando permissões...'
      : hasPermissionToCancel
      ? 'Cancelar reserva'
      : 'Para cancelar, você precisa ser o vendedor da reserva';
  };

  return (
    <>
      <BookedVehicleContainer>
        <div>
          <BookedVehicleName onClick={() => setOpenBookDetail(true)}>
            {currentProposal.bookedVehicle}
          </BookedVehicleName>
          <BookedTag bookedAt={currentProposal.bookedAt} />
          {currentProposal?.link && (
            <a
              href={currentProposal?.link}
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <Box
                component="div"
                color="#4443BC"
                display="grid"
                gridTemplateColumns="20px 100px"
                align-items="center"
              >
                <img src={LinkIcon} />
                <Box component="span">Ver anuncio</Box>
              </Box>
            </a>
          )}
        </div>
        <Tooltip title={getCancelButtonTitle()} style={{ fontSize: 12 }}>
          <span>
            {!props.closedProposal && (
              <IconButton
                disabled={!hasPermissionToCancel}
                onClick={() => setOpenCancelBook(true)}
              >
                <TrashIcon />
              </IconButton>
            )}
          </span>
        </Tooltip>
      </BookedVehicleContainer>
      <BookDetailDialog
        open={openBookDetail}
        onClose={() => setOpenBookDetail(false)}
        bookedId={currentProposal.bookedId}
        onBookCancelled={() => handleOnCloseCancelBook(true)}
      />
      <CancelBookDialog
        open={openCancelBook}
        onClose={handleOnCloseCancelBook}
        bookedAt={currentProposal.bookedAt}
        bookedId={currentProposal.bookedId}
      />
    </>
  );
}
