import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid).attrs({ container: false, item: true })`
  border-right: 1px solid #e8e8e8;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export const Content = styled.div`
  height: 300px;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
