import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const WarningContainer = styled.div`
  display: flex;
  background: ${({ background }) => background};
  border-radius: 4px;
  color: ${({ color }) => color};
  min-height: 56px;
  align-items: center;
  padding: 0px 18px;
  & > span {
    margin-left: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
  }
`;

export const ButtonStyled = styled(Button)`
    padding: 10px 16px !important;
    font-family: Poppins Medium !important;
    font-weight: 500 !important
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: inherit !important;
    background: ${({ background }) => background || 'transparent'} !important;
    color:  ${({ color }) => color || '#000000'} !important;
`;

export const AssociatedInvoiceText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #424242;
`;

export const NoteTypeText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  letter-spacing: -0.02em;

  color: #424242;
`;


export const NoteDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color:  ${({ color }) => color || '#757575'} !important;
  `;

