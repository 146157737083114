import React from 'react';
import moment from 'moment';
import { Grid, CircularProgress } from '@material-ui/core';
import getInitialsName from 'helpers/getInitialsName';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import OutlinedButton from 'components/atoms/OutlinedButton';
import CancelBlockedDialog from 'modules/store-inventory/components/Dialogs/BlockedDialogs/CancelBlockedDialog';
import AddBlockedDialog from 'modules/store-inventory/components/Dialogs/BlockedDialogs/AddBlockedDialog';
import VehicleItem from 'components/VehicleItem/VehicleItem';
import {
  Divider,
  BookInfo,
  SubTitle,
  ListLoading,
  Avatar,
  Text,
  VericalDivider,
} from './styles';

const BlockedDetailsComponent = ({
  open,
  onClose,
  dealerId,
  dealId,
  blokedData,
  isLoading,
  setBlockedState,
  blockedState,
  currentDeal,
  getBlockDetails,
  callbackOnSuccess,
  isManagerOrOwner,
}) => {
  return (
    <>
      <GenericDialog
        open={open}
        onClose={onClose}
        title={'Informações de Bloqueio'}
        maxWidth="sm"
        DialogAction={() => (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
            }}
          >
            <OutlinedButton onClick={onClose}>Cancelar</OutlinedButton>
            {isManagerOrOwner() && (
              <>
                <div style={{ width: 8 }} />
                {blokedData ? (
                  <>
                    {blokedData?.id && (
                      <ThemeButton
                        onClick={() =>
                          setBlockedState(prevState => ({
                            ...prevState,
                            openCancelBlocked: true,
                          }))
                        }
                      >
                        Cancelar Bloqueio
                      </ThemeButton>
                    )}
                  </>
                ) : (
                  <>
                    {!currentDeal.blocked && (
                      <ThemeButton
                        onClick={() =>
                          setBlockedState(prevState => ({
                            ...prevState,
                            openAddBlocked: true,
                          }))
                        }
                      >
                        Bloquear Veiculo
                      </ThemeButton>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}
      >
        {isLoading ? (
          <ListLoading>
            <CircularProgress />
            <span>Carregando dados do bloqueio...</span>
          </ListLoading>
        ) : (
          <>
            {blokedData ? (
              <>
                <SubTitle>Veículo</SubTitle>
                {blokedData?.vehicle && (
                  <VehicleItem
                    vehicle={{
                      ...blokedData?.vehicle,
                      ...currentDeal,
                      color:
                        currentDeal?.exteriorColor || currentDeal?.colorName,
                    }}
                    isSelectable={false}
                    showBooked={false}
                  />
                )}
                <Divider />
                <SubTitle>Loja</SubTitle>
                <BookInfo>{blokedData?.dealer?.name}</BookInfo>
                <Divider />
                <Grid container>
                  {blokedData?.salesManager && (
                    <>
                      <Grid item xs={5}>
                        <SubTitle>Gestor responsável</SubTitle>
                        <BookInfo>
                          <Avatar>
                            {getInitialsName(blokedData?.salesManager?.name)}
                          </Avatar>
                          <span>{blokedData?.salesManager?.name}</span>
                        </BookInfo>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={1}>
                    <VericalDivider />
                  </Grid>
                  <Grid item xs={6}>
                    <SubTitle>Motivo:</SubTitle>
                    <BookInfo>
                      <span>{blokedData?.reason}</span>
                    </BookInfo>
                    {blokedData?.blockedAt && (
                      <div>
                        <SubTitle>Bloquado até:</SubTitle>{' '}
                        {moment(blokedData?.blockedAt).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Divider end />
              </>
            ) : (
              <>
                <Text>
                  {currentDeal.blocked ? currentDeal?.blockedReason : 'Veículo desbloqueado, deseja realizar um bloqueio?'}
                </Text>
              </>
            )}
          </>
        )}
      </GenericDialog>
      {blockedState.openAddBlocked && (
        <AddBlockedDialog
          open={blockedState.openAddBlocked}
          onClose={() =>
            setBlockedState(prevState => ({
              ...prevState,
              openAddBlocked: false,
            }))
          }
          dealerId={dealerId}
          dealId={dealId}
          callbackOnSuccess={() => {
            getBlockDetails();
            callbackOnSuccess();
          }}
        />
      )}
      {blockedState.openCancelBlocked && (
        <CancelBlockedDialog
          open={blockedState.openCancelBlocked}
          onClose={() =>
            setBlockedState(prevState => ({
              ...prevState,
              openCancelBlocked: false,
            }))
          }
          dealerId={dealerId}
          blockedId={blokedData?.id}
          callbackOnSuccess={() => {
            getBlockDetails();
            callbackOnSuccess();
          }}
        />
      )}
    </>
  );
};

export default BlockedDetailsComponent;
