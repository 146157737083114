import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import getImage from 'components/getImage';
import formatAmount from 'helpers/formatAmount';
import { styles, InfosContainer, Wrapper } from './styles';
import { getDiffDays } from 'modules/mobiOne/helpers/getDiffDays';

function VehicleByMakeListCard(props) {
  const { classes, deal } = props;

  let linkImage = null;
  if (deal.imageId || deal.images?.length) {
    linkImage = getImage(
      {
        imageId: deal?.imageId || deal.images?.[0]?.imageId,
        imageVcode: deal?.imageVcode || deal.images?.[0]?.imageVcode,
      },
      80,
      290,
      0
    );
  }

  return (
    <Card className={classes.card} onClick={props.handleClick}>
      <CardMedia
        className={classes.cover}
        image={linkImage || require('assets/default-image.svg')}
        title="Carro"
      />
      <InfosContainer>
        <Wrapper>
          <CardActions
            classes={{ root: classes.title }}
            style={{ padding: '0 8px', margin: 0 }}
          >
            <Typography component="p" className={classes.modelName}>
              {`${deal?.fullName || deal?.title}`}
            </Typography>
          </CardActions>

          <CardActions
            classes={{ root: classes.spec }}
            style={{ padding: '0 8px', margin: 0 }}
          >
            {deal.finalYear && (
              <Tooltip title="Ano Modelo">
                <Typography component="p" className={classes.types}>
                  {deal.finalYear}
                </Typography>
              </Tooltip>
            )}
            {deal.transmissionName && (
              <Tooltip title="Transmissão">
                <Typography component="p" className={classes.types}>
                  {deal.transmissionName}
                </Typography>
              </Tooltip>
            )}
            {(deal.price || deal.price0km) && (
              <Tooltip title="Preço">
                <Typography component="p" className={classes.types}>
                  {formatAmount(deal.price, 'PT-BR', 'R$') ||
                    formatAmount(deal.price0km, 'PR-BR', 'R$')}
                </Typography>
              </Tooltip>
            )}
            {deal?.inStockDate && (
              <Tooltip title="Dias em estoque">
                <Typography component="p" className={classes.types}>
                  {getDiffDays(deal?.inStockDate)}
                </Typography>
              </Tooltip>
            )}
          </CardActions>
        </Wrapper>
        {props.selectComponent && (
          <Wrapper style={{ padding: 8 }}>{props.selectComponent}</Wrapper>
        )}
      </InfosContainer>
    </Card>
  );
}

export default withStyles(styles)(VehicleByMakeListCard);
