import React from 'react';

import Box from '@material-ui/core/Box';
import GenericDialog from 'components/GenericDialog';

const WarningDialogComponent = ({ open, onClose, currentDeal }) => {
  return (
    <>
      <GenericDialog
        open={open}
        onClose={onClose}
        title={'Avisos'}
        maxWidth="sm"
        DialogAction={() => null}
      >
        <Box
          component="div"
          display="flex"
          justifyContent="center"
          fontSize="16px"
        >
          {currentDeal?.externalWarning || 'Mensagem de Aviso'}
        </Box>
      </GenericDialog>
    </>
  );
};

export default WarningDialogComponent;
