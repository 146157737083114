import styled, { css } from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

const orange = css`
  background: #ff9800 !important ;
`;

const green = css`
  background: #00b4aa !important ;
`;

const  secondary = css`
background: #3E68BD !important ;

`

const red = css`
  background: #f44336 !important;
  color: '#FFFFFF';
  padding: '10px 16px';
  font-family: 'Poppins Medium';
  font-weight: 500;
  font-size: '14px;';
  line-height: '20px';
  text-transform: 'inherit';
`;

const purple = css`
  font-weight: 500;
  background: #4443bc !important;
  color: #ffffff !important ;
`;

export const ThemeButton = styled(Button)`
  display: flex;
  background: ${({ theme }) => `${theme.primary}`} !important;
  color: #fff !important;
  text-transform: inherit !important;
  ${props => props.variant === 'orange' && orange};
  ${props => props.variant === 'green' && green};
  ${props => props.variant === 'purple' && purple}
  ${props => props.variant === 'red' && red}
  ${props => props.variant === 'secondary' && secondary}
`;
