import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useChatContext } from 'pages/chats/ChatsContext';
import ProposalServices from 'services/proposalServices';
import handlePDFBlobFile from 'helpers/handlePDFBlobFile';
import OrderServices from 'modules/order/services/OrderServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import ServiceHistoryComponent from './ServiceHistoryComponent';

interface ServiceDataProps {
  proposalId: number;
  createdAt: number;
  status: string;
  origin: string;
  sellerName: string;
  scheduled: boolean;
  hasOrder: boolean;
  finishedAt: number;
  schedulingDone: string;
  orderId: number;
}

const ServiceHistoryContainer = () => {
  const { currentProposal }: any = useChatContext();
  const { dealerId, contactId } = currentProposal;
  const dispatch = useDispatch();

  const [serviceData, setServiceData] = useState<ServiceDataProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getServiceHistoryData = useCallback(async () => {
    try {
      const response: any = await ProposalServices.getContactHistory(
        dealerId,
        contactId
      );
      if (response.success) {
        const historyServiceList = response.data.map(
          (element: ServiceDataProps) => ({
            proposalId: element.proposalId,
            createdAt: moment(element.createdAt).format('L'),
            status: element.status,
            origin: element.origin,
            sellerName: element.sellerName,
            scheduled: element.scheduled,
            hasOrder: element.hasOrder,
            finishedAt: moment(element.finishedAt).format('L'),
            schedulingDone: element.schedulingDone,
            orderId: element?.orderId,
          })
        );

        setServiceData(historyServiceList);
        setIsLoading(false);
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Desculpe, Ocorreu um erro inesperado.'
          )
        );
        setIsLoading(false);
      }
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
      setIsLoading(false);
    }
  }, [contactId, dealerId, dispatch]);

  useEffect(() => {
    if (contactId) {
      getServiceHistoryData();
      return;
    }
    setIsLoading(false);
  }, [getServiceHistoryData, contactId]);

  const handleSearchActionClick = async orderId => {
    try {
      const result: any = await OrderServices.downloadContract(
        dealerId,
        orderId
      );
      if (result?.success) {
        handlePDFBlobFile(result.data, `pedido-${dealerId}-${orderId}`, true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const viewComponentProps = {
    serviceData,
    isLoading,
    handleSearchActionClick,
  };

  return <ServiceHistoryComponent {...viewComponentProps} />;
};

export default ServiceHistoryContainer;
