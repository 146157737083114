import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { all, takeLatest, select, put, call } from 'redux-saga/effects';
import InventorySearchServices from 'modules/store-inventory/services/InventorySearchServices';
import { convertObjectSopToString } from 'helpers/sop';
import { STOCK_KIND_TRANSLATE_ID } from 'modules/store-inventory/helpers/TranslateAggregations';
import isEmpty from 'helpers/isEmpty';
import {
  Types as StoryInventoryTypes,
  Creators as StoryInventoryCreators,
} from './reducers/index';
// import ActionTypes from './reducers/actionTypes';
// import ActionCreators from './reducers/actionCreators';
// import {
//   FilterAggregations,
//   FilterElement,
// } from 'modules/store-inventory/types/Filter';

function* showError(message) {
  yield put(UIActionCreators.snackbarManagement('error', message));
}

// function sortElementsDesc(elements: FilterElement[]) {
//   return elements.sort((a, b) => b.numElementsFounded - a.numElementsFounded);
// }

// function sortAggregationElements(aggregations: FilterAggregations) {
//   const sortedAggregations = Object.keys(aggregations)
//     .filter(aggregation => aggregations[aggregation].elements)
//     .reduce((result, aggregation) => {
//       result[aggregation] = {
//         elements: sortElementsDesc(aggregations[aggregation].elements),
//       };
//       return result;
//     }, {});

//   return sortedAggregations;
// }

function* searchInventory() {
  const {
    sop,
    currentDealer,
    aggregations,
    currentPage,
    pageSize,
    order,
  } = yield select(state => ({
    ...state.storeInventory,
    ...state.dealerStore,
  }));

  yield put(StoryInventoryCreators.setLoadingSearch(true));

  try {
    //WITH DEALHER HAS PRODUCT MOBIONE ADD "STK_T" TO SOP
    if (
      currentDealer.products.find(product => product.type === 'MOBIONE') &&
      isEmpty(sop?.['stk_'])
    ) {
      yield put(StoryInventoryCreators.onChangeSop('stk_', 't', null));
      return;
    }

    //
    if (
      isEmpty(aggregations) &&
      currentDealer?.stockType &&
      sop?.['skd_'] === null
    ) {
      const currentStockKindFilter = Object.values(
        STOCK_KIND_TRANSLATE_ID
      ).find(el => el.value === currentDealer.stockType);

      if (currentStockKindFilter) {
        yield put(
          StoryInventoryCreators.onChangeSop(
            'skd_',
            currentStockKindFilter.id,
            {
              name: currentStockKindFilter.label,
              aggregation: 'STOCK_KIND',
              id: currentStockKindFilter.id,
            }
          )
        );
        if (currentStockKindFilter.value === STOCK_KIND_TRANSLATE_ID[1].value) {
          yield put(
            StoryInventoryCreators.onChangeSop(
              'skd_',
              STOCK_KIND_TRANSLATE_ID[2].id,
              {
                name: STOCK_KIND_TRANSLATE_ID[2].label,
                aggregation: 'STOCK_KIND',
                id: STOCK_KIND_TRANSLATE_ID[2].id,
              }
            )
          );
        }
        return;
      }
    }

    const response = yield call(InventorySearchServices.searchDeals, {
      dealerId: currentDealer.id,
      page: currentPage,
      size: pageSize,
      sop: convertObjectSopToString(sop),
      order: order || 0,
    });
    if (response.success === false)
      yield showError('Não foi possível realizar a consulta do estoque.');
    else {
      yield put(StoryInventoryCreators.setDealPage(response.data));
      // const sortedAggregations = sortAggregationElements(
      //   response.data?.aggregations
      // );
      yield put(StoryInventoryCreators.setLoadingSearch(false));
      if (!aggregations.DEALER) {
        return yield put(
          StoryInventoryCreators.setAggregations({
            ...response.data?.aggregations,
          })
        );
      }
      yield put(
        StoryInventoryCreators.setAggregations({
          ...response.data?.aggregations,
          DEALER: aggregations.DEALER,
          // STOCK_KIND: aggregations.STOCK_KIND,
        })
      );

      //TODO: IS FILTER STATIC
      // yield put(
      //   StoryInventoryCreators.setAggregations(
      //     !isEmpty(aggregations) ? aggregations : sortedAggregations
      //   )
      // );
    }
  } catch (e) {
    console.error(e);
    yield showError('Não foi possível realizar a consulta do estoque.');
    yield put(StoryInventoryCreators.setLoadingSearch(false));
  }
  yield put(StoryInventoryCreators.setLoadingSearch(false));
}

function* searchInventoryMore() {
  const { sop, dealPage, currentDealer, order } = yield select(state => ({
    ...state.storeInventory,
    ...state.dealerStore,
  }));
  yield put(StoryInventoryCreators.setLoadingSearchMore(true));
  try {
    const response = yield call(InventorySearchServices.searchDeals, {
      dealerId: currentDealer.id,
      sop: convertObjectSopToString(sop),
      page: dealPage.page || 0,
      order: order || 0,
    });

    if (response.success === false || !dealPage)
      yield showError('Não foi possível realizar a consulta do estoque.');
    else {
      const newPage = {
        ...response?.data,
        results: [...dealPage.results, ...response?.data?.results],
      };
      yield put(StoryInventoryCreators.setDealPage(newPage));
    }
  } catch (e) {
    yield showError('Não foi possível realizar a consulta do estoque.');
    yield put(StoryInventoryCreators.setLoadingSearchMore(false));
  }
  yield put(StoryInventoryCreators.setLoadingSearchMore(false));
}

export default all([
  takeLatest(StoryInventoryTypes.SEARCH_INVENTORY, searchInventory),
  takeLatest(StoryInventoryTypes.SEARCH_INVENTORY_MORE, searchInventoryMore),
]);
