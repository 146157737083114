import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'InventorySearchServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default class InventorySearchServices {
  static searchDeals({ dealerId, order = 1, page = 0, size = 200, sop }) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/search`,
        { sop, page, size, order },
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.searchDeals()`) as any);
  }
  static getDeal({ dealerId, dealId }) {
    return request
      .get(
        `${domain}/dealer/${dealerId}/inventory/v1.0/search/deal/${dealId}`,
        {}
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getDeal()`) as any);
  }
  static vehiclePriceSimulation(dealerId, simulateObj) {
    return request
      .post(
        `${domain}/dealer/${dealerId}/proposal/order/v1.0/vehicle-price-simulation`,
        simulateObj
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.vehicle-price-simulation()`
        ) as any
      );
  }

  //BLOCK VEHICLE
  static addBlockVehicle(dealerId, dealId, reason, blockedAt = null) {
    return request
      .put(
        `${domain}/dealer/${dealerId}/block/v1.0/${dealId}?reason=${reason}${blockedAt &&
          `&blockedAtLong=${blockedAt}`}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.addBlockVehicle()`) as any
      );
  }

  static cancelBlockVehicle(dealerId, id, reason) {
    return request
      .delete(
        `${domain}/dealer/${dealerId}/block/v1.0/${id}?reason=${reason}`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.cancelBlockVehicle()`) as any
      );
  }

  static getBlockDetails(dealerId, dealId) {
    return request
      .get(`${domain}/dealer/${dealerId}/block/v1.0/${dealId}`, {})
      .then(
        buildHTTPResponseHandler(`${serviceName}.getBlockDetails()`) as any
      );
  }
}
