import request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'FandiService';

type dealerId = number | string;
type proposalId = number | string;

export default class FandiServices {
  static toObtainGuid = obj => {
    return request
      .post('https://core.fandi.com.br/v1/checkout/obter-guid', obj, {
        headers: {
          'fandi-tipo-servico': 'checkout',
        },
      })
      .then(buildHTTPResponseHandler(`${serviceName}.obterGuid()`) as any);
  };
}
