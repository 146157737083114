import isEmpty from 'helpers/isEmpty';
import { DealerFromStore } from 'components/withDealer';

function getDealer(dealer) {
  if (isEmpty(dealer)) {
    return [];
  }
  return [
    {
      name: `${(dealer || {}).name} ( ${dealer.id})`,
      text: `${(dealer || {}).name} ( ${dealer.id} )`,
      link: window.location.pathname,
      groupId: dealer.id,
    },
  ];
}

export default function buildPathWithDealer(path = []) {
  const currentDealer = DealerFromStore.getDealerGroupFromLocalStorage();
  const pathWithDealer = getDealer(currentDealer);
  pathWithDealer.push(...path);
  return pathWithDealer;
}
