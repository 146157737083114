import React from 'react';
import { ReactComponent as CircleCloseIcon } from 'assets/close-circle-icon-gray.svg';
import { Wrapper } from './styles';

const CloseButton = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <CircleCloseIcon style={{ width: 28, height: 28 }} />
    </Wrapper>
  );
};

export default CloseButton;
