import React from 'react';
import Select from 'modules/financing/components/atoms/Select';
import Grid from '@material-ui/core/Grid';
import AutoComplete from 'components/atoms/AutoComplete'
import { TextMaskCustom } from 'components/MaskTextInput';
import TextInput from 'modules/financing/components/atoms/TextField';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput'

const ReferencesForm = props => {
  const {
    financialInstitutionsOptions,
    bankAccountTypeOptions,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton
  } = props;

  return (
    <div>
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <AutoComplete
            name="referenceBankId"
            label="Selecione o Banco"
            options={financialInstitutionsOptions}
            value={formData?.['referenceBankId'] ? {
              id: formData['referenceBankId'],
              value: formData['referenceBankId'],
              label: financialInstitutionsOptions.find(e => e.value === formData['referenceBankId'])?.label,
              name: financialInstitutionsOptions.find(e => e.value === formData['referenceBankId'])?.label,
            } : null}
            onChange={(e) => onChange({ target: { value: e.value, name: 'referenceBankId' } })}
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="referenceBankAccountType"
            name="referenceBankAccountType"
            label="Tipo de conta"
            value={formData['referenceBankAccountType']}
            onChange={onChange}
            options={bankAccountTypeOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="referenceBankAgency"
            name="referenceBankAgency"
            label="Agência"
            value={formData['referenceBankAgency']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('referenceBankAgency')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="referenceBankAccount"
            name="referenceBankAccount"
            label="Conta"
            value={formData['referenceBankAccount']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('referenceBankAccount')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="referenceBankAgencyDigit"
            name="referenceBankAgencyDigit"
            label="Digíto"
            value={formData['referenceBankAgencyDigit']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('referenceBankAgencyDigit')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="referenceName"
            name="referenceName"
            value={formData['referenceName']}
            label="Nome da referencia"
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('referenceName')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="referencePhone"
            name="referencePhone"
            label="Celular"
            value={formData['referencePhone']}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('referencePhone')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            id="referenceRelationship"
            name="referenceRelationship"
            label="Relacionamento"
            value={formData['referenceRelationship']}
            onChange={onChange}
            disabled={disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton onClick={() => contentCopyByNameInput('referenceRelationship')}>
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReferencesForm;
