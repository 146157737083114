import React from 'react';
import GenericDialog from 'components/GenericDialog';
import ListStores from './components/ListStores';
import Filters from './components/Filters';
import Wrapper from 'components/Wrapper';

const ChangeDealerComponent = ({
  onChangeSearch,
  handleClose,
  open,
  ActionsButtons,
  handleChangeStore,
  state,
  setState,
}) => {
  return (
    <GenericDialog
      open={open}
      onClose={() => handleClose()}
      title={'Alterar loja'}
      aria-labelledby="Carregando lojas"
      OnCloseButton={() => handleClose()}
      maxWidth="lg"
      DialogAction={ActionsButtons}
    >
      <Wrapper flex mb={32} ml={8} mr={8} gap={32} direction={'column'}>
        <Filters
          onChangeSearch={onChangeSearch}
          state={state}
          setState={setState}
        />

        <ListStores handleChangeStore={handleChangeStore} state={state} />
      </Wrapper>
    </GenericDialog>
  );
};

export default ChangeDealerComponent;
