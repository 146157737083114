export default [
  {
    value: 'I',
    label: '1',
  },
  {
    value: 'IIA',
    label: '2A',
  },
  {
    value: 'II',
    label: '2',
  },
  {
    value: 'IIIA',
    label: '3A',
  },
  {
    value: 'III',
    label: '3',
  },
  {
    value: 'IV',
    label: '4',
  },
  {
    value: 'V',
    label: '5',
  },
]
