import ErrorReport from './ErrorReport';
import EnvVars from 'helpers/envVars';
import getRestActions from 'helpers/requestModules';
import { setManageDealsServicesUrl } from 'modules/manageDeals/services';
import { setFinancialServicesUrl } from 'modules/financial/services';

import * as OptionsServices from './optionsServices';
import * as SearchServices from './searchServices';
import * as InventoryServices from './inventoryServices';
import * as DealerServices from './dealerServices';
import * as ImageServices from './imageServices';
import * as ProposalServices from './proposalServices';
import * as PerformanceServices from './performanceServices';
import * as ProviderServices from './providerServices';
import * as MobiFiServices from './mobiFiServices';
import * as MobiPhoneServices from './mobiPhoneServices';
import * as BookVehicleServices from './bookVehicleServices';

const envBaseUrl = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

//AXIOS API WITH AUTHORIZATION AND DEALER INFOS
export let mainRequest = getRestActions('');

export function setMainBaseUrl(dealerId: number | string) {
  const baseUrl = `${envBaseUrl}/dealer/${dealerId}`;
  mainRequest = getRestActions(baseUrl);
}

export const setAllBaseUrlsFromModules = (dealerInfos: any) => {
  const dealerId = dealerInfos.id;
  setMainBaseUrl(dealerId);
  setManageDealsServicesUrl(dealerId);
  setFinancialServicesUrl(dealerId);
};

export {
  ErrorReport,
  OptionsServices,
  SearchServices,
  InventoryServices,
  DealerServices,
  ImageServices,
  ProposalServices,
  PerformanceServices,
  ProviderServices,
  MobiFiServices,
  MobiPhoneServices,
  BookVehicleServices,
};
