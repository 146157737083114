//import { Posting } from "modules/financial/types";
import { ContactCardInfosDTO } from 'modules/financial/pages//components/CreateClient/components/ClientCardOption/types';
import { ZipcodeInfo } from 'modules/financial/services/ZipcodeServices';
import { createPostingDTO, Posting } from 'modules/financial/types';
import { Action } from 'types/ActionTypes';
import actionTypes from './actionTypes';

export interface PostingFilters {
  openField?: string | number;
  paid?: boolean;
  revenue?: boolean;
  contactId?: number;
  dealId?: string | number;
  categoryId?: number; //nao precisa desse filtro?
}

interface InitalStateDTO {
  postings: Posting[];
  //FILTROS DISPONIVEIS  DE HISTORICO DE LANÇAMENTOS
  filters: PostingFilters;
  selectedContact: ContactCardInfosDTO | null;
  newContactZipCodeInfo: ZipcodeInfo | null;
  createdPostingId: number | null;
  selectedPosting: null | Posting | createPostingDTO;
  isLoading: boolean;
}

const initalState: InitalStateDTO = {
  postings: [],
  filters: {} as PostingFilters,
  selectedContact: null,
  newContactZipCodeInfo: null,
  createdPostingId: null,
  selectedPosting: {} as Posting,
  isLoading: false,
};

const postingReducer = (state = initalState, { type, payload }: Action) => {
  switch (type) {
    case actionTypes.SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case actionTypes.RELOAD_ALL_POSTINGS:
      return { ...state, postings: payload.postings };

    case actionTypes.SAVE_POSTING:
      return { ...state, postings: [...state.postings, ...payload.postings] };

    case actionTypes.UPDATE_POSTING:
      const { updatedPosting } = payload;

      const postignToUpdateIndex = state.postings.findIndex(
        posting => posting.id == updatedPosting.id
      );
      let postings = state.postings;

      if (postignToUpdateIndex >= 0) {
        postings.splice(postignToUpdateIndex, 1, updatedPosting);
      }

      return { ...state, postings: postings.map(p => p) };

    case actionTypes.FILTER_POSTINGS:
      return {
        ...state,
        filters: payload.filters,
      };

    case actionTypes.POST_POSTING_SUCCESS: {
      return {
        ...state,
        createdPostingId: payload.createdPostingId,
        selectedPosting: {} as createPostingDTO,
        newContactZipCodeInfo: null,
        selectedContact: null,
      };
    }

    case actionTypes.DEAL_SELECTED:
      return {
        ...state,
        selectedPosting: {
          ...state.selectedPosting,
          deal: payload.deal,
        },
      };

    case actionTypes.CONTACT_SELECTED:
      const { id: contactId } = payload.contact || {};
      let posting = state.selectedPosting;

      posting = contactId ? { ...posting, contactId: contactId } : posting;

      return {
        ...state,
        selectedPosting: posting,
        selectedContact: payload.contact,
        contacts: [],
      };

    case actionTypes.NEW_CONTACT_ZIPCODE_INFO:
      return {
        ...state,
        newContactZipCodeInfo: payload.info,
      };

    case actionTypes.UPDATE_SELECTED_POSTING: {
      return {
        ...state,
        selectedPosting: payload.posting,
      };
    }
    default:
      return state;
  }
};

export default postingReducer;
