import styled from 'styled-components';
import Button from 'bit/mobiauto.web-ui-components.components.button/Button';

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioLabel = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;

export const NextButton = styled(Button)`
  background: ${({ theme }) => `${theme.primary}`} !important;
  color: #fff !important;
  text-transform: inherit !important;
  width: 100% !important;
`;
