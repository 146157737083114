// const paramsToSop = {
//   vehicleType: 'vtp',
//   makesS: 'mks',
//   makesI: 'mki',
//   modelsS: 'mds',
//   modelsI: 'mdi',
//   trimsS: 'trs',
//   trimsI: 'tri',
//   cityS: 'cis',
//   citiesI: 'cii',
//   state: 'ste',
//   radius: 'rds',
//   radiusFrom: 'rdf',
//   status: 'stt',
//   initialYear: 'iyr',
//   finalYear: 'fyr',
//   initialProductionYear: 'ipy',
//   finalProductionYear: 'fpi',
//   geoPosition: 'geo',
//   zipcode: 'zcd',
//   dealZeroKm: 'dzk',
//   regionType: 'rtp',
//   areaCode: 'arc',
//   initialPrice: 'ipr',
//   finalPrice: 'fpr',
//   colors: 'clr',
//   lifestyles: 'lfs',
//   features: 'ftr',
//   conditionals: 'cdt',
//   marketCategories: 'mcs',
//   bodyStyles: 'bds',
//   transmissions: 'trm',
//   fuels: 'fue',
//   armored: 'arm',
//   doors: 'doo',
//   seats: 'set',
//   kmMax: 'kmm',
//   dealers: 'der',
//   dealerType: 'det',
//   deals: 'dea',
//   withPhotos: 'wph',
//   transmissionAutomatic: 'tra',
//   lastNumberOfPlate: 'lpt',
//   plate: 'plt',
//   negativeDeal: 'ndl',
//   negativeDealer: 'nde',
//   showroom: 'srm',
//   resale: 'rsl',
//   tradeIn: 'trd',
//   evaluationGetPrice: 'egt',
//   sitemap: 'smp',
// bkd("booked", false, true),
// blk("blocked", false, true),
// wod("withorder", false, true),
// avl("available", false, true),
// ops("opensearch", false, true),
// };

export const buildSop = (currentSop, sopKey, value) => {
  if (currentSop) {
    //if key have value
    if (currentSop[sopKey]) {
      //if value is null
      if (!value) {
        return {
          ...currentSop,
          [sopKey]: null,
        };
      }

      //if_sop_is_query
      if (['ops_'].includes(sopKey)) {
        return {
          ...currentSop,
          [sopKey]: value,
        };
      }

      // if (['ipy_', 'fpi_'].includes(sopKey)) {
      //   return {
      //     ...currentSop,
      //     [sopKey]: `${value}.1`,
      //   };
      // }

      const sopValues = currentSop[sopKey].split('.')[0].split('|');
      //if the value exists for the same key
      if (sopValues.includes(value?.toString())) {
        const sopFiltredArray = sopValues.filter(
          el => el !== value?.toString()
        );
        if (!sopFiltredArray.length) {
          return { ...currentSop, [sopKey]: null };
        }
        let sopFiltredString = '';
        for (let i = 0; i < sopFiltredArray.length; i++) {
          sopFiltredString = `${
            sopFiltredString
              ? sopFiltredString + `|` + sopFiltredArray[i]
              : sopFiltredArray[i]
          }`;
        }
        return { ...currentSop, [sopKey]: `${sopFiltredString}.1` };
      }
      //if it is a new value for the same key
      return {
        ...currentSop,
        [sopKey]: `${currentSop[sopKey].split('.1')[0]}|${value}.1`,
      };
    }
    //if is a new value to key
    return { ...currentSop, [sopKey]: `${value}.1` };
  }
  return {};
};

export const convertObjectSopToString = sopObject => {
  let finalString: string | null = null;
  const piecesOfSop = Object.entries(sopObject);
  for (let i = 0; i < piecesOfSop.length; i++) {
    if (piecesOfSop[i][0] && piecesOfSop[i][1]) {
      finalString = finalString
        ? `${finalString}-${piecesOfSop[i][0]}${piecesOfSop[i][1]}`
        : `${piecesOfSop[i][0]}${piecesOfSop[i][1]}`;
    }
  }
  return finalString;
};

export const aggregationsTypes = {
  TRANSMISSION: {
    label: 'Transmissão',
    sopKey: 'trm_',
    placeholder: 'Ex: manual',
  },
  COLOR: { label: 'Cor', sopKey: 'clr_', placeholder: 'Ex: vermelho' },
  EXTERIOR_COLOR: { label: 'Cor', sopKey: 'exc_', placeholder: 'Ex: vermelho' },
  FUEL: { label: 'Combustível', sopKey: 'fue_', placeholder: 'Ex: gasolina' },
  DEALER: { label: 'Loja', sopKey: 'der_', placeholder: '' },
  FINALPLATE: { label: 'Fim da placa', sopKey: 'lpt_', placeholder: 'Ex: 3' },
  MAKE: { label: 'Marca', sopKey: 'mki_', placeholder: 'Ex: Honda' },
  MODEL: { label: 'Modelo', sopKey: 'mdi_', placeholder: 'Ex: Civic' },
  YEAR: { label: 'Ano Modelo', sopKey: 'iyr_', placeholder: 'Ex: 2021' },
  YEAR_BY_PRODUCTION: {
    label: 'Ano de Produção',
    sopKey: 'ipy_',
    placeholder: '',
  },
  // INITIAL_PRODUCTION_YEAR: {
  //   label: 'Ano de Produção Inicial',
  //   sopKey: 'ipy_',
  //   placeholder: 'Ex: 2021',
  // },
  // FINAL_PRODUCTION_YEAR: {
  //   label: 'Ano de Produção Final',
  //   sopKey: 'fpi_',
  //   placeholder: 'Ex: 2021',
  // },
  BODYSTYLE: { label: 'Carroceria', sopKey: 'bds_', placeholder: 'Ex: SUV' },
  DOORS: { label: 'Portas', sopKey: 'doo_', placeholder: 'Ex: 2' },
  STATE: { label: 'Estado', sopKey: 'ste_', placeholder: 'Ex: são paulo' },
  EXTERNAL_STOCK_KIND: {
    label: 'Tipo de estoque',
    sopKey: 'esk_',
    placeholder: '',
  },
  STOCK_KIND: { label: 'Perfil de estoque', sopKey: 'skd_', placeholder: '' },
  PURCHASE_TYPE: { label: 'Tipo', sopKey: 'dlt_', placeholder: '' },
  
  STOCK_TYPE: { label: 'Tipo de estoque', sopKey: 'stc_', placeholder: '' },
};

export const defaultSop = {
  trm_: null,
  fue_: null,
  clr: null,
  der_: null,
  lpt_: null,
  mki_: null,
  mdi_: null,
  iyr_: null,
  bds_: null,
  doo_: null,
  ste_: null,
  bkd_: null,
  blk_: null,
  wod_: null,
  avl_: null,
  ops_: null,
  ipy_: null,
  fpi_: null,
  skd_: null,
  stc_: null,
  dlt: null,
};
