import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: ${({ bgColor }) => bgColor};
  padding: 12px 16px;
`;

export const List = styled.div`
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
`;

export const EmptyUserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #5b5b5b;
`;
