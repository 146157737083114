import ActionTypes from './actionTypes'
import { Action } from 'types/ActionTypes'

export const INITIAL_STATE: any = {
  eventsScheduler: [],
  isLoading: false,
  isGetError: false,
  isUpdateError: false,
  isSetError: false,
  isDeleteError: false,
  eventsSchedulerTEst:[]
}

export default function scheduler(state: any = INITIAL_STATE, action: Action): any {
  switch(action.type) {
    case ActionTypes.SCHEDULER_LOADING:
    return{
      ...state,
      isLoading: true,
    }

    case ActionTypes.GET_SCHEDULER_ALL_EVENT_SUCCESS:
      return{
        ...state,
        isLoading: false,
        isGetError: false,
        eventsScheduler: [...action.payload]
      } 

    case ActionTypes.GET_SCHEDULER_ALL_EVENT_ERROR:
      return{
        ...state,
        isLoading: false,
        isGetError: true,
      } 

    case ActionTypes.UPDATE_SCHEDULER_EVENT_SUCCESS:
      return{
        ...state,
        isLoading: false,
        isUpdateError: false,
      } 

    case ActionTypes.UPDATE_SCHEDULER_EVENT_ERROR:
      return{
        ...state,
        isLoading: false,
        isUpdateError: true,
      } 

    case ActionTypes.DELETE_SCHEDULER_EVENT_ERROR:
      return{
        ...state,
        isLoading: false,
        isDeleteError: true,
      } 

    case ActionTypes.DELETE_SCHEDULER_EVENT_SUCCESS:
      return{
        ...state,
        isLoading: false,
        isDeleteError: false,
      } 

    case ActionTypes.CREATE_SCHEDULER_EVENT_ERROR:
      return{
        ...state,
        isLoading: false,
        isSetError: true,
      } 

    case ActionTypes.CREATE_SCHEDULER_EVENT_SUCCESS:
      return{
        ...state,
        isLoading: false,
        isSetError: false,
      } 

    default:
      return state
  }
}