import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useSnackBar from 'hooks/useSnackBar';

import { FilterOption } from 'pages/proposals/tabs/PopoverFilters/interfaces';
import useCustomMutation from 'pages/proposals/tabs/PopoverFilters/hooks/useCustomMutation';
import groupServices from 'services/groupServices';

interface Props {
  apiProperty: string;
  selectedsValues?: { label?: string; value: string }[];
}

export default function useGroupOrigins({
  apiProperty,
  selectedsValues,
}: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[] | null>(
    null
  );

  const { handleSnackBarError } = useSnackBar();
  const { currentGroup } = useSelector(state => state.callCenter);
  const groupId = currentGroup.id;

  function useGetOriginsOptions() {
    return useCustomMutation({
      key: 'getOriginsOptions',
      handleReturn: {
        onError: () => {
          handleSnackBarError('Ocorreu um erro ao consultar "Origem".');
        },
      },
      request: async () =>
        groupServices
          .getOriginsOptionsByGroupId(groupId)
          .then(response => response?.data),
    });
  }

  const { data, isLoading, mutate: getGroupOrigins } = useGetOriginsOptions();

  useEffect(() => {
    if (options?.length) {
      if (!selectedsValues.length && selectedOptions) {
        setSelectedOptions(null);
      }
      if (selectedsValues && selectedsValues.length) {
        const filtered = options.filter(option => {
          return selectedsValues.some(selected => {
            return selected.value === option.value;
          });
        });
        setSelectedOptions(filtered);
      }
    }
  }, [options, selectedsValues]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(el => ({
        filterGroupId: apiProperty,
        id: `${apiProperty}-${el?.name}`,
        label: el?.label,
        value: String(el?.name),
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOptions,
    getGroupOrigins,
    isLoading,
  };
}
